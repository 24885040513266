import React, {useState} from "react";
import TeamAufgabenBewerten from "./TeamAufgabenBewerten";
import {connect} from "react-redux";
import {AufgabenFilter} from "../../lib/constants";

const AufgabenBewerten = ({teams, texte}) => {
  const [filter, setFilter] = useState([]);
  const filterKeys = Object.keys(AufgabenFilter);

  const toggleFilter = (wert) => {
    if(filter.includes(wert)) {
      setFilter(filter.filter(w => w !== wert));
    } else {
      setFilter([...filter, wert]);
    }
  };
  
  return (
    <>
      <div className="block">
        <div className="header btn-liste">
          {texte.filter}
          {filterKeys.map(key => <button key={key} className={`btn btn-mini ${filter.includes(AufgabenFilter[key]) ? 'btn-success' : ''}`} onClick={() => toggleFilter(AufgabenFilter[key])}>{texte[key]}</button>)}
        </div>
      </div>
      {teams.map(team => <TeamAufgabenBewerten key={team.id} team={team} aufgabenFilter={filter}/>)}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    teams: state.teams,
    texte: state.i18n.texte.aufgabenFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AufgabenBewerten);
