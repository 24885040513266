import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";

const AnsichtBlocker = ({anpfiffAb, texte, heartbeat}) => {
  const [keinSpielAnpfiff, setKeinSpielAnpfiff] = useState(true);
  const [zeitBisAnpfiff, setZeitBisAnpfiff] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  
  useEffect(() => {
    if (anpfiffAb > new Date()) {
      const interval = setInterval(() => {
        const jetzt = new Date();
        if (anpfiffAb > jetzt) {
          const delta = new Date(anpfiffAb - jetzt);
          delta.addMinutes(delta.getTimezoneOffset());
          setZeitBisAnpfiff(delta);
        } else {
          clearInterval(intervalId);
          setKeinSpielAnpfiff(false);
        }
      }, 1000);
      setIntervalId(interval);
    } else {
      setKeinSpielAnpfiff(false);
    }
  }, []);

  if (keinSpielAnpfiff) {
    return (
      <>
        <div className="blocker blockerOverlay"/>
        <div className="blocker blockerNachricht">
          {texte.titel.replace('{spieltag}', anpfiffAb.toLocaleDateString()).replace('{spielzeit}', anpfiffAb.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}))} 
          <br/>
          {zeitBisAnpfiff &&
          <span>{zeitBisAnpfiff.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second: '2-digit'})}</span>
          }
        </div>
      </>
    );
  } else if (!heartbeat.online) {
    return (
      <>
        <div className="blocker blockerOverlay"/>
        <div className="blocker blockerNachricht">
          {texte.tos_offline}
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state) => {
  return {
    anpfiffAb: state.spiel.anpfiff_ab,
    texte: state.i18n.ansicht_blocker,
    heartbeat: state.heartbeat,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AnsichtBlocker);
