import React, { useState } from "react";
import { connect } from "react-redux";
import ModalerDialog from "../../../../components/ModalerDialog";
import { sponsorenEdit } from "../../state/modules/sponsorenlaufSponsoren";
import ControlGroup from "../../../../components/Person/ControlGroup";
import { executeRequest } from "../../lib/fetchHelper";
import { executeJson } from "../../../../lib/fetchHelper";
import { formatiereBetrag } from "../../../../lib/formatierungen";

const SponsorBearbeitenModal = ({ sponsor, onHide, sponsorenlauf, spielerliste, api, texte, laender, einsaetze, sponsorEdit, bexio }) => {
  const [editSponsor, setEditSponsor] = useState({ ...sponsor, betrag: formatiereBetrag(parseFloat(sponsor.betragPauschal) + parseFloat(sponsor.betragRunde)) });
  const texteSponsoren = texte.sponsoren;

  // Fuer Speichern anzeige
  const [spinner, setSpinner] = useState(false);

  // Fuer Adresssuche
  const timeoutMiliseconds = 400; // TODO: passendes timeout für eingabe von hand finden
  const [sucheTimeout, setSucheTimeout] = useState(null);
  const [abfrageAktiv, setAbfrageAktiv] = useState(false);
  const [liste, setListe] = useState([]);
  const [showPlzResultate, setShowPlzResultate] = useState(false);
  const [showOrtResultate, setShowOrtResultate] = useState(false);
  const spieler = spielerliste.find(s => s.spielerId == sponsor.spielerId);

  const setzeSponsor = (key, value) => {
    setEditSponsor({ ...editSponsor, [key]: value });
  }

  const setzeAdresse = (key, value) => {
    setEditSponsor({ ...editSponsor, adresse: { ...editSponsor.adresse, [key]: value } });
  }
  const setzeBetrag = () => {
    setzeSponsor('betrag', formatiereBetrag(editSponsor.betrag))
  };

  const zeigePlz = (anzeigen) => {
    setShowPlzResultate(anzeigen && liste && liste.length > 0);
  }

  const zeigeOrt = (anzeigen) => {
    setShowOrtResultate(anzeigen && liste && liste.length > 0);
  }

  const ortSucheStarten = (sucheOrt) => {
    clearTimeout(sucheTimeout);
    const timeoutId = setTimeout(() => abfragen({ ort: sucheOrt }), timeoutMiliseconds);
    setSucheTimeout(timeoutId);
  };

  const plzSucheStarten = (suchePlz) => {
    clearTimeout(sucheTimeout);
    const timeoutId = setTimeout(() => abfragen({ plz: suchePlz }), timeoutMiliseconds);
    setSucheTimeout(timeoutId);
  };

  const abfragen = (daten) => {
    setAbfrageAktiv(true);
    executeRequest(api.adresse, api.token, 'GET', daten)
      .then(result => {
        if (result.ok) {
          const data = result.data;
          setListe(data);
          if (daten['ort']) {
            setShowOrtResultate(true);
          }
          if (daten['plz']) {
            setShowPlzResultate(true);
          }
        }
        setAbfrageAktiv(false);
      });
  };

  const selectEintrag = (eintrag) => {
    zeigeOrt(false);
    zeigePlz(false);
    editSponsor.adresse.plz = eintrag.plz;
    editSponsor.adresse.ort = eintrag.ort;
    editSponsor.adresse.landId = eintrag.land;
    setEditSponsor({ ...editSponsor })
    setListe(null);
  }

  const onChangeOrt = (text) => {
    setzeAdresse('ort', text);
    zeigeOrt(false);
    zeigePlz(false);
    ortSucheStarten(text);
  };

  const onChangePlz = (text) => {
    setzeAdresse('plz', text);
    zeigePlz(false);
    zeigeOrt(false);
    plzSucheStarten(text);
  };

  const onFocusPlz = () => {
    if (!liste || liste.length === 0) {
      plzSucheStarten(editSponsor.adresse.plz);
    }
  };

  const onFocusOrt = () => {
    if (!liste || liste.length === 0) {
      ortSucheStarten(editSponsor.adresse.ort);
    }
  };

  const onClickOrt = () => {
    zeigeOrt(!showOrtResultate);
    zeigePlz(false);
  }

  const onClickPlz = () => {
    zeigePlz(!showPlzResultate);
    zeigeOrt(false);
  }

  const onBlur = () => {
    zeigePlz(false);
    zeigeOrt(false);
  }

  const speichern = (ev) => {
    ev.currentTarget.blur();
    if (spinner) {
      return; // request läuft bereits
    }
    setSpinner(true);
    const data = {
      sponsorenlaufId: sponsorenlauf.id,
      strName: editSponsor.nachname,
      strVornameZusatz: editSponsor.vorname,
      strStrasse: editSponsor.adresse.strasse,
      strEMail: editSponsor.email,
      lPLZ: editSponsor.adresse.plz,
      strOrt: editSponsor.adresse.ort,
      sLandCodeBFS: editSponsor.adresse.landId,
      sVereinSponsorenlaufSpielerSponsorenEinsatz: editSponsor.einsatzId,
      betrag: editSponsor.betrag,
    };
    executeJson(`${api.sponsor}/${editSponsor.id}`, api.token, 'PUT', data)
      .then(result => {
        if (result.ok) {
          sponsorEdit(result.data);
          onHide();
        } else {
          setEditSponsor({ ...editSponsor, errors: result.data.errors });
          setSpinner(false);
        }
      })

  };
  const schliessen = () => {
    onHide();
  };

  return (
    <ModalerDialog>
      <div className="modal modal-wide">
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{`${texteSponsoren.bearbeiten} ${spieler.person.name} / ${editSponsor.name}`}</h3>
        </div>
        <div className="modal-body">
          <div className="row-fluid">
            <div className="span6">
              <ControlGroup labelText={texteSponsoren.vorname} htmlFor="strVornameZusatz" errors={editSponsor.errors.strVornameZusatz}>
                <input type="text" id="strVornameZusatz" value={editSponsor.vorname} onChange={ev => setzeSponsor('vorname', ev.currentTarget.value)}/>
              </ControlGroup>
              <ControlGroup labelText={texteSponsoren.nachname} htmlFor="strName" errors={editSponsor.errors.strName}>
                <input type="text" id="strName" value={editSponsor.nachname} onChange={ev => setzeSponsor('nachname', ev.currentTarget.value)}/>
              </ControlGroup>
              <ControlGroup labelText={texteSponsoren.mail} htmlFor="strMail" errors={editSponsor.errors.strMail}>
                <input type="text" id="strMail" value={editSponsor.email} onChange={ev => setzeSponsor('email', ev.currentTarget.value)}/>
              </ControlGroup>
            </div>
            <div className="span6">
              <ControlGroup labelText={texteSponsoren.strasse} htmlFor="strStrasse" errors={editSponsor.errors.strStrasse}>
                <input type="text" id="strStrasse" value={editSponsor.adresse.strasse} onChange={ev => {setzeAdresse('strasse', ev.currentTarget.value)}}/>
              </ControlGroup>
              <ControlGroup labelText={texteSponsoren.plz} htmlFor="lPLZ" errors={editSponsor.errors.lPLZ}>
                <input type="text" id="lPLZ" value={editSponsor.adresse.plz} onChange={ev => {onChangePlz(ev.currentTarget.value)}} onFocus={onFocusPlz} onBlur={onBlur} onClick={onClickPlz}/>
                {abfrageAktiv && <i className="fa fa-spinner fa-spin"/>}
                {showPlzResultate &&
                  <div className="suchresultat-container">
                    <div className="suchresultat-liste" onMouseDown={ev => ev.preventDefault()}>
                      {liste.map(eintrag =>
                        <div key={eintrag.plz + eintrag.ort} className={`item`} onClick={() => selectEintrag(eintrag)}>{`${eintrag.plz}, ${eintrag.ort}`}</div>)}
                    </div>
                  </div>
                }
              </ControlGroup>
              <ControlGroup labelText={texteSponsoren.ort} htmlFor="strOrt" errors={editSponsor.errors.strOrt}>
                <input type="text" id="strOrt" value={editSponsor.adresse.ort} onChange={ev => {onChangeOrt(ev.currentTarget.value)}} onFocus={onFocusOrt} onBlur={onBlur} onClick={onClickOrt}/>
                {abfrageAktiv && <i className="fa fa-spinner fa-spin"/>}
                {showOrtResultate &&
                  <div className="suchresultat-container">
                    <div className="suchresultat-liste" onMouseDown={ev => ev.preventDefault()}>
                      {liste.map(eintrag => <div key={eintrag.plz + eintrag.ort} className={`item`}
                                                 onClick={() => selectEintrag(eintrag)}>{`${eintrag.ort}, ${eintrag.plz}`}</div>)}
                    </div>
                  </div>
                }
              </ControlGroup>
              <ControlGroup labelText={texteSponsoren.land} htmlFor="sLandCodeBFS" errors={editSponsor.errors.sLandCodeBFS}>
                <select id="sLandCodeBFS" value={editSponsor.adresse.landId || ''} onChange={event => setzeAdresse('landId', event.currentTarget.value)}>
                  {laender && laender.map(l => <option key={l.id} value={l.id}>{l.bezeichnung}</option>)}
                </select>
              </ControlGroup>
            </div>
          </div>
          <div className="row-fluid">
            <div className="span6">
              <ControlGroup labelText={texteSponsoren.einsatz} htmlFor="sVereinSponsorenlaufSpielerSponsorenEinsatz" errors={editSponsor.errors.sVereinSponsorenlaufSpielerSponsorenEinsatz}>
                <select id="sVereinSponsorenlaufSpielerSponsorenEinsatz" value={editSponsor.einsatzId || ''} onChange={event => setzeSponsor('einsatzId', event.currentTarget.value)}>
                  {einsaetze && einsaetze.map(einsatz => <option key={einsatz.id} value={einsatz.id}>{einsatz.bezeichnung}</option>)}
                </select>
              </ControlGroup>
            </div>
            <div className="span6">
              <ControlGroup labelText={texteSponsoren.betrag} htmlFor="smnBetrag" errors={editSponsor.errors.betrag}>
                <input type="number" id="smnBetrag" min={0} step={0.05} className="text-right" value={editSponsor.betrag} onBlur={() => setzeBetrag()} onChange={ev => {setzeSponsor('betrag', ev.currentTarget.value)}}/>
              </ControlGroup>
            </div>
          </div>
          {bexio &&
            <div className="row-fluid">
              <p className="text-center" style={{backgroundColor: "yellow", margin: "15px"}}>
                {texteSponsoren.bexio_info}
              </p>
            </div>
          }
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" disabled={spinner} onClick={speichern}>
            {texte.speichern}
            {spinner && <>&nbsp;<i className="fa fa-refresh fa-spin"/></>}
          </button>
          <button type="button" className="btn" onClick={schliessen}>{texte.abbrechen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    sponsorenlauf: state.sponsorenlauf,
    spielerliste: state.spieler,
    api: state.api,
    texte: state.i18n.texte,
    laender: state.listen.laender,
    einsaetze: state.listen.einsaetze,
    bexio: state.bexio,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sponsorEdit: (sponsor) => {
      dispatch(sponsorenEdit([sponsor]));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SponsorBearbeitenModal);
