import React, {useMemo, useState} from 'react'
import {connect} from "react-redux";
import {toggleTickerAnzeige} from "../state/modules/editMarkierer";
import TickerEreignisEdit from "./TickerEreignisEdit";
import TickerEreignisView from "./TickerEreignisView";
import TickerEreignisGenerisch from "./TickerEreignisGenerisch";

const Ticker = ({highlight, ereignisse, heim, gast, spielIstAbgeschlossen, tickerAnzeigen, editSpielverlauf, texte, toggleTickerAnzeige}) => {
  const ereignisseSortiert = useMemo(() => {
    return [...ereignisse].reverse();
  }, [ereignisse])
  const [heimOderGast, setHeimOderGast] = useState(null);

  return (
    <div className={`ticker ${tickerAnzeigen ? '' : 'ohne-ticker'}`}>
      <div onClick={() => toggleTickerAnzeige(!tickerAnzeigen)} className="toggle"/>
      <div className="header flex-row">
        <span className="titel flex1">
          {texte.titel}
        </span>
        <span className="mha-benutzer flex1 flex-row">
        {highlight && highlight.uebernommen &&
        <>
          <i className="kamera"/>
          <span>
            {texte.videoaufnahmen}
            <br/>
            <i className={`icon icon-white ${highlight.online === true ? 'icon-ok-circle' : 'icon-ban-circle'}`} />
            {highlight.uebernommen_person}
          </span>
        </>
        }
        </span>
      </div>
      <div className="ereignisse">
        {!spielIstAbgeschlossen &&
          <div className="eintrag flex-row">
            <span className="links flex1 text-left">
              <button type="button" className="btn" onClick={() => setHeimOderGast(heim)}>
                <i className="icon-plus"/>
              </button>
            </span>
            <span className="zeitlinie flex-row">
              <span className="flex1"/>
              <span className="streifen"/>
              <span className="flex1"/>
            </span>
            <span className="rechts flex1 text-right">
              <button type="button" className="btn" onClick={() => setHeimOderGast(gast)}>
                <i className="icon-plus"/>
              </button>
            </span>
          </div>
        }
        {heimOderGast !== null &&
          <TickerEreignisGenerisch heimOderGast={heimOderGast} onClose={() => setHeimOderGast(null)} />
        }
        {ereignisseSortiert.map((ereignis) => {
          if (editSpielverlauf.includes(ereignis.id)) {
            return <TickerEreignisEdit key={ereignis.id} ereignis={ereignis}/>
          } else {
            return <TickerEreignisView key={ereignis.id} ereignis={ereignis}/>;
          }
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    highlight: state.heartbeat.highlight,
    ereignisse: state.spielverlauf,
    heim: state.heim,
    gast: state.gast,
    spielIstAbgeschlossen: state.spiel.ist_abgeschlossen,
    tickerAnzeigen: state.editMarkierer.tickerAnzeigen,
    editSpielverlauf: state.editMarkierer.spielverlauf,
    texte: state.i18n.ticker,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleTickerAnzeige: (anzeige) => { dispatch(toggleTickerAnzeige(anzeige)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ticker);
