import {spielgruppenSortieren} from "./modules/spielgruppen";

function preloaderFuerPlatzchef(options) {
  return {
    ligen: options.ligen.sort((a, b) => a.ligaNr - b.ligaNr),
    spielgruppen: options.spielgruppen.sort(spielgruppenSortieren),
    ranglisten: options.ranglisten,
    spiele: options.spiele.sort((a, b) => a.spielzeit.localeCompare(b.spielzeit)),
    teams: options.teams,
    spielstatusListe: options.spielstatusListe,
    meldungen: options.meldungen,
    api: options.api,
    i18n: options.i18n,
    rollbar: options.rollbar,
  };
}
export default preloaderFuerPlatzchef;
