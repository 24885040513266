const SEITENWECHSEL = 'SEITENWECHSEL';

export function seitenwechsel() {
  return (dispatch, getState) => {
    const anzeige = getState().spielfeld.anzeige === 'invertiert' ? 'normal' : 'invertiert';
    dispatch({type: SEITENWECHSEL, anzeige: anzeige});
  };
}

function spielfeldReducer(state = {}, action) {
  switch (action.type) {
    case SEITENWECHSEL:
      return {
        ...state,
        anzeige: action.anzeige,
      };
    default:
      return state;
  }
}

export default spielfeldReducer;
