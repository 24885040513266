import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {formatiereZeit} from "../../../lib/formatierungen";
import ResultatMeldenModal from "./ResultatMeldenModal";
import SpielstatusLabel from "./SpielstatusLabel";
import Rangliste from "./Rangliste";

const Spielgruppe = ({spielgruppe, ligen, ranglisten, spiele, teams, texte}) => {
  const [showResultatMelden, setShowResultatMelden] = useState(null);
  const [showFilter, setShowFilter] = useState(false);

  const alleResultateVorhanden = spiele.every(s => s.keinResultat === false);
  const [showSpiele, setShowSpiele] = useState(!alleResultateVorhanden);
  const [filter, setFilter] = useState(alleResultateVorhanden ? {team: null, resultatGemeldet: null} : {team: null, resultatGemeldet: false});

  const liga = ligen.find(l => l.id === spielgruppe.ligaId);
  const rangliste = ranglisten.find(r => r.id === spielgruppe.id);
  const gefilterteSpiele = filterSpiele(spiele);

  useEffect(() => {
    if (showSpiele && spiele.every(s => s.keinResultat === false)) {
      // wenn neu alle spiele das resultat gemeldet haben, spiele / filter zuklappen und filter reset
      setShowSpiele(false);
      setShowFilter(false);
      resetFilter();
    }
  }, [spiele]);
  
  const toggleFilter = (ev) => {
    ev.stopPropagation();
    setShowFilter(!showFilter);
  };

  const changeFilter = (name, wert) => {
    const f = {...filter};
    if (wert === '') {
      f[name] = null;
    } else {
      f[name] = wert;
    }
    setFilter(f);
  };

  const resetFilter = () => {
    setFilter({team: null, resultatGemeldet: null});
  };

  function filterSpiele(spiele) {
    let spielListe = [...spiele];

    if (filter.team !== null) {
      spielListe = spielListe.filter(s => {
        const heim = teams.find(t => t.id === s.heimTeamId);
        const gast = teams.find(t => t.id === s.gastTeamId);
        return heim.bezeichnung.toLocaleLowerCase().indexOf(filter.team.toLocaleLowerCase()) > -1 || gast.bezeichnung.toLocaleLowerCase().indexOf(filter.team.toLocaleLowerCase()) > -1;
      });
    }
    if (filter.resultatGemeldet !== null) {
      spielListe = spielListe.filter(s => s.keinResultat !== filter.resultatGemeldet);
    }

    return spielListe;
  }

  const renderSpiel = (spiel, index) => {
    const heim = teams.find(t => t.id === spiel.heimTeamId);
    const gast = teams.find(t => t.id === spiel.gastTeamId);
    const cssClass = `row-fluid spiel-box ${index % 2 === 0 ? 'striped' : ''}`;

    return (
      <div key={spiel.id}>
        <div className={`${cssClass} hidden-phone`}>
          <div className="span2">
            <i className="icon-time" style={{verticalAlign: 'baseline'}}/> {formatiereZeit(spiel.spielzeit)}
          </div>
          <div className="span3">{heim.bezeichnung}</div>
          <div className="span1">{spiel.keinResultat ? "-" : `${spiel.toreHeim}:${spiel.toreGast}`}</div>
          <div className="span3">{gast.bezeichnung}</div>
          <div className="span2">
            {spiel.keinResultat ||
            <SpielstatusLabel spielstatusId={spiel.spielstatusId}/>
            }
          </div>
          <div className="span1">
            <button type="button" className="btn resultat-melden" onClick={() => setShowResultatMelden(spiel)}>
              <span className="resultat-icon"/>
            </button>
          </div>
        </div>
        <div className={`${cssClass} visible-phone`}>
          <div className="paarung">
            <div className="team">
              <i className="icon-time" style={{verticalAlign: 'baseline'}}/> {formatiereZeit(spiel.spielzeit)}
            </div>
            <div>
              {spiel.keinResultat ||
              <SpielstatusLabel spielstatusId={spiel.spielstatusId}/>
              }
              &nbsp;
              <button type="button" className="btn resultat-melden" onClick={() => setShowResultatMelden(spiel)}>
                <span className="resultat-icon"/>
              </button>
            </div>
          </div>
          <div className="paarung">
            <div className="team">{heim.bezeichnung}</div>
            <div className="resultat">{spiel.keinResultat ? "" : `${spiel.toreHeim}`}</div>
          </div>
          <div className="paarung">
            <div className="team">{gast.bezeichnung}</div>
            <div className="resultat">{spiel.keinResultat ? "" : `${spiel.toreGast}`}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <section>
        <h2 className="page-section cursor-pointer clearfix" onClick={() => setShowSpiele(!showSpiele)}>
          {spielgruppe.spielklasse}
          {spielgruppe.bezeichnung &&
          ` - ${spielgruppe.bezeichnung}`
          }
          {' '}
          <small>{liga.bezeichnung}</small>
          <div className="pull-right">
            <button className="btn"><i className={showSpiele ? "icon-arrow-up" : "icon-arrow-down"} /></button>
            &nbsp;
            <button className="btn" onClick={toggleFilter}>
              <i className="icon-filter"/> {texte.filter}
            </button>
          </div>
        </h2>
        {showFilter &&
        <div className="filter-box">
          <div className="row-fluid">
            <div className="span4"><b>{texte.team}</b></div>
            <div className="span8">
              <input type="text" value={filter.team || ''} onChange={ev => changeFilter('team', ev.currentTarget.value)}/>
            </div>
          </div>
          <div className="row-fluid">
            <div className="span4"><b>{texte.resultatGemeldet}</b></div>
            <div className="span8">
              <span className="btn-group">
                <button type="button" className={`btn ${filter.resultatGemeldet === true ? ' btn-success' : ''}`} onClick={() => changeFilter('resultatGemeldet', filter.resultatGemeldet === true ? null : true)}>{texte.ja}</button>
                <button type="button" className={`btn ${filter.resultatGemeldet === false ? ' btn-success' : ''}`} onClick={() => changeFilter('resultatGemeldet', filter.resultatGemeldet === false ? null : false)}>{texte.nein}</button>
              </span>
            </div>
          </div>
          <div className="row-fluid">
            <div className="offset4 span8">
              <button className="btn" onClick={() => resetFilter()}>{texte.filterEntfernen}</button>
            </div>
          </div>
        </div>
        }
        {showSpiele &&
        <>
          {rangliste && rangliste.eintraege.length > 0 &&
          <Rangliste rangliste={rangliste.eintraege} useFavorit={false} />
          }
          <div className="spiel-container">
            {gefilterteSpiele.map((spiel, index) => renderSpiel(spiel, index))}
          </div>
        </>
        }
      </section>
      {showResultatMelden &&
      <ResultatMeldenModal spiel={showResultatMelden} onHide={() => setShowResultatMelden(null)}/>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ligen: state.ligen,
    ranglisten: state.ranglisten,
    teams: state.teams,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Spielgruppe);
