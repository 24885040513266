import React, {useEffect, useRef, useState} from "react";
import "../shared/shared-components";

export const POSITION = {
  left: 'left',
  right: 'right',
  top: 'top',
  bottom: 'bottom'
};

const PersonIcon = ({person, position = POSITION.top}) => {
  const [infoSichtbar, setInfoSichtbar] = useState(false);
  const [styleWerte, setStyleWerte] = useState({left: -100000, top: -100000}); // initiale position ausserhalb sichtbarem bereich setzen -> verhindert "flackern" beim ersten anzeigen
  const infoBoxElement = useRef(null);

  useEffect(() => {
    if (infoSichtbar) {
      let style = {
        left: 'auto',
        rigth: 'auto',
        top: 'auto',
        bottom: 'auto',
      };
      switch (position) {
        case POSITION.right:
          style.top = -Math.floor((infoBoxElement.current.clientHeight - 11) / 2);
          style.left = 12;
          break;
        case POSITION.left:
          style.top = -Math.floor((infoBoxElement.current.clientHeight - 11) / 2);
          style.right = 22;
          break;
        case POSITION.top:
          style.bottom = 22;
          style.left = -Math.floor((infoBoxElement.current.clientWidth - 11) / 2);
          break;
        case POSITION.bottom:
          style.top = 12;
          style.left = -Math.floor((infoBoxElement.current.clientWidth - 11) / 2);
          break;
      }
      setStyleWerte(style);
    }
  }, [infoSichtbar, position]);

  return (
    <span className='person-info' onClick={() => setInfoSichtbar(!infoSichtbar)}>
      <span className='icon-info-sign cursor-pointer'>
        {infoSichtbar &&
        <div ref={infoBoxElement} className={`info-box popover ${position}`} style={styleWerte}>
          <div className="arrow"/>
          <h3 className="popover-title">{person.name}</h3>
          <div className="popover-content">
            <div className="text-nowrap">
              {person.telefon.map((telefon, index) => <span key={index}><span className='fa fa-phone'/> <a href={`tel:${telefon}`}>{telefon}</a><br/></span>)}
              {person.email.map((email, index) => <span key={index}><span className='icon-envelope'/> <a href={`mailto:${email}`}>{email}</a><br/></span>)}
            </div>
          </div>
        </div>
        }
      </span>
    </span>
  );
};

export default PersonIcon;
