
export function Zeitslot(teams) {
  if (Array.isArray(teams)) {
    this.teams = teams;
  } else {
    this.teams = [teams];
  }
  this.von = this.teams[0].von;
  this.bis = this.teams[0].bis;
  this.key = createZeitslotKey(this.teams[0]);
}

export function createZeitslotKey(team) {
  const keys = [];
  if (team.von) keys.push(team.von.toJSON());
  if (team.bis) keys.push(team.bis.toJSON());
  return keys.join('-');
}
