import React, {useState} from "react";
import {connect} from "react-redux";
import TeamTeilnehmer from "./TeamTeilnehmer";
import {useDrop} from "react-dnd";
import {ItemTypes} from "../../lib/constants";
import {teamZuteilungSpeichern} from "../../state/lehrerTeamTeilnehmerReducer";
import IconToggle from "../IconToggle";
import Datepicker from "../Datepicker";
import {formatiereDatumZeit} from "../../lib/formatierungen";
import {teamDelete, teamSpeichern} from "../../state/teamsReducer";

const LehrerTeam = ({team, showZeit = true, showKlassenlehrer = true, teamTeilnehmer, programmBlockListe, texte, teamSpeichern, teamDelete, teamZuteilungSpeichern}) => {
  const teilnehmer = teamTeilnehmer.filter(tt => tt.teamId === team.id);
  const experte = team.experte;
  const [editMode, setEditMode] = useState(false);
  const [bezeichnung, setBezeichnung] = useState(team.bezeichnung);
  const [von, setVon] = useState(team.von);
  const [bis, setBis] = useState(team.bis);
  const [programmBlockId, setProgrammBlockId] = useState(team.programmBlock.id);

  const [{isOver, canDrop}, drop] = useDrop({
    accept: ItemTypes.Ausbildner,
    drop: (item) => {
      teamZuteilungSpeichern(team, item, {});
    },
    canDrop: (item) => {
      return teilnehmer.find(tt => tt.ausbildner.id === item.id) === undefined;
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const onSpeichernClick = () => {
    if (bezeichnung && programmBlockId) {
      const zusatzdaten = {
        teamId: team.id,
        bezeichnung: bezeichnung,
        von: von,
        bis: bis,
        programmBlockId: programmBlockId
      };
      teamSpeichern(experte, zusatzdaten);
      setEditMode(false);
    }
  };

  const onLoeschenClick = () => {
    teamDelete(team);
    setEditMode(false);
  };

  return (
    <div className="block">
      <div className="header">
        <button className="btn btn-mini pull-right" onClick={() => setEditMode(!editMode)}>
          <IconToggle istBearbeitbar={true}/>
        </button>
        <b>{bezeichnung}</b>
        &nbsp;<span className="label label-warning">{team.programmBlock.name}</span>
        <div className="edit">
          {showKlassenlehrer &&
          <div className="zeile">
            {`${texte.experte}: ${team.experte.name}`}
          </div>
          }
          {showZeit &&
          <div className="zeile">
            {texte.datumVon}: {formatiereDatumZeit(von)}
            <br/>
            {texte.datumBis}: {formatiereDatumZeit(bis)}
          </div>
          }
          {editMode &&
          <>
            <div className="zeile">
              <label>{texte.teamBezeichnung}</label>
              <input type="text" value={bezeichnung} onChange={(ev) => setBezeichnung(ev.currentTarget.value)}/>
            </div>
            <div className="zeile">
              <label>{texte.datumVon}</label>
              <Datepicker datum={von} changeHandler={setVon} mitZeit={true}/>
            </div>
            <div className="zeile">
              <label>{texte.datumBis}</label>
              <Datepicker datum={bis} changeHandler={setBis} mitZeit={true}/>
            </div>
            <div className="zeile">
              <label>{texte.teamProgrammBlock}</label>
              <select className='select' value={programmBlockId} onChange={(ev) => setProgrammBlockId(ev.currentTarget.value)}>
                <option value=""/>
                {programmBlockListe.map(block => <option key={block.id} value={block.id}>{block.name}</option>)}
              </select>
            </div>
            <div className="zeile">
              <label/>
              <button className="btn btn-primary" onClick={onSpeichernClick}>{texte.speichern}</button>
              <button className="btn btn-danger pull-right" onClick={onLoeschenClick}>{texte.loeschen}</button>
            </div>
          </>
          }
        </div>
      </div>
      <div ref={drop} className={`content ${canDrop ? 'drop-ziel' : ''} ${canDrop && isOver ? 'drop-hover' : ''}`}>
        {teilnehmer.map((t) => <TeamTeilnehmer key={t.id} team={team} teamTeilnehmer={t}/>)}
        {teilnehmer.length === 0 &&
        <div className="alert alert-info">{texte.keineTeamZuordnungen}</div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    teamTeilnehmer: state.teamTeilnehmer,
    programmBlockListe: state.programmBlockListe,
    texte: state.i18n.texte
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    teamSpeichern: (klassenlehrer, zusatzdaten) => { dispatch(teamSpeichern(klassenlehrer, zusatzdaten)) },
    teamDelete: (team) => { dispatch(teamDelete(team)) },
    teamZuteilungSpeichern: (klassenlehrer, ausbildner, zusatzdaten) => { dispatch(teamZuteilungSpeichern(klassenlehrer, ausbildner, zusatzdaten)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LehrerTeam);
