import React, {useState} from "react";
import {connect} from "react-redux";
import {rechnungenSpielerSperren} from "../../state/modules/rechnungskontrollen";
import ModalerDialog from "../../../../components/ModalerDialog";
import PersonBadge from "../../../../components/PersonBadge";

const SpielerListeSperrenModal = ({rechnungen, onHide, texte, rechnungenSpielerSperren}) => {
  const [bemerkungen, setBemerkungen] = useState('');
  const rechnungenMitSperre = rechnungen.filter(r => r.spielerInfo && r.spielerInfo.gesperrt === true);
  const rechnungenOhneSperre = rechnungen.filter(r => r.spielerInfo && r.spielerInfo.gesperrt === false);

  const sperren = () => {
    if (bemerkungen == '') {
      return;
    }
    rechnungenSpielerSperren(rechnungenOhneSperre, true, bemerkungen);
    if (rechnungenMitSperre.length === 0) {
      schliessen();
    }
  };

  const aufheben = () => {
    rechnungenSpielerSperren(rechnungenMitSperre, false, bemerkungen);
    if (rechnungenOhneSperre.length === 0) {
      schliessen();
    }
  };

  const schliessen = () => {
    onHide();
  };

  return (
    <ModalerDialog>
      <div className="modal modal-wide">
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{texte.titel}</h3>
        </div>
        <div className="modal-body" style={{overflow: 'visible'}}>
          <table className="table attributes">
            <tbody>
            {rechnungenOhneSperre.length > 0 &&
            <>
              <tr>
                <th>{texte.nichtGesperrteSpieler}</th>
                <td>{rechnungenOhneSperre.map(r => <PersonBadge key={r.id} person={r.person} personVerlinken={false} showVcf={false}/>)}</td>
              </tr>
              <tr>
                <th>{texte.bemerkungen}</th>
                <td><textarea rows={5} style={{width: '100%'}} value={bemerkungen} onChange={ev => setBemerkungen(ev.currentTarget.value)}/></td>
              </tr>
              <tr>
                <th></th>
                <td><button type="button" className="btn btn-danger" onClick={sperren}>{texte.spielerSperren}</button></td>
              </tr>
            </>
            }
            {rechnungenMitSperre.length > 0 &&
            <>
              <tr>
                <th>{texte.gesperrteSpieler}</th>
                <td>{rechnungenMitSperre.map(r => <PersonBadge key={r.id} person={r.person} personVerlinken={false} showVcf={false}/>)}</td>
              </tr>
              <tr>
                <th></th>
                <td><button type="button" className="btn btn-success" onClick={aufheben}>{texte.sperrungAufloesen}</button></td>
              </tr>
            </>
            }
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn" onClick={schliessen}>{texte.abbrechen || texte.schliessen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    texte: state.i18n.texte.spielerSperren,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rechnungenSpielerSperren: (rechnungen, sperren, bemerkungen) => { dispatch(rechnungenSpielerSperren(rechnungen, sperren, bemerkungen)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpielerListeSperrenModal);
