import React, {useState} from "react";
import {connect} from "react-redux";
import ArbeitsraumEintrag from "./ArbeitsraumEintrag";
import ArbeitsraumEintragEdit from "./ArbeitsraumEintragEdit";

const ArbeitsraumListe = ({arbeitsraumListe, aufgabeZuteilung = null, team = null, teamTeilnehmer = null, fuerKlasse = false, ausbildung, texte}) => {
  const [neuerEintrag, setNeuerEintrag] = useState(arbeitsraumListe.length === 0 && !fuerKlasse);

  const speichernClicked = () => {
    setNeuerEintrag(false);
  };

  return (
    <div className={fuerKlasse ? '' : 'chat'}>
      {arbeitsraumListe.map(a => <ArbeitsraumEintrag key={a.id} eintrag={a}/>)}
      {neuerEintrag &&
      <ArbeitsraumEintragEdit aufgabeZuteilung={aufgabeZuteilung} speichernClicked={speichernClicked} team={team} teamTeilnehmer={teamTeilnehmer}/>
      }
      {!neuerEintrag && ausbildung.bearbeitbar &&
      <button className="btn btn-primary" onClick={() => setNeuerEintrag(true)}>{texte.neuerEintragErstellen}</button>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ausbildung: state.ausbildung,
    texte: state.i18n.texte.arbeitsraum
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ArbeitsraumListe);
