import {encode} from "../../../lib/windows1252";

function bexioStatusZuNummer(status) {
  // string wieder in nummer umwandeln für die sortierung
  switch (status) {
    case 'draft':
      return 1;
    case 'pending':
      return 2;
    case 'partial':
      return 3;
    case 'paid':
      return 4;
    case 'canceled':
      return 5;
    case 'unpaid':
      return 6;
  }
  return 99;
}

export function downloadCsv(data, filename) {
  let dataString = data.map(zeile => zeile.join(';')).join('\n');
  let encodedString = encode(dataString, {mode: 'replacement'});
  let blob = new Blob([encodedString], { type: `text/csv;charset=windows-1252` });
  let url = window.URL.createObjectURL(blob);

  let link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export function sortiereRechnungen(rechnungen, pageSort = null) {
  const arr = [...rechnungen];

  if (pageSort) {
    switch (pageSort.name) {
      case 'person':
        arr.sort((a, b) => a.person.name.localeCompare(b.person.name));
        break;
      case 'geburtsdatum':
        arr.sort((a, b) => !a.person.geburtsdatum ? -1 : a.person.geburtsdatum.localeCompare(b.person.geburtsdatum));
        break;
      case 'strasse':
        arr.sort((a, b) => {
          if (a.adresse && b.adresse) {
            return a.adresse.strasse.localeCompare(b.adresse.strasse);
          }
          return 0;
        });
        break;
      case 'plz':
        arr.sort((a, b) => {
          if (a.adresse && b.adresse) {
            return b.adresse.plz - a.adresse.plz;
          }
          return 0;
        });
        break;
      case 'ort':
        arr.sort((a, b) => {
          if (a.adresse && b.adresse) {
            return a.adresse.ort.localeCompare(b.adresse.ort)
          }
          return 0;
        });
        break;
      case 'betragFreiwillig':
        arr.sort((a, b) => {
          if (a.betragFreiwillig === b.betragFreiwillig) return 0;
          if (a.betragFreiwillig) return -1;
          if (b.betragFreiwillig) return 1;
          return 1;
        });
        break;
      case 'leistung':
        arr.sort((a, b) => a.leistung.bezeichnung.localeCompare(b.leistung.bezeichnung));
        break;
      case 'betrag':
        arr.sort((a, b) => Number.parseFloat(b.betrag) - Number.parseFloat(a.betrag));
        break;
      case 'konto':
        arr.sort((a, b) => b.konto - a.konto);
        break;
      case 'restbetrag':
        arr.sort((a, b) => Number.parseFloat(a.bexio.restbetrag) - Number.parseFloat(b.bexio.restbetrag));
        break;
      case 'bexio':
        arr.sort((a, b) => bexioStatusZuNummer(b.bexioStatus) - bexioStatusZuNummer(a.bexioStatus));
        break;
      default:
        // alphabetisch als default
        arr.sort((a, b) => a.person.name.localeCompare(b.person.name));
        break;
    }

    // rechnungen ohne adresse immer zuoberst anzeigen, egal wie sortiert wird -> adresse muss vorhanden sein für bexio sync!
    arr.sort((a, b) => {
      if (!a.adresse) return pageSort.desc ? -1 : 1;
      if (!b.adresse) return pageSort.desc ? 1 : -1;
      return 0;
    });

    if (!pageSort.desc) {
      arr.reverse();
    }
  }

  return arr;
}

export function filtereRechnungen(rechnungen, filterValues = null, bexioResourceFehler = null) {
  if (filterValues === null) {
    return rechnungen;
  }

  let arr = [...rechnungen];

  if (filterValues.person !== null) {
    let searchPerson = filterValues.person.toLocaleLowerCase();
    arr = arr.filter(r => r.person.name.toLocaleLowerCase().indexOf(searchPerson) >= 0);
  }
  if (filterValues.geschlecht !== null) {
    arr = arr.filter(r => r.person.geschlecht === filterValues.geschlecht);
  }
  if (filterValues.strasse !== null) {
    let searchStrasse = filterValues.strasse.toLocaleLowerCase();
    arr = arr.filter(r => r.adresse.strasse.toLocaleLowerCase().indexOf(searchStrasse) >= 0);
  }
  if (filterValues.plz !== null) {
    arr = arr.filter(r => r.adresse.plz.toString().indexOf(filterValues.plz) >= 0);
  }
  if (filterValues.ort !== null) {
    let searchOrt = filterValues.ort.toLocaleLowerCase();
    arr = arr.filter(r => r.adresse.ort.toLocaleLowerCase().indexOf(searchOrt) >= 0);
  }
  if (filterValues.betragFreiwillig !== null) {
    arr = arr.filter(r => r.betragFreiwillig === filterValues.betragFreiwillig);
  }
  if (filterValues.leistung !== null) {
    arr = arr.filter(r => r.leistung.id === filterValues.leistung);
  }
  if (filterValues.betragVon !== null) {
    const betrag = Number.parseFloat(filterValues.betragVon);
    arr = arr.filter(r => Number.parseFloat(r.betrag) >= betrag);
  }
  if (filterValues.betragBis !== null) {
    const betrag = Number.parseFloat(filterValues.betragBis);
    arr = arr.filter(r => Number.parseFloat(r.betrag) <= betrag);
  }
  if (filterValues.bexio !== null) {
    if (Array.isArray(filterValues.bexio) && filterValues.bexio.length > 0) {
      arr = arr.filter(r => filterValues.bexio.includes(r.bexioStatus));
    }
  }
  if (filterValues.fehlerInBexio === true && bexioResourceFehler && bexioResourceFehler.length > 0) {
    arr = arr.filter(r => {
      return bexioResourceFehler.findIndex(rf => {
        switch (rf.referenceTyp) {
          case 'rechnung':
            return rf.referenceId === r.id;
          case 'person':
            return rf.referenceId === r.person.id;
          case 'spieler_sponsor':
            if (r.sponsorenlaufInfo) {
              return rf.referenceId === r.person.spielerSponsorId;
            }
            return false;
          default:
            return false;
        }
      }) >= 0;
    });
  }
  if (filterValues.team !== null) {
    const teamId = Number.parseInt(filterValues.team, 10);
    arr = arr.filter(r => r.teamIds.includes(teamId));
  }
  if (filterValues.ohneTeam !== null) {
    if (filterValues.ohneTeam === true) {
      arr = arr.filter(r => r.istSpieler && r.teamIds.length === 0);
    } else {
      arr = arr.filter(r => r.istSpieler && r.teamIds.length > 0);
    }
  }
  if (filterValues.spielerGesperrt !== null) {
    arr = arr.filter(r => r.spielerInfo && r.spielerInfo.gesperrt === filterValues.spielerGesperrt);
  }
  if (filterValues.qualifikationKleinerAls) {
    arr = arr.filter(r => r.spielerInfo && r.spielerInfo.qualifiziertAb && Date.parse(r.spielerInfo.qualifiziertAb) < filterValues.qualifikationKleinerAls);
  }

  if (filterValues.ohneAdresse === true) {
    arr = arr.filter(r => r.adresse === null);
  }

  // nur rechnungen mit mehrfach vorkommenden adressen anzeigen: zuletzt filtern, damit anzahl rechnungen hier bereits minimal ist.
  if (filterValues.doppelteAdressen === true) {
    const map = new Map();
    arr.filter(r => r.adresse != null).forEach(rechnung => {
      const key = `${rechnung.person.nachname}-${rechnung.adresse.strasse}-${rechnung.adresse.plz}-${rechnung.adresse.ort}`;
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [rechnung]);
      } else {
        collection.push(rechnung);
      }
    });
    arr = [...map.values()]
      .filter(entry => entry.length > 1)
      .flat();
  }

  // rechnungsempfänger filtern wo person mit rolle in verein mit derselben adresse existiert
  arr.forEach(r => delete r.personenInVerein); // custom property zuerst generell entfernen
  if (filterValues.personenInVerein) {
    if (filterValues.personenInVerein.length > 0) {
      arr.forEach(r => {
        let liste = filterValues.personenInVerein.filter(p => r.person.id != p.id && r.person.nachname === p.nachname && r.adresse.strasse === p.strasse && r.adresse.plz === p.plz && r.adresse.ort === p.ort);
        if (liste.length > 0) {
          r.personenInVerein = liste; // rechnungen ergänzen mit property -> wird dann auch zur anzeige im UI benutzt 
        }
      });
    }
    // jetzt einfacher filter anwenden: nur einträge anzeigen mit property "personenInVerein"
    arr = arr.filter(r => !!r.personenInVerein);
  }

  return arr;
}

export function filtereRechnungskontrollen(rechnungen, filterValues = null) {
  if (filterValues === null) {
    return rechnungen;
  }

  // rechnungsfilter anwenden
  let arr = filtereRechnungen(rechnungen, filterValues);

  // filter nur für rechnungskontrolle anwenden
  if (filterValues.mahnstufe !== null) {
    arr = arr.filter(r => r.bexio.mahnstufe == filterValues.mahnstufe);
  }
  if (filterValues.rechnungsdatumVon !== null) {
    arr = arr.filter(r => new Date(r.bexio.freigabedatum) >= filterValues.rechnungsdatumVon);
  }
  if (filterValues.rechnungsdatumBis !== null) {
    arr = arr.filter(r => new Date(r.bexio.freigabedatum) <= filterValues.rechnungsdatumBis);
  }
  if (filterValues.mahndatumVon !== null) {
    arr = arr.filter(r => new Date(r.bexio.mahndatum) >= filterValues.mahndatumVon);
  }
  if (filterValues.mahndatumBis !== null) {
    arr = arr.filter(r => new Date(r.bexio.mahndatum) <= filterValues.mahndatumBis);
  }

  return arr;
}

export function filtereSponsorenlauf(sponsorenlaeufe, filterValues, spielerkategorien, sponsoren = null) {
  if (filterValues === null) {
    return sponsorenlaeufe;
  }

  let arr = [...sponsorenlaeufe];

  if (filterValues.person !== null) {
    let searchString = filterValues.person.toLocaleLowerCase();
    arr = arr.filter(r => r.person.name.toLocaleLowerCase().indexOf(searchString) >= 0);
  }
  
  if (sponsoren && filterValues.sponsorVorhanden != null) {
    if (filterValues.sponsorVorhanden === true) {
      arr = arr.filter(r => sponsoren.find(s => s.spielerId === r.spielerId) !== undefined);
    } else {
      arr = arr.filter(r => sponsoren.find(s => s.spielerId === r.spielerId) === undefined);
    }
  }
  
  if (filterValues.geschlecht !== null) {
    arr = arr.filter(r => r.person.geschlecht === filterValues.geschlecht);
  }
  let kat = spielerkategorien.find(k => k.id === filterValues.spielerkategorie);
  if (kat) {
    let von  = new Date(kat.von);
    let bis  = new Date(kat.bis);
    arr = arr.filter(r => {
      let datum = new Date(r.person.geburtsdatum);
      return datum >= von && datum <= bis;
    });
  }
  if (filterValues.betragVon !== null) {
    const betrag = Number.parseFloat(filterValues.betragVon);
    arr = arr.filter(r => Number.parseFloat(r.pflichtbeitrag) >= betrag);
  }
  if (filterValues.betragBis !== null) {
    const betrag = Number.parseFloat(filterValues.betragBis);
    arr = arr.filter(r => Number.parseFloat(r.pflichtbeitrag) <= betrag);
  }
  if (filterValues.team !== null) {
    const teamId = Number.parseInt(filterValues.team, 10);
    arr = arr.filter(r => r.teamId === teamId);
  }
  if (filterValues.ohneTeam !== null) {
    if (filterValues.ohneTeam === true) {
      arr = arr.filter(r => r.teamId == null);
    } else {
      arr = arr.filter(r => r.teamId > 0);
    }
  }
  if (filterValues.spielerGesperrt !== null) {
    arr = arr.filter(r => r.gesperrt === filterValues.spielerGesperrt);
  }
  if (filterValues.abmeldenMarkiert !== null) {
    arr = arr.filter(r => r.abmeldenMarkiert === filterValues.abmeldenMarkiert);
  }

  return arr;
}

export function filtereSponsorenlaufSponsoren(sponsoren, filterValues, spielerkategorien, spieler) {
  let arr = [...sponsoren];

  if (filterValues === null) {
    return arr;
  }
  
  // wenn spieler gefiltert sind nur noch die sponsoren dieser spieler anzeigen:
  let spielerFilterValues = {...filterValues, betragVon: null, betragBis: null}; // betrag gilt für sponsor, nicht spieler
  let gefilterteSpieler = filtereSponsorenlauf(spieler, spielerFilterValues, spielerkategorien);
  if (gefilterteSpieler.length != spieler.length) {
    let spielerIds = gefilterteSpieler.map(s => s.spielerId);
    arr = arr.filter(s => spielerIds.includes(s.spielerId));
  }
  
  if (filterValues.sponsor !== null) {
    let searchString = filterValues.sponsor.toLocaleLowerCase();
    arr = arr.filter(s => s.name.toLocaleLowerCase().indexOf(searchString) >= 0);
  }

  if (filterValues.betragVon !== null) {
    const betrag = Number.parseFloat(filterValues.betragVon);
    arr = arr.filter(s => Number.parseFloat(s.betragTotal) >= betrag);
  }
  if (filterValues.betragBis !== null) {
    const betrag = Number.parseFloat(filterValues.betragBis);
    arr = arr.filter(s => Number.parseFloat(s.betragTotal) <= betrag);
  }

  return arr;
}
