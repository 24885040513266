import {AufgabenFilter} from "./constants";

export function formatiereDatum(datum) {
  if (datum) {
    return datum.toString('dd.MM.yyyy');
  }
  return '';
}
export function formatiereDatumZeit(datum) {
  if (datum) {
    if (datum.getMinutes() === 0 && datum.getHours() === 0) {
      return formatiereDatum(datum);
    } else {
      return datum.toString('dd.MM.yyyy HH:mm');
    }
  }
  return '';
}
export function formatiereZeit(datum) {
  if (datum) {
    return datum.toString('HH:mm');
  }
  return '';
}
export function sortierePersonName(a, b) {
  return a.name.localeCompare(b.name);
}
export function sortiereTeam(a, b) {
  const vonDiff = a.von - b.von;
  if (vonDiff === 0) {
    const bisDiff = a.bis - b.bis;
    if (bisDiff === 0) {
      return sortierePersonName(a.experte, b.experte);
    } else {
      return bisDiff;
    }
  } else {
    return vonDiff;
  }
}
export function ersetzeStringMitDatum(objektMitDatum, feldnamen) {
  if (objektMitDatum) {
    if (Array.isArray(feldnamen)) {
      feldnamen.forEach(name => {
        if (typeof (objektMitDatum[name]) === 'string') {
          objektMitDatum[name] = new Date(objektMitDatum[name]);
        }
      });
    } else {
      if (typeof (objektMitDatum[feldnamen]) === 'string') {
        objektMitDatum[feldnamen] = new Date(objektMitDatum[feldnamen]);
      }
    }
  }
  return objektMitDatum;
}
export function groupBy (list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
export function filtereAufgaben(aufgaben, filter) {
  let teilnehmerAufgaben = [];
  if (filter.length > 0) {
    if(filter.includes(AufgabenFilter.offen)) {
      teilnehmerAufgaben = teilnehmerAufgaben.concat(aufgaben.filter(a => !a.kommentarAbgeschlossen));
    }
    if(filter.includes(AufgabenFilter.abgelaufen)) {
      const jetzt = new Date();
      teilnehmerAufgaben = teilnehmerAufgaben.concat(aufgaben.filter(a => !a.kommentarAbgeschlossen && a.bis < jetzt));
    }
    if(filter.includes(AufgabenFilter.abgeschlossenTeilnehmer)) {
      teilnehmerAufgaben = teilnehmerAufgaben.concat(aufgaben.filter(a => a.kommentarAbgeschlossen));
    }
    if(filter.includes(AufgabenFilter.bewertungOffen)) {
      teilnehmerAufgaben = teilnehmerAufgaben.concat(aufgaben.filter(a => a.kommentarAbgeschlossen && !a.beurteilungAbgeschlossen));
    }
  } else {
    teilnehmerAufgaben = [...aufgaben];
  }
  return teilnehmerAufgaben;
}
