
const MELDUNG_SETZEN = 'MELDUNG_SETZEN';
const MELDUNG_ENTFERNEN = 'MELDUNG_ENTFERNEN';

export function meldungError(meldung) {
  return (dispatch) => {
    dispatch({ type: MELDUNG_SETZEN, meldung: { level: 'error', text: meldung } });
  }
}
export function meldungWarning(meldung) {
  return (dispatch) => {
    dispatch({ type: MELDUNG_SETZEN, meldung: { level: 'warning', text: meldung } });
  }
}
export function meldungInfo(meldung) {
  return (dispatch) => {
    dispatch({ type: MELDUNG_SETZEN, meldung: { level: 'info', text: meldung } });
  }
}
export function meldungEntfernen(meldung) {
  return (dispatch) => {
    dispatch({ type: MELDUNG_ENTFERNEN, meldung: meldung });
  }
}

function meldungenReducer(state = [], action) {
  switch (action.type) {
    case MELDUNG_SETZEN:
      return [...state, action.meldung];
    case MELDUNG_ENTFERNEN:
      return state.filter(m => m!== action.meldung);
    default:
      return state;
  }
}

export default meldungenReducer;
