import React, {useContext, useState} from 'react'
import {Kontext} from "../index";
import Icon from './AnwesenheitIcon'
import Dropdown from "./Dropdown";
import {eintraegeAbwesendDelete, eintraegeAbwesendUpdate} from "../lib/api";

const SpielerEintraege = (props) => {
  const eintraege = props.eintraege;
  const context = useContext(Kontext);
  const [grundSpiel, setGrundSpiel] = useState(null);
  const [grundTraining, setGrundTraining] = useState(null);
  const [grundTurnier, setGrundTurnier] = useState(null);
  const aktiviertTypen = eintraege.filter(eintrag => eintrag.aktiviert === true).map(eintrag => eintrag.typ).filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

  const handleAktiviertChange = (eintrag) => {
    eintraegeZusammenfuehren({eintraege: [eintrag]});
  };

  const handleGrundChange = (value, typ) => {
    switch (typ) {
      case 'spiel':
        setGrundSpiel(value);
        break;
      case 'training':
        setGrundTraining(value);
        break;
      case 'turnier':
        setGrundTurnier(value);
        break;
    }
  };

  const onSpeichernClick = () => {
    const aktiveEintraege = eintraege.filter(eintrag => eintrag.aktiviert === true);
    const gruende = {
      spiel: grundSpiel,
      training: grundTraining,
      turnier: grundTurnier
    };
    eintraegeAbwesendUpdate(context.api, aktiveEintraege, gruende, props.spielerId, eintraegeZusammenfuehren);
    setGrundSpiel(null);
    setGrundTraining(null);
    setGrundTurnier(null);
  };

  const onEntfernenClick = () => {
    const aktiveEintraege = eintraege.filter(eintrag => eintrag.aktiviert === true);
    eintraegeAbwesendDelete(context.api, aktiveEintraege, props.spielerId, eintraegeZusammenfuehren);
  };

  const eintraegeZusammenfuehren = (resultat) => {
    const liste = eintraege.map((eintrag) => {
      const aktualisierterEintrag = resultat.eintraege.find(e => e.typ === eintrag.typ && e.id === eintrag.id);
      if (aktualisierterEintrag) {
        if (aktualisierterEintrag.errors) {
          aktualisierterEintrag.errors.forEach(err => {
            neueFlashMessage(err.text, err.typ).anzeigen();
          });
          aktualisierterEintrag.errors = undefined;
        }

        return aktualisierterEintrag;
      } else {
        return eintrag;
      }
    });
    props.onEintraegeChanged(props.spielerId, liste);
  };

  return (
    <>
      <div className="zeitlinie">
        <span/>
        {
          eintraege.map((eintrag) => {
            return (
              <Icon key={eintrag.typ + eintrag.id} eintrag={eintrag} onAktiviertChange={handleAktiviertChange}/>
            );
          })
        }
        <span/>
      </div>
      {aktiviertTypen.length > 0 &&
        <div className="zeitlinie edit">
          <span/>
          <div>
            {aktiviertTypen.includes('spiel') &&
              <>
                <label>{context.texte.typ.spiel}:</label>
                <Dropdown onValueChange={handleGrundChange} typ="spiel" includeBlank={true}
                          options={context.abwesenheitGruende.spiel}/>
                <br/>
              </>
            }
            {aktiviertTypen.includes('training') &&
              <>
                <label>{context.texte.typ.training}:</label>
                <Dropdown onValueChange={handleGrundChange} typ="training" includeBlank={true}
                          options={context.abwesenheitGruende.training}/>
                <br/>
              </>
            }
            {aktiviertTypen.includes('turnier') &&
              <>
                <label>{context.texte.typ.turnier}:</label>
                <Dropdown onValueChange={handleGrundChange} typ="turnier" includeBlank={true}
                          options={context.abwesenheitGruende.training}/>
                <br/>
              </>
            }
            <button className="btn btn-primary" onClick={onEntfernenClick}>{context.texte.entfernen}</button>
            &nbsp;
            <button className="btn btn-danger pull-right" onClick={onSpeichernClick}>{context.texte.speichern}</button>
          </div>
          <span/>
        </div>
      }
    </>
  );
};
export default SpielerEintraege
