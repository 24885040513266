const FEHLERMELDUNG_CREATE = 'FEHLERMELDUNG_CREATE';
const FEHLERMELDUNG_DELETE = 'FEHLERMELDUNG_DELETE';

export function fehlermeldungCreate(fehlermeldungen) {
  return (dispatch) => {
    if (Array.isArray(fehlermeldungen) && fehlermeldungen.length > 0) {
      dispatch({type: FEHLERMELDUNG_CREATE, fehlermeldungen: fehlermeldungen});
    }
  };
}

export function fehlermeldungDelete(index) {
  return (dispatch) => {
    dispatch({type: FEHLERMELDUNG_DELETE, index: index});
  };
}

function fehlermeldungReducer(state = [], action) {
  switch (action.type) {
    case FEHLERMELDUNG_CREATE:
      const array = [...state];
      action.fehlermeldungen.forEach(f => array.push(f));
      return array;
    case FEHLERMELDUNG_DELETE:
      const temp = [...state];
      temp.splice(action.index, 1);
      return temp;
    default:
      return state;
  }
}

export default fehlermeldungReducer;
