import {executeRequest} from "../lib/api";
import {ersetzeStringMitDatum, sortiereTeam} from "../lib/formatierungen";
import {fehlermeldungCreate} from "./fehlermeldungReducer";
import {teamZuteilungenEntfernen, teamZuteilungUpdate} from "./lehrerTeamTeilnehmerReducer";

const TEAMS_UPDATE = 'TEAMS_UPDATE';
const TEAMS_DELETE = 'TEAMS_DELETE';

export function teamSpeichern(klassenlehrer, zusatzdaten) {
  return (dispatch, getState) => {
    const state = getState();
    const daten = {
      kontext: 'team_update',
      lehrerId: klassenlehrer.id,
    };
    if (zusatzdaten.teamId) {
      daten['teamId'] = zusatzdaten.teamId;
    } else {
      daten['ganzeKlasse'] = zusatzdaten.ganzeKlasse;
    }
    if (zusatzdaten.bezeichnung) daten['bezeichnung'] = zusatzdaten.bezeichnung;
    if (zusatzdaten.von) daten['von'] = zusatzdaten.von.toISOString();
    if (zusatzdaten.bis) daten['bis'] = zusatzdaten.bis.toISOString();
    if (zusatzdaten.programmBlockId) daten['programmBlockId'] = zusatzdaten.programmBlockId;

    return executeRequest(state.api.update, state.api.token, 'PUT', daten, (result) => {
      const team = ersetzeStringMitDatum(result.data.team, ['von', 'bis']);
      dispatch({type: TEAMS_UPDATE, team: team});
      if (result.data.teamTeilnehmer) {
        dispatch(teamZuteilungUpdate(result.data.teamTeilnehmer));
      }
    });
  };
}

export function teamDelete(team) {
  return (dispatch, getState) => {
    const api = getState().api;
    const daten = {
      kontext: 'team_delete',
      teamId: team.id,
    };
    return executeRequest(api.update, api.token, 'PUT', daten, (result) => {
      if (result.ok) {
        teamZuteilungenEntfernen(team);
        dispatch({type: TEAMS_DELETE, team: team});
      } else {
        dispatch(fehlermeldungCreate(result.data.errors));
      }
    });
  };
}

function teamsReducer(state = [], action) {
  switch (action.type) {
    case TEAMS_UPDATE:
      const team = action.team;
      return [
        ...state.filter(t => t.id !== team.id),
        team
      ].sort(sortiereTeam);
    case TEAMS_DELETE:
      return state.filter(tt => tt.id !== action.team.id);
    default:
      return state;
  }

}

export default teamsReducer;
