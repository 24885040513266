import React from "react";
import ControlGroup from "./ControlGroup";

const Kommunikation = ({person, texte, onWertChange}) => {
  // INFO: natürliche / juristische person muss unterschieden werden

  return (
    <section>
      <h3 className="page-section">{texte.titel}</h3>
      <div className="row-fluid">
        <div className="span6">
          <ControlGroup labelText={person.natuerlich ? texte.telefon_privat : texte.telefon_kontaktperson} htmlFor="telefon_privat" errors={person.errors.telefon_privat}>
            <input type="text" id="telefon_privat" placeholder="+41 (79) xxxxxxx" value={person.telefon_privat || ''} onChange={event => onWertChange('telefon_privat', event.currentTarget.value)}/>
          </ControlGroup>
          <ControlGroup labelText={texte.telefon_geschaeft_direkt} htmlFor="telefon_geschaeft_direkt" errors={person.errors.telefon_geschaeft_direkt}>
            <input type="text" id="telefon_geschaeft_direkt" placeholder="+41 (79) xxxxxxx" value={person.telefon_geschaeft_direkt || ''} onChange={event => onWertChange('telefon_geschaeft_direkt', event.currentTarget.value)}/>
          </ControlGroup>
          <ControlGroup labelText={texte.telefon_geschaeft_zentrale} htmlFor="telefon_geschaeft_zentrale" errors={person.errors.telefon_geschaeft_zentrale}>
            <input type="text" id="telefon_geschaeft_zentrale" placeholder="+41 (79) xxxxxxx" value={person.telefon_geschaeft_zentrale || ''} onChange={event => onWertChange('telefon_geschaeft_zentrale', event.currentTarget.value)}/>
          </ControlGroup>
          <ControlGroup labelText={person.natuerlich ? texte.email : texte.email_offiziell} htmlFor="email" errors={person.errors.email}>
            <input type="text" id="email" value={person.email || ''} onChange={event => onWertChange('email', event.currentTarget.value)}/>
            {person.natuerlich &&
            <span className="simple_form_hint"><i className="icon-question-sign"/>&nbsp;{texte.als_login_verwendbar}</span>
            }
          </ControlGroup>
          <ControlGroup labelText={texte.email_geschaeft} htmlFor="email_geschaeft" errors={person.errors.email_geschaeft}>
            <input type="text" id="email_geschaeft" value={person.email_geschaeft || ''} onChange={event => onWertChange('email_geschaeft', event.currentTarget.value)}/>
            {person.natuerlich &&
            <span className="simple_form_hint"><i className="icon-question-sign"/>&nbsp;{texte.als_login_verwendbar}</span>
            }
          </ControlGroup>
          {person.natuerlich &&
          <>
            <ControlGroup labelText={texte.email_gesetzlicher_vertreter1} htmlFor="email_gesetzlicher_vertreter1" errors={person.errors.email_gesetzlicher_vertreter1}>
              <input type="text" id="email_gesetzlicher_vertreter1" value={person.email_gesetzlicher_vertreter1 || ''} onChange={event => onWertChange('email_gesetzlicher_vertreter1', event.currentTarget.value)}/>
            </ControlGroup>
            <ControlGroup labelText={texte.email_gesetzlicher_vertreter2} htmlFor="email_gesetzlicher_vertreter2" errors={person.errors.email_gesetzlicher_vertreter2}>
              <input type="text" id="email_gesetzlicher_vertreter2" value={person.email_gesetzlicher_vertreter2 || ''} onChange={event => onWertChange('email_gesetzlicher_vertreter2', event.currentTarget.value)}/>
            </ControlGroup>
          </>
          }
          <ControlGroup labelText={texte.homepage} htmlFor="homepage" errors={person.errors.homepage}>
            <input type="text" id="homepage" value={person.homepage || ''} onChange={event => onWertChange('homepage', event.currentTarget.value)}/>
          </ControlGroup>
        </div>
        <div className="span6">
          <ControlGroup labelText={texte.telefon_mobil} htmlFor="telefon_mobil" errors={person.errors.telefon_mobil}>
            <input type="text" id="telefon_mobil" placeholder="+41 (79) xxxxxxx" value={person.telefon_mobil || ''} onChange={event => onWertChange('telefon_mobil', event.currentTarget.value)}/>
          </ControlGroup>
          <ControlGroup labelText={texte.telefon_mobil_zusatz} htmlFor="telefon_mobil_zusatz" errors={person.errors.telefon_mobil_zusatz}>
            <input type="text" id="telefon_mobil_zusatz" value={person.telefon_mobil_zusatz || ''} onChange={event => onWertChange('telefon_mobil_zusatz', event.currentTarget.value)}/>
          </ControlGroup>
          {person.natuerlich ||
          <ControlGroup labelText={texte.fax_geschaeft} htmlFor="fax_geschaeft" errors={person.errors.fax_geschaeft}>
            <input type="text" id="fax_geschaeft" value={person.fax_geschaeft || ''} onChange={event => onWertChange('fax_geschaeft', event.currentTarget.value)}/>
          </ControlGroup>
          }
          <ControlGroup labelText={texte.telefon_gesetzlicher_vertreter1} htmlFor="telefon_gesetzlicher_vertreter1" errors={person.errors.telefon_gesetzlicher_vertreter1}>
            <input type="text" id="telefon_gesetzlicher_vertreter1" placeholder="+41 (79) xxxxxxx" value={person.telefon_gesetzlicher_vertreter1 || ''} onChange={event => onWertChange('telefon_gesetzlicher_vertreter1', event.currentTarget.value)}/>
          </ControlGroup>
          <ControlGroup labelText={texte.telefon_gesetzlicher_vertreter1_zusatz} htmlFor="telefon_gesetzlicher_vertreter1_zusatz" errors={person.errors.telefon_gesetzlicher_vertreter1_zusatz}>
            <input type="text" id="telefon_gesetzlicher_vertreter1_zusatz" value={person.telefon_gesetzlicher_vertreter1_zusatz || ''} onChange={event => onWertChange('telefon_gesetzlicher_vertreter1_zusatz', event.currentTarget.value)}/>
          </ControlGroup>
          <ControlGroup labelText={texte.telefon_gesetzlicher_vertreter2} htmlFor="telefon_gesetzlicher_vertreter2" errors={person.errors.telefon_gesetzlicher_vertreter2}>
            <input type="text" id="telefon_gesetzlicher_vertreter2" placeholder="+41 (79) xxxxxxx" value={person.telefon_gesetzlicher_vertreter2 || ''} onChange={event => onWertChange('telefon_gesetzlicher_vertreter2', event.currentTarget.value)}/>
          </ControlGroup>
          <ControlGroup labelText={texte.telefon_gesetzlicher_vertreter2_zusatz} htmlFor="telefon_gesetzlicher_vertreter2_zusatz" errors={person.errors.telefon_gesetzlicher_vertreter2_zusatz}>
            <input type="text" id="telefon_gesetzlicher_vertreter2_zusatz" value={person.telefon_gesetzlicher_vertreter2_zusatz || ''} onChange={event => onWertChange('telefon_gesetzlicher_vertreter2_zusatz', event.currentTarget.value)}/>
          </ControlGroup>
        </div>
      </div>
    </section>
  );
};
export default Kommunikation;
