import React, {useEffect, useState} from "react";
import "../shared/shared-components";

const ModalerDialog = ({children}) => {
  const [cssFade, setCssFade] = useState('');
  
  useEffect(() => {
    setCssFade('fade-in');
  }, []);
  
  return (
    <>
      <div className={`modalerBackdrop ${cssFade}`}/>
      <div className="modalerDialog">
        {children}
      </div>
    </>
  );
}

export default ModalerDialog;
