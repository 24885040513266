import React from "react";
import {connect} from "react-redux";
import {berichtSetzeWert} from "../state/berichtReducer";
import FormRadioListeHorizontal from "./FormRadioListeHorizontal";
import FormTextarea from "./FormTextarea";

const CoachingPoints = ({bericht, coaching_points, setzeWert, texte}) => {
  let readonly = bericht.bBerichtDefinitiv;

  return (
    <>
      {Object.keys(coaching_points).map(key => {
        const titel = `${key}_titel`;
        const kommentar = `${key}_kommentar`.replace("_sra", "");
        return <React.Fragment key={key}>
          <table className="table table-striped" key={key}>
            <thead>
            <tr key={titel}>
              <th><h2 className={"kategorie"} style={{fontWeight: 'normal', margin: 0}}>{texte[titel]}</h2></th>
              <th><i className="icon-thumbs-up"/></th>
              <th><i className="icon-ban-circle"/></th>
              <th><i className="icon-thumbs-down"/></th>
            </tr>
            </thead>
            <tbody>
            {coaching_points[key].map(coachingPoint => {
              return (
                <tr key={coachingPoint}>
                  <th>{texte[coachingPoint]}</th>
                  <FormRadioListeHorizontal key={coachingPoint} name={coachingPoint} value={bericht[coachingPoint]} readonly={readonly} onRadioChange={(name, value) => setzeWert(name, value)}/>
                </tr>
              );
            })}
            </tbody>
          </table>
          <table className="table table-striped" key={kommentar}>
            <tbody>
            <tr key={kommentar}>
              <th>{texte[kommentar]}</th>
              <td><FormTextarea name={kommentar} value={bericht[kommentar]} readonly={readonly} onTextChange={(name, value) => setzeWert(name, value)}/></td>
            </tr>
            </tbody>
          </table>
        </React.Fragment>;
      })}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bericht: state.bericht,
    coaching_points: state.listen.coaching_points,
    listen: state.listen,
    texte: state.i18n.texte.coaching_points,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setzeWert: (name, value) => {
      dispatch(berichtSetzeWert(name, value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoachingPoints);

