// @flow

import type {AppState} from "../../../state/stateTypes";
import type {Dispatch} from "../../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

const EintragLoeschen = (props: { i18n: any, aktiv: boolean, loeschenKlick: (any) => void }) => {
  let buttonAktiv = <i className="icon-trash" onClick={props.loeschenKlick} style={{cursor: "pointer"}}/>;
  let buttonInaktiv = <i className="icon-trash" style={{opacity: '0.3'}}/>;
  return props.aktiv ? buttonAktiv : buttonInaktiv;
};

const mapStateToProps = (state: AppState) => {
  return {
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EintragLoeschen);
