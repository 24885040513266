// @flow

import type { AppState, EinsatzState } from "../../state/stateTypes";
import type { Dispatch } from "../../state/actionTypes";

import React from "react";
import { connect } from "react-redux";

import Aktionen from "./Aktionen";
import Validierung from "./Validierung";
import { EINSATZ_ROLLE } from "../../state/modules/einsatz";

const Abschluss = ({ einsatz, i18n }: { einsatz: EinsatzState, i18n: any }) => {
  const validTypes = ["spiel", "turnier", "training"];

  if (validTypes.includes(einsatz.typ)) {
    return (
      <div className="tab-pane">
        {einsatz.einsatzRolleId !== EINSATZ_ROLLE.scout_video && (
          <React.Fragment>
            <h3>{i18n.subtitel.validierung}</h3>
            <Validierung/>
          </React.Fragment>
        )}
        <Aktionen/>
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Abschluss);
