import React, {useEffect, useMemo, useRef, useState} from "react";

const Icon = ({ isOpen }) => {
  return (
    <svg viewBox="0 0 24 24" width="18" height="18" stroke="#444" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round" className={isOpen ? 'translate' : ''}>
      <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
  );
};
const CloseIcon = () => {
  return (
    <svg viewBox="0 0 24 24" width="14" height="14" stroke="#fff" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
      <line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  );
};
const ClearIcon = ({ onClick }) => {
  return (
    <svg onClick={onClick} viewBox="0 0 24 24" width="16" height="16" stroke="#555" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
      <line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  );
};

const SimpleSelect = ({ placeholder, options, value, isMulti, showClearAll = true, onChange}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const inputRef = useRef();

  const hasSelectedValue = useMemo(() => {
    if (!options || options.length === 0) {
      return false;
    }
    if (isMulti) {
      return selectedValue && selectedValue.length > 0;
    }
    return !!selectedValue;
  }, [options, selectedValue]);

  useEffect(() => {
    // alle clicks ausserhalb dieser komponente schliessen das menu (falls offen)
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  const handleClearClick = (ev) => {
    ev.stopPropagation();
    let val = isMulti ? [] : null;
    setSelectedValue(val);
    onChange(val);
  };

  const handleInputClick = (_ev) => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return placeholder;
    }
    if (isMulti) {
      return (
        <div className="tags">
          {
            options
              .filter(o => selectedValue.includes(o.value))
              .map(option => (
                <div key={option.value} className="tag-item">
                  {option.label}
                  <span onClick={() => onTagRemove(option.value)} className="tag-close" ><CloseIcon /></span>
                </div>
              ))
          }
        </div>
      );
    }
    return options.find(o => o.value === selectedValue)?.label;
  };

  const removeValue = (value) => {
    return selectedValue.filter((val) => val !== value);
  };

  const onTagRemove = (value) => {
    let newValue = removeValue(value);
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const onItemClick = (ev, value) => {
    ev.stopPropagation(); // klick nicht bubbeln damit das menu nicht geschlossen wird
    let newValue = value;
    if (isMulti) {
      // de- / select item (toggle mit klick):
      if (selectedValue.includes(value)) {
        newValue = removeValue(value);
      } else {
        newValue = [...selectedValue, value];
      }
    } else {
      setShowMenu(false); // single select schliesst menu bei auswahl
    }
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const isSelected = (value) => {
    if (isMulti) {
      return selectedValue.includes(value);
    }
    return selectedValue === value;
  };

  return (
    <div ref={inputRef} className="simple-select">
      <div onClick={handleInputClick} className="input">
        <div className={`selected-value ${!selectedValue || selectedValue.length === 0 ? 'placeholder' : ''}`}>{getDisplay()}</div>
        <div className="tool">
          {showClearAll && hasSelectedValue &&
            <>
              <ClearIcon onClick={handleClearClick} />
              <span className="separator" />
            </>
          }
          <Icon isOpen={showMenu} />
        </div>
      </div>
      {showMenu && (
        <div className="simple-menu">
          {
            options.map((option) => (
              <div onClick={(ev) => onItemClick(ev, option.value)} key={option.value} className={`item ${isSelected(option.value) && "selected"}`} >
                {option.label}
              </div>
            ))
          }
        </div>
      )}
    </div>
  );
}

export default SimpleSelect;
