import {bibliothekReferenzSetzen, sortiereTeamZuteilungen} from "./teamTeilnehmerReducer";
import {ersetzeStringMitDatum, sortierePersonName} from "../lib/formatierungen";
import {sortiereAufgabenZuteilungen} from "./teilnehmerAufgabenReducer";

function preloaderFuerKlassenlehrer(options) {
  return {
    ausbildung: ersetzeStringMitDatum(options.ausbildung, ['von', 'bis']),
    experte: options.experte,
    ausbildner: options.ausbildner.map(a => {
      a.ergebnis = ersetzeStringMitDatum(a.ergebnis, ['beurteilungAbgeschlossenAm'])
      return a;
    }).sort((a, b) => sortierePersonName(a, b)),
    teams: options.teams.map(team => {
      return ersetzeStringMitDatum(team, ['von', 'bis']);
    }),
    teamTeilnehmer: options.teamTeilnehmer.map(tt => {
      ersetzeStringMitDatum(tt, ['abgeschlossenAm']);
      tt.aufgaben.forEach(aufgabe => {
        bibliothekReferenzSetzen(aufgabe, options.bibliothek);
        ersetzeStringMitDatum(aufgabe, ['von', 'bis']);
      });
      tt.aufgaben.sort(sortiereAufgabenZuteilungen);
      return tt;
    }).sort(sortiereTeamZuteilungen),
    bibliothek: options.bibliothek,
    arbeitsraumListe: options.arbeitsraumListe.map(ar => bibliothekReferenzSetzen(ar, options.bibliothek)).sort((a, b) => b.id - a.id),
    abschlussStatusListe: options.abschlussStatusListe,
    api: options.api,
    i18n: options.i18n,
    rollbar: options.rollbar,
  };
}

export default preloaderFuerKlassenlehrer;
