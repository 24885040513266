// @flow

import type {AppState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

const Aktionen = (props: {||}) => {
  return (
    <div className="form-actions">
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Aktionen);
