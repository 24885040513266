// @flow

import type {AppState, EinsatzState, NavigationState, ProtokollState, TeamState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import ProtokollEintraege from "./ProtokollEintraege";
import MoeglicheEintraege from "./MoeglicheEintraege";
import Aktionen from "./Aktionen";

const Spielprotokoll = (props: {
  navigation: NavigationState,
  einsatz: EinsatzState,
  protokoll: ProtokollState,
  teams: TeamState,
  i18n: any,
}) => {
  return (
    <div className="tab-pane">
      <h3>{props.i18n.subtitel.protokoll_eintraege}</h3>
      {props.protokoll.liste.length > 5 && <MoeglicheEintraege position={"oben"}/>}
      <ProtokollEintraege heimTeam={props.teams.heimTeam} gastTeam={props.teams.gastTeam}/>
      <MoeglicheEintraege position={"unten"}/>
      <Aktionen/>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    navigation: state.navigation,
    einsatz: state.einsatz,
    protokoll: state.protokoll,
    teams: state.teams,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Spielprotokoll);
