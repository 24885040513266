// @flow

import type {AppState, ProtokollEintrag, TeamState} from "../../../state/stateTypes";
import type {Dispatch} from "../../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

const MinuteUndTeams = (props: {
  teams: TeamState,
  eintrag: ProtokollEintrag,
  aktiv: boolean,
  minuteChange: (any) => void,
  zusatzminuteChange: (any) => void,
  heimTeamKlick: (any) => void,
  gastTeamKlick: (any) => void,
  protokollSortieren: (any) => void,
}) => {
  if (props.teams.heimTeam && props.teams.gastTeam) {
    return (
      <div style={{margin: '0 15px 0 0'}}>
        <div className="input-prepend" style={{margin: '0'}}>
          <span className="add-on">
            <i className="icon-time"/>
          </span>
          <input type="text"
                 style={{margin: '0 10px 0 0', width: '30px'}}
                 value={props.eintrag.minute || ''}
                 disabled={props.aktiv ? null : "disabled"}
                 onChange={props.minuteChange}
                 onBlur={props.protokollSortieren}/>
        </div>
        <div className="input-prepend" style={{margin: '0'}}>
        <span className="add-on">
          <i className="icon-time"/>
          <i className="icon-plus"/>
        </span>
          <input type="text"
                 placeholder="0"
                 style={{margin: '0 20px 0 0', width: '30px'}}
                 value={props.eintrag.zusatzminute || ''}
                 disabled={props.aktiv ? null : "disabled"}
                 onChange={props.zusatzminuteChange}
                 onBlur={props.protokollSortieren}/>
        </div>
        <img src={props.teams.heimTeam.logo} style={{width: '27px'}} alt={props.teams.heimTeam.name}/>
        <input type="radio" value={props.teams.heimTeam.name} style={{margin: '5px'}} checked={props.eintrag.heimTeam} disabled={props.aktiv ? null : "disabled"}
               onChange={props.heimTeamKlick}/>
        <img src={props.teams.gastTeam.logo} style={{width: '27px'}} alt={props.teams.gastTeam.name}/>
        <input type="radio" value={props.teams.gastTeam.name} style={{margin: '5px'}} checked={props.eintrag.gastTeam} disabled={props.aktiv ? null : "disabled"}
               onChange={props.gastTeamKlick}/>
      </div>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    teams: state.teams,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MinuteUndTeams);
