import {executeGet} from "../../lib/fetchHelper";
import {apiRequestAktiv} from "./api";
import {ersetzeStringMitDatum} from "../../../../lib/datenhaltung";

const MIRWALD_SPIELER_INDEX = 'MIRWALD_SPIELER_INDEX';
const MIRWALD_SPIELER_UPDATE = 'MIRWALD_SPIELER_UPDATE';
const MIRWALD_SPIELER_DELETE = 'MIRWALD_SPIELER_DELETE';

export function biologischeEntwicklungText(liste, wert) {
  const eintrag = liste.find(be => be.id === wert);
  return eintrag ? eintrag.text : '';
}

export function mirwaldAlleSpielerLaden(phase) {
  return (dispatch, getState) => {
    dispatch(apiRequestAktiv(true));
    const api = getState().api;
    return executeGet(api.mirwald, {phase: phase})
      .then(result => {
        dispatch({type: MIRWALD_SPIELER_INDEX, liste: result.data.liste});
      }).finally(() => {
        dispatch(apiRequestAktiv(false));
      });
  };
}

export function mirwaldSpielerIndex(liste) {
  return (dispatch) => {
    dispatch({type: MIRWALD_SPIELER_INDEX, liste: liste});
  };
}

export function mirwaldSpielerUpdate(mirwald) {
  return (dispatch) => {
    dispatch({type: MIRWALD_SPIELER_UPDATE, mirwald: mirwald});
  };
}

export function mirwaldSpielerEntfernen(mirwald) {
  return (dispatch) => {
    dispatch({type: MIRWALD_SPIELER_DELETE, mirwald: mirwald});
  };
}

function mirwaldReducer(state = [], action) {
  switch (action.type) {
    case MIRWALD_SPIELER_INDEX:
      return action.liste.map(model => ersetzeStringMitDatum(model, ['datum_zeit_messung', 'datum_abgeschlossen'])); // daten komplett überschreiben
    case MIRWALD_SPIELER_UPDATE:
      return [
        ...(state.filter(m => m.spielerId !== action.mirwald.spielerId || m.phase !== action.mirwald.phase)),
        ersetzeStringMitDatum(action.mirwald, ['datum_zeit_messung', 'datum_abgeschlossen'])
      ];
    case MIRWALD_SPIELER_DELETE:
      return state.filter(m => m.id !== action.mirwald.id);
    default:
      return state;
  }
}

export default mirwaldReducer;
