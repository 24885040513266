// @flow

import type {AppState, NavigationState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import {aktivenTabSetzen} from "../../state/modules/navigation";

const NavigationLink = (props: { navigation: NavigationState, onNavTabKlick: (string) => void, tabName: string, titel: string }) => {
  return (
    <li className={props.navigation.aktiverTab === props.tabName ? "active" : ""} style={{cursor: "pointer"}}>
      <a onClick={() => props.onNavTabKlick(props.tabName)}>{props.titel}</a>
    </li>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    navigation: state.navigation,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onNavTabKlick: (tabName) => {
      dispatch(aktivenTabSetzen(tabName));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationLink);
