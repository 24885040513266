import {spielgruppenSortieren} from "./modules/spielgruppen";
import {ligenSortieren} from "./modules/ligen";

function preloaderFuerFinaltag(options) {
  return {
    ligen: options.ligen.sort(ligenSortieren),
    spielgruppen: options.spielgruppen.sort(spielgruppenSortieren),
    spiele: options.spiele,
    teams: options.teams,
    spielstatusListe: options.spielstatusListe,
    status: options.status,
    saisons: options.saisons,
    api: options.api,
    i18n: options.i18n,
    rollbar: options.rollbar,
  };
}
export default preloaderFuerFinaltag;
