import {combineReducers} from 'redux';
import ausbildnerReducer from "./ausbildnerReducer";
import klassenlehrerReducer from "./klassenlehrerReducer";
import teamsReducer from "./teamsReducer";
import lehrerTeamTeilnehmerReducer from "./lehrerTeamTeilnehmerReducer";
import fehlermeldungReducer from "./fehlermeldungReducer";
import bezogeneLeistungenReducer from "./bezogeneLeistungenReducer";
import rollbarReducer from "../../../reducers/rollbar";

function dummyReducer(state = {}, action) {
  return state;
}

export default combineReducers({
  ausbildung: dummyReducer,
  ausbildner: ausbildnerReducer,
  klassenlehrer: klassenlehrerReducer,
  teams: teamsReducer,
  teamTeilnehmer: lehrerTeamTeilnehmerReducer,
  teamStatusListe: dummyReducer,
  programmBlockListe: dummyReducer,
  bibliothek: dummyReducer,
  abschlussStatusListe: dummyReducer,
  abmeldungStatusListe: dummyReducer,
  abmeldegruende: dummyReducer,
  leistungen: dummyReducer,
  bezogeneLeistungen: bezogeneLeistungenReducer,
  api: dummyReducer,
  i18n: dummyReducer,
  rollbar: rollbarReducer,
  fehlermeldungen: fehlermeldungReducer,
});
