// @flow

import type {AppState, EinsatzState} from "../state/stateTypes";
import type {Dispatch} from "../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import {mitTimeout, apiRequestAbschicken} from "../state/modules/ajax";

const Textfeld = (props: {
  einsatz: EinsatzState,
  i18n: any,
  pfade: any,
  feldAnApiSchicken: (string, string, string | number | null, {}) => void,
  feldName: string,
  feldWert: number,
  breite: number | void,
  hoehe: number | void,
  requestDaten: {},
  onChange: (any) => void | void,
  platzhalter: string | void,
  forceReadonly: boolean | void,
}) => {
  const aktiv = !props.forceReadonly && !props.einsatz.readonly;
  if (aktiv) {
    let handleChange = (event) => {
      let neuerWert = event.target.value;
      mitTimeout(props.feldName, () => {
        props.feldAnApiSchicken(
          props.pfade.api.einsatz,
          props.feldName,
          neuerWert,
          props.requestDaten || {}
        );
      });
      props.onChange(event);
    };

    return (
      <textarea
        style={{width: props.breite || '600px', height: props.hoehe || '60px', marginBottom: '0'}}
        placeholder={props.platzhalter || ''}
        value={props.feldWert || ''}
        name={props.feldName}
        onChange={handleChange}
      />
    );
  } else {
    const style = {};
    if (props.breite) {
      style['width'] = props.breite;
    }
    return <span className="textfeld-zeile-readonly" style={style}>{props.feldWert}</span>;
  }
};

Textfeld.defaultProps = {
  onChange: (event) => {
    // noop
  },
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    i18n: state.i18n,
    pfade: state.pfade,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    feldAnApiSchicken: (apiUrl: string, feldName: string, neuerWert: string | number | null, requestDaten: {}) => {
      dispatch(apiRequestAbschicken(apiUrl, 'PATCH', feldName, neuerWert, requestDaten, () => {
      }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Textfeld);
