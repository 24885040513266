import React, {useEffect, useRef, useState} from 'react'
import {ControlBar, ForwardControl, PlaybackRateMenuButton, Player, ReplayControl, BigPlayButton} from "video-react";
import 'video-react/dist/video-react.css';

const Video = ({children, src, className = '', onEnded = () => {}, onTimeUpdate = () => {}, onVideoElementChange = () => {}, ...props}) => {
  const videoPlayer = useRef(null);
  const [videoState, setVideoState] = useState(null);
  const [hasEnded, setHasEnded] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    videoPlayer.current.load();
    setCurrentTime(0);
    if (props.autoPlay) {
      setTimeout(() => { videoPlayer.current.play(); }, 0);
    }
    setTimeout(() => { setHasEnded(false); }, 0);
    onVideoElementChange(videoPlayer.current);
  }, [src]);

  useEffect(() => {
    videoPlayer.current.subscribeToStateChange(setVideoState);
  }, [setVideoState]);

  useEffect(() => {
    if (videoState) {
      if (videoState.ended && !hasEnded) {
        setHasEnded(true);
        onEnded();
      }
      const sekunden = Math.floor(videoState.currentTime);
      if (sekunden != currentTime) {
        setCurrentTime(sekunden);
        onTimeUpdate(sekunden);
      }
    }
  }, [videoState, hasEnded, setHasEnded]);

  return (
    <Player ref={videoPlayer} {...props}>
      <source src={src} type="video/mp4"/>
      <ControlBar>
        <PlaybackRateMenuButton rates={[2, 1.5, 1, 0.5, 0.3, 0.1]} order={2.1}/>
        <ReplayControl seconds={10} order={2.2}/>
        <ForwardControl seconds={10} order={2.3}/>
      </ControlBar>
      <BigPlayButton position="center" />
    </Player>
  );
};

export default Video;
