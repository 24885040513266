import React, {useState, useMemo} from "react";
import {connect} from "react-redux";
import {filtereRechnungskontrollen, sortiereRechnungen} from "../../lib/datenHelper";
import {formatiereBetrag} from "../../../../lib/formatierungen";
import {berechtigungFuer} from "../../lib/berechtigungen";
import {rechnungenStornieren} from "../../state/modules/rechnungskontrollen";
import PersonBadge from "../../../../components/PersonBadge";
import Tooltip from "../../../../components/Tooltip";
import Popover from "../../../../components/Popover";
import Prewrap from "../../../../components/Prewrap";
import Pager from "../../../../components/Pager";
import ConfirmButton from "../../../../components/ConfirmButton";
import RechnungenFilter from "./RechnungenFilter";
import BexioStatusLabel from "../BexioStatusLabel";
import RechnungenSuche from "./RechnungenSuche";
import EmailSendenModal from "./EmailSendenModal";
import SpielerListeSperrenModal from "./SpielerListeSperrenModal";
import BemerkungenModal from "./BemerkungenModal";
import BemerkungenSpielerModal from "./BemerkungenSpielerModal";
import {BEXIO_STATUS} from "../../lib/constants";

const Rechnungen = ({rechnungen, bexio, teams, spielerSperrenAktiv, pageSizeArray, texteRoot, locale, spielerStatistikUrl, rechnungenStornieren}) => {
  const [showFilter, setShowFilter] = useState(true);
  const [filterValues, setFilterValues] = useState(null);
  const [menuRechnungId, setMenuRechnungId] = useState(null);
  const [resetCounter, setResetCounter] = useState(0);
  const [alleSelektieren, setAlleSelektieren] = useState(false);
  const [selectedRechnungenIds, setSelectedRechnungenIds] = useState([]);
  const [showEmailSenden, setShowEmailSenden] = useState(false);
  const [showSpielerSperren, setShowSpielerSperren] = useState(false);
  const [showBemerkungen, setShowBemerkungen] = useState(false);
  const [showBemerkungenVerein, setShowBemerkungenVerein] = useState(null); // hält einzelne rechnung oder null -> bemerkung verein kann nur pro spieler bearbeitet werden

  const [pageSize, setPageSize] = useState(999999);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSort, setPageSort] = useState({name: 'person', desc: true});
  
  const texte = texteRoot.rechnungen;

  const aktiveBexioStati = useMemo(() => {
    const stati = [];
    if (bexio.aktiv) {
      // naiver ansatz: object hat nach forEach ein property pro bexio status
      const keys = {};
      rechnungen.forEach(r => keys[r.bexioStatus] = true);
      // alle BEXIO_STATUS beachten, wo es rechnungen dazu gibt
      for (let bs in BEXIO_STATUS) {
        let statusText = BEXIO_STATUS[bs];
        if (keys[statusText]) {
          stati.push({key: statusText, text: texteRoot.bexio.status[bs]});
        }
      }
    }
    return stati;
  }, [rechnungen]);

  const gefilterteRechnungen = useMemo(() => sortiereRechnungen(filtereRechnungskontrollen(rechnungen, filterValues), pageSort), [rechnungen, filterValues, pageSort]);
  const paginated = useMemo(() => {
    let startIndex = (pageIndex - 1) * pageSize;
    let endIndex = Math.min(pageIndex * pageSize, rechnungen.length);
    return gefilterteRechnungen.slice(startIndex, endIndex);
  }, [pageIndex, pageSize, gefilterteRechnungen]);
  const selektierteRechnungen = useMemo(() => gefilterteRechnungen.filter(r => selectedRechnungenIds.includes(r.id)), [selectedRechnungenIds, rechnungen]);

  const stornierenEnabled = useMemo(() => {
    if (bexio.aktiv && bexio.darfStornieren) {
      const minDate = new Date(bexio.minimumDate);
      return selectedRechnungenIds.filter(rId => {
        const rechnung = rechnungen.find(r => r.id === rId);
        if (rechnung && rechnung.bexio && rechnung.bexio.freigabedatum) {
          return new Date(rechnung.bexio.freigabedatum) > minDate;
        }
        return true;
      });
    }
    return [];
  }, [selectedRechnungenIds]);

  const sortieren = (feldname) => {
    if (pageSort && pageSort.name == feldname) {
      setPageSort({...pageSort, desc: !pageSort.desc});
    } else {
      setPageSort({name: feldname, desc: true});
    }
    pageIndexChange(1);
  };

  const filtern = filter => {
    setFilterValues(filter);
    pageIndexChange(1);
  };

  const filterReset = () => {
    setResetCounter(resetCounter + 1);
    setFilterValues(null);
  };

  const pageIndexChange = (index) => {
    setMenuRechnungId(null);
    setPageIndex(index);
    allesAuswaehlen(false);
  };

  const pageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    pageIndexChange(1);
  };

  const allesAuswaehlen = (checked) => {
    setAlleSelektieren(checked);
    if (checked) {
      setSelectedRechnungenIds(paginated.map(r => r.id));
    } else {
      setSelectedRechnungenIds([]);
    }
  };

  const rechnungAuswaehlen = (rechnung, checked) => {
    if (checked) {
      setSelectedRechnungenIds([...selectedRechnungenIds, rechnung.id]);
    } else {
      setSelectedRechnungenIds(selectedRechnungenIds.filter(r => r !== rechnung.id));
    }
  };

  const rechnungenStornierenClick = () => {
    rechnungenStornieren(selektierteRechnungen);
    setSelectedRechnungenIds([]);
  };

  const menuClick = (rechnung) => {
    if (menuRechnungId === rechnung.id) {
      setMenuRechnungId(null);
    } else {
      setMenuRechnungId(rechnung.id);
    }
  };

  const renderButtonListe = () => {
    return (
      <div className="button-liste">
        <div className="left"/>
        <div className="right">
          <button className="btn" disabled={selectedRechnungenIds.length === 0} onClick={() => setShowEmailSenden(true)}>{texte.emailSenden}</button>
          {bexio.aktiv && bexio.darfBemerkungen &&
            <button className="btn" disabled={selectedRechnungenIds.length === 0} onClick={() => setShowBemerkungen(true)}>{texte.bemerkungenBearbeiten}</button>
          }
          {spielerSperrenAktiv &&
            <button className="btn btn-danger" disabled={selectedRechnungenIds.length === 0} onClick={() => setShowSpielerSperren(true)}>{texte.spielerSperren}</button>
          }
          {bexio.aktiv && bexio.darfStornieren &&
            <ConfirmButton className="btn btn-danger" confirmText={texte.wirklichStornieren} okText={texte.ok} cancelText={texte.abbrechen} disabled={stornierenEnabled.length === 0 || stornierenEnabled.length < selectedRechnungenIds.length} onConfirmed={rechnungenStornierenClick}>{texte.rechnungStornieren}</ConfirmButton>
          }
        </div>
      </div>
    );
  };

  const itemTemplate = (rechnung, index) => {
    const teamListe = teams.filter(t => rechnung.teamIds.includes(t.id));
    const spielerInfo = rechnung.spielerInfo;

    return (
      <tr key={index}>
        <td>
          <PersonBadge person={rechnung.person} personVerlinken={false} style={{width: 180}}/>
          {rechnung.sponsorenlaufInfo &&
            <div>{`(${rechnung.sponsorenlaufInfo.personName})`}</div>
          }
          {spielerInfo &&
            <>
              {spielerInfo.qualifiziertAb &&
                <Tooltip content={texte.qualifiziertAb}
                         className="label label-important">{spielerInfo.qualifiziertAb}</Tooltip>
              }
              <div>
                <Tooltip content={texte.eingangsdatum}>{spielerInfo.eingangsdatum}</Tooltip>
              </div>
            </>
          }
        </td>
        <td>
          {rechnung.adresse &&
            <>
              <div>{rechnung.adresse.strasse}</div>
              <div>{rechnung.adresse.plz} {rechnung.adresse.ort}</div>
            </>
          }
        </td>
        <td>
          {teamListe.map(team => <div key={team.id}>{team.bezeichnung}</div>)}
          <div>
            {rechnung.bemerkungen &&
              <Popover title={texte.bemerkungen} className="icon-info-sign"
                       content={<Prewrap>{rechnung.bemerkungen}</Prewrap>}/>
            }
            {spielerInfo && spielerInfo.bemerkungen &&
              <Popover className="icon-info-sign" title={texte.bemerkungenSpieler}
                       content={<Prewrap>{rechnung.spielerInfo.bemerkungen}</Prewrap>}/>
            }
            {rechnung.bemerkungenMitglied &&
              <Popover className="icon-info-sign" title={texte.bemerkungenMitglied}
                       content={<Prewrap>{rechnung.bemerkungenMitglied}</Prewrap>}/>
            }
          </div>
          {spielerInfo && spielerInfo.gesperrt &&
            <Tooltip content={<Prewrap>{spielerInfo.gesperrtBemerkung}</Prewrap>}
                     aktiv={!!spielerInfo.gesperrtBemerkung}>
              <span className="label label-important label-umbruch">{texte.vereinsinternGesperrt}</span>
            </Tooltip>
          }
          {spielerInfo && spielerInfo.zumAbmeldenMarkiert &&
            <span className="label label-important label-umbruch">{texte.zumAbmeldenMarkiert}</span>
          }
        </td>
        <td>
          {rechnung.betragFreiwillig === true &&
            <i className="icon-ok"/>
          }
        </td>
        <td>
          {rechnung.leistung.bezeichnung}
          {berechtigungFuer(rechnung.rechnungslaufTyp, 'kommentar') && rechnung.kommentar &&
            <>&nbsp;<Popover className="icon-info-sign" title={texte.kommentar} content={<Prewrap>{rechnung.kommentar}</Prewrap>}/></>
          }
        </td>
        <td className="text-right">
          {formatiereBetrag(rechnung.betrag, locale)}
          <br/>
          {rechnung.konto}
        </td>
        {bexio.aktiv &&
          <td>
            <BexioStatusLabel rechnung={rechnung}/>
            {spielerInfo &&
              <>
                <br/>
                <button type="button" className="btn btn-mini" onClick={() => menuClick(rechnung)}>
                  <i className="icon-cog"/>
                </button>
                {menuRechnungId === rechnung.id &&
                  <div className="open button-liste" style={{position: 'relative'}}>
                    <ul className="dropdown-menu">
                      <li>
                        <a href={spielerStatistikUrl.replace('ersetzeSpielerId', spielerInfo.id)}>
                          <i className="icon-list"/> {texte.statistik}
                        </a>
                      </li>
                      {rechnung.istSpieler && bexio.aktiv && bexio.darfBemerkungen &&
                        <li>
                          <a className="cursor-pointer" onClick={() => {
                            setShowBemerkungenVerein(rechnung);
                            setMenuRechnungId(null);
                          }}>
                            <i className="icon-edit"/> {texte.bemerkungenSpieler}
                          </a>
                        </li>
                      }
                    </ul>
                  </div>
                }
              </>
            }
          </td>
        }
        <td>
          <input type="checkbox" checked={selectedRechnungenIds.includes(rechnung.id)} onChange={ev => rechnungAuswaehlen(rechnung, ev.currentTarget.checked)}/>
        </td>
      </tr>
    );
  };

  const renderSortierung = (feldname, text) => {
    const css = ['sort-me'];
    if (pageSort && pageSort.name === feldname) {
      css.push('active');
      if (pageSort.desc) {
        css.push('desc');
      } else {
        css.push('asc');
      }
    } else {
      css.push('desc');
    }
    return <div className={css.join(' ')} onClick={() => sortieren(feldname)}>{text}</div>;
  };

  const renderTableFooter = () => {
    const colSpan = bexio.aktiv ? 5 : 4;
    const gesamttotalBetrag = gefilterteRechnungen.reduce((currentValue, rechnung) => currentValue + Number.parseFloat(rechnung.betrag), 0);
    const freiwilligerBetrag = gefilterteRechnungen.filter(r => r.betragFreiwillig).reduce((currentValue, rechnung) => currentValue + Number.parseFloat(rechnung.betrag), 0);
    const totalBetrag = gesamttotalBetrag - freiwilligerBetrag;

    return (
      <tfoot>
      <tr>
        <td colSpan={colSpan} className="text-right">
          {!isNaN(freiwilligerBetrag) && freiwilligerBetrag > 0 &&
            <>
              {isNaN(totalBetrag) ||
                <>
                  <b>{texte.totalBetrag}</b>
                  <br/>
                </>
              }
              {isNaN(freiwilligerBetrag) ||
                <>
                  <b>{texte.totalFreiwillig}</b>
                  <br/>
                </>
              }
            </>
          }
          {isNaN(gesamttotalBetrag) ||
            <b>{texte.gesamttotalBetrag}</b>
          }
        </td>
        <td colSpan={2} className="text-right">
          {!isNaN(freiwilligerBetrag) && freiwilligerBetrag > 0 &&
            <>
              {isNaN(totalBetrag) ||
                <>
                  <b>{formatiereBetrag(totalBetrag, locale)}</b>
                  <br/>
                </>
              }
              {isNaN(freiwilligerBetrag) ||
                <>
                  <b>{formatiereBetrag(freiwilligerBetrag, locale)}</b>
                  <br/>
                </>
              }
            </>
          }
          {isNaN(gesamttotalBetrag) ||
            <b>{formatiereBetrag(gesamttotalBetrag, locale)}</b>
          }
        </td>
        <td></td>
      </tr>
      </tfoot>
    );
  };

  return (
    <>
      <h2 className="page-section">
        {`${texte.titel} (${gefilterteRechnungen.length})`}
        <span className="button-liste pull-right" style={{lineHeight: 1}}>
          {filterValues === null ||
            <Tooltip tagName="button" className="btn btn-small" content={texte.filterLoeschen} onClick={filterReset}>
              <i className="icon-remove"/>
            </Tooltip>
          }
          <button type="button" className="btn btn-small" onClick={() => setShowFilter(!showFilter)}>
            <i className="icon-filter"/>
            {texte.filter}
          </button>
        </span>
      </h2>
      <RechnungenSuche/>
      {showFilter &&
        <RechnungenFilter key={`filter-${resetCounter}`} aktiveBexioStati={aktiveBexioStati} filter={filterValues} onFilterChange={(filter) => filtern(filter)}/>
      }
      {rechnungen.length > 0 &&
        <>
          {renderButtonListe()}
          <Pager pageIndex={pageIndex} pageSize={pageSize} totalItemCount={gefilterteRechnungen.length} pageSizeArray={pageSizeArray} onPageChange={pageIndexChange} onPageSizeChange={pageSizeChange}/>
          <table className="table table-striped table-sort table-condensed">
            <thead>
            <tr>
              <th>
                {renderSortierung('person', texte.person)}
                {renderSortierung('geburtsdatum', texte.geburtsdatum)}
              </th>
              <th>
                {renderSortierung('strasse', texte.strasse)}
                {renderSortierung('plz', texte.plz)}
                {renderSortierung('ort', texte.ort)}
              </th>
              <th>{texte.team}</th>
              <th className="text-nowrap">
                {renderSortierung('betragFreiwillig', <Tooltip content={texte.betragFreiwillig}><i className="icon-heart"/></Tooltip>)}
              </th>
              <th>
                {renderSortierung('leistung', texte.leistung)}
              </th>
              <th className="text-right">
                {renderSortierung('betrag', texte.betrag)}
                {renderSortierung('konto', texte.konto)}
              </th>
              {bexio.aktiv &&
                <th>
                  {renderSortierung('bexio', 'bexio')}
                </th>
              }
              <th>
                <input type="checkbox" checked={alleSelektieren} onChange={() => allesAuswaehlen(!alleSelektieren)}/>
              </th>
            </tr>
            </thead>
            <tbody>
            {paginated.map((item, index) => itemTemplate(item, index))}
            </tbody>
            {renderTableFooter()}
          </table>
          <Pager pageIndex={pageIndex} pageSize={pageSize} totalItemCount={gefilterteRechnungen.length} pageSizeArray={pageSizeArray} onPageChange={pageIndexChange} onPageSizeChange={pageSizeChange}/>
          {renderButtonListe()}
          <br/>
        </>
      }
      {showEmailSenden &&
        <EmailSendenModal rechnungen={selektierteRechnungen} onHide={() => setShowEmailSenden(false)}/>
      }
      {bexio.aktiv && spielerSperrenAktiv && showSpielerSperren &&
        <SpielerListeSperrenModal rechnungen={selektierteRechnungen} onHide={() => setShowSpielerSperren(false)}/>
      }
      {bexio.aktiv && bexio.darfBemerkungen && showBemerkungen &&
        <BemerkungenModal rechnungen={selektierteRechnungen} onHide={() => setShowBemerkungen(false)}/>
      }
      {bexio.aktiv && bexio.darfBemerkungen && showBemerkungenVerein &&
        <BemerkungenSpielerModal rechnung={showBemerkungenVerein} onHide={() => setShowBemerkungenVerein(null)}/>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    rechnungen: state.rechnungen,
    bexio: state.bexio,
    teams: state.teams,
    spielerSperrenAktiv: !!state.api.spieler,
    pageSizeArray: state.listen.pager,
    texteRoot: state.i18n.texte,
    locale: state.i18n.locale,
    spielerStatistikUrl: state.api.spielerStatistik,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rechnungenStornieren: (rechnungen) => {
      dispatch(rechnungenStornieren(rechnungen));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rechnungen);
