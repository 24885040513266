// @flow

import type { AppState, EinsatzState, PrognoseFragen, SpielerEintrag, SpielState } from "../../../state/stateTypes";
import type { Dispatch } from "../../../state/actionTypes";

import React from "react";
import { connect } from "react-redux";
import { spielerPrognoseFragenSetzen } from "../../../state/modules/spieler";
import PrognoseDropdown from "./PrognoseDropdown";
import { apiRequestAbschicken } from "../../../state/modules/ajax";
import { prognoseWertBerechnen } from "../../../../../lib/prognoseFragen";

const SpielerPrognoseFragen = ({
                                 einsatz,
                                 spiel,
                                 pfade,
                                 i18n,
                                 spieler,
                                 readonly,
                                 spielerPrognoseFragenSetzen,
                                 prognoseFragenAnApiSenden
                               }: Props) => {
  const prognoseFragen = spieler.prognoseFragen;
  if(!prognoseFragen) {
    return <></>;
  }

  const setzePrognose = (wert) => {
    const pf = {
      ...prognoseFragen,
      prognose: wert
    };
    speichern(spieler.id, pf);
  };

  const setzeFrage = (frageNr, frageId, checked) => {
    const pf = { ...prognoseFragen };
    pf[`frage${frageNr}`] = { id: frageId, selektiert: checked };
    pf.prognose = [1, 2, 3, 4, 5, 6].map(frageNr => pf[`frage${frageNr}`].selektiert).filter(b => b === true).length;
    speichern(spieler.id, pf);
  };

  const speichern = (spielerId, pf) => {
    spielerPrognoseFragenSetzen(spielerId, pf);
    prognoseFragenAnApiSenden(pfade.api.einsatz, 'prognoseFragen', pf, {
      spielerId: spielerId,
      istQualifikationNational: einsatz.istQualifikationNational
    });
  };

  const hatFragen = spiel.prognoseFragen.length > 0;
  let prognose;
  if(hatFragen) {
    prognose = [1, 2, 3, 4, 5, 6].map(frageNr => prognoseFragen[`frage${frageNr}`].selektiert).filter(b => b === true).length;
  } else {
    prognose = prognoseFragen.prognose || 0;
  }

  const darstellung = prognoseWertBerechnen(prognose);
  let cssPrognose = `text-center leistung ${darstellung.farbe}`;
  let buchstabePrognose = darstellung.buchstabe;

  return (
    <table>
      <tbody>
      <tr>
        <th>{i18n.prognoseFragen.titel}</th>
        <th style={{ width: 40 }} className={cssPrognose}>
          {buchstabePrognose}
        </th>
      </tr>
      {hatFragen &&
        <>
          {spiel.prognoseFragen.map((pf, index) => {
            // es gibt einfach eine liste von fragen, die aktuell gültig sind.
            const frageNr = index + 1;
            const frage = prognoseFragen[`frage${frageNr}`];
            return (
              <tr key={pf.id}>
                <td>{pf.text}</td>
                <td className="text-center">
                  <input type='checkbox' checked={frage.selektiert}
                         onChange={(e) => setzeFrage(frageNr, pf.id, e.currentTarget.checked)}
                         disabled={einsatz.readonly || readonly}/>
                </td>
              </tr>
            )
          })}
        </>
      }
      {hatFragen ||
        <tr>
          <td>{i18n.prognoseFragen.spielerPrognose}</td>
          <td>
            <PrognoseDropdown readonly={einsatz.readonly} aktuellerWert={prognose}
                              onValueChange={(wert => setzePrognose(wert))}/>
          </td>
        </tr>
      }
      </tbody>
    </table>
  );
};

type Props = {
  einsatz: EinsatzState,
  spiel: SpielState,
  pfade: any,
  i18n: any,
  spieler: SpielerEintrag,
  readonly: boolean | false,
  spielerPrognoseFragenSetzen: (number, PrognoseFragen) => void,
  prognoseFragenAnApiSenden: (apiUrl: string, feldName: string, neuerWert: {}, requestDaten: {}) => void,
}
const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    spiel: state.spiel,
    pfade: state.pfade,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    spielerPrognoseFragenSetzen: (spielerId: number, prognoseFragen: PrognoseFragen) => {
      dispatch(spielerPrognoseFragenSetzen(spielerId, prognoseFragen));
    },
    prognoseFragenAnApiSenden: (apiUrl: string, feldName: string, neuerWert: {}, requestDaten: {}) => {
      dispatch(apiRequestAbschicken(apiUrl, 'PATCH', feldName, neuerWert, requestDaten, () => {
      }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpielerPrognoseFragen);
