import React from "react";

const LabelInputStruktur = ({model, texte, name, colSpan, children}) => {
  const fehlerText = model.errors && model.errors[name] ? model.errors[name].join('. ') : null;

  return (
    <>
      <th><label className="control-label" htmlFor={name}>{texte[name]}</label></th>
      <td colSpan={colSpan}>
        <div className={`control-group ${fehlerText ? 'error' : ''}`}>
          <div className="controls">
            {children}
            {fehlerText && ` ${fehlerText}`}
          </div>
        </div>
      </td>
    </>
  );
};

export default LabelInputStruktur;
