import React, {useEffect, useMemo, useRef, useState} from "react";
import {executeRequest} from "../lib/fetchWrapper";
import "../shared/shared-components";
import LinkEinfuegen from "./Markdown/LinkEinfuegen";
import GrafikEinfuegen from "./Markdown/GrafikEinfuegen";
import ModalerDialog from "./ModalerDialog";

export const Modus = {
  tabs: 'tabs',
  inline: 'inline'
};

const ViewMode = {
  markdown: 'md',
  html: 'html'
};

const TextAktion = {
  de: [
    {key: 'undo', title: 'Rückgängig', text: '↫'},
    {key: 'redo', title: 'Wiederholen', text: '↬'},
    {key: 'bold', title: 'Fett', text: 'F'},
    {key: 'italic', title: 'Kursiv', text: 'K'},
    {key: 'underscore', title: 'Unterstrichen', text: 'U'},
    {key: 'linebreak', title: 'Neue Zeile', text: '⏎'},
    {key: 'list', title: 'Liste', text: '☰'},
    {key: 'numberlist', title: 'Numerierte Liste', text: '123'},
    {key: 'indentDec', title: 'Einzug verringern', text: '⇦'},
    {key: 'indentInc', title: 'Einzug erhöhen', text: '⇨'},
    {key: 'line', title: 'Linie', text: '—'},
    {key: 'link', title: 'Link', text: 'url'},
    {key: 'image', title: 'Bild', text: '⬚'},
  ],
  fr: [
    {key: 'undo', title: 'Annuler', text: '↫'},
    {key: 'redo', title: 'Refaire', text: '↬'},
    {key: 'bold', title: 'Gras', text: 'G'},
    {key: 'italic', title: 'Italique', text: 'I'},
    {key: 'underscore', title: 'Souligné', text: 'S'},
    {key: 'linebreak', title: 'Saut de ligne', text: '⏎'},
    {key: 'list', title: 'Liste', text: '☰'},
    {key: 'numberlist', title: 'Liste numérotée', text: '123'},
    {key: 'indentDec', title: 'Diminuer le retrait', text: '⇦'},
    {key: 'indentInc', title: 'Augmenter le retrait', text: '⇨'},
    {key: 'line', title: 'Ligne', text: '—'},
    {key: 'link', title: 'Lien', text: 'url'},
    {key: 'image', title: 'Image', text: '⬚'},
  ],
  it: [
    {key: 'undo', title: 'Disfare', text: '↫'},
    {key: 'redo', title: 'Rifare', text: '↬'},
    {key: 'bold', title: 'Grassetto', text: 'G'},
    {key: 'italic', title: 'Corsivo', text: 'C'},
    {key: 'underscore', title: 'Sottolineato', text: 'S'},
    {key: 'linebreak', title: 'Interruzione di linea', text: '⏎'},
    {key: 'list', title: 'Lista', text: '☰'},
    {key: 'numberlist', title: 'Lista numerata', text: '123'},
    {key: 'indentDec', title: 'Riduci rientro', text: '⇦'},
    {key: 'indentInc', title: 'Aumenta rientro', text: '⇨'},
    {key: 'line', title: 'Linea', text: '—'},
    {key: 'link', title: 'Link', text: 'url'},
    {key: 'image', title: 'Immagine', text: '⬚'},
  ]
};

const BexioAktion = {
  kontakt: [
    {key: 'anrede', value: '[Salutation]'},
    {key: 'nameFirmenname', value: '[Name 1]'},
    {key: 'name', value: '[Name]'},
    {key: 'vornameFirmenzusatz', value: '[Name 2]'},
    {key: 'firma', value: '[Company Name]'}
  ],
  rechnung: [
    {key: 'esrNr', value: '[Esr Nr]'},
    {key: 'total', value: '[Total]'},
    {key: 'datum', value: '[Date]'},
    {key: 'nummer', value: '[Document Number]'},
    {key: 'offenerBetrag', value: '[Needed Payment]'},
    {key: 'waehrung', value: '[Currency]'},
    {key: 'zahlbarBis', value: '[Payable By]'},
    {key: 'ueberfaelligeTage', value: '[Overdue Days]'}
  ],
  sponsorenlauf: [
    {key: 'spieler', value: '[spieler]'},
    {key: 'spielerkategorie', value: '[spielerkategorie]'},
    {key: 'team', value: '[team]'},
    {key: 'anzahl_runden', value: '[anzahl_runden]'}
  ]
};

const Uebersetzungen = {
  de: {
    schreiben: 'Schreiben',
    vorschau: 'Vorschau',
    imageAlt: 'Bild',
    hilfe: {
      link: 'Styling mit Markdown wird hier unterstützt',
      titel: 'Hilfe zu Markdown',
      schliessen: 'Schliessen',
      text: '*kursiv*  \n' +
        '**fett**  \n' +
        '***fett kursiv***  \n' +
        '_unterstrichen_\n' +
        '\n' +
        '* liste\n' +
        '* mit\n' +
        '* sternchen\n' +
        '\n' +
        '1. liste\n' +
        '2. nummeriert\n' +
        '3. möglich\n' +
        '\n' +
        'Bilder können als Inhalt eines Links benutzt werden. Fügen Sie dazu ein Bild ein, markieren Sie alles, und klicken auf "url".\n' +
        '\n' +
        'Weitere Formatierungen, Bilder, Links: siehe Buttons.\n'
    },
    kontaktTitel: 'Kontakt',
    kontakt: {
      anrede: 'Anrede',
      nameFirmenname: 'Name / Firmenname',
      name: 'Name',
      vornameFirmenzusatz: 'Vorname / Firmenzusatz',
      firma: 'Firmenname',
    },
    rechnungTitel: 'Rechnung',
    rechnung: {
      esrNr: 'ESR Nr.',
      total: 'Betrag',
      datum: 'Rechnungsdatum',
      nummer: 'Nummer',
      offenerBetrag: 'Offener Betrag',
      waehrung: 'Währung',
      zahlbarBis: 'Zahlbar bis',
      ueberfaelligeTage: 'Überfällige Tage',
    },
    sponsorenlaufTitel: 'Sponsorenlauf',
    sponsorenlauf: {
      spieler: 'Spielername',
      spielerkategorie: 'Spielerkategorie',
      team: 'Team',
      anzahl_runden: 'Anzahl Runden/Punkte'
    }
  },
  fr: {
    schreiben: 'Écrire',
    vorschau: 'Aperçu',
    imageAlt: 'Image',
    hilfe: {
      link: 'Mise en forme avec Markdown est pris en charge ici',
      titel: 'Aide Markdown',
      schliessen: 'Fermer',
      text: '*italique*  \n' +
        '**gras**  \n' +
        '***gras italique***  \n' +
        '_souligné_  \n' +
        '\n' +
        '* liste\n' +
        '* des\n' +
        '* choses\n' +
        '\n' +
        '1. liste\n' +
        '2. avec\n' +
        '3. numéros\n' +
        '\n' +
        'Les images peuvent être utilisées comme contenu d\'un lien. Pour ce faire, ajoutez une image, mettez tout en surbrillance, puis cliquez sur "url".\n' +
        '\n' +
        'Autres formats, images, liens: voiéz les boutons.\n'
    },
    kontaktTitel: 'Contact',
    kontakt: {
      anrede: 'Titre',
      nameFirmenname: 'Nom / Nom de la société',
      name: 'Nom',
      vornameFirmenzusatz: 'Prénom / Complément de société',
      firma: 'Nom de la société',
    },
    rechnungTitel: 'Facture',
    rechnung: {
      esrNr: 'N° de BVR',
      total: 'Total',
      datum: 'Date de la facture',
      nummer: 'Numéro',
      offenerBetrag: 'Montant en suspens',
      waehrung: 'Monnaie',
      zahlbarBis: 'Payable au plus tard',
      ueberfaelligeTage: 'Jours en retard',
    },
    sponsorenlaufTitel: 'Course sponsorisée',
    sponsorenlauf: {
      spieler: 'Nom du joueur',
      spielerkategorie: 'Catégorie de joueurs',
      team: 'Équipe',
      anzahl_runden: 'Nombre de tours/points'
    }
  },
  it: {
    schreiben: 'Scrivi',
    vorschau: 'Anteprima',
    imageAlt: 'Immagine',
    hilfe: {
      link: 'La formattazione con Markdown è supportato qui',
      titel: 'Aiuto Markdown',
      schliessen: 'Vicino',
      text: '*corsivo*  \n' +
        '**grassetto**  \n' +
        '***grassetto corsivo***  \n' +
        '_sottolineato_  \n' +
        '\n' +
        '* lista\n' +
        '* con\n' +
        '* l\'asterisco\n' +
        '\n' +
        '1. lista\n' +
        '2. numerato\n' +
        '3. possibile\n' +
        '\n' +
        'Le immagini possono essere utilizzate come contenuto di un collegamento. Per fare questo, aggiungere un\'immagine, evidenziare tutto e fare clic su "url".\n' +
        '\n' +
        'Altri formatti, immagini, link: vedere pulsanti.\n'
    },
    kontaktTitel: 'Contatta',
    kontakt: {
      anrede: 'Saluto',
      nameFirmenname: 'Nome / Ragione sociale',
      name: 'Nome',
      vornameFirmenzusatz: 'Nome / Affisso aziendale',
      firma: 'Ragione sociale',
    },
    rechnungTitel: 'Fattura',
    rechnung: {
      esrNr: 'VES No.',
      total: 'Importo',
      datum: 'Data della fattura',
      nummer: 'Numero',
      offenerBetrag: 'Importo aperto',
      waehrung: 'Valuta',
      zahlbarBis: 'Pagabile fino a',
      ueberfaelligeTage: 'Giorni di ritardo',
    },
    sponsorenlaufTitel: 'Corsa sponsorizzata',
    sponsorenlauf: {
      spieler: 'Nome giocatore',
      spielerkategorie: 'Categoria dei giocatori',
      team: 'Squadra',
      anzahl_runden: 'Numero di turni/punti'
    }
  }
};

let globalCounter = 0;
const createInstanceId = () => {
  return `mdEditor${++globalCounter}`;
};

const MarkdownEditor = ({showBexioAktionen = false, showHelp = true, modus = Modus.tabs, value, placeholder = '', markdownUrl, imageUploadsUrl = null, token, locale, onChange, onBlur}) => {
  const [markdown, setMarkdown] = useState(value);
  const [instanceId, _setInstanceId] = useState(createInstanceId());
  const [html, setHtml] = useState('');
  const [show, setShow] = useState(ViewMode.markdown);
  const [hilfeAnzeigen, setHilfeAnzeigen] = useState(false);
  const [erstelleUrl, setErstelleUrl] = useState(null);
  const [erstelleGrafik, setErstelleGrafik] = useState(null);
  const [showBexioMenu, setShowBexioMenu] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [verzoegereAjax, setVerzoegereAjax] = useState(0);
  const [undoListe, setUndoListe] = useState([]);
  const [redoListe, setRedoListe] = useState([]);
  const texte = Uebersetzungen[locale];
  const textareaRef = useRef(null);
  const inlinePreviewRef = useRef(null);

  const textAktionen = useMemo(() => {
    if (showBexioAktionen) {
      // bexio kann nur die folgenden formatierungen benutzen:
      return TextAktion[locale].filter(a => ['undo', 'redo', 'bold', 'italic', 'underscore', 'linebreak', 'list', 'numberlist'].includes(a.key));
    } else {
      if (imageUploadsUrl) {
        return TextAktion[locale];
      }
      return TextAktion[locale].filter(a => a.key !== 'image');
    }
  }, [showBexioAktionen]);

  useEffect(() => {
    if (modus === Modus.tabs) {
      if (show === ViewMode.markdown) {
        textareaRef.current.style.height = "0px";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 20}px`;

        if (cursorPosition) {
          textareaRef.current.selectionStart = cursorPosition.start;
          textareaRef.current.selectionEnd = cursorPosition.end;
          setCursorPosition(null);
        }
      }
    } else {
      textareaRef.current.style.height = `${Math.max(430, inlinePreviewRef.current.scrollHeight + 20)}px`;

      clearTimeout(verzoegereAjax);
      setVerzoegereAjax(setTimeout(() => ladeHtmlVonMarkdown(), 200));
    }
  }, [markdown, show]);

  useEffect(() => {
    setMarkdown(value);
  }, [value]);

  const ladeHtmlVonMarkdown = () => {
    executeRequest(markdownUrl, token, 'POST', {text: markdown}, result => {
      setHtml(result.data.preview);
    });
  };

  const anzeigen = (ev, mode) => {
    // nav-tabs sind links -> click muss unterdrückt werden
    ev.preventDefault();
    ev.stopPropagation();

    if (mode !== show && mode === ViewMode.html) {
      ladeHtmlVonMarkdown();
    }
    setShow(mode);
  };

  const markdownChange = (text) => {
    setMarkdown(text);
    if (onChange) {
      onChange(text);
    }
  };

  const markdownBlur = () => {
    if (onBlur) {
      onBlur(markdown);
    }
  };

  const bexioButtonClick = (ev) => {
    ev.preventDefault();
    setShowBexioMenu(!showBexioMenu);
  };

  const textAktionClick = (ev, key) => {
    ev.stopPropagation();
    ev.preventDefault();
    handleTextAktion(key);
  };

  const bexioAktionClick = (ev, value) => {
    ev.preventDefault();
    ev.stopPropagation();
    handleTextAktion(value);
    setShowBexioMenu(false);
  };

  const renderTextAktion = (typ) => {
    return <React.Fragment key={typ.key}><a href="" className={`${typ.key}`} onClick={(ev) => textAktionClick(ev, typ.key)} title={typ.title}>{typ.text}</a> </React.Fragment>;
  };

  const handleTextAktion = (type) => {
    const text = textareaRef.current.value;
    let start = textareaRef.current.selectionStart;
    let end = textareaRef.current.selectionEnd;

    // switch mit allen aktionen die (noch) nicht direkt den inhalt verändern:
    switch (type) {
      case 'undo':
        if (undoListe.length > 0) {
          setRedoListe([...redoListe, text]);
          setMarkdown(undoListe.pop());
          setUndoListe([...undoListe])
        }
        return;
      case 'redo':
        if (redoListe.length > 0) {
          setUndoListe([...undoListe, text]);
          setMarkdown(redoListe.pop());
          setRedoListe([...redoListe])
        }
        return;
      case 'link':
        setErstelleUrl({start: start, end: end, text: text});
        return; // nicht nur break hier, da noch nichts verändert werden soll!
      case 'image':
        setErstelleGrafik({start: start, end: end, text: text});
        return; // nicht nur break hier, da noch nichts verändert werden soll!
    }

    // es verbleiben alle aktionen die direkt den text verändern:

    setUndoListe([...undoListe, text]);
    setRedoListe([]);

    let formatter = new MarkdownPreview.TextFormatter(text, instanceId);
    let formatResult = formatter.formatText(instanceId, type, start, end);

    if (typeof (formatResult) == "object") {
      start = formatResult.start;
      end = formatResult.end;
      formatResult = formatResult.text
    }

    setCursorPosition({start, end});
    textareaRef.current.focus();
    markdownChange(formatResult);
  };

  const handleLink = (linkText, linkUrl) => {
    let text = erstelleUrl.text;
    let laengeAlt = text.length;
    let formattedLink = `[${linkText}](${linkUrl})`;

    setUndoListe([...undoListe, text]);
    setRedoListe([]);

    text = text.slice(0, erstelleUrl.start) + formattedLink + text.slice(erstelleUrl.start + Math.abs(erstelleUrl.end - erstelleUrl.start))
    markdownChange(text);

    let laengeDelta = Math.abs(text.length - laengeAlt);
    setCursorPosition({start: erstelleUrl.start, end: erstelleUrl.end + laengeDelta});
    textareaRef.current.focus();

    setErstelleUrl(null);
  };

  const handleGrafik = (linkUrl, imageWidth, imageHeight) => {
    let text = erstelleGrafik.text;
    let laengeAlt = text.length;
    let linkText = text.slice(erstelleGrafik.start, erstelleGrafik.end);
    if (linkText === '') {
      linkText = texte.imageAlt; // default alt-text wenn kein text selektiert
    }
    let size = '';
    if (imageWidth || imageHeight) {
      size = ' =';
      if (imageWidth) {
        size += imageWidth;
      }
      if (imageHeight) {
        size += `x${imageHeight}`;
      }
    }
    let formattedLink = `![${linkText}](${linkUrl}${size})`;

    setUndoListe([...undoListe, text]);
    setRedoListe([]);

    text = text.slice(0, erstelleGrafik.start) + formattedLink + text.slice(erstelleGrafik.start + Math.abs(erstelleGrafik.end - erstelleGrafik.start))
    markdownChange(text);

    let laengeDelta = Math.abs(text.length - laengeAlt);
    setCursorPosition({start: erstelleGrafik.start, end: erstelleGrafik.end + laengeDelta});
    textareaRef.current.focus();

    setErstelleGrafik(null);
  };

  const renderButtonBar = () => {
    return (
      <div className="markdown-button-bar" style={{display: 'inherit'}}>
        {textAktionen.map(typ => renderTextAktion(typ))}
        {showBexioAktionen &&
          <span className={`dropdown ${showBexioMenu ? 'open' : ''}`} style={{display: 'inline-block'}}>
            <a className="dropdown-toggle" data-toggle="dropdown" href="#" onClick={bexioButtonClick}>Bexio</a>
            <ul className="dropdown-menu markdown-menu" role="menu">
              <li><b>{texte.kontaktTitel}</b></li>
              {BexioAktion.kontakt.map(eintrag => <li key={eintrag.key} role="presentation"><a role="menuitem" tabIndex="-1" href="#" onClick={(ev) => bexioAktionClick(ev, eintrag.value)}>{texte.kontakt[eintrag.key]}</a></li>)}
              <li><b>{texte.rechnungTitel}</b></li>
              {BexioAktion.rechnung.map(eintrag => <li key={eintrag.key} role="presentation"><a role="menuitem" tabIndex="-1" href="#" onClick={(ev) => bexioAktionClick(ev, eintrag.value)}>{texte.rechnung[eintrag.key]}</a></li>)}
              <li><b>{texte.sponsorenlaufTitel}</b></li>
              {BexioAktion.sponsorenlauf.map(eintrag => <li key={eintrag.key} role="presentation"><a role="menuitem" tabIndex="-1" href="#" onClick={(ev) => bexioAktionClick(ev, eintrag.value)}>{texte.sponsorenlauf[eintrag.key]}</a></li>)}
            </ul>
          </span>
        }
      </div>
    );
  };
  
  const renderHilfeLink = () => {
    return (
      <a href="#" onClick={ev => {
        ev.preventDefault();
        setHilfeAnzeigen(true);
        return false;
      }}>
        <svg aria-hidden="true" className="octicon octicon-markdown v-align-bottom" height="16" version="1.1" viewBox="0 0 16 16" width="16">
          <path fillRule="evenodd" d="M14.85 3H1.15C.52 3 0 3.52 0 4.15v7.69C0 12.48.52 13 1.15 13h13.69c.64 0 1.15-.52 1.15-1.15v-7.7C16 3.52 15.48 3 14.85 3zM9 11H7V8L5.5 9.92 4 8v3H2V5h2l1.5 2L7 5h2v6zm2.99.5L9.5 8H11V5h2v3h1.5l-2.51 3.5z"/>
        </svg>
        <span style={{verticalAlign: 'text-bottom', fontSize: 'smaller'}}>&nbsp;{texte.hilfe.link}</span>
      </a>
    );
  };

  return (
    <>
      {modus === Modus.tabs &&
        <div className="markdown-tab-bar-container">
          <div className="markdown-tab-bar">
            <div className="tabbable">
              <ul className="nav nav-tabs">
                <li className={show === ViewMode.markdown ? 'active' : ''}>
                  <a href="" onClick={(ev) => anzeigen(ev, ViewMode.markdown)}>{texte.schreiben}</a>
                </li>
                <li className={show === ViewMode.html ? 'active' : ''}>
                  <a href="" onClick={(ev) => anzeigen(ev, ViewMode.html)}>{texte.vorschau}</a>
                </li>
                <li>
                  {renderButtonBar()}
                </li>
              </ul>
              {show === ViewMode.markdown &&
                <div className="markdown-text-wrapper">
                  <textarea ref={textareaRef} className="markdown-with-preview" placeholder={placeholder} style={{resize: 'none', width: '100%', height: 70}} value={markdown} onChange={ev => markdownChange(ev.currentTarget.value)} onBlur={markdownBlur}/>
                </div>
              }
              {show === ViewMode.html &&
                <div className="markdown-preview-wrapper">
                  <div className="markdown-preview" style={{minHeight: 28}} dangerouslySetInnerHTML={{__html: html}}/>
                </div>
              }
            </div>
            {showHelp && renderHilfeLink()}
          </div>
        </div>
      }
      {modus === Modus.inline &&
        <div className="markdown-text-table" style={{width: "100%"}}>
          <div className="markdown-text-wrapper">
            {renderButtonBar()}
            <textarea ref={textareaRef} className="markdown-with-preview" placeholder={placeholder} style={{resize: 'none', width: '100%', height: 430, maxHeight: 'inherit'}} value={markdown} onChange={ev => markdownChange(ev.currentTarget.value)} onBlur={markdownBlur}/>
          </div>
          <div className="markdown-preview-wrapper">
            <div ref={inlinePreviewRef} className="markdown-preview" style={{minHeight: 28}} dangerouslySetInnerHTML={{__html: html}}/>
          </div>
        </div>
      }
      {erstelleUrl &&
        <LinkEinfuegen locale={locale} context={erstelleUrl} onHide={() => setErstelleUrl(null)} onChange={(text, link) => handleLink(text, link)}/>
      }
      {erstelleGrafik &&
        <GrafikEinfuegen locale={locale} imageUploadsUrl={imageUploadsUrl} token={token} context={erstelleGrafik} onHide={() => setErstelleGrafik(null)} onChange={(link, width, height) => handleGrafik(link, width, height)}/>
      }
      {hilfeAnzeigen &&
        <ModalerDialog>
          <div className="modal breit-modal">
            <div className="modal-header">
              <button type="button" className="close" onClick={() => setHilfeAnzeigen(false)}>&times;</button>
              <h3>{texte.hilfe.titel}</h3>
            </div>
            <div className="modal-body">
              <MarkdownEditor modus={Modus.inline} showHelp={false} locale={locale} markdownUrl={markdownUrl} imageUploadsUrl={imageUploadsUrl} token={token} value={texte.hilfe.text}/>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn" onClick={() => setHilfeAnzeigen(false)}>{texte.hilfe.schliessen}</button>
            </div>
          </div>
        </ModalerDialog>
      }
    </>
  );
};

export default MarkdownEditor;
