import React, {createRef, useState} from "react";
import {connect} from "react-redux";
import {formatiereDatumZeit} from "../../lib/formatierungen";
import {arbeitsraumGelesen} from "../../state/arbeitsraumReducer";
import {aufgabeZuteilungFileUpload, aufgabeZuteilungUpdate} from "../../state/teilnehmerAufgabenReducer";
import AufgabeLabel from "../AufgabeLabel";
import IconToggle from "../IconToggle";
import BibliothekDetail from "../BibliothekDetail";
import ArbeitsraumListe from "../ArbeitsraumListe";
import VideoTagging from "../Klassenlehrer/VideoTagging";
import Tooltip from "../../../../components/Tooltip";
import {removeFileUpload} from "../../state/fileUpload";

const AufgabeBearbeiten = ({aufgabeZuteilung, ausbildung, arbeitsraumListe, fileUpload, dokumentPfad, texte, arbeitsraumGelesen, aufgabeUpdate, aufgabeFileUpload, fileUploadEntfernen}) => {
  const [editMode, setEditMode] = useState(true);
  const [chatMode, setChatMode] = useState(false);
  const von = aufgabeZuteilung.von ? aufgabeZuteilung.von : undefined;
  const bis = aufgabeZuteilung.bis ? aufgabeZuteilung.bis : undefined;
  const [kommentar, setKommentar] = useState(aufgabeZuteilung.kommentar || '');
  const fileInput = createRef();
  const [fileName, setFileName] = useState(undefined);
  const istBearbeitbar = ausbildung.bearbeitbar && !aufgabeZuteilung.kommentarAbgeschlossen;
  const meineArbeitsraumEintraege = arbeitsraumListe.filter(a => a.kursplanTeamsId === aufgabeZuteilung.kursplanTeamsId && a.bibliothekId === aufgabeZuteilung.bibliothekId);
  const hatUngelesene = meineArbeitsraumEintraege.filter(ae => !ae.gelesen && ae.experte.id === ae.absenderId).length > 0;
  const aktuellerFileUpload = fileUpload.find(fu => fu.id === aufgabeZuteilung.id);

  const setzeChatMode = (sichtbar) => {
    setChatMode(sichtbar);
    if (sichtbar && hatUngelesene) {
      arbeitsraumGelesen(meineArbeitsraumEintraege.map(e => e.id));
    }
  };
  
  const onFileChange = () => {
    fileUploadEntfernen(aufgabeZuteilung.id);
    if (fileInput.current.files.length > 0) {
      setFileName(fileInput.current.files[0].name);
    } else {
      setFileName(undefined);
    }
  };

  const speichern = (zusatzdaten = {}) => {
    zusatzdaten['kommentar'] = kommentar;
    aufgabeUpdate(aufgabeZuteilung, zusatzdaten);
    if (fileInput.current.files.length > 0) {
      fileUploadEntfernen(aufgabeZuteilung.id);
      aufgabeFileUpload(aufgabeZuteilung, fileInput.current.files[0]);
      fileInput.current.value = '';
      setFileName(undefined);
    } else {
      setEditMode(false); // offen lassen bei file upload, damit progress bar gleich sichtbar ist
    }
  };

  const speichernClick = () => {
    speichern();
  };
  const abschliessenClick = () => {
    speichern({kommentarAbgeschlossen: true});
  };

  return (
    <div className="aufgabe eintrag-box">
      <div className="titel">
        {aufgabeZuteilung.bibliothek.titel}
        <span className="pull-right">
          {aufgabeZuteilung.istFreigegeben &&
          <Tooltip content={texte.aufgabenFreigeben.istFreigegeben} className="btn btn-mini btn-warning">
            <i className="icon-share"/>
          </Tooltip>
          }
          <AufgabeLabel aufgabeZuteilung={aufgabeZuteilung}/>
          <button className="btn btn-mini" onClick={() => setEditMode(!editMode)}>
            <IconToggle istBearbeitbar={istBearbeitbar} istOffen={editMode}/>
          </button>
        </span>
      </div>
      <div className="zeile">
        {`${texte.datumVon}: ${formatiereDatumZeit(von)}, ${texte.datumBis}: ${formatiereDatumZeit(bis)}`}
      </div>
      {<BibliothekDetail aufgabe={aufgabeZuteilung.bibliothek}/>}
      {editMode &&
      <>
        <div className="zeile">
          {texte.kommentar}
          {istBearbeitbar &&
          <textarea rows="5" onChange={(ev) => setKommentar(ev.currentTarget.value)} value={kommentar}/>
          }
          {istBearbeitbar ||
          <div className="kommentar">{kommentar}</div>
          }
        </div>
        {istBearbeitbar &&
        <div className="zeile">
          <label>{texte.file}</label>
          <input type="file" ref={fileInput} accept="*/*" className="hide" onChange={onFileChange}/>
          <span className="btn btn-upload" onClick={() => fileInput.current.click()}>{texte.fileButton}</span>
          {fileName &&
          <>
            &nbsp;<span>{fileName}</span>
          </>
          }
          {aktuellerFileUpload &&
          <>
            {aktuellerFileUpload.running === false &&
            <>
              {aktuellerFileUpload.error &&
              <>&nbsp;<span className="label label-important">{texte.uploadFehler}</span></>
              }
              {aktuellerFileUpload.error ||
              <>&nbsp;<span className="label label-info">{texte.uploadKomplett}</span></>
              }
            </>
            }
            <div className={`progress progress-striped ${aktuellerFileUpload.running ? 'active' : aktuellerFileUpload.error ? 'progress-danger' : 'progress-success'}`}>
              <div className="bar" style={{"width": `${aktuellerFileUpload.progress}%`}}/>
            </div>
          </>
          }
        </div>
        }
        {aufgabeZuteilung.hatDokument &&
        <>
          <div className="zeile">
            <span className="datei-upload">
              {texte.file}:&nbsp;
              <a href={`${dokumentPfad}?aufgabeZuteilungId=${aufgabeZuteilung.id}`} target="_blank" className={`ikon ${aufgabeZuteilung.dokumentIcon}`}/>
            </span>
          </div>
          {aufgabeZuteilung.dokumentIcon === 'ikon-video' &&
          <div className="zeile">
            <VideoTagging aufgabeZuteilung={aufgabeZuteilung}/>
          </div>
          }
        </>
        }
        {aufgabeZuteilung.beurteilungAbgeschlossen &&
        <div className="zeile">
          {texte.beurteilung}
          <div className="kommentar">{aufgabeZuteilung.beurteilung}</div>
        </div>
        }
        {istBearbeitbar &&
        <div className="zeile">
          <button className="btn btn-primary" onClick={speichernClick}>{texte.speichern}</button>
          <button className="btn btn-success pull-right" onClick={abschliessenClick}>{texte.abschliessen}</button>
        </div>
        }
      </>
      }
      {(istBearbeitbar || meineArbeitsraumEintraege.length > 0) &&
      <div className="zeile abgrenzen">
        <div className="zeile">
          <button className={`btn btn-mini ${hatUngelesene ? 'btn-warning' : ''}`} onClick={() => setzeChatMode(!chatMode)}>
            <i className="icon-envelope"/>
            {meineArbeitsraumEintraege.length > 0 &&
            <span className="anzahl">({meineArbeitsraumEintraege.length})</span>
            }
          </button>
        </div>
        {chatMode &&
        <ArbeitsraumListe arbeitsraumListe={meineArbeitsraumEintraege} aufgabeZuteilung={aufgabeZuteilung}/>
        }
      </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ausbildung: state.ausbildung,
    arbeitsraumListe: state.arbeitsraumListe,
    fileUpload: state.fileUpload,
    dokumentPfad: state.api.update,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    arbeitsraumGelesen: (arbeitsraumIdListe) => { dispatch(arbeitsraumGelesen(arbeitsraumIdListe, null)); },
    aufgabeUpdate: (aufgabeZuteilung, zusatzdaten) => { dispatch(aufgabeZuteilungUpdate(aufgabeZuteilung, zusatzdaten)); },
    aufgabeFileUpload: (aufgabeZuteilung, datei) => { dispatch(aufgabeZuteilungFileUpload(aufgabeZuteilung, datei)); },
    fileUploadEntfernen: (aufgabeZuteilungId) => { dispatch(removeFileUpload(aufgabeZuteilungId)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AufgabeBearbeiten);
