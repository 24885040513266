import React, {useState} from "react";
import {connect} from "react-redux";
import {ausbildungSpeichern} from "../../state/ausbildnerReducer";
import {AusbildungStatus} from "../../lib/constants";
import ModalerDialog from "../../../../components/ModalerDialog";

const KurzfristigAbmelden = ({ausbildner, abmeldungStatusListe, abschlussStatusListe, abmeldegruende, texte, ausbildungSpeichern}) => {
  const [editPerson, setEditPerson] = useState(undefined);
  const [anmeldenPerson, setAnmeldenPerson] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [grund, setGrund] = useState(undefined);
  const [bemerkung, setBemerkung] = useState('');
  const ausbildnerAngemeldet = ausbildner.filter(a => [...abschlussStatusListe.map(s => s.id), AusbildungStatus.Angemeldet, AusbildungStatus.Anwesend].includes(a.ausbildungStatusId));
  const ausbildnerAbgemeldet = ausbildner.filter(a => abmeldungStatusListe.map(s => s.id).includes(a.ausbildungStatusId));

  const personAbmeldenClick = () => {
    if (editPerson && status && grund) {
      ausbildungSpeichern(editPerson, status, { bemerkungen: bemerkung, abmeldegrundId: grund });
      resetAbmelden();
    }
  };

  const personAbmelden = (ausbildner) => {
    resetAbmelden();
    setEditPerson(ausbildner);
    if(abmeldungStatusListe.includes(ausbildner.ausbildungStatusId)) {
      setStatus(ausbildner.ausbildungStatusId);
      setGrund(ausbildner.abmeldegrundId);
      setBemerkung(ausbildner.bemerkung);
    }
  };

  const personWiederanmeldenClick = () => {
    if (anmeldenPerson) {
      ausbildungSpeichern(anmeldenPerson, AusbildungStatus.Angemeldet, { bemerkungen: '', abmeldegrundId: 0 });
      resetAbmelden();
    }
  };

  const personWiederanmelden = (ausbildner) => {
    resetAbmelden();
    setAnmeldenPerson(ausbildner);
  };

  const resetAbmelden = () => {
    setEditPerson(undefined);
    setAnmeldenPerson(undefined);
    setGrund(undefined);
    setStatus(undefined);
    setBemerkung('');
  };

  return (
    <>
      <div className="block">
        <div className="header">
          {texte.teilnehmer}
        </div>
        <div className="content btn-liste">
          {ausbildnerAngemeldet.map(a => <button key={a.id} className="btn" onClick={() => personAbmelden(a)}>{a.name}</button>)}
        </div>
      </div>
      {ausbildnerAbgemeldet.length > 0 &&
      <div className="block">
        <div className="header">
          {texte.teilnehmerAbgemeldet}
        </div>
        <div className="content btn-liste">
          {ausbildnerAbgemeldet.map(a => <button key={a.id} className="btn" onClick={() => personWiederanmelden(a)}>{a.name}</button>)}
        </div>
      </div>
      }
      {editPerson !== undefined &&
      <ModalerDialog>
        <div className="block">
          <div className="header">
            <b>{texte.ansichten.kurzfristigAbmelden}</b>
          </div>
          <div className="content edit">
            <div className="zeile">
              <label>&nbsp;</label>
              <label>{editPerson.name}</label>
            </div>
            <div className="zeile">
              <label>{texte.ausbildungStatus}*</label>
              <select onChange={event => setStatus(event.currentTarget.value)} value={status}>
                <option value=""/>
                {abmeldungStatusListe.map(grund => <option key={grund.id} value={grund.id}>{grund.name}</option>)}
              </select>
            </div>
            <div className="zeile">
              <label>{texte.kursleiter.abmeldegrund}*</label>
              <select onChange={event => setGrund(event.currentTarget.value)} value={grund}>
                <option value=""/>
                {abmeldegruende.map(grund => <option key={grund.id} value={grund.id}>{grund.name}</option>)}
              </select>
            </div>
            <div className="zeile">
              <label>{texte.kursleiter.bemerkungen}</label>
              <textarea className="inline" rows="5" value={bemerkung} onChange={(event => setBemerkung(event.currentTarget.value))}/>
            </div>
            <div className="zeile">
              <button className="btn btn-default" onClick={resetAbmelden}>{texte.abbrechen}</button>
              <button className="btn btn-primary pull-right" onClick={personAbmeldenClick}>{texte.kursleiter.abmelden}</button>
            </div>
          </div>
        </div>
      </ModalerDialog>
      }
      {anmeldenPerson !== undefined &&
      <ModalerDialog>
        <div className="block">
          <div className="header">
            <b>{texte.kursleiter.wiederanmeldung}</b>
            &nbsp;{anmeldenPerson.name}
          </div>
          <div className="content edit">
            <div className="zeile">
              <button className="btn btn-default" onClick={resetAbmelden}>{texte.abbrechen}</button>
              <button className="btn btn-primary pull-right" onClick={personWiederanmeldenClick}>{texte.kursleiter.wiederanmeldung}</button>
            </div>
          </div>
        </div>
      </ModalerDialog>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ausbildner: state.ausbildner,
    abmeldungStatusListe: state.abmeldungStatusListe,
    abschlussStatusListe: state.abschlussStatusListe,
    abmeldegruende: state.abmeldegruende,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ausbildungSpeichern: (ausbildner, statusId, bemerkungen) => { dispatch(ausbildungSpeichern(ausbildner, statusId, bemerkungen)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KurzfristigAbmelden);
