import {combineReducers} from 'redux';
import sponsorenlaufReducer from "./modules/sponsorenlauf";
import sponsorenlaufPflichtbeitraegeReducer from "./modules/sponsorenlaufPflichtbeitraege";
import sponsorenlaufSpielerReducer from "./modules/sponsorenlaufSpieler";
import sponsorenlaufSponsorenReducer from "./modules/sponsorenlaufSponsoren";
import sponsorenlaufBezeichnungenReducer from "./modules/sponsorenlaufBezeichnungen";
import {dummyReducer} from "../../../lib/datenhaltung";
import rollbarReducer from "../../../reducers/rollbar";
import sponsorenlaufListenReducer from "./modules/sponsorenlaufListen";
import laufendeRequestsReducer from "./modules/laufendeAbfragen";

export default combineReducers({
  sponsorenlauf: sponsorenlaufReducer,
  pflichtbeitraege: sponsorenlaufPflichtbeitraegeReducer,
  spieler: sponsorenlaufSpielerReducer,
  sponsoren: sponsorenlaufSponsorenReducer,
  bezeichnungen: sponsorenlaufBezeichnungenReducer,
  listen: sponsorenlaufListenReducer,
  laufendeRequests: laufendeRequestsReducer,
  api: dummyReducer,
  i18n: dummyReducer,
  rollbar: rollbarReducer,
  bexio: dummyReducer,
});
