import React from "react";
import {connect} from "react-redux";
import Popover from "../../../components/Popover";

const BexioResourceFehler = ({resourceFehler, texteBexio}) => {
  const renderContent = () => {
    return (
      <>
        {resourceFehler.referenceTyp === 'spieler_sponsor' &&
        <div className="alert alert-info">{texteBexio.fehlerSponsorenlaufSponsor}</div>
        }
        <ul>
          {resourceFehler.fehler.map((text, index) => <li key={index}>{text}</li>)}
        </ul>
      </>
    );
  };
  
  return (
    <Popover title={texteBexio.fehler} content={renderContent()}>
      <span className="label label-warning">
        <i className="icon-warning-sign"/>
      </span>
    </Popover>
  );
};

const mapStateToProps = (state) => {
  return {
    texteBexio: state.i18n.texte.bexio,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BexioResourceFehler);

