import {executeRequest} from "../lib/api";
import {bibliothekReferenzSetzen} from "./teamTeilnehmerReducer";
import {ersetzeStringMitDatum} from "../lib/formatierungen";
import axios from "axios";
import {endeFileUpload, errorFileUpload, startFileUpload, updateFileUpload} from "./fileUpload";

const TEILNEHMER_AUFGABE_EDIT = 'TEILNEHMER_AUFGABE_EDIT';

export function sortiereAufgabenZuteilungen(a, b) {
  return a.bibliothek.reihenfolge - b.bibliothek.reihenfolge;
}

export function aufgabeZuteilungUpdate(aufgabeZuteilung, zusatzdaten) {
  return (dispatch, getState) => {
    const state = getState();
    const daten = {
      ...zusatzdaten,
      kontext: 'aufgabe_update',
      aufgabeZuteilungId: aufgabeZuteilung.id
    };
    return executeRequest(state.api.update, state.api.token, 'PUT', daten, (result) => {
      const az = bibliothekReferenzSetzen(result.data.aufgabeZuteilung, state.bibliothek);
      ersetzeStringMitDatum(az, ['von', 'bis'])
      dispatch({type: TEILNEHMER_AUFGABE_EDIT, aufgabeZuteilung: az});
    });
  };
}

export function aufgabeZuteilungFileUpload(aufgabeZuteilung, datei) {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(startFileUpload(aufgabeZuteilung.id));

    const formPayload = new FormData();
    formPayload.append('kontext', 'aufgabe_update');
    formPayload.append('aufgabeZuteilungId', aufgabeZuteilung.id);
    formPayload.append('authenticity_token', state.api.token);
    formPayload.append('file', datei);

    const token = document.querySelector('meta[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

    axios.request({
      method: 'PUT',
      url: state.api.update,
      data: formPayload,
      onUploadProgress: (p) => {
        dispatch(updateFileUpload(aufgabeZuteilung.id, 100 * (p.loaded / p.total)));
      }
    }).then(result => {
      const az = bibliothekReferenzSetzen(result.data.aufgabeZuteilung, state.bibliothek);
      ersetzeStringMitDatum(az, ['von', 'bis'])
      dispatch({type: TEILNEHMER_AUFGABE_EDIT, aufgabeZuteilung: az});

      dispatch(endeFileUpload(aufgabeZuteilung.id));
    }).catch(error => {
      dispatch(errorFileUpload(aufgabeZuteilung.id));
    });
  };
}

function teilnehmerAufgabenReducer(state = [], action) {
  switch (action.type) {
    case TEILNEHMER_AUFGABE_EDIT:
      const liste = [
        ...state.filter(az => az.id !== action.aufgabeZuteilung.id),
        action.aufgabeZuteilung
      ];
      liste.sort(sortiereAufgabenZuteilungen);
      return liste;
    default:
      return state;
  }

}

export default teilnehmerAufgabenReducer;
