import React from "react";
import ControlGroup from "./ControlGroup";

const Adresse = ({person, texte, laender, onWertChange}) => {
  const adresse = person.post_adresse_attributes;
  if (!adresse) {
    return <></>;
  }
  return (
    <section>
      <h3 className="page-section">{texte.titel}</h3>
      <div className="row-fluid">
        <div className="span6">
          <ControlGroup labelText={texte.zusatz} htmlFor="zusatz" errors={adresse.errors.zusatz}>
            <input type="text" id="zusatz" value={adresse.zusatz || ''} onChange={event => onWertChange('zusatz', event.currentTarget.value)}/>
          </ControlGroup>
          <ControlGroup labelText={texte.strasse} htmlFor="strasse" errors={adresse.errors.strasse}>
            <input type="text" id="strasse" value={adresse.strasse || ''} onChange={event => onWertChange('strasse', event.currentTarget.value)}/>
          </ControlGroup>
          <ControlGroup labelText={texte.plz} htmlFor="plz" errors={adresse.errors.plz}>
            <input type="text" id="plz" value={adresse.plz || ''} onChange={event => onWertChange('plz', event.currentTarget.value)}/>
          </ControlGroup>
        </div>
        <div className="span6">
          <ControlGroup labelText={texte.ort} htmlFor="ort" errors={adresse.errors.ort}>
            <input type="text" id="ort" value={adresse.ort || ''} onChange={event => onWertChange('ort', event.currentTarget.value)}/>
          </ControlGroup>
          <ControlGroup labelText={texte.kanton} htmlFor="kanton" errors={adresse.errors.kanton}>
            <input type="text" id="kanton" value={adresse.kanton || ''} onChange={event => onWertChange('kanton', event.currentTarget.value)}/>
          </ControlGroup>
          <ControlGroup labelText={texte.land} htmlFor="land" errors={adresse.errors.land}>
            <select id="land" value={adresse.land_id || ''} onChange={event => onWertChange('land_id', event.currentTarget.value)}>
              {laender && laender.map(l => <option key={l.id} value={l.id}>{l.bezeichnung}</option>)}
            </select>
          </ControlGroup>
        </div>
      </div>
    </section>
  );
};
export default Adresse;
