import React from "react";
import useGlobal from "../../store";

const GameSubheader = () => {
  const [globalState] = useGlobal();

  return (
    <div className="game-subheader subtitle">
      <b>{globalState.spielInfos.liga}</b>
      <br />
      {globalState.spielInfos.untertitel.map((row, i) => (
        <div key={i}>{row}</div>
      ))}
    </div>
  );
};

export default GameSubheader;
