
function preloader(options) {
  return {
    team: options.team,
    spieler: options.spieler,
    listen: options.listen,
    mirwald: [],
    sportmotorik: [],
    sportspezifisch: [],
    api: options.api,
    i18n: options.i18n,
    rollbar: options.rollbar,
  };
}

export default preloader;
