import {combineReducers} from 'redux';
import {dummyReducer} from "../../../lib/datenhaltung";
import apiReducer from "./modules/api";
import bewertungen from "./modules/bewertungen";
import selektiert from "./modules/selektiert";

export default combineReducers({
  spielerliste: dummyReducer,
  bewertungen: bewertungen,
  selektiert: selektiert,
  listen: dummyReducer,
  api: apiReducer,
  i18n: dummyReducer,
  rollbar: dummyReducer
});
