import React from "react";
import {connect} from "react-redux";

const FormCheckbox = ({name, checked, readonly = false, onCheckedChange, texte}) => {
  const wert = checked === true;

  if (readonly) {
    return <>{wert ? texte.ja : texte.nein}</>;
  }

  return (
    <span className="control-group boolean optional">
      <span className="checkbox inline">
        <input type="checkbox" checked={wert} onChange={ev => onCheckedChange(name, ev.currentTarget.checked)}/>
      </span>
    </span>
  );
};

const mapStateToProps = (state) => {
  return {
    texte: state.i18n.texte
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FormCheckbox);
