// @flow

import type {
  AppState,
  EinsatzState,
  SpielerEintrag,
  SpielerTips,
  SpielerkarteEintrag,
  SpielState
} from "../../../state/stateTypes";
import type { Dispatch } from "../../../state/actionTypes";

import React from "react";
import { connect } from "react-redux";

import { spielerTipsKommentarSetzen } from "../../../state/modules/spieler";
import Textfeld from "../../Textfeld";
import SpielerPrognoseFragen from "./SpielerPrognoseFragen";
import SpielerRow from "../SpielerRow";
import AndereTipsRow from "../AndereTipsRow";

const SpielerkarteSpieler = ({ einsatz, spiel, spielerTipsKommentarSetzen, spieler, spielerEinsatz }: Props) => {

  const aktiv = !einsatz.readonly;
  const requestDaten = { spielId: spiel.id, spielerId: spieler.id, spielerTips: spieler.tips };

  const prognoseFragen = spieler.prognoseFragen;
  const prognoseWert = !prognoseFragen ? 0 : prognoseFragen.prognose || [1, 2, 3, 4, 5, 6].filter(frageNr => prognoseFragen[`frage${frageNr}`].selektiert == true).length;

  const kommentarAnzeigen = aktiv ? spieler.tips.kommentarAufgeklappt : (spieler.tips.kommentar || '').length > 0;
  const weitereTipsAnzeigen = einsatz.andereTipsAnzeigen ? spieler.tips.weitereTipsAufgeklappt : false;
  const prognoseAnzeigen = prognoseFragen && prognoseFragen.prognoseAufgeklappt;

  return (
    <tbody>
    <SpielerRow spieler={spieler} spielerEinsatz={spielerEinsatz} requestDaten={requestDaten}
                prognoseWert={prognoseWert} typ={'spiel'} andereTipsAnzeigen={einsatz.andereTipsAnzeigen}/>
    {prognoseAnzeigen &&
      <tr>
        <td colSpan="14" className="text-right">
          <div style={{ display: 'inline-block' }}>
            <SpielerPrognoseFragen spieler={spieler}/>
          </div>
        </td>
      </tr>
    }
    {kommentarAnzeigen &&
      <tr>
        <td colSpan="14" className="text-right">
          <Textfeld
            feldName="tipsKommentar"
            feldWert={spieler.tips.kommentar || ''}
            requestDaten={requestDaten}
            breite='388px'
            onChange={(event) => {spielerTipsKommentarSetzen(spieler.id, event.target.value, spieler.tips)}}
          />
        </td>
      </tr>
    }
    {(weitereTipsAnzeigen) &&
      <AndereTipsRow spieler={spieler}/>
    }
    </tbody>
  );
};

type Props = {
  einsatz: EinsatzState,
  spiel: SpielState,
  spielerTipsKommentarSetzen: (number, string, SpielerTips) => void,
  spieler: SpielerEintrag,
  spielerEinsatz: SpielerkarteEintrag,
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    spiel: state.spiel,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    spielerTipsKommentarSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsKommentarSetzen(spielerId, neuerWert, altesTips));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpielerkarteSpieler);
