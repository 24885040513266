import React from "react";
import {connect} from "react-redux";
import {meldungEntfernen} from "../state/modules/meldungen";

const Meldungen = ({meldungen, meldungEntfernen}) => {
  if (meldungen.length === 0) {
    return <></>;
  }

  return (
    <div className="meldungen-container">
      {meldungen.map((meldung, index) => {
        return (
          <div key={index} className={`alert alert-${meldung.level}`}>
            <button type="button" className="close" onClick={() => meldungEntfernen(meldung)}>×</button>
            {meldung.text}
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    meldungen: state.meldungen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    meldungEntfernen: (meldung) => {
      dispatch(meldungEntfernen(meldung))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Meldungen);
