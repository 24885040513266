import React, {useState} from "react";
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import deLocale from '@fullcalendar/core/locales/de';
import frLocale from '@fullcalendar/core/locales/fr';
import itLocale from '@fullcalendar/core/locales/it';
import {connect} from "react-redux";
import {EventFarben} from "../../lib/constants";
import KalenderPopup from "./KalenderPopup";
import {teamSpeichern} from "../../state/teamsReducer";
import Position, {POSITION} from "../../../../components/Position";
import {formatiereZeit} from "../../lib/formatierungen";

const Kalender = ({ausbildung, teams, klassenlehrer, texte, locale, teamSpeichern}) => {
  const [filter, setFilter] = useState([]);
  const [hoverTeam, setHoverTeam] = useState(0);
  const [popover, setPopover] = useState(undefined);
  const [editTeam, setEditTeam] = useState(undefined);
  let kalenderLocale = deLocale;
  switch (locale) {
    case 'fr':
      kalenderLocale = frLocale;
      break;
    case 'it':
      kalenderLocale = itLocale;
      break;
  }
  let teamsFiltered = teams;
  if (filter.length > 0) {
    teamsFiltered = teamsFiltered.filter(team => filter.includes(team.experte.id));
  }
  const events = teamsFiltered.map(team => {
    const lehrerIndex = klassenlehrer.findIndex(kl => kl.id === team.experte.id);
    return {
      id: team.id,
      title: `${team.bezeichnung} - ${team.experte.name}`,
      start: team.von,
      end: team.bis,
      color: EventFarben[lehrerIndex % 10],
      extendedProps: {
        programmBlock: team.programmBlock.name,
        teamId: team.id,
      }
    };
  });

  const toggleFilter = (experteId) => {
    if (filter.includes(experteId)) {
      setFilter(filter.filter(id => id !== experteId));
    } else {
      setFilter([...filter, experteId]);
    }
  };

  const datumSelection = (eventInfo) => {
    // neu erstellen
    setEditTeam({ experte: {}, bezeichnung: '', von: eventInfo.start, bis: eventInfo.end, programmBlock: {} });
  };

  const eventClick = (eventInfo) => {
    // klassenlehrer und bezeichnung bearbeiten
    const team = teams.find(t => t.id === eventInfo.event._def.publicId);
    if (team) {
      setEditTeam(team);
    }
  };

  const eventChange = (eventInfo) => {
    // von-bis neu setzen
    const team = teams.find(t => t.id === eventInfo.event._def.publicId);
    if (team) {
      const range = eventInfo.event._instance.range;
      teamSpeichern(team.experte, { teamId: team.id, von: zeitzoneBereinigen(range.start), bis: zeitzoneBereinigen(range.end) })
    }
  };
  
  const zeitzoneBereinigen = (date) => {
    // TODO: wieso ist das nötig? fullcalendar liefert hier die zeit als utc... konfiguriert ist timeZone='local' und sollte sich anders verhalten!
    const str = date.toISOString().replace(/.000Z/, '');
    return Date.parse(str);
  };
  
  const eventMouseEnter = (info) => {
    const rect = info.el.getBoundingClientRect();
    const teamId = info.event.extendedProps.teamId;
    if (hoverTeam !== teamId && rect.height > 0) {
      if (popover) {
        setHoverTeam(0);
        setPopover(undefined);
        return;
      }
      const team = teams.find(t => t.id === info.event.extendedProps.teamId);
      setHoverTeam(teamId);
      setPopover(<Position className="popover fade in" triggerRect={rect} position={POSITION.top}>
        <div className="arrow"/>
        <h3 className="popover-title">{formatiereZeit(team.von)} - {formatiereZeit(team.bis)}</h3>
        <div className="popover-content">
          <span className="label label-warning">{team.programmBlock.name}</span>
          <br/>
          {team.bezeichnung}
          <hr/>
          {team.experte.name}
        </div>
      </Position>)
    }
  };
  
  const eventMouseLeave = () => {
    setHoverTeam(0);
    setPopover(undefined);
  };
  
  const eventDidMount = (info) => {
    const time = info.el.getElementsByClassName('fc-event-time')[0];
    if (time) {
      time.innerHTML = info.timeText + ' ' + info.event.extendedProps.programmBlock;
    }
  };

  return (
    <div className="kalender">
      <div className="block">
        <div className="header btn-liste">
          {texte.kursleiter.filterExperten}
          {klassenlehrer.map(lehrer => <button key={lehrer.id} className={`btn btn-small ${filter.includes(lehrer.id) ? 'btn-success' : ''}`} onClick={() => toggleFilter(lehrer.id)}>{lehrer.name}</button>)}
        </div>
      </div>
      <FullCalendar
        initialView="timeGridWeek"
        plugins={[interactionPlugin, timeGridPlugin]}
        headerToolbar={{center: 'timeGridDay,timeGridWeek'}}
        locale={kalenderLocale}
        titleFormat={{year: 'numeric', month: 'long', day: 'numeric'}}
        weekNumbers={true}
        navLinks={true}
        allDaySlot={false}
        timeZone="local"
        slotDuration="00:30:00"
        snapDuration="00:15:00"
        slotLabelInterval={ {hours: 1} }
        slotMinTime="07:00:00"
        slotMaxTime="23:00:00"
        height="auto"
        initialDate={ausbildung.von}
        editable={true}
        selectable={true}
        eventClick={eventClick} eventDrop={eventChange} eventResize={eventChange} select={datumSelection}
        eventMouseEnter={eventMouseEnter} eventMouseLeave={eventMouseLeave}
        eventDidMount={eventDidMount}
        events={events}
      />
      {editTeam &&
      <KalenderPopup team={editTeam} onClose={() => setEditTeam(undefined)}/>
      }
      {popover &&
      <>
        {popover}
      </>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ausbildung: state.ausbildung,
    teams: state.teams,
    klassenlehrer: state.klassenlehrer,
    texte: state.i18n.texte,
    locale: state.i18n.locale
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    teamSpeichern: (klassenlehrer, zusatzdaten) => { dispatch(teamSpeichern(klassenlehrer, zusatzdaten)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Kalender);
