import {combineReducers} from 'redux';

import roomsReducer from "./rooms";
import settingsReducer from "./settings";
import rollbarReducer from "../../../reducers/rollbar";

function dummyReducer(state = {}) {
  return state;
}

export default combineReducers({
  person: dummyReducer,
  rooms: roomsReducer,
  settings: settingsReducer,
  i18n: dummyReducer,
  api: dummyReducer,
  rollbar: rollbarReducer,
});
