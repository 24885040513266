import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../components/ModalerDialog";
import {executeGet, executeJson} from "../lib/fetchHelper";
import {sportspezifischSpielerEntfernen, sportspezifischSpielerUpdate} from "../state/modules/sportspezifisch";
import PersonBadge from "../../../components/PersonBadge";
import {ersetzeStringMitDatum} from "../../../lib/datenhaltung";
import {formatiereDatum} from "../../../lib/formatierungen";
import LabelInputStruktur from "./LabelInputStruktur";

const SportspezifischModal = ({phase, spieler, onHide, locale, texte, api, sportspezifischSpielerUpdate, sportspezifischSpielerEntfernen}) => {
  const [sportspezifisch, setSportspezifisch] = useState(null);
  const [xhrAktiv, setXhrAktiv] = useState(false);
  const texteSportspezifisch = texte.sportspezifisch;
  const readonly = sportspezifisch && sportspezifisch.abgeschlossen;

  const schliessen = () => {
    onHide();
  };

  useEffect(() => {
    // lade mirwald von server
    setXhrAktiv(true);
    executeGet(`${api.sportspezifisch}/${spieler.id}`, {phase: phase})
      .then(result => {
        const motorik = ersetzeStringMitDatum(result.data.sportspezifisch, ['datum_zeit', 'datum_abgeschlossen']);
        setSportspezifisch(motorik);
        if (motorik.id > 0) {
          sportspezifischSpielerUpdate(motorik);
        }
        setXhrAktiv(false);
      })
      .catch(() => setXhrAktiv(false));
  }, []);

  const speichern = (aktion = null) => {
    const daten = {
      id: spieler.id,
      phase: phase,
      aktion: aktion,
      sportspezifisch: {...sportspezifisch}
    };
    delete daten.sportspezifisch.errors;
    setXhrAktiv(true);
    executeJson(`${api.sportspezifisch}/${spieler.id}`, api.token, 'PUT', daten)
      .then(result => {
        const motorik = ersetzeStringMitDatum(result.data.sportspezifisch, ['datum_zeit', 'datum_abgeschlossen']);
        setSportspezifisch(motorik);
        sportspezifischSpielerUpdate(motorik);
        setXhrAktiv(false);
      })
      .catch(() => setXhrAktiv(false));
  };

  const loeschen = () => {
    setXhrAktiv(true);
    executeJson(`${api.sportspezifisch}/${spieler.id}`, api.token, 'DELETE', {phase: phase})
      .then(result => {
        if (result.ok) {
          setSportspezifisch(null);
          sportspezifischSpielerEntfernen(sportspezifisch);
          setXhrAktiv(false);
          schliessen();
        }
      })
      .catch(() => setXhrAktiv(false));
  };

  const setzeWert = (key, value) => {
    const daten = {...sportspezifisch};
    if (value === '') {
      daten[key] = null;
    } else {
      daten[key] = Number(value);
    }
    
    if (daten.score_durchschnitt_punkte > 0 && daten.score_durchschnitt_note > 0) {
      daten.score_gesamt = (daten.score_durchschnitt_punkte + daten.score_durchschnitt_note).toFixed(1);
      const scoreProzent = 100 / 8 * daten.score_gesamt;
      daten.score_prozent = scoreProzent.toFixed(1);
      daten.note_durchschnitt = (5 / 100 * scoreProzent).toFixed(1);
    } else {
      daten.score_gesamt = null;
      daten.score_prozent = null;
      daten.note_durchschnitt = null;
    }

    setSportspezifisch(daten);
  };

  const setzeText = (key, value) => {
    const daten = {...sportspezifisch};
    daten[key] = value;
    setSportspezifisch(daten);
  };

  return (
    <ModalerDialog>
      <div className="modal breit-modal">
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{texteSportspezifisch.titel}</h3>
        </div>
        <div className="modal-body">
          <table>
            <tbody>
            <tr>
              <td style={{verticalAlign: 'top', paddingRight: 10}}><span className="label label-info">{spieler.passnummer}</span></td>
              <td><PersonBadge person={spieler.person} personVerlinken={false} fotoTooltipAktiv={false}/></td>
            </tr>
            </tbody>
          </table>
          {sportspezifisch &&
          <table className="table table-bordered table-condensed attributes">
            <colgroup>
              <col width="25%"/>
              <col width="75%"/>
            </colgroup>
            <tbody>
            <tr>
              <LabelInputStruktur model={sportspezifisch} name={'score_durchschnitt_punkte'} texte={texteSportspezifisch}>
                <input id="score_durchschnitt_punkte" disabled={sportspezifisch.abgeschlossen} type="number" min={1} max={1000} step={0.1} value={sportspezifisch.score_durchschnitt_punkte !== null ? sportspezifisch.score_durchschnitt_punkte : ''} onChange={(ev) => setzeWert('score_durchschnitt_punkte', ev.currentTarget.value)}/>
              </LabelInputStruktur>
            </tr>
            <tr>
              <LabelInputStruktur model={sportspezifisch} name={'score_durchschnitt_note'} texte={texteSportspezifisch}>
                <input id="score_durchschnitt_note" disabled={sportspezifisch.abgeschlossen} type="number" min={1} max={5} step={0.1} value={sportspezifisch.score_durchschnitt_note !== null ? sportspezifisch.score_durchschnitt_note : ''} onChange={(ev) => setzeWert('score_durchschnitt_note', ev.currentTarget.value)}/>
              </LabelInputStruktur>
            </tr>
            <tr>
              <LabelInputStruktur model={sportspezifisch} name={'bemerkungen'} texte={texteSportspezifisch}>
                <input id="bemerkungen" disabled={sportspezifisch.abgeschlossen} type="text" className="input-100prozent" value={sportspezifisch.bemerkungen || ''} onChange={ev => setzeText('bemerkungen', ev.currentTarget.value)} />
              </LabelInputStruktur>
            </tr>
            <tr>
              <LabelInputStruktur model={sportspezifisch} name={'verletzt'} texte={texteSportspezifisch}>
                <input type="checkbox" disabled={sportspezifisch.abgeschlossen} checked={sportspezifisch.verletzt} onChange={ev => setzeWert('verletzt', ev.currentTarget.checked)} />
              </LabelInputStruktur>
            </tr>
            <tr>
              <th><label>{texteSportspezifisch.score_gesamt}</label></th>
              <td>{sportspezifisch.score_gesamt}</td>
            </tr>
            <tr>
              <th><label>{texteSportspezifisch.score_prozent}</label></th>
              <td>{sportspezifisch.score_prozent > 0 && `${sportspezifisch.score_prozent}%`}</td>
            </tr>
            <tr>
              <th><label>{texte.note_durchschnitt}</label></th>
              <td>{sportspezifisch.note_durchschnitt}</td>
            </tr>
            {sportspezifisch.abgeschlossen &&
            <tr>
              <th><label className="text-bold">{texte.datum_abgeschlossen}</label></th>
              <td>
                <span className="label">{formatiereDatum(sportspezifisch.datum_abgeschlossen)}</span>
              </td>
            </tr>
            }
            </tbody>
          </table>
          }
        </div>
        <div className="modal-footer">
          {sportspezifisch && sportspezifisch.id > 0 && !readonly &&
          <button type="button" className="btn btn-success" onClick={() => speichern('abschliessen')}>{texte.abschliessen}</button>
          }
          {sportspezifisch && readonly === false &&
          <button type="submit" className="btn btn-primary" onClick={(ev) => { ev.currentTarget.blur(); speichern(); }}>
            {texte.speichern}
            {xhrAktiv &&
            <>&nbsp;<i className="fa fa-refresh fa-spin"></i></>
            }
          </button>
          }
          {sportspezifisch && sportspezifisch.id > 0 && readonly &&
          <button type="button" className="btn btn-warning" onClick={() => speichern('bearbeiten')}>{texte.bearbeiten}</button>
          }
          <button type="button" className="btn" onClick={schliessen}>{texte.schliessen}</button>
          {sportspezifisch && sportspezifisch.id > 0 && !readonly &&
          <div className="pull-left">
            <button type="button" className="btn btn-danger" onClick={loeschen}>{texte.loeschen}</button>
          </div>
          }
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    locale: state.i18n.locale,
    texte: state.i18n.texte,
    api: state.api,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sportspezifischSpielerUpdate: (sportspezifisch) => { dispatch(sportspezifischSpielerUpdate(sportspezifisch)); },
    sportspezifischSpielerEntfernen: (sportspezifisch) => { dispatch(sportspezifischSpielerEntfernen(sportspezifisch)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SportspezifischModal);
