import {combineReducers} from 'redux';
import ausbildnerReducer from "./ausbildnerReducer";
import teamTeilnehmerReducer from "./teamTeilnehmerReducer";
import arbeitsraumReducer from "./arbeitsraumReducer";
import rollbarReducer from "../../../reducers/rollbar";

function dummyReducer(state = {}, action) {
  return state;
}

export default combineReducers({
  ausbildung: dummyReducer,
  experte: dummyReducer,
  ausbildner: ausbildnerReducer,
  teams: dummyReducer,
  teamTeilnehmer: teamTeilnehmerReducer,
  arbeitsraumListe: arbeitsraumReducer,
  bibliothek: dummyReducer,
  abschlussStatusListe: dummyReducer,
  api: dummyReducer,
  i18n: dummyReducer,
  rollbar: rollbarReducer,
});
