import React, {useState} from "react";
import {connect} from "react-redux";
import ErgebnisTrainer from "./ErgebnisTrainer";
import KlassenListe from "./KlassenListe";
import FreigegebeneAufgaben from "../FreigegebeneAufgaben";

const VIEWS = {
  aufgaben: 'aufgaben',
  freigegebeneAufgaben: 'freigegebeneAufgaben',
};

const Ansichten = ({texte}) => {
  const [ansicht, setAnsicht] = useState(VIEWS.aufgaben);

  const navClick = (event, neueAnsicht) => {
    event.stopPropagation();
    event.preventDefault();
    setAnsicht(neueAnsicht);
  };

  return (
    <>
      <div>
        <ul className="nav nav-tabs">
          <li className={ansicht === VIEWS.aufgaben ? 'active' : ''}>
            <a href="" onClick={(ev) => navClick(ev, VIEWS.aufgaben)}>{texte.aufgaben}</a>
          </li>
          <li className={ansicht === VIEWS.freigegebeneAufgaben ? 'active' : ''}>
            <a href="" onClick={(ev) => navClick(ev, VIEWS.freigegebeneAufgaben)}>{texte.freigegebeneAufgaben}</a>
          </li>
        </ul>
      </div>
      {ansicht === VIEWS.aufgaben &&
      <>
        <ErgebnisTrainer/>
        <KlassenListe/>
      </>
      }
      {ansicht === VIEWS.freigegebeneAufgaben &&
      <FreigegebeneAufgaben/>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ausbildung: state.ausbildung,
    teams: state.teams,
    hatPruefungErgebnis: state.ausbildung.hatPruefungErgebnis,
    texte: state.i18n.texte.ansichten,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Ansichten);
