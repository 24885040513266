import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {groupBy, sortiereTeam} from "../../lib/formatierungen";
import Datepicker from "../Datepicker";
import ZeitslotZeitpunkt from "./ZeitslotZeitpunkt";
import AusbildnerListe from "./AusbildnerListe";
import IconToggle from "../IconToggle";
import {createZeitslotKey, Zeitslot} from "../../lib/models";
import {teamSpeichern} from "../../state/teamsReducer";

const ZeitslotListe = ({ausbildung, klassenlehrer, teams, programmBlockListe, texte, teamSpeichern}) => {
  const [showZeitbereichErstellen, setShowZeitbereichErstellen] = useState(false);
  const [zeitslots, setZeitslots] = useState([]);
  const [von, setVon] = useState(ausbildung.von);
  const [bis, setBis] = useState(ausbildung.bis);
  const [bezeichnung, setBezeichnung] = useState('');
  const [experteId, setExperteId] = useState(undefined);
  const [programmBlockId, setProgrammBlockId] = useState(undefined);

  useEffect(() => {
    // alle teams nach zeitslot gruppieren, damit sie einfach dargestellt werden können
    const map = groupBy(teams, tt => createZeitslotKey(tt));
    const slots = [...map.values()]
      .map(t => new Zeitslot(t))
      .sort((a, b) => sortiereTeam(a, b));
    setZeitslots(slots);
  }, [teams]);

  const erstelleTeam = () => {
    const experte = klassenlehrer.find(kl => kl.id === experteId);
    if (experte && bezeichnung && programmBlockId) {
      teamSpeichern(experte, {von: von, bis: bis, bezeichnung: bezeichnung, programmBlockId: programmBlockId});
    }
  };

  return <>
    <div className="block">
      <div className="header">
        {texte.kursleiter.zeitbereichErstellen}
        <button className="btn btn-mini pull-right" onClick={() => setShowZeitbereichErstellen(!showZeitbereichErstellen)}>
          <IconToggle istOffen={showZeitbereichErstellen}/>
        </button>
      </div>
      {showZeitbereichErstellen &&
      <div className="content edit">
        <div className="zeile">
          <label>{texte.datumVon}</label>
          <Datepicker datum={von} changeHandler={setVon} mitZeit={true}/>
        </div>
        <div className="zeile">
          <label>{texte.datumBis}</label>
          <Datepicker datum={bis} changeHandler={setBis} mitZeit={true}/>
        </div>
        <div className="zeile">
          <label>{texte.experte}</label>
          <select className='select' value={experteId} onChange={(ev) => setExperteId(ev.currentTarget.value)}>
            <option value=""/>
            {klassenlehrer.map(lehrer => <option key={lehrer.id} value={lehrer.id}>{`${lehrer.name} (${lehrer.funktion})`}</option>)}
          </select>
        </div>
        <div className="zeile">
          <label>{texte.teamBezeichnung}</label>
          <input type="text" value={bezeichnung} onChange={(ev) => setBezeichnung(ev.currentTarget.value)}/>
        </div>
        <div className="zeile">
          <label>{texte.teamProgrammBlock}</label>
          <select className='select' value={programmBlockId} onChange={(ev) => setProgrammBlockId(ev.currentTarget.value)}>
            <option value=""/>
            {programmBlockListe.map(block => <option key={block.id} value={block.id}>{block.name}</option>)}
          </select>
        </div>
        <div className="zeile">
          <button className="btn btn-primary" onClick={erstelleTeam}>{texte.kursleiter.erstellen}</button>
        </div>
      </div>
      }
    </div>
    <AusbildnerListe istOffen={false} />
    {zeitslots.map(slot => <ZeitslotZeitpunkt key={slot.key} zeitslot={slot}/>)}
  </>;
};

const mapStateToProps = (state) => {
  return {
    ausbildung: state.ausbildung,
    klassenlehrer: state.klassenlehrer,
    teams: state.teams,
    programmBlockListe: state.programmBlockListe,
    texte: state.i18n.texte
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    teamSpeichern: (klassenlehrer, zusatzdaten) => { dispatch(teamSpeichern(klassenlehrer, zusatzdaten)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ZeitslotListe);
