// @flow

import type {AppState, AbwesendeEintrag, SpielerEintrag, Spielerkarte, SpielerState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

const Spielerbank = (props: {
  spielerliste: SpielerState,
  i18n: any,
  spielerkarte: Spielerkarte,
}) => {
  return (
    <div className={"row-fluid bank-container"}>
      <div>
        <div className={"span4"}>
          <div className={"ersatzbank-label"}>
            {props.i18n.aufstellung.bank.ersatzbank}
          </div>
          <div className={"ersatzbank"}>
            {
              props.spielerkarte.ersatzformation.map((eintrag) => {
                let einsatzSpieler: SpielerEintrag | void = props.spielerliste.liste.find((spieler: SpielerEintrag) => {
                  return spieler.id === eintrag.spielerId;
                });
                if (einsatzSpieler) {
                  return <span className={"spieler ersatz"} key={eintrag.id}>
                    <span className={"nr"}>{eintrag.rueckennummer}</span>
                    <span className={"name"}>{einsatzSpieler.name}</span>
                  </span>;
                } else {
                  return <></>;
                }
              })
            }
          </div>
        </div>
        <div className={"span4"}>
          <div className={"abwesende-label"}>
            {props.i18n.aufstellung.bank.abwesende}
          </div>
          <div className={"abwesende"}>
            {
              props.spielerkarte.abwesende.map((eintrag) => {
                if (eintrag.grund) {
                  return <span className={"spieler"} key={eintrag.id}>
                    <span className={"name"}>{eintrag.name}</span>
                    <span className={"abwesenheit"}>({eintrag.grund})</span>
                  </span>;
                } else {
                  return <span className={"spieler"} key={eintrag.id}>
                    <span className={"name"}>{eintrag.name}</span>
                  </span>;
                }
              })
            }
          </div>
        </div>
        <div className={"span4"}>
          <div className={"staffbank-label"}>
            {props.i18n.aufstellung.bank.staffbank}
          </div>
          <div className={"staffbank"}>
            {
              props.spielerkarte.staffbank.map((eintrag) => {
                return <span className={"staff bank"} key={eintrag.id}>
                  <span className={"name"}>{eintrag.name}</span>
                  <span className={"funktion"}>({eintrag.funktion})</span>
                </span>;
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    spielerliste: state.spielerliste,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Spielerbank);
