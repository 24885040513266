import {ersetzeStringMitDatum} from "../../../lib/datenhaltung";
import {spielerSortieren} from "./modules/sponsorenlaufSpieler";
import {sponsorenSortieren} from "./modules/sponsorenlaufSponsoren";

function preloaderSponsorenlauf(options) {
  return {
    sponsorenlauf: ersetzeStringMitDatum(options.sponsorenlauf, ['datum', 'registrationVon', 'registrationBis']),
    pflichtbeitraege: options.pflichtbeitraege,
    spieler: options.spieler.sort(spielerSortieren),
    sponsoren: options.sponsoren.sort(sponsorenSortieren),
    bezeichnungen: options.bezeichnungen,
    listen: options.listen,
    laufendeRequests: {},
    api: options.api,
    i18n: options.i18n,
    rollbar: options.rollbar,
    bexio: options.bexio,
  };
}

export default preloaderSponsorenlauf;
