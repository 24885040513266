import React, {createRef, useState} from "react";
import {connect} from "react-redux";
import DatumPicker from "../../../../components/DatumPicker";
import {sponsorenlaufCreate, sponsorenlaufEdit, sponsorenlaufUpdate} from "../../state/modules/sponsorenlauf";
import MarkdownEditor from "../../../../components/MarkdownEditor";
import {executeFormData} from "../../../../lib/fetchHelper";
import IkonFuerDatei from "../../../../components/IkonFuerDatei";
import BezeichnungModal from "./BezeichnungModal";

const RenderInput = ({children, sponsorenlauf, labelText, htmlFor = ''}) => {
  const errors = sponsorenlauf.errors[htmlFor];
  return (
    <tr>
      <th>{htmlFor ? <label htmlFor={htmlFor}>{labelText}</label> : <span>{labelText}</span>}</th>
      <td>
        <div className={`control-group${errors ? ' error' : ''}`}>
          <div className="controls">
            {children}
            {errors && errors.join(' ')}
          </div>
        </div>
      </td>
    </tr>
  );
};

const Stammdaten = ({sponsorenlauf, bezeichnungen, listen, laufendeRequests, i18n, api, zurueckLink, sponsorenlaufEdit, sponsorenlaufCreate, sponsorenlaufUpdate}) => {
  const [editBezeichnung, setEditBezeichnung] = useState(null);
  const [ajaxLaueft, setAjaxLaeuft] = useState({bild: false, dokument: false});
  const bildInput = createRef();
  const dokumentInput = createRef();

  const sponsorenlaufGespeichert = sponsorenlauf.id > 0;
  const texte = i18n.texte.sponsorenlauf;
  let publicLink = sponsorenlaufGespeichert ? `${window.location.origin}${api.publicLink}${sponsorenlauf.id}` : null;

  const onWertChange = (key, value) => {
    sponsorenlauf[key] = value;
    sponsorenlaufEdit(sponsorenlauf);
  };

  const onTextChange = (key, value) => {
    sponsorenlauf.texte[key] = value;
    sponsorenlaufEdit(sponsorenlauf);
  };

  const macheUpload = (type) => {
    // file upload eigens machen
    let uploadInput;
    switch (type) {
      case 'bild':
        uploadInput = bildInput.current;
        break;
      case 'dokument':
        uploadInput = dokumentInput.current;
        break;
    }
    if (uploadInput && uploadInput.files.length >= 1) {
      const typeObj = {}
      typeObj[type] = true;
      setAjaxLaeuft({...ajaxLaueft, ...typeObj});
      let data = {
        upload: true,
      };
      data[type] = uploadInput.files[0];

      executeFormData(`${api.sponsorenlauf}/${sponsorenlauf.id}`, api.token, 'PUT', data).then(result => {
        if (result.ok) {
          sponsorenlaufEdit(result.data.sponsorenlauf);
        }
      }).finally(() => {
        uploadInput.value = ''; // file-input immer zurücksetzen
        typeObj[type] = false;
        return setAjaxLaeuft({...ajaxLaueft, ...typeObj});
      });
    }
  };

  const entferneUpload = (type) => {
    const typeObj = {}
    typeObj[type] = true;
    setAjaxLaeuft({...ajaxLaueft, ...typeObj});
    let data = {
      upload: true,
      delete: type
    };
    executeFormData(`${api.sponsorenlauf}/${sponsorenlauf.id}`, api.token, 'PUT', data).then(result => {
      if (result.ok) {
        sponsorenlaufEdit(result.data.sponsorenlauf);
      }
    }).finally(() => {
      typeObj[type] = false;
      return setAjaxLaeuft({...ajaxLaueft, ...typeObj});
    });
  };

  const speichern = (ev) => {
    ev.currentTarget.blur();
    if (laufendeRequests.sponsorenlauf) {
      return; // request läuft bereits
    }
    if (sponsorenlaufGespeichert) {
      sponsorenlaufUpdate(sponsorenlauf);
    } else {
      sponsorenlaufCreate(sponsorenlauf);
    }
  };

  const neueBezeichnungErstellt = (bezeichnungId) => {
    onWertChange('bezeichnungId', bezeichnungId);
    setEditBezeichnung(null);
  };

  return (
    <>
      <section>
        <table className="attributes table">
          <tbody>
          <RenderInput sponsorenlauf={sponsorenlauf} htmlFor="saisonId" labelText={texte.saison}>
            <select id="saisonId" className="select required" value={sponsorenlauf.saisonId || ''} onChange={ev => onWertChange('saisonId', ev.currentTarget.value)}>
              <option value=""></option>
              {listen.saisons.map(s => <option key={s.id} value={s.id}>{s.bezeichnung}</option>)}
            </select>
          </RenderInput>
          <RenderInput sponsorenlauf={sponsorenlauf} htmlFor="datum" labelText={texte.datum}>
            <label className="input-prepend">
              <span className="add-on"><i className="icon-calendar"></i></span>
              <DatumPicker id="datum" value={sponsorenlauf.datum} showTimeSelect={true} locale={i18n.locale} onChange={d => onWertChange('datum', d)}/>
            </label>
          </RenderInput>
          <RenderInput sponsorenlauf={sponsorenlauf} htmlFor="registrationVon" labelText={texte.registrationVon}>
            <label className="input-prepend">
              <span className="add-on"><i className="icon-calendar"></i></span>
              <DatumPicker id="registrationVon" value={sponsorenlauf.registrationVon} showTimeSelect={true} locale={i18n.locale} onChange={d => onWertChange('registrationVon', d)}/>
            </label>
          </RenderInput>
          <RenderInput sponsorenlauf={sponsorenlauf} htmlFor="registrationBis" labelText={texte.registrationBis}>
            <label className="input-prepend">
              <span className="add-on"><i className="icon-calendar"></i></span>
              <DatumPicker id="registrationBis" value={sponsorenlauf.registrationBis} showTimeSelect={true} locale={i18n.locale} onChange={d => onWertChange('registrationBis', d)}/>
            </label>
          </RenderInput>
          <RenderInput sponsorenlauf={sponsorenlauf} htmlFor="publikation" labelText={texte.publikation}>
            <input id="publikation" className="boolean optional" type="checkbox" checked={sponsorenlauf.publikation || false} onChange={ev => onWertChange('publikation', ev.currentTarget.checked)}/>
          </RenderInput>
          <RenderInput sponsorenlauf={sponsorenlauf} htmlFor="bezeichnungId" labelText={texte.bezeichnung}>
            <select id="bezeichnungId" className="select required" value={sponsorenlauf.bezeichnungId || ''} style={{width: 'auto'}} onChange={ev => onWertChange('bezeichnungId', ev.currentTarget.value)}>
              <option value=""/>
              {bezeichnungen.map(bezeichnung => {
                let text = bezeichnung.texte.find(t => t.locale === i18n.locale) || bezeichnung.texte[0];
                return <option key={bezeichnung.id} value={bezeichnung.id}>{text.bezeichnung}</option>;
              })}
            </select>
            &nbsp;
            <button type="button" className="btn" onClick={() => setEditBezeichnung({inaktiv: false, texte: []})}>{i18n.texte.neueBezeichnungErstellen}</button>
          </RenderInput>
          {sponsorenlaufGespeichert &&
            <RenderInput sponsorenlauf={sponsorenlauf} labelText={texte.weblink}>
              {publicLink}&nbsp;
              <button type="button" className="btn btn-mini" onClick={() => navigator.clipboard.writeText(publicLink)}><i className="fa fa-copy"/></button>
            </RenderInput>
          }
          {sponsorenlaufGespeichert &&
            <RenderInput sponsorenlauf={sponsorenlauf} htmlFor="bildpfad" labelText={texte.bildpfad}>
              <input ref={bildInput} id="bildpfad" accept=".png, .jpg, .jpeg, .gif" type="file" className="hide" onChange={() => macheUpload('bild')}/>
              <span className="btn btn-upload" onClick={() => bildInput.current.click()}>{texte.fileButton}</span>
              {ajaxLaueft.bild && <div className="ajax-spinner-klein"/>}
              {sponsorenlauf.bildpfadUrl &&
                <>
                  &nbsp;
                  <img alt="" src={sponsorenlauf.bildpfadUrl} style={{maxWidth: 100, maxHeight: 50}}/>
                  &nbsp;
                  <button className="btn btn-mini" onClick={() => entferneUpload('bild')}><i className="icon icon-trash"/></button>
                </>
              }
            </RenderInput>
          }
          <tr>
            <th><span>{texte.text}</span></th>
            <td>
              <div className={`control-group${sponsorenlauf.errors.text ? ' error' : ''}`}>
                <div className="controls">
                  {i18n.sprachen.map(sprache => {
                    return <React.Fragment key={sprache.id}>
                      {sprache.bezeichnung}
                      <MarkdownEditor markdownUrl={api.markdown} imageUploadsUrl={api.imageUploadsUrl} token={api.token} locale={i18n.locale} value={sponsorenlauf.texte[sprache.locale] || ''} onChange={text => onTextChange(sprache.locale, text)}/>
                    </React.Fragment>;
                  })}
                  {sponsorenlauf.errors.text && sponsorenlauf.errors.text}
                </div>
              </div>
            </td>
          </tr>
          {sponsorenlaufGespeichert &&
            <RenderInput sponsorenlauf={sponsorenlauf} htmlFor="dokumentpfad" labelText={texte.dokumentpfad}>
              <input ref={dokumentInput} id="dokumentpfad" type="file" accept="*/*" className="hide" onChange={() => macheUpload('dokument')}/>
              <span className="btn btn-upload" onClick={() => dokumentInput.current.click()} style={{verticalAlign: 'middle'}}>{texte.fileButton}</span>
              {ajaxLaueft.dokument && <div className="ajax-spinner-klein"/>}
              {sponsorenlauf.dokumentpfadUrl &&
                <>
                  &nbsp;
                  <a target="_blank" href={sponsorenlauf.dokumentpfadUrl} style={{verticalAlign: 'middle'}}><IkonFuerDatei dateiname={sponsorenlauf.dokumentpfad}/></a>
                  &nbsp;
                  <button className="btn btn-mini" onClick={() => entferneUpload('dokument')} style={{verticalAlign: 'middle'}}><i className="icon icon-trash"/></button>
                </>
              }
            </RenderInput>
          }
          </tbody>
        </table>
        <div className="form-actions">
          <button type="submit" className="btn btn-primary" disabled={laufendeRequests.sponsorenlauf} onClick={speichern}>
            {sponsorenlauf.id ? i18n.texte.speichern : i18n.texte.erstellen}
            {laufendeRequests.sponsorenlauf && <>&nbsp;<i className="fa fa-refresh fa-spin"/></>}
          </button>
          &nbsp;
          <a className="btn" href={zurueckLink}>{i18n.texte.zurueck}</a>
        </div>
      </section>
      {editBezeichnung && <BezeichnungModal bezeichnung={editBezeichnung} onClose={id => neueBezeichnungErstellt(id)}/>}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sponsorenlauf: state.sponsorenlauf,
    bezeichnungen: state.bezeichnungen,
    listen: state.listen,
    laufendeRequests: state.laufendeRequests,
    i18n: state.i18n,
    api: state.api,
    zurueckLink: state.api.root
  };
}

const mapDispatchToProps = (dispatch) => {
    return {
      sponsorenlaufEdit: (sponsorenlauf) => { dispatch(sponsorenlaufEdit(sponsorenlauf)); },
      sponsorenlaufCreate: (sponsorenlauf) => { dispatch(sponsorenlaufCreate(sponsorenlauf)); },
      sponsorenlaufUpdate: (sponsorenlauf) => { dispatch(sponsorenlaufUpdate(sponsorenlauf)); },
    };
  }
;

export default connect(mapStateToProps, mapDispatchToProps)(Stammdaten);
