import React from "react";
import useGlobal from "../../../store";

const TickerItem = ({item, editable}) => {
  const [globalState, globalActions] = useGlobal();

  const getIcon = () => {
    if (item.typIcon) {
      return <div className="ikon-wrapper mit-icon">
        <div className="icon">
          <img src={globalState.stammdaten.icons[item.typIcon]} alt={item.typIcon}/>
        </div>
      </div>;
    } else if (item.typId) {
      const iconTyp = globalState.stammdaten.kommentarTypen.filter((typDef) => parseInt(typDef.id.toString()) === parseInt(item.typId.toString()))[0];
      const iconName = iconTyp && iconTyp.icon;
      if (iconName) {
        return <div className="ikon-wrapper mit-icon">
          <div className="icon">
            <img src={globalState.stammdaten.icons[iconName]} alt={item.typIcon}/>
          </div>
        </div>;
      }
    }
    return <div className="ikon-wrapper">
      <div className="dot">&nbsp;</div>
    </div>;
  };

  const handleEdit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    jumpTo(item.video, item.start, false);
    const video = document.querySelector("div.video-wrapper video");
    if (video.pause) video.pause();

    globalActions.setFormItem(item);
    globalActions.setFormVisible(true);
  };

  const jumpTo = (videoId, time, showOverlay, event = null) => {
    if (event) {
      event.stopPropagation();
    }

    const video = document.querySelector("div.video-wrapper video");
    if (videoId) {
      globalActions.selectVideo(videoId);
    }
    if (video && (time || time === 0)) {
      video.currentTime = time;
    }
    if (showOverlay && item.ereignisVideo) {
      if (video) {
        video.pause();
      }
      globalActions.setOverlaySequence(item.ereignisVideo);
    }
  };

  const clickable = (item.start || item.start === 0) || item.ereignisVideo;
  const zeitanzeige = item.zeitVerstecken ? '' : (item.zusatzminute ? `${item.minute}' +${item.zusatzminute}` : `${item.minute}'`);
  const nameAnzeigen = item.teamName || item.spielerName || item.trainerName || item.schiedsrichterName;

  return (
    <div className={`item flex-row ${item.type} ${clickable ? "clickable" : ""}`} onClick={(ev) => jumpTo(item.video, item.start, false, ev)}>
      {getIcon()}
      <div className="time">
        {zeitanzeige}
      </div>
      <div className="flex-1">
        <div className="title">
          <b>{item.titel}</b>
        </div>
        <div className="smaller">{item.beschreibung}</div>
        {nameAnzeigen && 
        <div className="smallerer">{[item.teamName, item.spielerName, item.trainerName, item.schiedsrichterName].filter((e) => e).join(' | ')}</div>
        }
      </div>
      <div className="edit" hidden={!editable}>
        <button className="icon-button" onClick={handleEdit}>
          edit
        </button>
      </div>
      <div className="video" hidden={!item.ereignisVideo}>
        <button className="icon-button" onClick={(ev) => jumpTo(item.video, item.start, true, ev)}>videocam</button>
      </div>
    </div>
  );
};

export default TickerItem;
