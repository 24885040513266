import fetch from "cross-fetch";

export function executeRequest(url, token, method, daten, callback, fehlerCallback) {
  const fetchInit = {
    method: method,
    headers: {
      'x-requested-with': 'XMLHttpRequest',
    },
    credentials: 'same-origin'
  };

  let apiUrl = url;
  if (method === 'GET') {
    if (daten) {
      const params = new URLSearchParams();
      for (let propName in daten) {
        if (daten.hasOwnProperty(propName)) {
          params.append(propName, daten[propName]);
        }
      }
      apiUrl = url + "?" + params.toString()
    }
  } else {
    const form = new FormData();
    fillObjectToFormData(form, daten);
    form.append('authenticity_token', token);
    fetchInit.body = form;
  }

  fetch(apiUrl, fetchInit)
    .then(response => {
      return response.json().then(jsonData => ({data: jsonData, ok: response.ok, status: response.status}));
    })
    .then(jsonResultat => callback(jsonResultat))
    .catch(fehler => {
      if (fehlerCallback) {
        fehlerCallback(fehler);
      } else {
        console.error(fehler);
      }
    });
}

function fillObjectToFormData(formData, daten, prefix = '') {
  for (let propName in daten) {
    if (daten.hasOwnProperty(propName)) {
      const val = daten[propName];
      if (val instanceof Array) {
        fillArray(formData, val, `${prefix}${propName}`);
      } else if (val instanceof Date) { // fällt sonst unter "Object"
        formData.append(`${prefix}${propName}`, JSON.stringify(val));
      } else if (val instanceof File) { // fällt sonst unter "Object"
        formData.append(`${prefix}${propName}`, val);
      } else if (val instanceof Object) {
        fillObjectToFormData(formData, val, `${prefix}[${propName}]`);
      } else {
        formData.append(`${prefix}${propName}`, val);
      }
    }
  }
}

function fillArray(form, array, prefix = '') {
  array.forEach((element, _index) => {
    form.append(`${prefix}[]`, element);
  });
}
