import React, {useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../../components/ModalerDialog";
import MarkdownEditor from "../../../../components/MarkdownEditor";
import {executeRequest} from "../../lib/fetchHelper";

const EmailSendenModal = ({spielerliste, onModalClose, sponsorenlauf, spieler, api, locale, texte}) => {
  const [betreff, setBetreff] = useState('');
  const [nachricht, setNachricht] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [mailFehler, setMailFehler] = useState(null);
  const [empfaenger, setEmpfaenger] = useState(spielerliste);
  const [sendenLaeuft, setSendenLaeuft] = useState(false);

  const emailSenden = () => {
    setMailFehler(null);
    if (empfaenger.length === 0 || betreff == '' || nachricht == '') {
      return;
    }
    const daten = {
      sponsorenlaufId: sponsorenlauf.id,
      typ: 'email',
      spielerIds: spieler.filter(s => empfaenger.includes(s.id)).map(s => s.spielerId),
      betreff: betreff,
      nachricht: nachricht,
      anhaenge: attachments
    };
    setSendenLaeuft(true);
    return executeRequest(api.nachrichten, api.token, 'POST', daten)
      .then(result => {
        if (result.ok) {
          schliessen();
        } else {
          setMailFehler(result.data.errors)
        }
      }).catch(ex => {
        setMailFehler([{ id: 'ex', error: ex.toString() }])
      }).finally(() => {
        setSendenLaeuft(false);
      });
  };

  const schliessen = () => {
    onModalClose();
  };

  const toggleEmpfaenger = (spieler) => {
    if (empfaenger.includes(spieler.id)) {
      setEmpfaenger(empfaenger.filter(p => p !== spieler.id));
    } else {
      setEmpfaenger([...empfaenger, spieler.id]);
    }
  };
  
  const erstelleAttachment = (ev) => {
    setAttachments([...attachments, ev.currentTarget.files[0]])
    ev.currentTarget.value = '';
  };
  
  const entferneAttachment = (id) => {
    setAttachments(attachments.filter(a => a.lastModified !== id))
  };
  
  const removeFehler = (id) => {
    setMailFehler(mailFehler.filter(f => f.id != id));
  };

  return (
    <ModalerDialog>
      <div className="modal breit-modal">
        <div className="modal-header">
          <button type="button" className="close" disabled={sendenLaeuft} onClick={schliessen}>&times;</button>
          <h3>{texte.titel}</h3>
        </div>
        <div className="modal-body">
          {mailFehler &&
          <div>
            {mailFehler.map((fehler) => {
              return <div key={fehler.id} className="alert alert-error">
                <a className="close" onClick={() => removeFehler(fehler.id)}>×</a>
                <span>{fehler.error}</span>
              </div>;
            })}
          </div>
          }
          <table className="table attributes">
            <tbody>
            <tr>
              <td className="label-liste">
                <div>
                  <b>{texte.empfaenger}</b>
                </div>
                {spieler.filter(s => spielerliste.includes(s.id)).map(s => {
                  return <span key={s.person.id} disabled={!empfaenger.includes(s.id)} className="label label-info" onClick={() => toggleEmpfaenger(s)}>{s.person.name}</span>;
                })}
              </td>
            </tr>
            <tr>
              <td><input placeholder={texte.betreff} style={{width: 'calc(100% - 14px)'}} type="text" value={betreff} onChange={ev => setBetreff(ev.currentTarget.value)}/></td>
            </tr>
            <tr>
              <td><MarkdownEditor placeholder={texte.nachricht} value={nachricht} onChange={text => setNachricht(text)} markdownUrl={api.markdown} token={api.token} locale={locale}/></td>
            </tr>
            <tr>
              <td>
                <input type="file" className="btn" value="" onChange={erstelleAttachment} />
                <div className="button-liste" style={{marginTop: 2, marginLeft: -2}}>
                  {attachments.length > 0 &&
                    <>{attachments.map(a => <button key={a.lastModified} type="button" className="btn btn-small" onClick={() => entferneAttachment(a.lastModified)}>{a.name} | <i className="icon-trash"/></button>)}</>
                  }
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" disabled={sendenLaeuft || empfaenger.length === 0 || betreff == '' || nachricht == ''} onClick={() => emailSenden()}>
            {texte.senden}
            {sendenLaeuft && <>&nbsp;<span className="ajax-spinner-klein inverted"/></>}
          </button>
          <button type="button" className="btn" disabled={sendenLaeuft} onClick={schliessen}>{texte.abbrechen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    sponsorenlauf: state.sponsorenlauf,
    spieler: state.spieler,
    api: state.api,
    locale: state.i18n.locale,
    texte: state.i18n.texte.emailSenden,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailSendenModal);
