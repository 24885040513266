import React, { useEffect } from 'react';

function useWindowUnload(nachricht) {
  useEffect(() => {
    const handleWindowUnload = (event) => {
      event.preventDefault();
      event.returnValue = nachricht;
    };
    window.addEventListener('beforeunload', handleWindowUnload);

    return () => window.removeEventListener('beforeunload', handleWindowUnload);
  }, []);
}

export default useWindowUnload;
