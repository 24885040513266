import {executeGet} from "../../lib/fetchHelper";
import {apiRequestAktiv} from "./api";
import {ersetzeStringMitDatum} from "../../../../lib/datenhaltung";

const SPORTSPEZIFISCH_SPIELER_INDEX = 'SPORTSPEZIFISCH_SPIELER_INDEX';
const SPORTSPEZIFISCH_SPIELER_UPDATE = 'SPORTSPEZIFISCH_SPIELER_UPDATE';
const SPORTSPEZIFISCH_SPIELER_DELETE = 'SPORTSPEZIFISCH_SPIELER_DELETE';

export function sportspezifischAlleSpielerLaden(phase) {
  return (dispatch, getState) => {
    dispatch(apiRequestAktiv(true));
    const api = getState().api;
    return executeGet(api.sportspezifisch, {phase: phase})
      .then(result => {
        dispatch({type: SPORTSPEZIFISCH_SPIELER_INDEX, liste: result.data.liste});
      }).finally(() => {
        dispatch(apiRequestAktiv(false));
      });
  };
}

export function sportspezifischSpielerIndex(liste) {
  return (dispatch) => {
    dispatch({type: SPORTSPEZIFISCH_SPIELER_INDEX, liste: liste});
  };
}

export function sportspezifischSpielerUpdate(sportspezifisch) {
  return (dispatch) => {
    dispatch({type: SPORTSPEZIFISCH_SPIELER_UPDATE, sportspezifisch: sportspezifisch});
  };
}

export function sportspezifischSpielerEntfernen(sportspezifisch) {
  return (dispatch) => {
    dispatch({type: SPORTSPEZIFISCH_SPIELER_DELETE, sportspezifisch: sportspezifisch});
  };
}

function sportspezifischReducer(state = [], action) {
  switch (action.type) {
    case SPORTSPEZIFISCH_SPIELER_INDEX:
      return action.liste.map(model => ersetzeStringMitDatum(model, ['datum_zeit_messung', 'datum_abgeschlossen'])); // daten komplett überschreiben
    case SPORTSPEZIFISCH_SPIELER_UPDATE:
      return [
        ...(state.filter(m => m.spielerId !== action.sportspezifisch.spielerId || m.phase !== action.sportspezifisch.phase)),
        ersetzeStringMitDatum(action.sportspezifisch, ['datum_zeit_messung', 'datum_abgeschlossen'])
      ];
    case SPORTSPEZIFISCH_SPIELER_DELETE:
      return state.filter(m => m.id !== action.sportspezifisch.id);
    default:
      return state;
  }
}

export default sportspezifischReducer;
