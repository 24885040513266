import {executeGet, executeJson} from "../../../../lib/fetchHelper";
import {teamsLaden} from "./teams";
import {spieleAktualisieren, spieleLaden, spieleSortieren} from "./spiele";
import {spielgruppeRanglisteSetzen} from "./spielgruppen";
import {saisonWechsel} from "./ligen";

const SPIELGRUPPE_LADEN = "SPIELGRUPPE_LADEN";
const SPIELGRUPPE_CLEAR = "SPIELGRUPPE_CLEAR";
const SAISON_SETZEN = "SAISON_SETZEN";
const DISCLAIMER_HINWEIS = "DISCLAIMER_HINWEIS";
const DISCLAIMER_ANZEIGEN = "DISCLAIMER_ANZEIGEN";
const FAVORITEN_TEAMS_SETZEN = "FAVORITEN_TEAMS_SETZEN";
const FAVORITEN_TEAMS_ENTFERNEN = "FAVORITEN_TEAMS_ENTFERNEN";
const ZEITPUNKT_SETZEN = "ZEITPUNKT_SETZEN";
const SPIEL_MELDUNGEN_SETZEN = "SPIEL_MELDUNGEN_SETZEN";
const SPIEL_MELDUNGEN_CLEAR = "SPIEL_MELDUNGEN_CLEAR";
const LIGA_SETZEN = "LIGA_SETZEN";
const SCROLL_TO_SPIELGRUPPE = "SCROLL_TO_SPIELGRUPPE";

export function gruppenSpieleLaden(spielgruppe) {
  return (dispatch, getState) => {
    const state = getState();
    const gruppeStatus = state.status.spielgruppen.find(sg => sg.id === spielgruppe.id);
    if (gruppeStatus && ["pending"].includes(gruppeStatus.xhr)) {
      // ist noch am laden
      return;
    }
    dispatch({type: SPIELGRUPPE_LADEN, spielgruppeId: spielgruppe.id, xhr: "pending"});

    executeGet(state.api.spiele, {spielGruppeId: spielgruppe.id, saisonId: state.status.saisonId}).then(result => {
      if (result.ok) {
        dispatch({type: SPIELGRUPPE_LADEN, spielgruppeId: spielgruppe.id, xhr: "complete"});
        dispatch(spielgruppeRanglisteSetzen(spielgruppe, result.data.rangliste));
        dispatch(teamsLaden(result.data.teams));
        dispatch(spieleLaden(result.data.spiele));
      } else {
        dispatch({type: SPIELGRUPPE_LADEN, spielgruppeId: spielgruppe.id, xhr: "error"});
      }
    }).catch(error => {
      dispatch({type: SPIELGRUPPE_LADEN, spielgruppeId: spielgruppe.id, xhr: "error"});
    });
  };
}

export function statusSpielgruppenClear() {
  return (dispatch) => {
    dispatch({type: SPIELGRUPPE_CLEAR});
  };
}

export function statusSaisonSetzen(saisonId) {
  return (dispatch) => {
    dispatch({type: SAISON_SETZEN, saisonId: saisonId});
  };
}

export function statusDisclaimerHinweisGelesen() {
  return (dispatch) => {
    let expire = new Date();
    expire.setTime(expire.getTime() + (365 * 24 * 60 * 60 * 1000));
    document.cookie = `disclaimerHinweis=1;path=/;expires=${expire.toUTCString()}`;
    dispatch({type: DISCLAIMER_HINWEIS, disclaimerHinweis: false});
  };
}

export function statusDisclaimerAnzeigen(disclaimerAnzeigen) {
  return (dispatch) => {
    dispatch({type: DISCLAIMER_ANZEIGEN, disclaimerAnzeigen: disclaimerAnzeigen});
  };
}

export function statusFavoritenTeamsSetzen(teamId) {
  return (dispatch) => {
    dispatch({type: FAVORITEN_TEAMS_SETZEN, teamId: teamId});
  };
}

export function statusFavoritenTeamsEntfernen(teamId) {
  return (dispatch, getState) => {
    const keineFavoritenMehr = getState().status.favoritenTeams.filter(t => t !== teamId).length === 0;
    dispatch({type: FAVORITEN_TEAMS_ENTFERNEN, teamId: teamId});
    if (keineFavoritenMehr) {
      dispatch(statusFavoritenTeamsPoll());
    }
  };
}

export function statusFavoritenTeamsPoll() {
  return (dispatch, getState) => {
    const state = getState();
    const daten = {
      teamIds: state.status.favoritenTeams,
      zeitpunkt: state.status.zeitpunkt
    };
    executeGet(state.api.favoritenTeams, daten).then(result => {
      if (result.ok) {
        let expire = new Date();
        expire.setTime(expire.getTime() + (365 * 24 * 60 * 60 * 1000));
        document.cookie = `zeitpunktAbfrage=${result.data.zeitpunkt};path=/;expires=${expire.toUTCString()}`;
        dispatch({type: ZEITPUNKT_SETZEN, zeitpunkt: result.data.zeitpunkt});
        dispatch({type: SPIEL_MELDUNGEN_SETZEN, spielMeldungen: result.data.spielMeldungen});

        // spiel resultate aktualisieren (falls bereits vorhanden)
        dispatch(spieleAktualisieren(result.data.spielMeldungen));

        // alle spielgruppen neu laden die changes haben
        result.data.spielgruppenIds.forEach(spielgruppeId => {
          dispatch(gruppenSpieleLaden({id: spielgruppeId}));
        });
      }
    });
  };
}

export function spielmeldungenClear() {
  return (dispatch) => {
    dispatch({type: SPIEL_MELDUNGEN_CLEAR});
  };
}

export function ligaSetzen(liga) {
  return (dispatch) => {
    dispatch({type: LIGA_SETZEN, liga: liga});
  };
}

export function zuLigaWechseln(liga) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.status.aktuelleSaisonId === state.status.saisonId) {
      dispatch({type: LIGA_SETZEN, liga: liga});
    } else {
      dispatch(saisonWechsel(state.status.aktuelleSaisonId, liga));
    }
  };
}

export function zuSpielgruppeWechseln(spielgruppeId) {
  return (dispatch, getState) => {
    const state = getState();
    const spielgruppe = state.spielgruppen.find(sg => sg.id === spielgruppeId);
    if (spielgruppe) {
      dispatch({type: SCROLL_TO_SPIELGRUPPE, spielgruppeId: spielgruppeId});

      const liga = state.ligen.find(l => l.id === spielgruppe.ligaId);
      if (state.status.aktuelleSaisonId === state.status.saisonId) {
        dispatch({type: LIGA_SETZEN, liga: liga});
      } else {
        dispatch(saisonWechsel(state.status.aktuelleSaisonId, liga));
      }
    }
  };
}

export function scrollToSpielgruppeClear() {
  return (dispatch) => {
    dispatch({type: SCROLL_TO_SPIELGRUPPE, spielgruppeId: null});
  };
}

function statusReducer(state = {}, action) {
  switch (action.type) {
    case SPIELGRUPPE_LADEN:
      return {
        ...state,
        spielgruppen: [
          ...state.spielgruppen.filter(sg => sg.id != action.spielgruppeId),
          {id: action.spielgruppeId, xhr: action.xhr},
        ]
      };
    case SPIELGRUPPE_CLEAR:
      return {
        ...state,
        spielgruppen: []
      };
    case SAISON_SETZEN:
      return {
        ...state,
        saisonId: action.saisonId
      };
    case DISCLAIMER_HINWEIS:
      return {
        ...state,
        disclaimerHinweis: action.disclaimerHinweis
      };
    case DISCLAIMER_ANZEIGEN:
      return {
        ...state,
        disclaimerAnzeigen: action.disclaimerAnzeigen
      };
    case FAVORITEN_TEAMS_SETZEN:
      return {
        ...state,
        favoritenTeams: [
          ...state.favoritenTeams.filter(t => t !== action.teamId),
          action.teamId
        ]
      };
    case FAVORITEN_TEAMS_ENTFERNEN:
      return {
        ...state,
        favoritenTeams: state.favoritenTeams.filter(t => t !== action.teamId)
      };
    case SPIEL_MELDUNGEN_SETZEN:
      const neueMeldungenIds = action.spielMeldungen.map(m => m.id);
      const meldungen = state.spielMeldungen
        .filter(m => !neueMeldungenIds.includes(m.id))
        .concat(action.spielMeldungen)
        .sort(spieleSortieren);
      return {
        ...state,
        spielMeldungen: meldungen
      };
    case SPIEL_MELDUNGEN_CLEAR:
      return {
        ...state,
        spielMeldungen: []
      };
    case ZEITPUNKT_SETZEN:
      return {
        ...state,
        zeitpunkt: action.zeitpunkt
      };
    case LIGA_SETZEN:
      return {
        ...state,
        selektierteLiga: action.liga
      };
    case SCROLL_TO_SPIELGRUPPE:
      return {
        ...state,
        scrollToSpielgruppe: action.spielgruppeId
      };
    default:
      return state;
  }
}

export default statusReducer;
