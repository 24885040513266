import React from "react";
import {connect} from "react-redux";
import Tooltip from "../../../../components/Tooltip";
import {vorlageDatenEdit} from "../../state/modules/vorlagenDaten";
import {vorlageUpdate} from "../../state/modules/vorlagen";

const Table = ({vorlagen, i18n, vorlageDatenEdit, vorlageUpdate}) => {
  const texte = i18n.texte;

  const editClick = (vorlage) => {
    vorlageDatenEdit(vorlage);
  };

  const deleteClick = (vorlage) => {
    vorlageUpdate(vorlage.id, true, vorlage.bezeichnung, vorlage.buchungstyp.id, vorlage.texte);
  };

  return (
    <table className="table table-condensed table-gestreift">
      <thead>
      <tr>
        <th>{texte.bezeichnung}</th>
        <th>{texte.sprachen}</th>
        <th>{texte.buchungstyp}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      {vorlagen.map(v => {
        return <tr key={v.id}>
          <td>{v.bezeichnung}</td>
          <td>{v.texte.map(t => i18n.sprachen.find(s => s.id === t.spracheId).bezeichnung).join(', ')}</td>
          <td>{v.buchungstyp.bezeichnung}</td>
          <td className="text-right button-liste">
            <button type="button" className="btn" onClick={() => editClick(v)}>
              <i className="icon-edit"/>
            </button>
            {v.id > 0 && !v.inaktiv &&
            <Tooltip tagName="button" content={texte.inaktivSetzen} type="button" className="btn" onClick={() => deleteClick(v)}>
              <i className="icon-trash"/>
            </Tooltip>
            }
          </td>
        </tr>;
      })}
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => {
  return {
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    vorlageDatenEdit: (vorlage) => { dispatch(vorlageDatenEdit(vorlage)); },
    vorlageUpdate: (vorlageId, inaktiv, bezeichnung, buchungstypId, texte) => { dispatch(vorlageUpdate(vorlageId, inaktiv, bezeichnung, buchungstypId, texte)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);

