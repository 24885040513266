import React from 'react'
import {connect} from "react-redux";
import Feldspieler from "./Feldspieler";
import {PERIODE} from "../lib/spielablauf";
import PenaltytorAnzeige from "./PenaltytorAnzeige";

const Spielfeld = ({anzeige, heim, gast, spiel}) => {
  const heimAnzeige = anzeige === 'normal' ? 'links' : 'rechts';
  const gastAnzeige = anzeige === 'normal' ? 'rechts' : 'links';
  const penaltySchiessen = spiel.periode === PERIODE.penaltyschiessen;
  
  // positionierung check: wenn positionen nicht gesetzt -> sortierung nach rückennummer, und einfach auflisten (mittels css klasse)
  const heimHatPositionen = heim.zustand.feldspieler.filter(s => s.position_id != 0).length >= 5;
  let heimZustand = heim.zustand.feldspieler;
  if (!heimHatPositionen) {
    heimZustand.sort((a, b) => a.rueckennummer - b.rueckennummer);
  }
  const gastHatPositionen = gast.zustand.feldspieler.filter(s => s.position_id != 0).length >= 5;
  let gastZustand = gast.zustand.feldspieler;
  if (!gastHatPositionen) {
    gastZustand.sort((a, b) => a.rueckennummer - b.rueckennummer);
  }

  return (
    <div className="feld">
      <div className="feld-aspect-ratio">
        <div className="feld-linien">
          <div className={`feld-container ${spiel.periode} ${anzeige}`}>
            {penaltySchiessen &&
            <div className="penaltytore">
              <PenaltytorAnzeige team={heim.team}/>
              <PenaltytorAnzeige team={gast.team}/>
            </div>
            }
            <div className={`feld-${heimAnzeige} ${heimHatPositionen ? '' : 'freie-position'}`}>
              {heimZustand.map((s) => <Feldspieler key={`${s.unregistriert ? 'u' : 'r'}:${s.id}`} zustandSpieler={s} teamSpielerkarte={heim} anzeige={heimAnzeige} penaltySchiessen={penaltySchiessen} positionBenutzen={heimHatPositionen}/>)}
            </div>
            <div className={`feld-${gastAnzeige} ${gastHatPositionen ? '' : 'freie-position'}`}>
              {gastZustand.map((s) => <Feldspieler key={`${s.unregistriert ? 'u' : 'r'}:${s.id}`} zustandSpieler={s} teamSpielerkarte={gast} anzeige={gastAnzeige} penaltySchiessen={penaltySchiessen} positionBenutzen={gastHatPositionen}/>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    anzeige: state.spielfeld.anzeige,
    heim: state.heim,
    gast: state.gast,
    spiel: state.heartbeat.spiel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Spielfeld);
