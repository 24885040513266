import React from "react";
import {connect} from "react-redux";
import FormBetrag from "./FormBetrag";
import {formatiereBetrag} from "../../../lib/formatierungen";
import {berechneTotal, berichtSetzeWert} from "../state/berichtReducer";

const Spesen = ({bericht, fehler, i18n, setzeWert}) => {
  let readonly = bericht.bBerichtDefinitiv;
  const texte = i18n.texte.coaching_bericht
  
  const erstelleZeile = (name, value) => {
    let fehlerEintrag = fehler.find(f => f.name == name);
    return (
      <tr className={`control-group${fehlerEintrag ? ' error' : ''}`}>
        <th className="span3 control-label">{texte[name]}</th>
        <td className="text-right"><FormBetrag name={name} value={value} readonly={readonly} onValueChange={(name, value) => setzeWert(name, value)}/></td>
      </tr>
    );
  };

  return (
    <table className="table table-striped">
      <tbody>
      {erstelleZeile('smnEntschädigung', bericht.smnEntschädigung)}
      {erstelleZeile('smnReisespesen', bericht.smnReisespesen)}
      {erstelleZeile('smnZuschlag', bericht.smnZuschlag)}
      <tr>
        <th className="span3">{texte.spesen_total}</th>
        <td><span className="span1 pull-right text-right" style={{width: '100px'}}>{formatiereBetrag(berechneTotal(bericht), i18n.locale)}</span></td>
      </tr>
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => {
  return {
    bericht: state.bericht,
    fehler: state.fehler.messages || [],
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setzeWert: (name, value) => dispatch(berichtSetzeWert(name, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Spesen);

