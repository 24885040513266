import React from "react";
import {connect} from "react-redux";
import AufgabenListe from "./AufgabenListe";

const KlassenListe = ({klassen, texte}) => {
  if (klassen.length === 0) {
    return (
      <div className="block">
        <div className="header">{texte.keineKlasseZugeteilt}</div>
      </div>
    );
  } else {
    return (
      <>
        {klassen.map((klasse) => <AufgabenListe key={klasse.id} klasse={klasse}/>)}
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    klassen: state.klassen,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(KlassenListe);
