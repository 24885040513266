import {executeGet} from "../../../../lib/fetchHelper";
import {spielgruppeSetzen} from "./spielgruppen";
import {ligaSetzen, statusSaisonSetzen} from "./status";

const LIGEN_LADEN = 'LIGEN_LADEN';

export function ligenSortieren(a, b) {
  return a.ligaNr - b.ligaNr;
}

export function saisonWechsel(saisonId, liga = null) {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(statusSaisonSetzen(saisonId));

    executeGet(state.api.ligen, {saisonId: saisonId}).
    then(result => {
      if (result.ok) {
        dispatch({type: LIGEN_LADEN, ligen: result.data.ligen });
        dispatch(spielgruppeSetzen(result.data.spielgruppen));
        if (liga) {
          dispatch(ligaSetzen(liga));
        }
      }
    });
  }
}

function ligenReducer(state = [], action) {
  switch (action.type) {
    case LIGEN_LADEN:
      return action.ligen.sort(ligenSortieren);
    default:
      return state;
  }
}

export default ligenReducer;
