import React, {useState, useEffect, useRef} from 'react'
import Spielernummer from './components/Spielernummer'
import PersonBadge from "../../components/PersonBadge";
import Zeitlinie from './components/Zeitlinie'
import SpielerEintraege from "./components/SpielerEintraege";
import {datenAbfragen} from "./lib/api";

export const Kontext = React.createContext();

const Anwesenheitskontrolle = (props) => {
  const context = props.kontext;
  const [eintraege, setEintraege] = useState([]);
  const [kader, setKader] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(context.api.limit);
  const [abfrageAktiv, setAbfrageAktiv] = useState(false);
  const refZeitlinie = useRef(null);

  useEffect(() => {
    const anzahl = berechneAnzahlEintraege();
    setEintraege(props.eintraege.slice(0, anzahl));
    let k = props.kader.map(spieler => {
      return {...spieler, eintraege: spieler.eintraege.slice(0, anzahl)}
    });
    setKader(k);
  }, []);

  const berechneAnzahlEintraege = () => {
    const breite = $(refZeitlinie.current).width();
    const anzahl = Math.floor(breite / 40) - 2; // zwei elemente platz lassen für pager-spans
    setLimit(anzahl);
    return Math.max(anzahl, 1);
  };

  const handlePageChange = (page) => {
    if (!abfrageAktiv) {
      setAbfrageAktiv(true);
      datenAbfragen(context.api, page, limit, (resultat) => {
        setEintraege(resultat.eintraege);
        setKader(resultat.kader);
        setPage(page);
        setAbfrageAktiv(false);
      });
    }
  };

  const handleEintraegeChange = (spielerId, neueEintraege) => {
    console.log(neueEintraege);
    const neueKaderDaten = kader.map((spieler) => {
      if (spieler.id === spielerId) {
        spieler.eintraege = neueEintraege.filter(eintrag => eintrag.bearbeitbar === true);
      }
      return spieler;
    });
    setKader(neueKaderDaten);

    // update der eintraege (damit header "anzahl abwesende" aktualisiert)
    const tempEintraege = [...eintraege];
    neueEintraege.forEach((neuerEintrag) => {
      const eintrag = tempEintraege.find(e => e.id === neuerEintrag.id && e.typ === neuerEintrag.typ);
      eintrag.abwesende = eintrag.abwesende.filter(a => a.spielerId !== spielerId);
      if (neuerEintrag.abwesend) {
        eintrag.abwesende.push(neuerEintrag.abwesend);
      }
    });
    setEintraege(tempEintraege)
  };

  return (
    <Kontext.Provider value={context}>
      <table className="table table-striped listing anwesenheitskontrolle">
        <thead>
        <tr>
          <th style={{width: 190}} colSpan="2">{context.texte.spieler}</th>
          <th style={{width: "69%"}} ref={refZeitlinie}>
            <Zeitlinie eintraege={eintraege} page={page} limit={limit} onPageChange={handlePageChange}/>
          </th>
        </tr>
        </thead>
        <tbody>
        {!context.ansichtSpieler &&
          <tr>
            <td/>
            <td>{context.texte.anzahl_anwesende}</td>
            <td>
              <div className="zeitlinie">
                <span/>
                {eintraege.map((eintrag, index) => <span key={index}>{kader.length - eintrag.abwesende.length}</span>)}
                <span/>
              </div>
            </td>
          </tr>
        }
        {
          kader.map((spieler) => {
            return (
              <tr key={spieler.id}>
                <td className="vertical-top">
                  <Spielernummer nr={spieler.passnummer}/>
                </td>
                <td>
                  <PersonBadge person={spieler.badge}/>
                </td>
                <td>
                  <SpielerEintraege onEintraegeChanged={handleEintraegeChange} spielerId={spieler.id}
                                    eintraege={spieler.eintraege}/>
                </td>
              </tr>
            );
          })
        }
        </tbody>
      </table>
    </Kontext.Provider>
  );
};
export default Anwesenheitskontrolle
