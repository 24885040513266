import React from "react";
import {connect} from "react-redux";
import FormTextarea from "./FormTextarea";
import FormRadioListe from "./FormRadioListe";
import FormCheckbox from "./FormCheckbox";
import {berichtSetzeWert} from "../state/berichtReducer";

const Komission = ({bericht, listen, setzeWert, texte}) => {
  let readonly = bericht.bBerichtDefinitiv;

  return (
    <table className="table table-striped">
      <tbody>
      <tr>
        <th className="span3">{texte.kommission}</th>
        <td><FormTextarea name="kommission" value={bericht.kommission} readonly={readonly} onTextChange={(name, value) => setzeWert(name, value)}/></td>
      </tr>
      <tr>
        <th className="span3">{texte.sEinsatzmoeglichkeit}</th>
        <td><FormRadioListe name="sEinsatzmoeglichkeit" value={bericht.sEinsatzmoeglichkeit} readonly={readonly} eintraege={listen.einsatzmoeglichkeiten} onRadioChange={(name, value) => setzeWert(name, value)}/></td>
      </tr>
      {listen.features.verhalten_debriefing &&
        <tr>
          <th className="span3">{texte.sVerhaltenDebriefing}</th>
          <td><FormRadioListe name="sVerhaltenDebriefing" value={bericht.sVerhaltenDebriefing} readonly={readonly} eintraege={listen.verhaltenDebriefings} onRadioChange={(name, value) => setzeWert(name, value)}/></td>
        </tr>
      }
      {listen.features.akzeptanz_sr_team &&
        <tr>
          <th className="span3">{texte.sAkzeptanzSRTeam}</th>
          <td><FormRadioListe name="sAkzeptanzSRTeam" value={bericht.sAkzeptanzSRTeam} readonly={readonly} eintraege={listen.akzeptanzSRTeams} onRadioChange={(name, value) => setzeWert(name, value)}/></td>
        </tr>
      }
      <tr>
        <th className="span3">{texte.bZusaetzlicheBetreuungGespraech}</th>
        <td><FormCheckbox name="bZusaetzlicheBetreuungGespraech" checked={bericht.bZusaetzlicheBetreuungGespraech} readonly={readonly} onCheckedChange={(name, value) => setzeWert(name, value)}/></td>
      </tr>
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => {
  return {
    bericht: state.bericht,
    listen: state.listen,
    texte: state.i18n.texte.coaching_bericht,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setzeWert: (name, value) => {
      dispatch(berichtSetzeWert(name, value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Komission);

