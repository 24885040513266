import React from 'react';
import {connect} from "react-redux";
import AufgabeZuteilung from "./AufgabeZuteilung";
import {filtereAufgaben} from "../../lib/formatierungen";

const TeilnehmerAufgaben = ({teilnehmer, aufgabenModus, aufgabenFilter = [], texte}) => {
  let teilnehmerAufgaben = filtereAufgaben(teilnehmer.aufgaben, aufgabenFilter);

  return (
    <div className="content expand-width">
      {teilnehmerAufgaben.map(az => <AufgabeZuteilung key={az.id} aufgabeZuteilung={az} teamTeilnehmer={teilnehmer} aufgabenModus={aufgabenModus}/>)}
      {teilnehmer.aufgaben.length === 0 &&
      <div className="alert alert-info">{texte.keineAufgabenZugeteilt}</div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeilnehmerAufgaben);
