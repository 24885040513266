import React, {useState} from "react";
import {connect} from "react-redux";
import Stammdaten from "./Stammdaten";
import Spieler from "./Spieler";
import Sponsoren from "./Sponsoren";
import Bezeichnungen from "./Bezeichnungen";
import Pflichtbeitraege from "./Pflichtbeitraege";
import SpielerResultate from "./SpielerResultate";
import Gesamtuebersicht from "./Gesamtuebersicht";

const VIEWS = {
  stammdaten: 'stammdaten',
  pflichtbeitraege: 'pflichtbeitraege',
  spieler: 'spieler',
  abschluss: 'abschluss',
  sponsoren: 'sponsoren',
  gesamtuebersicht: 'gesamtuebersicht',
  bezeichnungen: 'bezeichnungen',
};

const Ansichten = ({sponsorenlauf, texte, publicLink}) => {
  const [ansicht, setAnsicht] = useState(VIEWS.stammdaten);
  const texteNavigation = texte.navigation;

  const navClick = (event, neueAnsicht) => {
    event.stopPropagation();
    event.preventDefault();
    setAnsicht(neueAnsicht);
  };

  return (
    <>
      {sponsorenlauf.id > 0 &&
        <div className="pull-right">
          <a className="btn" target="_blank" href={`${publicLink}${sponsorenlauf.id}`}><i className="icon-share"/>&nbsp;{texte.publicLink}</a>
        </div>
      }

      <h2 className="page-header">{sponsorenlauf.id > 0 ? texte.titelEdit : texte.titelNew}</h2>

      <ul className="nav nav-tabs">
        <li className={ansicht === VIEWS.stammdaten ? 'active' : ''}>
          <a href="" onClick={ev => navClick(ev, VIEWS.stammdaten)}>{texteNavigation.stammdaten}</a>
        </li>
        {sponsorenlauf.id > 0 &&
          <>
            <li className={ansicht === VIEWS.pflichtbeitraege ? 'active' : ''}>
              <a href="" onClick={ev => navClick(ev, VIEWS.pflichtbeitraege)}>{texteNavigation.pflichtbeitraege}</a>
            </li>
            <li className={ansicht === VIEWS.spieler ? 'active' : ''}>
              <a href="" onClick={ev => navClick(ev, VIEWS.spieler)}>{texteNavigation.spieler}</a>
            </li>
            <li className={ansicht === VIEWS.sponsoren ? 'active' : ''}>
              <a href="" onClick={ev => navClick(ev, VIEWS.sponsoren)}>{texteNavigation.sponsoren}</a>
            </li>
            <li className={ansicht === VIEWS.abschluss ? 'active' : ''}>
              <a href="" onClick={ev => navClick(ev, VIEWS.abschluss)}>{texteNavigation.abschluss}</a>
            </li>
            <li className={ansicht === VIEWS.gesamtuebersicht ? 'active' : ''}>
              <a href="" onClick={ev => navClick(ev, VIEWS.gesamtuebersicht)}>{texteNavigation.gesamtuebersicht}</a>
            </li>
          </>
        }
        <li className={ansicht === VIEWS.bezeichnungen ? 'active' : ''}>
          <a href="" onClick={ev => navClick(ev, VIEWS.bezeichnungen)}>{texteNavigation.bezeichnungen}</a>
        </li>
      </ul>

      {ansicht === VIEWS.stammdaten && <Stammdaten/>}
      {ansicht === VIEWS.pflichtbeitraege && <Pflichtbeitraege/>}
      {ansicht === VIEWS.spieler && <Spieler/>}
      {ansicht === VIEWS.sponsoren && <Sponsoren/>}
      {ansicht === VIEWS.abschluss && <SpielerResultate/>}
      {ansicht === VIEWS.gesamtuebersicht && <Gesamtuebersicht/>}
      {ansicht === VIEWS.bezeichnungen && <Bezeichnungen/>}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sponsorenlauf: state.sponsorenlauf,
    texte: state.i18n.texte,
    publicLink: state.api.publicLink
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Ansichten);
