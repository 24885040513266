import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {executeRequest} from "../lib/api";
import {ersetzeStringMitDatum} from "../lib/formatierungen";
import {bibliothekReferenzSetzen} from "../state/teamTeilnehmerReducer";
import FreigebenenePersonAufgaben from "./FreigebenenePersonAufgaben";

const FreigegebeneAufgaben = ({alleAufgaben = false, api, bibliothek, texte}) => {
  const [personenAufgaben, setPersonenAufgaben] = useState(null);

  useEffect(() => {
    executeRequest(api.freigegebeneAufgaben, api.token, 'GET', { alleAufgaben: alleAufgaben }, (result) => {
      if (result.ok) {
        let arr = [];
        result.data.daten.forEach(datum => {
          let aufgaben = [];
          datum.aufgaben.forEach(aufgabe => {
            const az = bibliothekReferenzSetzen(aufgabe, bibliothek);
            ersetzeStringMitDatum(az, ['von', 'bis']);
            aufgaben.push(az);
          });
          arr.push({person: datum.person, aufgaben: aufgaben});
        });
        setPersonenAufgaben(arr);
        }
    });
  }, []);

  if (personenAufgaben === null) {
    return <div className="ajax-spinner-large"><span/></div>;
  } else {
    if (personenAufgaben.length === 0) {
      return <>{texte.keineEintraege}</>;
    } else {
      return <>{personenAufgaben.map(pa => <FreigebenenePersonAufgaben key={pa.person.id} person={pa.person} aufgaben={pa.aufgaben}/>)}</>;
    }
  }
};

const mapStateToProps = (state) => {
  return {
    api: state.api,
    bibliothek: state.bibliothek,
    texte: state.i18n.texte.aufgabenFreigeben,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FreigegebeneAufgaben);
