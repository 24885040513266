import React, {useState} from 'react';
import {connect} from "react-redux";
import {ausbildungSpeichern} from "../state/ausbildnerReducer";
import {AusbildungStatus} from "../lib/constants";

const AusbildungStatusAbschluss = ({ausbildner, abschlussStatusListe, texte, ausbildungSpeichern}) => {
  const [statusId, setStatusId] = useState(abschlussStatusListe.find(s => s.id === ausbildner.ausbildungStatusId) !== undefined ? ausbildner.ausbildungStatusId : AusbildungStatus.Erfuellt);

  const speichern = () => {
    ausbildungSpeichern(ausbildner, statusId);
  };

  return (
    <div className="content edit">
      <div className="zeile">
        <label htmlFor={`${ausbildner.id}-ausbildungStatus`}>{texte.teilnehmerAbschluss.ausbildungStatus}</label>
        <select id={`${ausbildner.id}-ausbildungStatus`} className='select' value={statusId} onChange={(ev) => setStatusId(ev.currentTarget.value)}>
          {abschlussStatusListe.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
        </select>
      </div>
      <div className="zeile">
        <label/>
        <button className="btn btn-primary" onClick={speichern}>{texte.speichern}</button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    abschlussStatusListe: state.abschlussStatusListe,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ausbildungSpeichern: (ausbildner, statusId) => { dispatch(ausbildungSpeichern(ausbildner, statusId)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AusbildungStatusAbschluss);
