import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {formatiereZeit} from "../../../lib/formatierungen";
import SpielstatusLabel from "./SpielstatusLabel";
import {gruppenSpieleLaden, scrollToSpielgruppeClear} from "../state/modules/status";
import Rangliste from "./Rangliste";
import TeamLabel from "./TeamLabel";

const LigaSpielgruppe = ({spielgruppe, spiele, teams, status, gruppenSpieleLaden, scrollToSpielgruppeClear}) => {
  const [showSpiele, setShowSpiele] = useState(true);
  const gruppeStatus = status.spielgruppen.find(sg => sg.id === spielgruppe.id);
  const refSection = useRef();

  useEffect(() => {
    gruppenSpieleLaden(spielgruppe);
  }, [spielgruppe]);

  useLayoutEffect(() => {
    if (status.scrollToSpielgruppe && status.scrollToSpielgruppe === spielgruppe.id) {
      scrollToSpielgruppeClear();
      refSection.current.scrollIntoView({behavior: "smooth"});
    }
  }, [status.scrollToSpielgruppe]);

  const renderSpiel = (spiel, index) => {
    const heim = teams.find(t => t.id === spiel.heimTeamId);
    const gast = teams.find(t => t.id === spiel.gastTeamId);
    const cssClass = `row-fluid spiel-box ${index % 2 === 0 ? 'striped' : ''}`;

    return (
      <div key={spiel.id}>
        <div className={`${cssClass} hidden-phone`}>
          <div className="span2">
            <i className="icon-time" style={{verticalAlign: 'baseline'}}/> {formatiereZeit(spiel.spielzeit)}
          </div>
          <div className="span3"><TeamLabel team={heim}/></div>
          <div className="span1">{spiel.keinResultat ? "-" : `${spiel.toreHeim}:${spiel.toreGast}`}</div>
          <div className="span3"><TeamLabel team={gast}/></div>
          <div className="span3">
            {spiel.keinResultat ||
            <SpielstatusLabel spielstatusId={spiel.spielstatusId}/>
            }
          </div>
        </div>
        <div className={`${cssClass} visible-phone`}>
          <div className="paarung">
            <div className="team">
              <i className="icon-time" style={{verticalAlign: 'baseline'}}/> {formatiereZeit(spiel.spielzeit)}
            </div>
            <div>
              {spiel.keinResultat ||
              <SpielstatusLabel spielstatusId={spiel.spielstatusId}/>
              }
            </div>
          </div>
          <div>
            <div className="paarung">
              <div className="team"><TeamLabel team={heim}/></div>
              <div className="resultat">{spiel.keinResultat ? "" : `${spiel.toreHeim}`}</div>
            </div>
          </div>
          <div>
            <div className="paarung">
              <div className="team"><TeamLabel team={gast}/></div>
              <div className="resultat">{spiel.keinResultat ? "" : `${spiel.toreGast}`}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <section ref={refSection}>
        <h2 className="page-section cursor-pointer clearfix" onClick={() => setShowSpiele(!showSpiele)}>
          {spielgruppe.spielklasse}
          {spielgruppe.bezeichnung &&
          ` - ${spielgruppe.bezeichnung}`
          }
          <div className="pull-right">
            <button className="btn"><i className={showSpiele ? "icon-arrow-up" : "icon-arrow-down"}/></button>
          </div>
        </h2>
        {showSpiele &&
        <>
          {spielgruppe.rangliste && spielgruppe.rangliste.length > 0 &&
          <Rangliste rangliste={spielgruppe.rangliste}/>
          }
          <div className="spiel-container">
            {gruppeStatus && gruppeStatus.xhr === "pending" &&
            <span className="ajax-spinner"/>
            }
            {spiele.map((spiel, index) => renderSpiel(spiel, index))}
          </div>
        </>
        }
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    teams: state.teams,
    status: state.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    gruppenSpieleLaden: (spielGruppeId) => {
      dispatch(gruppenSpieleLaden(spielGruppeId))
    },
    scrollToSpielgruppeClear: () => {
      dispatch(scrollToSpielgruppeClear())
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LigaSpielgruppe);
