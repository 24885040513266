import React, {useState} from "react";
import {DndProvider} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import {connect} from "react-redux";
import LehrerListe from "./LehrerListe";
import ZeitslotListe from "./ZeitslotListe";
import TeamAbschluss from "../TeamAbschluss";
import Fehlermeldungen from "../Fehlermeldungen";
import Kalender from "./Kalender";
import KurzfristigAbmelden from "./KurzfristigAbmelden";
import TeilnehmerKlasseGrid from "./TeilnehmerKlasseGrid";
import Abbrechnung from "./Abbrechnung";
import FreigegebeneAufgaben from "../FreigegebeneAufgaben";

const VIEWS = {
  kalender: 'kalender',
  organisation: 'organisation',
  planungGrid: 'planungGrid',
  planungListe: 'planungListe',
  abschluss: 'abschluss',
  abbrechnung: 'abbrechnung',
  abmelden: 'abmelden',
  abschlussBericht: 'abschlussBericht',
  freigegebeneAufgaben: 'freigegebeneAufgaben',
};

const Ansichten = ({ausbildung, texte}) => {
  const [ansicht, setAnsicht] = useState(ausbildung.bearbeitbar ? VIEWS.kalender : VIEWS.abschluss);

  const navClick = (event, neueAnsicht) => {
    event.stopPropagation();
    event.preventDefault();
    setAnsicht(neueAnsicht);
  };

  return (
    <>
      <div>
        <ul className="nav nav-tabs">
          {ausbildung.bearbeitbar &&
          <>
            <li className={ansicht === VIEWS.kalender || ansicht === VIEWS.organisation ? 'active' : ''}>
              <a href="#" onClick={(ev) => navClick(ev, VIEWS.kalender)}>{texte.kursorganisation}</a>
            </li>
            <li className={(ansicht === VIEWS.planungGrid || ansicht === VIEWS.planungListe) ? 'active' : ''}>
              <a href="#" onClick={(ev) => navClick(ev, VIEWS.planungGrid)}>{texte.teamplanung}</a>
            </li>
          </>
          }
          <li className={ansicht === VIEWS.abschluss ? 'active' : ''}>
            <a href="#" onClick={(ev) => navClick(ev, VIEWS.abschluss)}>{texte.abschluss}</a>
          </li>
          <li className={ansicht === VIEWS.abbrechnung ? 'active' : ''}>
            <a href="#" onClick={(ev) => navClick(ev, VIEWS.abbrechnung)}>{texte.abbrechnung}</a>
          </li>
          {ausbildung.bearbeitbar &&
          <li className={ansicht === VIEWS.abmelden ? 'active' : ''}>
            <a href="#" onClick={(ev) => navClick(ev, VIEWS.abmelden)}>{texte.kurzfristigAbmelden}</a>
          </li>
          }
          <li className={ansicht === VIEWS.freigegebeneAufgaben ? 'active' : ''}>
            <a href="" onClick={(ev) => navClick(ev, VIEWS.freigegebeneAufgaben)}>{texte.freigegebeneAufgaben}</a>
          </li>
          <li className={ansicht === VIEWS.abschlussBericht ? 'active' : ''}>
            <a href="#" onClick={(ev) => navClick(ev, VIEWS.abschlussBericht)}>{texte.abschlussBericht}</a>
          </li>
        </ul>
      </div>
      <Fehlermeldungen />
      {ausbildung.bearbeitbar && (ansicht === VIEWS.kalender || ansicht === VIEWS.organisation) &&
      <div>
        <ul className="nav nav-tabs">
          <li className={ansicht === VIEWS.kalender ? 'active' : ''}>
            <a href="#" onClick={(ev) => navClick(ev, VIEWS.kalender)}>{texte.kalender}</a>
          </li>
          <li className={ansicht === VIEWS.organisation ? 'active' : ''}>
            <a href="#" onClick={(ev) => navClick(ev, VIEWS.organisation)}>{texte.liste}</a>
          </li>
        </ul>
        {ansicht === VIEWS.kalender &&
        <Kalender/>
        }
        {ansicht === VIEWS.organisation &&
        <DndProvider backend={HTML5Backend}>
          <ZeitslotListe/>
        </DndProvider>
        }
      </div>
      }
      {ausbildung.bearbeitbar && (ansicht === VIEWS.planungGrid || ansicht === VIEWS.planungListe) &&
      <div>
        <ul className="nav nav-tabs">
          <li className={ansicht === VIEWS.planungGrid ? 'active' : ''}>
            <a href="#" onClick={(ev) => navClick(ev, VIEWS.planungGrid)}>{texte.planungGrid}</a>
          </li>
          <li className={ansicht === VIEWS.planungListe ? 'active' : ''}>
            <a href="#" onClick={(ev) => navClick(ev, VIEWS.planungListe)}>{texte.planungListe}</a>
          </li>
        </ul>
        {ansicht === VIEWS.planungGrid && 
        <TeilnehmerKlasseGrid/>
        }
        {ansicht === VIEWS.planungListe &&
        <DndProvider backend={HTML5Backend}>
          <LehrerListe/>
        </DndProvider>
        }
      </div>
      }
      {ansicht === VIEWS.abschluss &&
      <TeamAbschluss />
      }
      {ansicht === VIEWS.abbrechnung &&
      <Abbrechnung />
      }
      {ausbildung.bearbeitbar && ansicht === VIEWS.abmelden &&
      <KurzfristigAbmelden />
      }
      {ansicht === VIEWS.freigegebeneAufgaben &&
      <FreigegebeneAufgaben/>
      }
      {ansicht === VIEWS.abschlussBericht &&
      <div>TODO...</div>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ausbildung: state.ausbildung,
    texte: state.i18n.texte.ansichten,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Ansichten);
