import React, {useEffect, useState} from "react";
import {rechnungskontrollenSuche} from "../../state/modules/rechnungskontrollen";
import {connect} from "react-redux";
import DatumPicker from "../../../../components/DatumPicker";
import Tooltip from "../../../../components/Tooltip";

const RechnungenSuche = ({status, geschaeftsjahr, i18n, api_suche, rechnungenSuchen}) => {
  const [sucheVon, setSucheVon] = useState(new Date(geschaeftsjahr.von));
  const [sucheBis, setSucheBis] = useState(new Date(geschaeftsjahr.bis));
  const [initialGeladen, setInitialGeladen] = useState(false);
  const texte = i18n.texte.suche;

  useEffect(() => {
    if (!initialGeladen) {
      sucheStarten();
      setInitialGeladen(true);
    }
  }, [initialGeladen]);

  const sucheStarten = () => {
    if (sucheVon && sucheBis) {
      rechnungenSuchen(sucheVon, sucheBis);
    }
  };

  return (
    <>
      <div className="zeile">
        <span style={{fontWeight: 'bold'}}>{texte.von} / {texte.bis}</span>
        &nbsp;
        <DatumPicker locale={i18n.locale} value={sucheVon} onChange={datum => setSucheVon(datum)}/>
        &nbsp;
        <DatumPicker locale={i18n.locale} value={sucheBis} minDate={sucheVon} onChange={datum => setSucheBis(datum)}/>
        &nbsp;
        <button className="btn btn-primary btn-small" disabled={!(sucheVon && sucheBis)} onClick={sucheStarten}>{texte.starten}</button>
        {status.sucheLaeuft ||
          <Tooltip content={texte.exportCsv} className="pull-right">
            <a className="export-csv" target="_blank" href={`${api_suche}.csv?von=${JSON.stringify(sucheVon)}&bis=${JSON.stringify(sucheBis)}`}/>
          </Tooltip>
        }
      </div>
      {status.fehler.length > 0 &&
        <div>
          {status.fehler.map((f, index) => <div key={index} className="alert alert-error">{f}</div>)}
        </div>
      }
      {(status.sucheLaeuft || !initialGeladen) &&
        <div className="ajax-spinner-bar"><span/></div>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    status: state.status,
    geschaeftsjahr: state.bexio.geschaeftsjahr,
    i18n: state.i18n,
    api_suche: state.api.rechnungenSuche,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rechnungenSuchen: (von, bis) => { dispatch(rechnungskontrollenSuche(von, bis)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RechnungenSuche);

