import React, {useState} from "react";
import {connect} from "react-redux";
import {formatiereBetrag} from "../../../lib/formatierungen";

const FormBetrag = ({name, value, readonly = false, onValueChange, fehler}) => {
  const [betrag, setBetrag] = useState(value || 0);

  if (readonly) {
    return <>{betrag}</>;
  }

  const handleOnBlur = (wert) => {
    const formattedWert = formatiereBetrag(wert);
    if (!isNaN(formattedWert)) {
      setBetrag(formattedWert);
    }
    onValueChange(name, formattedWert);
  };

  const fehlerEintrag = fehler.find(f => f.name == name);

  return (
    <span className={fehlerEintrag ? ' error' : ''}>
      <input type="text" inputMode="numeric" className="text-right" style={{width: '100px'}} value={betrag}
             onChange={e => setBetrag(e.currentTarget.value)}
             onBlur={e => handleOnBlur(e.currentTarget.value)}/>
      {fehlerEintrag && fehlerEintrag.messages.map((msg, index) => <div key={index}>{msg}</div>)}
    </span>
  );
};

const mapStateToProps = (state) => {
  return {
    fehler: state.fehler.messages || []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FormBetrag);

