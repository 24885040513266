import {combineReducers} from "redux";
import {dummyReducer} from "../../../lib/datenhaltung";
import mirwaldReducer from "./modules/mirwald";
import sportmotorikReducer from "./modules/sportmotorik";
import sportspezifischReducer from "./modules/sportspezifisch";
import apiReducer from "./modules/api";

export default combineReducers({
  team: dummyReducer,
  spieler: dummyReducer,
  listen: dummyReducer,
  mirwald: mirwaldReducer,
  sportmotorik: sportmotorikReducer,
  sportspezifisch: sportspezifischReducer,
  api: apiReducer,
  i18n: dummyReducer,
  rollbar: dummyReducer,
});
