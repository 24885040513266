import React, {useEffect, useState} from "react";
import ModalerDialog from "../ModalerDialog";
import {executeGet, executeJson} from "../../lib/fetchWrapper";
import {ladeCsrfSettings, ladeLocale} from "../../lib/datenhaltung";
import Stammdaten from "./Stammdaten";
import Adresse from "./Adresse";
import Kommunikation from "./Kommunikation";

const BearbeitenTexte = {
  de: {
    speichern: 'Speichern',
    abbrechen: 'Abbrechen',
    keineBerechtigung: 'Sie haben keine Berechtigung zum bearbeiten dieser Person',
    stammdaten: {
      titel: 'Stammdaten',
      firmenname: 'Firmenname',
      zusatz: 'Zusatz',
      gruendung: 'Gründungsdatum',
      firmensitz: 'Firmensitz',
      anrede: 'Anrede',
      nachname: 'Name',
      vorname: 'Vorname',
      geburtsdatum: 'Geburtsdatum',
      geburtsort: 'Geburtsort / Heimatort',
      nationalitaet: 'Nationalität',
      land: 'Land',
      sprache: 'Sprache',
      korrespondenzsprache: 'Korrespondenzsprache',
      beruf: 'Beruf',
      ahv_nr: 'AHV-Nr',
    },
    adresse: {
      titel: 'Adresse',
      zusatz: 'Zusatz',
      strasse: 'Strasse',
      plz: 'PLZ',
      ort: 'Ort',
      kanton: 'Kanton',
      land: 'Land',
    },
    kommunikation: {
      titel: 'Kommunikation',
      telefon_privat: 'Tel. Privat',
      telefon_kontaktperson: 'Tel. Kontaktperson',
      telefon_geschaeft_direkt: 'Tel. G. direkt',
      telefon_geschaeft_zentrale: 'Tel. G. Zentrale',
      fax_geschaeft: 'Fax Geschäft',
      telefon_mobil: 'Tel. Mobil',
      telefon_mobil_zusatz: 'Name/Vorname des Mobilbesitzers',
      telefon_gesetzlicher_vertreter1: 'Tel. Mobil gesetzlicher Vertreter',
      telefon_gesetzlicher_vertreter1_zusatz: 'Name/Vorname gesetzlicher Vertreter',
      telefon_gesetzlicher_vertreter2: 'Tel. Mobil gesetzlicher Vertreter 2',
      telefon_gesetzlicher_vertreter2_zusatz: 'Name/Vorname gesetzlicher Vertreter 2',
      email: 'Email 1',
      email_offiziell: 'Email (offiziell)',
      email_geschaeft: 'Email 2',
      als_login_verwendbar: 'Kann als Login benutzt werden',
      email_gesetzlicher_vertreter1: 'Email gesetzlicher Vertreter',
      email_gesetzlicher_vertreter2: 'Email gesetzlicher Vertreter 2',
      homepage: 'Homepage'
    },
  },
  fr: {
    speichern: 'Sauvegarder',
    abbrechen: 'Annuler',
    keineBerechtigung: "Vous n'avez pas l'autorisation de modifier cette personne",
    stammdaten: {
      titel: 'Données de base',
      firmenname: 'Nom de la société',
      zusatz: 'Additif',
      gruendungsdatum: 'Fondé',
      firmensitz: 'Siège social',
      anrede: 'Salutation',
      nachname: 'Nom',
      vorname: 'Prénom',
      geburtsdatum: 'Date de naissance',
      geburtsort: 'Lieu de naissance / Lieu d‘origine',
      nationalitaet: 'Nationalité',
      land: 'Pays',
      sprache: 'Langue',
      korrespondenzsprache: 'Langue de correspondance',
      beruf: 'Profession',
      ahv_nr: 'N° AVS',
    },
    adresse: {
      titel: 'Adresse',
      zusatz: 'Additif',
      strasse: 'Rue',
      plz: 'Code postal',
      ort: 'Lieu',
      kanton: 'Canton',
      land: 'Pays',
    },
    kommunikation: {
      titel: 'Communication',
      telefon_privat: 'Téléphone privé',
      telefon_kontaktperson: 'Téléphone personne de contact',
      telefon_geschaeft_direkt: 'Téléphone prof. direct',
      telefon_geschaeft_zentrale: 'Téléphone prof. du siège social',
      fax_geschaeft: 'Téléfax d‘entreprise',
      telefon_mobil: 'Téléphone portable',
      telefon_mobil_zusatz: 'om et prénom du propriétaire du téléphone portable',
      telefon_gesetzlicher_vertreter1: 'Tél. portable représentant légal',
      telefon_gesetzlicher_vertreter1_zusatz: 'Nom/prénom représentant légal',
      telefon_gesetzlicher_vertreter2: 'Tél. portable représentant légal 2',
      telefon_gesetzlicher_vertreter2_zusatz: 'Nom/prénom représentant légal 2',
      email: 'E-mail 1',
      email_offiziell: 'E-Mail (officiel)',
      email_geschaeft: 'E-mail 2',
      als_login_verwendbar: 'Utilisable comme login',
      email_gesetzlicher_vertreter1: 'E-mail représentant légal',
      email_gesetzlicher_vertreter2: 'E-mail représentant légal 2',
      homepage: 'Site web'
    },
  },
  it: {
    speichern: 'Salvare',
    abbrechen: 'Cancellare',
    keineBerechtigung: 'Sie haben keine Berechtigung zum bearbeiten dieser Person',
    stammdaten: {
      firmenname: 'Nome azienda',
      zusatz: 'Additivo',
      gruendungsdatum: 'Fondato',
      firmensitz: 'Sede centrale',
      titel: 'Dati base ',
      anrede: 'Titolo',
      nachname: 'Cognome',
      vorname: 'Nome',
      geburtsdatum: 'Data di nascita',
      geburtsort: 'Luogo di nascita / Luogo di attinenza',
      nationalitaet: 'Nazionalità',
      land: 'Paese',
      sprache: 'Lingua',
      korrespondenzsprache: 'Lingua di corrispondenza',
      beruf: 'Professione',
      ahv_nr: 'Numero AVS',
    },
    adresse: {
      titel: 'Indirizzo',
      zusatz: 'Aggiunta (c/o)',
      strasse: 'Via',
      plz: 'NPA',
      ort: 'Luogo',
      kanton: 'Cantone',
      land: 'Paese',
    },
    kommunikation: {
      titel: 'Comunicazione',
      telefon_privat: 'Telefono privato',
      telefon_kontaktperson: 'Telefono contatto',
      telefon_geschaeft_direkt: 'Telefono ufficio diretto',
      telefon_geschaeft_zentrale: 'Telefono ufficio centrale',
      fax_geschaeft: 'Telefax ufficio',
      telefon_mobil: 'Telefono cellulare',
      telefon_mobil_zusatz: 'Nome e cognomedel del prorietario del telefono cellulare',
      telefon_gesetzlicher_vertreter1: 'Telefono cellulare rappresentante legale',
      telefon_gesetzlicher_vertreter1_zusatz: 'Nome/cognome rappresentante legale',
      telefon_gesetzlicher_vertreter2: 'Telefono cellulare rappresentante legale 2',
      telefon_gesetzlicher_vertreter2_zusatz: 'Nome/cognome rappresentante legale 2',
      email: 'E-mail 1',
      email_offiziell: 'E-mail (ufficiale)',
      email_geschaeft: 'E-mail 2',
      als_login_verwendbar: 'Utilizzabile come login',
      email_gesetzlicher_vertreter1: 'E-mail rappresentante legale',
      email_gesetzlicher_vertreter2: 'E-mail rappresentante legale 2',
      homepage: 'Sito internet'
    },
  },
};

const Bearbeiten = ({person, onClose = undefined}) => {
  const [xhrAktiv, setXhrAktiv] = useState(null);
  const [keineBerechtigung, setKeineBerechtigung] = useState(false);
  const [personEdit, setPersonEdit] = useState(null);
  const [laender, setLaender] = useState(null);
  const [sprachen, setSprachen] = useState(null);
  const [korrespondenzsprachen, setKorrespondenzsprachen] = useState(null);
  const [locale, _setLocale] = useState(ladeLocale());
  const [csrfSettings, _seCsrfSettings] = useState(ladeCsrfSettings());
  const texte = BearbeitenTexte[locale];

  useEffect(() => {
    setXhrAktiv('GET');
    // initial personen daten abfragen
    executeGet(person.editApiUrl).then((result) => {
      if (result.ok) {
        const data = result.data;
        setPersonEdit(data.person);
        setLaender(data.laender);
        setSprachen(data.sprachen);
        setKorrespondenzsprachen(data.korrespondenzsprachen);
      } else {
        if (result.status == 404) {
          setKeineBerechtigung(true);
        }
      }
      setXhrAktiv(null);
    }).catch(error => {
      setXhrAktiv(null);
    });
  }, []);

  const personWertChange = (feldname, wert) => {
    if (personEdit) {
      const obj = {...personEdit};
      if (wert === '') {
        obj[feldname] = null;
      } else {
        obj[feldname] = wert;
      }
      setPersonEdit(obj);
    }
  };

  const adresseWertChange = (feldname, wert) => {
    if (personEdit) {
      const obj = {...personEdit};
      if (wert === '') {
        obj.post_adresse_attributes[feldname] = null;
      } else {
        obj.post_adresse_attributes[feldname] = wert;
      }
      setPersonEdit(obj);
    }
  };

  const schliessen = () => {
    if (onClose) {
      onClose();
    }
  };

  const speichern = (event) => {
    if (xhrAktiv != null) {
      return;
    }
    
    event.preventDefault(); // kein form post auslösen

    setXhrAktiv('PATCH');

    executeJson(person.updateApiUrl, csrfSettings.token, 'PATCH', { person: personEdit}).then((result) => {
      const data = result.data;
      setPersonEdit(data.person);
      if (result.ok && result.status === 200) {
        schliessen();
      } else {
        setXhrAktiv(null);
      }
    }).catch(error => {
      setXhrAktiv(null);
    });
  };

  return (
    <ModalerDialog>
      <div className="modal breit-modal">
        <form onSubmit={speichern} className="form-horizontal">
          <div className="modal-header">
            <button type="button" className="close" onClick={schliessen} aria-hidden="true">&times;</button>
            <h3>{person.name}</h3>
          </div>
          <div className="modal-body">
            {xhrAktiv == 'GET' &&
            <div className="ajax-spinner-bar"><span/></div>
            }
            {keineBerechtigung &&
            <div className="alert alert-error">{texte.keineBerechtigung}</div>
            }
            {personEdit != null &&
            <div>
              <Stammdaten person={personEdit} texte={texte.stammdaten} sprachen={sprachen} korrespondenzsprachen={korrespondenzsprachen} onWertChange={personWertChange}/>
              <Adresse person={personEdit} texte={texte.adresse} laender={laender} onWertChange={adresseWertChange}/>
              <Kommunikation person={personEdit} texte={texte.kommunikation} onWertChange={personWertChange}/>
            </div>
            }
          </div>
          <div className="modal-footer">
            {personEdit != null &&
            <button type="submit" className="btn btn-primary">
              {texte.speichern}
              {xhrAktiv === 'PATCH' && <>&nbsp;<i className="fa fa-refresh fa-spin"></i></>}
            </button>
            }
            <button type="button" className="btn" onClick={schliessen}>{texte.abbrechen}</button>
          </div>
        </form>
      </div>
    </ModalerDialog>
  );
};

export default Bearbeiten;
