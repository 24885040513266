import React from "react";
import {connect} from "react-redux";
import {statusDisclaimerAnzeigen, statusDisclaimerHinweisGelesen} from "../state/modules/status";

const DisclaimerHinweis = ({anzeigen, texte, hinweisGelesen, disclaimerAnzeigen}) => {

  if (anzeigen) {
    return (
      <div className="disclaimer-container">
        <div className="disclaimer-inner">
          <p>{texte.hinweis}<b><a onClick={disclaimerAnzeigen}>{texte.anzeigen}</a></b></p>
          <div className="text-center">
            <button className="btn btn-large" onClick={hinweisGelesen}>{texte.schliessen}</button>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

const mapStateToProps = (state) => {
  return {
    anzeigen: state.status.disclaimerHinweis,
    texte: state.i18n.texte.disclaimer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hinweisGelesen: () => { dispatch(statusDisclaimerHinweisGelesen()) },
    disclaimerAnzeigen: () => { dispatch(statusDisclaimerAnzeigen(true)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DisclaimerHinweis);
