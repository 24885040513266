// @flow

import type {EinsatzState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";
import SpielEinsatzInfos from "./SpielEinsatzInfos";
import TurnierEinsatzInfos from "./TurnierEinsatzInfos";
import TrainingEinsatzInfos from "./TrainingEinsatzInfos";

const EinsatzInfos = (props: {
  einsatz: EinsatzState,
}) => {
  if (props.einsatz.typ === "spiel") {
    return <SpielEinsatzInfos/>;
  } else if (props.einsatz.typ === "turnier") {
    return <TurnierEinsatzInfos/>
  } else if (props.einsatz.typ === "training") {
    return <TrainingEinsatzInfos/>
  }
};

const mapStateToProps = (state) => {
  return {
    einsatz: state.einsatz,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EinsatzInfos);
