import React from "react";
import {connect} from "react-redux";
import DatePicker from "react-datepicker/es";
import 'react-datepicker/src/stylesheets/datepicker.scss'
import {registerLocale} from "react-datepicker";
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';

registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('it', it);

const Datepicker = ({datum, mitZeit = false, changeHandler, texte, locale}) => {
  const inputClassName = mitZeit ? 'datetime' : 'date';
  
  const onDateChange = (date) => {
    if (!(date instanceof Date)) {
      return;
    }
    changeHandler(date);
  };

  return (
    <DatePicker
      selected={datum}
      onChange={onDateChange}
      showTimeSelect={mitZeit}
      locale={locale}
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption={texte.zeit}
      dateFormat="dd.MM.yyyy HH:mm"
      className={inputClassName}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    texte: state.i18n.texte,
    locale: state.i18n.locale
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Datepicker);
