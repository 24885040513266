import React, { useContext } from 'react'
import { Kontext } from "../index";
import Tooltip from "../../../components/Tooltip";

const AnwesenheitIcon = (props) => {
  const eintrag = props.eintrag;
  const context = useContext(Kontext);
  let iconOderGrafik = <i className="icon icon-ok" />;
  let titel = context.texte.typ[eintrag.typ];

  const aktiviertChange = () => {
    if (eintrag.bearbeitbar === true) {
      eintrag.aktiviert = !eintrag.aktiviert;
      props.onAktiviertChange(eintrag);
    }
  };

  if (eintrag.abwesend) {
    iconOderGrafik = <i className="icon icon-remove" />;
    switch (eintrag.typ) {
      case 'spiel':
        if (eintrag.abwesend.grund > 0) {
          const grund = context.abwesenheitGruende.spiel.find((g) => { return g.value == eintrag.abwesend.grund; }).text;
          titel = `${titel}, ${grund}`;
        }
        break;
      case 'turnier':
      case 'training':
        if (eintrag.abwesend.grund > 0) {
          iconOderGrafik = <img src={context.bilder.training[`grund${eintrag.abwesend.grund}`]} className="abwesend" />;
          const grund = context.abwesenheitGruende.training.find((g) => { return g.value == eintrag.abwesend.grund; }).text;
          titel = `${titel}, ${grund}`;
        }
        break;
    }
  }

  const tooltipContent = <>{titel}<br/>{eintrag.zeitpunkt}</>;

  return (
    <Tooltip content={tooltipContent} className={eintrag.aktiviert ? "aktiv" : "inaktiv"}>
      <span className={"label label-warning" + (eintrag.abwesend ? " abwesend" : "")} onClick={() => aktiviertChange()}>
        {iconOderGrafik}
      </span>
    </Tooltip>
  );
};
export default AnwesenheitIcon
