import React from "react";

const ControlGroup = ({children, labelText, htmlFor, errors}) => {
  return (
    <div className={`control-group${ errors ? ' error' : ''}`}>
      <label className="control-label" htmlFor={htmlFor}>{labelText}</label>
      <div className="controls">
        {children}
        {errors && errors.join(' ')}
      </div>
    </div>
  );
};
export default ControlGroup;
