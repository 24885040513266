function preloaderVorlagenTexte(options) {
  return {
    vorlagen: options.vorlagen,
    buchungstypen: options.buchungstypen,
    daten: {
      editVorlage: null,
      requestAktiv: false,
    },
    api: options.api,
    i18n: options.i18n,
    rollbar: options.rollbar,
  };
}

export default preloaderVorlagenTexte;
