import {executeRequest} from "../../lib/fetchHelper";
import {vorlageDatenEdit} from "./vorlagenDaten";

const VORLAGE_SETZEN = 'VORLAGE_SETZEN';

export function vorlageErstellen(inaktiv, bezeichnung, buchungstypId, texte) {
  return (dispatch, getState) => {
    const api = getState().api;
    const daten = {
      inaktiv: inaktiv,
      bezeichnung: bezeichnung,
      texte: texte
    };
    if (buchungstypId > 0) {
      daten.buchungstypId = buchungstypId;
    }
    return executeRequest(api.vorlagen, api.token, 'POST', daten)
      .then(result => {
        const vorlage = result.data.vorlage;
        if (vorlage.id > 0) {
          dispatch({type: VORLAGE_SETZEN, vorlage: vorlage});
        }
        dispatch(vorlageDatenEdit(vorlage));
      });
  };
}

export function vorlageUpdate(vorlageId, inaktiv, bezeichnung, buchungstypId, texte) {
  return (dispatch, getState) => {
    const api = getState().api;
    const daten = {
      inaktiv: inaktiv,
      bezeichnung: bezeichnung,
      texte: texte
    };
    if (buchungstypId > 0) {
      daten.buchungstypId = buchungstypId;
    }
    return executeRequest(`${api.vorlagen}/${vorlageId}`, api.token, 'PUT', daten)
      .then(result => {
        dispatch({type: VORLAGE_SETZEN, vorlage: result.data.vorlage});
        dispatch(vorlageDatenEdit(result.data.vorlage));
      });
  };
}

export function vorlageTextDelete(vorlageId, text) {
  return (dispatch, getState) => {
    const api = getState().api;
    const daten = {
      spracheId: text.spracheId,
    };
    return executeRequest(`${api.vorlagen}/${vorlageId}`, api.token, 'delete', daten)
      .then(result => {
        dispatch({type: VORLAGE_SETZEN, vorlage: result.data.vorlage});
        dispatch(vorlageDatenEdit(result.data.vorlage));
      });
  };
}

function vorlagenReducer(state = [], action) {
  switch (action.type) {
    case VORLAGE_SETZEN:
      return [
        ...state.filter(v => v.id !== action.vorlage.id),
        action.vorlage
      ];
    default:
      return state;
  }
}

export default vorlagenReducer;
