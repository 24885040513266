import React from "react";
import {connect} from "react-redux";
import {statusFavoritenTeamsEntfernen, statusFavoritenTeamsSetzen} from "../state/modules/status";

const TeamLabel = ({team, leerAnzeigen = false, iconWhite = false, status, teamSetzen, teamEntfernen}) => {

  const istFavorit = status.favoritenTeams.includes(team.id);
  const cssClass = [];
  if (iconWhite) {
    cssClass.push("icon-white");
  }
  if (istFavorit) {
    cssClass.push("icon-star");
  } else if (leerAnzeigen) {
    cssClass.push("icon-star-empty");
  }

  const toggleFavorit = () => {
    if (istFavorit) {
      teamEntfernen(team.id);
    } else {
      teamSetzen(team.id);
    }
  };
  
  let icon = null;
  if (istFavorit || leerAnzeigen) {
    icon = <i className={cssClass.join(" ")}/>;
  }

  return (
    <span className="favorit" onClick={toggleFavorit}>
      {team.bezeichnung}
      {icon &&
      <>&nbsp;<i className={cssClass.join(" ")}/></>
      }
    </span>
  );
};

const mapStateToProps = (state) => {
  return {
    status: state.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    teamSetzen: (teamId) => { dispatch(statusFavoritenTeamsSetzen(teamId)); },
    teamEntfernen: (teamId) => { dispatch(statusFavoritenTeamsEntfernen(teamId)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamLabel);
