
export const PERIODE = {
  noch_nicht_gestartet: 'noch_nicht_gestartet',
  spiel_gestartet: 'spiel_gestartet',
  zweite_halbzeit: 'zweite_halbzeit',
  zweites_drittel: 'zweites_drittel',
  drittes_drittel: 'drittes_drittel',
  zweites_viertel: 'zweites_viertel',
  drittes_viertel: 'drittes_viertel',
  viertes_viertel: 'viertes_viertel',
  erste_verlaengerung: 'erste_verlaengerung',
  zweite_verlaengerung: 'zweite_verlaengerung',
  penaltyschiessen: 'penaltyschiessen',
  pause_nach_erster_halbzeit: 'pause_nach_erster_halbzeit',
  pause_nach_zweiter_halbzeit: 'pause_nach_zweiter_halbzeit',
  pause_nach_erstem_drittel: 'pause_nach_erstem_drittel',
  pause_nach_zweitem_drittel: 'pause_nach_zweitem_drittel',
  pause_nach_drittem_drittel: 'pause_nach_drittem_drittel',
  pause_nach_erstem_viertel: 'pause_nach_erstem_viertel',
  pause_nach_zweitem_viertel: 'pause_nach_zweitem_viertel',
  pause_nach_drittem_viertel: 'pause_nach_drittem_viertel',
  pause_nach_viertem_viertel: 'pause_nach_viertem_viertel',
  pause_nach_erster_verlaengerung: 'pause_nach_erster_verlaengerung',
  pause_nach_zweiter_verlaengerung: 'pause_nach_zweiter_verlaengerung',
  spiel_beendet: 'spiel_beendet',
  spiel_abgebrochen: 'spiel_abgebrochen',
};

export const AKTION = {
  // typ POST
  spiel_starten: 'spiel_starten',
  zweite_halbzeit_starten: 'zweite_halbzeit_starten',
  zweites_drittel_starten: 'zweites_drittel_starten',
  drittes_drittel_starten: 'drittes_drittel_starten',
  zweites_viertel_starten: 'zweites_viertel_starten',
  drittes_viertel_starten: 'drittes_viertel_starten',
  viertes_viertel_starten: 'viertes_viertel_starten',
  erste_verlaengerung_starten: 'erste_verlaengerung_starten',
  zweite_verlaengerung_starten: 'zweite_verlaengerung_starten',
  penaltyschiessen_starten: 'penaltyschiessen_starten',

  // typ DELETE
  erste_halbzeit_beenden: 'erste_halbzeit_beenden',
  zweite_halbzeit_beenden: 'zweite_halbzeit_beenden',
  erstes_drittel_beenden: 'erstes_drittel_beenden',
  zweites_drittel_beenden: 'zweites_drittel_beenden',
  drittes_drittel_beenden: 'drittes_drittel_beenden',
  erstes_viertel_beenden: 'erstes_viertel_beenden',
  zweites_viertel_beenden: 'zweites_viertel_beenden',
  drittes_viertel_beenden: 'drittes_viertel_beenden',
  viertes_viertel_beenden: 'viertes_viertel_beenden',
  erste_verlaengerung_beenden: 'erste_verlaengerung_beenden',
  zweite_verlaengerung_beenden: 'zweite_verlaengerung_beenden',
  spiel_beenden: 'spiel_beenden',
  spiel_abbrechen: 'spiel_abbrechen',
  noop: 'noop',
};

export const EREIGNIS_TYP = {
  default: 0,
  tor: 1,
  auswechslung: 2,
  gelbeKarte: 3,
  roteKarte: 4,
  assist: 9,
  penaltyschiessen: 12,
  spielinformation: 13
};
export const EREIGNIS_SUBTYP = {
  default: 0,
  kopftor: 1,
  eigentor: 2,
  freistosstor: 3,
  penaltytor: 4,
  penaltyschiessen_tor: 90,
  penaltyschiessen_kein_tor: 91,
  penaltyschiessen_beginnt: 110
};

// initialer zustand für spielfeld situation anhand der spielerkarte erstellen. das ist die ausgangsituation für ein replay der ereignisse.
export function erstelleZustandFuerSpielerkarte(spielerkarte) {
  return {
    feldspieler: spielerkarte.startformation.map(s => ({ id: s.id, unregistriert: s.unregistrierter_spieler, name: s.name, position_id: s.position_id, rueckennummer: s.rueckennummer, ereignisse: [] })),
    ersatzspieler: spielerkarte.ersatzformation.map(s => ({ id: s.id, unregistriert: s.unregistrierter_spieler, name: s.name, position_id: s.position_id, rueckennummer: s.rueckennummer, ereignisse: [] })),
    staff: spielerkarte.staffbank.map(s => ({ id: s.id, unregistriert: s.unregistrierter_spieler, name: s.name, ereignisse: [] })),
  };
}

// liefert alle möglichen aktionen
export function naechsteAktionen(periode, liga, laufendeSpielzeit, texte) {
  const data = [];
  let submenu = null;
  const anzahlPerioden = liga.perioden.length;
  const [spielminuten, verlaengerungen] = spielminutenUndVerlaengerungenErstellen(liga);
  
  switch(periode) {
    case PERIODE.noch_nicht_gestartet:
      data.push({ aktion: AKTION.spiel_starten, zeit_laeuft: true, typ: 'POST', text: texte.spiel_starten, minute: 0 });
      break;
    case PERIODE.spiel_gestartet:
      switch(anzahlPerioden) {
        case 4:
          data.push({ aktion: AKTION.erstes_viertel_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.erstes_viertel_beenden, minute: null });
          break;
        case 3:
          data.push({ aktion: AKTION.erstes_drittel_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.erstes_drittel_beenden, minute: null });
          break;
        default:
          data.push({ aktion: AKTION.erste_halbzeit_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.erste_halbzeit_beenden, minute: null });
          break;
      }
      break;

    // spiel nach halbzeiten
    case PERIODE.pause_nach_erster_halbzeit:
      data.push({ aktion: AKTION.zweite_halbzeit_starten, zeit_laeuft: true, typ: 'POST', text: texte.zweite_halbzeit_starten, minute: spielminuten[1] });
      break;
    case PERIODE.zweite_halbzeit:
      submenu = [];
      submenu.push({ aktion: AKTION.spiel_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.spiel_beenden, minute: null });
      submenu.push({ aktion: AKTION.zweite_halbzeit_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.verlaengerung_oder_elfmeterschiessen, minute: null });
      data.push({ text: texte.spiel_abpfeiffen, submenu: submenu });
      break;
    case PERIODE.pause_nach_zweiter_halbzeit:
      data.push({ aktion: AKTION.erste_verlaengerung_starten, zeit_laeuft: true, typ: 'POST', text: texte.erste_verlaengerung_starten, minute: spielminuten[2] });
      data.push({ aktion: AKTION.penaltyschiessen_starten, zeit_laeuft: false, typ: 'POST', text: texte.elfmeter_starten, minute: spielminuten[2] + 1 });
      break;

    // spiel nach dritteln
    case PERIODE.pause_nach_erstem_drittel:
      data.push({ aktion: AKTION.zweites_drittel_starten, zeit_laeuft: true, typ: 'POST', text: texte.zweites_drittel_starten, minute: spielminuten[1] });
      break;
    case PERIODE.zweites_drittel:
      data.push({ aktion: AKTION.zweites_drittel_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.zweites_drittel_beenden, minute: null });
      break;
    case PERIODE.pause_nach_zweitem_drittel:
      data.push({ aktion: AKTION.drittes_drittel_starten, zeit_laeuft: true, typ: 'POST', text: texte.drittes_drittel_starten, minute: spielminuten[2] });
      break;
    case PERIODE.drittes_drittel:
      submenu = [];
      submenu.push({ aktion: AKTION.spiel_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.spiel_beenden, minute: null });
      submenu.push({ aktion: AKTION.drittes_drittel_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.verlaengerung_oder_elfmeterschiessen, minute: null });
      data.push({ text: texte.spiel_abpfeiffen, submenu: submenu });
      break;
    case PERIODE.pause_nach_drittem_drittel:
      data.push({ aktion: AKTION.erste_verlaengerung_starten, zeit_laeuft: true, typ: 'POST', text: texte.erste_verlaengerung_starten, minute: spielminuten[3] });
      data.push({ aktion: AKTION.penaltyschiessen_starten, zeit_laeuft: false, typ: 'POST', text: texte.elfmeter_starten, minute: spielminuten[3] + 1 });
      break;

    // spiel nach vierteln
    case PERIODE.pause_nach_erstem_viertel:
      data.push({ aktion: AKTION.zweites_viertel_starten, zeit_laeuft: true, typ: 'POST', text: texte.zweites_viertel_starten, minute: spielminuten[1] });
      break;
    case PERIODE.zweites_viertel:
      data.push({ aktion: AKTION.zweites_viertel_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.zweites_viertel_beenden, minute: null });
      break;
    case PERIODE.pause_nach_zweitem_viertel:
      data.push({ aktion: AKTION.drittes_viertel_starten, zeit_laeuft: true, typ: 'POST', text: texte.drittes_viertel_starten, minute: spielminuten[2] });
      break;
    case PERIODE.drittes_viertel:
      data.push({ aktion: AKTION.drittes_viertel_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.drittes_viertel_beenden, minute: null });
      break;
    case PERIODE.pause_nach_drittem_viertel:
      data.push({ aktion: AKTION.viertes_viertel_starten, zeit_laeuft: true, typ: 'POST', text: texte.viertes_viertel_starten, minute: spielminuten[3] });
      break;
    case PERIODE.viertes_viertel:
      submenu = [];
      submenu.push({ aktion: AKTION.spiel_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.spiel_beenden, minute: null });
      submenu.push({ aktion: AKTION.viertes_viertel_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.verlaengerung_oder_elfmeterschiessen, minute: null });
      data.push({ text: texte.spiel_abpfeiffen, submenu: submenu });
      break;
    case PERIODE.pause_nach_viertem_viertel:
      data.push({ aktion: AKTION.erste_verlaengerung_starten, zeit_laeuft: true, typ: 'POST', text: texte.erste_verlaengerung_starten, minute: spielminuten[4] });
      data.push({ aktion: AKTION.penaltyschiessen_starten, zeit_laeuft: false, typ: 'POST', text: texte.elfmeter_starten, minute: spielminuten[4] + 1 });
      break;

    // gemeinsam gehts weiter:
    case PERIODE.erste_verlaengerung:
      if (liga.verlaengerungen.length === 2) {
        data.push({ aktion: AKTION.erste_verlaengerung_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.erste_verlaengerung_beenden, minute: null });
      } else {
        submenu = [];
        submenu.push({ aktion: AKTION.spiel_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.spiel_beenden, minute: null });
        submenu.push({ aktion: AKTION.erste_verlaengerung_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.elfmeter_schiessen, minute: null });
        data.push({ text: texte.erste_verlaengerung_beenden, submenu: submenu });
      }
      break;
    case PERIODE.pause_nach_erster_verlaengerung:
      if (liga.verlaengerungen.length === 2) {
        data.push({ aktion: AKTION.zweite_verlaengerung_starten, zeit_laeuft: true, typ: 'POST', text: texte.zweite_verlaengerung_starten, minute: spielminuten[spielminuten.length - 1] + verlaengerungen[1] });
      } else {
        data.push({ aktion: AKTION.penaltyschiessen_starten, zeit_laeuft: false, typ: 'POST', text: texte.elfmeter_starten, minute: null });
      }
      break;
    case PERIODE.zweite_verlaengerung:
      submenu = [];
      submenu.push({ aktion: AKTION.spiel_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.spiel_beenden, minute: null });
      submenu.push({ aktion: AKTION.zweite_verlaengerung_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.elfmeter_schiessen, minute: null });
      data.push({ text: texte.zweite_verlaengerung_beenden, submenu: submenu });
      break;
    case PERIODE.pause_nach_zweiter_verlaengerung:
      data.push({ aktion: AKTION.penaltyschiessen_starten, zeit_laeuft: false, typ: 'POST', text: texte.elfmeter_starten, minute: null });
      break;
  }
  
  // spiel beenden:
  switch(periode) {
    case PERIODE.penaltyschiessen:
      data.push({ aktion: AKTION.spiel_beenden, zeit_laeuft: false, typ: 'DELETE', text: texte.spiel_beenden, minute: null });
      break
  }
  // spiel abbrechen:
  switch(periode) {
    case PERIODE.noch_nicht_gestartet:
    case PERIODE.spiel_beendet:
    case PERIODE.spiel_abgebrochen:
      break;
    default:
      submenu = [];
      submenu.push({ text: texte.spiel_abbrechen_info });
      submenu.push({ aktion: AKTION.spiel_abbrechen, zeit_laeuft: false, typ: 'DELETE', text: texte.spiel_abbrechen_ja, minute: null });
      submenu.push({ aktion: AKTION.noop, text: texte.spiel_abbrechen_nein });
      data.push({ text: texte.spiel_abbrechen, submenu: submenu });
      break;
  }

  return data;
}

export function berechneSpielUndZusatzminute(periode, liga, laufendeMinute) {
  const [spielminuten, verlaengerungen] = spielminutenUndVerlaengerungenErstellen(liga);
  let regulaereSpielminute = laufendeMinute;

  switch(periode) {
    case PERIODE.spiel_gestartet:
      regulaereSpielminute = spielminuten[1];
      break;
    case PERIODE.zweite_halbzeit:
    case PERIODE.zweites_drittel:
    case PERIODE.zweites_viertel:
      regulaereSpielminute = spielminuten[2];
      break;
    case PERIODE.drittes_drittel:
    case PERIODE.drittes_viertel:
      regulaereSpielminute = spielminuten[3];
      break;
    case PERIODE.viertes_viertel:
      regulaereSpielminute = spielminuten[4];
      break;
    case PERIODE.erste_verlaengerung:
      regulaereSpielminute = spielminuten[spielminuten.length - 1] + verlaengerungen[1];
      break;
    case PERIODE.zweite_verlaengerung:
      regulaereSpielminute = spielminuten[spielminuten.length - 1] + verlaengerungen[2];
      break;
    case PERIODE.pause_nach_zweiter_verlaengerung:
      // TODO: welche zeit wird beim penaltyschiessen gesetzt? und läuft dann die zeit auch wieder??? aktuell reguläre zeit mit zusatzminute 99
      regulaereSpielminute = spielminuten[spielminuten.length - 1] + verlaengerungen[2];
      return [regulaereSpielminute, 99];
  }
  
  const zusatzminute = Math.max(laufendeMinute - regulaereSpielminute, 0); // niemals negativen wert zurückgeben
  const spielminute = Math.min(laufendeMinute, regulaereSpielminute); // spielminute ist maximal die reguläre minute

  return [spielminute, zusatzminute];
}

function spielminutenUndVerlaengerungenErstellen(liga) {
  const spielminuten = [0]; // gibt sowas wie [0, 45, 90]
  liga.perioden.forEach(min => { spielminuten.push(min + spielminuten[spielminuten.length - 1]); });

  const verlaengerungen = [0];
  liga.verlaengerungen.forEach(min => { verlaengerungen.push(min + verlaengerungen[verlaengerungen.length - 1]); });

  return [spielminuten, verlaengerungen];
}

export function periodenEndeMinutenErstellen(liga) {
  const spielminuten = [0]; // damit forEach korrekt tut, wird nachher wieder entfernt
  liga.perioden.forEach(min => { spielminuten.push(min + spielminuten[spielminuten.length - 1]); });
  liga.verlaengerungen.forEach(min => { spielminuten.push(min + spielminuten[spielminuten.length - 1]); });
  spielminuten.shift();
  return spielminuten;
}

