// alles in dieser Datei wird in app/javascript/apps/Videoanalyse/store/index.js mittels useGlobalHook an useGlobal angehängt

import {apiCreateComment, apiUpdateComment, apiDeleteComment} from "../services/api";

export const setSpielInfos = (store, spielInfos) => {
  store.setState({spielInfos})
};

export const setVideos = (store, videos) => {
  const firstVideo = videos[0] || {};
  store.setState({videos, selectedVideo: firstVideo.id});
};

export const selectVideo = (store, selectedVideo) => {
  const video = document.querySelector("div.video-wrapper video");

  if (store.state.selectedVideo !== selectedVideo) {
    store.setState({selectedVideo});
    if (video) video.load();
  }

  if (video) video.play().catch(() => {
    // noop
  });
};

export const setEreignisse = (store, ereignisse) => {
  store.setState({ereignisse});
};

export const setFormVisible = (store, formVisible) => {
  store.setState({formVisible});
};

export const setFormItem = (store, formItem) => {
  store.setState({formItem});
};

export const setAutorisierung = (store, autorisierung) => {
  store.setState({autorisierung});
};

export const setStammdaten = (store, stammdaten) => {
  store.setState({stammdaten});
};

export const setUebersetzungen = (store, uebersetzungen) => {
  store.setState({i18n: uebersetzungen})
};

export const createComment = (store, values) => {
  apiCreateComment(store, values, (resultat) => {
    const kommentare = store.state.kommentare;
    kommentare.push({
      ...values,
      id: resultat.spiel_analyse_video_tagging.id,
      teamName: resultat.spiel_analyse_video_tagging.teamName,
      spielerName: resultat.spiel_analyse_video_tagging.spielerName,
      trainerName: resultat.spiel_analyse_video_tagging.trainerName,
      schiedsrichterName: resultat.spiel_analyse_video_tagging.schiedsrichterName,
    });
    store.setState({kommentare});
  });
};

export const invalidateComment = (store, values) => {
  const kommentare = store.state.kommentare;
  const index = kommentare.findIndex(c => c.id === values.id);
  kommentare.splice(index, 1);
  kommentare.push({
    ...values,
    bearbeitet: true,
  });
};

export const updateComment = (store, values) => {
  apiUpdateComment(store, values, (resultat) => {
    const kommentare = store.state.kommentare;
    const index = kommentare.findIndex(c => c.id === values.id);
    kommentare.splice(index, 1);
    kommentare.push({
      ...values,
      teamName: resultat.spiel_analyse_video_tagging.teamName,
      spielerName: resultat.spiel_analyse_video_tagging.spielerName,
      trainerName: resultat.spiel_analyse_video_tagging.trainerName,
      schiedsrichterName: resultat.spiel_analyse_video_tagging.schiedsrichterName,
    });
    store.setState({kommentare});
  });
};

export const deleteComment = (store, values) => {
  apiDeleteComment(store, values, (resultat) => {
    const kommentare = store.state.kommentare;
    const index = kommentare.findIndex(c => c.id === values.id);
    kommentare.splice(index, 1);
    store.setState({kommentare});
  });
};

export const setKommentare = (store, kommentare) => {
  store.setState({kommentare});
};

export const setTore = (store, tore) => {
  store.setState({tore});
};

export const setOverlaySequence = (store, overlaySequence) => {
  store.setState({overlaySequence});

  if (overlaySequence) {
    const video = document.getElementById("overlay-video");
    if (video) {
      video.load();
      video.play().catch(() => {
      });
    }
  }
};

export const initAppState = (store) => {
  store.setState({
    appState: {
      runningRequests: 0,
      aktuelleFehler: [],
    }
  });
};

export const addRunningRequest = (store) => {
  store.setState({
    appState: {
      ...store.state.appState,
      runningRequests: store.state.appState.runningRequests + 1,
    }
  });
};

export const removeRunningRequest = (store) => {
  store.setState({
    appState: {
      ...store.state.appState,
      runningRequests: Math.min(store.state.appState.runningRequests - 1, 0),
    }
  });
};

function unique(arr) {
  // kopiert von https://stackoverflow.com/questions/11688692/how-to-create-a-list-of-unique-items-in-javascript
  let u = {}, a = [];
  for(let i = 0, l = arr.length; i < l; ++i){
    if(!u.hasOwnProperty(arr[i])) {
      a.push(arr[i]);
      u[arr[i]] = 1;
    }
  }
  return a;
}

export const fehlerHinzufuegen = (store, fehler) => {
  let fehlerListe = store.state.appState.aktuelleFehler;
  fehlerListe.push(fehler.toString());
  fehlerListe = unique(fehlerListe);
  store.setState({
    appState: {
      ...store.state.appState,
      aktuelleFehler: fehlerListe,
    }
  });
};

export const fehlerEntfernen = (store, fehler) => {
  let fehlerListe = store.state.appState.aktuelleFehler;
  for (let i = 0; i < fehlerListe.length; i++) {
    if (fehlerListe[i] === fehler) {
      fehlerListe.splice(i, 1);
    }
  }
  store.setState({
    appState: {
      ...store.state.appState,
      aktuelleFehler: fehlerListe,
    }
  });
};

export const alleFehlerEntfernen = (store) => {
  store.setState({
    appState: {
      ...store.state.appState,
      aktuelleFehler: [],
    }
  });
};
