
export function berechtigungFuer(rechnungslaufTyp, feldname) {
  const berechtigungFuerTyp = BERECHTIGUNGEN_NACH_TYP[rechnungslaufTyp];
  if (berechtigungFuerTyp && berechtigungFuerTyp[feldname]) {
    return berechtigungFuerTyp[feldname];
  } else {
    return false;
  }
}

const BERECHTIGUNGEN_NACH_TYP = {
  rechnungslauf_konfiguration: {
    person: {
      create: true,
      update: false
    },
    leistung: {
      create: true,
      update: true
    },
    betrag: {
      create: true,
      update: true
    },
    betragFreiwillig: {
      create: true,
      update: true
    },
    team: true,
    kommentar: false,
    neueRechnung: true,
    spielerSperren: true
  },
  rechnungen_verbaende: {
    person: {
      create: true,
      update: true
    },
    leistung: {
      create: true,
      update: true
    },
    betrag: {
      create: true,
      update: true
    },
    betragFreiwillig: false,
    team: true,
    kommentar: true,
    neueRechnung: true,
    spielerSperren: true
  },
  sponsoren: {
    person: {
      create: true,
      update: false
    },
    leistung: {
      create: true,
      update: true
    },
    betrag: {
      create: true,
      update: true
    },
    betragFreiwillig: false,
    team: false,
    kommentar: false,
    neueRechnung: true,
    spielerSperren: false
  },
  sponsorenlauf_spieler: {
    person: {
      create: false,
      update: false
    },
    leistung: {
      create: true,
      update: true
    },
    betrag: {
      create: true,
      update: true
    },
    betragFreiwillig: true,
    team: true,
    kommentar: false,
    neueRechnung: false,
    spielerSperren: true
  },
  sponsorenlauf_sponsor: {
    person: {
      create: false,
      update: false
    },
    leistung: {
      create: true,
      update: true
    },
    betrag: {
      create: true,
      update: true
    },
    betragFreiwillig: false,
    team: true,
    kommentar: false,
    neueRechnung: false,
    spielerSperren: false
  },
  spielerlizenzen: {
    person: {
      create: true,
      update: false
    },
    leistung: {
      create: true,
      update: true
    },
    betrag: {
      create: true,
      update: true
    },
    betragFreiwillig: {
      create: true,
      update: true
    },
    team: true,
    kommentar: false,
    neueRechnung: true,
    spielerSperren: true
  },
};
