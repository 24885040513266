const RANGLISTE_SETZEN = 'RANGLISTE_SETZEN';

export function ranglisteSetzen(rangliste) {
  return (dispatch) => {
    dispatch({type: RANGLISTE_SETZEN, rangliste: rangliste });
  }
}

function ranglistenReducer(state = [], action) {
  switch (action.type) {
    case RANGLISTE_SETZEN:
      return [
        ...state.filter(r => r.id !== action.rangliste.id),
        action.rangliste
      ];
    default:
      return state;
  }
}

export default ranglistenReducer;
