export const ViewKontext = {
  Kursleiter: 'kursleiter',
  Klassenlehrer: 'klassenlehrer',
  Teilnehmer: 'teilnehmer'
};

export const ItemTypes = {
  Aufgabe: 'aufgabe',
  Ausbildner: 'ausbildner',
};

export const TeamStatus = {
  TeamZugeteilt: 10,
  NormalerTeilnehmer: 20,
  ZurueckhaltenderTeilnehmer: 30,
  SprachlicheBarriere: 40,
  TeamLeader: 50
};

export const ChatStatus = {
  Normal: 10,
};

export const AusbildungStatus = {
  Angemeldet: 10,
  Abgemeldet: 11,
  Bestanden: 12,
  Teilbesuch: 13,
  Erfuellt: 15,
  Anwesend: 16,
};

export const AufgabenFilter = {
  offen: 1,
  abgelaufen: 2,
  abgeschlossenTeilnehmer: 3,
  bewertungOffen: 4
};

export const EventFarben = [
  '#3788d8', '#dc5e70', '#3bd87f', '#d8a73c',
  '#9b7ad8', '#d86103', '#00d800', '#d80000',
  '#d8aecd', '#6400d8'
];

export const DokumentArt = {
  linkMitInhalt: 34
};
