import React, {useRef} from "react";
import {connect} from "react-redux";
import {useDrag, useDrop} from "react-dnd";
import ConfirmButton from "../../../../components/ConfirmButton";

const TextItem = ({vorlageText, index, i18n, onEditText, onDeleteText, sortierungChange, sortierungSave}) => {
  const texte = i18n.texte;
  const dropRef = useRef(null);
  const dragRef = useRef(null);

  const [, drop] = useDrop({
    accept: 'text',
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      
      // Time to actually perform the action
      sortierungChange(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop: () => {
      sortierungSave();
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'text', id: vorlageText.spracheId, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  
  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <tr ref={dropRef} style={{ opacity }}>
      <td ref={dragRef}>
        <button className="btn">
          <i className="icon-resize-vertical" />
        </button>
      </td>
      <td>{i18n.sprachen.find(s => s.id === vorlageText.spracheId).bezeichnung}</td>
      <td className="umbrechen">{vorlageText.header}</td>
      <td className="umbrechen">{vorlageText.footer}</td>
      <td className="umbrechen">{vorlageText.freiwilligZusatz}</td>
      <td className="umbrechen">{vorlageText.freiwilligHeader}</td>
      <td className="umbrechen">{vorlageText.freiwilligFooter}</td>
      <td className="button-liste" style={{flexWrap: 'wrap'}}>
        <button className="btn" onClick={() => onEditText(vorlageText)}><i className="icon-edit"/></button>
        <ConfirmButton className="btn" onConfirmed={() => onDeleteText(vorlageText)} confirmText={texte.wirklichLoeschen} okText={texte.ok} cancelText={texte.abbrechen}>
          <i className="icon-trash"/>
        </ConfirmButton>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TextItem);
