const TEAMS_LADEN = 'TEAMS_LADEN';

export function teamsLaden(teams) {
  return (dispatch) => {
    dispatch({type: TEAMS_LADEN, teams: teams});
  }
}

function teamsReducer(state = [], action) {
  switch (action.type) {
    case TEAMS_LADEN:
      const ids = action.teams.map(t => t.id);
      return state
        .filter(r => !ids.includes(r.id))
        .concat(action.teams);
    default:
      return state;
  }
}

export default teamsReducer;
