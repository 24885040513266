// @flow

import type {
  AppState,
  SpielerEintrag,
  SpielerkarteEintrag,
} from "../../../state/stateTypes";
import type { Dispatch } from "../../../state/actionTypes";

import React from "react";
import { connect } from "react-redux";

import Textfeld from "../../Textfeld";
import { prognoseWertBerechnen } from "../../../../../lib/prognoseFragen";
import SpielerPrognoseFragen from "./SpielerPrognoseFragen";
import { spielerPrognoseEinAusblenden } from "../../../state/modules/spieler";
import Tooltip from "../../../../../components/Tooltip";
import SpielerBadge from "../../SpielerBadge";
import SpielerReadonlyTips from "./SpielerReadonlyTips";

const SpielerkarteSpielerReadonly = ({ i18n, spielerPrognoseEinAusblenden, spieler, spielerEinsatz }: Props) => {
  const prognoseFragen = spieler.prognoseFragen;
  const prognoseWert = !prognoseFragen ? 0 : prognoseFragen.prognose || [1, 2, 3, 4, 5, 6].filter(frageNr => prognoseFragen[`frage${frageNr}`].selektiert == true).length;
  const darstellung = prognoseWertBerechnen(prognoseWert);

  const spielerRow =
    <tr>
      <td style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }}>
        <span className="label label-info">{spieler.passnummer}</span>
        {spielerEinsatz.spielfuehrer &&
          <span className="label label-important spielfuehrer">C</span>
        }
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <SpielerBadge spieler={spieler}/>
      </td>
      <td>
        <div className="spielposition" style={{ width: '110px', verticalAlign: 'middle' }}>
          {spielerEinsatz.spielposition.name}
        </div>
      </td>
      <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
        {spielerEinsatz.rueckennummer}
      </td>
      <SpielerReadonlyTips tips={spieler.tips}/>
      <td style={{ verticalAlign: 'middle' }}>
        <Tooltip content={i18n.prognoseFragen.titel}>
        <span className={`leistung fakeInput ${darstellung.farbe}`}
              onClick={() => spielerPrognoseEinAusblenden(spieler.id)}>
          {darstellung.buchstabe}
        </span>
        </Tooltip>
      </td>
      <td colSpan={2}>&nbsp;</td>
    </tr>

  const kommentarAnzeigen = (spieler.tips.kommentar || '').length > 0;
  const prognoseAnzeigen = prognoseFragen && prognoseFragen.prognoseAufgeklappt;

  return (
    <tbody>
    {spielerRow}
    {prognoseAnzeigen &&
      <tr>
        <td colSpan="14" className="text-right">
          <div style={{ display: 'inline-block' }}>
            <SpielerPrognoseFragen spieler={spieler} readonly={true}/>
          </div>
        </td>
      </tr>
    }
    {kommentarAnzeigen &&
      <tr>
        <td colSpan="14" className="text-right">
          <Textfeld
            feldName="tipsKommentar"
            feldWert={spieler.tips.kommentar || ''}
            requestDaten={{}}
            breite='388px'
            forceReadonly={true}
          />
        </td>
      </tr>
    }
    </tbody>
  );
};

type Props = {
  i18n: any,
  spielerPrognoseEinAusblenden: (number) => void,
  spieler: SpielerEintrag,
  spielerEinsatz: SpielerkarteEintrag,
};

const mapStateToProps = (state: AppState) => {
  return {
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    spielerPrognoseEinAusblenden: (spielerId: number) => {
      dispatch(spielerPrognoseEinAusblenden(spielerId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpielerkarteSpielerReadonly);
