import fetch from "cross-fetch";

export async function executeRequest(url, token, method, daten, options = {}) {
  const fetchInit = {
    ...options,
    method: method,
    mode: 'same-origin',
    credentials: 'same-origin'
  };

  let apiUrl = url;
  if (method === 'GET') {
    if (daten) {
      const params = new URLSearchParams();
      appendObjectToRequest(params, daten);
      apiUrl = url + "?" + params.toString()
      fetchInit.headers = { 'Content-Type': 'application/json'};
    }
  } else {
    const form = new FormData();
    appendObjectToRequest(form, daten);
    form.append('authenticity_token', token);
    fetchInit.body = form;
  }

  return fetch(apiUrl, fetchInit)
    .then(response => {
      return response.json().then(jsonData => ({data: jsonData, ok: response.ok, status: response.status}));
    });
}

function appendObjectToRequest(formData, daten, prefix = '') {
  for (let propName in daten) {
    if (daten.hasOwnProperty(propName)) {
      const val = daten[propName];
      if (val instanceof Array) {
        fillArray(formData, val, `${prefix}${propName}`);
      } else if (val instanceof File) { // fällt sonst unter "Object"
        formData.append(`${prefix}${propName}`, val);
      } else if (val instanceof Date) { // fällt sonst unter "Object"
        formData.append(`${prefix}${propName}`, JSON.stringify(val));
      } else if (val instanceof Object) {
        appendObjectToRequest(formData, val, `${prefix}[${propName}]`);
      } else {
        formData.append(`${prefix}${propName}`, val);
      }
    }
  }
}

function fillArray(form, array, prefix = '') {
  array.forEach((element, _index) => {
    if (element instanceof Array || element instanceof Date || (element instanceof Object && element instanceof File === false)) {
      appendObjectToRequest(form, element, `${prefix}[]`);
    } else {
      form.append(`${prefix}[]`, element);
    }
  });
}
