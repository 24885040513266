
export function formatiereDatum(wert) {
  const datum = konvertiereAlsDatum(wert);
  if (datum) {
    return datum.toString('dd.MM.yyyy');
  }
  return '';
}
export function formatiereDatumZeit(wert) {
  const datum = konvertiereAlsDatum(wert);
  if (datum) {
    if (datum.getMinutes() === 0 && datum.getHours() === 0) {
      return formatiereDatum(datum);
    } else {
      return datum.toString('dd.MM.yyyy HH:mm');
    }
  }
  return '';
}
export function formatiereZeit(wert) {
  const datum = konvertiereAlsDatum(wert);
  if (datum) {
    return datum.toString('HH:mm');
  }
  return '';
}

function konvertiereAlsDatum(datum) {
  if (datum) {
    if (datum instanceof Date) {
      return datum;
    }
    try { return new Date(datum); }
    catch {}
  }
  return undefined;
}

const numberFormat = {
  decimal: new Intl.NumberFormat('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
  de: new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }),
  fr: new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }),
  it: new Intl.NumberFormat('it-CH', { style: 'currency', currency: 'CHF' })
};
export function formatiereBetrag(betrag, locale = 'decimal') {
  if (betrag || betrag == 0) {
    let betragFormatiert = betrag;
    if (betrag instanceof String || typeof betrag === 'string') {
      betragFormatiert = Number.parseFloat(betrag);
    }
    if (isNaN(betragFormatiert)) {
      return betrag; // parsing nok -> original input zurückgeben
    }
    betragFormatiert = numberFormat.decimal.format(betragFormatiert);

    if (locale == 'decimal') {
      // nur zahl zurückgeben
      return betragFormatiert;
    }
    return 'CHF ' + betragFormatiert;
  }
  return betrag;
}
