export const liveSupportAnzeige = (istLive) => {
  if (istLive) {
    document.querySelectorAll('.live-support-on').forEach(node => node.classList.remove('hide'));
    document.querySelectorAll('.live-support-off').forEach(node => node.classList.add('hide'));
  } else {
    document.querySelectorAll('.live-support-on').forEach(node => node.classList.add('hide'));
    document.querySelectorAll('.live-support-off').forEach(node => node.classList.remove('hide'));
  }
};

export const sortiereLobby = (a, b) => a.name.localeCompare(b.name);
