export function initializeContent(globalState, globalActions, props) {
  if (globalState.spielInfos === null && props.spielInfos !== null) {
    globalActions.setSpielInfos(props.spielInfos)
  }
  if (globalState.videos.length === 0 && (props.videos && props.videos.length !== 0)) {
    globalActions.setVideos(props.videos);
  }
  if (globalState.ereignisse.length === 0 && (props.ereignisse && props.ereignisse.length !== 0)) {
    globalActions.setEreignisse(props.ereignisse);
  }
  if (globalState.kommentare.length === 0 && (props.kommentare && props.kommentare.length !== 0)) {
    globalActions.setKommentare(props.kommentare);
  }
  if (globalState.tore.length === 0 && (props.tore && props.tore.length !== 0)) {
    globalActions.setTore(props.tore);
  }
  if (isEmpty(globalState.autorisierung) && (props.autorisierung && !isEmpty(props.autorisierung))) {
    globalActions.setAutorisierung(props.autorisierung);
  }
  if (isEmpty(globalState.stammdaten) && (props.stammdaten && !isEmpty(props.stammdaten))) {
    globalActions.setStammdaten(props.stammdaten);
  }
  if (isEmpty(globalState.i18n) && (props.i18n && !isEmpty(props.i18n))) {
    globalActions.setUebersetzungen(props.i18n);
  }
  if (isEmpty(globalState.appState)) {
    globalActions.initAppState();
  }
}

function isEmpty(obj) {
  for (var x in obj) {
    return false;
  }
  return true;
}

export function getVideo(globalState, videoID) {
  if (!videoID) return;
  return globalState.videos.find(v => v.id === videoID);
}

export function sortiereTickerEintraege(items) {
  return items.sort((a, b) => {
    if (a.minute !== b.minute) return a.minute < b.minute ? -1 : 1;
    if (a.zusatzminute !== b.zusatzminute) return a.zusatzminute < b.zusatzminute ? -1 : 1;
    if (a.id !== b.id) return a.id < b.id ? -1 : 1;
    return a.titel < b.titel ? -1 : 1;
  });
}

export function spielstandUndZeitBei(currentTime, video, periodenInfos, tore) {
  // currentTime sind Sekunden ab HTML5-Video
  const relevanteAenderungen = (video.aenderungen || []).filter(
    aenderung => aenderung.video_zeit <= currentTime
  );
  const letzteAenderung = relevanteAenderungen.slice(-1)[0] || {};
  const relevanteTore = (tore || []).filter(
    tor => tor.video < video.id || (tor.video === video.id && tor.start <= currentTime)
  );
  const letztesTor = relevanteTore.slice(-1)[0] || {};
  let aktuellePeriode = { // Struktur wie periodenInfos, alles in Minuten
    name: "unbekannte_periode",
    i18nName: document.getElementById("unbekannte-periode").value,
    start: 0,
    dauer: 0,
    ende: 0,
  };
  periodenInfos.forEach((periodenInfo) => {
    if (letzteAenderung.spiel_abschnitt === periodenInfo.name) {
      aktuellePeriode = periodenInfo;
    }
  });

  let sekunden = 0;
  let zusatzsekunden = 0;
  let roheSekunden = (parseInt(letzteAenderung.spiel_zeit) + parseInt(letzteAenderung.zusatz_zeit)) + (currentTime - parseInt(letzteAenderung.video_zeit));
  if (roheSekunden > (aktuellePeriode.ende * 60)) {
    sekunden = aktuellePeriode.ende * 60;
    zusatzsekunden = roheSekunden - (aktuellePeriode.ende * 60)
  } else {
    sekunden = roheSekunden;
  }

  return {
    periode: aktuellePeriode.name,
    periodeName: aktuellePeriode.i18nName,
    zeitAnzeigen: aktuellePeriode.name !== 'penaltyschiessen', // TODO magic string raus?
    spielstand: [
      letztesTor.spielstandHeim || 0,
      letztesTor.spielstandGast || 0,
    ].join(":"),
    sekunden: Math.floor(sekunden),
    zusatzsekunden: Math.floor(zusatzsekunden),
    minute: Math.ceil(sekunden / 60),
    zusatzminute: Math.ceil(zusatzsekunden / 60),
  };
}

export function videoUndZeitBei(globalState, minute, zusatzminute) {
  // currentTime sind Sekunden ab HTML5-Video
  let videoLetzteAenderung = null;
  let letzteAenderung = null;
  globalState.videos.forEach((video) => {
    const relevanteAenderungen = (video.aenderungen || []).filter(
      aenderung => aenderung.spiel_zeit <= (minute * 60) && aenderung.zusatz_zeit <= (zusatzminute * 60)
    );
    if (relevanteAenderungen.length) {
      let letzteAenderungImVideo = relevanteAenderungen.slice(-1)[0] || {};
      if (!letzteAenderung ||
        (letzteAenderungImVideo.spiel_zeit > letzteAenderung.spiel_zeit) ||
        (letzteAenderungImVideo.spiel_zeit === letzteAenderung.spiel_zeit && letzteAenderungImVideo.zusatz_zeit >= letzteAenderung.zusatz_zeit)) {
        videoLetzteAenderung = video;
        letzteAenderung = letzteAenderungImVideo;
      }
    }
  });
  if (videoLetzteAenderung && letzteAenderung) {
    let start = letzteAenderung.video_zeit +
      ((minute - 1) * 60 - letzteAenderung.spiel_zeit) +
      (zusatzminute * 60 - letzteAenderung.zusatz_zeit);
    if (start < 0) {
      start = 0;
    }
    return {
      video: videoLetzteAenderung.id,
      start: start,
    }
  } else {
    return {
      video: null,
      start: null,
    }
  }
}
