// @flow

import type { AppState, SpielerEintrag, SpielerkarteEintrag } from "../../../state/stateTypes";
import type { Dispatch } from "../../../state/actionTypes";

import React from "react";
import { connect } from "react-redux";
import SpielerBadge from "../../SpielerBadge";

const SpielerkarteSpielerOhneDaten = ({ spieler, spielerEinsatz }: Props) => {
  return (
    <tbody>
    <tr>
      <td style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }}>
        <span className="label label-info">{spieler.passnummer}</span>
        {spielerEinsatz.spielfuehrer &&
          <span className="label label-important spielfuehrer">C</span>
        }
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <SpielerBadge spieler={spieler}/>
      </td>
      <td>
        <div className="spielposition" style={{ width: '110px', verticalAlign: 'middle' }}>
          {spielerEinsatz.spielposition.name}
        </div>
      </td>
      <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
        {spielerEinsatz.rueckennummer}
      </td>
      <td colSpan={10} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
        &mdash;
      </td>
    </tr>
    </tbody>
  );
};

type Props = {
  spieler: SpielerEintrag,
  spielerEinsatz: SpielerkarteEintrag,
}

const mapStateToProps = (state: AppState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpielerkarteSpielerOhneDaten);
