import React from 'react';
import {connect} from "react-redux";
import TeilnehmerAufgabenZuteilen from "./TeilnehmerAufgabenZuteilen";
import TeilnehmerAufgaben from "./TeilnehmerAufgaben";
import PersonIcon from "../../../../components/PersonIcon";
import {AufgabenModus} from "./AufgabeZuteilung";

const TeamTeilnehmer = ({teilnehmer, aufgabenModus, aufgabenFilter, texte}) => {
  return (
    <div className="block">
      <div className="header">
        <div className="spalte">
          {`${texte.teilnehmer}: ${teilnehmer.ausbildner.name}`}
          <PersonIcon person={teilnehmer.ausbildner}/>
        </div>
      </div>
      {aufgabenModus === AufgabenModus.zuteilen &&
      <TeilnehmerAufgabenZuteilen teilnehmer={teilnehmer} aufgabenModus={aufgabenModus}/>
      }
      {aufgabenModus === AufgabenModus.abschliessen &&
      <TeilnehmerAufgaben teilnehmer={teilnehmer} aufgabenModus={aufgabenModus} aufgabenFilter={aufgabenFilter}/>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamTeilnehmer);
