// @flow

import type {ProtokollState, ProtokollEintrag, TrainingState} from "../stateTypes";
import type {Action, ActionThunk, GetState, Dispatch} from "../actionTypes";

import {spielstandAusProtokollHolen} from "./spiel";
import {apiRequestAbschicken, mitTimeout} from "./ajax";
import {protokollDatenLaden} from "../datenLaden";

// Helpers für Action Generators

const eintragMitKey = (getState: GetState, eintragKey: string) => {
  let gefundenerEintrag = {};
  const protokoll = getState().protokoll;
  if (protokoll !== null) {
    protokoll.liste.forEach((eintrag) => {
      if (eintrag.key === eintragKey) {
        gefundenerEintrag = eintrag;
      }
    });
  }
  return gefundenerEintrag;
};

// Action Generators

export function protokollNeuenEintragErstellen(position: "oben" | "unten", art: string, aktion: string, istTor: boolean): ActionThunk {
  return (dispatch: Dispatch, getState: GetState) => {
    const protokoll = getState().protokoll;
    if (protokoll !== null) {
      const neuIndex = protokoll.neuIndex;
      dispatch({type: "PROTOKOLL_NEUEN_EINTRAG_ERSTELLEN", position, art, aktion});
      if (istTor) {
        dispatch({type: "PROTOKOLL_RESULTATE_AKTUALISIEREN", auchOhneTore: false});
        dispatch(spielstandAusProtokollHolen());
      }
      const apiUrl = getState().pfade.api.protokoll;
      dispatch(apiRequestAbschicken(apiUrl, "POST", "id", null, {art: art, aktion: aktion}, (dispatch, resultat) => {
        dispatch({type: "PROTOKOLL_WERT_SETZEN_ID", eintragKey: "neu_" + neuIndex, wert: (resultat.id: number)})
      }));
    }
  };
}

export function protokollEintragLoeschen(eintragKey: string, istTor: boolean): ActionThunk {
  return (dispatch: Dispatch, getState: GetState) => {
    const eintrag = eintragMitKey(getState, eintragKey);
    dispatch({type: "PROTOKOLL_EINTRAG_LOESCHEN", eintragKey});
    if (istTor) {
      dispatch({type: "PROTOKOLL_RESULTATE_AKTUALISIEREN", auchOhneTore: true});
      dispatch(spielstandAusProtokollHolen())
    }
    const apiUrl = getState().pfade.api.protokoll;
    dispatch(apiRequestAbschicken(apiUrl + "/" + eintrag.id, "DELETE", null, null, null, null));
  };
}

export function protokollWertSetzenMinute(eintragKey: string, wert: number | null, istTor: boolean): ActionThunk {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({type: "PROTOKOLL_WERT_SETZEN_MINUTE", eintragKey, wert});
    if (istTor) {
      dispatch({type: "PROTOKOLL_RESULTATE_AKTUALISIEREN", auchOhneTore: false});
    }
    mitTimeout(eintragKey + "minute", () => {
      const eintrag = eintragMitKey(getState, eintragKey);
      const apiUrl = getState().pfade.api.protokoll;
      dispatch(apiRequestAbschicken(apiUrl + "/" + eintrag.id, "PATCH", "minute", wert, eintrag, null));
    });
  };
}

export function protokollWertSetzenZusatzminute(eintragKey: string, wert: number, istTor: boolean): ActionThunk {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({type: "PROTOKOLL_WERT_SETZEN_ZUSATZMINUTE", eintragKey, wert});
    if (istTor) {
      dispatch({type: "PROTOKOLL_RESULTATE_AKTUALISIEREN", auchOhneTore: false});
    }
    mitTimeout(eintragKey + "zusatzminute", () => {
      const eintrag = eintragMitKey(getState, eintragKey);
      const apiUrl = getState().pfade.api.protokoll;
      dispatch(apiRequestAbschicken(apiUrl + "/" + eintrag.id, "PATCH", "zusatzminute", wert, eintrag, null));
    });
  };
}

export function protokollWertSetzenToreHeim(eintragKey: string, wert: number, istTor: boolean): ActionThunk {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({type: "PROTOKOLL_WERT_SETZEN_TORE_HEIM", eintragKey, wert});
    if (istTor) {
      dispatch({type: "PROTOKOLL_RESULTATE_AKTUALISIEREN", auchOhneTore: true});
    }
    mitTimeout(eintragKey + "tore_heim", () => {
      const eintrag = eintragMitKey(getState, eintragKey);
      const apiUrl = getState().pfade.api.protokoll;
      dispatch(apiRequestAbschicken(apiUrl + "/" + eintrag.id, "PATCH", "toreHeim", wert, eintrag, null));
    });
  };
}

export function protokollWertSetzenToreGast(eintragKey: string, wert: number, istTor: boolean): ActionThunk {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({type: "PROTOKOLL_WERT_SETZEN_TORE_GAST", eintragKey, wert});
    if (istTor) {
      dispatch({type: "PROTOKOLL_RESULTATE_AKTUALISIEREN", auchOhneTore: true});
    }
    mitTimeout(eintragKey + "tore_gast", () => {
      const eintrag = eintragMitKey(getState, eintragKey);
      const apiUrl = getState().pfade.api.protokoll;
      dispatch(apiRequestAbschicken(apiUrl + "/" + eintrag.id, "PATCH", "toreGast", wert, eintrag, null));
    });
  };
}

export function protokollSortieren(eintragKey: string, istTor: boolean): ActionThunk {
  return (dispatch: Dispatch) => {
    dispatch({type: "PROTOKOLL_SORTIERUNG_AKTUALISIEREN", highlightKey: eintragKey});
    if (istTor) {
      dispatch({type: "PROTOKOLL_RESULTATE_AKTUALISIEREN", auchOhneTore: false});
    }
  };
}

export function protokollWertSetzenTeam(eintragKey: string, heimTeam: boolean, gastTeam: boolean, istTor: boolean): ActionThunk {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({type: "PROTOKOLL_WERT_SETZEN_TEAM", eintragKey, heimTeam, gastTeam});
    if (istTor) {
      dispatch({type: "PROTOKOLL_RESULTATE_AKTUALISIEREN", auchOhneTore: false});
      dispatch(spielstandAusProtokollHolen())
    }
    mitTimeout(eintragKey + "team_id", () => {
      const eintrag = eintragMitKey(getState, eintragKey);
      const apiUrl = getState().pfade.api.protokoll;
      const spiel = getState().spiel;
      if (spiel !== null) {
        const teamId = heimTeam ? spiel.heimTeamId : (gastTeam ? spiel.gastTeamId : null);
        dispatch(apiRequestAbschicken(apiUrl + "/" + eintrag.id, "PATCH", "team_id", teamId, eintrag, null));
      }
    });
  };
}

export function protokollWertSetzenSpielerId(eintragKey: string, wert: number | null, istTor: boolean): ActionThunk {
  return (dispatch: Dispatch, getState: GetState) => {
    const spiel = getState().spiel;
    if (spiel !== null) {
      let spielerkarteHeimTeam = spiel.spielerkarten.heimTeam;
      let spielerkarteGastTeam = spiel.spielerkarten.gastTeam;
      dispatch({type: "PROTOKOLL_WERT_SETZEN_SPIELER_ID", eintragKey, wert, spielerkarteHeimTeam, spielerkarteGastTeam});
      if (istTor) {
        dispatch({type: "PROTOKOLL_RESULTATE_AKTUALISIEREN", auchOhneTore: false});
        dispatch(spielstandAusProtokollHolen())
      }
      mitTimeout(eintragKey + "spieler_id", () => {
        const apiUrl = getState().pfade.api.protokoll;
        const eintrag = eintragMitKey(getState, eintragKey);
        dispatch(apiRequestAbschicken(apiUrl + "/" + eintrag.id, "PATCH", "spieler_id", wert, eintrag, null));
      });
    }
  };
}

export function protokollWertSetzenBemerkung(eintragKey: string, wert: string | null): ActionThunk {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({type: "PROTOKOLL_WERT_SETZEN_BEMERKUNG", eintragKey, wert});
    mitTimeout(eintragKey + "bemerkung", () => {
      const apiUrl = getState().pfade.api.protokoll;
      const eintrag = eintragMitKey(getState, eintragKey);
      dispatch(apiRequestAbschicken(apiUrl + "/" + eintrag.id, "PATCH", "bemerkung", wert, eintrag, null));
    });
  };
}

export function protokollVideoAnzeigen(eintragKey: string): ActionThunk {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({type: "PROTOKOLL_VIDEO_ANZEIGEN", eintragKey});
    setTimeout(() => {
      Array.from(document.getElementsByClassName("overlay-video")).forEach((video) => {
        if (video.play && video.offsetParent !== null) {
          (video: any).play();
        }
      });
    }, 500)
  };
}

export function protokollVideoVerstecken(eintragKey: string): ActionThunk {
  return (dispatch: Dispatch, getState: GetState) => {
    Array.from(document.getElementsByClassName("overlay-video")).forEach((video) => {
      if (video.pause) {
        (video: any).pause();
      }
    });
    dispatch({type: "PROTOKOLL_VIDEO_VERSTECKEN", eintragKey});
  };
}

// Helpers für Reducer

const eintragErstellen = (position: "oben" | "unten", liste: ProtokollEintrag[], art: string, aktion: string, neuIndex: number) => {
  const neuerEintrag: ProtokollEintrag = {
    key: "neu_" + neuIndex,
    id: null,
    art: art,
    aktion: aktion,
    minute: null,
    zusatzminute: 0,
    heimTeam: false,
    gastTeam: false,
    spielerId: null,
    spielerUnregistriert: null,
    auswechselSpielerId: null,
    auswechselSpielerUnregistriert: null,
    toreHeim: null,
    toreGast: null,
    bemerkung: null,
    strafgrundId: null,
    bemerkungId: null,
    unfallartId: null,
    highlight: false,
    hatVideo: false,
    videoPfad: null,
    videoAnzeigen: false,
    nurLesen: false,
    torBearbeitbar: protokollHatSpielverlaufTore(liste),
    neuPosition: position,
  };
  if (position === "oben") {
    let obenIndex = 0;
    liste.forEach((listeEintrag) => {
      if (listeEintrag.neuPosition === "oben") {
        obenIndex += 1;
      }
    });
    liste.splice(obenIndex, 0, neuerEintrag);
  } else {
    liste.push(neuerEintrag);
  }
  return liste;
};
const eintragLoeschen = (liste: ProtokollEintrag[], eintragKey: string) => {
  liste = liste.reduce<ProtokollEintrag[]>((neueListe: ProtokollEintrag[], eintrag: ProtokollEintrag) => {
    if (eintrag.key !== eintragKey) {
      neueListe.push(eintrag);
    }
    return neueListe;
  }, ([]: ProtokollEintrag[]));
  return liste;
};
const eintragWertAktualisieren = (liste: ProtokollEintrag[], eintragKey: string, wertName: string, neuerWert: string | number | boolean | null, istZahl: boolean, defaultWert: string | number | null) => {
  liste = liste.map<ProtokollEintrag>((eintrag) => {
    if (eintrag.key === eintragKey) {
      let neuerEintrag = {...eintrag};
      neuerEintrag[wertName] = istZahl ? (parseInt(neuerWert, 10) || defaultWert) : neuerWert;
      if (wertName === "minute" || wertName === "zusatzminute") {
        neuerEintrag.neuPosition = null;
      }
      return neuerEintrag;
    } else {
      return eintrag;
    }
  });
  return liste;
};
const eintragTeamAktualisieren = (liste, eintragKey, heimTeam, gastTeam) => {
  liste = liste.map<ProtokollEintrag>((eintrag) => {
    if (eintrag.key === eintragKey) {
      return {
        ...eintrag,
        heimTeam: heimTeam,
        gastTeam: gastTeam,
      };
    } else {
      return eintrag;
    }
  });
  return liste;
};
const spielerInSpielerkarte = (spielerId, spielerkarte) => {
  let inStartformation = spielerkarte.startformation.some((einsatz) => {
    return einsatz.spielerId === spielerId;
  });
  let inErsatzformation = spielerkarte.ersatzformation.some((einsatz) => {
    return einsatz.spielerId === spielerId;
  });
  return inStartformation || inErsatzformation;
};
const eintragSpielerIdAktualisieren = (liste, eintragKey, wertName, neuerWert, spielerkarteHeimTeam, spielerkarteGastTeam) => {
  liste = liste.map<ProtokollEintrag>((eintrag) => {
    if (eintrag.key === eintragKey) {
      let spielerId = parseInt(neuerWert, 10) || null;
      let heimTeam = eintrag.heimTeam;
      let gastTeam = eintrag.gastTeam;
      if (spielerId !== null && (heimTeam && gastTeam) || (!heimTeam && !gastTeam)) {
        heimTeam = spielerInSpielerkarte(spielerId, spielerkarteHeimTeam);
        gastTeam = spielerInSpielerkarte(spielerId, spielerkarteGastTeam);
      }
      let neuerEintrag = {
        ...eintrag,
        heimTeam: heimTeam,
        gastTeam: gastTeam,
      };
      neuerEintrag[wertName] = spielerId;
      return neuerEintrag;
    } else {
      return eintrag;
    }
  });
  return liste;
};
// QuickSort nicht in JS integriert, siehe
// https://medium.com/@Charles_Stover/implementing-quicksort-in-javascript-8044a8e2bf39
const vergleichMitNull = (a: string | number | null, b: string | number | null, istZahl: boolean) => {
  if (a === null) {
    if (b === null) {
      return 0;
    } else {
      return 1;
    }
  } else {
    if (b === null) {
      return -1;
    } else {
      if (istZahl) {
        if (parseInt(a, 10) < parseInt(b, 10)) {
          return -1;
        } else if (parseInt(a, 10) > parseInt(b, 10)) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (a.toString() < b.toString()) {
          return -1;
        } else if (a.toString() > b.toString()) {
          return 1;
        } else {
          return 0;
        }
      }
    }
  }
};
const eintragComparator = (a: ProtokollEintrag, b: ProtokollEintrag) => {
  const neuPositionVergleich = vergleichMitNull(
    a.neuPosition === "oben" ? -1 : a.neuPosition === "unten" ? 1 : 0,
    b.neuPosition === "oben" ? -1 : b.neuPosition === "unten" ? 1 : 0,
    true
  );
  if (neuPositionVergleich !== 0) {
    return neuPositionVergleich;
  } else {
    const minuteVergleich = vergleichMitNull(a.minute, b.minute, true);
    if (minuteVergleich !== 0) {
      return minuteVergleich;
    } else {
      const zusatzminuteVergleich = vergleichMitNull(a.zusatzminute, b.zusatzminute, true);
      if (zusatzminuteVergleich !== 0) {
        return zusatzminuteVergleich;
      } else {
        return vergleichMitNull(a.key, b.key, false);
      }
    }
  }
};
export const eintragQuicksort = (liste: ProtokollEintrag[], highlightKey: string | null) => {
  // Daten vorbereiten
  const keysVorher = liste.map<string>((eintrag) => {
    return eintrag.key;
  });
  liste.forEach((eintrag) => {
    eintrag.minute = parseInt(eintrag.minute, 10) || null;
    eintrag.zusatzminute = parseInt(eintrag.zusatzminute, 10) || 0;
  });
  const highlightIndexVorher = keysVorher.indexOf(highlightKey);
  const sortierteListe = [...liste];
  // Funktion fürs Sortieren bereitstellen
  const rekursivSortieren = (start, ende) => {
    if (ende - start < 1) {
      return;
    }
    const pivotWert = sortierteListe[ende];
    let splitIndex = start;
    for (let i = start; i < ende; i++) {
      const sortierung = eintragComparator(sortierteListe[i], pivotWert);
      if (sortierung === -1) {
        if (splitIndex !== i) {
          const temp = sortierteListe[splitIndex];
          sortierteListe[splitIndex] = sortierteListe[i];
          sortierteListe[i] = temp;
        }
        splitIndex++;
      }
    }
    sortierteListe[ende] = sortierteListe[splitIndex];
    sortierteListe[splitIndex] = pivotWert;
    rekursivSortieren(start, splitIndex - 1);
    rekursivSortieren(splitIndex + 1, ende);
  };
  // Sortierung durchführen
  rekursivSortieren(0, liste.length - 1);
  // Nachbearbeitung vorbereiten
  const keysNachher = sortierteListe.map((eintrag) => {
    return eintrag.key;
  });
  const highlightIndexNachher = keysNachher.indexOf(highlightKey);
  let alleGleich = true;
  keysVorher.forEach((key, index) => {
    alleGleich = alleGleich && key === keysNachher[index];
  });
  // Neue Liste zurückgeben (alte Liste falls keine Änderungen, damit kein neues Rendern getriggert wird)
  if (alleGleich) {
    liste.forEach((eintrag, index) => {
      if (eintrag.highlight) {
        liste[index] = {
          ...eintrag,
          highlight: false,
        };
      }
    });
    return liste; // sortierteListe wird verworfen
  } else {
    sortierteListe.forEach((eintrag, index) => {
      if (eintrag.key !== highlightKey && eintrag.highlight) {
        sortierteListe[index] = {
          ...eintrag,
          highlight: false,
        };
      }
    });
    if (highlightIndexNachher !== highlightIndexVorher) {
      sortierteListe[highlightIndexNachher] = {
        ...sortierteListe[highlightIndexNachher],
        highlight: true,
      };
    }
    return sortierteListe;
  }
};

export const protokollHatSpielverlaufTore = (liste: ProtokollEintrag[]) => {
  if (liste.length > 0) {
    let toreGefunden = false;
    liste.forEach((eintrag) => {
      if (eintrag.art === "spielverlauf") {
        switch (eintrag.aktion) {
          case "tor":
          case "elfmeter_tor":
          case "eigentor":
            toreGefunden = true;
            break;
          default:
          // noop
        }
      }
    });
    return toreGefunden;
  } else {
    return false;
  }
};
export const protokollHatTore = (liste: ProtokollEintrag[]) => {
  if (liste.length > 0) {
    let toreGefunden = false;
    liste.forEach((eintrag) => {
      switch (eintrag.aktion) {
        case "tor":
        case "elfmeter_tor":
        case "eigentor":
          toreGefunden = true;
          break;
        default:
        // noop
      }
    });
    return toreGefunden;
  } else {
    return false;
  }
};

function protokollReducer(state: ProtokollState, action: Action): ProtokollState | null {
  switch (action.type) {
    case "PROTOKOLL_NEUEN_EINTRAG_ERSTELLEN":
      return {
        ...state,
        liste: eintragErstellen(action.position, state.liste, action.art, action.aktion, state.neuIndex),
        neuIndex: state.neuIndex + 1,
      };
    case "PROTOKOLL_EINTRAG_LOESCHEN":
      return {
        ...state,
        liste: eintragLoeschen(state.liste, action.eintragKey),
      };
    case "PROTOKOLL_SORTIERUNG_AKTUALISIEREN":
      return {
        ...state,
        liste: eintragQuicksort(state.liste, action.highlightKey),
      };
    case "PROTOKOLL_RESULTATE_AKTUALISIEREN":
      if (protokollHatTore(state.liste) || action.auchOhneTore) {
        let spielstandHeim = 0;
        let spielstandGast = 0;
        return {
          ...state,
          liste: state.liste.map<ProtokollEintrag>((eintrag) => {
            switch (eintrag.aktion) {
              case "tor":
                spielstandHeim += eintrag.heimTeam ? 1 : 0;
                spielstandGast += eintrag.gastTeam ? 1 : 0;
                if (eintrag.nurLesen) {
                  return eintrag;
                } else {
                  return {
                    ...eintrag,
                    toreHeim: spielstandHeim,
                    toreGast: spielstandGast,
                  };
                }
              case "elfmeter_tor":
                spielstandHeim += eintrag.heimTeam ? 1 : 0;
                spielstandGast += eintrag.gastTeam ? 1 : 0;
                if (eintrag.nurLesen) {
                  return eintrag;
                } else {
                  return {
                    ...eintrag,
                    toreHeim: spielstandHeim,
                    toreGast: spielstandGast,
                  };
                }
              case "eigentor":
                spielstandHeim += eintrag.gastTeam ? 1 : 0;
                spielstandGast += eintrag.heimTeam ? 1 : 0;
                if (eintrag.nurLesen) {
                  return eintrag;
                } else {
                  return {
                    ...eintrag,
                    toreHeim: spielstandHeim,
                    toreGast: spielstandGast,
                  };
                }
              default:
                return eintrag;
            }
          }),
          spielstandHeim: spielstandHeim,
          spielstandGast: spielstandGast,
        };
      } else {
        return state;
      }
    case "PROTOKOLL_WERT_SETZEN_ID":
      return {
        ...state,
        liste: eintragWertAktualisieren(state.liste, action.eintragKey, "id", action.wert, true, null),
      };
    case "PROTOKOLL_WERT_SETZEN_MINUTE":
      return {
        ...state,
        liste: eintragWertAktualisieren(state.liste, action.eintragKey, "minute", action.wert, true, null),
      };
    case "PROTOKOLL_WERT_SETZEN_ZUSATZMINUTE":
      return {
        ...state,
        liste: eintragWertAktualisieren(state.liste, action.eintragKey, "zusatzminute", action.wert, true, 0),
      };
    case "PROTOKOLL_WERT_SETZEN_TEAM":
      return {
        ...state,
        liste: eintragTeamAktualisieren(state.liste, action.eintragKey, action.heimTeam, action.gastTeam),
      };
    case "PROTOKOLL_WERT_SETZEN_SPIELER_ID":
      return {
        ...state,
        liste: eintragSpielerIdAktualisieren(state.liste, action.eintragKey, "spielerId", action.wert, action.spielerkarteHeimTeam, action.spielerkarteGastTeam),
      };
    case "PROTOKOLL_WERT_SETZEN_BEMERKUNG":
      return {
        ...state,
        liste: eintragWertAktualisieren(state.liste, action.eintragKey, "bemerkung", action.wert, false, null),
      };
    case "PROTOKOLL_WERT_SETZEN_TORE_HEIM":
      return {
        ...state,
        liste: eintragWertAktualisieren(state.liste, action.eintragKey, "toreHeim", action.wert, true, 0),
      };
    case "PROTOKOLL_WERT_SETZEN_TORE_GAST":
      return {
        ...state,
        liste: eintragWertAktualisieren(state.liste, action.eintragKey, "toreGast", action.wert, true, 0),
      };
    case "PROTOKOLL_VIDEO_ANZEIGEN":
      return {
        ...state,
        liste: eintragWertAktualisieren(state.liste, action.eintragKey, "videoAnzeigen", true, false, null),
      };
    case "PROTOKOLL_VIDEO_VERSTECKEN":
      return {
        ...state,
        liste: eintragWertAktualisieren(state.liste, action.eintragKey, "videoAnzeigen", false, false, null),
      };
    case "PROTOKOLL_HIGHLIGHT_ENTFERNEN":
      return {
        ...state,
        liste: state.liste.map<ProtokollEintrag>((eintrag) => {
          if (eintrag.highlight) {
            return {
              ...eintrag,
              highlight: false,
            };
          } else {
            return eintrag;
          }
        })
      };
    case "AJAX_PROTOKOLL_DATEN_ZURUECKSETZEN":
      return protokollDatenLaden(action.serverDaten);
    default:
      return state || null;
  }
}

export default protokollReducer
