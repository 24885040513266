import React from "react";
import useGlobalHook from "use-global-hook";

import * as actions from "../actions";

const initialState = {
  spielInfos: null,
  videos: [],
  selectedVideo: "",
  ereignisse: [],
  kommentare: [],
  tore: [],
  formVisible: false,
  formItem: {},
  overlaySequence: null,
  autorisierung: {},
  i18n: {},
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
