import React, {useEffect, useState} from "react";
import {executeJson} from "../lib/fetchWrapper";

const MarkdownText = ({markdownUrl, token, text}) => {
  const [html, setHtml] = useState(null);

  let abortController = new AbortController();

  useEffect(() => {
    executeJson(markdownUrl, token, 'POST', {text: text}, {signal: abortController.signal}).
    then(result => {
      setHtml(result.data.preview);
    }).catch(error => {
      // wir ingorieren alle fehler
    });

    return () => abortController.abort();
  }, []);

  if (html === null) {
    return <span className="ajax-spinner-klein"/>
  }

  return <div dangerouslySetInnerHTML={{__html: html}}/>;
}

export default MarkdownText;
