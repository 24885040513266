// @flow
import React from "react";
import { connect } from "react-redux";
import { aktivesSpielerTeamSetzen } from "../../../state/modules/navigation";
import type { AppState, EinsatzState, NavigationState, Team, TeamState } from "../../../state/stateTypes";
import type { Dispatch } from "../../../state/actionTypes";
import { EINSATZ_ROLLE } from "../../../state/modules/einsatz";

const TeamSelektion = ({ einsatz, navigation, teams, i18n, onTeamTabKlick, }: Props) => {
  const istVideoScout = einsatz.einsatzRolleId == EINSATZ_ROLLE.scout_video
  const erstelleTeamTab = (team_tab_name: string, team: Team, subtitel: any) => {
    const istAktiv = navigation.aktivesSpielerTeam === team_tab_name;
    return (<li className={istAktiv ? "active" : ""} style={{ cursor: istAktiv ? "" : "pointer" }}>
      <a onClick={() => !istAktiv && onTeamTabKlick(team_tab_name)}>
        <img src={team.logo} style={{ width: "20px" }} alt={team.id}/>
        &nbsp;&nbsp;{subtitel}
      </a>
    </li>);
  }

  return teams.heimTeam && teams.gastTeam ? (
    <ul className="nav nav-tabs">
      {!!teams.heimTeam && erstelleTeamTab("heim", teams.heimTeam, i18n.subtitel.heimTeam)}
      {(!!teams.heimTeam && !istVideoScout) && erstelleTeamTab("heimWettkampfleistungen", teams.heimTeam, i18n.subtitel.heimTeamWettkampfleistungen)}
      {!!teams.gastTeam && erstelleTeamTab("gast", teams.gastTeam, i18n.subtitel.gastTeam)}
      {(!!teams.gastTeam && !istVideoScout) && erstelleTeamTab("gastWettkampfleistungen", teams.gastTeam, i18n.subtitel.gastTeamWettkampfleistungen)}
    </ul>
  ) : null;
};

type Props = {
  einsatz: EinsatzState,
  navigation: NavigationState,
  teams: TeamState,
  i18n: any,
  onTeamTabKlick: (string) => void,
}

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    navigation: state.navigation,
    teams: state.teams,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onTeamTabKlick: (aktivesSpielerTeam) => {dispatch(aktivesSpielerTeamSetzen(aktivesSpielerTeam));},
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamSelektion);
