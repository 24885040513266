const SPONSORENLAUF_LISTEN_TEAMS = 'SPONSORENLAUF_LISTEN_TEAMS';
const SPONSORENLAUF_LISTEN_FEHLER = 'SPONSORENLAUF_LISTEN_FEHLER';

export function listenTeams(teams) {
  return (dispatch) => {
    dispatch({type: SPONSORENLAUF_LISTEN_TEAMS, teams: teams});
  }
}

export function listenFehler(fehler) {
  return (dispatch) => {
    dispatch({type: SPONSORENLAUF_LISTEN_FEHLER, fehler: fehler});
  }
}

function sponsorenlaufListenReducer(state = {}, action) {
  switch (action.type) {
    case SPONSORENLAUF_LISTEN_TEAMS:
      let bestehendeTeamIds = state.teams.map(t => t.id);
      return {
        ...state,
        teams: state.teams.concat(action.teams.filter(t => !bestehendeTeamIds.includes(t.id)))
      };
    case SPONSORENLAUF_LISTEN_FEHLER:
      return {
        ...state,
        fehler: action.fehler
      };
    default:
      return state;
  }
}

export default sponsorenlaufListenReducer;
