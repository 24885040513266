import React from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../components/ModalerDialog";
import {statusDisclaimerAnzeigen} from "../state/modules/status";

const DisclaimerModal = ({anzeigen, texte, disclaimerSchliessen}) => {
  if (anzeigen) {
    return (
      <ModalerDialog>
        <div className="modal disclaimerModal">
          <div className="modal-header">
            <button type="button" className="close" onClick={disclaimerSchliessen}>&times;</button>
            <h3>{texte.titel}</h3>
          </div>
          <div className="modal-body" dangerouslySetInnerHTML={{ __html: texte.inhalt_html}}/>
          <div className="modal-footer">
            <button className="btn btn-large btn-primary" onClick={disclaimerSchliessen}>{texte.schliessen}</button>
          </div>
        </div>
      </ModalerDialog>
    );
  }
  return <></>;
};

const mapStateToProps = (state) => {
  return {
    anzeigen: state.status.disclaimerAnzeigen,
    texte: state.i18n.texte.disclaimerModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    disclaimerSchliessen: () => { dispatch(statusDisclaimerAnzeigen(false)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DisclaimerModal);
