import React from 'react'
import {connect} from "react-redux";
import Header from "./Header";
import Spielfeld from "./Spielfeld";
import Footer from "./Footer";
import useEscape from "../../../effects/useEscape";
import {schliesseAlleMenus} from "../state/modules/editMarkierer";

const SpielAnsicht = ({spiel, tickerAnzeigen, schliesseAlleMenus}) => {
  const ready = spiel != undefined;
  const spielPeriode = ready ? spiel.periode : '';
  useEscape(() => schliesseAlleMenus());

  const onClick = () => {
    schliesseAlleMenus();
  };

  return (
    <div className={`spiel-ansicht ${spielPeriode} ${tickerAnzeigen ? '' : 'ohne-ticker'}`} onClick={onClick}>
      <div className={`spielfeld ${spielPeriode}`}>
        {ready &&
        <>
          <Header/>
          <Spielfeld/>
          <Footer/>
        </>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    spiel: state.heartbeat.spiel,
    tickerAnzeigen: state.editMarkierer.tickerAnzeigen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    schliesseAlleMenus: () => { dispatch(schliesseAlleMenus()) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpielAnsicht);
