import React, {useEffect} from 'react';
import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import reducer from "./state/reducerFuerTeilnehmer";
import preloaderFuerTeilnehmer from "./state/preloaderFuerTeilnehmer";
import {rollbarInitialisieren} from "../../reducers/rollbar";
import ErrorBoundary from "../../components/ErrorBoundary";
import Ansichten from "./components/Teilnehmer/Ansichten";

const Teilnehmer = (props) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducer, preloaderFuerTeilnehmer(props), composeEnhancers(applyMiddleware(thunk)));

  useEffect(() => {
    store.dispatch(rollbarInitialisieren());
  }, []);

  return (
    <Provider store={store}>
      <ErrorBoundary fehlermeldung={'Es ist ein Fehler passiert... :/ Bitte laden Sie die Seite neu.'}>
        <Ansichten/>
      </ErrorBoundary>
    </Provider>
  );
};

export default Teilnehmer;
