import React, {useState} from "react";
import {connect} from "react-redux";
import {bezeichnungDelete} from "../../state/modules/sponsorenlaufBezeichnungen";
import BezeichnungModal from "./BezeichnungModal";

const Bezeichnungen = ({bezeichnungen, sprachen, texte, bezeichnungDelete}) => {
  const [editBezeichnung, setEditBezeichnung] = useState(null);

  const loeschen = (bezeichnung) => {
    bezeichnungDelete(bezeichnung);
  };

  return (
    <>
      <section>
        <h2>{texte.bezeichnungen}</h2>
        <div className="form-actions">
          <button type="button" className="btn" onClick={() => setEditBezeichnung({inaktiv: false, texte: []})}>{texte.neueBezeichnungErstellen}</button>
        </div>
        <table className="attributes table table-striped">
          <thead>
          <tr>
            {sprachen.map(s => <th key={s.id} style={{width: 'calc(33% - 160px)'}}>{s.bezeichnung}</th>)}
            <th style={{width: 62}}></th>
            <th style={{width: 100}}></th>
          </tr>
          </thead>
          <tbody>
          {bezeichnungen.map(bezeichnung => {
            return <tr key={bezeichnung.id}>
              {sprachen.map(sprache => {
                let text = bezeichnung.texte.find(t => t.spracheId === sprache.id) || {};
                return <td key={sprache.id}>{text.bezeichnung}</td>;
              })}
              <td>{bezeichnung.inaktiv ? <span className="label">{texte.inaktiv}</span> : ''}</td>
              <td>
                <button type="button" className="btn" onClick={() => setEditBezeichnung(bezeichnung)}><i className="icon-edit"/></button>
                &nbsp;
                <button type="button" className="btn" onClick={() => loeschen(bezeichnung)}><i className="icon-trash"/></button>
              </td>
            </tr>;
          })}
          </tbody>
        </table>
      </section>
      {editBezeichnung && <BezeichnungModal bezeichnung={editBezeichnung} onClose={() => setEditBezeichnung(null)}/>}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bezeichnungen: state.bezeichnungen,
    sprachen: state.listen.sprachen,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bezeichnungDelete: (bezeichnung) => { dispatch(bezeichnungDelete(bezeichnung)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bezeichnungen);
