import {executeRequest} from "../lib/api";
import {sortierePersonName} from "../lib/formatierungen";
import {bezogeneLeistungUpdate} from "./bezogeneLeistungenReducer";

const KLASSENLEHRER_UPDATE = 'KLASSENLEHRER_UPDATE';

export function klassenlehrerLeistungenVerrechnen(klassenlehrer, anzahlTage, anzahlKilometer, betragSonstige) {
  return (dispatch, getState) => {
    const state = getState();
    const daten = {
      kontext: 'leistung_verrechnen',
      ausbildungId: klassenlehrer.ausbildungId,
      anzahlTage: anzahlTage,
      anzahlKilometer: anzahlKilometer,
      betragSonstige: betragSonstige
    };
    return executeRequest(state.api.update, state.api.token, 'PUT', daten, (result) => {
      dispatch({type: KLASSENLEHRER_UPDATE, klassenlehrer: result.data.klassenlehrer});
      dispatch(bezogeneLeistungUpdate(result.data.bezogeneLeistungen));
    });
  };
}
export function anfahrtswegBerechnen(klassenlehrer) {
  return (dispatch, getState) => {
    const state = getState();
    const daten = {
      kontext: 'anfahrtsweg_berechnen',
      ausbildungId: klassenlehrer.ausbildungId
    };
    return executeRequest(state.api.update, state.api.token, 'PUT', daten, (result) => {
      const lehrer = {...klassenlehrer, anfahrtsweg: result.data.anfahrtsweg};
      dispatch({type: KLASSENLEHRER_UPDATE, klassenlehrer: lehrer});
    });
  };
}

function klassenlehrerReducer(state = [], action) {
  switch (action.type) {
    case KLASSENLEHRER_UPDATE:
      const klassenlehrer = action.klassenlehrer;
      return [
        ...state.filter(a => a.id !== klassenlehrer.id),
        klassenlehrer
      ].sort((a, b) => sortierePersonName(a, b));
    default:
      return state;
  }

}

export default klassenlehrerReducer;
