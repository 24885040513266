import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {liveSupportAnzeige} from "../lib/helpers";
import AnsichtSupport from "./AnsichtSupport";
import {lobbyEnter, lobbyLeave, lobbyTimerStarten, lobbyTimerStoppen} from "../state/settings";

const Lobby = ({person, settings, texte, lobbyEnter, lobbyLeave, lobbyTimerStarten, lobbyTimerStoppen}) => {

  useEffect(() => {
    lobbyTimerStarten();
    return () => lobbyTimerStoppen();
  }, []);

  useEffect(() => {
    liveSupportAnzeige(settings.supportAnwesend);
  }, [settings.supportAnwesend]);

  const toggleLobby = () => {
    if (settings.lobbyOffen) {
      lobbyLeave();
    } else {
      lobbyEnter();
    }
  };
  
  if (settings.lobbyOffen === false) {
    return <button className="btn btn-large btn-success" onClick={toggleLobby}>{texte.lobbyBetreten}</button>;
  } else {
    return (
      <>
        <div>
          {settings.currentRoom === null &&
          <button className="btn btn-warning pull-right" onClick={toggleLobby}>{texte.lobbyVerlassen}</button>
          }
          <h4>{texte.lobbyAnwesende}</h4>
          <ul>
            {settings.lobby.sort((a, b) => a.name.localeCompare(b.name)).map(p => <li key={p.id}>{p.name}</li>)}
          </ul>
        </div>
        <hr/>
        <AnsichtSupport/>
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    person: state.person,
    settings: state.settings,
    texte: state.i18n.ansichtSupport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    lobbyEnter: () => { dispatch(lobbyEnter()); },
    lobbyLeave: () => { dispatch(lobbyLeave()); },
    lobbyTimerStarten: () => { dispatch(lobbyTimerStarten()); },
    lobbyTimerStoppen: () => { dispatch(lobbyTimerStoppen()); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Lobby);
