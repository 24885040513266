import {EREIGNIS_SUBTYP, erstelleZustandFuerSpielerkarte} from "../../lib/spielablauf";
import {ICON_TYP} from "../../components/EreignisIcon";

const TEAM_SPIELERKARTE_RESET = 'TEAM_SPIELERKARTE_RESET';
const TEAM_SPIELERKARTE_AUSWECHSLUNG = 'SPIELERKARTEN_AUSWECHSLUNG';
const TEAM_SPIELERKARTE_ROTE_KARTE = 'SPIELERKARTEN_ROTE_KARTE';
const TEAM_SPIELERKARTE_GELBE_KARTE = 'SPIELERKARTEN_GELBE_KARTE';
const TEAM_SPIELERKARTE_TOR = 'SPIELERKARTEN_TOR';
const TEAM_SPIELERKARTE_PENALTYSCHIESSEN_BEGINNT = 'SPIELERKARTEN_PENALTYSCHIESSEN_BEGINNT';
const TEAM_SPIELERKARTE_PENALTYSCHIESSEN = 'SPIELERKARTEN_PENALTYSCHIESSEN';

export function teamSpielerkarteZustandReset(teamId) {
  return (dispatch) => {
    dispatch({type: TEAM_SPIELERKARTE_RESET, teamId: teamId});
  };
}

export function teamSpielerkarteTor(teamId, ereignis) {
  return (dispatch) => {
    dispatch({type: TEAM_SPIELERKARTE_TOR, teamId: teamId, ereignis: ereignis});
  };
}

export function teamSpielerkartePenaltyschiessenBeginnt(teamId) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.liga.fussball_art === 'futsal') {
      dispatch({type: TEAM_SPIELERKARTE_PENALTYSCHIESSEN_BEGINNT, teamId: teamId});
    }
  };
}

export function teamSpielerkartePenaltyschiessen(teamId, ereignis) {
  return (dispatch) => {
    dispatch({type: TEAM_SPIELERKARTE_PENALTYSCHIESSEN, teamId: teamId, ereignis: ereignis});
  };
}

export function teamSpielerkarteAuswechslung(teamId, ereignis) {
  return (dispatch) => {
    dispatch({type: TEAM_SPIELERKARTE_AUSWECHSLUNG, teamId: teamId, ereignis: ereignis});
  };
}

export function teamSpielerkarteGelbeKarte(teamId, ereignis) {
  return (dispatch) => {
    dispatch({type: TEAM_SPIELERKARTE_GELBE_KARTE, teamId: teamId, ereignis: ereignis});
  };
}

export function teamSpielerkarteRoteKarte(teamId, ereignis) {
  return (dispatch) => {
    dispatch({type: TEAM_SPIELERKARTE_ROTE_KARTE, teamId: teamId, ereignis: ereignis});
  };
}

function teamSpielerkarteReducer(state = {}, action) {
  if (state.team === undefined || state.team.id != action.teamId) {
    // dieser reducer ist doppelt vorhanden, einmal pro team. wird also doppelt durchlaufen, gilt aber nur fürs korrekte team!
    return state;
  }

  let ereignis = null;
  let feldspieler = null;
  let ersatzspieler = null;
  let staff = null;

  switch (action.type) {
    case TEAM_SPIELERKARTE_RESET:
      return {
        ...state,
        zustand: erstelleZustandFuerSpielerkarte(state.spielerkarte)
      };
    case TEAM_SPIELERKARTE_TOR:
      ereignis = action.ereignis;
      let iconTor = ICON_TYP.tor;
      if (ereignis.subtyp_id == EREIGNIS_SUBTYP.eigentor) {
        iconTor = ICON_TYP.eigentor
      }
      
      feldspieler = state.zustand.feldspieler.find(s => s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler);
      if (feldspieler) {
        return {
          ...state,
          zustand: {
            ...state.zustand,
            feldspieler: [
              ...state.zustand.feldspieler.filter(s => !(s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler)),
              {...feldspieler, ereignisse: [...feldspieler.ereignisse, iconTor]}
            ]
          }
        };
      }

      ersatzspieler = state.zustand.ersatzspieler.find(s => s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler);
      if (ersatzspieler) {
        return {
          ...state,
          zustand: {
            ...state.zustand,
            ersatzspieler: [
              ...state.zustand.ersatzspieler.filter(s => !(s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler)),
              {...ersatzspieler, ereignisse: [...ersatzspieler.ereignisse, iconTor]}
            ]
          }
        };
      }

      // TODO: fehlerhandling?
      console.log('teamSpielerkarteReducer: spieler/ersatzspieler für tor nicht gefunden!');
      console.log(ereignis);
      return state;
    case TEAM_SPIELERKARTE_PENALTYSCHIESSEN_BEGINNT:
      // alle ersatzspieler sind nun feldspieler, d.h. zugelassen fürs penaltyschiessen
      ersatzspieler = state.zustand.ersatzspieler.filter(s => s.ereignisse.includes(ICON_TYP.roteKarte));
      const ersatzspielerImFeld = state.zustand.ersatzspieler.filter(s => !s.ereignisse.includes(ICON_TYP.roteKarte));
      if (ersatzspielerImFeld.length > 0) {
        return {
          ...state,
          zustand: {
            ...state.zustand,
            feldspieler: state.zustand.feldspieler.concat(ersatzspielerImFeld),
            ersatzspieler: ersatzspieler
          }
        };
      }
      return state;
    case TEAM_SPIELERKARTE_PENALTYSCHIESSEN:
      ereignis = action.ereignis;
      let iconPenaltyTor = ICON_TYP.tor;
      if (ereignis.subtyp_id === EREIGNIS_SUBTYP.penaltyschiessen_kein_tor) {
        iconPenaltyTor = ICON_TYP.fehlschuss
      }
      
      feldspieler = state.zustand.feldspieler.find(s => s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler);
      if (feldspieler) {
        return {
          ...state,
          zustand: {
            ...state.zustand,
            feldspieler: [
              ...state.zustand.feldspieler.filter(s => !(s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler)),
              {...feldspieler, ereignisse: [...feldspieler.ereignisse, iconPenaltyTor]}
            ]
          }
        };
      }

      // TODO: fehlerhandling?
      console.log('teamSpielerkarteReducer: spieler/ersatzspieler für penaltyschiessen-tor nicht gefunden!');
      console.log(ereignis);
      return state;
    case TEAM_SPIELERKARTE_AUSWECHSLUNG:
      ereignis = action.ereignis;
      feldspieler = state.zustand.feldspieler.find(s => s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler);
      if (feldspieler === undefined) { // chronologie fehler: feldspieler ist nicht auf feld 
        feldspieler = state.zustand.ersatzspieler.find(s => s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler);
      }

      ersatzspieler = state.zustand.ersatzspieler.find(s => s.id === ereignis.auswechsel_spieler_id && s.unregistriert === ereignis.auswechsel_unregistrierter_spieler);
      if (ersatzspieler === undefined) { // chronologie fehler: ersatzspieler ist nicht auf staffbank
        ersatzspieler = state.zustand.feldspieler.find(s => s.id === ereignis.auswechsel_spieler_id && s.unregistriert === ereignis.auswechsel_unregistrierter_spieler);
      }

      if (feldspieler === undefined || ersatzspieler === undefined) {
        // TODO: fehlerhandling? feldspieler und ersatzspieler sind nicht im aufgebot..!
        console.log('teamSpielerkarteReducer: spieler/ersatzspieler für auswechslung nicht gefunden!');
        console.log(ereignis);
        return state;
      }

      return {
        ...state,
        zustand: {
          ...state.zustand,
          feldspieler: [
            ...state.zustand.feldspieler.filter(s => !(s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler)),
            {...ersatzspieler, position_id: feldspieler.position_id, ereignisse: [...ersatzspieler.ereignisse, ICON_TYP.einwechsel]}
          ],
          ersatzspieler: [
            ...state.zustand.ersatzspieler.filter(s => !(s.id === ereignis.auswechsel_spieler_id && s.unregistriert === ereignis.auswechsel_unregistrierter_spieler)),
            {...feldspieler, ereignisse: [...feldspieler.ereignisse, ICON_TYP.auswechsel]}
          ]
        }
      };
    case TEAM_SPIELERKARTE_ROTE_KARTE:
      ereignis = action.ereignis;

      feldspieler = state.zustand.feldspieler.find(s => s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler);
      if (feldspieler) {
        return {
          ...state,
          zustand: {
            ...state.zustand,
            feldspieler: [
              ...state.zustand.feldspieler.filter(s => !(s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler)),
            ],
            ersatzspieler: [
              ...state.zustand.ersatzspieler,
              {...feldspieler, ereignisse: [...feldspieler.ereignisse, ICON_TYP.roteKarte]}
            ]
          }
        };
      }

      ersatzspieler = state.zustand.ersatzspieler.find(s => s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler);
      if (ersatzspieler) {
        return {
          ...state,
          zustand: {
            ...state.zustand,
            ersatzspieler: [
              ...state.zustand.ersatzspieler.filter(s => !(s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler)),
              {...ersatzspieler, ereignisse: [...ersatzspieler.ereignisse, ICON_TYP.roteKarte]}
            ]
          }
        };
      }

      staff = state.zustand.staff.find(s => s.id === ereignis.rolle_id);
      if (staff) {
        return {
          ...state,
          zustand: {
            ...state.zustand,
            staff: [
              ...state.zustand.staff.filter(s => s.id != ereignis.rolle_id),
              {...staff, ereignisse: [...staff.ereignisse, ICON_TYP.roteKarte]}
            ]
          }
        };
      }

      // TODO: fehlerhandling?
      console.log('teamSpielerkarteReducer: spieler für rote karte nicht gefunden!');
      console.log(ereignis);
      return state;
    case TEAM_SPIELERKARTE_GELBE_KARTE:
      ereignis = action.ereignis;
      feldspieler = state.zustand.feldspieler.find(s => s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler);
      if (feldspieler) {
        return {
          ...state,
          zustand: {
            ...state.zustand,
            feldspieler: [
              ...state.zustand.feldspieler.filter(s => !(s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler)),
              {...feldspieler, ereignisse: [...feldspieler.ereignisse, ICON_TYP.gelbeKarte]}
            ]
          }
        };
      }

      ersatzspieler = state.zustand.ersatzspieler.find(s => s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler);
      if (ersatzspieler) {
        return {
          ...state,
          zustand: {
            ...state.zustand,
            ersatzspieler: [
              ...state.zustand.ersatzspieler.filter(s => !(s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler)),
              {...ersatzspieler, ereignisse: [...ersatzspieler.ereignisse, ICON_TYP.gelbeKarte]}
            ]
          }
        };
      }

      staff = state.zustand.staff.find(s => s.id === ereignis.rolle_id);
      if (staff) {
        return {
          ...state,
          zustand: {
            ...state.zustand,
            staff: [
              ...state.zustand.staff.filter(s => s.id != ereignis.rolle_id),
              {...staff, ereignisse: [...staff.ereignisse, ICON_TYP.gelbeKarte]}
            ]
          }
        };
      }

      // TODO: fehlerhandling?
      console.log('teamSpielerkarteReducer: spieler/ersatzspieler/staff für gelbe karte nicht gefunden!');
      console.log(ereignis);
      return state;
    default:
      return state;
  }
}

export default teamSpielerkarteReducer;
