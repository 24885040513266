import React, {useRef, useState} from "react";
import ModalerDialog from "../../../components/ModalerDialog";
import {connect} from "react-redux";

const VideoPreview = ({videoPfad, texte}) => {
  const videoRef = useRef(undefined);
  const [showVideo, setShowVideo] = useState(false);
  const [videoWidth, setVideoWidth] = useState('auto');
  const [videoHeight, setVideoHeight] = useState('auto');

  const videoReady = () => {
    if (videoRef.current.videoWidth >= videoRef.current.videoHeight) {
      setVideoWidth('100%');
    } else {
      setVideoHeight('100%');
    }
  };

  return (
    <>
      <button className="btn" onClick={() => setShowVideo(!showVideo)}>{texte.videoAnzeigen}</button>
      {showVideo &&
      <ModalerDialog>
        <div className="block" style={{width: '67%', maxHeight: '90%', overflow: 'auto'}}>
          <div className="header">
            <b>{texte.videoAnzeigen}</b>
            <button className="btn btn-small pull-right" onClick={() => setShowVideo(false)}>
              <i className="icon-remove"/>
            </button>
          </div>
          <div className="content">
            <div className="video-container">
              <video ref={videoRef} onCanPlay={videoReady} width={videoWidth} height={videoHeight} controls="controls">
                <source src={videoPfad} type="video/mp4"/>
                {texte.browserUnterstuetztNichtHtml5Video}
              </video>
            </div>
          </div>
        </div>
      </ModalerDialog>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    texte: state.i18n.texte
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(VideoPreview);
