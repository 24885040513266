import fetch from "cross-fetch";

function fetchInitFuer(method, options = {}) {
  return {
    ...options,
    method: method,
    mode: 'same-origin',
    credentials: 'same-origin',
    headers: {'content-type': 'application/json'}
  }
}

function startFetch(fetchInit, url) {
  return fetch(url, fetchInit)
    .then(response => {
      return response.json().then(jsonData => ({data: jsonData, ok: response.ok, status: response.status}));
    });
}

export async function executeGet(url, daten = null, options = {}) {
  let apiUrl = url;
  if (daten) {
    const querystring = Object.keys(daten)
      .map(key => {
        if (Array.isArray(daten[key])) {
          // rails params als array braucht das format "key[]=" 
          return daten[key].map(val => `${encodeURIComponent(key)}[]=${encodeURIComponent(val)}`).join('&');
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(daten[key])}`;
        }
      })
      .join('&');
    apiUrl = `${url}?${querystring}`;
  }
  return startFetch(fetchInitFuer('GET', options), apiUrl);
}

export async function executeJson(url, token, method, daten = {}, options = {}) {
  daten['authenticity_token'] = token;

  const fetchInit = fetchInitFuer(method, options);
  fetchInit.body = JSON.stringify(daten);

  return startFetch(fetchInit, url);
}

// bisherige version mit synchroner ausführung und FormData für post requests
export function executeRequest(url, token, method, daten, callback, fehlerCallback) {
  const fetchInit = {
    method: method,
    headers: {
      'x-requested-with': 'XMLHttpRequest',
    },
    credentials: 'same-origin'
  };

  let apiUrl = url;
  if (method === 'GET') {
    if (daten) {
      const params = new URLSearchParams();
      for (let propName in daten) {
        if (daten.hasOwnProperty(propName)) {
          params.append(propName, daten[propName]);
        }
      }
      apiUrl = url + "?" + params.toString()
    }
  } else {
    const form = new FormData();
    fillObjectToFormData(form, daten);
    form.append('authenticity_token', token);
    fetchInit.body = form;
  }

  fetch(apiUrl, fetchInit)
    .then(response => {
      return response.json().then(jsonData => ({data: jsonData, ok: response.ok, status: response.status}));
    })
    .then(jsonResultat => callback(jsonResultat))
    .catch(fehler => {
      if (fehlerCallback) {
        fehlerCallback(fehler);
      } else {
        console.error(fehler);
      }
    });
}

function fillObjectToFormData(formData, daten, prefix = '') {
  for (let propName in daten) {
    if (daten.hasOwnProperty(propName)) {
      const val = daten[propName];
      if (val instanceof Array) {
        fillArray(formData, val, `${prefix}${propName}`);
      } else if (val instanceof Date) { // fällt sonst unter "Object"
        formData.append(`${prefix}${propName}`, JSON.stringify(val));
      } else if (val instanceof Object) {
        fillObjectToFormData(formData, val, `${prefix}[${propName}]`);
      } else {
        formData.append(`${prefix}${propName}`, val);
      }
    }
  }
}

function fillArray(form, array, prefix = '') {
  array.forEach((element, _index) => {
    form.append(`${prefix}[]`, element);
  });
}
