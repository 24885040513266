import {executeJson} from "../../../../lib/fetchHelper";

const SPONSORENLAUF_BEZEICHNUNGEN_EDIT = 'SPONSORENLAUF_BEZEICHNUNGEN_EDIT';
const SPONSORENLAUF_BEZEICHNUNGEN_DELETE = 'SPONSORENLAUF_BEZEICHNUNGEN_DELETE';

export function bezeichnungCreate(bezeichnung) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const data = {
      bezeichnung: bezeichnung
    };
    executeJson(`${api.bezeichnungen}`, api.token, 'POST', data).
    then(result => {
      if (result.ok) {
        dispatch({type: SPONSORENLAUF_BEZEICHNUNGEN_EDIT, bezeichnung: result.data.bezeichnung});
      } else {
        if (result.data.bezeichnung.errorMessages.length > 0) {
          result.data.bezeichnung.errorMessages.forEach(error => {
            window.neueFlashMessage(error, 'error').anzeigen();
          });
        }
      }
    });
  };
}

export function setzeBezeichnung(bezeichnung) {
  return (dispatch) => {
    dispatch({type: SPONSORENLAUF_BEZEICHNUNGEN_EDIT, bezeichnung: bezeichnung});
  };
}

export function bezeichnungDelete(bezeichnung) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    executeJson(`${api.bezeichnungen}/${bezeichnung.id}`, api.token, 'DELETE').
    then(result => {
      if (result.ok) {
        dispatch({type: SPONSORENLAUF_BEZEICHNUNGEN_DELETE, bezeichnung: result.data.bezeichnung});
      } else {
        // TODO fehler anzeigen?
        dispatch({type: SPONSORENLAUF_BEZEICHNUNGEN_EDIT, bezeichnung: result.data.bezeichnung});
      }
    });
  };
}

function sponsorenlaufBezeichnungenReducer(state = [], action) {
  switch (action.type) {
    case SPONSORENLAUF_BEZEICHNUNGEN_EDIT:
      let liste = state.filter(b => b.id !== action.bezeichnung.id);
      liste.push(action.bezeichnung);
      liste.sort((a, b) => a.id - b.id);
      return liste;
    case SPONSORENLAUF_BEZEICHNUNGEN_DELETE:
      return state.filter(b => b.id !== action.bezeichnung.id);
    default:
      return state;
  }
}

export default sponsorenlaufBezeichnungenReducer;
