import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {saisonWechsel} from "../state/modules/ligen";
import {executeJson} from "../../../lib/fetchHelper";
import {ligaSetzen} from "../state/modules/status";

const TeamsucheSaison = ({status, saisons, ligen, texte, api, saisonWechsel, ligaSetzen}) => {
  const [teamsuche, setTeamsuche] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);
  const [suchResultate, setSuchResultate] = useState(null);
  const [showResultate, setShowResultate] = useState(false);

  const saisonChange = (ev) => {
    ev.currentTarget.blur();
    saisonWechsel(parseInt(ev.currentTarget.value, 10));
  };

  const teamsucheChange = (teamsuche) => {
    setTeamsuche(teamsuche);
  };
  
  const setzeSuchresultate = (resultate) => {
    setSuchResultate(resultate);
    setShowResultate(resultate !== null && resultate.length > 0);
  };
  
  useEffect(() => {
    clearTimeout(timeoutId);
    if (teamsuche.length > 2) {
      setTimeoutId(setTimeout(teamsucheAbfragen, 400));
    } else {
      setShowResultate(false);
    }

    return () => clearTimeout(timeoutId);
  }, [teamsuche]);

  const teamsucheAbfragen = () => {
    executeJson(api.favoritenTeams, api.token, 'POST', {teamSuche: teamsuche}).then(result => {
      if (result.ok) {
        setzeSuchresultate(result.data.teams);
      }
    });
  };

  const resultatClick = (team) => {
    const liga = ligen.find(liga => liga.id === team.ligaId);
    if (liga) {
      setzeSuchresultate(null);
      setTeamsuche('');
      ligaSetzen(liga);
    }
  };
  
  const teamsucheFocus = () => {
    // DRY: bestehende logik verwenden
    setzeSuchresultate(suchResultate);
  };
  
  const teamsucheBlur = (ev) => {
    if (ev.relatedTarget === null || !ev.relatedTarget.className === 'resultat') {
      setShowResultate(false);
    }
  };

  return (
    <div className="saisonwahl row-fluid">
      <div className="span6 teamsuche">
        {texte.saison}&nbsp;
        <input type="text" value={teamsuche} placeholder={texte.teamSuche} onChange={ev => teamsucheChange(ev.currentTarget.value)} onFocus={teamsucheFocus} onBlur={teamsucheBlur}/>
        {showResultate && suchResultate.length > 0 &&
        <div className="teamsucheResultate">
          {suchResultate.map(team => <div key={team.id} className="resultat" tabIndex={0} onClick={() => resultatClick(team)}>{team.bezeichnung}</div>)}
        </div>
        }
      </div>
      <div className="span6">
        {texte.saison}&nbsp;
        <select value={status.saisonId} onChange={ev => saisonChange(ev)}>
          {saisons.map(s => <option key={s.id} value={s.id}>{s.bezeichnung}</option>)}
        </select>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    status: state.status,
    saisons: state.saisons,
    ligen: state.ligen,
    texte: state.i18n.texte,
    api: state.api
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saisonWechsel: (saisonId) => { dispatch(saisonWechsel(saisonId)) },
    ligaSetzen: (liga) => { dispatch(ligaSetzen(liga)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsucheSaison);
