import React, {useState} from "react";
import {connect} from "react-redux";
import {executeRequest} from "../lib/fetchHelper";
import PersonBadge from "../../../components/PersonBadge";

const PersonAuswahl = ({value = null, minChars = 0, onPersonChange, api, rollenTypen, texte}) => {
  const [liste, setListe] = useState([]);
  const [index, setIndex] = useState(0);
  const [weitereResultate, setWeitereResultate] = useState(false);
  const [abfrageAktiv, setAbfrageAktiv] = useState(false);
  const [sucheTimeout, setSucheTimeout] = useState(null);

  const [person, setPerson] = useState(value);
  const [showPersonResultate, setShowPersonResultate] = useState(false);
  const [rolleTyp, setRolleTyp] = useState(rollenTypen[0].typ);
  const [personName, setPersonName] = useState('');

  let abortController = null;
  const timeoutMiliseconds = 400; // TODO: passendes timeout für eingabe von hand finden

  const zeigeResultate = (anzeigen) => {
    setShowPersonResultate(anzeigen && liste.length > 0);
  };

  const personenSucheStarten = (typ, name, idx = 0) => {
    if (name && name.length >= minChars) {
      clearTimeout(sucheTimeout);
      const timeoutId = setTimeout(() => abfragen(typ, name, idx), timeoutMiliseconds);
      setSucheTimeout(timeoutId);
    }
  };

  const abfragen = (typ, name, idx) => {
    const daten = {
      rolleTyp: typ,
      name: name,
      index: idx
    };
    setAbfrageAktiv(true);
    setIndex(idx);
    if (abortController) {
      abortController.abort();
    }
    abortController = new AbortController();
    executeRequest(api.personen, api.token, 'GET', daten, { signal: abortController.signal })
      .then(result => {
        if (result.ok) {
          const data = result.data;
          if (idx > 0) {
            setListe(liste.concat(data.personen));
          } else {
            setListe(data.personen);
          }
          setWeitereResultate(data.weitereResultate);
          setShowPersonResultate(data.personen.length > 0);
        }
        setAbfrageAktiv(false);
      }).catch(() => {
        // noop, aber drin wegen AbortController.abort()
      });
  };

  const selectPerson = (p) => {
    zeigeResultate(false);
    setPerson(p);
    onPersonChange(p);
  };

  const onScrollResultate = (event) => {
    if (weitereResultate && abfrageAktiv === false) {
      const target = event.currentTarget;
      if (target.scrollTop >= (target.scrollHeight - target.clientHeight)) {
        abfragen(rolleTyp, personName, index + 1);
      }
    }
  };

  const onChangeRolleTyp = (typ) => {
    setIndex(0);
    setRolleTyp(typ);
    zeigeResultate(false);
    personenSucheStarten(typ, personName);
  };

  const onChangePersonName = (name) => {
    setIndex(0);
    setPersonName(name);
    zeigeResultate(false);
    personenSucheStarten(rolleTyp, name);
  };

  const onFocusPersonName = () => {
    if (liste.length === 0) {
      personenSucheStarten(rolleTyp, personName);
    }
  };

  const onClickPersonName = (event) => {
    event.stopPropagation();
    zeigeResultate(!showPersonResultate);
  };

  const onBlur = (event) => {
    event.stopPropagation();
    zeigeResultate(false);
  };

  return (
    <div className="suchbereich" style={{width: 220}}>
      <div className="zeile">
        <select value={rolleTyp} onChange={ev => onChangeRolleTyp(ev.currentTarget.value)} onBlur={onBlur}>
          {rollenTypen.map(typ => <option key={typ.typ} value={typ.typ}>{typ.bezeichnung}</option>)}
        </select>
      </div>
      <div className="zeile text-nowrap">
        <input type="text" placeholder={texte.textsuche} value={personName} onChange={ev => onChangePersonName(ev.currentTarget.value)} onFocus={onFocusPersonName} onBlur={onBlur} onClick={onClickPersonName}/>
        {abfrageAktiv &&
        <span className="ajax-spinner-klein"/>
        }
      </div>
      {person &&
      <div className="zeile">
        <span className="bordered-box" onClick={() => zeigeResultate(!showPersonResultate)} onBlur={onBlur} tabIndex={-1}>
          <PersonBadge person={person} personVerlinken={false} showVcf={false} fotoTooltipAktiv={false}/>
        </span>
      </div>
      }
      {showPersonResultate &&
      <div className="suchresultat-container">
        <div className="suchresultat-liste" onScroll={onScrollResultate} onMouseDown={ev => ev.preventDefault()}>
          {liste.map(p => <div key={p.id} className={`item ${person && p.id == person.id ? 'selected' : ''}`} onClick={() => selectPerson(p)}><PersonBadge person={p} personVerlinken={false} showVcf={false} fotoTooltipAktiv={false}/></div>)}
        </div>
      </div>
      }
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    api: state.api,
    rollenTypen: state.typen.rollen,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonAuswahl);
