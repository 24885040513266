import {executeGet} from "../../lib/fetchHelper";
import {apiRequestAktiv} from "./api";
import {ersetzeStringMitDatum} from "../../../../lib/datenhaltung";

const SPORTMOTORIK_SPIELER_INDEX = 'SPORTMOTORIK_SPIELER_INDEX';
const SPORTMOTORIK_SPIELER_UPDATE = 'SPORTMOTORIK_SPIELER_UPDATE';
const SPORTMOTORIK_SPIELER_DELETE = 'SPORTMOTORIK_SPIELER_DELETE';

export function sportmotorikAlleSpielerLaden(phase) {
  return (dispatch, getState) => {
    dispatch(apiRequestAktiv(true));
    const api = getState().api;
    return executeGet(api.sportmotorik, {phase: phase})
      .then(result => {
        dispatch({type: SPORTMOTORIK_SPIELER_INDEX, liste: result.data.liste});
      }).finally(() => {
        dispatch(apiRequestAktiv(false));
      });
  };
}

export function sportmotorikSpielerIndex(liste) {
  return (dispatch) => {
    dispatch({type: SPORTMOTORIK_SPIELER_INDEX, liste: liste});
  };
}

export function sportmotorikSpielerUpdate(sportmotorik) {
  return (dispatch) => {
    dispatch({type: SPORTMOTORIK_SPIELER_UPDATE, sportmotorik: sportmotorik});
  };
}

export function sportmotorikSpielerEntfernen(sportmotorik) {
  return (dispatch) => {
    dispatch({type: SPORTMOTORIK_SPIELER_DELETE, sportmotorik: sportmotorik});
  };
}

function sportmotorikReducer(state = [], action) {
  switch (action.type) {
    case SPORTMOTORIK_SPIELER_INDEX:
      return action.liste.map(model => ersetzeStringMitDatum(model, ['datum_zeit_messung', 'datum_abgeschlossen'])); // daten komplett überschreiben
    case SPORTMOTORIK_SPIELER_UPDATE:
      return [
        ...(state.filter(m => m.spielerId !== action.sportmotorik.spielerId || m.phase !== action.sportmotorik.phase)),
        ersetzeStringMitDatum(action.sportmotorik, ['datum_zeit_messung', 'datum_abgeschlossen'])
      ];
    case SPORTMOTORIK_SPIELER_DELETE:
      return state.filter(m => m.id !== action.sportmotorik.id);
    default:
      return state;
  }
}

export default sportmotorikReducer;
