import {executeRequest} from "../../lib/api";
import {ereignisZuStateHinzufuegen} from "./spielverlauf";
import {heartbeatAbfragen} from "./heartbeat";

const ANZAHL_ZUSCHAUER_SETZEN = 'ANZAHL_ZUSCHAUER_SETZEN';
const SPIELZEIT_SETZEN = 'SPIELZEIT_SETZEN';
const LAUFENDE_SPIELZEIT_TIMER = 'LAUFENDE_SPIELZEIT_TIMER';
const SPIELANZEIGE_TORE_SETZEN = 'SPIELANZEIGE_TORE_SETZEN';

let timerIntervalId = null;

function berechneLaufendeSpielzeit(heartbeat) {
  let aktuelle_sekunden = 0;
  if (heartbeat.spielzeit.zeit_laeuft === true) {
    // anzahl sekunden berechnen -> delta zu jetzt seit letzem spiel start (anpfiff, start zweite halbzeit, etc) 
    const stempel = new Date(heartbeat.spielzeit.gemessen_um);
    const offset = new Date();
    offset.addMinutes(heartbeat.spielzeit.minute);
    offset.addSeconds(heartbeat.spielzeit.sekunde);
    aktuelle_sekunden = Math.floor((offset - stempel) / 1000);
  } else {
    // spiel läuft nicht: zustand initial oder seit letztem spiel stop (halbzeit ende, ende erste verlängerung, etc) 
    if (heartbeat.spielzeit.minute) {
      aktuelle_sekunden = heartbeat.spielzeit.minute * 60 + heartbeat.spielzeit.sekunde;
    }
  }
  return aktuelle_sekunden;
}

export function laufendeSpielzeitInitialisieren() {
  return (dispatch, getState) => {
    const heartbeat = getState().heartbeat;
    let aktuelle_sekunden = berechneLaufendeSpielzeit(heartbeat);
    dispatch({type: LAUFENDE_SPIELZEIT_TIMER, laufendeSpielzeit: aktuelle_sekunden});
  };
}

export function laufendeSpielzeitStarten() {
  return (dispatch, getState) => {
    clearInterval(timerIntervalId); // zur sicherheit

    timerIntervalId = setInterval(() => {
      const heartbeat = getState().heartbeat;
      let aktuelle_sekunden = berechneLaufendeSpielzeit(heartbeat);
      dispatch({type: LAUFENDE_SPIELZEIT_TIMER, laufendeSpielzeit: aktuelle_sekunden});
    }, 1000);
  };
}

export function laufendeSpielzeitStoppen() {
  return () => {
    clearInterval(timerIntervalId);
  };
}

export function spielAnzahlZuschauerSetzen(anzahlZuschauer) {
  return (dispatch, getState) => {
    const api = getState().api;
    return executeRequest(api.spiel, api.token, 'PUT', {zuschauer: anzahlZuschauer}, (result) => {
      dispatch({type: ANZAHL_ZUSCHAUER_SETZEN, zuschauer: result.data.anzahlZuschauer});
    });
  };
}

export function laufendeSpielzeitSetzen(typ, method, spielminute, zusatzminute) {
  // setzt ereignis für spielinformation, also anpfiff, halbzeit ende, usw
  
  return (dispatch, getState) => {
    if (zusatzminute === undefined) { zusatzminute = 0; }
    const api = getState().api;
    const daten = {
      typ: typ,
      minute: spielminute,
      zusatzminute: zusatzminute,
    };
    return executeRequest(api.spielzeit, api.token, method, daten, (result) => {
      dispatch(ereignisZuStateHinzufuegen(result.data));
      dispatch(heartbeatAbfragen()); // damit uhr *fast* sofort stehenbleibt
    });
  };
}

export function spielanzeigeToreSetzen(heimTore, gastTore) {
  return (dispatch) => {
    dispatch({type: SPIELANZEIGE_TORE_SETZEN, heimTore: heimTore, gastTore: gastTore});
  };
}

function spielanzeigeReducer(state = {}, action) {
  switch (action.type) {
    case LAUFENDE_SPIELZEIT_TIMER:
      return {
        ...state,
        laufendeSpielzeit: action.laufendeSpielzeit,
      };
    case ANZAHL_ZUSCHAUER_SETZEN:
      return {
        ...state,
        zuschauer: action.zuschauer,
      };
    case SPIELZEIT_SETZEN:
      return state;
    case SPIELANZEIGE_TORE_SETZEN:
      return {
        ...state,
        heimTore: action.heimTore,
        gastTore: action.gastTore,
      };
    default:
      return state;
  }
}

export default spielanzeigeReducer;
