import {executeJson} from "../../../../lib/fetchHelper";
import {meldungError} from "./meldungen";
import {ranglisteSetzen} from "./ranglisten";

const SPIELE_LADEN = 'SPIELE_LADEN';
const SPIEL_UPDATE = 'SPIEL_UPDATE';

export function spieleLaden(spiele) {
  return (dispatch) => {
    dispatch({type: SPIELE_LADEN, spiele: spiele});
  };
}

export function spieleAktualisieren(meldungen) {
  return (dispatch, getState) => {
    const ids = meldungen.map(m => m.id);
    const aktualisierteSpiele = getState()
      .spiele
      .filter(s => ids.includes(s.id))
      .map(spiel => {
        const meldung = meldungen.find(m => m.id === spiel.id);
        return {
          ...spiel,
          toreHeim: meldung.toreHeim,
          toreGast: meldung.toreGast,
          spielstatusId: meldung.spielstatusId,
          keinResultat: false
        };
      });
    dispatch({type: SPIELE_LADEN, spiele: aktualisierteSpiele});
  };
}

export function resultatMelden(spiel, toreHeim, toreGast, spielstatusId, kommentar) {
  return (dispatch, getState) => {
    const state = getState();
    const data = {
      spielId: spiel.id,
      toreHeim: toreHeim,
      toreGast: toreGast,
      spielstatusId: spielstatusId,
      kommentar: kommentar
    };
    executeJson(state.api.spiele, state.api.token, 'POST', data).then(result => {
      if (result.ok) {
        dispatch({type: SPIEL_UPDATE, spiel: result.data.spiel});
        dispatch(ranglisteSetzen(result.data.rangliste))
      } else {
        dispatch(meldungError(state.i18n.texte.meldungen.resultatMeldenFehler));
      }
    }).catch(error => {
      dispatch(meldungError(state.i18n.texte.meldungen.resultatMeldenFehler));
    });
  };
}

export function spieleSortieren(a, b) {
  return a.spielzeit.localeCompare(b.spielzeit);
}

function spieleReducer(state = [], action) {
  switch (action.type) {
    case SPIELE_LADEN:
      const ids = action.spiele.map(s => s.id);
      return state
        .filter(r => !ids.includes(r.id))
        .concat(action.spiele)
        .sort(spieleSortieren);
    case SPIEL_UPDATE:
      return [
        ...state.filter(r => r.id !== action.spiel.id),
        action.spiel
      ].sort(spieleSortieren);
    default:
      return state;
  }
}

export default spieleReducer;
