import {combineReducers} from 'redux';
import {dummyReducer} from "../../../lib/datenhaltung";
import rollbarReducer from "../../../reducers/rollbar";
import rechnungskontrollenReducer from "./modules/rechnungskontrollen";
import rechnungskontrollenStatusReducer from "./modules/rechnungskontrollenStatus";
import teamsReducer from "./modules/teams";

export default combineReducers({
  rechnungen: rechnungskontrollenReducer,
  status: rechnungskontrollenStatusReducer,
  teams: teamsReducer,
  listen: dummyReducer,
  bexio: dummyReducer,
  api: dummyReducer,
  i18n: dummyReducer,
  rollbar: rollbarReducer,
});
