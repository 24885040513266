import {executeRequest} from "../../lib/fetchHelper";

const TEAMS_SETZEN = 'TEAMS_SETZEN';
const TEAMS_INAKTIVE_SETZEN = 'TEAMS_INAKTIVE_SETZEN';

function sortiereTeams(a, b) {
  if (a.ligaNr === b.ligaNr) {
    return a.bezeichnung.localeCompare(b.bezeichnung);
  }
  return a.ligaNr - b.ligaNr;
}

export function teamsLaden(rechnungen) {
  return (dispatch, getState) => {
    const alleTeams = getState().teams.map(team => team.id);
    const fehlendeTeams = [];
    rechnungen.forEach(rechnung => {
      rechnung.teamIds.forEach(teamId => {
        if (!alleTeams.includes(teamId)) {
          fehlendeTeams.push(teamId);
        }
      });
    });
    if (fehlendeTeams.length > 0) {
      const api = getState().api;
      return executeRequest(api.teams, api.token, 'GET', {teamIds: fehlendeTeams})
        .then(result => {
          dispatch({type: TEAMS_SETZEN, teams: result.data.teams});
        });
    }
  };
}

export function inaktiveTeamsSetzen(inaktiveTeams) {
  return(dispatch) => {
    if (inaktiveTeams.length > 0) {
      dispatch({type: TEAMS_INAKTIVE_SETZEN, teams: inaktiveTeams});
    }
  }
}

function teamsReducer(state = [], action) {
  switch (action.type) {
    case TEAMS_SETZEN:
      const teams = state.concat(action.teams);
      teams.sort(sortiereTeams);
      return teams;
    case TEAMS_INAKTIVE_SETZEN:
      const alleIds = state.map(t => t.id);
      const neueTeams = action.teams.filter(neu => alleIds.includes(neu.id) === false);
      const teams2 = state.concat(neueTeams);
      teams2.sort(sortiereTeams);
      return teams2;
    default:
      return state;
  }
}

export default teamsReducer;
