import React, {useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../../components/ModalerDialog";
import MarkdownEditor from "../../../../components/MarkdownEditor";

const TextEdit = ({text, api, i18n, onChange, onClose}) => {
  const [textModel, setTextModel] = useState({...text});
  const texte = i18n.texte;

  const textChange = (name, value) => {
    const model =  {...textModel};
    model[name] = value;
    setTextModel(model);
  };

  const schliessen = () => {
    onChange(textModel);
    onClose();
  };

  return (
    <ModalerDialog>
      <div className="" style={{width: '60%', }}>
        <div className="modal-header">
          <button type="button" className="close" onClick={onClose}>&times;</button>
          <h3>{texte.textBearbeiten} ({i18n.sprachen.find(s => s.id === text.spracheId).bezeichnung})</h3>
        </div>
        <div className="modal-body">
          <table className="table formular-breit">
            <tbody>
            <tr>
              <th><label>{texte.header}</label></th>
              <td><MarkdownEditor showBexioAktionen={true} value={textModel['header']} onChange={md => textChange('header', md)} markdownUrl={api.markdown} token={api.token} locale={i18n.locale} /></td>
            </tr>
            <tr>
              <th><label>{texte.footer}</label></th>
              <td><MarkdownEditor showBexioAktionen={true} value={textModel['footer']} onChange={md => textChange('footer', md)} markdownUrl={api.markdown} token={api.token} locale={i18n.locale} /></td>
            </tr>
            <tr>
              <th><label>{texte.freiwilligZusatz}</label></th>
              <td><input type="text" value={textModel['freiwilligZusatz']} onChange={ev => textChange('freiwilligZusatz', ev.currentTarget.value)}/></td>
            </tr>
            <tr>
              <th><label>{texte.freiwilligHeader}</label></th>
              <td><MarkdownEditor showBexioAktionen={true} value={textModel['freiwilligHeader']} onChange={md => textChange('freiwilligHeader', md)} markdownUrl={api.markdown} token={api.token} locale={i18n.locale} /></td>
            </tr>
            <tr>
              <th><label>{texte.freiwilligFooter}</label></th>
              <td><MarkdownEditor showBexioAktionen={true} value={textModel['freiwilligFooter']} onChange={md => textChange('freiwilligFooter', md)} markdownUrl={api.markdown} token={api.token} locale={i18n.locale} /></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" onClick={schliessen}>{texte.ok}</button>
          <button type="button" className="btn" onClick={onClose}>{texte.abbrechen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    api: state.api,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TextEdit);

