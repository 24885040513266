import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PersonIcon from "../../../../components/PersonIcon";
import IconToggle from "../IconToggle";
import {klassenlehrerLeistungenVerrechnen, anfahrtswegBerechnen} from "../../state/klassenlehrerReducer";
import {bezogeneLeistungDelete} from "../../state/bezogeneLeistungenReducer";

const AbbrechnungKlassenlehrer = ({klassenlehrer, leistungen, bezogeneLeistungen, texte, leistungVerrechnen, leistungEntfernen, anfahrtswegBerechnen}) => {
  const bezogeneLeistung = bezogeneLeistungen.find(bz => bz.ausbildungId === klassenlehrer.ausbildungId);
  const [showKlassenlehrer, setShowKlassenlehrer] = useState(false);
  const [anzahlTage, setAnzahlTage] = useState(klassenlehrer.anzahlTage);
  const [anzahlKilometer, setAnzahlKilometer] = useState(bezogeneLeistung && bezogeneLeistung.reisespesen && anzahlTage > 0 ? bezogeneLeistung.reisespesen.anzahl / anzahlTage : 0);
  const [betragSonstige, setBetragSonstige] = useState(bezogeneLeistung && bezogeneLeistung.sonstigeSpesen ? bezogeneLeistung.sonstigeSpesen.betrag : 0);
  const kilometerTotal = anzahlKilometer * Math.ceil(anzahlTage); // anzahlKilometer ist km pro tag -> mit anzahlTage multplizieren
  
  useEffect(() => {
    if(showKlassenlehrer && leistungen.reise && anzahlKilometer === 0) {
      anfahrtswegBerechnen(klassenlehrer);
    }
  }, [showKlassenlehrer]);
  
  useEffect(() => {
    if(klassenlehrer.anfahrtsweg > 0) {
      setAnzahlKilometer(klassenlehrer.anfahrtsweg);
    }
  }, [klassenlehrer]);

  const speichern = () => {
    leistungVerrechnen(klassenlehrer, anzahlTage, kilometerTotal, betragSonstige);
  };
  
  const loeschen = (bezogeneLeistungId) => {
    if(bezogeneLeistung.reise && bezogeneLeistung.reise.id === bezogeneLeistungId) {
      setAnzahlKilometer(klassenlehrer.anfahrtsweg || 0);
    }
    if(bezogeneLeistung.sonstigeSpesen && bezogeneLeistung.sonstigeSpesen.id === bezogeneLeistungId) {
      setBetragSonstige(0);
    }
    leistungEntfernen(klassenlehrer, bezogeneLeistungId);
  };

  return (
    <div className="block">
      <div className="header">
        <button className="btn btn-mini pull-right" onClick={() => setShowKlassenlehrer(!showKlassenlehrer)}>
          <IconToggle istOffen={showKlassenlehrer}/>
        </button>
        {`${klassenlehrer.funktion}: ${klassenlehrer.name}`}
        <PersonIcon person={klassenlehrer}/>
      </div>
      {showKlassenlehrer &&
      <div className="content edit">
        <div className="zeile">
          <label>{texte.kursleiter.anzahlTage}</label>
          <input className="tage" type="number" step="0.5" min={0} value={anzahlTage} onChange={e => setAnzahlTage(e.currentTarget.value)}/>
        </div>
        {leistungen.entschaedigung &&
        <div className="zeile">
          <label>{texte.kursleiter.entschaedigung}</label>
          {anzahlTage > 0 &&
          <span className="leistung-betrag">CHF {anzahlTage * leistungen.entschaedigung.betrag}</span>
          }
          {bezogeneLeistung && bezogeneLeistung.entschaedigung &&
          <button className="btn btn-mini" title={texte.loeschen} onClick={() => loeschen(bezogeneLeistung.entschaedigung.id)}>
            <i className="icon-trash"/>
          </button>
          }
        </div>
        }
        {leistungen.reise &&
        <div className="zeile">
          <label>{texte.kursleiter.reisespesen}</label>
          {(!bezogeneLeistung || !bezogeneLeistung.reisespesen) && anzahlTage > 0 &&
          <>
            {kilometerTotal > 0 &&
            <span className="leistung-betrag">CHF {Math.round(kilometerTotal * leistungen.reise.betrag)} ({kilometerTotal} km)</span>
            }
            {kilometerTotal === 0 &&
            <span className="ajax-spinner"/>
            }
          </>
          }
          {bezogeneLeistung && bezogeneLeistung.reisespesen &&
          <>
            <span className="leistung-betrag">CHF {Math.round(kilometerTotal * leistungen.reise.betrag)} ({kilometerTotal} km)</span>
            <button className="btn btn-mini" title={texte.loeschen} onClick={() => loeschen(bezogeneLeistung.reisespesen.id)}>
              <i className="icon-trash"/>
            </button>
          </>
          }
        </div>
        }
        {leistungen.sonstige &&
        <div className="zeile">
          <label>{texte.kursleiter.sonstigeSpesen}</label>
          {anzahlTage > 0 &&
          <span className="leistung-betrag">CHF <input className="tage" type="number" step="1" min={0} value={betragSonstige} onChange={e => setBetragSonstige(e.currentTarget.value)}/></span>
          }
          {bezogeneLeistung && bezogeneLeistung.sonstigeSpesen &&
          <button className="btn btn-mini" title={texte.loeschen} onClick={() => loeschen(bezogeneLeistung.sonstigeSpesen.id)}>
            <i className="icon-trash"/>
          </button>
          }
        </div>
        }
        <div className="zeile">
          <label />
          <button className="btn btn-primary" onClick={speichern}>{texte.speichern}</button>
        </div>
      </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    leistungen: state.leistungen,
    bezogeneLeistungen: state.bezogeneLeistungen,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    leistungVerrechnen: (klassenlehrer, anzahlTage, anzahlKilometer, betragSonstige) => { dispatch(klassenlehrerLeistungenVerrechnen(klassenlehrer, anzahlTage, anzahlKilometer, betragSonstige)) },
    leistungEntfernen: (klassenlehrer, id) => { dispatch(bezogeneLeistungDelete(klassenlehrer, id)) },
    anfahrtswegBerechnen: (klassenlehrer) => { dispatch(anfahrtswegBerechnen(klassenlehrer)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AbbrechnungKlassenlehrer);
