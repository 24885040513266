// @flow

import type {AppState, EinsatzState, NavigationState, SpielerEintrag, SpielerkarteEintrag, SpielerState, SpielState} from "../../../state/stateTypes";
import type {Dispatch} from "../../../state/actionTypes";

import React from "react";
import { connect } from "react-redux";

import SpielerkarteSpieler from "./SpielerkarteSpieler";
import SpielerkarteSpielerOhneDaten from "./SpielerkarteSpielerOhneDaten";
import SpielerkarteSpielerReadonly from "./SpielerkarteSpielerReadonly";
import SpielerkarteTorhueterNoten from "./SpielerkarteTorhueterNoten";
import TeamSelektion from "./TeamSelektion";
import TipsZusammenfassung from "../TipsZusammenfassung";
import Wettkampfleistungen from "./Wettkampfleistungen";

const Spielerkarte = ({ navigation, einsatz, spiel, spielerliste, i18n }: Props) => {

  const einsatzHatDaten = (einsatzSpieler) => {
    return einsatzSpieler && !einsatzSpieler.nichtregistriert;
  };

  const einsatzIstSchreibgeschuetzt = (spielerkarteEintrag: SpielerkarteEintrag, spielerEintrag: SpielerEintrag | void) => {
    return !spielerEintrag ||
      spielerEintrag.nichtregistriert ||
      spielerEintrag.tips.nurLesen ||
      (einsatz.hatTorhueterEinsatz && !einsatz.istTorhueterEinsatz && spielerkarteEintrag.torhueter) ||
      (einsatz.istTorhueterEinsatz && !spielerkarteEintrag.torhueter)
  };

  const sortierteSpielerliste = (teamFormation) => {
    let spielerlisteIds = spielerliste.liste.map(s => s.id)
    return [...teamFormation].sort((a, b) => spielerlisteIds.indexOf(a.spielerId) - spielerlisteIds.indexOf(b.spielerId));
  }

  const formation = (teamFormation) => {
    return sortierteSpielerliste(teamFormation).map((spielerkarteEintrag) => {
      let spielerlisteEintrag = spielerliste.liste.find((spieler) => {
        return spieler.id === spielerkarteEintrag.spielerId && spieler.nichtregistriert === spielerkarteEintrag.nichtregistriert;
      });
      if(einsatzHatDaten(spielerlisteEintrag)) {
        if(!einsatzIstSchreibgeschuetzt(spielerkarteEintrag, spielerlisteEintrag)) {
          if(einsatz.istTorhueterEinsatz && spielerkarteEintrag.torhueter) {
            return <SpielerkarteTorhueterNoten key={spielerkarteEintrag.id}
                                               spielerEinsatz={spielerkarteEintrag}
                                               spieler={spielerlisteEintrag}/>;
          } else {
            return <SpielerkarteSpieler key={spielerkarteEintrag.id}
                                        spielerEinsatz={spielerkarteEintrag}
                                        spieler={spielerlisteEintrag}/>;
          }
        } else {
          return <SpielerkarteSpielerReadonly key={spielerkarteEintrag.id}
                                              spielerEinsatz={spielerkarteEintrag}
                                              spieler={spielerlisteEintrag}/>;
        }
      } else {
        return <SpielerkarteSpielerOhneDaten key={spielerkarteEintrag.id}
                                             spielerEinsatz={spielerkarteEintrag}
                                             spieler={spielerlisteEintrag}/>;
      }
    });
  };

  const tableHeader =
    <thead>
    <tr>
      <th>{i18n.tips.passnummer}</th>
      <th>{i18n.tips.spieler}</th>
      <th className={"spielposition"}>{i18n.tips.position}</th>
      <th>{i18n.tips.rueckennummer}</th>
      <th className="text-center">{i18n.tips.t}</th>
      <th className="text-center">{i18n.tips.i}</th>
      <th className="text-center">{i18n.tips.p}</th>
      <th className="text-center">{i18n.tips.s}</th>
      <th className="text-center">ø</th>
      <th className="text-center">{i18n.tips.entwicklung}</th>
      <th className="text-center">{i18n.tips.note}</th>
      <th className="text-center">{i18n.prognoseFragen.header}</th>
      <th style={{ width: 40 }}></th>
      <th style={{ width: 40 }}></th>
      <th style={{ width: 40 }}></th>
      <th></th>
    </tr>
    </thead>

  const teamTable = (team) => {
    const relevanteSpieler: SpielerEintrag[] = spielerliste.liste.filter((spieler) => {
      let gefunden = false;
      team.startformation.forEach((teamEintrag) => {
        if(teamEintrag.spielerId === spieler.id) {
          gefunden = true;
        }
      });
      team.ersatzformation.forEach((teamEintrag) => {
        if(teamEintrag.spielerId === spieler.id) {
          gefunden = true;
        }
      });
      return gefunden;
    });
    return (
      <div>
        <TeamSelektion/>
        <TipsZusammenfassung relevanteSpieler={relevanteSpieler} relevanteWettkampfleistungen={null}/>
        <section>
          <h2 className="page-section">
            {i18n.subtitel.startformation}
          </h2>
        </section>
        <table className="table table-condensed">
          {tableHeader}
          {formation(team.startformation)}
        </table>
        <section>
          <h2 className="page-section">
            {i18n.subtitel.auswechselspieler}
          </h2>
        </section>
        <table className="table table-condensed">
          {tableHeader}
          {formation(team.ersatzformation)}
        </table>
      </div>
    );
  };

  if(navigation.aktivesSpielerTeam === 'heim') {
    return teamTable(spiel.spielerkarten.heimTeam);
  } else if(navigation.aktivesSpielerTeam === 'gast') {
    return teamTable(spiel.spielerkarten.gastTeam);
  } else {
    return <div>
      <TeamSelektion/>
      <Wettkampfleistungen/>
    </div>
  }
};

type Props = {
  navigation: NavigationState,
  einsatz: EinsatzState,
  spiel: SpielState,
  spielerliste: SpielerState,
  i18n: any,
};

const mapStateToProps = (state: AppState) => {
  return {
    navigation: state.navigation,
    einsatz: state.einsatz,
    spiel: state.spiel,
    spielerliste: state.spielerliste,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Spielerkarte);
