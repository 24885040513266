import React, {useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../../components/ModalerDialog";
import {aufgabeZuteilungUpdate} from "../../state/teamTeilnehmerReducer";

const AufgabenFreigeben = ({teamTeilnehmer, bibliothekEintraege, onClose, texte, aufgabeZuteilungUpdate}) => {
    const [bibliothekSelektiert, setBibliothekSelektiert] = useState([]);

    const toggleBibliothek = (bibliothek) => {
      if (bibliothekSelektiert.filter(b => b.id === bibliothek.id).length === 0) {
        setBibliothekSelektiert([...bibliothekSelektiert, bibliothek]);
      } else {
        setBibliothekSelektiert(bibliothekSelektiert.filter(bs => bs.id !== bibliothek.id));
      }
    };

    const freigabeClick = (freigabe) => {
      if (bibliothekSelektiert.length > 0) {
        const zusatzdaten = { freigabe: freigabe };
        teamTeilnehmer.forEach(tt => {
          tt.aufgaben
            .filter(a => bibliothekSelektiert.filter(bs => bs.id === a.bibliothekId).length > 0)
            .forEach(a => aufgabeZuteilungUpdate(a, tt, zusatzdaten));
        });
        setBibliothekSelektiert([]);
        onClose();
      }
    };

    return (
      <ModalerDialog>
        <div className="block">
          <div className="header">
            <b>{texte.aufgabenFreigeben.titel}</b>
          </div>
          <div className="content">
            <div className="zeile btn-liste">
              <label>{texte.aufgabenFreigeben.bibliothekWaehlen}</label>
              {bibliothekEintraege.map(b => <button key={b.id} onClick={() => toggleBibliothek(b)} className={`btn btn-small${bibliothekSelektiert.filter(bs => b.id === bs.id).length > 0 ? ' btn-primary' : ''}`}>{b.titel}</button>)}
            </div>
            <div className="zeile btn-liste">
              <button className="btn btn-success" onClick={() => freigabeClick(true)}>{texte.aufgabenFreigeben.freigabeErteilen}</button>
              <button className="btn btn-danger" onClick={() => freigabeClick(false)}>{texte.aufgabenFreigeben.freigabeEntfernen}</button>
              <button className="btn pull-right" onClick={() => onClose()}>{texte.abbrechen}</button>
            </div>
          </div>
        </div>
      </ModalerDialog>
    );
  }
;

const mapStateToProps = (state) => {
  return {
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    aufgabeZuteilungUpdate: (aufgabeZuteilung, teilnehmer, zusatzdaten) => { dispatch(aufgabeZuteilungUpdate(aufgabeZuteilung, teilnehmer, zusatzdaten)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AufgabenFreigeben);
