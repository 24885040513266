import React from "react";
import {connect} from "react-redux";
import FormTextarea from "./FormTextarea";
import FormRadioListe from "./FormRadioListe";
import FormCheckbox from "./FormCheckbox";
import {berichtSetzeWert} from "../state/berichtReducer";

const Noten = ({bericht, listen, texte, setzeWert}) => {
  const readonly = bericht.bBerichtDefinitiv;

  return (
    <table className="table table-striped">
      <tbody>
      <tr>
        <th className="span3">{texte.sNoteSpiel}</th>
        <td><FormRadioListe name="sNoteSpiel" value={bericht.sNoteSpiel} readonly={readonly} eintraege={listen.noten} onRadioChange={(name, value) => setzeWert(name, value)}/></td>
      </tr>
      {listen.features.note_potenzial &&
        <tr>
          <th className="span3">{texte.sNotePotenzial}</th>
          <td><FormRadioListe name="sNotePotenzial" value={bericht.sNotePotenzial} readonly={readonly} eintraege={listen.noten} onRadioChange={(name, value) => setzeWert(name, value)}/></td>
        </tr>
      }
      <tr>
        <th className="span3">{texte.sSpielgewichtung}</th>
        <td><FormRadioListe name="sSpielgewichtung" value={bericht.sSpielgewichtung} readonly={readonly} eintraege={listen.notenSpielgewichtung} onRadioChange={(name, value) => setzeWert(name, value)}/></td>
      </tr>
      {listen.features.note_potenzial &&
        <tr>
          <th className="span3">{texte.bVorschlag}</th>
          <td><FormCheckbox name="bVorschlag" checked={bericht.bVorschlag} readonly={readonly} onCheckedChange={(name, value) => setzeWert(name, value)}/></td>
        </tr>
      }
      {listen.features.punkte_positiv &&
        <tr>
          <th className="span3">{texte.punkte_positiv}</th>
          <td><FormTextarea name="punkte_positiv" value={bericht.punkte_positiv} readonly={readonly} onTextChange={(name, value) => setzeWert(name, value)}/></td>
        </tr>
      }
      {listen.features.punkte_verbesserung &&
        <tr>
          <th className="span3">{texte.punkte_verbesserung}</th>
          <td><FormTextarea name="punkte_verbesserung" value={bericht.punkte_verbesserung} readonly={readonly} onTextChange={(name, value) => setzeWert(name, value)}/>
          </td>
        </tr>
      }
      <tr>
        <th className="span3">{texte.gesamtbeurteilung}</th>
        <td><FormTextarea name="gesamtbeurteilung" value={bericht.gesamtbeurteilung} readonly={readonly} onTextChange={(name, value) => setzeWert(name, value)}/></td>
      </tr>
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => {
  return {
    bericht: state.bericht,
    listen: state.listen,
    texte: state.i18n.texte.coaching_bericht
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setzeWert: (name, value) => dispatch(berichtSetzeWert(name, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Noten);

