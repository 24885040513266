import {executeJson} from "../../../lib/fetchHelper";
import {fehlerUpdate} from "./fehlerReducer";
import {ersetzeStringMitDezimalzahl} from "../../../lib/datenhaltung";
import {endeRequest, startRequest} from "../../../lib/laufendeAbfragenReducer";

const BERICHT_UPDATE = 'BERICHT_UPDATE';
const BERICHT_VALUE_UPDATE = 'BERICHT_VALUE_UPDATE';

export const ABFRAGE_BERICHT_ABSCHLUSS = 'abschluss';

export function berechneTotal(bericht) {
  return [bericht.smnEntschädigung, bericht.smnReisespesen, bericht.smnZuschlag]
    .reduce(function (previousValue, currentValue) {
      let num = parseFloat(currentValue);
      return previousValue + (isNaN(num) ? 0 : num);
    }, 0);
}

export function berichtSetzeWert(name, value) {
  return (dispatch, getState) => {
    const bericht = getState().bericht;
    // update bericht auf server nur wenn wert geändert
    if (bericht[name] !== value) {
      const previousValue = bericht[name];
      dispatch({type: BERICHT_VALUE_UPDATE, name: name, value: value});
      dispatch(berichtUpdate(name, value, previousValue));
    }
  };
}

function berichtUpdate(name, value, previousValue) {
  return (dispatch, getState) => {
    const daten = {name, value};
    const api = getState().api;
    return executeJson(api.bericht, api.token, 'PUT', daten)
      .then(result => {
        if (result.ok) {
          dispatch(fehlerUpdate({}));
        } else {
          dispatch(fehlerUpdate(result.data));
          // da nicht gespeichert, wert in state zurücksetzen!
          dispatch({type: BERICHT_VALUE_UPDATE, name: name, value: previousValue});
        }
      })
      .catch(error => {
        const texte = getState().i18n.texte;
        dispatch(fehlerUpdate({full_messages: [texte.speichern_fehler]}));
        // da nicht gespeichert, wert in state zurücksetzen!
        dispatch({type: BERICHT_VALUE_UPDATE, name: name, value: previousValue});
      });
  };
}

export function berichtAbschliessen(bericht) {
  return (dispatch, getState) => {
    dispatch(startRequest(ABFRAGE_BERICHT_ABSCHLUSS));

    const daten = {name: 'bBerichtDefinitiv', value: true};
    const api = getState().api;
    return executeJson(api.bericht, api.token, 'PUT', daten)
      .then(result => {
        dispatch({type: BERICHT_UPDATE, bericht: result.data.bericht});
        if (result.ok) {
          dispatch(fehlerUpdate({}));
        } else {
          dispatch(fehlerUpdate(result.data));
        }
      })
      .catch(error => {
        const texte = getState().i18n.texte;
        dispatch(fehlerUpdate({full_messages: [texte.speichern_fehler]}));
      }).finally(() => {
        dispatch(endeRequest(ABFRAGE_BERICHT_ABSCHLUSS));
      });
  };
}

function berichtReducer(state = {}, action) {
  switch (action.type) {
    case BERICHT_UPDATE:
      return ersetzeStringMitDezimalzahl(action.bericht, ['smnEntschädigung', 'smnReisespesen', 'smnZuschlag']);
    case BERICHT_VALUE_UPDATE:
      let bericht = {...state};
      bericht[action.name] = action.value;
      ersetzeStringMitDezimalzahl(bericht, ['smnEntschädigung', 'smnReisespesen', 'smnZuschlag']);
      return bericht;
    default:
      return state;
  }
}

export default berichtReducer;
