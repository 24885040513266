const SPIEL_UPDATE = 'SPIEL_UPDATE';

export function spielUpdate(daten) {
  return (dispatch) => {
    dispatch({ type: SPIEL_UPDATE, daten: daten});
  };
}

function spielReducer(state = {}, action) {
  switch (action.type) {
    case SPIEL_UPDATE:
      return {
        ...state,
        ...action.daten,
      };
    default:
      return state;
  }
}

export default spielReducer;
