// @flow

import type {AppState, ProtokollEintrag} from "../../../../state/stateTypes";
import type {Dispatch} from "../../../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import MinuteUndTeams from "../MinuteUndTeams";
import TeamSpieler from "../TeamSpieler";
import VideoAnzeige from "../../VideoAnzeige";

const SonstigeStrafe = (props: {
  pfade: any,
  i18n: any,
  eintrag: ProtokollEintrag,
}) => {
  return (
    <tr>
      <td>
        <div style={{display: 'flex', alignItems: 'center', height: '45px'}}>
          <div style={{width: '45px'}}>
            <img src={props.pfade.icons.strafe} alt={props.i18n.eintraege.strafe}/>
          </div>
          <MinuteUndTeams aktiv={false}
                          eintrag={props.eintrag}
                          minuteChange={(event) => {
                            // noop
                          }}
                          zusatzminuteChange={(event) => {
                            // noop
                          }}
                          protokollSortieren={(event) => {
                            // noop
                          }}
                          heimTeamKlick={(event) => {
                            // noop
                          }}
                          gastTeamKlick={(event) => {
                            // noop
                          }}/>
          <TeamSpieler aktiv={false}
                       eintrag={props.eintrag}
                       auswechselSpieler={false}
                       spielerChange={(event) => {
                         // noop
                       }}/>
        </div>
      </td>
      <td className={props.eintrag.highlight ? "protokoll-highlight" : ""}>
        <VideoAnzeige eintrag={props.eintrag}/>
      </td>
    </tr>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    pfade: state.pfade,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SonstigeStrafe);
