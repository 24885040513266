// diverse helpermethoden, vorwiegend in preloaders/reducers im einsatz

export function dummyReducer(state = {}, action) {
  return state;
}

export function ersetzeStringMitDatum(objektMitDatum, feldnamen) {
  if (objektMitDatum) {
    if (Array.isArray(feldnamen)) {
      feldnamen.forEach(name => {
        if (typeof (objektMitDatum[name]) === 'string') {
          objektMitDatum[name] = new Date(objektMitDatum[name]);
        }
      });
    } else {
      if (typeof (objektMitDatum[feldnamen]) === 'string') {
        objektMitDatum[feldnamen] = new Date(objektMitDatum[feldnamen]);
      }
    }
  }
  return objektMitDatum;
}

export function ersetzeStringMitDezimalzahl(objektMitDezimalzahl, feldnamen, precision = 2) {
  if (objektMitDezimalzahl) {
    if (Array.isArray(feldnamen)) {
      feldnamen.forEach(name => {
        if (typeof (objektMitDezimalzahl[name]) === 'string') {
          objektMitDezimalzahl[name] = parseFloat(objektMitDezimalzahl[name]).toFixed(precision);
        }
      });
    } else {
      if (typeof (objektMitDezimalzahl[feldnamen]) === 'string') {
        objektMitDezimalzahl[feldnamen] = parseFloat(objektMitDezimalzahl[feldnamen]).toFixed(precision);
      }
    }
  }
  return objektMitDezimalzahl;
}

export function setzePageTitel(titel) {
  window.title = titel + ' - clubcorner.ch';
  const el = document.getElementById('content-titel');
  if (el) {
    el.innerText = titel;
  }
}

export function ladeLocale() {
  return document.querySelector('meta[name="locale"]').getAttribute('content');
}

export function ladeCsrfSettings() {
  const csrf = {
    param: document.querySelector('meta[name="csrf-param"]').getAttribute('content'),
    token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
  };
  return csrf;
}
