import React, {useState} from 'react'
import {connect} from "react-redux";
import {spielerEreignisErstellen, staffEreignisErstellen} from "../state/modules/spielverlauf";
import {EREIGNIS_SUBTYP, EREIGNIS_TYP} from "../lib/spielablauf";

const StaffbankMenu = ({spieler, staff, team, hideMenu, ereignisse, spiel, heimTeamId, texte, spielerEreignisErstellen, staffEreignisErstellen}) => {
  const [selectedEreignis, setSelectedEreignis] = useState(null);
  const [letztesZwischenresultat, setLetztesZwischenresultat] = useState(spiel.zwischenresultat);

  const onEreignisClick = (event, ereignis) => {
    event.stopPropagation();

    if (selectedEreignis === ereignis.id) {
      setSelectedEreignis(null);
    } else {
      setSelectedEreignis(ereignis.id);
    }
  };

  const onSubaktionClick = (ereignisTyp, ereignisSubtyp, zusatzdaten = {}) => {
    if (spieler) {
      spielerEreignisErstellen(spieler, ereignisTyp, ereignisSubtyp, team, zusatzdaten);
    } else if (staff) {
      staffEreignisErstellen(staff, ereignisTyp, ereignisSubtyp, team);
    } else {
      console.error('Hier muss ein Spieler oder ein Staff vorhanden sein.');
    }
    hideMenu();
  };

  const ereignisTypTitel = (ereignisTyp) => {
    if (ereignisTyp.id === EREIGNIS_TYP.tor) {
      return `${ereignisTyp.title} (${texte.tor_zum} ${erstelleNaechstesZwischenresultat()})`;
    } else {
      return ereignisTyp.title;
    }
  };

  const ereignisSubtypTitel = (ereignisSubtyp) => {
    if (ereignisSubtyp.id === EREIGNIS_SUBTYP.eigentor) {
      return `${ereignisSubtyp.title} (${texte.tor_zum} ${erstelleNaechstesZwischenresultat(ereignisSubtyp)})`;
    } else {
      return ereignisSubtyp.title;
    }
  };

  const erstelleNaechstesZwischenresultat = (ereignisSubtyp = null) => {
    let resultat = [letztesZwischenresultat.heim, letztesZwischenresultat.gast];
    if (ereignisSubtyp && ereignisSubtyp.id === EREIGNIS_SUBTYP.eigentor) {
      team.id === heimTeamId ? resultat[1]++ : resultat[0]++;
    } else {
      team.id === heimTeamId ? resultat[0]++ : resultat[1]++;
    }
    return resultat.join(':');
  };

  return (
    <ul className="menu ereignisMenu">
      {
        ereignisse.map((ereignisTyp) => {
          let subactions = null;
          const zeigeSubmenu = selectedEreignis === ereignisTyp.id;
          if (zeigeSubmenu) {
            subactions = ereignisTyp.subactions;
          }

          return (
            <li key={ereignisTyp.id} onClick={(e) => onEreignisClick(e, ereignisTyp)}>
              {ereignisTypTitel(ereignisTyp)}
              {zeigeSubmenu && subactions &&
              <ul className="menu ereignisSubmenu">
                {
                  subactions.map((ereignisSubtyp) => {
                    let zusatzdaten = selectedEreignis === EREIGNIS_TYP.tor ? { zwischenresultat: erstelleNaechstesZwischenresultat(ereignisSubtyp) } : {};
                    return <li key={ereignisSubtyp.id} onClick={() => onSubaktionClick(ereignisTyp, ereignisSubtyp, zusatzdaten)}>{ereignisSubtypTitel(ereignisSubtyp)}</li>;
                  })
                }
              </ul>
              }
            </li>
          );
        })
      }
    </ul>
  );
};

const mapStateToProps = (state) => {
  return {
    spiel: state.heartbeat.spiel,
    heimTeamId: state.heim.team.id,
    texte: state.i18n.spieler_menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    spielerEreignisErstellen: (spieler, ereignisTyp, ereignisSubtyp, team, zusatzdaten) => { dispatch(spielerEreignisErstellen(spieler, ereignisTyp, ereignisSubtyp, team, zusatzdaten))},
    staffEreignisErstellen: (staff, ereignis, subaction, team) => { dispatch(staffEreignisErstellen(staff, ereignis, subaction, team))},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffbankMenu);
