// @flow

import type {AppState, EinsatzState, TeamState, TrainingState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";
import TextfeldZeile from "../TextfeldZeile";
import NoteDropdown from "../NoteDropdown";

import {trainingTeamNoteSetzen, trainingTeamKommentarSetzen, trainingTeamBemerkungenSetzen} from "../../state/modules/training";

const Trainingsnoten = (props: {
  einsatz: EinsatzState,
  training: TrainingState,
  teams: TeamState,
  i18n: any,
  trainingTeamNoteSetzen: ((string) => void),
  trainingTeamKommentarSetzen: ((string) => void),
  trainingTeamBemerkungenSetzen: ((string) => void)
}) => {
  return (
    <table className="table table-striped">
      <tbody>
      <tr className="control-group">
        <th>
          <label className="control-label" htmlFor="trainingTeamNote" style={{width: '170px'}}>
            {props.i18n.felder.trainingTeamNote}
          </label>
        </th>
        <td>
          <div className="controls">
            <NoteDropdown feldName="trainingTeamNote" feldWert={props.training.trainingTeamNote}
                          requestDaten={{trainingId: props.training.id}} onValueChange={(neuerWert) => {
              props.trainingTeamNoteSetzen(neuerWert);
            }}/>
          </div>
        </td>
      </tr>
      <TextfeldZeile feldTitel={props.i18n.felder.trainingTeamKommentar} feldName="trainingTeamKommentar" feldWert={props.training.trainingTeamKommentar}
                     requestDaten={{trainingId: props.training.id}} onChange={(event) => {
        props.trainingTeamKommentarSetzen(event.target.value);
      }}/>
      <TextfeldZeile feldTitel={props.i18n.felder.trainingTeamBemerkungen} feldName="trainingTeamBemerkungen" feldWert={props.training.trainingTeamBemerkungen}
                     requestDaten={{trainingId: props.training.id}} onChange={(event) => {
        props.trainingTeamBemerkungenSetzen(event.target.value);
      }}/>
      </tbody>
    </table>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    training: state.training,
    teams: state.teams,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    trainingTeamNoteSetzen: (neuerWert: string) => {
      dispatch(trainingTeamNoteSetzen(neuerWert));
    },
    trainingTeamKommentarSetzen: (neuerWert: string) => {
      dispatch(trainingTeamKommentarSetzen(neuerWert));
    },
    trainingTeamBemerkungenSetzen: (neuerWert: string) => {
      dispatch(trainingTeamBemerkungenSetzen(neuerWert));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Trainingsnoten);
