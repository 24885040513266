import React from "react";
import {connect} from "react-redux";
import Popover from "../../../components/Popover";
import {formatiereBetrag, formatiereDatum} from "../../../lib/formatierungen";

const farbeFuerStatus = (status) => {
  switch (status) {
    case 'draft':
      return 'info';
    case 'pending':
    case 'partial':
      return 'warning';
    case 'paid':
      return 'success';
    case 'canceled':
    case 'unpaid':
      return 'danger';
  }
  return 'default';
};

const BexioStatusLabel = ({rechnung, locale, texte}) => {
  const text = texte.bexio.status[rechnung.bexioStatus] || '';
  const rechnungsTexte = texte.rechnungen;

  if (rechnung.bexioReferenzId === null || text === '') {
    return <></>;
  }
  
  const statusLabel = <span className={`label label-${farbeFuerStatus(rechnung.bexioStatus)}`}>{text}</span>;
  
  const statusContent = () => {
    const bexio = rechnung.bexio;
    const hr = <hr style={{margin: '8px 0px'}}/>;
    return (
      <>
        <p>
          {rechnungsTexte.rechnungsstatus}: {statusLabel}
          {bexio.freigegeben &&
          <>
            <br/>
            {rechnungsTexte.freigabedatum}: {formatiereDatum(bexio.freigabedatum)}
          </>
          }
          {bexio.bezahlt &&
          <>
            <br/>
            {rechnungsTexte.bezahldatum}: {formatiereDatum(bexio.bezahldatum)}
          </>
          }
        </p>
        {bexio.teilbezahlt &&
        <>
          {hr}
          <b>{rechnungsTexte.teilzahlung}</b>
          <p>
            {rechnungsTexte.restbetragTeilzahlung}: {formatiereBetrag(bexio.restbetrag, locale)}
            <br/>
            {rechnungsTexte.teilzahldatum}: {formatiereDatum(bexio.teilzahldatum)}
          </p>
        </>
        }
        {bexio.mahnung &&
        <>
          {hr}
          <b>{rechnungsTexte.mahnungen.titel}</b>
          <p>
            {rechnungsTexte.mahnungen.mahnstufe}: {bexio.mahnstufe}
            <br/>
            {rechnungsTexte.mahnungen.mahndatum}: {formatiereDatum(bexio.mahndatum)}
          </p>
        </>
        }
      </>
    );
  };

  return (
    <div>
      <Popover tagName={'div'} title={rechnungsTexte.rechnungstitel} content={statusContent()} className={'text-nowrap'}>
        {statusLabel}
        {rechnung.bexio && rechnung.bexio.mahnung && rechnung.bexio.bezahlt === false &&
        <>&nbsp;<span className="icon-warning-sign"/></>
        }
      </Popover>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    locale: state.i18n.locale,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BexioStatusLabel);

