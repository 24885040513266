import React from "react";
import {connect} from "react-redux";
import Table from "./Table";
import {vorlageDatenEdit} from "../../state/modules/vorlagenDaten";

const Liste = ({vorlagen, texte, vorlageDatenEdit}) => {

  const vorlagenAktiv = vorlagen.filter(v => !v.inaktiv);
  const vorlagenInaktiv = vorlagen.filter(v => v.inaktiv);

  const neueVorlageClick = () => {
    vorlageDatenEdit({texte: []});
  };

  return (
    <>
      <h2 className="page-header">
        {texte.titel}
        <div className="pull-right">
          <button type="button" className="btn" onClick={neueVorlageClick}>
            <i className="icon-plus-sign"/>
            {texte.neueVorlage}
          </button>
        </div>
      </h2>

      {vorlagenAktiv.length > 0 &&
      <>
        <h4 className="page-header">
          {`${texte.titelAktiv} (${vorlagenAktiv.length})`}
        </h4>
        <Table vorlagen={vorlagenAktiv}/>
      </>
      }

      {vorlagenInaktiv.length > 0 &&
      <>
        <h4 className="page-header">
          {`${texte.titelInaktiv} (${vorlagenInaktiv.length})`}
        </h4>
        <Table vorlagen={vorlagenInaktiv}/>
      </>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    vorlagen: state.vorlagen,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    vorlageDatenEdit: (vorlage) => { dispatch(vorlageDatenEdit(vorlage)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Liste);

