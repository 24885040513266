import React, {useState} from 'react';
import {connect} from "react-redux";
import IconToggle from "./IconToggle";
import AusbildnerAbschluss from "./AusbildnerAbschluss";
import PersonIcon from "../../../components/PersonIcon";
import AusbildungStatusAbschluss from "./AusbildungStatusAbschluss";
import {AusbildungStatus} from "../lib/constants";
import {ausbildungSpeichern} from "../state/ausbildnerReducer";
import TeamTeilnehmerAbschluss from "./TeamTeilnehmerAbschluss";
import {sortiereTeam} from "../lib/formatierungen";

const TeamAbschluss = ({ausbildung, ausbildnerListe, teamTeilnehmer, hatPruefungErgebnis, abschlussStatusListe, teams, texte, ausbildungSpeichern}) => {
  const [filter, setFilter] = useState([]);
  const [showDetails, setShowDetails] = useState([]);
  const [statusId, setStatusId] = useState(AusbildungStatus.Erfuellt);
  const sichtbareStatus = [...abschlussStatusListe.map(s => s.id), AusbildungStatus.Angemeldet, AusbildungStatus.Anwesend];
  const ausbildnerListeFiltered = ausbildnerListe.filter((a) => (filter.length > 0 ? filter : sichtbareStatus).includes(a.ausbildungStatusId));

  const toggleFilter = (statusId) => {
    if (filter.includes(statusId)) {
      setFilter(filter.filter(id => id !== statusId));
    } else {
      setFilter([...filter, statusId]);
    }
  };

  const toggleAusbildnerDetails = (ausbildnerId) => {
    if (showDetails.includes(ausbildnerId)) {
      setShowDetails(showDetails.filter(id => id !== ausbildnerId));
    } else {
      setShowDetails([...showDetails, ausbildnerId]);
    }
  };

  const alleSpeichern = () => {
    ausbildnerListeFiltered.forEach(ausbildner => {
      ausbildungSpeichern(ausbildner, statusId);
    });
  };

  // unterscheidung: ergebnis (tblTrainerErgebnis) oder alle ausbildungen abschliessen

  return <>
    <div className="block">
      <div className="header btn-liste">
        {texte.kursleiter.filterAusbildungStatus}
        <button className={`btn btn-small ${filter.includes(AusbildungStatus.Angemeldet) ? 'btn-success' : ''}`} onClick={() => toggleFilter(AusbildungStatus.Angemeldet)}>{texte.status.angemeldet}</button>
        <button className={`btn btn-small ${filter.includes(AusbildungStatus.Anwesend) ? 'btn-success' : ''}`} onClick={() => toggleFilter(AusbildungStatus.Anwesend)}>{texte.status.anwesend}</button>
        {abschlussStatusListe.map(status => <button key={status.id} className={`btn btn-small ${filter.includes(status.id) ? 'btn-success' : ''}`} onClick={() => toggleFilter(status.id)}>{status.name}</button>)}
      </div>
    </div>
    {ausbildung.bearbeitbar && !hatPruefungErgebnis &&
    <div className="block">
      <div className="header">
        <b>{texte.teilnehmerAbschluss.alleAbschliessen}</b>
      </div>
      <div className="content">
        <div className="edit">
          <div className="zeile">
            <label htmlFor="ausbildungStatus">{texte.teilnehmerAbschluss.ausbildungStatus}</label>
            <select id="ausbildungStatus" className='select' value={statusId} onChange={(ev) => setStatusId(ev.currentTarget.value)}>
              {abschlussStatusListe.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
            </select>
          </div>
          <div className="zeile">
            <label/>
            <button className="btn btn-primary" onClick={alleSpeichern}>{texte.speichern}</button>
          </div>
        </div>
      </div>
    </div>
    }
    {ausbildnerListeFiltered.map(ausbildner => {
      const teilnehmer = teamTeilnehmer
        .filter(tt => tt.ausbildner.id === ausbildner.id)
        .sort((a, b) => {
          const teamA = teams.find(t => t.id === a.teamId);
          const teamB = teams.find(t => t.id === b.teamId);
          return sortiereTeam(teamA, teamB);
        });
      return (
        <div key={ausbildner.id} className="block">
          <div className="header">
            <span className="pull-right">
              <button className="btn btn-mini" onClick={() => toggleAusbildnerDetails(ausbildner.id)}>
                {texte.ausbildnerAbschluss}
                <IconToggle istOffen={showDetails.includes(ausbildner.id)}/>
              </button>
            </span>
            <b>{ausbildner.name}</b>
            <PersonIcon person={ausbildner}/>
            <span className="label-liste">
              <span className="label label-default">{ausbildner.ausbildungStatusText}</span>
              {hatPruefungErgebnis && ausbildner.ergebnis.beurteilungAbgeschlossen &&
              <span className={`label label-important`}>{texte.teilnehmerAbschluss.abgeschlossen}</span>
              }
            </span>
          </div>
          {showDetails.includes(ausbildner.id) &&
          <>
            {hatPruefungErgebnis &&
            <AusbildnerAbschluss ausbildner={ausbildner}/>
            }
            {ausbildung.bearbeitbar && !hatPruefungErgebnis &&
            <AusbildungStatusAbschluss ausbildner={ausbildner}/>
            }
            <div className="content">
              <div className="zeile">
                {teilnehmer.map(tt => <TeamTeilnehmerAbschluss key={tt.id} teamTeilnehmer={tt}/>)}
              </div>
            </div>
          </>
          }
        </div>
      );
    })}
  </>;
};

const mapStateToProps = (state) => {
  return {
    ausbildung: state.ausbildung,
    ausbildnerListe: state.ausbildner,
    teamTeilnehmer: state.teamTeilnehmer,
    hatPruefungErgebnis: state.ausbildung.hatPruefungErgebnis,
    abschlussStatusListe: state.abschlussStatusListe,
    teams: state.teams,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ausbildungSpeichern: (ausbildner, statusId) => { dispatch(ausbildungSpeichern(ausbildner, statusId)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamAbschluss);
