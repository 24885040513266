// @flow

import type {AppState, EinsatzState, ProtokollState, TrainingState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import Tor from "./eintraege/Tor";
import AllgemeinerEintrag from "./eintraege/AllgemeinerEintrag";
import Verwarnung from "./eintraege/readonly/Verwarnung";
import Ausschluss from "./eintraege/readonly/Ausschluss";
import AusEinwechslung from "./eintraege/readonly/AusEinwechslung";
import Bemerkung from "./eintraege/readonly/Bemerkung";
import Unfall from "./eintraege/readonly/Unfall";
import Zeitstrafe from "./eintraege/readonly/Zeitstrafe";

const ProtokollEintraege = (props: {
  einsatz: EinsatzState,
  training: TrainingState,
  protokoll: ProtokollState,
  i18n: any,
  pfade: any,
}) => {
  const aktiv = !props.einsatz.readonly;

  if (props.protokoll.liste.length > 0) {
    return (
      <div className="tab-pane">
        <table className="table table-striped">
          <tbody>
          {
            props.protokoll.liste.map((eintrag) => {
              switch (eintrag.art) {
                case "spielereignis":
                  switch (eintrag.aktion) {
                    case "tor":
                      return <Tor key={eintrag.key}
                                  eintrag={eintrag}
                                  iconPfad={props.pfade.icons.tor}
                                  i18nTitel={props.i18n.eintraege.voll.tor}
                                  readonly={!aktiv}/>;
                    case "assist":
                      return <AllgemeinerEintrag key={eintrag.key}
                                                 eintrag={eintrag}
                                                 iconPfad={props.pfade.icons.assist}
                                                 i18nTitel={props.i18n.eintraege.voll.assist}
                                                 textfeldRendern={false}
                                                 readonly={!aktiv}/>;
                    case "eigentor":
                      return <Tor key={eintrag.key}
                                  eintrag={eintrag}
                                  iconPfad={props.pfade.icons.eigentor}
                                  i18nTitel={props.i18n.eintraege.voll.eigentor}
                                  readonly={!aktiv}/>;
                    default:
                      let iconPfad = props.pfade.icons[eintrag.aktion];
                      let i18nTitel = props.i18n.eintraege.voll[eintrag.aktion];
                      return <AllgemeinerEintrag key={eintrag.key}
                                                 eintrag={eintrag}
                                                 iconPfad={iconPfad}
                                                 i18nTitel={i18nTitel}
                                                 readonly={!aktiv}/>
                  }
                case "spielverlauf":
                  switch (eintrag.aktion) {
                    case "tor":
                      return <Tor key={eintrag.key}
                                  eintrag={eintrag}
                                  iconPfad={props.pfade.icons.tor}
                                  i18nTitel={props.i18n.eintraege.voll.tor}
                                  readonly={true}/>;
                    case "assist":
                      return <AllgemeinerEintrag key={eintrag.key}
                                                 eintrag={eintrag}
                                                 iconPfad={props.pfade.icons.assist}
                                                 i18nTitel={props.i18n.eintraege.voll.assist}
                                                 textfeldRendern={false}
                                                 readonly={true}/>;
                    case "eigentor":
                      return <Tor key={eintrag.key}
                                  eintrag={eintrag}
                                  iconPfad={props.pfade.icons.eigentor}
                                  i18nTitel={props.i18n.eintraege.voll.eigentor}
                                  readonly={true}/>;
                    case "verwarnung":
                      return <Verwarnung key={eintrag.key}
                                         eintrag={eintrag}
                                         iconPfad={props.pfade.icons.verwarnung}
                                         i18nTitel={props.i18n.eintraege.voll.verwarnung}
                                         readonly={true}/>;
                    case "ausschluss":
                      return <Ausschluss key={eintrag.key}
                                         eintrag={eintrag}
                                         iconPfad={props.pfade.icons.ausschluss}
                                         i18nTitel={props.i18n.eintraege.voll.ausschluss}
                                         readonly={true}/>;
                    case "auseinwechslung":
                      return <AusEinwechslung key={eintrag.key}
                                              eintrag={eintrag}
                                              iconPfad={props.pfade.icons.auseinwechslung}
                                              i18nTitel={props.i18n.eintraege.voll.auseinwechslung}
                                              readonly={true}/>;
                    case "bemerkung":
                      return <Bemerkung key={eintrag.key}
                                        eintrag={eintrag}
                                        iconPfad={props.pfade.icons.bemerkung}
                                        i18nTitel={props.i18n.eintraege.voll.bemerkung}
                                        readonly={true}/>;
                    case "elfmeter_tor":
                      return <Tor key={eintrag.key}
                                  eintrag={eintrag}
                                  iconPfad={props.pfade.icons.penaltyTor}
                                  i18nTitel={props.i18n.eintraege.voll.elfmeterTor}
                                  readonly={true}/>;
                    case "elfmeter_kein_tor":
                      return <Tor key={eintrag.key}
                                  eintrag={eintrag}
                                  iconPfad={props.pfade.icons.penaltyKeinTor}
                                  i18nTitel={props.i18n.eintraege.voll.elfmeterKeinTor}
                                  readonly={true}/>;
                    case "strafe":
                      return <Verwarnung key={eintrag.key}
                                         eintrag={eintrag}
                                         iconPfad={props.pfade.icons.strafe}
                                         i18nTitel={props.i18n.eintraege.voll.strafe}
                                         readonly={true}/>;
                    case "unfall":
                      return <Unfall key={eintrag.key}
                                     eintrag={eintrag}
                                     iconPfad={props.pfade.icons.unfall}
                                     i18nTitel={props.i18n.eintraege.voll.unfall}
                                     readonly={true}/>;
                    case "zeitstrafe":
                      return <Zeitstrafe key={eintrag.key}
                                         eintrag={eintrag}
                                         iconPfad={props.pfade.icons.zeitstrafe}
                                         i18nTitel={props.i18n.eintraege.voll.zeitstrafe}
                                         readonly={true}/>;
                    default:
                      let iconPfad = props.pfade.icons[eintrag.aktion];
                      let i18nTitel = props.i18n.eintraege.voll[eintrag.aktion];
                      return <AllgemeinerEintrag key={eintrag.key}
                                                 eintrag={eintrag}
                                                 iconPfad={iconPfad}
                                                 i18nTitel={i18nTitel}
                                                 readonly={true}/>
                  }
                default:
                  let iconPfad = props.pfade.icons[eintrag.art];
                  let i18nTitel = props.i18n.eintraege.voll[eintrag.art];
                  return <AllgemeinerEintrag key={eintrag.key}
                                             eintrag={eintrag}
                                             iconPfad={iconPfad}
                                             i18nTitel={i18nTitel}
                                             spielerRendern={false}
                                             readonly={!aktiv}/>
              }
            })
          }
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div className="tab-pane">
        <table className="table table-striped">
          <tbody>
          <tr>
            <td style={{textAlign: "center"}}>
              {props.i18n.eintraege.keineEintraege}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    training: state.training,
    protokoll: state.protokoll,
    i18n: state.i18n,
    pfade: state.pfade,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProtokollEintraege);
