const FEHLER_UPDATE = 'FEHLER_UPDATE';

export function fehlerUpdate(fehler) {
  return (dispatch) => {
    dispatch({type: FEHLER_UPDATE, fehler: fehler});
  };
}

function fehlerReducer(state = {}, action) {
  switch (action.type) {
    case FEHLER_UPDATE:
      return action.fehler;
    default:
      return state;
  }
}

export default fehlerReducer;
