// @flow

import React, {useEffect} from "react";
import {Provider} from "react-redux"
import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";

import reducer from "./state/reducer";
import {datenInitialLaden} from "./state/datenLaden";
import {tipsLocksTimerStarten} from "./state/modules/spieler";

import Fehlermeldungen from "./components/Fehlermeldungen";
import TeamHeader from "./components/TeamHeader";
import Navigation from "./components/Navigation";
import ContentRouter from "./components/Navigation/ContentRouter";

import type {ServerDaten} from "./state/stateTypes";

const ScoutingEinsatz = (props: ServerDaten) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducer,
    datenInitialLaden(props),
    composeEnhancers(applyMiddleware(thunk))
  );

  useEffect(() => {
    store.dispatch(tipsLocksTimerStarten());
  }, []);

  return (
    <div className="scouting-bericht">
      <Provider store={store}>
        <Fehlermeldungen/>
        <TeamHeader/>
        <Navigation/>
        <ContentRouter/>
      </Provider>
    </div>
  );
};

export default ScoutingEinsatz
