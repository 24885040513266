// @flow

import type {
  AppState,
  EinsatzState,
  SchiedsrichterState,
  SpielState,
  Team,
  TeamState,
  TrainingState,
  TurnierState,
  VerantwortlicheState
} from "../../state/stateTypes";
import type { Dispatch } from "../../state/actionTypes";

import React from "react";
import { connect } from "react-redux";

const teamLogo = (team) => {
  return <div className={"team-" + team.id}
              style={{ height: '50px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
    <div className="emblem">
      <div className="avatar pull-left">
        <img src={team.logo} style={{ width: '34px' }} alt={team.id}/>
      </div>
      <div className="pull-left">
        <div className="title">{team.name}</div>
        <div className="subtitle">{team.liga}</div>
        <div className="subtitle">{team.spielklasse}</div>
      </div>
    </div>
    <div className="clearfix"/>
  </div>;
};

const personInfos = (person, i18n) => (
  <>
    <td>
      <div className="emblem" rel="tooltip" data-content-selector=".hide" data-content-childselector="true"
           data-placement="left">
        <div className="hide bild">
          <img src={person.bildUrl} alt={person.personName}/>
        </div>
        <img className="avatar" src={person.bildUrl} style={{ width: 34 }} alt={person.personName}/>
      </div>
    </td>
    <td>
      <strong>{person.funktion}</strong>
    </td>
    <td>
      {person.personName}
      {person.diplome.map((diplom, index) => {
        return <div key={index}><em style={{ fontSize: 'smaller' }}>{diplom}</em></div>
      })}
    </td>
    <td>
      {
        ((person.mailAdressen.length > 0) || (Object.keys(person.telefonNummern).length > 0)) &&
        <ul style={{ marginBottom: 0 }}>
          {
            person.mailAdressen.map((adresse) => {
              return <li key={adresse} style={{ fontSize: 'smaller' }}>
                <a href={'mailto:' + adresse}>{adresse}</a>
              </li>;
            })
          }
          {
            Object.keys(person.telefonNummern).map((telefonTyp) => {
              let telefon: any = person.telefonNummern[telefonTyp];
              return <li key={telefonTyp} style={{ fontSize: 'smaller' }}>
                {i18n.felder[telefonTyp]}: <a href={'tel:' + telefon}>{telefon}</a>
              </li>;
            })
          }
        </ul>
      }
    </td>
  </>
);

const trainerInfos = (team, i18n) => team.trainers.map((trainer, trainerIndex) => {
  return <tr key={trainer.personId}>
    {
      trainerIndex === 0 && <td rowSpan={Math.max(1, team.trainers.length)} style={{ padding: '10px 0' }}>
        {teamLogo(team)}
      </td>
    }
    {
      personInfos(trainer, i18n)
    }
  </tr>;
});

const Kontakt = (props: {
  einsatz: EinsatzState,
  teams: TeamState,
  spiel: SpielState,
  training: TrainingState,
  turnier: TurnierState,
  schiedsrichterliste: SchiedsrichterState,
  verantwortlichenliste: VerantwortlicheState,
  i18n: any,
}) => {
  let eigenesTeam: Team = props.teams.liste[0];
  let heimTeam: Team = props.teams.liste[0];
  let gastTeam: Team = props.teams.liste[0];
  let andereTeams: Team[] = props.teams.liste;
  if(props.einsatz.typ === 'spiel') {
    props.teams.liste.forEach((teamEintrag: Team) => {
      andereTeams = [];
      if(teamEintrag.id === props.spiel.heimTeamId) {
        heimTeam = teamEintrag;
      } else if(teamEintrag.id === props.spiel.gastTeamId) {
        gastTeam = teamEintrag;
      } else {
        andereTeams.push(teamEintrag);
      }
    });
  } else if(props.einsatz.typ === 'turnier') {
    andereTeams = [];
    props.teams.liste.forEach((teamEintrag: Team) => {
      if(teamEintrag.id === props.turnier.teamId) {
        eigenesTeam = teamEintrag;
      } else {
        andereTeams.push(teamEintrag);
      }
    });
  } else if(props.einsatz.typ === 'training') {
    andereTeams = [];
    props.teams.liste.forEach((teamEintrag: Team) => {
      if(teamEintrag.id === props.training.teamId) {
        eigenesTeam = teamEintrag;
      } else {
        andereTeams.push(teamEintrag);
      }
    });
  }
  let heimGastTrainerSegment = <></>;
  if(props.einsatz.typ === 'spiel') {
    heimGastTrainerSegment = <>
      <section><h2 className="page-section">{props.i18n.subtitel.heimGastTrainer}</h2></section>
      <table className={'table table-condensed'}>
        <tbody>
        {trainerInfos(heimTeam, props.i18n)}
        {trainerInfos(gastTeam, props.i18n)}
        </tbody>
      </table>
    </>
  }
  let hauptTeamTrainerSegment = <></>;
  if(props.einsatz.typ !== 'spiel') {
    hauptTeamTrainerSegment = <>
      <section><h2 className="page-section">{props.i18n.subtitel.eigeneTrainer}</h2></section>
      <table className={'table table-condensed'}>
        <tbody>
        {trainerInfos(eigenesTeam, props.i18n)}
        </tbody>
      </table>
    </>;
  }
  let andereTeamsTrainerSegment = <></>;
  if(andereTeams.length > 0) {
    andereTeamsTrainerSegment = <>
      <section><h2 className="page-section">{props.i18n.subtitel.andereTrainer}</h2></section>
      <table className={'table table-condensed'}>
        <tbody>
        {
          andereTeams.map((anderesTeam: Team) => {
            return trainerInfos(anderesTeam, props.i18n);
          })
        }
        </tbody>
      </table>
    </>;
  }
  let schiedsrichterSegment = <></>;
  if (props.schiedsrichterliste.liste !== null) {
    schiedsrichterSegment = <>
      <section><h2 className="page-section">{props.i18n.subtitel.schiedsrichter}</h2></section>
      <table className={'table table-condensed'}>
        <tbody>
        {
          props.schiedsrichterliste.liste.map((schiedsrichter, index) => {
            return (<tr key={index}>
              {
                personInfos(schiedsrichter, props.i18n)
              }
            </tr>);
          })
        }
        </tbody>
      </table>
    </>
  }
  let verantwortlicheSegment = <></>;
  if(props.verantwortlichenliste.liste.length > 0) {
    verantwortlicheSegment = <>
      <section><h2 className="page-section">{props.i18n.subtitel.verantwortliche}</h2></section>
      <table className={'table table-condensed'}>
        <tbody>
        {
          props.verantwortlichenliste.liste.map((verantwortlichePerson, index) => {
            return (<tr key={index}>
              {
                personInfos(verantwortlichePerson, props.i18n)
              }
            </tr>);
          })
        }
        </tbody>
      </table>
    </>
  }
  return (<>
    {schiedsrichterSegment}
    {verantwortlicheSegment}
    {heimGastTrainerSegment}
    {hauptTeamTrainerSegment}
    {andereTeamsTrainerSegment}
  </>);
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    teams: state.teams,
    spiel: state.spiel,
    turnier: state.turnier,
    training: state.training,
    schiedsrichterliste: state.schiedsrichterliste,
    verantwortlichenliste: state.verantwortlichenliste,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Kontakt);
