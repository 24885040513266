import React, {useState} from "react";
import {connect} from "react-redux";
import Mirwald from "./Mirwald";
import Sportmotorik from "./Sportmotorik";
import Sportspezifisch from "./Sportspezifisch";

const VIEWS = {
  mirwald: 'mirwald',
  sportmotorik: 'motorik',
  sportspezifisch: 'spezifisch',
};

const PHASE = {
  vorrunde: 1,
  rueckrunde: 2
};

const Ansicht = ({team, api, texte}) => {
  const [ansicht, setAnsicht] = useState(VIEWS.mirwald);
  const [phase, setPhase] = useState(PHASE.vorrunde);

  const navClick = (event, neueAnsicht) => {
    event.stopPropagation();
    event.preventDefault();
    setAnsicht(neueAnsicht);
  };

  const phaseClick = (event, neuePhase) => {
    event.target.blur();
    setPhase(neuePhase);
  };

  return (
    <>
      <div>
        {team.ist_pisteteam === false &&
        <ul className="nav nav-tabs">
          <li className={ansicht === VIEWS.mirwald ? 'active' : ''}>
            <a href="" onClick={(ev) => navClick(ev, VIEWS.mirwald)}>{texte.tabs.mirwald}</a>
          </li>
          <li className={ansicht === VIEWS.sportmotorik ? 'active' : ''}>
            <a href="" onClick={(ev) => navClick(ev, VIEWS.sportmotorik)}>{texte.tabs.sportmotorik}</a>
          </li>
          <li className={ansicht === VIEWS.sportspezifisch ? 'active' : ''}>
            <a href="" onClick={(ev) => navClick(ev, VIEWS.sportspezifisch)}>{texte.tabs.sportspezifisch}</a>
          </li>
        </ul>
        }
        <div>
          <div className="btn-group">
            <button className={`btn ${phase === PHASE.vorrunde ? 'btn-primary' : ''}`} onClick={(ev) => phaseClick(ev, PHASE.vorrunde)}>{texte.phasen.vorrunde}</button>
            <button className={`btn ${phase === PHASE.rueckrunde ? 'btn-primary' : ''}`} onClick={(ev) => phaseClick(ev, PHASE.rueckrunde)}>{texte.phasen.rueckrunde}</button>
          </div>
          {ansicht === VIEWS.mirwald &&
          <a className="pull-right icon-pdf" href={`${api.mirwald}.pdf`}></a>
          }
        </div>
      </div>
      <h2 className="page-header">{team.bezeichnung}</h2>
      {ansicht === VIEWS.mirwald &&
      <Mirwald phase={phase}/>
      }
      {ansicht === VIEWS.sportmotorik &&
      <Sportmotorik phase={phase}/>
      }
      {ansicht === VIEWS.sportspezifisch &&
      <Sportspezifisch phase={phase}/>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    team: state.team,
    api: state.api,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Ansicht);
