export function datenAbfragen(settings, seiteNummer, anzahlEintraege, callback) {
  const getDaten = [
    {name: 'page', value: seiteNummer},
    {name: 'limit', value: anzahlEintraege}
  ];
  executeRequest(settings.methode, settings.token, 'GET', getDaten, callback);
}

export function eintraegeAbwesendUpdate(settings, eintraege, gruende, spielerId, callback) {
  const postDaten = {
    spielerId: spielerId,
    eintraege: eintraege.map(e => ({id: e.id, typ: e.typ})),
    gruende: gruende
  };
  executeRequest(settings.methode, settings.token, 'POST', postDaten, callback);
}

export function eintraegeAbwesendDelete(settings, eintraege, spielerId, callback) {
  const deleteDaten = {
    spielerId: spielerId,
    eintraege: eintraege.map(e => ({id: e.id, typ: e.typ}))
  };
  executeRequest(settings.methode, settings.token, 'DELETE', deleteDaten, callback);
}

function executeRequest(url, token, method, daten, callback) {
  let apiUrl = url;
  const fetchInit = {
    method: method,
    headers: {
      'x-requested-with': 'XMLHttpRequest',
      'content-type': 'application/json',
    },
    credentials: 'same-origin'
  };
  if (method === 'GET') {
    const params = new URLSearchParams();
    daten.forEach((d) => {
      params.append(d.name, d.value);
    });
    apiUrl = url + "?" + params.toString()
  } else {
    daten['authenticity_token'] = token;
    fetchInit.body = JSON.stringify(daten);
  }
  window.AjaxActivityIndicator.show();
  fetch(apiUrl, fetchInit)
    .then(response => {
      window.AjaxActivityIndicator.hide();
      let data = {};
      if (response.ok) {
        data = response.json();
      }
      return data;
    })
    .then(jsonResultat => {
      callback(jsonResultat);
    })
    .catch(fehler => {
      window.AjaxActivityIndicator.hide();
    });
}
