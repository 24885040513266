import React, { useState} from "react";
import {connect} from "react-redux";
import {executeRequest} from "../lib/fetchHelper";
import {formatiereBetrag} from "../../../lib/formatierungen";

const LeistungAuswahl = ({value = null, onLeistungChange, istEntfernbar = false, minChars = 0, api, texte, locale}) => {
  const [liste, setListe] = useState([]);
  const [index, setIndex] = useState(0);
  const [weitereResultate, setWeitereResultate] = useState(false);
  const [abfrageAktiv, setAbfrageAktiv] = useState(false);
  const [sucheTimeout, setSucheTimeout] = useState(null);

  const timeoutMiliseconds = 400; // TODO: passendes timeout für eingabe von hand finden

  const [leistung, setLeistung] = useState(value);
  const [bezeichnung, setBezeichnung] = useState('');
  const [showLeistungResultate, setShowLeistungResultate] = useState(false);
  
  let abortController = null;

  const zeigeResultate = (anzeigen) => {
    setShowLeistungResultate(anzeigen && liste.length > 0);
  };

  const leistungenSucheStarten = (suche, idx = 0) => {
    if (suche && suche.length >= minChars) {
      clearTimeout(sucheTimeout);
      const timeoutId = setTimeout(() => abfragen(suche, idx), timeoutMiliseconds);
      setSucheTimeout(timeoutId);
    }
  };

  const abfragen = (suche, idx) => {
    const daten = {
      bezeichnung: suche,
      index: idx
    };
    setAbfrageAktiv(true);
    setIndex(idx);
    if (abortController) {
      abortController.abort();
    }
    abortController = new AbortController();
    executeRequest(api.leistungen, api.token, 'GET', daten, { signal: abortController.signal })
      .then(result => {
        if (result.ok) {
          const data = result.data;
          if (idx > 0) {
            setListe(liste.concat(data.leistungen));
          } else {
            setListe(data.leistungen);
          }
          setWeitereResultate(data.weitereResultate);
          setShowLeistungResultate(data.leistungen.length > 0);
        }
        setAbfrageAktiv(false);
      }).catch(() => {
        // noop, aber drin wegen AbortController.abort()
      });
  };

  const selectLeistung = (l) => {
    zeigeResultate(false);
    setLeistung(l);
    onLeistungChange(l);
  };

  const renderLeistung = (l) => {
    if (l.betrag > 0) {
      return `${l.bezeichnung} (${formatiereBetrag(l.betrag, locale)})`;
    } else {
      return l.bezeichnung;
    }
  };

  const onScrollResultate = (event) => {
    if (weitereResultate && abfrageAktiv === false) {
      const target = event.currentTarget;
      if (target.scrollTop >= (target.scrollHeight - target.clientHeight)) {
        abfragen(bezeichnung, index + 1);
      }
    }
  };

  const auswahlEntfernen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    selectLeistung(null);
  };

  const onChangeBezeichnung = (text) => {
    setIndex(0);
    setBezeichnung(text);
    zeigeResultate(false);
    leistungenSucheStarten(text);
  };

  const onFocusBezeichnung = (ev) => {
    if (liste.length === 0) {
      leistungenSucheStarten(bezeichnung);
    }
  };

  const onClickBezeichnung = () => {
    zeigeResultate(!showLeistungResultate);
  };

  const onBlur = (event) => {
    zeigeResultate(false);
  };

  return (
    <div className="suchbereich">
      <div className="zeile text-nowrap">
        <input type="text" placeholder={texte.textsuche} onChange={ev => onChangeBezeichnung(ev.currentTarget.value)} onFocus={onFocusBezeichnung} onBlur={onBlur} onClick={onClickBezeichnung}/>
        {abfrageAktiv &&
        <span className="ajax-spinner-klein"/>
        }
      </div>
      {leistung &&
      <div className="zeile">
        <span className="bordered-box" onClick={() => zeigeResultate(!showLeistungResultate)} onBlur={onBlur} tabIndex={-1}>
          {renderLeistung(leistung)}
          {istEntfernbar &&
          <button type="button" className="close" onClick={auswahlEntfernen}>&times;</button>
          }
        </span>
      </div>
      }
      {showLeistungResultate > 0 &&
      <div className="suchresultat-container">
        <div className="suchresultat-liste" onScroll={onScrollResultate} onMouseDown={ev => ev.preventDefault()}>
          {liste.map(l => <div key={l.id} className={`item ${leistung && l.id == leistung.id ? 'selected' : ''}`} onClick={() => selectLeistung(l)}>{renderLeistung(l)}</div>)}
        </div>
      </div>
      }
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    api: state.api,
    texte: state.i18n.texte,
    locale: state.i18n.locale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LeistungAuswahl);
