const LAUFENDE_ABFRAGEN_START = 'LAUFENDE_ABFRAGEN_START';
const LAUFENDE_ABFRAGEN_ENDE = 'LAUFENDE_ABFRAGEN_ENDE';

export const requestTyp = {
  rechnungslauf: 'rechnungslauf',
  bexioCheckPersonen: 'bexioCheckPersonen',
  bexioCheckKonten: 'bexioCheckKonten',
  bexioCheckRechnungen: 'bexioCheckRechnungen',
  bexioCheckRechnungenStatus: 'bexioCheckRechnungenStatus',
  bexioTransfer: 'bexioTransfer',
}; 

export function startRequest(abfrageTyp) {
  return (dispatch) => {
    dispatch({type: LAUFENDE_ABFRAGEN_START, abfrageTyp: abfrageTyp});
  };
}

export function endeRequest(abfrageTyp) {
  return (dispatch) => {
    dispatch({type: LAUFENDE_ABFRAGEN_ENDE, abfrageTyp: abfrageTyp});
  };
}

function laufendeRequestsReducer(state = {}, action) {
  switch (action.type) {
    case LAUFENDE_ABFRAGEN_START:
      const temp = {...state};
      temp[action.abfrageTyp] = true;
      return temp;
    case LAUFENDE_ABFRAGEN_ENDE:
      const temp2 = {...state};
      temp2[action.abfrageTyp] = false;
      return temp2;
    default:
      return state;
  }
}

export default laufendeRequestsReducer;
