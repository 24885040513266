import React, {useState} from 'react'
import {connect} from "react-redux";
import EreignisIcon, {ICON_TYP} from "./EreignisIcon";
import Tooltip from "../../../components/Tooltip";
import {EREIGNIS_TYP} from "../lib/spielablauf";
import {ereignisEditStop} from "../state/modules/editMarkierer";
import {spielerEreignisLoeschen, spielerEreignisUpdate, staffEreignisUpdate} from "../state/modules/spielverlauf";
import Video from "../../../components/Video";

const TickerEreignisEdit = ({ereignis, nurZeitBearbeitbar, heim, gast, liga, mhaEreignisse, texte, editEreignisStop, spielerEreignisSpeichern, staffEreignisSpeichern, ereignisLoeschen}) => {
  const [spielerSelectValue, setSpielerSelectValue] = useState({id: ereignis.spieler_id, unregistriert: ereignis.unregistrierter_spieler});
  const [auswechselSelectValue, setAuswechselSelectValue] = useState({id: ereignis.auswechsel_spieler_id, unregistriert: ereignis.auswechsel_unregistrierter_spieler});
  const [staffSelectValue, setStaffSelectValue] = useState({id: ereignis.rolle_id, unregistriert: ereignis.unregistrierter_spieler});
  const [minuteValue, setMinuteValue] = useState(ereignis.minute);
  const [zusatzminuteValue, setZusatzminuteValue] = useState(ereignis.zusatzminute);
  const [sekundenValue, setSekundenValue] = useState(ereignis.sekunden);
  const [zeigeVideo, setZeigeVideo] = useState(false);

  // ereignisse mit film sind nicht löschbar, aber typ/subtyp muss geändert werden können:
  const [ereignisTypSubtyp, setEreignisTypSubtyp] = useState(`${ereignis.typ_id}:${ereignis.subtyp_id}`);

  // event handlers
  const zeigeVideoClick = (e) => {
    setZeigeVideo(!zeigeVideo);
    e.stopPropagation();
  };

  const onMinuteChange = (event) => {
    let minute = parseInt(event.target.value, 10);
    if (!isNaN(minute)) {
      // TODO: min / max werte prüfen?
      minute = Math.max(minute, 1);
      minute = Math.min(minute, 120);
      setMinuteValue(minute);
      if(!istZusatzminuteMoeglich(minute)) {
        setZusatzminuteValue(0);
      }
    }
  };

  const onZusatzminuteChange = (event) => {
    const zusatzminute = parseInt(event.target.value, 10);
    if (!isNaN(zusatzminute)) {
      setZusatzminuteValue(zusatzminute);
    }
  };

  const onSekundenChange = (event) => {
    let sekunden = parseInt(event.target.value, 10);
    if (!isNaN(sekunden)) {
      sekunden = Math.max(sekunden, 0);
      sekunden = Math.min(sekunden, 59);
      setSekundenValue(sekunden);
    }
  };

  const onSpielerSelectChange = (event) => {
    const [unregistriert, id] = event.target.value.split(':');
    setSpielerSelectValue({id: parseInt(id, 10), unregistriert: unregistriert === 'u'});
  };
  
  const onAuswechselSelectChange = (event) => {
    const [unregistriert, id] = event.target.value.split(':');
    setAuswechselSelectValue({id: parseInt(id, 10), unregistriert: unregistriert === 'u'});
  };
  
  const onStaffSelectChange = (event) => {
    const [unregistriert, id] = event.target.value.split(':');
    setStaffSelectValue({id: parseInt(id, 10), unregistriert: unregistriert === 'u'});
  };
  
  const onEreignisTypChange = (event) => {
    setEreignisTypSubtyp(event.target.value);
  };
  
  const onSpeichernClick = () => {
    let zusatzdaten = {};
    if (ereignis.hat_film) {
      const [typ, subtyp] = ereignisTypSubtyp.split(':');
      zusatzdaten.typ_id = parseInt(typ, 10);
      zusatzdaten.subtyp_id = parseInt(subtyp, 10);
      zusatzdaten.tore_neu_berechnen = true; // evt umstellung tor zu eigentor oder chance -> tore stimmen nicht mehr
    }
    if (ereignis.typ_id === EREIGNIS_TYP.auswechslung) {
      zusatzdaten.auswechsel_spieler_id = auswechselSelectValue.id;
      zusatzdaten.auswechsel_unregistrierter_spieler = auswechselSelectValue.unregistriert;
    }
    if (ereignis.spieler_id != null) {
      spielerEreignisSpeichern(ereignis, spielerSelectValue.id, spielerSelectValue.unregistriert, minuteValue, zusatzminuteValue, sekundenValue, zusatzdaten);
    } else if (ereignis.rolle_id != null) {
      staffEreignisSpeichern(ereignis, staffSelectValue.id, minuteValue, zusatzminuteValue, sekundenValue);
    }
  };
  
  const onLoeschenClick = () => {
    if (!nurZeitBearbeitbar && !ereignis.hat_film) {
      ereignisLoeschen(ereignis);
    }
  };

  // helpers
  const istZusatzminuteMoeglich = (minute) => {
    return liga.periodenEndeMinuten.indexOf(minute) >= 0;
  };
  const alleSpielerFuerTeam = (teamId) => {
    if (heim.team.id === teamId) {
      return heim.spieler;
    } else {
      return gast.spieler;
    }
  };

  const alleStaffFuerTeam = (teamId) => {
    if (heim.team.id === teamId) {
      return heim.spielerkarte.staffbank;
    } else {
      return gast.spielerkarte.staffbank;
    }
  };

  // rendering

  function erstelleSpielerKey(s) {
    // s ist spieler oder zustand-spieler oder key
    return `${(s.unregistriert || s.unregistrierter_spieler) ? 'u' : 'r'}:${s.id}`;
  }

  const renderSpielerOptions = (spielerListe) => {
    return spielerListe.map(s => {
      const key = erstelleSpielerKey(s);
      return <option key={key} value={key}>{[s.rueckennummer, s.name].filter(s => s).join(' ')}</option>;
    });
  };

  const renderSelect = (liste, selektiert, changeHandler) => {
    return (
      <select value={erstelleSpielerKey(selektiert)} onChange={changeHandler}>
        {Array.isArray(liste) &&
        <>
          {renderSpielerOptions(liste)}
        </>
        }
        {liste === null &&
        <>
          <optgroup label={heim.team.name}>
            {renderSpielerOptions(heim.spieler)}
          </optgroup>
          <optgroup label={gast.team.name}>
            {renderSpielerOptions(gast.spieler)}
          </optgroup>
        </>
        }
      </select>
    );
  };

  const renderSelectFuerAuswechsel = (ereignis) => {
    let start, ersatz = null;
    if (heim.team.id === ereignis.team_id) {
      start = heim.zustand.feldspieler;
      ersatz = heim.zustand.ersatzspieler;
    } else {
      start = gast.zustand.feldspieler;
      ersatz = gast.zustand.ersatzspieler;
    }
    
    // wechsel ist schon passiert, deshalb beide spieler jeweils im anderen select anzeigen 
    const einwechsel = start.find(s => s.id === ereignis.auswechsel_spieler_id && s.unregistriert === ereignis.unregistrierter_spieler);
    const auswechsel = ersatz.find(s => s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler);
    // absichern falls chronologie der ereignisse falsch
    if (einwechsel && auswechsel) {
      start = [
        ...start.filter(s => !(s.id === einwechsel.id && s.unregistriert === einwechsel.unregistriert)),
        auswechsel
      ];
      ersatz = [
        ...ersatz.filter(s => !(s.id === auswechsel.id && s.unregistriert === auswechsel.unregistriert)),
        einwechsel
      ];
    }

    return (
      <>
        <b>{texte.ausgewechselt}</b>
        <br/>
        {renderSelect(start, spielerSelectValue, onSpielerSelectChange)}
        <br/>
        <b>{texte.eingewechselt}</b>
        <br/>
        {renderSelect(ersatz, auswechselSelectValue, onAuswechselSelectChange)}
      </>
    );
  };

  const renderPersonGrafik = (ereignis) => {
    let bildUrl = null;
    
    if (!ereignis.bestaetigt) {
      return <></>;
    }

    if (ereignis.spieler_id != null) {
      let spieler = alleSpielerFuerTeam(ereignis.team_id).find(s => s.id === ereignis.spieler_id && s.unregistrierter_spieler === ereignis.unregistrierter_spieler);
      if (spieler) bildUrl = spieler.bild_url;
    } else {
      let staff = alleStaffFuerTeam(ereignis.team_id).find(s => s.id === ereignis.rolle_id);
      if (staff) bildUrl = staff.bild_url;
    }
    if (bildUrl === null) {
      return <></>;
    } else {
      return <img className="bild" src={bildUrl}/>;
    }
  };

  let spielerSelect = null;
  if (ereignis.typ_id === EREIGNIS_TYP.auswechslung) {
    spielerSelect = renderSelectFuerAuswechsel(ereignis)
  } else {
    if (ereignis.spieler_id != null) {
      if (ereignis.hat_film) {
        spielerSelect = renderSelect(null, spielerSelectValue, onSpielerSelectChange);
      } else {
        spielerSelect = renderSelect(alleSpielerFuerTeam(ereignis.team_id), spielerSelectValue, onSpielerSelectChange);
      }
    } else if(ereignis.rolle_id != null) {
      spielerSelect = renderSelect(alleStaffFuerTeam(ereignis.team_id), staffSelectValue, onStaffSelectChange);
    }
  }
  
  const renderEreignisTyp = () => {
    if (ereignis.typ_id === EREIGNIS_TYP.default) {
      return <span>
        <select>
          
        </select>
      </span>;
    } else {
      return <b>{ereignis.typ_name}</b>;
    }
  };
  
  return (
    <div className="eintrag edit">
      <div className="">
        {renderPersonGrafik(ereignis)}
        {renderEreignisTyp()}
        {ereignis.hat_film &&
        <span className="pull-right">
          <Tooltip content={texte.video_anzeigen}>
            <EreignisIcon ereignisTyp={ICON_TYP.hatFilm} className="ikon-pointer" onClick={zeigeVideoClick}/>
          </Tooltip>
        </span>
        }
      </div>
      <div className="">
        {spielerSelect != null &&
        spielerSelect
        }
      </div>
      <div className="flex-row">
        <Tooltip className="input-prepend contentbox" content={texte.spielminute}>
          <span className="add-on">
            <i className="icon-time"/>
          </span>
          <input type="text" value={minuteValue} onChange={onMinuteChange} disabled={ereignis.typ_id === EREIGNIS_TYP.assist}/>
        </Tooltip>
        <Tooltip className="input-prepend contentbox" content={texte.zusatzminute}>
          <span className="add-on">
            <i className="icon-time"/>
            <i className="icon-plus"/>
          </span>
          <input type="text" value={zusatzminuteValue} onChange={onZusatzminuteChange} disabled={istZusatzminuteMoeglich(minuteValue) === false || ereignis.typ_id === EREIGNIS_TYP.assist}/>
        </Tooltip>
        <Tooltip className="input-prepend contentbox" content={texte.sekunden}>
          <span className="add-on">
            <i className="icon-time"/>
          </span>
          <input type="text" value={sekundenValue} onChange={onSekundenChange} disabled={ereignis.typ_id === EREIGNIS_TYP.assist}/>
        </Tooltip>
      </div>
      {ereignis.hat_film &&
      <div className="flex-row">
        <select value={ereignisTypSubtyp} onChange={onEreignisTypChange}>
          {mhaEreignisse.map(et => <option key={`${et.typ_id}:${et.subtyp_id}`} value={`${et.typ_id}:${et.subtyp_id}`}>{et.name}</option>)}
        </select>
      </div>
      }
      <div className="flex-row">
        <button className="btn btn-primary" onClick={onSpeichernClick}>{texte.speichern}</button>
        {!nurZeitBearbeitbar && !ereignis.hat_film &&
        <button className="btn btn-danger" onClick={onLoeschenClick}>{texte.loeschen}</button>
        }
        <button className="btn " onClick={() => {
          setZeigeVideo(false);
          return editEreignisStop(ereignis.id);
        }}>{texte.abbrechen}</button>
      </div>
      {zeigeVideo &&
      <div className="flex-row">
        <Video src={ereignis.film_url}/>
      </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    nurZeitBearbeitbar: state.spiel.nur_zeit_bearbeitbar,
    heim: state.heim,
    gast: state.gast,
    liga: state.liga,
    mhaEreignisse: state.spielfeld.mhaEreignisse,
    texte: state.i18n.ticker,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    spielerEreignisSpeichern: (ereignis, spielerId, unregistriert, spielminute, zusatzminute, sekunden, zusatzdaten) => { dispatch(spielerEreignisUpdate(ereignis, spielerId, unregistriert, spielminute, zusatzminute, sekunden, zusatzdaten)) },
    staffEreignisSpeichern: (ereignis, staffId, spielminute, zusatzminute, sekunden) => { dispatch(staffEreignisUpdate(ereignis, staffId, spielminute, zusatzminute, sekunden)) },
    ereignisLoeschen: (ereignis) => { dispatch(spielerEreignisLoeschen(ereignis)) },
    editEreignisStop: (ereignisId) => { dispatch(ereignisEditStop(ereignisId)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TickerEreignisEdit);
