import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {pflichtbeitraegeSpeichern, pflichtbeitraegeVergangenUebernehmen} from "../../state/modules/sponsorenlaufPflichtbeitraege";
import {formatiereBetrag} from "../../../../lib/formatierungen";

const Pflichtbeitraege = ({pflichtbeitraege, spielerkategorien, texte, pflichtbeitraegeVergangenUebernehmen, pflichtbeitraegeSpeichern}) => {
  const [beitraege, setBeitraege] = useState(pflichtbeitraege);
  const [speichernLaueft, setSpeichernLaeuft] = useState(false);

  useEffect(() => {
    setBeitraege(pflichtbeitraege);
    setSpeichernLaeuft(false);
  }, [pflichtbeitraege]);

  const setzeWert = (spielerkategorieId, key, value) => {
    const beitrag = beitraege.find(b => b.spielerkategorieId === spielerkategorieId) || {spielerkategorieId: spielerkategorieId, inaktiv: false, pflichtbeitrag: ''};
    beitrag[key] = value;

    setBeitraege([
      ...beitraege.filter(b => b.spielerkategorieId !== spielerkategorieId),
      beitrag
    ]);
  };

  const speichern = (ev) => {
    ev.currentTarget.blur();
    setSpeichernLaeuft(true);
    pflichtbeitraegeSpeichern(beitraege.filter(b => b.pflichtbeitrag !== null && b.pflichtbeitrag !== '' && b.pflichtbeitrag >= 0));
  };

  const renderZeile = (spielerkategorie) => {
    let pflichtbeitrag = beitraege.find(pb => pb.spielerkategorieId === spielerkategorie.id) || {spielerkategorieId: spielerkategorie.id, inaktiv: false, pflichtbeitrag: ''};
    const errors = pflichtbeitrag.errors && pflichtbeitrag.errors.pflichtbeitrag;
    return (
      <tr key={spielerkategorie.id}>
        <td>{spielerkategorie.bezeichnung}</td>
        <td>
          <div className={`control-group${errors ? ' error' : ''}`}>
            <div className="controls" key={pflichtbeitrag.pflichtbeitrag}>
              <input className="numeric money required text-right" type="number" min={0} defaultValue={pflichtbeitrag.pflichtbeitrag} onBlur={ev => setzeWert(spielerkategorie.id, 'pflichtbeitrag', formatiereBetrag(ev.currentTarget.value))}/>
              {errors && errors.join(' ')}
            </div>
          </div>
        </td>
        <td>
          <div className="controls">
            <input className="boolean optional" type="checkbox" checked={pflichtbeitrag.inaktiv} onChange={ev => setzeWert(spielerkategorie.id, 'inaktiv', ev.currentTarget.checked)}/>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <section>
      <h2 className="page-section cursor-pointer">{texte.pflichtbeitraege}</h2>
      {pflichtbeitraege.length == 0 &&
        <div className="text-right">
          <button type='button' className="btn btn-primary" onClick={pflichtbeitraegeVergangenUebernehmen}>{texte.vonLetztemSponsorenlaufUebernehmen}</button>
        </div>
      }
      <table className="attributes table">
        <colgroup>
          <col/>
          <col/>
          <col width="100"/>
        </colgroup>
        <thead>
        <tr>
          <th>{texte.spielerkategorie}</th>
          <th>{texte.betrag}</th>
          <th>{texte.inaktiv}</th>
        </tr>
        </thead>
        <tbody>
        {spielerkategorien.map(sk => renderZeile(sk))}
        </tbody>
      </table>
      <div className="form-actions">
        <button type="button" className="btn btn-primary" onClick={speichern}>
          {texte.speichern}
          {speichernLaueft && <>&nbsp;<div className="ajax-loader"/></>}
        </button>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    pflichtbeitraege: state.pflichtbeitraege,
    spielerkategorien: state.listen.spielerkategorien,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pflichtbeitraegeVergangenUebernehmen: () => { dispatch(pflichtbeitraegeVergangenUebernehmen()); },
    pflichtbeitraegeSpeichern: (pflichtbeitraege) => { dispatch(pflichtbeitraegeSpeichern(pflichtbeitraege)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pflichtbeitraege);
