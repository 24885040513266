import React from "react";
import {connect} from "react-redux";
import MarkdownEditor from "../../../components/MarkdownEditor";
import MarkdownText from "../../../components/MarkdownText";

const FormTextarea = ({name, value, readonly = false, locale, api, onTextChange}) => {
  if (readonly) {
    return <MarkdownText markdownUrl={api.markdown} token={api.token} text={value} />;
  }

  return (
    <span className="control-group text optional">
      <MarkdownEditor markdownUrl={api.markdown} imageUploadsUrl={api.imageUploadsUrl} token={api.token} locale={locale} value={value || ''} onBlur={text => onTextChange(name, text)}/>
    </span>
  );
};

const mapStateToProps = (state) => {
  return {
    locale: state.i18n.locale,
    api: state.api,
  };
};

export default connect(mapStateToProps, {})(FormTextarea);
