import {ersetzeStringMitDatum} from "../lib/formatierungen";
import {bibliothekReferenzSetzen} from "./teamTeilnehmerReducer";

function preloaderFuerTeilnehmer(options) {
  return {
    ausbildung: ersetzeStringMitDatum(options.ausbildung, ['von', 'bis']),
    ausbildner: options.ausbildner,
    ergebnisTrainer: options.ergebnisTrainer,
    klassen: options.klassen.map(tt => ersetzeStringMitDatum(tt, ['von', 'bis'])).sort((a, b) => a.von.compareTo(b.von)),
    aufgaben: options.aufgaben.map(aufgabe => {
      bibliothekReferenzSetzen(aufgabe, options.bibliothek);
      return ersetzeStringMitDatum(aufgabe, ['von', 'bis']);
    }),
    bibliothek: options.bibliothek,
    arbeitsraumListe: options.arbeitsraumListe.map(ar => bibliothekReferenzSetzen(ar, options.bibliothek)).sort((a, b) => b.id - a.id),
    fileUpload: [],
    api: options.api,
    i18n: options.i18n,
    rollbar: options.rollbar,
  };
}

export default preloaderFuerTeilnehmer;
