import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import LeistungAuswahl from "../LeistungAuswahl";
import {BEXIO_STATUS} from "../../lib/constants";
import {PERSON_GESCHLECHT} from "../../../../lib/constants";
import DatumPicker from "../../../../components/DatumPicker";
import SimpleSelect from "../SimpleSelect";

const RechnungenFilter = ({bexio, teams, i18n, aktiveBexioStati, onFilterChange}) => {
  const [filterValues, setFilterValues] = useState({
    person: null, strasse: null, plz: null, ort: null, 
    betragFreiwillig: false, leistung: null, betragVon: null, betragBis: null, geschlecht: null, 
    bexio: bexio.aktiv ? [BEXIO_STATUS.pending, BEXIO_STATUS.partial] : [], 
    mahnstufe: null, rechnungsdatumVon: null, rechnungsdatumBis: null, mahndatumVon: null, mahndatumBis: null,
    team: null, ohneTeam: null, spielerGesperrt: null
  });
  const texte = i18n.texte;
  const texteRechnungen = i18n.texte.rechnungen;

  const showBetragFreiwillig = true;
  const showTeam = teams.length > 0;

  // initial filter bekannt machen
  useEffect(() => {
    onFilterChange(filterValues);
  }, []);

  const filterChange = (feldname, wert) => {
    const filter = {...filterValues};
    if (wert === '') {
      filter[feldname] = null;
    } else {
      filter[feldname] = wert;
    }
    setFilterValues(filter);
    onFilterChange(filter);
  };

  return (
    <table className="table attributes">
      <tbody>
      <tr>
        <th>{texteRechnungen.person}</th>
        <td>
          <input type="text" onChange={ev => filterChange('person', ev.currentTarget.value)}/>
        </td>
        <th>{showBetragFreiwillig && texteRechnungen.betragFreiwillig}</th>
        <td>
          {showBetragFreiwillig &&
            <span className="btn-group">
              <button type="button" className={`btn btn-small${filterValues.betragFreiwillig === true ? ' btn-success' : ''}`} onClick={() => filterChange('betragFreiwillig', filterValues.betragFreiwillig === true ? null : true)}>{texte.ja}</button>
              <button type="button" className={`btn btn-small${filterValues.betragFreiwillig === false ? ' btn-success' : ''}`} onClick={() => filterChange('betragFreiwillig', filterValues.betragFreiwillig === false ? null : false)}>{texte.nein}</button>
            </span>
          }
        </td>
      </tr>
      <tr>
        <th>{texteRechnungen.strasse}</th>
        <td>
          <input type="text" onChange={ev => filterChange('strasse', ev.currentTarget.value)}/>
        </td>
        <th>{texteRechnungen.plz} / {texteRechnungen.ort}</th>
        <td className="text-nowrap">
          <input type="text" onChange={ev => filterChange('plz', ev.currentTarget.value)} style={{width: 42}}/>
          &nbsp;
          <input type="text" onChange={ev => filterChange('ort', ev.currentTarget.value)} style={{width: 158}}/>
        </td>
      </tr>
      <tr>
        <th>{texteRechnungen.doppelteAdressen}</th>
        <td>
          <button type="button" className={`btn btn-small${filterValues.doppelteAdressen === true ? ' btn-success' : ''}`} onClick={() => filterChange('doppelteAdressen', filterValues.doppelteAdressen === true ? null : true)}>{texte.ja}</button>
        </td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th>{texteRechnungen.leistung}</th>
        <td>
          <LeistungAuswahl onLeistungChange={l => filterChange('leistung', l ? l.id : '')} istEntfernbar={true}/>
        </td>
        <th>{texteRechnungen.betrag} {texteRechnungen.von} / {texteRechnungen.bis}</th>
        <td className="text-nowrap">
          <input className="betrag" type="number" min={0} step={1} onChange={ev => filterChange('betragVon', ev.currentTarget.value)}/>
          &nbsp;
          <input className="betrag" type="number" min={0} step={1} onChange={ev => filterChange('betragBis', ev.currentTarget.value)}/>
        </td>
      </tr>
      {showTeam &&
        <>
          <tr>
            <th>{texteRechnungen.team}</th>
            <td>
              <select onChange={ev => filterChange('team', ev.currentTarget.value)}>
                <option value=""></option>
                {teams.map(t => <option key={t.id} value={t.id}>{t.bezeichnung}</option>)}
              </select>
            </td>
            <th>{texteRechnungen.ohneTeam}</th>
            <td>
              <span className="btn-group">
                <button type="button" className={`btn btn-small${filterValues.ohneTeam === true ? ' btn-success' : ''}`} onClick={() => filterChange('ohneTeam', filterValues.ohneTeam === true ? null : true)}>{texte.ja}</button>
                <button type="button" className={`btn btn-small${filterValues.ohneTeam === false ? ' btn-success' : ''}`} onClick={() => filterChange('ohneTeam', filterValues.ohneTeam === false ? null : false)}>{texte.nein}</button>
              </span>
            </td>
          </tr>
          <tr>
            <th>{texteRechnungen.geschlecht}</th>
            <td>
            <span className="btn-group">
              <button type="button" className={`btn btn-small${filterValues.geschlecht === PERSON_GESCHLECHT.mann ? ' btn-success' : ''}`} onClick={() => filterChange('geschlecht', filterValues.geschlecht === PERSON_GESCHLECHT.mann ? null : PERSON_GESCHLECHT.mann)}>{texteRechnungen.mann}</button>
              <button type="button" className={`btn btn-small${filterValues.geschlecht === PERSON_GESCHLECHT.frau ? ' btn-success' : ''}`} onClick={() => filterChange('geschlecht', filterValues.geschlecht === PERSON_GESCHLECHT.frau ? null : PERSON_GESCHLECHT.frau)}>{texteRechnungen.frau}</button>
            </span>
            </td>
            <th>{texteRechnungen.vereinsinternGesperrt}</th>
            <td>
            <span className="btn-group">
              <button type="button" className={`btn btn-small${filterValues.spielerGesperrt === true ? ' btn-success' : ''}`} onClick={() => filterChange('spielerGesperrt', filterValues.spielerGesperrt === true ? null : true)}>{texte.ja}</button>
              <button type="button" className={`btn btn-small${filterValues.spielerGesperrt === false ? ' btn-success' : ''}`} onClick={() => filterChange('spielerGesperrt', filterValues.spielerGesperrt === false ? null : false)}>{texte.nein}</button>
            </span>
            </td>
          </tr>
        </>
      }
      {bexio.aktiv &&
        <>
          <tr>
            <th>{texteRechnungen.statusInBexio}</th>
            <td>
              <SimpleSelect isMulti value={filterValues.bexio} options={aktiveBexioStati.map(s => ({label: s.text, value: s.key}))} onChange={val => filterChange('bexio', val)} />
            </td>
            <th>{texteRechnungen.mahnstufe}</th>
            <td>
              <select onChange={ev => filterChange('mahnstufe', ev.currentTarget.value)}>
                <option value=""></option>
                {[1, 2, 3].map(z => <option key={z} value={z}>{`${texteRechnungen.mahnstufe} ${z}`}</option>)}
              </select>
            </td>
          </tr>
          <tr>
            <th>{texteRechnungen.rechnungsdatum}<br/>{texteRechnungen.von_bis}</th>
            <td>
              <DatumPicker locale={i18n.locale} value={filterValues.rechnungsdatumVon || ''} onChange={date => filterChange('rechnungsdatumVon', date)}/>
              &nbsp;
              <DatumPicker locale={i18n.locale} value={filterValues.rechnungsdatumBis || ''} onChange={date => filterChange('rechnungsdatumBis', date)}/>
            </td>
            <th>{texteRechnungen.mahndatum}<br/>{texteRechnungen.von_bis}</th>
            <td>
              <DatumPicker locale={i18n.locale} value={filterValues.mahndatumVon || ''} onChange={date => filterChange('mahndatumVon', date)}/>
              &nbsp;
              <DatumPicker locale={i18n.locale} value={filterValues.mahndatumBis || ''} onChange={date => filterChange('mahndatumBis', date)}/>
            </td>
          </tr>
        </>
      }
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => {
  return {
    bexio: state.bexio,
    teams: state.teams,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RechnungenFilter);

