// @flow

import type {AppState, EinsatzState, SpielerState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import Spielerkarte from "./spiel/Spielerkarte";
import Aktionen from "./Aktionen";
import TrainingSpielerliste from "./training/TrainingSpielerliste";
import TurnierSpielerliste from "./turnier/TurnierSpielerliste";
import TipsZusammenfassung from "./TipsZusammenfassung";

const Spielerliste = (props: { einsatz: EinsatzState, spielerliste: SpielerState, i18n: any }) => {
  if (props.einsatz.typ === 'spiel') {
    return (
      <div className="tab-pane">
        <Spielerkarte/>
        <Aktionen/>
      </div>
    );
  } else if (props.einsatz.typ === 'training') {
    return (
      <div className="tab-pane">
        <TipsZusammenfassung relevanteSpieler={props.spielerliste.liste} relevanteWettkampfleistungen={null}/>
        <TrainingSpielerliste/>
        <Aktionen/>
      </div>
    );
  } else if (props.einsatz.typ === 'turnier') {
    return (
      <div className="tab-pane">
        <TipsZusammenfassung relevanteSpieler={props.spielerliste.liste} relevanteWettkampfleistungen={null}/>
        <TurnierSpielerliste/>
        <Aktionen/>
      </div>
    );
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    spielerliste: state.spielerliste,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Spielerliste);
