import React, {useState, useEffect} from "react";
import useGlobal from "../../../../store";
import {videoUndZeitBei} from "../../../../services/helpers";

const EditForm = () => {
  const [globalState, globalActions] = useGlobal();
  const [values, setValues] = useState(globalState.formItem);

  const texte = globalState.i18n;
  const heimSpielerkarte = globalState.spielInfos.spielerkarten.heimSpielerkarte;
  const gastSpielerkarte = globalState.spielInfos.spielerkarten.gastSpielerkarte;
  const stammdaten = globalState.stammdaten;
  
  useEffect(() => {
    setValues(globalState.formItem);
  }, [globalState.formItem]);

  const closeForm = () => {
    globalActions.setFormVisible(false);
  };

  const handleSubmit = () => {
    values.video = parseInt(values.video || 0);
    values.start = parseInt(values.start || 0);
    values.end = parseInt(values.end || 0);

    if (values.id) {
      globalActions.invalidateComment(values);
      globalActions.updateComment(values);
    } else {
      globalActions.createComment(values);
    }

    closeForm();
  };

  const handleDelete = () => {
    globalActions.invalidateComment(values);
    globalActions.deleteComment(values);
    closeForm();
  };

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  const handleZeitChange = (name) => (event) => {
    const videoUndZeit = videoUndZeitBei(globalState,
      name === 'minute' ? event.target.value : values.minute,
      name === 'zusatzminute' ? event.target.value : values.zusatzminute);
    setValues({
      ...values,
      [name]: event.target.value,
      video: videoUndZeit.video,
      start: videoUndZeit.start,
    });
  };

  const renderTeamsDropdown = () => {
    if (!stammdaten.anzuzeigendeDropdowns.includes('teams')) {
      return <></>;
    }

    let spielInfos = globalState.spielInfos;
    return (
      <div className="field">
        <label htmlFor="teamId">{texte.kommentar_team}</label>
        <select id="teamId" onChange={handleChange("teamId")} value={values.teamId || ""}>
          <option value=""/>
          <option value={spielInfos.heimTeam.id}>{spielInfos.heimTeam.name}</option>
          <option value={spielInfos.gastTeam.id}>{spielInfos.gastTeam.name}</option>
        </select>
      </div>
    );
  };


  const renderSpielerDropdown = () => {
    if (!stammdaten.anzuzeigendeDropdowns.includes('spieler')) {
      return <></>;
    }
    return (
      <div className="field">
        <label htmlFor="spielerEinsatzId">{texte.kommentar_spieler}</label>
        <select id="spielerEinsatzId" onChange={handleChange("spielerEinsatzId")} value={values.spielerEinsatzId || ""}>
          <option value=""/>
          <optgroup label={texte.heimTeamStartformation}>
            {heimSpielerkarte.startformation.map(einsatz => (<option key={einsatz.id} value={einsatz.id}>{einsatz.rueckennummer} | {einsatz.kurzname}</option>))}
          </optgroup>
          <optgroup label={texte.heimTeamErsatzformation}>
            {heimSpielerkarte.ersatzformation.map(einsatz => (<option key={einsatz.id} value={einsatz.id}>{einsatz.rueckennummer} | {einsatz.kurzname}</option>))}
          </optgroup>
          <optgroup label={texte.gastTeamStartformation}>
            {gastSpielerkarte.startformation.map(einsatz => (<option key={einsatz.id} value={einsatz.id}>{einsatz.rueckennummer} | {einsatz.kurzname}</option>))}
          </optgroup>
          <optgroup label={texte.gastTeamErsatzformation}>
            {gastSpielerkarte.ersatzformation.map(einsatz => (<option key={einsatz.id} value={einsatz.id}>{einsatz.rueckennummer} | {einsatz.kurzname}</option>))}
          </optgroup>
        </select>
      </div>
    );
  }

  const renderTrainerDropdown = () => {
    if (!stammdaten.anzuzeigendeDropdowns.includes('trainer')) {
      return <></>;
    }
    return (
      <div className="field">
        <label htmlFor="trainerId">{texte.kommentar_trainer}</label>
        <select id="trainerId" onChange={handleChange("trainerId")} value={values.trainerId || ""}>
          <option value=""/>
          <optgroup label={texte.heimStaffbank}>
            {heimSpielerkarte.staffbank.filter((s) => s.istTrainer).map(staff => <option key={staff.rolleId} value={staff.rolleId}>{staff.name} ({staff.ereignisSubtyp})</option>)}
          </optgroup>
          <optgroup label={texte.gastStaffbank} key="gastTrainer">
            {gastSpielerkarte.staffbank.filter((s) => s.istTrainer).map(staff => <option key={staff.rolleId} value={staff.rolleId}>{staff.name} ({staff.ereignisSubtyp})</option>)}
          </optgroup>
        </select>
      </div>
    );
  };
  
  const renderSchiedsrichterDropdown = () => {
    if (!stammdaten.anzuzeigendeDropdowns.includes('schiedsrichter')) {
      return <></>;
    }
    return (
      <div className="field">
        <label htmlFor="schiedsrichterId">{texte.kommentar_schiedsrichter}</label>
        <select id="schiedsrichterId" onChange={handleChange("schiedsrichterId")} value={values.schiedsrichterId || ""}>
          {globalState.spielInfos.schiedsrichter.map(sr => <option key={sr.id} value={sr.id}>{sr.name} ({sr.einsatzRolle})</option>)}
        </select>
      </div>
    );
  };

  return (
    <div className="form expanded">
      <div className="header flex-row">
        <div>{values.id ? texte.kommentar_bearbeiten : texte.neuer_kommentar}</div>
        <div className="flex-1">&nbsp;</div>
        <button className="icon-button" onClick={closeForm}>close</button>
      </div>
      <div className="form-container">
        <div className="field">
          <label htmlFor="titel">{texte.titel}</label>
          <input type="text" id="titel" value={values.titel || ""} onChange={handleChange("titel")}/>
        </div>
        <div className="field">
          <label htmlFor="beschreibung">{texte.beschreibung}</label>
          <textarea id="beschreibung" onChange={handleChange("beschreibung")} value={values.beschreibung || ""}/>
        </div>
        <div className="field">
          <label htmlFor="typId">{texte.kommentar_typ}</label>
          <select id="typId" onChange={handleChange("typId")} value={values.typId || ""}>
            {stammdaten.kommentarTypen.map(t => (<option key={t.id} value={t.id}>{t.name}</option>))}
          </select>
        </div>
        {renderTeamsDropdown()}
        {renderSpielerDropdown()}
        {renderTrainerDropdown()}
        {renderSchiedsrichterDropdown()}
        <div className="field" hidden>
          <label htmlFor="start">{texte.start_seconds}</label>
          <input type="number" id="start" value={values.start || 0} readOnly/>
        </div>
        <div className="field">
          <label htmlFor="minute">{texte.spielminute}</label>
          <input type="text" id="minute" value={values.minute || 0} onChange={handleZeitChange("minute")} style={{maxWidth: 80, display: 'inline', textAlign: 'right'}}/>
          <span style={{padding: '5px', position: 'relative', top: '1px'}}>+</span>
          <input type="text" id="zusatzminute" value={values.zusatzminute || 0} onChange={handleZeitChange("zusatzminute")} style={{maxWidth: 80, display: 'inline', textAlign: 'right'}}/>
        </div>
        <div className="flex-row">
          <div className="flex-1">
            <button type="button" className="primary" onClick={handleSubmit}>{texte.speichern}</button>
          </div>
          <div className="flex-1">
            <button onClick={closeForm}>{texte.abbrechen}</button>
          </div>
          {values.id > 0 && 
            <button className="icon-button text-red" onClick={handleDelete}>delete</button>
          }
        </div>
      </div>
    </div>
  );
};

export default EditForm;
