import React from "react";

const TeamBadge = ({team, className = '', ...props}) => {
  const classNames = ["team-badge", className].join(" ");

  return (
    <div className={classNames} {...props}>
      <div className="avatar">
        <img src={team.logoUrl}/>
      </div>
      <div className="text">
        <div className="title">
          <span className="abschneiden">{team.name}</span>
        </div>
        <div className="subtitle">
          <span className="abschneiden">{team.ligaSpielklasse}</span>
        </div>
      </div>
    </div>
  );
};

export default TeamBadge;
