import React from "react";
import {connect} from "react-redux";

const ArbeitsraumEintrag = ({eintrag, texte, dokumentPfad}) => {
  return (
    <div className="eintrag-box">
      <div className="zeile">
        <b>{eintrag.titel}</b>
        <span className="pull-right text-right label-liste">
          {!eintrag.gelesen && !eintrag.betrifftKlasse &&
          <span className="label label-warning">{texte.ungelesen}</span>
          }
          {eintrag.experte.id === eintrag.absenderId &&
          <span className="label label-info">{eintrag.experte.name}</span>
          }
          {eintrag.ausbildner && eintrag.ausbildner.id === eintrag.absenderId &&
          <span className="label label-info">{eintrag.ausbildner.name}</span>
          }
          &nbsp;{eintrag.datum}
        </span>
      </div>
      <div className="zeile plaintext">
        {eintrag.text}
      </div>
      {eintrag.dokumentPfad &&
      <div className="zeile">
        <a href={eintrag.dokumentPfad} target="_blank" className={`ikon ${eintrag.dokumentArt.icon}`}/>
      </div>
      }
      {eintrag.uploadVorhanden &&
      <div className="zeile">
        <a href={`${dokumentPfad}?arbeitsraumId=${eintrag.id}`} target="_blank" className={`ikon ${eintrag.dokumentArt.icon}`}/>
      </div>
      }
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    texte: state.i18n.texte.arbeitsraum,
    dokumentPfad: state.api.update,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ArbeitsraumEintrag);
