import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import Noten from "./Noten";
import Komission from "./Komission";
import Spesen from "./Spesen";
import Abschluss from "./Abschluss";
import CoachingPoints from "./CoachingPoints";
import {fehlerUpdate} from "../state/fehlerReducer";

const VIEWS = {
  noten: 'noten',
  coaching_points: 'coaching_points',
  kommission: 'kommission',
  spesen: 'spesen',
  abschluss: 'abschluss',
};

const Ansicht = ({bericht, tabs, texte, fehler, clearFehler}) => {
  const [ansicht, setAnsicht] = useState(VIEWS.noten);

  useEffect(() => {
    if (bericht.bBerichtDefinitiv && ansicht === VIEWS.abschluss) {
      // nach abschluss wechsel auf erstes tab -> ansicht ist auf "abschluss"
      setAnsicht(VIEWS.noten);
    }
  }, [bericht]);
  
  const anzeigbareTabs = useMemo(() => {
    return tabs.filter(tab => {
      if (tab === VIEWS.abschluss)  {
        // abschluss nur anzeigen solange bericht nicht abgeschlossen
        return bericht.bBerichtDefinitiv === false;
      }
      return true;
      });
  }, [bericht]);

  const navClick = (event, neueAnsicht) => {
    event.stopPropagation();
    event.preventDefault();
    setAnsicht(neueAnsicht);
  };

  return (
    <div>
      {fehler && fehler.length > 0 &&
        <div className="alert alert-error">
          <a className="close" onClick={() => clearFehler()}>×</a>
          {fehler.map((fehler, index) => {
            return <div key={index}>{fehler}</div>
          })}
        </div>
      }
      <ul className="nav nav-tabs">
        {anzeigbareTabs.map(tab => {
          return <li key={tab} className={ansicht === tab ? 'active' : ''}>
            <a href="" onClick={(ev) => navClick(ev, tab)}>{texte.tabs[tab]}</a>
          </li>;
        })}
      </ul>
      {ansicht === VIEWS.noten &&
        <Noten/>
      }
      {ansicht === VIEWS.coaching_points &&
        <CoachingPoints/>
      }
      {ansicht === VIEWS.kommission &&
        <Komission/>
      }
      {ansicht === VIEWS.spesen &&
        <Spesen/>
      }
      {ansicht === VIEWS.abschluss &&
        <Abschluss/>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    bericht: state.bericht,
    tabs: state.listen.tabs,
    texte: state.i18n.texte,
    fehler: state.fehler.full_messages
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearFehler: () => dispatch(fehlerUpdate({})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ansicht);
