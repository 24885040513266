const RECHNUNGEN_SUCHE_START = 'RECHNUNGEN_SUCHE_START';
const RECHNUNGEN_SUCHE_ENDE = 'RECHNUNGEN_SUCHE_ENDE';
const RECHNUNGEN_FEHLER = 'RECHNUNGEN_FEHLER';

export function sucheStarten() {
  return (dispatch) => {
    dispatch({type: RECHNUNGEN_SUCHE_START });
  };
}
export function sucheBeenden() {
  return (dispatch) => {
    dispatch({type: RECHNUNGEN_SUCHE_ENDE });
  };
}
export function sucheFehler(fehlerTextArray) {
  return (dispatch) => {
    dispatch({type: RECHNUNGEN_FEHLER, fehler: fehlerTextArray });
  };
}

function rechnungskontrollenStatusReducer(state = {}, action) {
  switch (action.type) {
    case RECHNUNGEN_SUCHE_START:
      return {
        ...state,
        sucheLaeuft: true,
        sucheGestartet: true
      };
    case RECHNUNGEN_SUCHE_ENDE:
      return {
        ...state,
        sucheLaeuft: false
      };
    case RECHNUNGEN_FEHLER:
      return {
        ...state,
        fehler: action.fehler
      };
    default:
      return state;
  }
}

export default rechnungskontrollenStatusReducer;
