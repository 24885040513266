// @flow

import type {AppState, EinsatzState, SpielState, SpielerState, SpielerEintrag, SpielerkarteEintrag, SpielerTips, TorhueterNote} from "../../../state/stateTypes";
import type {Dispatch} from "../../../state/actionTypes";

import React from "react";
import { connect } from "react-redux";
import NoteDropdown from "../../NoteDropdown";

import {
  spielerPrognoseEinAusblenden,
  spielerTipsEntwicklungSetzen,
  spielerTipsISetzen,
  spielerTipsKommentarSetzen,
  spielerTipsNoteSetzen,
  spielerTipsPSetzen,
  spielerTipsSSetzen,
  spielerTipsTSetzen,
  spielerTorhueterNoteKommentarSetzen,
  spielerTorhueterNoteSetzen,
} from "../../../state/modules/spieler";
import Textfeld from "../../Textfeld";
import LoeschButton from "../LoeschButton";
import { prognoseWertBerechnen } from "../../../../../lib/prognoseFragen";
import SpielerPrognoseFragen from "./SpielerPrognoseFragen";
import Tooltip from "../../../../../components/Tooltip";
import SpielerBadge from "../../SpielerBadge";

const SpielerkarteTorhueterNoten = ({
                                      einsatz,
                                      spiel,
                                      i18n,
                                      spielerPrognoseEinAusblenden,
                                      spielerTipsTSetzen,
                                      spielerTipsISetzen,
                                      spielerTipsPSetzen,
                                      spielerTipsSSetzen,
                                      spielerTipsNoteSetzen,
                                      spielerTipsEntwicklungSetzen,
                                      spielerTipsKommentarSetzen,
                                      spielerTorhueterNoteSetzen,
                                      spielerTorhueterNoteKommentarSetzen,
                                      spieler,
                                      spielerEinsatz,
                                    }: Props) => {
  const aktiv = !einsatz.readonly;

  const requestDaten = { spielId: spiel.id, spielerId: spieler.id, spielerTips: spieler.tips, torhueterNoten: spieler.torhueterNoten, };
  const successKlass = spieler.tips.t && spieler.tips.i && spieler.tips.p && spieler.tips.s ? "success" : "";

  const prognoseFragen = spieler.prognoseFragen;
  const prognoseWert = !prognoseFragen ? 0 : prognoseFragen.prognose || [1, 2, 3, 4, 5, 6].filter(frageNr => prognoseFragen[`frage${frageNr}`].selektiert == true).length;
  const darstellung = prognoseWertBerechnen(prognoseWert);

  const spielerRow = <tr className={successKlass}>
    <td style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }}>
      <span className="label label-info">{spieler.passnummer}</span>
      {spielerEinsatz.spielfuehrer &&
        <span className="label label-important spielfuehrer">C</span>
      }
    </td>
    <td style={{ verticalAlign: 'middle' }}>
      <SpielerBadge spieler={spieler}/>
    </td>
    <td>
      <div className="spielposition" style={{ width: '110px', verticalAlign: 'middle' }}>
        {spielerEinsatz.spielposition.name}
      </div>
    </td>
    <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
      {spielerEinsatz.rueckennummer}
    </td>
    <td style={{ width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
      <NoteDropdown feldName="tipsT"
                    feldLabel={i18n.tips.tLang}
                    feldWert={spieler.tips.t || ''}
                    einfaerben={true}
                    requestDaten={requestDaten}
                    onValueChange={(neuerWert) => {
                      spielerTipsTSetzen(spieler.id, neuerWert, spieler.tips)
                    }}/>
    </td>
    <td style={{ width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
      <NoteDropdown feldName="tipsI"
                    feldLabel={i18n.tips.iLang}
                    feldWert={spieler.tips.i || ''}
                    einfaerben={true}
                    requestDaten={requestDaten}
                    onValueChange={(neuerWert) => {
                      spielerTipsISetzen(spieler.id, neuerWert, spieler.tips)
                    }}/>
    </td>
    <td style={{ width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
      <NoteDropdown feldName="tipsP"
                    feldLabel={i18n.tips.pLang}
                    feldWert={spieler.tips.p || ''}
                    einfaerben={true}
                    requestDaten={requestDaten}
                    onValueChange={(neuerWert) => {
                      spielerTipsPSetzen(spieler.id, neuerWert, spieler.tips)
                    }}/>
    </td>
    <td style={{ width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
      <NoteDropdown feldName="tipsS"
                    feldLabel={i18n.tips.sLang}
                    feldWert={spieler.tips.s || ''}
                    einfaerben={true}
                    requestDaten={requestDaten}
                    onValueChange={(neuerWert) => {
                      spielerTipsSSetzen(spieler.id, neuerWert, spieler.tips)
                    }}/>
    </td>
    <td style={{ width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
      <span className="durchschnitt">
        {spieler.tips.noteDurchschnitt ? spieler.tips.noteDurchschnitt.toFixed(1) : ''}
      </span>
    </td>
    <td style={{ width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
      <NoteDropdown feldName="tipsEntwicklung"
                    feldLabel={i18n.tips.entwicklungLang}
                    feldWert={spieler.tips.entwicklung || ''}
                    requestDaten={requestDaten}
                    onValueChange={(neuerWert) => {
                      spielerTipsEntwicklungSetzen(spieler.id, neuerWert, spieler.tips)
                    }}/>
    </td>
    <td style={{ width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
      <NoteDropdown feldName="tipsNote"
                    feldLabel={i18n.tips.noteLang}
                    feldWert={spieler.tips.note || ''}
                    einfaerben={true}
                    requestDaten={requestDaten}
                    onValueChange={(neuerWert) => {
                      spielerTipsNoteSetzen(spieler.id, neuerWert, spieler.tips)
                    }}/>
    </td>
    <td style={{ verticalAlign: 'middle' }}>
      <Tooltip content={i18n.prognoseFragen.titel}>
        {prognoseWert <= 0 &&
          <button disabled={(aktiv || prognoseWert > 0) ? null : "disabled"}
                  name="button"
                  type="button"
                  className="btn btn-mini prognoseFragen"
                  onClick={() => spielerPrognoseEinAusblenden(spieler.id)}>
            <i className="icon-question-sign"/>
          </button>
        }
        {prognoseWert > 0 &&
          <span className={`leistung fakeInput ${darstellung.farbe}`}
                onClick={() => spielerPrognoseEinAusblenden(spieler.id)}>
        {darstellung.buchstabe}
      </span>
        }
      </Tooltip>
    </td>
    <td>&nbsp;</td>
    <td style={{ verticalAlign: 'middle' }}>
      <LoeschButton spieler={spieler}
                    spielerEinsatz={spielerEinsatz}
                    aktiv={aktiv}
                    requestDaten={requestDaten}/>
    </td>
  </tr>;

  const prognoseAnzeigen = prognoseFragen && prognoseFragen.prognoseAufgeklappt;

  return (
    <tbody>
    {spielerRow}
    {prognoseAnzeigen &&
      <tr>
        <td colSpan="14" className="text-right">
          <div style={{ display: 'inline-block' }}>
            <SpielerPrognoseFragen spieler={spieler}/>
          </div>
        </td>
      </tr>
    }
    <tr>
      <td colSpan="4"></td>
      <td colSpan="10">
        <Textfeld
          feldName="tipsKommentar"
          feldWert={spieler.tips.kommentar || ''}
          requestDaten={requestDaten}
          breite='403px'
          hoehe='80px'
          onChange={(event) => {
            spielerTipsKommentarSetzen(spieler.id, event.target.value, spieler.tips)
          }}
        />
      </td>
    </tr>
    {
      spieler.torhueterNoten.map((note: TorhueterNote) => {
        return (<tr key={'torhueterNote' + note.noteArtId + spieler.id}>
          <td>&nbsp;</td>
          <td colSpan="2">
            <strong>
              {note.name}{note.pflichtnote && "*"}
            </strong>
          </td>
          <td>
            <NoteDropdown feldName={'torhueterNote' + note.noteArtId}
                          feldWert={note.note || ''}
                          requestDaten={requestDaten}
                          forceReadonly={note.gesamtnote}
                          onValueChange={(neuerWert) => {
                            spielerTorhueterNoteSetzen(spieler.id, note.noteArtId, neuerWert);
                          }}
            />
          </td>
          <td colSpan="10">
            <Textfeld
              feldName={'torhueterNoteKommentar' + note.noteArtId}
              feldWert={note.kommentar || ''}
              requestDaten={requestDaten}
              breite='403px'
              hoehe='60px'
              onChange={(event) => {
                spielerTorhueterNoteKommentarSetzen(spieler.id, note.noteArtId, event.target.value)
              }}
            />
          </td>
        </tr>);
      })
    }
    </tbody>);
};

type Props = {
  einsatz: EinsatzState,
  spiel: SpielState,
  i18n: any,
  spielerPrognoseEinAusblenden: (number) => void,
  spielerTipsTSetzen: (number, string, SpielerTips) => void,
  spielerTipsISetzen: (number, string, SpielerTips) => void,
  spielerTipsPSetzen: (number, string, SpielerTips) => void,
  spielerTipsSSetzen: (number, string, SpielerTips) => void,
  spielerTipsNoteSetzen: (number, string, SpielerTips) => void,
  spielerTipsEntwicklungSetzen: (number, string, SpielerTips) => void,
  spielerTipsKommentarSetzen: (number, string, SpielerTips) => void,
  spielerTorhueterNoteSetzen: (number, number, number | null) => void,
  spielerTorhueterNoteKommentarSetzen: (number, number, string) => void,
  spieler: SpielerEintrag,
  spielerEinsatz: SpielerkarteEintrag,
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    spiel: state.spiel,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    spielerPrognoseEinAusblenden: (spielerId: number) => {
      dispatch(spielerPrognoseEinAusblenden(spielerId));
    },
    spielerTipsTSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsTSetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsISetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsISetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsPSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsPSetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsSSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsSSetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsEntwicklungSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsEntwicklungSetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsNoteSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsNoteSetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsKommentarSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsKommentarSetzen(spielerId, neuerWert, altesTips));
    },
    spielerTorhueterNoteSetzen: (spielerId: number, noteArtId: number, neuerWert: number | null) => {
      dispatch(spielerTorhueterNoteSetzen(spielerId, noteArtId, neuerWert));
    },
    spielerTorhueterNoteKommentarSetzen: (spielerId: number, noteArtId: number, neuerWert: string) => {
      dispatch(spielerTorhueterNoteKommentarSetzen(spielerId, noteArtId, neuerWert));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpielerkarteTorhueterNoten);
