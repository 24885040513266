// @flow

import type {AppState, EinsatzState} from "../state/stateTypes";
import type {Dispatch} from "../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import {mitTimeout, apiRequestAbschicken} from "../state/modules/ajax";

const TextfeldZeile = (props: {
  einsatz: EinsatzState,
  i18n: any,
  pfade: any,
  feldAnApiSchicken: (string, string, string | number | null, {}) => void,
  feldName: string,
  feldTitel: string,
  feldWert: number,
  requestDaten: {},
  onChange: (any) => void,
  platzhalter: string | void,
}) => {
  let handleChange = (event) => {
    let neuerWert = event.target.value;
    mitTimeout(props.feldName, () => {
      props.feldAnApiSchicken(
        props.pfade.api.einsatz,
        props.feldName,
        neuerWert,
        props.requestDaten || {}
      );
    });
    props.onChange(event);
  };

  const aktiv = !props.einsatz.readonly;

  return (
    <tr className="control-group">
      <th>
        <label className="control-label" htmlFor={props.feldName} style={{width: '170px'}}>
          {props.feldTitel}
        </label>
      </th>
      <td>
        <div className="controls">
          {aktiv &&
          <textarea
            style={{width: '600px', height: '60px'}}
            placeholder={props.platzhalter}
            value={props.feldWert || ''}
            name={props.feldName}
            onChange={handleChange}
          />
          }
          {aktiv ||
          <span className="textfeld-zeile-readonly">{props.feldWert}</span>
          }
        </div>
      </td>
    </tr>
  );
};

TextfeldZeile.defaultProps = {
  onChange: (event) => {
    // noop
  },
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    i18n: state.i18n,
    pfade: state.pfade,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    feldAnApiSchicken: (apiUrl: string, feldName: string, neuerWert: string | number | null, requestDaten: {}) => {
      dispatch(apiRequestAbschicken(apiUrl, 'PATCH', feldName, neuerWert, requestDaten, () => {
      }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TextfeldZeile);
