import React from "react";
import {connect} from "react-redux";

const AufgabeLabel = ({aufgabeZuteilung, texte}) => {
  let text = '';
  let cssLabel = 'label-info';
  if (aufgabeZuteilung.kommentarAbgeschlossen) {
    text = texte.aufgabeBeendet;
    cssLabel = 'label-success';
  } else {
    text = texte.aufgabeOffen;
    if (aufgabeZuteilung.bis < new Date()) {
      cssLabel = 'label-important';
    }
  }
  return (
    <>
      {aufgabeZuteilung.beurteilungAbgeschlossen &&
      <span className={`label label-important`}>{texte.kursleiter.abgeschlossen}</span>
      }
      <span className={`label ${cssLabel}`}>{text}</span>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    texte: state.i18n.texte
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AufgabeLabel);
