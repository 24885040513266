// @flow

import type { AppState } from "../../../state/stateTypes";
import type { Dispatch } from "../../../state/actionTypes";

import React, { useState } from "react";
import { connect } from "react-redux";
import { prognoseWertBerechnen } from "../../../../../lib/prognoseFragen";

const PrognoseDropdown = ({ aktuellerWert, onValueChange, readonly }: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const prognoseSetzen = (note) => {
    onValueChange(note);
    setShowDropdown(false);
  };

  let cssClasses = 'text-center leistung';
  if(!readonly) {
    cssClasses += ' simple-select';
  }
  if(aktuellerWert !== null) {
    const darstellung = prognoseWertBerechnen(aktuellerWert);
    cssClasses += ` ${darstellung.farbe}`;
  }

  return (
    <span className="note-container" onFocus={() => setShowDropdown(!readonly && true)} onBlur={() => setShowDropdown(false)}>
      <input
        type={"text"}
        disabled={readonly ? "disabled" : null}
        value={aktuellerWert || undefined}
        className={cssClasses}
        style={{ width: 22, position: 'relative' }}
        onChange={() => {}}
        rel={"tooltip"}
      />
      {showDropdown &&
        <ul className={"simple-select-list text-center"}>
          <li onMouseDown={() => setShowDropdown(false)}>-</li>
          {[6, 5, 4, 3, 2, 1].map((note) => <li key={note} onMouseDown={() => prognoseSetzen(note)}>{note}</li>)}
        </ul>
      }
    </span>
  );
};

type Props = {
  aktuellerWert: number | null,
  onValueChange: (number | null) => void,
  readonly: boolean | void,
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    pfade: state.pfade,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrognoseDropdown);
