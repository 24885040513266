import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {DndProvider} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import Bibliothek from "./Bibliothek";
import TeamAbschluss from "../TeamAbschluss";
import ArbeitsraumKlasse from "./ArbeitsraumKlasse";
import TeamAufgabenZuteilen from "./TeamAufgabenZuteilen";
import AufgabenBewerten from "./AufgabenBewerten";
import FreigegebeneAufgaben from "../FreigegebeneAufgaben";

const VIEWS = {
  aufgabenZuteilung: 'aufgabenZuteilung',
  aufgabenAbschluss: 'aufgabenAbschluss',
  teamAbschluss: 'teamAbschluss',
  nachrichten: 'nachrichten',
  alleAufgaben: 'alleAufgaben',
  freigegebeneAufgaben: 'freigegebeneAufgaben',
};

const Ansichten = ({ausbildung, teams, hatPruefungErgebnis, texte}) => {
  const [ansicht, setAnsicht] = useState(null);

  useEffect(() => {
    const jetzt = new Date(); // tab abschluss anzeigen, wenn kurs jetzt stattfindet
    if (hatPruefungErgebnis && (!ausbildung.bearbeitbar || ausbildung.bis < jetzt)) {
      setAnsicht(VIEWS.teamAbschluss);
    } else if ((hatPruefungErgebnis && ausbildung.von < jetzt && ausbildung.bis > jetzt) || (!hatPruefungErgebnis && ausbildung.von < jetzt)) {
      setAnsicht(VIEWS.aufgabenAbschluss);
    } else {
      setAnsicht(VIEWS.aufgabenZuteilung);
    }
  }, []);

  const navClick = (event, neueAnsicht) => {
    event.stopPropagation();
    event.preventDefault();
    setAnsicht(neueAnsicht);
  };

  return (
    <>
      <div>
        <ul className="nav nav-tabs">
          {ausbildung.bearbeitbar &&
          <li className={ansicht === VIEWS.aufgabenZuteilung ? 'active' : ''}>
            <a href="" onClick={(ev) => navClick(ev, VIEWS.aufgabenZuteilung)}>{texte.aufgabenZuteilung}</a>
          </li>
          }
          <li className={ansicht === VIEWS.aufgabenAbschluss ? 'active' : ''}>
            <a href="" onClick={(ev) => navClick(ev, VIEWS.aufgabenAbschluss)}>{texte.aufgabenAbschluss}</a>
          </li>
          {hatPruefungErgebnis &&
          <li className={ansicht === VIEWS.teamAbschluss ? 'active' : ''}>
            <a href="" onClick={(ev) => navClick(ev, VIEWS.teamAbschluss)}>{texte.teamAbschluss}</a>
          </li>
          }
          <li className={ansicht === VIEWS.alleAufgaben ? 'active' : ''}>
            <a href="" onClick={(ev) => navClick(ev, VIEWS.alleAufgaben)}>{texte.alleAufgaben}</a>
          </li>
          <li className={ansicht === VIEWS.freigegebeneAufgaben ? 'active' : ''}>
            <a href="" onClick={(ev) => navClick(ev, VIEWS.freigegebeneAufgaben)}>{texte.freigegebeneAufgaben}</a>
          </li>
          {ausbildung.bearbeitbar &&
          <li className={ansicht === VIEWS.nachrichten ? 'active' : ''}>
            <a href="" onClick={(ev) => navClick(ev, VIEWS.nachrichten)}>{texte.nachrichten}</a>
          </li>
          }
        </ul>
      </div>
      {ansicht === VIEWS.aufgabenZuteilung && ausbildung.bearbeitbar &&
      <DndProvider backend={HTML5Backend}>
        <Bibliothek/>
        {teams.map(team => <TeamAufgabenZuteilen key={team.id} team={team}/>)}
      </DndProvider>
      }
      {ansicht === VIEWS.aufgabenAbschluss &&
      <AufgabenBewerten/>
      }
      {ansicht === VIEWS.teamAbschluss &&
      <TeamAbschluss/>
      }
      {ansicht === VIEWS.alleAufgaben &&
      <FreigegebeneAufgaben alleAufgaben={true}/>
      }
      {ansicht === VIEWS.freigegebeneAufgaben &&
      <FreigegebeneAufgaben/>
      }
      {ansicht === VIEWS.nachrichten && ausbildung.bearbeitbar &&
      <ArbeitsraumKlasse/>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ausbildung: state.ausbildung,
    teams: state.teams,
    hatPruefungErgebnis: state.ausbildung.hatPruefungErgebnis,
    texte: state.i18n.texte.ansichten,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Ansichten);
