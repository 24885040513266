import {combineReducers} from "redux";
import {dummyReducer} from "../../../lib/datenhaltung";
import rollbarReducer from "../../../reducers/rollbar";
import ligenReducer from "./modules/ligen";
import spielgruppenReducer from "./modules/spielgruppen";
import spieleReducer from "./modules/spiele";
import statusReducer from "./modules/status";
import teamsReducer from "./modules/teams";

export default combineReducers({
  ligen: ligenReducer,
  spielgruppen: spielgruppenReducer,
  spiele: spieleReducer,
  teams: teamsReducer,
  spielstatusListe: dummyReducer,
  status: statusReducer,
  saisons: dummyReducer,
  api: dummyReducer,
  i18n: dummyReducer,
  rollbar: rollbarReducer,
});
