import {executeRequest} from "../../lib/api";
import {ereignisEditStart, ereignisEditStop} from "./editMarkierer";
import {berechneSpielUndZusatzminute, EREIGNIS_SUBTYP, EREIGNIS_TYP} from "../../lib/spielablauf";
import {teamSpielerkarteAuswechslung, teamSpielerkarteGelbeKarte, teamSpielerkartePenaltyschiessen, teamSpielerkartePenaltyschiessenBeginnt, teamSpielerkarteRoteKarte, teamSpielerkarteTor, teamSpielerkarteZustandReset} from "./teamSpielerkarte";
import {spielanzeigeToreSetzen} from "./spielanzeige";
import {heartbeatAbfrageEreignisse} from "./heartbeat";

const SPIELVERLAUF_ABFRAGEN = 'SPIELVERLAUF_ABFRAGEN';
const SPIELVERLAUF_EREIGNIS_HINZUFUEGEN = 'SPIELVERLAUF_EREIGNIS_HINZUFUEGEN';
const SPIELVERLAUF_EREIGNIS_UPDATEN = 'SPIELVERLAUF_EREIGNIS_UPDATEN';
const SPIELVERLAUF_EREIGNIS_LOESCHEN = 'SPIELVERLAUF_EREIGNIS_LOESCHEN';

function sortiereEreignisse(a, b) {
  if (a.minute === b.minute) {
    if (a.zusatzminute === b.zusatzminute) {
      return a.sekunden - b.sekunden;
    }
    return a.zusatzminute - b.zusatzminute;
  }
  return a.minute - b.minute;
}

export function ereignisseAbfragen() {
  return (dispatch, getState) => {
    const api = getState().api;
    dispatch(heartbeatAbfrageEreignisse()); // setzt zeitstempel dieser abfrage (pesimistisch: vor ajax-abfrage), damit der heartbeat erkennen kann ob erneute abfrage nötig ist. 
    return executeRequest(api.ereignisse, api.token, 'GET', {}, (result) => {
      dispatch({type: SPIELVERLAUF_ABFRAGEN, ereignisse: result.data});
      dispatch(replayEreignisse());
    });
  };
}

export function replayEreignisse() {
  return (dispatch, getState) => {

    // spielzustand reset für beide teams
    dispatch(teamSpielerkarteZustandReset(getState().heim.team.id));
    dispatch(teamSpielerkarteZustandReset(getState().gast.team.id));

    let state = getState();
    let zwischenresultat = null;
    // alle ereignisse durchlaufen und zustand entsprechend vom ereignis-typ verändern
    state.spielverlauf.forEach(ereignis => {
      state = getState();
      switch (ereignis.typ_id) {
        case EREIGNIS_TYP.tor:
          dispatch(teamSpielerkarteTor(ereignis.team_id, ereignis));
          zwischenresultat = ereignis.zwischenresultat;
          break;
        case EREIGNIS_TYP.auswechslung:
          const team_zustand = state.heim.team.id == ereignis.team_id ? state.heim.zustand : state.gast.zustand;
          if (team_zustand.feldspieler.find(s => s.id === ereignis.spieler_id && s.unregistriert === ereignis.unregistrierter_spieler) === undefined ||
            team_zustand.ersatzspieler.find(s => s.id === ereignis.auswechsel_spieler_id && s.unregistriert === ereignis.auswechsel_unregistrierter_spieler) === undefined) {
            // chronologie fehler: feldspieler ist nicht auf feld und/oder ersatzspieler nicht auf ersatzbank
            const fehlerEreignis = {...ereignis, hatFehler: true};
            dispatch({type: SPIELVERLAUF_EREIGNIS_UPDATEN, ereignis: fehlerEreignis});
          }
          dispatch(teamSpielerkarteAuswechslung(ereignis.team_id, ereignis));
          break;
        case EREIGNIS_TYP.gelbeKarte:
          dispatch(teamSpielerkarteGelbeKarte(ereignis.team_id, ereignis));
          break;
        case EREIGNIS_TYP.roteKarte:
          dispatch(teamSpielerkarteRoteKarte(ereignis.team_id, ereignis));
          break;
        case EREIGNIS_TYP.penaltyschiessen:
          dispatch(teamSpielerkartePenaltyschiessen(ereignis.team_id, ereignis));
          if (ereignis.subtyp_id === EREIGNIS_SUBTYP.penaltyschiessen_tor) {
            zwischenresultat = ereignis.zwischenresultat;
          }
          break;
        case EREIGNIS_TYP.spielinformation:
          switch (ereignis.subtyp_id) {
            case EREIGNIS_SUBTYP.penaltyschiessen_beginnt:
              dispatch(teamSpielerkartePenaltyschiessenBeginnt(state.heim.team.id));
              dispatch(teamSpielerkartePenaltyschiessenBeginnt(state.gast.team.id));
              break;
          }
          break;
      }
    });

    if (zwischenresultat != null) {
      // spielanzeige tore anhand letztem tor setzen
      const [heimTore, gastTore] = zwischenresultat.split(':').map(tore => parseInt(tore, 10));
      dispatch(spielanzeigeToreSetzen(heimTore, gastTore));
    }
  };
}

export function generischesEreignisErstellen(heimOderGast, ereignisDaten) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;

    // spieler ist nicht echt ausgewählt, muss aber für db vorhanden sein (not null feld)
    let spieler = heimOderGast.spieler[0];

    const daten = {
      ...ereignisDaten,
      spieler_id: spieler.id,
      unregistrierter_spieler: spieler.unregistrierter_spieler,
      team_id: heimOderGast.team.id,
      tore_neu_berechnen: false,
      bestaetigt: false
    };
    switch (ereignisDaten.typ_id) {
      case EREIGNIS_TYP.auswechslung:
        // auswechselspieler ist nicht echt ausgewählt, muss aber bei typ "auswechlsung" für ereignis-validierung vorhanden sein
        let auswechselspieler = heimOderGast.spieler[1];
        daten.auswechsel_spieler_id = auswechselspieler.id;
        daten.auswechsel_unregistrierter_spieler = auswechselspieler.unregistrierter_spieler;
        break;
      case EREIGNIS_TYP.tor:
        // ergänzugen für tor
        let zwischenresultat = state.heartbeat.spiel.zwischenresultat;
        let resultat = [zwischenresultat.heim, zwischenresultat.gast];
        if (ereignisDaten.subtyp_id == EREIGNIS_SUBTYP.eigentor) {
          heimOderGast.team.id === state.heim.team.id ? resultat[1]++ : resultat[0]++;
        } else {
          heimOderGast.team.id === state.heim.team.id ? resultat[0]++ : resultat[1]++;
        }
        daten.zwischenresultat = resultat.join(':');
        daten.tore_neu_berechnen = true;
        break;
      case EREIGNIS_TYP.assist:
        // Assist übernimmt Zeitangaben von Tor
        let tor = state.spielverlauf.filter((tor) => tor.zwischenresultat === ereignisDaten.zwischenresultat && tor.typ_id === EREIGNIS_TYP.tor);
        daten.minute = tor[0].minute;
        daten.zusatzminute = tor[0].zusatzminute;
        daten.sekunden = tor[0].sekunden;
        break;
    }

    return executeRequest(api.ereignisse, api.token, 'POST', daten, (result) => {
      if (result.ok) {
        dispatch({type: SPIELVERLAUF_EREIGNIS_HINZUFUEGEN, ereignis: result.data});
        // generisches ereignis sofort in edit-modus setzen!
        dispatch(ereignisEditStart(result.data.id));
      } else {
        if (result.status === 409 || result.status === 422) {
          // TODO: fehler, was nun / tun? 409 und 422 sind möglich
        }
      }
    });
  };
}

export function spielerEreignisErstellen(spieler, ereignisTyp, ereignisSubtyp, team, zusatzdaten = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    let spielminute;
    let zusatzminute;
    let sekunden = 0; // default benutzen
    switch (ereignisTyp.id) {
      case EREIGNIS_TYP.penaltyschiessen:
        // zeitangaben penalty-spezial: spielminute ist laufende spielzeit, zusatzminute ist 10x (x ist nummer vom penalty, start mit 101)
        spielminute = Math.floor(state.spielanzeige.laufendeSpielzeit / 60);
        zusatzminute = state.spielverlauf.filter(e => e.typ_id === EREIGNIS_TYP.penaltyschiessen).length + 101;
        break;
      case EREIGNIS_TYP.assist:
        // Assist übernimmt Zeitangaben von Tor
        let tor = state.spielverlauf.filter((tor) => tor.zwischenresultat === zusatzdaten.zwischenresultat && tor.typ_id === EREIGNIS_TYP.tor);
        spielminute = tor[0].minute;
        zusatzminute = tor[0].zusatzminute;
        sekunden = tor[0].sekunden;
        break;
      default:
        const laufendeMinute = Math.floor(state.spielanzeige.laufendeSpielzeit / 60) + 1;
        [spielminute, zusatzminute] = berechneSpielUndZusatzminute(state.heartbeat.spiel.periode, state.liga, laufendeMinute);
        sekunden = state.spielanzeige.laufendeSpielzeit % 60;
        break;
    }

    const daten = {
      ...zusatzdaten,
      typ_id: ereignisTyp.id,
      subtyp_id: ereignisSubtyp.id,
      minute: spielminute,
      zusatzminute: zusatzminute,
      sekunden: sekunden,
      spieler_id: spieler.id,
      unregistrierter_spieler: spieler.unregistrierter_spieler,
      team_id: team.id,
      tore_neu_berechnen: false,
    };

    return executeRequest(api.ereignisse, api.token, 'POST', daten, (result) => {
      if (result.ok) {
        dispatch({type: SPIELVERLAUF_EREIGNIS_HINZUFUEGEN, ereignis: result.data});
        dispatch(replayEreignisse());
        if (result.data.typ_id === EREIGNIS_TYP.default) {
          // generisches ereignis sofort in edit-modus setzen!
          dispatch(ereignisEditStart(result.data.id));
        }
      } else {
        if (result.status === 409 || result.status === 422) {
          // TODO: fehler, was nun / tun? 409 und 422 sind möglich
          dispatch(ereignisseAbfragen());
        }
      }
    });
  };
}

export function spielerEreignisUpdate(ereignis, spielerId, unregistrierterSpieler, spielminute, zusatzminute, sekunden, zusatzdaten = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;

    const daten = {
      ...zusatzdaten,
      minute: spielminute,
      zusatzminute: zusatzminute,
      sekunden: sekunden,
      spieler_id: spielerId,
      unregistrierter_spieler: unregistrierterSpieler,
      team_id: ereignis.team_id,
      tore_neu_berechnen: false,
      zwischenresultat: ereignis.zwischenresultat
    };
    if (ereignis.hat_film) {
      // evt umstellung tor zu eigentor oder chance -> tore stimmen nicht mehr
      daten.tore_neu_berechnen = true;
      // spieler ist evt im anderen team: zur sicherheit team-id vom gewählten spieler neu setzen
      if (state.heim.spieler.find(s => s.id === spielerId && s.unregistrierter_spieler === unregistrierterSpieler)) {
        daten.team_id = state.heim.team.id;
      } else if (state.gast.spieler.find(s => s.id === spielerId && s.unregistrierter_spieler === unregistrierterSpieler)) {
        daten.team_id = state.gast.team.id;
      }
    } else {
      daten.typ_id = ereignis.typ_id;
      daten.subtyp_id = ereignis.subtyp_id;
    }
    if (ereignis.typ_id === EREIGNIS_TYP.tor) {
      daten.tore_neu_berechnen = true;
    }

    return executeRequest(`${api.ereignisse}/${ereignis.id}`, api.token, 'PUT', daten, (result) => {
      dispatch(ereignisEditStop(result.data.id));
      if (daten.tore_neu_berechnen || result.status === 422) {
        // zwischenresultate aller tor-ereignisse könnten geändert haben - ODER - 
        // code 422 (unprocessable entity): ereigniss hat irgendwelche fehler
        // -> reload all! (weil einfacher als alles andere)
        dispatch(ereignisseAbfragen());
      } else {
        dispatch({type: SPIELVERLAUF_EREIGNIS_UPDATEN, ereignis: result.data});
        dispatch(replayEreignisse());
      }
    });
  };
}

export function spielerEreignisLoeschen(ereignis) {
  return (dispatch, getState) => {
    const api = getState().api;

    return executeRequest(`${api.ereignisse}/${ereignis.id}`, api.token, 'DELETE', {}, (result) => {
      dispatch(ereignisEditStop(result.data.id));
      const spielverlauf = getState().spielverlauf;
      if (ereignis.typ_id === EREIGNIS_TYP.tor || (ereignis.typ_id === EREIGNIS_TYP.penaltyschiessen && ereignis.subtyp_id === EREIGNIS_SUBTYP.penaltyschiessen_tor)) {
        dispatch(ereignisseAbfragen());
      } else {
        dispatch({type: SPIELVERLAUF_EREIGNIS_LOESCHEN, ereignisId: ereignis.id});
      }
      dispatch(replayEreignisse());
    });
  };
}

export function staffEreignisErstellen(staff, ereignisTyp, ereignisSubtyp, team, zusatzdaten = {}) {
  return (dispatch, getState) => {
    let state = getState();
    const api = state.api;
    const laufendeMinute = Math.floor(state.spielanzeige.laufendeSpielzeit / 60) + 1;
    const [spielminute, zusatzminute] = berechneSpielUndZusatzminute(state.heartbeat.spiel.periode, state.liga, laufendeMinute);
    const sekunden = state.spielanzeige.laufendeSpielzeit % 60;

    const daten = {
      ...zusatzdaten,
      typ_id: ereignisTyp.id,
      subtyp_id: ereignisSubtyp.id,
      minute: spielminute,
      zusatzminute: zusatzminute,
      sekunden: sekunden,
      staff_id: staff.id,
      unregistrierter_spieler: false,
      team_id: team.id,
      tore_neu_berechnen: false,
    };

    return executeRequest(api.ereignisse, api.token, 'POST', daten, (result) => {
      if (result.ok) {
        dispatch({type: SPIELVERLAUF_EREIGNIS_HINZUFUEGEN, ereignis: result.data});
        dispatch(replayEreignisse());
      } else {
        if (result.status === 409 || result.status === 422) {
          // TODO: fehler, was nun / tun? 409 und 422 sind möglich
        }
      }
    });
  };
}

export function staffEreignisUpdate(ereignis, staffId, spielminute, zusatzminute, sekunden) {
  return (dispatch, getState) => {
    const api = getState().api;

    const daten = {
      typ_id: ereignis.typ_id,
      subtyp_id: ereignis.subtyp_id,
      minute: spielminute,
      zusatzminute: zusatzminute,
      sekunden: sekunden,
      staff_id: staffId,
      unregistrierter_spieler: false,
      team_id: ereignis.team_id,
      tore_neu_berechnen: false
    };

    return executeRequest(`${api.ereignisse}/${ereignis.id}`, api.token, 'PUT', daten, (result) => {
      dispatch(ereignisEditStop(result.data.id));
      if (result.status === 422) {
        // zwischenresultate aller tor-ereignisse könnten geändert haben - ODER - 
        // code 422 (unprocessable entity): ereigniss hat irgendwelche fehler
        // -> reload all! (weil einfacher als alles andere)
        dispatch(ereignisseAbfragen());
      } else {
        dispatch({type: SPIELVERLAUF_EREIGNIS_UPDATEN, ereignis: result.data});
        dispatch(replayEreignisse());
      }
    });
  };
}

export function ereignisZuStateHinzufuegen(ereignis) {
  return (dispatch) => {
    dispatch({type: SPIELVERLAUF_EREIGNIS_HINZUFUEGEN, ereignis: ereignis});
  };
}

function spielverlaufReducer(state = [], action) {
  switch (action.type) {
    case SPIELVERLAUF_EREIGNIS_HINZUFUEGEN:
      const liste1 = [...state, action.ereignis];
      liste1.sort(sortiereEreignisse);
      return liste1;
    case SPIELVERLAUF_EREIGNIS_UPDATEN:
      // aktualisiertes ereignis austauschen
      const liste2 = state.map(e => e.id === action.ereignis.id ? action.ereignis : e);
      liste2.sort(sortiereEreignisse);
      return liste2;
    case SPIELVERLAUF_EREIGNIS_LOESCHEN:
      // gelöschtes ereignis filtern
      return state.filter(e => e.id != action.ereignisId);
    case SPIELVERLAUF_ABFRAGEN:
      return action.ereignisse;
    default:
      return state;
  }
}

export default spielverlaufReducer;
