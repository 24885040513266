import React from "react";
import {connect} from "react-redux";
import {fehlermeldungDelete} from "../state/fehlermeldungReducer";

const Fehlermeldungen = ({fehlermeldungen, deleteMeldung}) => {
  if (fehlermeldungen.length > 0) {
    return (
      <div className="fehlermeldungen">
        {fehlermeldungen.map((f, index) => {
          return <div key={index} className="alert alert-error">
            <a className="close" onClick={() => deleteMeldung(index)}>×</a>
            <span>{f}</span>
          </div>;
        })}
      </div>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state) => {
  return {
    fehlermeldungen: state.fehlermeldungen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteMeldung: (index) => { dispatch(fehlermeldungDelete(index)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Fehlermeldungen);
