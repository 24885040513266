import React, {useState} from 'react'
import {connect} from "react-redux";

import Tooltip, {POSITION} from "../../../components/Tooltip";
import EreignisIcon, {ICON_TYP} from "./EreignisIcon";
import {EREIGNIS_SUBTYP, EREIGNIS_TYP} from "../lib/spielablauf";
import Video from "../../../components/Video";
import {ereignisEditStart} from "../state/modules/editMarkierer";


const RenderZeitangabe = (ereignis) => {
  if (ereignis.zusatzminute > 0 && ereignis.zusatzminute < 99) {
    return `${ereignis.minute}+${ereignis.zusatzminute}'`
  } else {
    return `${ereignis.minute}'`
  }
};

const RenderIconZeitlinie = (ereignis) => {
  switch (ereignis.typ_id) {
    case EREIGNIS_TYP.tor:
    case EREIGNIS_TYP.penaltyschiessen:
    case EREIGNIS_TYP.auswechslung:
    case EREIGNIS_TYP.gelbeKarte:
    case EREIGNIS_TYP.roteKarte:
      return <EreignisIcon ereignis={ereignis}/>
    default:
      return <EreignisIcon ereignisTyp={ICON_TYP.punkt}/>
  }
};

// render-helpers für alle typen
const Tor = (ereignis) => {
  // TODO: assist hier anzeigen, und dafür im Ticker.jsx rausfiltern?
  return (
    <>
      <span className="titel">{ereignis.spieler_name}</span>
      {ereignis.typ_name}
      {(ereignis.subtyp_id === EREIGNIS_SUBTYP.eigentor || ereignis.subtyp_id === EREIGNIS_SUBTYP.penaltytor) &&
        `, ${ereignis.subtyp_name}`
      }
    </>
  );
};

const Penaltyschiessen = (ereignis) => {
  return (
    <>
      <span className="titel">{ereignis.spieler_name}</span>
      {`${ereignis.typ_name}, ${ereignis.subtyp_name}`}
    </>
  );
};

const Auswechslung = (ereignis, auswechselspieler, istHeimTeam) => {
  let auswechselspieler_name = auswechselspieler ? auswechselspieler.name : '';
  if (istHeimTeam) {
    return (
      <>
        <span>{ereignis.spieler_name}<EreignisIcon ereignisTyp={ICON_TYP.auswechsel}/></span>
        <span className="titel">{auswechselspieler_name}<EreignisIcon ereignisTyp={ICON_TYP.einwechsel}/></span>
      </>
    );
  } else {
    return (
      <>
        <span><EreignisIcon ereignisTyp={ICON_TYP.auswechsel}/>{ereignis.spieler_name}</span>
        <span className="titel"><EreignisIcon ereignisTyp={ICON_TYP.einwechsel}/>{auswechselspieler_name}</span>
      </>
    );
  }
};

const Karte = (ereignis) => {
  const grund = ereignis.subtyp_id == EREIGNIS_SUBTYP.default ? ereignis.typ_name : ereignis.subtyp_name;
  return (
    <>
      <span className="titel">{ereignis.spieler_name || ereignis.staff_name}</span>
      {grund}
    </>
  );
};

const Spielinformation = (ereignis) => {
  return (<span>{ereignis.subtyp_name}</span>);
};

const TickerEreignisView = ({ereignis, spielIstAbgeschlossen, heim, gast, texte, editEreignisStart}) => {
  const [zeigeVideo, setZeigeVideo] = useState(false);

  const ereignisEditable = !spielIstAbgeschlossen && ereignis.typ_id != EREIGNIS_TYP.spielinformation;
  const renderFuerHeimteam = (ereignis.team_id === null || ereignis.team_id === heim.team.id);
  const renderFuerGastteam = (ereignis.team_id === null || ereignis.team_id === gast.team.id);

  // event handlers
  const onEditClick = () => {
    if (ereignisEditable) {
      editEreignisStart(ereignis.id);
    }
  };

  const zeigeVideoClick = (e) => {
    setZeigeVideo(!zeigeVideo);
    e.stopPropagation();
  };

  const alleSpielerFuerTeam = (teamId) => {
    if (heim.team.id === teamId) {
      return heim.spieler;
    } else {
      return gast.spieler;
    }
  };

  // rendering
  const renderEreignis = (ereignis) => {
    if (!ereignis.bestaetigt) {
      return (
        <>
          <span className="titel">???</span>
          {ereignis.typ_name}
        </>
      );
    }
    
    switch (ereignis.typ_id) {
      case EREIGNIS_TYP.spielinformation:
        return Spielinformation(ereignis);
      case  EREIGNIS_TYP.tor:
        return Tor(ereignis);
      case EREIGNIS_TYP.auswechslung:
        const auswechelspieler = alleSpielerFuerTeam(ereignis.team_id).find(s => s.id === ereignis.auswechsel_spieler_id && s.unregistrierter_spieler === ereignis.unregistrierter_spieler);
        return Auswechslung(ereignis, auswechelspieler, renderFuerHeimteam);
      case EREIGNIS_TYP.gelbeKarte:
      case EREIGNIS_TYP.roteKarte:
        return Karte(ereignis);
      case EREIGNIS_TYP.penaltyschiessen:
        return Penaltyschiessen(ereignis);
      default:
        if (ereignis.spieler_name) {
          return (
            <>
              <span className="titel">{ereignis.spieler_name}</span>
              {ereignis.typ_name}
            </>
          );
        } else {
          return <span className="titel">{ereignis.typ_name}</span>;
        }
    }
  };

  return (
    <>
      <div className={"eintrag flex-row" + (ereignisEditable ? ' editable' : '') + (ereignis.hatFehler || !ereignis.bestaetigt ? ' error' : '')} onClick={onEditClick}>
          <span className="links flex1">
            {renderFuerHeimteam &&
              renderEreignis(ereignis)
            }
            {renderFuerGastteam && ereignis.hat_film &&
              <Tooltip content={texte.video_anzeigen} position={POSITION.right}>
                <EreignisIcon ereignisTyp={ICON_TYP.hatFilm} className="ikon-pointer" onClick={zeigeVideoClick}/>
              </Tooltip>
            }
          </span>
        <span className="zeitlinie flex-row">
            <span className="flex1 text-right">
              {renderFuerHeimteam && ereignis.typ_id != EREIGNIS_TYP.spielinformation &&
                RenderZeitangabe(ereignis)
              }
            </span>
            <span className="streifen">
              {
                RenderIconZeitlinie(ereignis)
              }
            </span>
            <span className="flex1">
              {renderFuerGastteam &&
                RenderZeitangabe(ereignis)
              }
            </span>
          </span>
        <span
          className="rechts flex1 text-right ">
            {renderFuerGastteam && ereignis.typ_id != EREIGNIS_TYP.spielinformation &&
              renderEreignis(ereignis)
            }
          {renderFuerHeimteam && ereignis.hat_film &&
            <Tooltip content={texte.video_anzeigen}>
              <EreignisIcon ereignisTyp={ICON_TYP.hatFilm} className="ikon-pointer" onClick={zeigeVideoClick}/>
            </Tooltip>
          }
          </span>
      </div>
      {zeigeVideo &&
        <div className="flex-row">
          <Video src={ereignis.film_url}/>
        </div>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    spielIstAbgeschlossen: state.spiel.ist_abgeschlossen,
    heim: state.heim,
    gast: state.gast,
    liga: state.liga,
    texte: state.i18n.ticker,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editEreignisStart: (ereignisId) => { dispatch(ereignisEditStart(ereignisId)) },
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(TickerEreignisView);
