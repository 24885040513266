import {executeRequest} from "../lib/api";

const ARBEITSRAUM_ERSTELLEN = 'ARBEITSRAUM_ERSTELLEN';
const ARBEITSRAUM_GELESEN = 'ARBEITSRAUM_GELESEN';

export function arbeitsraumSpeichern(aufgabeZuteilung, zusatzdaten) {
  return (dispatch, getState) => {
    const api = getState().api;
    const daten = {
      ...zusatzdaten,
      kontext: 'arbeitsraum_erstellen',
    };
    if (aufgabeZuteilung) { // nicht vorhanden bei eintrag für klasse!
      daten.aufgabeZuteilungId = aufgabeZuteilung.id;
      daten.teamId = aufgabeZuteilung.kursplanTeamsId;
    }
    return executeRequest(api.update, api.token, 'PUT', daten, (result) => {
      dispatch({type: ARBEITSRAUM_ERSTELLEN, arbeitsraum: result.data.arbeitsraum});
    });
  };
}
export function arbeitsraumGelesen(arbeitsraumIdListe, zusatzdaten = {}) {
  return (dispatch, getState) => {
    const api = getState().api;
    const daten = {
      ...zusatzdaten,
      kontext: 'arbeitsraum_gelesen',
      arbeitsraumIdListe: arbeitsraumIdListe,
    };
    return executeRequest(api.update, api.token, 'PUT', daten, (result) => {
      dispatch({type: ARBEITSRAUM_GELESEN, arbeitsraumIdListe: result.data.arbeitsraumIdListe});
    });
  };
}

function arbeitsraumReducer(state = [], action) {
  switch (action.type) {
    case ARBEITSRAUM_ERSTELLEN:
      const arr = [...state];
      arr.unshift(action.arbeitsraum);
      return arr;
    case ARBEITSRAUM_GELESEN:
      return state.map((eintrag) => {
        const ae = {...eintrag};
        if (action.arbeitsraumIdListe.includes(eintrag.id)) {
          ae.gelesen = true;
        }
        return ae;
      });
    default:
      return state;
  }
}

export default arbeitsraumReducer;
