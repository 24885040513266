// @flow

import type {AppState, SpielerState, SpielerkarteEintrag} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

const Feldspieler = (props: {
  spielerliste: SpielerState,
  pfade: any,
  i18n: any,
  einsatz: SpielerkarteEintrag,
}) => {
  const spieler = props.spielerliste.liste.find(s => s.id === props.einsatz.spielerId && s.nichtregistriert === props.einsatz.nichtregistriert);

  if (spieler) {
    return (
      <span className={"spieler"}>
      <div className="foto-container">
        <img src={spieler.bildUrl || props.pfade.platzhalter.spieler} alt={spieler.name}/>
      </div>
      <div className="nr">{props.einsatz.rueckennummer}</div>
      <span className="name" title={spieler.name}>{spieler.name}</span>
        {props.einsatz.spielfuehrer &&
        <span className="captain">C</span>
        }
    </span>
    );
  }
  return <></>;
};

const mapStateToProps = (state: AppState) => {
  return {
    spielerliste: state.spielerliste,
    pfade: state.pfade,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Feldspieler);
