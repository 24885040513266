const FILE_UPLOAD_SET = 'FILE_UPLOAD_SET';
const FILE_UPLOAD_END = 'FILE_UPLOAD_END';
const FILE_UPLOAD_REMOVE = 'FILE_UPLOAD_REMOVE';
const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';

export function startFileUpload(aufgabeZuteilungId) {
  return (dispatch) => {
    dispatch({type: FILE_UPLOAD_SET, id: aufgabeZuteilungId, progress: 0});
  };
}
export function updateFileUpload(aufgabeZuteilungId, progress) {
  return (dispatch) => {
    dispatch({type: FILE_UPLOAD_SET, id: aufgabeZuteilungId, progress: progress});
  };
}
export function endeFileUpload(aufgabeZuteilungId) {
  return (dispatch) => {
    dispatch({type: FILE_UPLOAD_END, id: aufgabeZuteilungId});
  };
}
export function removeFileUpload(aufgabeZuteilungId) {
  return (dispatch) => {
    dispatch({type: FILE_UPLOAD_REMOVE, id: aufgabeZuteilungId});
  };
}
export function errorFileUpload(aufgabeZuteilungId) {
  return (dispatch) => {
    dispatch({type: FILE_UPLOAD_ERROR, id: aufgabeZuteilungId});
  };
}

function fileUploadReducer(state = [], action) {
  switch (action.type) {
    case FILE_UPLOAD_SET:
      return [
        ...state.filter(fu => fu.id !== action.id),
        { id: action.id, running: true, progress: action.progress }
      ];
    case FILE_UPLOAD_END:
      return [
        ...state.filter(fu => fu.id !== action.id),
        { id: action.id, running: false, progress: 100 }
      ];
    case FILE_UPLOAD_REMOVE:
      return state.filter(fu => fu.id !== action.id);
    case FILE_UPLOAD_ERROR:
      const eintrag = state.find(fu => fu.id === action.id);
      return [
        ...state.filter(fu => fu.id !== action.id),
        {
          ...eintrag,
          running: false,
          error: true
        }
      ];
    default:
      return state;
  }

}

export default fileUploadReducer;
