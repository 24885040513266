import React, {createRef, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {AusbildungStatus, ViewKontext} from "../lib/constants";
import {ausbildnerAbschlussSpeichern} from "../state/ausbildnerReducer";

const AusbildnerAbschluss = ({ausbildner, ausbildung, dokumentPfad, abschlussStatusListe, texte, ausbildnerAbschlussSpeichern}) => {
  const [ergebnis, setErgebnis] = useState(ausbildner.ergebnis);
  const bearbeitbar = ausbildung.bearbeitbar && !ergebnis.beurteilungAbgeschlossen;
  const fileInput = createRef();
  const [fileName, setFileName] = useState(undefined);

  useEffect(() => {
    setErgebnis(ausbildner.ergebnis);
  }, [ausbildner]);

  const onFileChange = () => {
    if (fileInput.current.files.length > 0) {
      setFileName(fileInput.current.files[0].name);
    } else {
      setFileName(undefined);
    }
  };

  const inputChange = (feldName, wert) => {
    const temp = {...ergebnis};
    temp[feldName] = wert;
    setErgebnis(temp);
  };

  const speichern = (abschliessen) => {
    const zusatzdaten = {...ergebnis, abschliessen: abschliessen};
    if (fileInput.current.files.length > 0) {
      zusatzdaten['file'] = fileInput.current.files[0];
    }
    ausbildnerAbschlussSpeichern(ausbildner, zusatzdaten)
  };
  
  const statusDefaultValue = () => {
    return abschlussStatusListe.find(s => s.id === ergebnis.ausbildungStatusId) !== undefined ? ergebnis.ausbildungStatusId : AusbildungStatus.Bestanden;
  };

  return (
    <div className="content edit">
      {['noteTechnik', 'noteMethodik', 'noteKondition', 'noteTheorie', 'noteWeiterempfehlung', 'notePotenzial'].map(noteName => {
        return <div key={noteName} className="zeile">
          <label htmlFor={`${ausbildner.id}-${noteName}`}>{texte.teilnehmerAbschluss[noteName]}</label>
          <input disabled={!bearbeitbar} id={`${ausbildner.id}-${noteName}`} type="number" step="0.1" className="note" value={ergebnis[noteName]} onChange={(ev) => inputChange(noteName, ev.currentTarget.value)}/>
        </div>
      })}
      <div className="zeile">
        <label htmlFor={`${ausbildner.id}-note`}>{texte.teilnehmerAbschluss.note}</label>
        <input disabled={!bearbeitbar} id={`${ausbildner.id}-note`} type="text" className="" value={ergebnis.note} maxLength="10" onChange={(ev) => inputChange('note', ev.currentTarget.value)}/>
      </div>
      <div className="zeile">
        <label htmlFor={`${ausbildner.id}-notenblatt`}>{texte.teilnehmerAbschluss.notenblatt}</label>
        <input disabled={!bearbeitbar} id={`${ausbildner.id}-notenblatt`} type="text" className="" value={ergebnis.notenblatt} maxLength="50" onChange={(ev) => inputChange('notenblatt', ev.currentTarget.value)}/>
      </div>
      <div className="zeile">
        <label htmlFor={`${ausbildner.id}-abschlussBeurteilung`}>{texte.teilnehmerAbschluss.abschlussBeurteilung}</label>
        <textarea disabled={!bearbeitbar} id={`${ausbildner.id}-abschlussBeurteilung`} rows="5" className="inline" value={ergebnis.abschlussBeurteilung} onChange={(ev) => inputChange('abschlussBeurteilung', ev.currentTarget.value)}/>
      </div>
      <div className="zeile">
        <label htmlFor={`${ausbildner.id}-upload`}>{texte.teilnehmerAbschluss.abschlussDokument}</label>
        {bearbeitbar &&
        <>
          <input id={`${ausbildner.id}-upload`} type="file" ref={fileInput} accept="*/*" className="hide" onChange={onFileChange}/>
          <span className="btn btn-upload" onClick={() => fileInput.current.click()}>{texte.fileButton}</span>
          {fileName &&
          <>
            &nbsp;<span>{fileName}</span>
          </>
          }
        </>
        }
        {ergebnis.hatDokument &&
        <span className="datei-upload">
          <a href={`${dokumentPfad}?ergebnisTrainerId=${ergebnis.id}`} target="_blank" className={`ikon ${ergebnis.dokumentIcon}`}/>
        </span>
        }
      </div>
      {ausbildung.kontext === ViewKontext.Kursleiter &&
      <div className="zeile">
        <label htmlFor={`${ausbildner.id}-ausbildungStatus`}>{texte.teilnehmerAbschluss.ausbildungStatus}</label>
        <select disabled={!bearbeitbar} id={`${ausbildner.id}-ausbildungStatus`} className='select' value={statusDefaultValue()} onChange={(ev) => inputChange('ausbildungStatusId', ev.currentTarget.value)}>
          {abschlussStatusListe.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
        </select>
      </div>
      }
      {bearbeitbar &&
      <div className="zeile">
        <button className="btn btn-primary" onClick={() => speichern(false)}>{texte.speichern}</button>
        {ausbildung.kontext === ViewKontext.Kursleiter &&
        <button className="btn btn-success pull-right" onClick={() => speichern(true)}>{texte.abschliessen}</button>
        }
      </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ausbildung: state.ausbildung,
    dokumentPfad: state.api.update,
    abschlussStatusListe: state.abschlussStatusListe,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ausbildnerAbschlussSpeichern: (ausbildner, zusatzdaten) => { dispatch(ausbildnerAbschlussSpeichern(ausbildner, zusatzdaten)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AusbildnerAbschluss);
