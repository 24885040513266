import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../components/ModalerDialog";
import LabelInputStruktur from "./LabelInputStruktur";
import {executeGet, executeJson} from "../lib/fetchHelper";
import {biologischeEntwicklungText, mirwaldSpielerEntfernen, mirwaldSpielerUpdate} from "../state/modules/mirwald";
import PersonBadge from "../../../components/PersonBadge";
import DatumPicker from "../../../components/DatumPicker";
import {ersetzeStringMitDatum} from "../../../lib/datenhaltung";
import {formatiereDatum} from "../../../lib/formatierungen";
import {berechneAlleWerte} from "../lib/mirwaldBerechnung";

const WIZARD_STEPS = {
  einleitung: 'einleitung',
  messdatum: 'messdatum',
  gewicht: 'gewicht',
  groesseStehend: 'groesse_stehend',
  groesseSitzend: 'groesse_sitzend',
};

const MirwaldModal = ({phase, spieler, onHide, biologischeEntwicklung, wachstumsreserve, locale, texte, api, mirwaldSpielerUpdate, mirwaldSpielerEntfernen}) => {
  const [spielertest, setSpielertest] = useState(null);
  const [xhrAktiv, setXhrAktiv] = useState(false);
  const [showWizard, setShowWizard] = useState(undefined); // undefined rendert gar keinen inhalt
  const [wizardStep, setWizardStep] = useState(WIZARD_STEPS.einleitung);
  const texteMirwald = texte.mirwald;
  const readonly = spielertest && spielertest.abgeschlossen;

  const schliessen = () => {
    onHide();
  };

  useEffect(() => {
    // lade mirwald von server
    setXhrAktiv(true);
    executeGet(`${api.mirwald}/${spieler.id}`, {phase: phase})
      .then(result => {
        const mirwaldDaten = ersetzeStringMitDatum(result.data.mirwald, ['datum_zeit_messung', 'datum_abgeschlossen']);
        setSpielertest(mirwaldDaten);
        setXhrAktiv(false);
        // wenn gespeichert: kein wizard anzeigen
        setShowWizard(mirwaldDaten.id === null);
      })
      .catch(() => setXhrAktiv(false));
  }, []);

  const speichern = (aktion = null) => {
    const daten = {
      id: spieler.id,
      phase: phase,
      aktion: aktion,
      mirwald: {...spielertest}
    };
    delete daten.mirwald.errors;
    setXhrAktiv(true);
    executeJson(`${api.mirwald}/${spieler.id}`, api.token, 'PUT', daten)
      .then(result => {
        setSpielertest(ersetzeStringMitDatum(result.data.mirwald, 'datum_zeit_messung'));
        mirwaldSpielerUpdate(result.data.mirwald);
        setXhrAktiv(false);
      })
      .catch(() => setXhrAktiv(false));
  };

  const loeschen = () => {
    setXhrAktiv(true);
    executeJson(`${api.mirwald}/${spieler.id}`, api.token, 'DELETE', {phase: phase})
      .then(result => {
        if (result.ok) {
          setSpielertest(null);
          mirwaldSpielerEntfernen(spielertest);
          setXhrAktiv(false);
          schliessen();
        }
      })
      .catch(() => setXhrAktiv(false));
  };

  const setzeWert = (key, value) => {
    const daten = {...spielertest};
    daten[key] = new Number(value);

    // alle werte berechnen und setzen
    berechneAlleWerte(daten, spieler, wachstumsreserve);

    setSpielertest(daten);
  };

  const setzeText = (key, value) => {
    const daten = {...spielertest};
    daten[key] = value;

    if (key === 'datum_zeit_messung') {
      // alle werte berechnen und setzen
      berechneAlleWerte(daten, spieler, wachstumsreserve);
    }

    setSpielertest(daten);
  };

  const inputNummer = (name, props = {maxValue: 100, minValue: 1, step: 1}, appendix = '') => {
    const cssClass = appendix === '' ? '' : 'text-right';
    return (
      <LabelInputStruktur model={spielertest} texte={texteMirwald} name={name}>
        <input id={name} disabled={spielertest.abgeschlossen} type="number" {...props} value={spielertest[name] || ''} onChange={(ev) => setzeWert(name, ev.currentTarget.value)} className={cssClass}/>
        {appendix}
      </LabelInputStruktur>
    );
  };

  const berechneterWert = (name, appendix = '') => {
    return (
      <LabelInputStruktur model={spielertest} texte={texteMirwald} name={name}>
        {spielertest[name] &&
        <>{spielertest[name]}{appendix}</>
        }
      </LabelInputStruktur>
    );
  };

  const renderUebersicht = () => {
    return (
      <>
        {spielertest &&
        <table className="table table-condensed attributes">
          <colgroup>
            <col width="25%"/>
            <col width="25%"/>
            <col width="25%"/>
            <col width="25%"/>
          </colgroup>
          <tbody>
          <tr>
            <LabelInputStruktur model={spielertest} texte={texteMirwald} name={'datum_zeit_messung'}>
              {readonly ||
              <DatumPicker locale={locale} id="datum_zeit_messung" value={spielertest.datum_zeit_messung} onChange={datum => setzeText('datum_zeit_messung', datum)}/>
              }
              {readonly && spielertest.datum_zeit_messung &&
              <span className="label">{formatiereDatum(spielertest.datum_zeit_messung)}</span>
              }
            </LabelInputStruktur>
            {berechneterWert('chronologisches_alter')}
          </tr>
          <tr>
            {inputNummer('koerpergewicht', {max: 500, step: 0.1}, ' kg')}
            <th></th>
            <td></td>
          </tr>
          <tr>
            {inputNummer('koerpergroesse_stehend', {max: 250, step: 0.1}, ' cm')}
            <th></th>
            <td>{spielertest['offset']}</td>
          </tr>
          <tr>
            {inputNummer('koerpergroesse_sitzend', {max: 250, step: 0.1}, ' cm')}
            {berechneterWert('beinlaenge', ' cm')}
          </tr>
          <tr>
            {berechneterWert('biologisches_alter', ` ${texteMirwald.jahre}`)}
            <LabelInputStruktur model={spielertest} texte={texteMirwald} name={'biologischer_entwicklungsstand'}>
              {biologischeEntwicklungText(biologischeEntwicklung, spielertest.biologischer_entwicklungsstand)}
            </LabelInputStruktur>
          </tr>
          <tr>
            {berechneterWert('erwachsenen_groesse_cm', ' cm')}
            {berechneterWert('erwachsenen_groesse_prozent', '%')}
          </tr>
          <tr>
            <LabelInputStruktur model={spielertest} texte={texteMirwald} name={'bemerkungen'} colSpan={3}>
              <textarea id="bemerkungen" disabled={readonly} className="input-100prozent" rows="5" value={spielertest.bemerkungen || ''} onChange={(ev) => setzeText('bemerkungen', ev.currentTarget.value)}/>
            </LabelInputStruktur>
          </tr>
          {spielertest.abgeschlossen &&
          <tr>
            <th>{texte.abgeschlossen}</th>
            <td>
              {spielertest.datum_abgeschlossen &&
              <span className="label">{formatiereDatum(spielertest.datum_abgeschlossen)}</span>
              }
            </td>
            <th></th>
            <td></td>
          </tr>
          }
          </tbody>
        </table>
        }
      </>
    );
  };

  const renderWizard = () => {
    const texteWizard = texteMirwald.wizard[wizardStep];
    let inhalt = <></>;

    switch (wizardStep) {
      case WIZARD_STEPS.einleitung:
        inhalt = (
          <tr>
            <td colSpan={3}>
              <ol>
                <li dangerouslySetInnerHTML={{__html: texteWizard.hinweis1}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.hinweis2}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.hinweis3}}/>
              </ol>
            </td>
          </tr>
        );
        break;
      case WIZARD_STEPS.messdatum:
        inhalt = (
          <tr>
            <th><label htmlFor="datum_zeit_messung">{texteMirwald.datum_zeit_messung}</label></th>
            <td><DatumPicker locale={locale} inline={true} id="datum_zeit_messung" value={spielertest.datum_zeit_messung} onChange={datum => setzeText('datum_zeit_messung', datum)}/></td>
            <td></td>
          </tr>
        );
        break;
      case WIZARD_STEPS.gewicht:
        inhalt = (
          <tr>
            {inputNummer('koerpergewicht', {max: 500, step: 0.1}, ' kg')}
            <td>
              <ol>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt1}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt2}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt3}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt4}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt5}}/>
              </ol>
            </td>
          </tr>
        );
        break;
      case WIZARD_STEPS.groesseStehend:
        inhalt = (
          <tr>
            {inputNummer('koerpergroesse_stehend', {max: 250, step: 0.1}, ' cm')}
            <td>
              <ol>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt1}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt2}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt3}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt4}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt5}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt6}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt7}}/>
              </ol>
            </td>
          </tr>
        );
        break;
      case WIZARD_STEPS.groesseSitzend:
        inhalt = (
          <tr>
            {inputNummer('koerpergroesse_sitzend', {max: 250, step: 0.1}, ' cm')}
            <td>
              <ol>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt1}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt2}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt3}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt4}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt5}}/>
                <li dangerouslySetInnerHTML={{__html: texteWizard.schritt6}}/>
              </ol>
            </td>
          </tr>
        );
        break;
    }
    return (
      <>
        <hr/>
        <p dangerouslySetInnerHTML={{__html: texteWizard.titel}}/>
        <table className="table table-condensed attributes">
          <colgroup>
            <col width="25%"/>
            <col width="25%"/>
            <col width="50%"/>
          </colgroup>
          <tbody>
          {inhalt}
          </tbody>
        </table>
      </>
    );
  };

  const weiter = () => {
    switch (wizardStep) {
      case WIZARD_STEPS.einleitung:
        setWizardStep(WIZARD_STEPS.messdatum);
        break;
      case WIZARD_STEPS.messdatum:
        setWizardStep(WIZARD_STEPS.gewicht);
        break;
      case WIZARD_STEPS.gewicht:
        setWizardStep(WIZARD_STEPS.groesseStehend);
        break;
      case WIZARD_STEPS.groesseStehend:
        setWizardStep(WIZARD_STEPS.groesseSitzend);
        break;
      default:
        setShowWizard(false);
        setWizardStep(null);
        break;
    }
  };

  const zurueck = () => {
    switch (wizardStep) {
      case WIZARD_STEPS.groesseSitzend:
        setWizardStep(WIZARD_STEPS.groesseStehend);
        break;
      case WIZARD_STEPS.groesseStehend:
        setWizardStep(WIZARD_STEPS.gewicht);
        break;
      case WIZARD_STEPS.gewicht:
        setWizardStep(WIZARD_STEPS.messdatum);
        break;
      case WIZARD_STEPS.messdatum:
        setWizardStep(WIZARD_STEPS.einleitung);
        break;
    }
  };

  return (
    <ModalerDialog>
      <div className="modal breit-modal">
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{texteMirwald.titel}</h3>
        </div>
        <div className="modal-body">
          <table>
            <tbody>
            <tr>
              <td style={{verticalAlign: 'top', paddingRight: 10}}><span className="label label-info">{spieler.passnummer}</span></td>
              <td><PersonBadge person={spieler.person} personVerlinken={false} fotoTooltipAktiv={false}/></td>
            </tr>
            </tbody>
          </table>
          {showWizard === false && renderUebersicht()}
          {showWizard === true && renderWizard()}
        </div>
        <div className="modal-footer">
          {showWizard === false &&
          <>
            {spielertest && spielertest.id > 0 && !readonly &&
            <button type="button" className="btn btn-success" onClick={() => speichern('abschliessen')}>{texte.abschliessen}</button>
            }
            {readonly ||
            <button type="submit" className="btn btn-primary" onClick={(ev) => { ev.currentTarget.blur(); speichern(); }}>
              {texte.speichern}
              {xhrAktiv &&
              <>&nbsp;<i className="fa fa-refresh fa-spin"/></>
              }
            </button>
            }
            {spielertest && spielertest.id > 0 && readonly &&
            <button type="button" className="btn btn-warning" onClick={() => speichern('bearbeiten')}>{texte.bearbeiten}</button>
            }
            <button type="button" className="btn" onClick={schliessen}>{texte.schliessen}</button>
            {spielertest && spielertest.id > 0 && !readonly &&
            <div className="pull-left">
              <button type="button" className="btn btn-danger" onClick={loeschen}>{texte.loeschen}</button>
            </div>
            }
          </>
          }
          {showWizard === true &&
          <>
            <div className="pull-left">
              <button type="button" className="btn" onClick={schliessen}>{texte.schliessen}</button>
            </div>
            {wizardStep !== WIZARD_STEPS.einleitung &&
            <button type="button" className="btn" onClick={zurueck}>{texteMirwald.wizard.zurueck}</button>
            }
            <button type="button" className="btn btn-primary" onClick={weiter}>{texteMirwald.wizard.weiter}</button>
          </>
          }
          {showWizard === undefined &&
          <button type="button" className="btn" onClick={schliessen}>{texte.schliessen}</button>
          }
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    biologischeEntwicklung: state.listen.biologischeEntwicklung,
    wachstumsreserve: state.listen.wachstumsreserve,
    locale: state.i18n.locale,
    texte: state.i18n.texte,
    api: state.api,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mirwaldSpielerUpdate: (mirwald) => {
      dispatch(mirwaldSpielerUpdate(mirwald));
    },
    mirwaldSpielerEntfernen: (mirwald) => {
      dispatch(mirwaldSpielerEntfernen(mirwald));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MirwaldModal);
