// @flow

import type {AppState, EinsatzState, SpielerEintrag, TurnierState, TurnierSpielerEintrag} from "../../../state/stateTypes";
import type {Dispatch} from "../../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import type {SpielerState} from "../../../state/stateTypes";
import TurnierSpieler from "./TurnierSpieler";
import TurnierSpielerReadonly from "./TurnierSpielerReadonly";
import TurnierSpielerOhneDaten from "./TurnierSpielerOhneDaten";
import TurnierTorhueterNoten from "./TurnierTorhueterNoten";

const einsatzHatDaten = (scoutingEinsatz: EinsatzState, einsatz: TurnierSpielerEintrag, einsatzSpieler: SpielerEintrag | void) => {
  return !!einsatzSpieler;
};

const einsatzIstSchreibgeschuetzt = (scoutingEinsatz: EinsatzState, einsatz: TurnierSpielerEintrag, einsatzSpieler: SpielerEintrag | void) => {
  return !einsatzSpieler || einsatzSpieler.tips.nurLesen ||
    (scoutingEinsatz.hatTorhueterEinsatz && !scoutingEinsatz.istTorhueterEinsatz && einsatz.torhueter) ||
    (scoutingEinsatz.istTorhueterEinsatz && !einsatz.torhueter);
};

const sortierteSpielerliste = (einsaetze, spielerliste) => {
  let spielerlisteIds = spielerliste.map(s => s.id)
  return [...einsaetze].sort((a, b) => spielerlisteIds.indexOf(a.spielerId) - spielerlisteIds.indexOf(b.spielerId));
}

const formation = (scoutingEinsatz: EinsatzState, einsaetze: TurnierSpielerEintrag[], spielerliste: SpielerEintrag[]) => {
  return einsaetze.map((einsatz: TurnierSpielerEintrag) => {
    let einsatzSpieler: SpielerEintrag | void = spielerliste.find((spieler: SpielerEintrag) => {
      return spieler.id === einsatz.spielerId;
    });
    if (einsatzHatDaten(scoutingEinsatz, einsatz, einsatzSpieler)) {
      if (!einsatzIstSchreibgeschuetzt(scoutingEinsatz, einsatz, einsatzSpieler)) {
        if (scoutingEinsatz.istTorhueterEinsatz && einsatz.torhueter) {
          return <TurnierTorhueterNoten key={einsatz.id} spielerEinsatz={einsatz} spieler={einsatzSpieler}/>;
        } else {
          return <TurnierSpieler key={einsatz.id} spielerEinsatz={einsatz} spieler={einsatzSpieler}/>;
        }
      } else {
        return <TurnierSpielerReadonly key={einsatz.id} spielerEinsatz={einsatz} spieler={einsatzSpieler}/>;
      }
    } else {
      return <TurnierSpielerOhneDaten key={einsatz.id} spielerEinsatz={einsatz} spieler={einsatzSpieler}/>;
    }
  });
};
const TurnierSpielerliste = (props: {
  einsatz: EinsatzState,
  turnier: TurnierState,
  spielerliste: SpielerState,
  i18n: any,
}) => {
  const tableHeader = <thead>
  <tr>
    <th>{props.i18n.tips.passnummer}</th>
    <th>{props.i18n.tips.spieler}</th>
    <th className={"spielposition"}>{props.i18n.tips.position}</th>
    <th>{props.i18n.tips.rueckennummer}</th>
    <th className="text-center">{props.i18n.tips.t}</th>
    <th className="text-center">{props.i18n.tips.i}</th>
    <th className="text-center">{props.i18n.tips.p}</th>
    <th className="text-center">{props.i18n.tips.s}</th>
    <th className="text-center">ø</th>
    <th className="text-center">{props.i18n.tips.entwicklung}</th>
    <th className="text-center">{props.i18n.tips.note}</th>
    <th colSpan={2}></th>
  </tr>
  </thead>;

  return (
    <div>
      {
        props.turnier.spielerliste.mit_einsatz.length > 0 && <>
          <section>
            <h2 className="page-section">
              {props.i18n.aufstellung.mit_einsatz}
            </h2>
          </section>
          <table className="table table-condensed">
            {tableHeader}
            {formation(props.einsatz, sortierteSpielerliste(props.turnier.spielerliste.mit_einsatz, props.spielerliste.liste), props.spielerliste.liste)}
          </table>
        </>
      }
      {
        props.turnier.spielerliste.ohne_einsatz.length > 0 && <>
          <section>
            <h2 className="page-section">
              {props.i18n.aufstellung.ohne_einsatz}
            </h2>
          </section>
          <table className="table table-condensed">
            {tableHeader}
            {formation(props.einsatz, sortierteSpielerliste(props.turnier.spielerliste.ohne_einsatz, props.spielerliste.liste), props.spielerliste.liste)}
          </table>
        </>
      }
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    turnier: state.turnier,
    spielerliste: state.spielerliste,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TurnierSpielerliste);
