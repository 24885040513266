import React, {useState} from "react";
import {connect} from "react-redux";
import {formatiereDatumZeit} from "../../lib/formatierungen";
import IconToggle from "../IconToggle";
import LehrerTeam from "./LehrerTeam";

const ZeitslotZeitpunkt = ({zeitslot, texte}) => {
  const [showTeams, setShowTeams] = useState(true);

  return (
    <div className="block">
      <div className="header">
        <button className="btn btn-mini pull-right" onClick={() => setShowTeams(!showTeams)}>
          <IconToggle istOffen={showTeams}/>
        </button>
        {`${texte.datumVon}: ${formatiereDatumZeit(zeitslot.von)}`}
        <br/>
        {`${texte.datumBis}: ${formatiereDatumZeit(zeitslot.bis)}`}
      </div>
      {showTeams &&
      <div className="content">
        {zeitslot.teams.map(team => <LehrerTeam key={team.id} team={team} showZeit={false}/>)}
      </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    texte: state.i18n.texte
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ZeitslotZeitpunkt);
