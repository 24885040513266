// @flow

import type {
  AppState,
  NavigationState,
  SpielerEintrag,
  SpielerState,
  SpielState,
  Wettkampfleistung
} from "../../../state/stateTypes";
import type { Dispatch } from "../../../state/actionTypes";

import React from "react";
import { connect } from "react-redux";
import TipsZusammenfassung from "../TipsZusammenfassung";
import WettkampfleistungSpieler from "./WettkampfleistungSpieler";
import WettkampfleistungSpielerReadonly from "./WettkampfleistungSpielerReadonly";

const Wettkampfleistungen = (props: {
  navigation: NavigationState,
  spiel: SpielState,
  spielerliste: SpielerState,
  i18n: any,
}) => {
  const einsatzIstSchreibgeschuetzt = (spielerlisteEintrag: SpielerEintrag | void) => {
    return !spielerlisteEintrag || spielerlisteEintrag.nichtregistriert;
  };

  const sortierteSpielerliste = (spielerkarteEintraege) => {
    let spielerlisteIds = props.spielerliste.liste.map(s => s.id)
    return [...spielerkarteEintraege].sort((a, b) => spielerlisteIds.indexOf(a.spielerId) - spielerlisteIds.indexOf(b.spielerId));
  }

  const formation = (spielerkarteEintraege, wettkampfleistungen) => {
    return sortierteSpielerliste(spielerkarteEintraege).map((spielerkarteEintrag) => {
      let spielerEintrag = props.spielerliste.liste.find((spieler) => {
        return spieler.id === spielerkarteEintrag.spielerId && spieler.nichtregistriert === spielerkarteEintrag.nichtregistriert;
      });

      let wettkampfleistung = wettkampfleistungen.find((wettkampfleistung: Wettkampfleistung) => {
        return wettkampfleistung.spielerId === spielerkarteEintrag.spielerId && !(spielerkarteEintrag.nichtregistriert);
      });

      if (!einsatzIstSchreibgeschuetzt(spielerEintrag)) {
        return <WettkampfleistungSpieler key={spielerkarteEintrag.id} spielerEinsatz={spielerkarteEintrag}
                                         spieler={spielerEintrag}
                                         wettkampfleistung={wettkampfleistung}/>;
      } else {
        return <WettkampfleistungSpielerReadonly key={spielerkarteEintrag.id} spielerEinsatz={spielerkarteEintrag}
                                                 spieler={spielerEintrag}/>
      }
    });
  };

  const tableHeader =
    <thead>
    <tr>
      <th>{props.i18n.tips.passnummer}</th>
      <th>{props.i18n.tips.spieler}</th>
      <th className={"spielposition"}>{props.i18n.tips.position}</th>
      <th className="text-center" style={{width: 40}}>{props.i18n.tips.rueckennummer}</th>
      <th className="text-center" style={{width: 40}}>
        <span className={"icon-time"}/>
      </th>
      <th className="text-center" style={{width: 40}}>{props.i18n.tips.t}</th>
      <th className="text-center" style={{width: 40}}>{props.i18n.tips.i}</th>
      <th className="text-center" style={{width: 40}}>{props.i18n.tips.p}</th>
      <th className="text-center" style={{width: 40}}>{props.i18n.tips.s}</th>
      <th className="text-center" style={{width: 40}}>ø</th>
      <th className="text-center" style={{width: 40}}>{props.i18n.tips.note}</th>
    </tr>
    </thead>


  const teamTable = (wettkampfleistungenTeam, spielerkarteTeam) => {
    const relevanteWettkampfleistungen: Wettkampfleistung[] = wettkampfleistungenTeam;
    return <>
      <TipsZusammenfassung relevanteSpieler={null} relevanteWettkampfleistungen={relevanteWettkampfleistungen}/>
      <section>
        <h2 className="page-section">
          {props.i18n.subtitel.startformation}
        </h2>
      </section>
      <table className="table table-condensed">
        {tableHeader}
        {formation(spielerkarteTeam.startformation, relevanteWettkampfleistungen)}
      </table>
      <section>
        <h2 className="page-section">
          {props.i18n.subtitel.auswechselspieler}
        </h2>
      </section>
      <table className="table table-condensed">
        {tableHeader}
        {formation(spielerkarteTeam.ersatzformation, relevanteWettkampfleistungen)}
      </table>
    </>;
  }

  if (props.navigation.aktivesSpielerTeam === 'heimWettkampfleistungen') {
    return teamTable(props.spiel.wettkampfleistungen.heimTeam, props.spiel.spielerkarten.heimTeam)
  } else if (props.navigation.aktivesSpielerTeam === 'gastWettkampfleistungen') {
    return teamTable(props.spiel.wettkampfleistungen.gastTeam, props.spiel.spielerkarten.gastTeam)
  } else {
    return <></>;
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    navigation: state.navigation,
    spiel: state.spiel,
    spielerliste: state.spielerliste,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wettkampfleistungen);
