// @flow

import type {AppState, EinsatzState, SpielState, Team, TeamState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

const TeamHeaderSpiel = (props: {
  einsatz: EinsatzState,
  spiel: SpielState,
  teams: TeamState,
  i18n: any,
}) => {
  if (!props.teams.heimTeam || !props.teams.gastTeam) {
    return <></>;
  }

  const angezeigterSpielstand = <>{props.spiel.spielstandHeim}:{props.spiel.spielstandGast}</>;
  let zwischenresultate = <></>;
  if (props.spiel.zwischenresultate.length > 0) {
    zwischenresultate = <div style={{textAlign: "center", fontSize: "15px"}}>
      {
        props.spiel.zwischenresultate.map((zwischenresultat) => {
          return <span key={zwischenresultat.id}>({zwischenresultat.heimTore}:{zwischenresultat.gastTore})</span>;
        })
      }
    </div>
  }
  let spielstandWarnung = <></>;
  if (props.spiel.spielstandDefinitiv) {
    if ((props.spiel.spielstandHeim !== props.spiel.serverSpielstandHeim) || (props.spiel.spielstandGast !== props.spiel.serverSpielstandGast)) {
      spielstandWarnung = <div style={{textAlign: "center", fontSize: "15px"}}>
        {props.i18n.validierung.spielstandServer}: {props.spiel.serverSpielstandHeim}:{props.spiel.serverSpielstandGast}
      </div>;
    }
  }

  return (
    <div id="spiel-informationen">
      <div className="well">
        <div className={"heim-team-infos pull-left team-" + props.teams.heimTeam.id}>
          <div className="emblem">
            <div className="avatar pull-left">
              <img src={props.teams.heimTeam.logo} style={{width: '34px'}} alt={props.teams.heimTeam.id}/>
            </div>
            <div className="pull-left">
              <div className="title">{props.teams.heimTeam.name}</div>
              <div className="subtitle">{props.teams.heimTeam.ligaUndSpielklasse}</div>
            </div>
          </div>
          <div className="clearfix"/>
        </div>
        <div className="spiel-ergebnis pull-left">
          {angezeigterSpielstand}
        </div>
        <div className={"gast-team-infos pull-right team-" + props.teams.gastTeam.id}>
          <div className="emblem">
            <div className="avatar pull-left">
              <img src={props.teams.gastTeam.logo} style={{width: '34px'}} alt={props.teams.gastTeam.id}/>
            </div>
            <div className="pull-left">
              <div className="title">{props.teams.gastTeam.name}</div>
              <div className="subtitle">{props.teams.gastTeam.ligaUndSpielklasse}</div>
            </div>
          </div>
          <div className="clearfix"/>
        </div>
        <div className="clearfix"/>
        {zwischenresultate}
        {spielstandWarnung}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    spiel: state.spiel,
    teams: state.teams,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamHeaderSpiel);
