import {combineReducers} from "redux";
import {dummyReducer} from "../../../lib/datenhaltung";
import spieleReducer from "./modules/spiele";
import ranglistenReducer from "./modules/ranglisten";
import rollbarReducer from "../../../reducers/rollbar";
import meldungenReducer from "./modules/meldungen";

export default combineReducers({
  ligen: dummyReducer,
  spielgruppen: dummyReducer,
  ranglisten: ranglistenReducer,
  spiele: spieleReducer,
  teams: dummyReducer,
  spielstatusListe: dummyReducer,
  meldungen: meldungenReducer,
  api: dummyReducer,
  i18n: dummyReducer,
  rollbar: rollbarReducer,
});
