import {executeJson} from "../../../../lib/fetchHelper";
import {ersetzeStringMitDatum, setzePageTitel} from "../../../../lib/datenhaltung";
import {endeRequest, startRequest} from "./laufendeAbfragen";

const SPONSORENLAUF_EDIT = 'SPONSORENLAUF_EDIT';

export function sponsorenlaufEdit(sponsorenlauf) {
  return (dispatch) => {
    dispatch({type: SPONSORENLAUF_EDIT, sponsorenlauf: {...sponsorenlauf}});
  };
}

export function sponsorenlaufCreate(sponsorenlauf) {
  return (dispatch, getState) => {
    dispatch(startRequest('sponsorenlauf'));

    const state = getState();
    const api = state.api;
    const data = {
      sponsorenlauf: sponsorenlauf
    };
    executeJson(api.sponsorenlauf, api.token, 'POST', data).
    then(result => {
      dispatch({type: SPONSORENLAUF_EDIT, sponsorenlauf: result.data.sponsorenlauf});
      if (result.ok) {
        const titel = state.i18n.texte.titelEdit;
        window.history.pushState({}, `${titel} - clubcorner.ch`, `${window.location.origin}${api.root}/${result.data.sponsorenlauf.id}/edit`);
        setzePageTitel(titel);
      }
    }).finally(() => {
      dispatch(endeRequest('sponsorenlauf'));
    });
  };
}

export function sponsorenlaufUpdate(sponsorenlauf) {
  return (dispatch, getState) => {
    dispatch(startRequest('sponsorenlauf'));

    const state = getState();
    const api = state.api;
    const data = {
      sponsorenlauf: sponsorenlauf
    };
    executeJson(`${api.sponsorenlauf}/${sponsorenlauf.id}`, api.token, 'PUT', data).
    then(result => {
      dispatch({type: SPONSORENLAUF_EDIT, sponsorenlauf: result.data.sponsorenlauf});
    }).finally(() => {
      dispatch(endeRequest('sponsorenlauf'));
    });
  };
}

function sponsorenlaufReducer(state = {}, action) {
  switch (action.type) {
    case SPONSORENLAUF_EDIT:
      return ersetzeStringMitDatum(action.sponsorenlauf, ['datum', 'registrationVon', 'registrationBis']);
    default:
      return state;
  }
}

export default sponsorenlaufReducer;
