import React from "react";
import useGlobal from "../store";

const Fehlermeldung = ({fehler}) => {
  const [globalState, globalActions] = useGlobal();

  const fehlerEntfernen = () => {
    globalActions.fehlerEntfernen(fehler);
  };

  return (
    <div className="alert fade in alert-error">
      <a className="close" onClick={fehlerEntfernen}>×</a>
      <span>{fehler}</span>
    </div>
  );
};

export default Fehlermeldung;
