import React, {useState} from "react";
import {connect} from "react-redux";
import {formatiereDatumZeit} from "../../lib/formatierungen";
import {aufgabeZuteilungTeamErstellen} from "../../state/teamTeilnehmerReducer";
import {useDrop} from "react-dnd";
import TeamTeilnehmer from "./TeamTeilnehmer";
import IconToggle from "../IconToggle";
import {AusbildungStatus, ItemTypes} from "../../lib/constants";
import {AufgabenModus} from "./AufgabeZuteilung";
import FisNachricht from "../FisNachricht";
import Tooltip from "../../../../components/Tooltip";
import AufgabenFreigeben from "./AufgabenFreigeben";

const TeamAufgabenZuteilen = ({team, ausbildner, teamTeilnehmer, abschlussStatusListe, texte, aufgabeZuteilungTeamErstellen}) => {
  const [showTeilnehmer, setShowTeilnehmer] = useState(false);
  const [showFisNachricht, setShowFisNachricht] = useState(false);
  const [showAufgabenFreigeben, setShowAufgabenFreigeben] = useState(false);
  const sichtbareStatus = [...abschlussStatusListe.map(s => s.id), AusbildungStatus.Angemeldet, AusbildungStatus.Anwesend];
  const ausbildnerFiltered = ausbildner.filter(a => sichtbareStatus.includes(a.ausbildungStatusId)).map(a => a.id);
  const teilnehmer = teamTeilnehmer.filter(tt => tt.teamId === team.id && ausbildnerFiltered.includes(tt.ausbildner.id));

  const bibliothekEintraege = [];
  teilnehmer.forEach(tt => {
    tt.aufgaben.forEach(a => {
      if (bibliothekEintraege.filter(b => b.id === a.bibliothekId).length === 0) {
        bibliothekEintraege.push(a.bibliothek);
      }
    });
  });

  const [{isOver, canDrop}, drop] = useDrop({
    accept: ItemTypes.Aufgabe,
    drop: (item) => {
      aufgabeZuteilungTeamErstellen(item, teilnehmer);
    },
    canDrop: (item) => {
      return !teilnehmer.every(tt => tt.aufgaben.findIndex(a => a.bibliothekId === item.id) >= 0);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  return (
    <div className="block">
      <div ref={drop} className={`header flex ${canDrop ? 'drop-ziel' : ''} ${canDrop && isOver ? 'drop-hover' : ''}`}>
        <div className="spalte">
          <b>{team.bezeichnung}</b>
          &nbsp;<span className="label label-warning">{team.programmBlock.name}</span>
        </div>
        <div className="spalte">
          <span className="pull-right">
            {bibliothekEintraege.length > 0 &&
            <button className="btn btn-mini" onClick={() => setShowAufgabenFreigeben(true)}>
              <Tooltip content={texte.aufgabenFreigeben.titel}>
                <i className="icon-share"/>
              </Tooltip>
            </button>
            }
            <button className="btn btn-mini" onClick={() => setShowFisNachricht(true)}>
              <i className="icon-envelope"/> FIS
            </button>
            <button className="btn btn-mini" onClick={() => setShowTeilnehmer(!showTeilnehmer)}>
              <IconToggle istOffen={showTeilnehmer}/>
            </button>
          </span>
          {`${texte.datumVon}: ${formatiereDatumZeit(team.von)}`}
          <br/>
          {`${texte.datumBis}: ${formatiereDatumZeit(team.bis)}`}
        </div>
      </div>
      {showTeilnehmer &&
      <div className="content">
        {teilnehmer.length === 0 &&
        <div className="alert alert-info">{texte.keineTeamZuordnungen}</div>
        }
        {teilnehmer.map(tt => <TeamTeilnehmer key={tt.id} teilnehmer={tt} aufgabenModus={AufgabenModus.zuteilen}/>)}
      </div>
      }
      {showFisNachricht &&
      <FisNachricht headerText={texte.fisNachrichtAnKlasse} zusatzdaten={ { teamId: team.id} } empfaenger={teilnehmer.map(t => t.ausbildner)} onClose={() => setShowFisNachricht(false)}/>
      }
      {showAufgabenFreigeben &&
      <AufgabenFreigeben teamTeilnehmer={teilnehmer} bibliothekEintraege={bibliothekEintraege} onClose={() => setShowAufgabenFreigeben(false)}/>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ausbildner: state.ausbildner,
    teamTeilnehmer: state.teamTeilnehmer,
    abschlussStatusListe: state.abschlussStatusListe,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    aufgabeZuteilungTeamErstellen: (aufgabe, teilnehmerListe) => { dispatch(aufgabeZuteilungTeamErstellen(aufgabe, teilnehmerListe)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamAufgabenZuteilen);
