import React, {useEffect} from "react";

import useGlobal from "./store";
import {initializeContent} from "./services/helpers";
import VideoPlayer from "./components/Video/VideoPlayer";
import VideoPreviews from "./components/Video/VideoPreviews";
import GameDetails from "./components/GameDetails";
import OverlayVideo from "./components/Video/OverlayVideo";
import Fehlermeldung from "./components/Fehlermeldung";

function Videoanalyse(props) {
  const [globalState, globalActions] = useGlobal();

  useEffect(() => {
    initializeContent(globalState, globalActions, props);
  });

  if (document.documentMode) {
    return (<div style={{marginTop: '50px'}}>
      <Fehlermeldung fehler={document.getElementById("ie-fehlermeldung").value}/>
    </div>);
  } else {
    if (globalState.spielInfos !== null) {
      const aktuelleFehler = globalState.appState.aktuelleFehler;
      return (
        <>
          {aktuelleFehler.length > 0 &&
          <div style={{marginTop: '3px', marginBotton: '0'}}>
            {aktuelleFehler.map((fehler) => <Fehlermeldung key={fehler} fehler={fehler}/>)}
          </div>
          }
          <div className="videoanalyse">
            <div className="videoanalyse-content flex-height-100">
              <div className="flex-height-100">
                <main className="flex-row">
                  <div className="center flex-1">
                    <VideoPlayer/>
                    <VideoPreviews/>
                  </div>
                  <GameDetails/>
                </main>
                <OverlayVideo/>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }
}

export default Videoanalyse;
