import {combineReducers} from 'redux';
import arbeitsraumReducer from "./arbeitsraumReducer";
import teilnehmerAufgabenReducer from "./teilnehmerAufgabenReducer";
import rollbarReducer from "../../../reducers/rollbar";
import fileUploadReducer from "./fileUpload";

function dummyReducer(state = {}, action) {
  return state;
}

export default combineReducers({
  ausbildung: dummyReducer,
  ausbildner: dummyReducer,
  ergebnisTrainer: dummyReducer,
  klassen: dummyReducer,
  aufgaben: teilnehmerAufgabenReducer,
  bibliothek: dummyReducer,
  arbeitsraumListe: arbeitsraumReducer,
  fileUpload: fileUploadReducer,
  api: dummyReducer,
  i18n: dummyReducer,
  rollbar: rollbarReducer,
});
