import React from "react";
import useGlobal from "../../../../store";
import ShowFormButton from "./ShowFormButton";
import EditForm from "./EditForm";

const Form = ({ tab }) => {
  const [globalState] = useGlobal();

  if (tab !== "analyse") {
    return <></>;
  } else if (globalState.formVisible) {
    return <EditForm />;
  } else {
    return <ShowFormButton />;
  }
};

export default Form;
