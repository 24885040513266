// @flow

import type {ServerDaten, TrainingState} from "../stateTypes";
import type {Action} from "../actionTypes";

export function trainingTeamNoteSetzen(wert: string): Action {
  return {type: "TRAINING_TEAM_NOTE_SETZEN", wert};
}

export function trainingTeamKommentarSetzen(wert: string): Action {
  return {type: "TRAINING_TEAM_KOMMENTAR_SETZEN", wert};
}

export function trainingTeamBemerkungenSetzen(wert: string): Action {
  return {type: "TRAINING_TEAM_BEMERKUNGEN_SETZEN", wert};
}

function trainingReducer(state: TrainingState, action: Action): TrainingState | null {
  switch (action.type) {
    case "AJAX_TRAINING_DATEN_ZURUECKSETZEN":
      return {
        ...action.serverDaten.training,
      };
    case "TRAINING_TEAM_NOTE_SETZEN":
      return {
        ...state,
        trainingTeamNote: action.wert ? parseFloat(action.wert) : null,
      };
    case "TRAINING_TEAM_KOMMENTAR_SETZEN":
      return {
        ...state,
        trainingTeamKommentar: action.wert,
      };
    case "TRAINING_TEAM_BEMERKUNGEN_SETZEN":
      return {
        ...state,
        trainingTeamBemerkungen: action.wert,
      };
    default:
      return state || null;
  }
}

export default trainingReducer
