import React from 'react';
import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import reducer from "./state/reducer";
import preloader from "./state/preloader";
import ErrorBoundary from "../../components/ErrorBoundary";
import Ansicht from "./components/Ansicht";

const Spielertests = (props) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducer, preloader(props), composeEnhancers(applyMiddleware(thunk)));

  return (
    <Provider store={store}>
      <ErrorBoundary fehlermeldung={props.i18n.allgemeinerFehler}>
        <Ansicht/>
      </ErrorBoundary>
    </Provider>
  );
};

export default Spielertests;
