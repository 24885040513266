// @flow

import type {TrainingState, TurnierState} from "../stateTypes";
import type {Action} from "../actionTypes";

export function turnierTeamNoteSetzen(wert: string): Action {
  return {type: "TURNIER_TEAM_NOTE_SETZEN", wert}
}

export function turnierTeamKommentarSetzen(wert: string): Action {
  return {type: "TURNIER_TEAM_KOMMENTAR_SETZEN", wert}
}

export function turnierTeamBemerkungenSetzen(wert: string): Action {
  return {type: "TURNIER_TEAM_BEMERKUNGEN_SETZEN", wert}
}

function turnierReducer(state: TurnierState, action: Action): TurnierState | null {
  switch (action.type) {
    case "AJAX_TURNIER_DATEN_ZURUECKSETZEN":
      return {
        ...action.serverDaten.turnier,
      };
    case "TURNIER_TEAM_NOTE_SETZEN":
      return {
        ...state,
        turnierTeamNote: action.wert ? parseFloat(action.wert) : null,
      };
    case "TURNIER_TEAM_KOMMENTAR_SETZEN":
      return {
        ...state,
        turnierTeamKommentar: action.wert,
      };
    case "TURNIER_TEAM_BEMERKUNGEN_SETZEN":
      return {
        ...state,
        turnierTeamBemerkungen: action.wert,
      };
    default:
      return state || null;
  }
}

export default turnierReducer
