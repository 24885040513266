// @flow

import type { AppState, EinsatzState, ValidierungState } from "../../state/stateTypes";
import type { Dispatch } from "../../state/actionTypes";

import React from "react";
import { connect } from "react-redux";
import {
  einsatzAbschlussButtonAus,
  einsatzAbschlussButtonEin,
  einsatzAbschlussStarten,
  einsatzLoeschenButtonAus,
  einsatzLoeschenButtonEin,
  einsatzLoeschenStarten
} from "../../state/modules/einsatz";

const Aktionen = (props: {
  einsatz: EinsatzState,
  validierung: ValidierungState,
  pfade: any,
  i18n: any,
  einsatzAbschlussStarten: (void) => void,
  einsatzAbschlussButtonEin: (void) => void,
  einsatzAbschlussButtonAus: (void) => void,
  einsatzLoeschenStarten: (void) => void,
  einsatzLoeschenButtonEin: (void) => void,
  einsatzLoeschenButtonAus: (void) => void,
}) => {

  const AbschlussButton = () => {
    if (props.einsatz.abgeschlossen) {
      return (
        <input type="submit"
               name="commit"
               value={props.i18n.buttons.abgeschlossen}
               className="btn btn-primary primary"
               disabled={"disabled"}/>
      );
    } else if (props.einsatz.abschlussGestartet || props.einsatz.readonly) {
      return (
        <input type="submit"
               name="commit"
               value={props.i18n.buttons.abschliessen}
               className="btn btn-primary primary"
               disabled={"disabled"}/>
      );
    } else {
      return (
        <input type="submit"
               name="commit"
               value={props.i18n.buttons.abschliessen}
               className="btn btn-primary primary"
               onClick={props.einsatzAbschlussButtonEin}/>
      );
    }
  }

  const LoeschenButton = () => {
    const { einsatz, i18n, einsatzLoeschenButtonEin } = props;

    if (einsatz.typ !== "turnier") { return null;}

    if (einsatz.abgeschlossen) { return null; }

    if (einsatz.loeschenGestartet || einsatz.readonly) {
      return (
        <input
          type="button"
          name="delete"
          value={i18n.buttons.loeschen}
          style={{ float: "right" }}
          className="btn btn-danger loeschen"
          disabled
        />
      );
    }

    return (
      <input
        type="button"
        name="delete"
        value={i18n.buttons.loeschen}
        style={{ float: "right" }}
        className="btn btn-danger loeschen"
        onClick={einsatzLoeschenButtonEin}
      />
    );
  };

  return (
    <div className="form-actions">
      <AbschlussButton/>
      <LoeschenButton/>
      {props.einsatz.abschlussButtonGedrueckt &&
        <React.Fragment>
          <div className="bootbox modal fade in" aria-hidden="false">
            <div className="modal-body">{props.i18n.anderes.wollen_sie_abschliessen}</div>
            <div className="modal-footer">
              <a className="btn btn-primary" href="#"
                 onClick={props.einsatzAbschlussStarten}>{props.i18n.buttons.ok}</a>
              <a data-handler="0" className="btn null" href="#"
                 onClick={props.einsatzAbschlussButtonAus}>{props.i18n.buttons.abbrechen}</a>
            </div>
          </div>
          <div className="modal-backdrop fade in" onClick={props.einsatzAbschlussButtonAus}/>
        </React.Fragment>
      }
      {props.einsatz.loeschenButtonGedrueckt &&
        <React.Fragment>
          <div className="bootbox modal fade in" aria-hidden="false">
            <div className="modal-body">{props.i18n.anderes.wollen_sie_loeschen}</div>
            <div className="modal-footer">
              <a className="btn btn-primary" href="#"
                 onClick={props.einsatzLoeschenStarten}>{props.i18n.buttons.ok}</a>
              <a data-handler="0" className="btn null" href="#"
                 onClick={props.einsatzLoeschenButtonAus}>{props.i18n.buttons.abbrechen}</a>
            </div>
          </div>
          <div className="modal-backdrop fade in" onClick={props.einsatzAbschlussButtonAus}/>
        </React.Fragment>
      }
    </div>
  )

};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    validierung: state.validierung,
    pfade: state.pfade,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    einsatzAbschlussButtonEin: () => {
      dispatch(einsatzAbschlussButtonEin());
    },
    einsatzAbschlussButtonAus: () => {
      dispatch(einsatzAbschlussButtonAus());
    },
    einsatzAbschlussStarten: () => {
      dispatch(einsatzAbschlussStarten());
    },
    einsatzLoeschenStarten: () => {
      dispatch(einsatzLoeschenStarten());
    },
    einsatzLoeschenButtonEin: () => {
      dispatch(einsatzLoeschenButtonEin());
    },
    einsatzLoeschenButtonAus: () => {
      dispatch(einsatzLoeschenButtonAus());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Aktionen);
