import React from "react";
import useGlobal from "../../../store";
import TickerItem from "./TickerItem";
import { sortiereTickerEintraege } from "../../../services/helpers";

const Ticker = ({ tab }) => {
  const [globalState] = useGlobal();

  const istAnalyse = tab === "analyse";

  const getItems = () => {
    let items = istAnalyse ? globalState.kommentare : globalState.ereignisse;
    return sortiereTickerEintraege(items || []);
  };

  return (
    <div className="content flex-column flex-1">
      <div className="ticker flex-1">
        {getItems().map(event => (
          <TickerItem item={event} key={event.id} editable={istAnalyse && !(event.bearbeitet)} />
        ))}
      </div>
    </div>
  );
};

export default Ticker;
