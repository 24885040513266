import {executeRequest} from "../lib/api";

export function fisNachrichtSenden(empfaenger, betreff, nachrichtText, zusatzdaten, callback) {
  return (dispatch, getState) => {
    const state = getState();
    const daten = {
      ...zusatzdaten,
      kontext: 'fis_nachricht',
      fis: {
        betreff: betreff,
        nachricht: nachrichtText,
        empfaenger_liste: empfaenger.map(e => e.id)
      }
    };
    return executeRequest(state.api.update, state.api.token, 'PUT', daten, (result) => {
      if (result.ok) {
        callback({ ok: true })
      } else {
        callback({ ok: false, fehler: result.data.errors });
      }
    });
  };
}

// ist kein eigentlicher reducer, da kein state dazu existiert
