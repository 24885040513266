import SpielerBadge from "../SpielerBadge";
import NoteDropdown from "../NoteDropdown";
import Tooltip from "../../../../components/Tooltip";
import LoeschButton from "./LoeschButton";
import React from "react";
import { connect } from "react-redux";
import type { Dispatch } from "../../state/actionTypes";
import {
  spielerKommentarfeldEinAusblenden,
  spielerPrognoseEinAusblenden,
  spielerTipsEntwicklungSetzen,
  spielerTipsISetzen,
  spielerTipsNoteSetzen,
  spielerTipsPSetzen,
  spielerTipsSSetzen,
  spielerTipsTSetzen,
  spielerWeitereTipsEinAusblenden
} from "../../state/modules/spieler";
import type { AppState, EinsatzState, SpielerEintrag, SpielerkarteEintrag, SpielerTips } from "../../state/stateTypes";
import { prognoseWertBerechnen } from "../../../../lib/prognoseFragen";

const SpielerRow = ({
                      einsatz,
                      i18n,
                      spieler,
                      spielerEinsatz,
                      requestDaten,
                      prognoseWert,
                      typ,
                      andereTipsAnzeigen,
                      spielerTipsTSetzen,
                      spielerTipsISetzen,
                      spielerTipsPSetzen,
                      spielerTipsSSetzen,
                      spielerTipsEntwicklungSetzen,
                      spielerTipsNoteSetzen,
                      spielerPrognoseEinAusblenden,
                      spielerKommentarfeldEinAusblenden,
                      spielerWeitereTipsEinAusblenden
                    }: Props) => {

  const successKlass = spieler.tips.t && spieler.tips.i && spieler.tips.p && spieler.tips.s ? "success" : "";
  const aktiv = !einsatz.readonly;
  const darstellung = typ === 'spiel' ? prognoseWertBerechnen(prognoseWert) : {};

  return (
    <tr className={successKlass}>
      <td style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }}>
        <span className="label label-info">{spieler.passnummer}</span>
        {((typ === 'spiel' || typ === 'turnier') && spielerEinsatz.spielfuehrer) &&
          <span className="label label-important spielfuehrer">C</span>
        }
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <SpielerBadge spieler={spieler}/>
      </td>
      <td>
        <div className="spielposition" style={{ width: '110px', verticalAlign: 'middle' }}>
          {typ === 'spiel' ? spielerEinsatz.spielposition.name : (spielerEinsatz.spielposition || '')}
        </div>
      </td>
      <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
        {spielerEinsatz.rueckennummer}
      </td>
      <td style={{ width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
        <NoteDropdown feldName="tipsT" feldLabel={i18n.tips.tLang} feldWert={spieler.tips.t || ''} einfaerben={true}
                      requestDaten={requestDaten}
                      onValueChange={(neuerWert) => {
                        spielerTipsTSetzen(spieler.id, neuerWert, spieler.tips)
                      }}/>
      </td>
      <td style={{ width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
        <NoteDropdown feldName="tipsI" feldLabel={i18n.tips.iLang} feldWert={spieler.tips.i || ''} einfaerben={true}
                      requestDaten={requestDaten}
                      onValueChange={(neuerWert) => {
                        spielerTipsISetzen(spieler.id, neuerWert, spieler.tips)
                      }}/>
      </td>
      <td style={{ width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
        <NoteDropdown feldName="tipsP" feldLabel={i18n.tips.pLang} feldWert={spieler.tips.p || ''} einfaerben={true}
                      requestDaten={requestDaten}
                      onValueChange={(neuerWert) => {
                        spielerTipsPSetzen(spieler.id, neuerWert, spieler.tips)
                      }}/>
      </td>
      <td style={{ width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
        <NoteDropdown feldName="tipsS" feldLabel={i18n.tips.sLang} feldWert={spieler.tips.s || ''} einfaerben={true}
                      requestDaten={requestDaten}
                      onValueChange={(neuerWert) => {
                        spielerTipsSSetzen(spieler.id, neuerWert, spieler.tips)
                      }}/>
      </td>
      <td style={{ width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
        <span className="durchschnitt">
          {spieler.tips.noteDurchschnitt ? spieler.tips.noteDurchschnitt.toFixed(1) : ''}
        </span>
      </td>
      <td style={{ width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
        <NoteDropdown feldName="tipsEntwicklung" feldLabel={i18n.tips.entwicklungLang}
                      feldWert={spieler.tips.entwicklung || ''} requestDaten={requestDaten}
                      onValueChange={(neuerWert) => {
                        spielerTipsEntwicklungSetzen(spieler.id, neuerWert, spieler.tips)
                      }}
        />
      </td>
      <td style={{ width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
        <NoteDropdown feldName="tipsNote" feldLabel={i18n.tips.noteLang} feldWert={spieler.tips.note || ''}
                      einfaerben={true} requestDaten={requestDaten}
                      onValueChange={(neuerWert) => {
                        spielerTipsNoteSetzen(spieler.id, neuerWert, spieler.tips)
                      }}
        />
      </td>
      {typ === 'spiel' &&
        <td style={{ verticalAlign: 'middle' }}>
          <Tooltip content={i18n.prognoseFragen.titel}>
            {prognoseWert <= 0 &&
              <button disabled={(aktiv || prognoseWert > 0) ? null : "disabled"} name="button" type="button"
                      className="btn btn-mini prognoseFragen" onClick={() => spielerPrognoseEinAusblenden(spieler.id)}>
                <i className="icon-question-sign"/>
              </button>
            }
            {prognoseWert > 0 &&
              <span className={`leistung fakeInput ${darstellung.farbe}`}
                    onClick={() => spielerPrognoseEinAusblenden(spieler.id)}>
              {darstellung.buchstabe}
            </span>
            }
          </Tooltip>
        </td>
      }
      <td style={{ verticalAlign: 'middle' }}>
        <Tooltip content={i18n.icons.kommentar}>
          <button disabled={aktiv ? null : "disabled"} name="button" type="button" className="btn btn-mini kommentar"
                  onClick={() => spielerKommentarfeldEinAusblenden(spieler.id)}>
            <i className="icon-comment"/>
          </button>
        </Tooltip>
      </td>
      {andereTipsAnzeigen &&
        <td style={{ verticalAlign: 'middle' }}>
          <Tooltip content={i18n.icons.tips}>
            <button disabled={aktiv ? null : "disabled"} name="button" type="button" className="btn btn-mini tips"
                    onClick={() => spielerWeitereTipsEinAusblenden(spieler.id)}>
              <i className="icon-arrow-down"/>
            </button>
          </Tooltip>
        </td>
      }
      <td style={{ verticalAlign: 'middle' }}>
        <LoeschButton spieler={spieler} spielerEinsatz={spielerEinsatz} aktiv={aktiv} requestDaten={requestDaten}/>
      </td>
    </tr>
  );
};

type Props = {
  einsatz: EinsatzState,
  i18n: any,
  spieler: SpielerEintrag,
  spielerEinsatz: SpielerkarteEintrag,
  requestDaten: any,
  prognoseWert: number | any,
  typ: string,
  andereTipsAnzeigen: boolean,
  spielerTipsTSetzen: (number, string, SpielerTips) => void,
  spielerTipsISetzen: (number, string, SpielerTips) => void,
  spielerTipsPSetzen: (number, string, SpielerTips) => void,
  spielerTipsSSetzen: (number, string, SpielerTips) => void,
  spielerTipsNoteSetzen: (number, string, SpielerTips) => void,
  spielerTipsEntwicklungSetzen: (number, string, SpielerTips) => void,
  spielerPrognoseEinAusblenden: (number) => void,
  spielerKommentarfeldEinAusblenden: (number) => void,
  spielerWeitereTipsEinAusblenden: (number) => void
}

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    spielerKommentarfeldEinAusblenden: (spielerId: number) => {
      dispatch(spielerKommentarfeldEinAusblenden(spielerId));
    },
    spielerWeitereTipsEinAusblenden: (spielerId: number) => {
      dispatch(spielerWeitereTipsEinAusblenden(spielerId));
    },
    spielerPrognoseEinAusblenden: (spielerId: number) => {
      dispatch(spielerPrognoseEinAusblenden(spielerId));
    },
    spielerTipsTSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsTSetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsISetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsISetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsPSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsPSetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsSSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsSSetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsEntwicklungSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsEntwicklungSetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsNoteSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsNoteSetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpielerRow);