import {executeRequest} from "../../lib/fetchHelper";
import {sucheBeenden, sucheFehler, sucheStarten} from "./rechnungskontrollenStatus";
import {inaktiveTeamsSetzen} from "./teams";

const RECHNUNGSKONTROLLEN_SUCHE = 'RECHNUNGSKONTROLLEN_SUCHE';
const RECHNUNGSKONTROLLEN_UPDATE = 'RECHNUNGSKONTROLLEN_UPDATE';

export function rechnungskontrollenSuche(von, bis) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const daten = {
      von: von,
      bis: bis
    };
    dispatch({type: RECHNUNGSKONTROLLEN_SUCHE, rechnungen: []});
    dispatch(sucheStarten());
    return executeRequest(api.rechnungenSuche, api.token, 'GET', daten)
      .then(result => {
        dispatch({type: RECHNUNGSKONTROLLEN_SUCHE, rechnungen: result.data.rechnungen});
        if (result.data.inaktiveTeams) {
          dispatch(inaktiveTeamsSetzen(result.data.inaktiveTeams));
        }
        dispatch(sucheBeenden());
        dispatch(sucheFehler([]));
      }).catch(() => {
        dispatch(sucheBeenden());
        dispatch(sucheFehler([state.i18n.texte.suche.ladeFehler]));
      });
  };
}

export function rechnungenStornieren(rechnungen) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const daten = {
      typ: 'stornieren',
      rechnungId: rechnungen.map(r => r.id)
    };
    return executeRequest(api.rechnungenSuche, api.token, 'POST', daten)
      .then(result => {
        dispatch({type: RECHNUNGSKONTROLLEN_UPDATE, rechnungen: result.data.rechnungen});
      });
  };
}

export function rechnungenSpielerSperren(rechnungen, sperren, bemerkungen = null) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const daten = {
      rechnungId: rechnungen.map(r => r.id),
      sperren : sperren,
      bemerkungen: bemerkungen
    };
    return executeRequest(api.spieler, api.token, 'POST', daten)
      .then(result => {
        dispatch({type: RECHNUNGSKONTROLLEN_UPDATE, rechnungen: result.data.rechnungen});
      });
  };
}

export function rechnungenBemerkungSpeichern(rechnungen, bemerkungen) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const daten = {
      typ: 'bemerkungen',
      rechnungId: rechnungen.map(r => r.id),
      bemerkungen: bemerkungen
    };
    return executeRequest(api.rechnungenSuche, api.token, 'POST', daten)
      .then(result => {
        dispatch({type: RECHNUNGSKONTROLLEN_UPDATE, rechnungen: result.data.rechnungen});
      });
  };
}

export function rechnungenBemerkungVereinSpeichern(rechnung, bemerkungen) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const daten = {
      typ: 'bemerkungenVerein',
      rechnungId: rechnung.id,
      bemerkungen: bemerkungen
    };
    return executeRequest(api.rechnungenSuche, api.token, 'POST', daten)
      .then(result => {
        dispatch({type: RECHNUNGSKONTROLLEN_UPDATE, rechnungen: result.data.rechnungen});
      });
  };
}

function rechnungskontrollenReducer(state = [], action) {
  switch (action.type) {
    case RECHNUNGSKONTROLLEN_SUCHE:
      return action.rechnungen;
    case RECHNUNGSKONTROLLEN_UPDATE:
      const filterIds = action.rechnungen.map(r => r.id);
      return [
        ...state.filter(r => !filterIds.includes(r.id)),
        ...action.rechnungen
      ];
    default:
      return state;
  }
}

export default rechnungskontrollenReducer;
