import React from "react";
import {connect} from "react-redux";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (this.props.rollbar && this.props.rollbar.instance) {
      this.props.rollbar.instance.error(error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1 style={ {textAlign: "center"} }>{this.props.fehlermeldung}</h1>;
    }

    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    rollbar: state.rollbar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
