import React from "react";
import {connect} from "react-redux";
import AufgabeLabel from "./AufgabeLabel";
import BibliothekDetail from "./BibliothekDetail";
import {teamZuteilungAufgabeZuruecksetzen} from "../state/lehrerTeamTeilnehmerReducer";
import {ViewKontext} from "../lib/constants";
import VideoTagging from "./Klassenlehrer/VideoTagging";
import Tooltip from "../../../components/Tooltip";

const TeamTeilnehmerAufgabe = ({aufgabe, ausbildung, dokumentPfad, texte, teamZuteilungAufgabeZuruecksetzen}) => {
  const istKursleiter = ausbildung.kontext === ViewKontext.Kursleiter;

  const beurteilungZuruecksetzen = () => {
    if (istKursleiter) {
      teamZuteilungAufgabeZuruecksetzen(aufgabe);
    }
  };

  return (
    <div className="aufgabe eintrag-box expand-width">
      <div className="header flex">
        <div className="spalte">
          <b>{aufgabe.bibliothek.titel}</b>
        </div>
        <div className="spalte">
          <span className="pull-right btn-liste">
            {aufgabe.istFreigegeben &&
            <Tooltip content={texte.aufgabenFreigeben.istFreigegeben} className="btn btn-mini btn-warning">
              <i className="icon-share"/>
            </Tooltip>
            }
          </span>
          <span className="pull-right label-liste">
            <AufgabeLabel aufgabeZuteilung={aufgabe}/>
          </span>
        </div>
      </div>
      <BibliothekDetail aufgabe={aufgabe.bibliothek}/>
      <div className="zeile">
        {texte.kommentar}
        <div className="kommentar">{aufgabe.kommentar}</div>
      </div>
      {aufgabe.hatDokument &&
      <div className="zeile">
        <span className="datei-upload">
          {texte.file}:&nbsp;<a href={`${dokumentPfad}?aufgabeZuteilungId=${aufgabe.id}`} target="_blank" className={`ikon ${aufgabe.dokumentIcon}`}/>
        </span>
      </div>
      }
      {aufgabe.hatDokument && aufgabe.dokumentIcon === 'ikon-video' &&
      <div className="zeile">
        <VideoTagging aufgabeZuteilung={aufgabe}/>
      </div>
      }
      <div className="zeile">
        {texte.beurteilung}
        <div className="kommentar">{aufgabe.beurteilung}</div>
      </div>
      {ausbildung.bearbeitbar && aufgabe.beurteilungAbgeschlossen && istKursleiter &&
      <div className="zeile">
        <button className="btn btn-danger" onClick={beurteilungZuruecksetzen}>{texte.kursleiter.beurteilungZuruecksetzen}</button>
      </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ausbildung: state.ausbildung,
    dokumentPfad: state.api.update,
    texte: state.i18n.texte
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    teamZuteilungAufgabeZuruecksetzen: (aufgabe) => { dispatch(teamZuteilungAufgabeZuruecksetzen(aufgabe)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamTeilnehmerAufgabe);
