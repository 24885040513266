import React, {useEffect} from "react";
import {connect} from "react-redux";
import Liga from "./Liga";
import {saisonWechsel} from "../state/modules/ligen";
import {ligaSetzen, statusFavoritenTeamsEntfernen} from "../state/modules/status";
import TeamsucheSaison from "./TeamsucheSaison";

const AnsichtFinaltag = ({ligen, saisons, teams, status, texte, api, saisonWechsel, ligaSetzen, favoritTeamEntfernen}) => {

  const favoritEntfernen = (teamId) => {
    favoritTeamEntfernen(teamId);
  };

  const zuLigaWechseln = (liga) => {
    if (liga) {
      ligaSetzen(liga);
    }
  };

  useEffect(() => {
    // bei wechsel von liga übersicht zu detail und umgekehrt, immer scroll to top machen
    window.scrollTo(0, 0);
  }, [status.selektierteLiga]);

  const favoritRendern = (teamId) => {
    const team = teams.find(t => t.id === teamId);
    const liga = ligen.find(l => l.id === team.ligaId);
    return (
      <div key={team.id} className="team">
        <div className="text" onClick={() => zuLigaWechseln(liga)}>
          {`${team.bezeichnung} `}
          {liga && <small>({liga.bezeichnung})</small>}
        </div>
        <div className="button">
          <button className="btn btn-small" onClick={() => favoritEntfernen(team.id)}><i className="icon-trash"/></button>
        </div>
      </div>
    );
  };

  if (status.selektierteLiga === null) {
    return (
      <>
        <TeamsucheSaison/>
        {status.favoritenTeams.length > 0 &&
        <div className="favoriten-container">
          <h2>{texte.meineFavoriten}</h2>
          {status.favoritenTeams.map(t => favoritRendern(t))}
        </div>
        }
        <div className="liga-container">
          {ligen.map(liga => <div key={liga.id} className="liga-box" onClick={() => ligaSetzen(liga)}><b>{liga.bezeichnung}</b></div>)}
          {ligen.length === 0 &&
          <h2>{texte.keineDaten}</h2>
          }
        </div>
      </>
    );
  }
  return <Liga liga={status.selektierteLiga} onClose={() => ligaSetzen(null)}/>;
};

const mapStateToProps = (state) => {
  return {
    ligen: state.ligen,
    saisons: state.saisons,
    teams: state.teams,
    status: state.status,
    texte: state.i18n.texte,
    api: state.api
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saisonWechsel: (saisonId) => { dispatch(saisonWechsel(saisonId)) },
    ligaSetzen: (liga) => { dispatch(ligaSetzen(liga)) },
    favoritTeamEntfernen: (teamId) => { dispatch(statusFavoritenTeamsEntfernen(teamId)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnsichtFinaltag);
