// @flow

import type {AppState, SpielerEintrag} from "../state/stateTypes";
import type {Dispatch} from "../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

const SpielerBadge = (props: {
  pfade: any,
  i18n: any,
  spieler: SpielerEintrag,
}) => {

  return (
    <div className="emblem person" rel="tooltip" data-content-selector=".hide" data-content-childselector="true" data-placement="left">
      <div className="hide bild">
        <img src={props.spieler.bildUrl || props.pfade.platzhalter.spieler} alt={props.spieler.name}/>
      </div>
      <img className="avatar" src={props.spieler.bildUrl || props.pfade.platzhalter.spieler} style={{width: 34}} alt={props.spieler.name}/>
      <div className="text">
        <div className="title">
          <a href={props.spieler.personKontakt || "#"} rel="tooltip" title={props.i18n.buttons.kontakt_speichern}>
            <i className="icon-download"/>
          </a>
          {props.spieler.personEdit ? <a href={props.spieler.personEdit || "#"} className="name abschneiden"> {props.spieler.name}</a> : <span className="name abschneiden">{props.spieler.name}</span>}
        </div>
        <div className="subtitle">
          <i className="icon-calendar"/>
          {props.spieler.geburtstag}
        </div>
        {(props.spieler.externeVereine && props.spieler.externeVereine.length > 0) &&
        <div>{props.spieler.externeVereine.map((ev, index) => <div key={index}>{ev}</div>)}</div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    pfade: state.pfade,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpielerBadge);
