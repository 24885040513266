import React from "react";
import useGlobal from "../../store";

const OverlayVideo = () => {
  const [globalState, globalActions] = useGlobal();

  const closeOverlay = () => {
    const overlayVideo = document.getElementById("overlay-video");
    if (overlayVideo) overlayVideo.pause();
    globalActions.setOverlaySequence(null);
    const video = document.querySelector("div.video-wrapper video");
    if (video) video.play().catch(() => {
      // noop
    });
  };

  return (
    <div
      className="blur flex-column"
      onClick={closeOverlay}
      onBlur={closeOverlay}
      hidden={!globalState.overlaySequence}
    >
      <div className="overlay-wrapper">
        <div id="overlay-seqiuence" onClick={e => e.stopPropagation()}>
          <button className="close-overlay icon-button" onClick={closeOverlay}>
            close
          </button>
          <video id="overlay-video" controls onEnded={closeOverlay}>
            <source src={globalState.overlaySequence || ""} type="video/mp4" />
            {globalState.i18n.browser_unterstuetzt_nicht_html5_video}
          </video>
        </div>
      </div>
    </div>
  );
};

export default OverlayVideo;
