
const SELEKTIERT_BEWERTUNG = 'SELEKTIERT_BEWERTUNG';

export function selektiertBewertung(bewertung = null) {
  return (dispatch) => {
    dispatch({type: SELEKTIERT_BEWERTUNG, bewertung: bewertung});
  };
}

function selektiert(state = {}, action) {
  switch (action.type) {
    case SELEKTIERT_BEWERTUNG:
      return {
        ...state,
        bewertung: action.bewertung
      };
    default:
      return state;
  }
}

export default selektiert;
