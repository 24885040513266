import React, {useState} from "react";
import {DokumentArt} from "../lib/constants";
import ModalerDialog from "../../../components/ModalerDialog";
import {connect} from "react-redux";

const BibliothekDetail = ({aufgabe, texte}) => {
  const [showModal, setShowModal] = useState(false);
  let link = null;
  if (aufgabe.dokumentPfad) {
    if (aufgabe.dokumentArt.id === DokumentArt.linkMitInhalt) {
      link = <button className="btn btn-small" onClick={() => setShowModal(!showModal)}>
        <i className="icon-share-alt"/> {texte.bibliothekOeffnen}
      </button>;
    } else {
      link = <a href={aufgabe.dokumentPfad} target="_blank" className={`ikon ${aufgabe.dokumentArt.icon} valign-middle`}/>;
    }
  }
  
  return (
    <>
      <div className="zeile label-liste">
        <span className="label label-info">{aufgabe.thema}</span>
        <span className="label label-info">{aufgabe.block}</span>
        {link}
      </div>
      <div className="zeile plaintext">{aufgabe.beschreibung}</div>
      {showModal &&
      <ModalerDialog>
        <div style={{width: '95%', height: '95%'}}>
          <div className="modal-header">
            <button type="button" className="close" onClick={() => setShowModal(false)}>&times;</button>
            <h3>{aufgabe.titel}</h3>
          </div>
          <div className="modal-body">
            <iframe src={aufgabe.dokumentPfad} frameBorder="0" width="100%" height="100%" />
          </div>
        </div>
      </ModalerDialog>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    texte: state.i18n.texte
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BibliothekDetail);
