import fetch from 'cross-fetch';
import {addRunningRequest, alleFehlerEntfernen, fehlerHinzufuegen, removeRunningRequest, setFormItem, setFormVisible} from "../actions";

const apiFehlerBehandeln = (store, fehlerliste, values) => {
  alleFehlerEntfernen(store);
  fehlerliste.forEach((fehler) => {
    fehlerHinzufuegen(store, fehler.toString());
  });
  setFormItem(store, values);
  setFormVisible(store, true);
};

const runRequest = (store, values, callback, uri, params) => {
  addRunningRequest(store);
  fetch(uri, params)
    .then(resultat => {
      removeRunningRequest(store);
      if ((resultat.status < 200 || resultat.status >= 300) && resultat.status !== 422) {
        apiFehlerBehandeln(store, [store.state.i18n.server_fehler + resultat.status], values);
      } else {
        resultat.json().then(resultatJson => {
          if (resultatJson.fehler) {
            apiFehlerBehandeln(store, resultatJson.fehler, values);
          } else {
            callback(resultatJson);
          }
        })
      }
    })
    .catch(fehler => {
      removeRunningRequest(store);
      apiFehlerBehandeln(store, [fehler], values);
    });
};

const spielerEinsatzMitId = (store, einsatzId) => {
  let gefundenerEinsatz = {};
  if (einsatzId) {
    store.state.spielInfos.spielerkarten.heimSpielerkarte.startformation.forEach(einsatz => {
      if (einsatz.id.toString() === einsatzId.toString()) {
        gefundenerEinsatz = einsatz;
      }
    });
    store.state.spielInfos.spielerkarten.heimSpielerkarte.ersatzformation.forEach(einsatz => {
      if (einsatz.id.toString() === einsatzId.toString()) {
        gefundenerEinsatz = einsatz;
      }
    });
    store.state.spielInfos.spielerkarten.gastSpielerkarte.startformation.forEach(einsatz => {
      if (einsatz.id.toString() === einsatzId.toString()) {
        gefundenerEinsatz = einsatz;
      }
    });
    store.state.spielInfos.spielerkarten.gastSpielerkarte.ersatzformation.forEach(einsatz => {
      if (einsatz.id.toString() === einsatzId.toString()) {
        gefundenerEinsatz = einsatz;
      }
    });
  }
  return gefundenerEinsatz;
};

export const apiCreateComment = (store, values, callback) => {
  const spielerEinsatz = spielerEinsatzMitId(store, values.spielerEinsatzId);
  const state = store.state;
  const params = {
    method: 'POST',
    headers: {'content-type': 'application/json'},
    credentials: 'same-origin',
    body: JSON.stringify({
      authenticity_token: state.autorisierung.token,
      minute: values.minute,
      zusatzminute: values.zusatzminute,
      titel: values.titel,
      beschreibung: values.beschreibung,
      typ_id: values.typId,
      team_id: values.teamId,
      spieler_id: spielerEinsatz.spielerId,
      unregistrierter_spieler: spielerEinsatz.unregistrierterSpieler,
      trainer_id: values.trainerId,
      schiedsrichter_id: values.schiedsrichterId,
    }),
  };
  runRequest(store, values, callback, state.autorisierung.apiPfad, params);
};

export const apiUpdateComment = (store, values, callback) => {
  const spielerEinsatz = spielerEinsatzMitId(store, values.spielerEinsatzId);
  const state = store.state;
  const params = {
    method: 'PATCH',
    headers: {'content-type': 'application/json'},
    credentials: 'same-origin',
    body: JSON.stringify({
      authenticity_token: state.autorisierung.token,
      minute: values.minute,
      zusatzminute: values.zusatzminute,
      titel: values.titel,
      beschreibung: values.beschreibung,
      typ_id: values.typId,
      team_id: values.teamId,
      spieler_id: spielerEinsatz.spielerId,
      unregistrierter_spieler: spielerEinsatz.unregistrierterSpieler,
      trainer_id: values.trainerId,
      schiedsrichter_id: values.schiedsrichterId,
    }),
  };
  runRequest(store, values, callback, `${state.autorisierung.apiPfad}/${values.id}`, params);
};

export const apiDeleteComment = (store, values, callback) => {
  const state = store.state;
  const uri = `${state.autorisierung.apiPfad}/${values.id}`;
  const params = {
    method: 'DELETE',
    headers: {'content-type': 'application/json'},
    credentials: 'same-origin',
    body: JSON.stringify({
      authenticity_token: state.autorisierung.token
    })
  };
  runRequest(store, values, callback, uri, params);
};
