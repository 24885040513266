import React, {useState} from "react";
import {connect} from "react-redux";
import UnzugeordneterAusbildner from "./UnzugeordneterAusbildner";
import IconToggle from "../IconToggle";
import {AusbildungStatus} from "../../lib/constants";

const AusbildnerListe = ({istOffen = true, ausbildner, abschlussStatusListe, texte}) => {
  const [showAlleAusbildner, setShowAlleAusbildner] = useState(istOffen);
  const sichtbareStatus = [...abschlussStatusListe.map(s => s.id), AusbildungStatus.Angemeldet, AusbildungStatus.Anwesend];
  const ausbildnerFiltered = ausbildner.filter(a => sichtbareStatus.includes(a.ausbildungStatusId));

  return (
    <div className="block">
      <div className="header">
        {texte.ausbildnerListe}
        <button className="btn btn-mini pull-right" onClick={() => setShowAlleAusbildner(!showAlleAusbildner)}>
          <IconToggle istOffen={showAlleAusbildner}/>
        </button>
      </div>
      {showAlleAusbildner &&
      <div className="content">
        {ausbildnerFiltered.map(a => <UnzugeordneterAusbildner key={a.id} ausbildner={a}/>)}
      </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ausbildner: state.ausbildner,
    abschlussStatusListe: state.abschlussStatusListe,
    texte: state.i18n.texte
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AusbildnerListe);
