// @flow

import type { AppState, EinsatzState, SpielerEintrag, SpielerTips, TrainingSpielerEintrag, TrainingState, } from "../../../state/stateTypes";
import type { Dispatch } from "../../../state/actionTypes";

import React from "react";
import { connect } from "react-redux";

import { spielerTipsKommentarSetzen, } from "../../../state/modules/spieler";
import Textfeld from "../../Textfeld";
import SpielerRow from "../SpielerRow";
import AndereTipsRow from "../AndereTipsRow";

const TrainingSpieler = ({ einsatz, training, spielerTipsKommentarSetzen, spieler, spielerEinsatz, }: Props) => {
  const aktiv = !einsatz.readonly;
  const requestDaten = { trainingId: training.id, spielerId: spieler.id, spielerTips: spieler.tips, };
  const kommentarAnzeigen = aktiv ? spieler.tips.kommentarAufgeklappt : (spieler.tips.kommentar || '').length > 0;
  const weitereTipsAnzeigen = einsatz.andereTipsAnzeigen ? spieler.tips.weitereTipsAufgeklappt : false;

  return (
    <tbody>
      <SpielerRow spieler={spieler} spielerEinsatz={spielerEinsatz} requestDaten={requestDaten} prognoseWert={null} typ={'training'} andereTipsAnzeigen={einsatz.andereTipsAnzeigen}/>
      {kommentarAnzeigen &&
        <tr>
          <td colSpan="4">&nbsp;</td>
          <td colSpan="9">
            <Textfeld
              feldName="tipsKommentar"
              feldWert={spieler.tips.kommentar || ''}
              requestDaten={requestDaten}
              breite='388px'
              onChange={(event) => {
                spielerTipsKommentarSetzen(spieler.id, event.target.value, spieler.tips)
              }}
            />
          </td>
        </tr>
      }
      {(weitereTipsAnzeigen) &&
        <AndereTipsRow spieler={spieler}/>
      }
    </tbody>
  );
};

type Props = {
  einsatz: EinsatzState,
  training: TrainingState,
  spielerTipsKommentarSetzen: (number, string, SpielerTips) => void,
  spieler: SpielerEintrag,
  spielerEinsatz: TrainingSpielerEintrag,
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    training: state.training,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    spielerTipsKommentarSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsKommentarSetzen(spielerId, neuerWert, altesTips));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingSpieler);
