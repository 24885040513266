import React from "react";
import useGlobal from "../../store";

const Tabs = ({ tab, setTab }) => {
  const [globalState] = useGlobal();

  return (
    <div className="tabs flex-row">
      <button
        className={tab === "ereignisse" ? "flex-1 active" : "flex-1"}
        onClick={() => setTab("ereignisse")}
      >
        {globalState.i18n.ereignisse}
      </button>
      <button
        className={tab === "analyse" ? "flex-1 active" : "flex-1"}
        onClick={() => setTab("analyse")}
      >
        {globalState.i18n.analyse}
      </button>
    </div>
  );
};

export default Tabs;
