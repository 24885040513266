import {combineReducers} from 'redux';
import {dummyReducer} from "../../../lib/datenhaltung";
import rollbarReducer from "../../../reducers/rollbar";
import berichtReducer from "./berichtReducer";
import fehlerReducer from "./fehlerReducer";
import laufendeAbfragenReducer from "../../../lib/laufendeAbfragenReducer";

export default combineReducers({
  bericht: berichtReducer,
  hatSpesen: dummyReducer,
  listen: dummyReducer,
  api: dummyReducer,
  i18n: dummyReducer,
  fehler: fehlerReducer,
  laufendeAbfragen: laufendeAbfragenReducer,
  rollbar: rollbarReducer,
});
