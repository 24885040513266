const AJAX_SPINNER_ANZEIGEN = 'AJAX_SPINNER_ANZEIGEN';
const AJAX_SPINNER_VERSTECKEN = 'AJAX_SPINNER_VERSTECKEN';

export function spinnerAnzeigen() {
  return (dispatch) => {
    dispatch({type: AJAX_SPINNER_ANZEIGEN});
  };
}

export function spinnerVerstecken() {
  return (dispatch) => {
    dispatch({type: AJAX_SPINNER_VERSTECKEN});
  };
}

function ajaxReducer(state = {}, action) {
  switch (action.type) {
    case AJAX_SPINNER_ANZEIGEN:
      console.log(`action: ${action.type}, count: ${state.spinnerCount}`);
      return {
        ...state,
        spinnerCount: state.spinnerCount + 1,
      };
    case AJAX_SPINNER_VERSTECKEN:
      console.log(`action: ${action.type}, count: ${state.spinnerCount}`);
      return {
        ...state,
        spinnerCount: Math.max(state.spinnerCount - 1, 0),
      };
    default:
      return state;
  }
}

export default ajaxReducer;
