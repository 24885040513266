import {combineReducers} from 'redux';

import ajaxReducer from "./modules/ajax";
import spielReducer from "./modules/spiel";
import heartbeatReducer from "./modules/heartbeat";
import spielanzeigeReducer from "./modules/spielanzeige";
import spielverlaufReducer from "./modules/spielverlauf";
import editMarkiererReducer from "./modules/editMarkierer";
import spielfeldReducer from "./modules/spielfeld";
import teamSpielerkarteReducer from "./modules/teamSpielerkarte";
import rollbarReducer from "../../../reducers/rollbar";

function dummyReducer(state = {}, action) {
  return state;
}

export default combineReducers({
  ajax: ajaxReducer,
  spiel: spielReducer,
  heartbeat: heartbeatReducer,
  spielanzeige: spielanzeigeReducer,
  spielverlauf: spielverlaufReducer,
  editMarkierer: editMarkiererReducer,
  spielfeld: spielfeldReducer,
  heim: teamSpielerkarteReducer,
  gast: teamSpielerkarteReducer,
  liga: dummyReducer,
  i18n: dummyReducer,
  api: dummyReducer,
  rollbar: rollbarReducer,
});
