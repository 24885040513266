import React, {useEffect} from 'react';
import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

import reducer from './state/reducer';

import preloader from "./state/preloader";
import {rollbarInitialisieren} from "../../reducers/rollbar";
import {roomList} from "./state/rooms";
import './support.scss'
import ErrorBoundary from "../../components/ErrorBoundary";
import Lobby from "./components/Lobby";
import AnsichtAnfrage from "./components/AnsichtAnfrage";

const Support = (props) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducer, preloader(props), composeEnhancers(applyMiddleware(thunk)));
  const istSupport = props.person.istSupport;

  useEffect(() => {
    store.dispatch(rollbarInitialisieren());
    store.dispatch(roomList());
  }, []);

  return (
    <Provider store={store}>
      <ErrorBoundary fehlermeldung={props.i18n.allgemeinerFehler}>
        <div className="support-container">
          {istSupport === true &&
          <Lobby/>
          }
          {istSupport === false &&
          <AnsichtAnfrage/>
          }
        </div>
      </ErrorBoundary>
    </Provider>
  );
};

export default Support;
