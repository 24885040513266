import React from "react";
import {Provider} from "react-redux"
import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";
import preloaderFuerPlatzchef from "./state/preloaderFuerPlatzchef";
import reducerFuerPlatzchef from "./state/reducerFuerPlatzchef";
import ErrorBoundary from "../../components/ErrorBoundary";
import Spielgruppen from "./components/Spielgruppen";
import Meldungen from "./components/Meldungen";

const Platzchef = (props) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducerFuerPlatzchef, preloaderFuerPlatzchef(props), composeEnhancers(applyMiddleware(thunk)));

  return (
    <Provider store={store}>
      <ErrorBoundary fehlermeldung={props.i18n.allgemeinerFehler}>
        <Spielgruppen/>
        <Meldungen/>
      </ErrorBoundary>
    </Provider>
  );
};

export default Platzchef

