/*  
* Dieser Reducer kann verwendet werden um laufende http-requests zu verfolgen.
* 
* Verwendung:
* - preloader: root-object erweitern mit einem object für die laufenden Abfragen, z.B: "laufendeAbfragen: {}"
* - reducer: analog erweitern mit diesem reducer, z.B.: "laufendeAbfragen: laufendeAbfragenReducer"
* 
* In einer action kann das dann z.B. so verwendet werden:
* 
* dispatch(startRequest('eindeutigerStringFürDieseAbfrage'));
* fetch(...)
*   .then(...)
*   .catch(...)
*   .finally(() => {
*     dispatch(endeRequest('eindeutigerStringFürDieseAbfrage'));
*   })
* 
*/

const LAUFENDE_ABFRAGEN_START = 'LAUFENDE_ABFRAGEN_START';
const LAUFENDE_ABFRAGEN_ENDE = 'LAUFENDE_ABFRAGEN_ENDE';

export function startRequest(abfrageTyp) {
  return (dispatch) => {
    dispatch({type: LAUFENDE_ABFRAGEN_START, abfrageTyp: abfrageTyp});
  };
}

export function endeRequest(abfrageTyp) {
  return (dispatch) => {
    dispatch({type: LAUFENDE_ABFRAGEN_ENDE, abfrageTyp: abfrageTyp});
  };
}

function laufendeAbfragenReducer(state = {}, action) {
  switch (action.type) {
    case LAUFENDE_ABFRAGEN_START:
      const temp = {...state};
      temp[action.abfrageTyp] = true;
      return temp;
    case LAUFENDE_ABFRAGEN_ENDE:
      const temp2 = {...state};
      temp2[action.abfrageTyp] = false;
      return temp2;
    default:
      return state;
  }
}

export default laufendeAbfragenReducer;
