import React, {useState} from "react";
import {connect} from "react-redux";
import {fisNachrichtSenden} from "../state/fisReducer";
import ModalerDialog from "../../../components/ModalerDialog";

const FisNachricht = ({empfaenger, zusatzdaten, headerText, texte, onClose, fisSenden}) => {
  const [betreff, setBetreff] = useState('');
  const [nachricht, setNachricht] = useState('');
  const [fehler, setFehler] = useState([]);

  const senden = () => {
    setFehler([]);
    fisSenden(empfaenger, betreff, nachricht, zusatzdaten, callback);
  };

  const callback = (result) => {
    if (result.ok) {
      onClose();
    } else {
      setFehler(result.fehler);
    }
  };

  return (
    <ModalerDialog>
      <div className="block">
        <div className="header">
          <b>{headerText}</b>
        </div>
        <div className="content">
          {fehler.length > 0 &&
          <div className="zeile">
            {fehler.map(f => <div className="alert alert-error"><span>{f}</span></div>)}
          </div>
          }
          <div className="zeile">
            <label>{texte.betreff}</label>
            <input type="text" onChange={(ev) => setBetreff(ev.currentTarget.value)} value={betreff}/>
          </div>
          <div className="zeile">
            <label>{texte.nachricht}</label>
            <textarea onChange={(ev) => setNachricht(ev.currentTarget.value)} value={nachricht} rows={5}/>
          </div>
          <div className="zeile">
            <button className="btn btn-primary" onClick={() => senden()}>{texte.senden}</button>
            <button className="btn pull-right" onClick={() => onClose()}>{texte.abbrechen}</button>
          </div>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    fis: state.fis,
    texte: state.i18n.texte.fis
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fisSenden: (empfaenger, betreff, nachricht, zusatzdaten, callback) => { dispatch(fisNachrichtSenden(empfaenger, betreff, nachricht, zusatzdaten, callback)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FisNachricht);
