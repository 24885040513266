import React from 'react'

const Dropdown = (props) => {
  const handleChange = (e) => {
    props.onValueChange(e.target.value, props.typ);
  };

  return (
    <select onChange={handleChange}>
      {props.includeBlank &&
        <option value=""></option>
      }
      {
        props.options.sort((a, b) => a.text.localeCompare(b.text)).map((option) => {
          return (
            <option key={option.value} value={option.value}>{option.text}</option>
          );
        })
      }
    </select>
  );
};
export default Dropdown
