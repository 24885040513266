import React, {useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../../components/ModalerDialog";
import PersonBadge from "../../../../components/PersonBadge";
import {BEXIO_STATUS} from "../../lib/constants";
import {formatiereBetrag} from "../../../../lib/formatierungen";
import {rechnungenBemerkungSpeichern} from "../../state/modules/rechnungskontrollen";

const BemerkungenModal = ({rechnungen, onHide, bexio, locale, texte, rechnungenBemerkungSpeichern}) => {
  const gefilterteRechnungen = bexio.aktiv ? rechnungen.filter(r => [BEXIO_STATUS.pending, BEXIO_STATUS.partial, BEXIO_STATUS.canceled, BEXIO_STATUS.unpaid].includes(r.bexioStatus)) : rechnungen;
  const rechnung = gefilterteRechnungen.length === 1 ? gefilterteRechnungen[0] : null;
  const [bemerkungen, setBemerkungen] = useState(rechnung ? (rechnung.bemerkungen || '') : '');
  
  const speichern = () => {
    if (gefilterteRechnungen.length > 0) {
      rechnungenBemerkungSpeichern(gefilterteRechnungen, bemerkungen);
    }
    schliessen();
  };

  const schliessen = () => {
    onHide();
  };

  return (
    <ModalerDialog>
      <div className="modal modal-wide">
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{texte.titel}</h3>
        </div>
        <div className="modal-body" style={{overflow: 'visible'}}>
          <table className="table attributes ">
            <tbody>
            <tr>
              <th>{texte.person}</th>
              <td>
                {gefilterteRechnungen.length === 1 &&
                <PersonBadge person={rechnung.person} personVerlinken={false} showVcf={false} fotoTooltipAktiv={false}/>
                }
                {gefilterteRechnungen.length > 1 &&
                <div className="label-liste">
                  {gefilterteRechnungen.map(r => <span key={r.id} className="label label-info">{r.person.name}</span>)}
                </div>
                }
              </td>
            </tr>
            {rechnung &&
            <>
              <tr>
                <th>{texte.leistung}</th>
                <td>{rechnung.leistung.bezeichnung}</td>
              </tr>
              <tr>
                <th>{texte.betragFreiwillig}</th>
                <td>
                  {rechnung.betragFreiwillig === true &&
                  <i className="icon-ok"/>
                  }
                </td>
              </tr>
              <tr>
                <th>{texte.betrag}</th>
                <td>{formatiereBetrag(rechnung.betrag, locale)}</td>
              </tr>
            </>
            }
            <tr>
              <th>{texte.bemerkungen}</th>
              <td><textarea rows={5} style={{width: '100%'}} value={bemerkungen} onChange={ev => setBemerkungen(ev.currentTarget.value)}/></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" disabled={gefilterteRechnungen.length === 0} onClick={speichern}>{texte.speichern}</button>
          <button type="button" className="btn" onClick={schliessen}>{texte.abbrechen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    bexio: state.bexio,
    locale: state.i18n.locale,
    texte: state.i18n.texte.bemerkungenBearbeiten,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rechnungenBemerkungSpeichern: (rechnungen, bemerkungen) => { dispatch(rechnungenBemerkungSpeichern(rechnungen, bemerkungen)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BemerkungenModal);
