// @flow

import type { AppState, SpielerEintrag, SpielerkarteEintrag, Wettkampfleistung } from "../../../state/stateTypes";
import type { Dispatch } from "../../../state/actionTypes";

import React from "react";
import { connect } from "react-redux";
import SpielerBadge from "../../SpielerBadge";

const WettkampfleistungSpieler = ({ spieler, spielerEinsatz, wettkampfleistung, }: Props) => {

  const spielerRow = <tr>
    <td style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }}>
      <span className="label label-info">{spieler.passnummer}</span>
      {
        spielerEinsatz.spielfuehrer ? <span className="label label-important spielfuehrer">C</span> : ''
      }
    </td>
    <td style={{ verticalAlign: 'middle' }}>
      <SpielerBadge spieler={spieler}/>
    </td>
    <td>
      <div className="spielposition" style={{ width: '110px', verticalAlign: 'middle' }}>
        {spielerEinsatz.spielposition.name}
      </div>
    </td>
    <td className="text-center vertical-middle">{spielerEinsatz.rueckennummer}</td>
    <td className="text-center vertical-middle">{(wettkampfleistung && wettkampfleistung.von && wettkampfleistung.bis) ? wettkampfleistung.bis - wettkampfleistung.von + 1 : '-'}</td>
    <td className="text-center vertical-middle">{(wettkampfleistung && wettkampfleistung.t && wettkampfleistung.t.toFixed(1)) || '-'}</td>
    <td className="text-center vertical-middle">{(wettkampfleistung && wettkampfleistung.i && wettkampfleistung.i.toFixed(1)) || '-'}</td>
    <td className="text-center vertical-middle">{(wettkampfleistung && wettkampfleistung.p && wettkampfleistung.p.toFixed(1)) || '-'}</td>
    <td className="text-center vertical-middle">{(wettkampfleistung && wettkampfleistung.s && wettkampfleistung.s.toFixed(1)) || '-'}</td>
    <td className="text-center vertical-middle">{(wettkampfleistung && wettkampfleistung.durchschnitt && wettkampfleistung.durchschnitt.toFixed(1)) || '-'}</td>
    <td className="text-center vertical-middle">{(wettkampfleistung && wettkampfleistung.note && wettkampfleistung.note.toFixed(1)) || '-'}</td>
  </tr>;

  if((wettkampfleistung && wettkampfleistung.kommentar)) {
    return (
      <tbody>
      {spielerRow}
      <tr>
        <td colSpan="5">&nbsp;</td>
        <td colSpan="7">
          {(wettkampfleistung && wettkampfleistung.kommentar)}
        </td>
      </tr>
      </tbody>
    );
  } else {
    return (
      <tbody>
      {spielerRow}
      </tbody>
    );
  }
};

type Props = {
  spieler: SpielerEintrag,
  spielerEinsatz: SpielerkarteEintrag,
  wettkampfleistung: Wettkampfleistung | void,
}

const mapStateToProps = (state: AppState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WettkampfleistungSpieler);
