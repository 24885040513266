import React, {useState} from "react";
import PersonIcon from "../../../components/PersonIcon";
import FreigebeneneAufgabe from "./FreigebeneneAufgabe";
import IconToggle from "./IconToggle";

const FreigebenenePersonAufgaben = ({person, aufgaben}) => {
  const [showDetail, setShowDetail] = useState(false);

  return (
    <div className="block">
      <div className="header">
        <b>{person.name}</b><PersonIcon person={person}/>
        <span className="pull-right">
          <button className="btn btn-mini" onClick={() => setShowDetail(!showDetail)}>
            <IconToggle istOffen={showDetail}/>
          </button>
        </span>
      </div>
      {showDetail &&
      <div className="content">
        {aufgaben.map(a => <FreigebeneneAufgabe key={a.id} aufgabe={a}/>)}
      </div>
      }
    </div>
  );
};

export default FreigebenenePersonAufgaben;
