// @flow

import type {EinsatzState, Team, TeamState, TurnierState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";
import TeamBadge from "../../../../components/TeamBadge";
import einsatz from "../../state/modules/einsatz";

const TurnierEinsatzInfos = ({einsatz, turnier, teams, texte}: { einsatz: EinsatzState, turnier: TurnierState, teams: TeamState, texte: any}) => {
  let team = teams.liste.find(t => t.id === turnier.teamId);

  return (
    <div className="tab-pane">
      <table className="table table-striped">
        <tbody>
        <tr>
          <th>{texte.titel.zeit}</th>
          <td>
            <i className="icon-calendar"/> {einsatz.datum}
            <br/>
            <i className="icon-time"/> {einsatz.zeit}
          </td>
        </tr>
        <tr>
          <th>{texte.titel.ort}</th>
          <td>
            {turnier.verein || '-'}
            {turnier.austragungsort ? <><br/>{turnier.austragungsort}</> : <></>}
            {turnier.wettspielfeld ? <><br/>{turnier.wettspielfeld}</> : <></>}
          </td>
        </tr>
        <tr>
          <th>{texte.titel.typ}</th>
          <td>
            <span className="label">{einsatz.einsatzTyp}</span>
          </td>
        </tr>
        <tr>
          <th>{texte.titel.rolle}</th>
          <td>
            <span className="label">{einsatz.einsatzRolle}</span>
          </td>
        </tr>
        <tr>
          <th>{texte.titel.turnier}</th>
          <td>
            <span className="label label-info">{turnier.nummer || '-'}</span>
            <br/>
            {turnier.turnierart || '-'}
          </td>
        </tr>
        <tr>
          <th>{texte.titel.beschreibung}</th>
          <td>
            {turnier.beschreibung || '-'}
            {turnier.bemerkung ? <><br/>{turnier.bemerkung}</> : <></>}
          </td>
        </tr>
        {team && 
          <tr>
            <th>{texte.titel.team}</th>
            <td>
              <TeamBadge className="komplett" team={{name: team.name, logoUrl: team.logo, ligaSpielklasse: team.ligaUndSpielklasse}} />
            </td>
          </tr>
        }
        {
          turnier.andereTeamIds.length > 0 && <tr>
            <th>{texte.titel.andereTeams}</th>
            <td>
              {teams.liste.filter(t => turnier.andereTeamIds.includes(t.id)).map(t => <TeamBadge className="komplett" key={t.id} team={{name: t.name, logoUrl: t.logo, ligaSpielklasse: t.ligaUndSpielklasse}}/>)}
            </td>
          </tr>
        }
        <tr>
          <th>{texte.titel.abschluss}</th>
          <td>
            {
              einsatz.abgeschlossen ? (
                einsatz.abschlussDatum ?
                  texte.abschluss.abgeschlossenAm + " " + einsatz.abschlussDatum : 
                  texte.abschluss.abgeschlossen
              ) : texte.abschluss.keinAbschluss
            }
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    einsatz: state.einsatz,
    turnier: state.turnier,
    teams: state.teams,
    texte: state.i18n.infos,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TurnierEinsatzInfos);
