import React from "react";
import {connect} from "react-redux";
import {useDrag} from "react-dnd";
import {ItemTypes} from "../../lib/constants";

const UnzugeordneterAusbildner = ({ausbildner}) => {
  const [{}, drag] = useDrag({
    item: {type: ItemTypes.Ausbildner, id: ausbildner.id}
  });

  return (
    <div className="eintrag-box" ref={drag}>{ausbildner.name}</div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UnzugeordneterAusbildner);
