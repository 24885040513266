// @flow

import type {AppState, EinsatzState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import {protokollNeuenEintragErstellen} from "../../state/modules/protokoll"

const NeuerEintrag = (props: {
  einsatz: EinsatzState,
  protokollNeuenEintragErstellen: ("oben" | "unten", string, string, boolean) => void,
  art: string,
  aktion: string,
  istTor: boolean,
  icon: string,
  titel: string,
  kurzerTitel: string,
  position: "oben" | "unten",
}) => {
  const aktiv = !props.einsatz.readonly;

  const callback = () => {
    if (aktiv) {
      props.protokollNeuenEintragErstellen(props.position, props.art, props.aktion, props.istTor)
    }
  };

  return <div style={{display: "flex", flexDirection: "column"}}>
    <a onClick={callback} className="btn btn-large neuer-eintrag" rel="tooltip" data-original-title={props.titel}>
      <div style={{width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <img src={props.icon} alt={props.titel}/>
      </div>
    </a>
    <div style={{fontSize: "10px", textAlign: "center"}}>
      {props.kurzerTitel}
    </div>
  </div>

};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    protokollNeuenEintragErstellen: (position: "oben" | "unten", art: string, aktion: string, istTor: boolean) => {
      dispatch(protokollNeuenEintragErstellen(position, art, aktion, istTor));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NeuerEintrag);
