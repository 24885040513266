import React from "react";
import {connect} from "react-redux";
import AusbildnerListe from "./AusbildnerListe";
import LehrerTeams from "./LehrerTeams";

const LehrerListe = ({klassenlehrer}) => {
  return(
    <>
      <AusbildnerListe />
      {klassenlehrer.map((lehrer) => <LehrerTeams key={lehrer.id} klassenlehrer={lehrer}/>) }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    klassenlehrer: state.klassenlehrer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LehrerListe);
