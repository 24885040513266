import React from "react";
import {connect} from "react-redux";
import LigaSpielgruppen from "./LigaSpielgruppe";

const Liga = ({liga, onClose, spielgruppen, spiele}) => {
  const ligaSpielgruppen = spielgruppen.filter(sg => sg.ligaId === liga.id);

  return (
    <>
      <h2 className="fixed-to-top">
        <button className="btn" onClick={() => onClose()}>
          <i className="icon-arrow-left"/>
        </button>
        &nbsp;
        {liga.bezeichnung}
      </h2>
      <div className="liga-spielgruppen">
      {ligaSpielgruppen.map(sg => {
        const spieleInSpielgruppe = spiele.filter(s => s.spielgruppeId === sg.id);
        return <LigaSpielgruppen key={sg.id} spielgruppe={sg} spiele={spieleInSpielgruppe}/>;
      })}
    </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    spielgruppen: state.spielgruppen,
    spiele: state.spiele,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Liga);
