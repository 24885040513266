// @flow

import type {
  SpielerState,
  SpielerEintrag,
  SpielerTips,
  TorhueterNote,
  PrognoseFragen,
  AndereTips
} from "../stateTypes";
import type { Action, ActionThunk } from "../actionTypes";
import { spielerDatenLaden } from "../datenLaden";
import { apiRequestAbschicken } from "./ajax";

export function spielerKommentarfeldEinAusblenden(spielerId: number): Action {
  return { type: "SPIELER_KOMMENTARFELD_EIN_AUSBLENDEN", spielerId }
}

export function spielerWeitereTipsEinAusblenden(spielerId: number): Action {
  return { type: "SPIELER_WEITERE_TIPS_EIN_AUSBLENDEN", spielerId }
}

export function spielerPrognoseEinAusblenden(spielerId: number): Action {
  return { type: "SPIELER_PROGNOSE_EIN_AUSBLENDEN", spielerId }
}

let tipsLocksTimerId = null;

export function tipsLocksTimerStarten(): ActionThunk {
  return (dispatch, getState) => {
    clearInterval(tipsLocksTimerId);
    if (getState().einsatz.andereTipsAnzeigen) {
      tipsLocksTimerId = setInterval(() => {
        dispatch(tipsLocksTimerAbfragen());
      }, 60000);
    }
  };
}

function tipsLocksTimerAbfragen(): ActionThunk {
  return (dispatch, getState) => {
    apiRequestAbschicken(
      getState().pfade.api.tipsLocks, "GET", null, null, {}, (dispatch, resultatJson) => {
        dispatch({
          type: "SPIELER_ALLE_TIPS_LOCKS_SETZEN",
          eigeneScoutingEinsatzId: getState().einsatz.id,
          werte: resultatJson
        });
      }
    )(dispatch, getState);
  };
}

function tipsSetzenAction(spielerId: number, neuesTips: SpielerTips): ActionThunk {
  return (dispatch, getState) => {
    dispatch({
      type: "SPIELER_TIPS_SETZEN",
      scoutingEinsatzId: getState().einsatz.id,
      spielerId,
      neuesT: neuesTips.t,
      neuesI: neuesTips.i,
      neuesP: neuesTips.p,
      neuesS: neuesTips.s,
      neueEntwicklung: neuesTips.entwicklung,
      neueNote: neuesTips.note,
      neuerKommentar: neuesTips.kommentar,
    });
  };
}

export function spielerTipsTSetzen(spielerId: number, neuerWert: number | null, altesTips: SpielerTips): ActionThunk {
  const neuesTips = {
    ...altesTips,
    t: neuerWert,
  };
  return tipsSetzenAction(spielerId, neuesTips);
}

export function spielerTipsISetzen(spielerId: number, neuerWert: number | null, altesTips: SpielerTips): ActionThunk {
  const neuesTips = {
    ...altesTips,
    i: neuerWert,
  };
  return tipsSetzenAction(spielerId, neuesTips);
}

export function spielerTipsPSetzen(spielerId: number, neuerWert: number | null, altesTips: SpielerTips): ActionThunk {
  const neuesTips = {
    ...altesTips,
    p: neuerWert,
  };
  return tipsSetzenAction(spielerId, neuesTips);
}

export function spielerTipsSSetzen(spielerId: number, neuerWert: number | null, altesTips: SpielerTips): ActionThunk {
  const neuesTips = {
    ...altesTips,
    s: neuerWert,
  };
  return tipsSetzenAction(spielerId, neuesTips);
}

export function spielerTipsEntwicklungSetzen(spielerId: number, neuerWert: number | null, altesTips: SpielerTips): ActionThunk {
  const neuesTips = {
    ...altesTips,
    entwicklung: neuerWert,
  };
  return tipsSetzenAction(spielerId, neuesTips);
}

export function spielerTipsNoteSetzen(spielerId: number, neuerWert: number | null, altesTips: SpielerTips): ActionThunk {
  const neuesTips = {
    ...altesTips,
    note: neuerWert,
  };
  return tipsSetzenAction(spielerId, neuesTips);
}

export function spielerTipsKommentarSetzen(spielerId: number, neuerWert: string, altesTips: SpielerTips): ActionThunk {
  const neuesTips = {
    ...altesTips,
    kommentar: neuerWert,
  };
  return tipsSetzenAction(spielerId, neuesTips);
}

export function spielerPrognoseFragenSetzen(spielerId: number, prognoseFragen: PrognoseFragen): Action {
  return { type: "SPIELER_PROGNOSE_FRAGEN_SETZEN", spielerId, prognoseFragen };
}

export function spielerLoeschNachfrageAnzeigen(spielerId: number): Action {
  return { type: "SPIELER_LOESCH_NACHFRAGE_ANZEIGEN", spielerId };
}

export function spielerLoeschNachfrageVerstecken(spielerId: number): Action {
  return { type: "SPIELER_LOESCH_NACHFRAGE_VERSTECKEN", spielerId };
}

export function spielerTipsLoeschen(spielerId: number, altesTips: SpielerTips): ActionThunk {
  return (dispatch, getState) => {
    const neuesTips = {
      ...altesTips,
      t: null,
      i: null,
      p: null,
      s: null,
      entwicklung: null,
      kommentar: null,
      note: null,
    };
    tipsSetzenAction(spielerId, neuesTips)(dispatch, getState);
    dispatch({ type: "SPIELER_TORHUETER_NOTEN_ZURUECKSETZEN", spielerId });
    dispatch({ type: "SPIELER_LOESCH_NACHFRAGE_VERSTECKEN", spielerId });
  };
}

export function spielerTorhueterNoteSetzen(spielerId: number, noteArtId: number, neuerWert: number | null): Action {
  return { type: "SPIELER_TORHUETER_NOTE_SETZEN", spielerId, noteArtId, neuerWert };
}

export function spielerTorhueterNoteKommentarSetzen(spielerId: number, noteArtId: number, neuerWert: string): Action {
  return { type: "SPIELER_TORHUETER_NOTE_KOMMENTAR_SETZEN", spielerId, noteArtId, neuerWert };
}

function arraysOfTipsGleich(arr1, arr2) {
  return arr1 != null && arr2 != null &&
    arr1.length === arr2.length &&
    arr1.every((element, index) => tipsGleich(element, arr2[index]));
}

function tipsGleich(ersteTips: SpielerTips | AndereTips, zweiteTips: SpielerTips | AndereTips) {
  return (
    ersteTips.scoutingEinsatzId === zweiteTips.scoutingEinsatzId &&
    ersteTips.t === zweiteTips.t &&
    ersteTips.i === zweiteTips.i &&
    ersteTips.p === zweiteTips.p &&
    ersteTips.s === zweiteTips.s &&
    ersteTips.entwicklung === zweiteTips.entwicklung &&
    ersteTips.note === zweiteTips.note &&
    ersteTips.kommentar === zweiteTips.kommentar
  );
}

function spielerReducer(state: SpielerState, action: Action): SpielerState | null {
  let durchschnitt = (tips) => {
    return (
      (tips.t || 0) +
      (tips.i || 0) +
      (tips.p || 0) +
      (tips.s || 0)
    ) / (
      (tips.t ? 1 : 0) +
      (tips.i ? 1 : 0) +
      (tips.p ? 1 : 0) +
      (tips.s ? 1 : 0)
    )
  };

  switch (action.type) {
    case "SPIELER_KOMMENTARFELD_EIN_AUSBLENDEN":
      return {
        ...state,
        liste: state.liste.map<SpielerEintrag>((spieler) => {
          if (spieler.id === action.spielerId) {
            return {
              ...spieler,
              tips: {
                ...spieler.tips,
                kommentarAufgeklappt: !spieler.tips.kommentarAufgeklappt,
              },
            }
          } else {
            return spieler;
          }
        }),
      };
    case "SPIELER_WEITERE_TIPS_EIN_AUSBLENDEN":
      return {
        ...state,
        liste: state.liste.map<SpielerEintrag>((spieler) => {
          if (spieler.id === action.spielerId) {
            return {
              ...spieler,
              tips: {
                ...spieler.tips,
                weitereTipsAufgeklappt: !spieler.tips.weitereTipsAufgeklappt,
              },
            }
          } else {
            return spieler;
          }
        }),
      };
    case "SPIELER_PROGNOSE_EIN_AUSBLENDEN":
      return {
        ...state,
        liste: state.liste.map<SpielerEintrag>((spieler) => {
          if (spieler.id === action.spielerId && spieler.prognoseFragen) {
            return {
              ...spieler,
              prognoseFragen: {
                ...spieler.prognoseFragen,
                prognoseAufgeklappt: !spieler.prognoseFragen.prognoseAufgeklappt,
              },
            }
          } else {
            return spieler;
          }
        }),
      };
    case "SPIELER_PROGNOSE_FRAGEN_SETZEN":
      return {
        ...state,
        liste: state.liste.map<SpielerEintrag>((spieler) => {
          if (spieler.id === action.spielerId && spieler.prognoseFragen) {
            return { ...spieler, prognoseFragen: action.prognoseFragen }
          } else {
            return spieler;
          }
        }),
      };
    case "SPIELER_TIPS_SETZEN":
      return {
        ...state,
        liste: state.liste.map<SpielerEintrag>((spieler) => {
          if (spieler.id === action.spielerId) {
            let neuesTips = {
              ...spieler.tips,
              scoutingEinsatzId: action.scoutingEinsatzId,
              t: action.neuesT,
              i: action.neuesI,
              p: action.neuesP,
              s: action.neuesS,
              entwicklung: action.neueEntwicklung,
              note: action.neueNote,
              kommentar: action.neuerKommentar,
            };
            neuesTips.noteDurchschnitt = durchschnitt(neuesTips);
            return {
              ...spieler,
              tips: neuesTips,
            }
          } else {
            return spieler;
          }
        }),
      };
    case "SPIELER_ALLE_TIPS_LOCKS_SETZEN":
      const updateTips = (spieler, gelieferteWerte, eigeneScoutingEinsatzId) => {
        const gelieferterWert = gelieferteWerte.find(wert => wert.spielerId === spieler.id && !spieler.nichtregistriert);

        if (!gelieferterWert) {
          return null;
        }

        return {
          scoutingEinsatzId: gelieferterWert.scoutingEinsatzId,
          t: gelieferterWert.t,
          i: gelieferterWert.i,
          p: gelieferterWert.p,
          s: gelieferterWert.s,
          entwicklung: gelieferterWert.entwicklung,
          note: gelieferterWert.note,
          kommentar: gelieferterWert.kommentar,
          nurLesen: gelieferterWert.scoutingEinsatzId !== eigeneScoutingEinsatzId,
          noteDurchschnitt: durchschnitt({
            t: gelieferterWert.t,
            i: gelieferterWert.i,
            p: gelieferterWert.p,
            s: gelieferterWert.s,
          }),
        };
      };

      const updateAndereTips = (spieler, gelieferteWerte) => {
        const andereWerte = gelieferteWerte.filter(wert => wert.spielerId === spieler.id && !spieler.nichtregistriert);

        const neueTips = andereWerte.map(andererWert => ({
          scoutingEinsatzId: andererWert.scoutingEinsatzId,
          t: andererWert.t,
          i: andererWert.i,
          p: andererWert.p,
          s: andererWert.s,
          entwicklung: andererWert.entwicklung,
          note: andererWert.note,
          kommentar: andererWert.kommentar,
          noteDurchschnitt: durchschnitt({
            t: andererWert.t,
            i: andererWert.i,
            p: andererWert.p,
            s: andererWert.s,
          }),
          scout: andererWert.scout,
        }));

        return neueTips.length > 0 ? neueTips : null;
      };

      return {
        ...state,
        liste: state.liste.map((spieler) => {
          const neueScoutTips = updateTips(spieler, action.werte.scout_tips, action.eigeneScoutingEinsatzId);
          const neueAndereTips = updateAndereTips(spieler, action.werte.andere_tips);

          if (neueScoutTips && !tipsGleich(spieler.tips, neueScoutTips)) {
            spieler = { ...spieler, tips: neueScoutTips };
          }

          if (neueAndereTips && !arraysOfTipsGleich(spieler.andereTips, neueAndereTips)) {
            spieler = { ...spieler, andereTips: neueAndereTips };
          }

          return spieler;
        }),
      };
    case "SPIELER_TORHUETER_NOTE_SETZEN":
      return {
        ...state,
        liste: state.liste.map<SpielerEintrag>((spieler: SpielerEintrag) => {
          if (spieler.id === action.spielerId) {
            let nichtGesamtNotenTotal = 0;
            let nichtGesamtNotenAnzahl = 0;
            spieler.torhueterNoten.forEach((note: TorhueterNote) => {
              let notenWert = note.noteArtId === action.noteArtId ? action.neuerWert : note.note;
              if (!note.gesamtnote && notenWert) {
                nichtGesamtNotenTotal += notenWert;
                nichtGesamtNotenAnzahl += 1;
              }
            });
            let gesamtNote = nichtGesamtNotenAnzahl > 0 ? Math.round(nichtGesamtNotenTotal / nichtGesamtNotenAnzahl * 10) / 10 : null;
            return ({
              ...spieler,
              torhueterNoten: spieler.torhueterNoten.map((note: TorhueterNote) => {
                if (note.gesamtnote) {
                  if (note.note !== gesamtNote) {
                    return {
                      ...note,
                      note: gesamtNote,
                    };
                  } else {
                    return note;
                  }
                } else if (note.noteArtId === action.noteArtId) {
                  return {
                    ...note,
                    note: action.neuerWert,
                  }
                } else {
                  return note;
                }
              }),
            });
          } else {
            return spieler;
          }
        }),
      };
    case "SPIELER_TORHUETER_NOTEN_ZURUECKSETZEN":
      return {
        ...state,
        liste: state.liste.map<SpielerEintrag>((spieler: SpielerEintrag) => {
          if (spieler.id === action.spielerId) {
            return ({
              ...spieler,
              torhueterNoten: spieler.torhueterNoten.map((note: TorhueterNote) => {
                return {
                  ...note,
                  note: null,
                  kommentar: null,
                };
              }),
            });
          } else {
            return spieler;
          }
        }),
      };
    case "SPIELER_TORHUETER_NOTE_KOMMENTAR_SETZEN":
      return {
        ...state,
        liste: state.liste.map<SpielerEintrag>((spieler: SpielerEintrag) => {
          if (spieler.id === action.spielerId) {
            return ({
              ...spieler,
              torhueterNoten: spieler.torhueterNoten.map((note: TorhueterNote) => {
                if (note.noteArtId === action.noteArtId) {
                  return {
                    ...note,
                    kommentar: action.neuerWert,
                  }
                } else {
                  return note;
                }
              }),
            });
          } else {
            return spieler;
          }
        }),
      };
    case "AJAX_SPIELER_DATEN_ZURUECKSETZEN":
      return spielerDatenLaden(action.serverDaten);
    case "SPIELER_LOESCH_NACHFRAGE_ANZEIGEN":
      return {
        ...state,
        liste: state.liste.map<SpielerEintrag>((spieler: SpielerEintrag) => {
          if (spieler.id === action.spielerId) {
            return ({
              ...spieler,
              loeschNachfrageAnzeigen: true,
            });
          } else {
            return spieler;
          }
        }),
      };
    case "SPIELER_LOESCH_NACHFRAGE_VERSTECKEN":
      return {
        ...state,
        liste: state.liste.map<SpielerEintrag>((spieler: SpielerEintrag) => {
          if (spieler.id === action.spielerId) {
            return ({
              ...spieler,
              loeschNachfrageAnzeigen: false,
            });
          } else {
            return spieler;
          }
        }),
      };
    default:
      return state || null;
  }
}

export default spielerReducer
