import React, {useState} from "react";
import {connect} from "react-redux";
import {formatiereDatumZeit} from "../../lib/formatierungen";
import {arbeitsraumGelesen} from "../../state/arbeitsraumReducer";
import {aufgabeZuteilungDelete, aufgabeZuteilungUpdate} from "../../state/teamTeilnehmerReducer";
import Datepicker from "../Datepicker";
import AufgabeLabel from "../AufgabeLabel";
import IconToggle from "../IconToggle";
import BibliothekDetail from "../BibliothekDetail";
import ArbeitsraumListe from "../ArbeitsraumListe";
import VideoTagging from "./VideoTagging";
import Tooltip from "../../../../components/Tooltip";

export const AufgabenModus = {
  zuteilen: 'zuteilen',
  abschliessen: 'abschliessen'
};

const AufgabeZuteilung = ({aufgabeZuteilung, teamTeilnehmer, aufgabenModus, ausbildung, arbeitsraumListe, dokumentPfad, texte, arbeitsraumGelesen, aufgabeUpdate, aufgabeLoeschen}) => {
  const ausbildner = teamTeilnehmer.ausbildner;
  const [editMode, setEditMode] = useState(aufgabenModus === AufgabenModus.abschliessen);
  const [chatMode, setChatMode] = useState(false);
  const [von, setVon] = useState(aufgabeZuteilung.von ? new Date(aufgabeZuteilung.von) : undefined);
  const [bis, setBis] = useState(aufgabeZuteilung.bis ? new Date(aufgabeZuteilung.bis) : undefined);
  const [beurteilung, setBeurteilung] = useState(aufgabeZuteilung.beurteilung || '');
  const darfLoeschen = ausbildung.bearbeitbar && aufgabenModus === AufgabenModus.zuteilen && !aufgabeZuteilung.kommentarAbgeschlossen;
  const darfSpeichern = ausbildung.bearbeitbar && !aufgabeZuteilung.beurteilungAbgeschlossen;
  const darfAbschliessen = darfSpeichern && aufgabenModus === AufgabenModus.abschliessen;
  const darfAbschlussAufheben = ausbildung.bearbeitbar && aufgabenModus === AufgabenModus.abschliessen && aufgabeZuteilung.kommentarAbgeschlossen && !aufgabeZuteilung.beurteilungAbgeschlossen;
  const meineArbeitsraumEintraege = arbeitsraumListe.filter(a => a.kursplanTeamsId === aufgabeZuteilung.kursplanTeamsId && a.betrifftKlasse === false && a.ausbildner.id === teamTeilnehmer.ausbildner.id && a.bibliothekId === aufgabeZuteilung.bibliothekId);
  const hatUngelesene = meineArbeitsraumEintraege.filter(ae => !ae.gelesen && ae.ausbildner.id === ae.absenderId).length > 0;

  const setzeChatMode = (sichtbar) => {
    setChatMode(sichtbar);
    if (sichtbar && hatUngelesene) {
      arbeitsraumGelesen(meineArbeitsraumEintraege.map(e => e.id), {ausbildnerId: ausbildner.id});
    }
  };

  const speichernClick = (abschliessen = false) => {
    if (darfSpeichern) {
      const zusatzdaten = {};
      switch (aufgabenModus) {
        case AufgabenModus.zuteilen:
          zusatzdaten['von'] = von;
          zusatzdaten['bis'] = bis;
          break;
        case AufgabenModus.abschliessen:
          zusatzdaten['beurteilung'] = beurteilung;
          zusatzdaten['beurteilungAbgeschlossen'] = abschliessen;
          break;
      }
      aufgabeUpdate(aufgabeZuteilung, teamTeilnehmer, zusatzdaten);
      setEditMode(false);
    }
  };

  const loeschenClick = () => {
    if (darfLoeschen) {
      aufgabeLoeschen(aufgabeZuteilung, teamTeilnehmer);
      setEditMode(false);
    }
  };

  const abschlussAufhebenClick = () => {
    if (darfAbschlussAufheben) {
      aufgabeUpdate(aufgabeZuteilung, teamTeilnehmer, { kommentarAbgeschlossen: false });
      setEditMode(false);
    }
  };

  return (
    <div className="aufgabe eintrag-box">
      <div className="titel">
        {aufgabeZuteilung.bibliothek.titel}
        {aufgabeZuteilung.bibliothek.aktiv ||
        <span className="label label-warning">{texte.bibliothekInaktiv}</span>
        }
        {aufgabeZuteilung.bibliothek.publiziert ||
        <span className="label label-warning">{texte.bibliothekNichtPubliziert}</span>
        }
        <span className="pull-right">
          {aufgabeZuteilung.istFreigegeben &&
          <Tooltip content={texte.aufgabenFreigeben.istFreigegeben} className="btn btn-mini btn-warning">
            <i className="icon-share"/>
          </Tooltip>
          }
          <AufgabeLabel aufgabeZuteilung={aufgabeZuteilung}/>
          <button className="btn btn-mini" onClick={() => setEditMode(!editMode)}>
            <IconToggle istBearbeitbar={darfSpeichern} istOffen={editMode}/>
          </button>
        </span>
      </div>
      <div className="zeile">
        {`${texte.datumVon}: ${formatiereDatumZeit(von)}, ${texte.datumBis}: ${formatiereDatumZeit(bis)}`}
      </div>
      {<BibliothekDetail aufgabe={aufgabeZuteilung.bibliothek}/>}
      {editMode &&
      <>
        {aufgabenModus === AufgabenModus.zuteilen &&
        <>
          <div className="edit zeile abgrenzen">
            <label className="kurz">{texte.datumVon}</label>
            <Datepicker datum={von} changeHandler={setVon} mitZeit={true}/>
          </div>
          <div className="edit zeile">
            <label className="kurz">{texte.datumBis}</label>
            <Datepicker datum={bis} changeHandler={setBis} mitZeit={true}/>
          </div>
        </>
        }
        {aufgabenModus === AufgabenModus.abschliessen &&
        <>
          <div className="zeile">
            <label>{texte.kommentar}</label>
            <div className="kommentar">{aufgabeZuteilung.kommentar}</div>
          </div>
          {aufgabeZuteilung.hatDokument &&
          <div className="zeile">
            <span className="datei-upload">
              {texte.file}&nbsp;<a href={`${dokumentPfad}?aufgabeZuteilungId=${aufgabeZuteilung.id}`} target="_blank" className={`ikon ${aufgabeZuteilung.dokumentIcon}`}/>
            </span>
          </div>
          }
          {darfAbschlussAufheben &&
          <div className="zeile text-right">
            <button className="btn btn-danger" onClick={abschlussAufhebenClick}>{texte.abschliessenAufheben}</button>
          </div>
          }
          <div className="zeile abgrenzen">
            <label>{texte.beurteilung}</label>
            {darfSpeichern &&
            <textarea rows="5" value={beurteilung} onChange={(ev) => setBeurteilung(ev.currentTarget.value)}/>
            }
            {darfSpeichern ||
            <div className="kommentar">{beurteilung}</div>
            }
          </div>
          {aufgabeZuteilung.hatDokument && aufgabeZuteilung.dokumentIcon === 'ikon-video' &&
          <div className="zeile">
            <VideoTagging aufgabeZuteilung={aufgabeZuteilung} teamTeilnehmer={teamTeilnehmer}/>
          </div>
          }
        </>
        }
        {(darfSpeichern || darfLoeschen || darfAbschlussAufheben) &&
        <div className="zeile">
          {darfSpeichern &&
          <button className="btn btn-primary" onClick={() => speichernClick(false)}>{texte.speichern}</button>
          }
          <div className="pull-right btn-liste">
            {darfSpeichern && darfAbschliessen &&
            <button className="btn btn-success" onClick={() => speichernClick(true)}>{texte.abschliessen}</button>
            }
            {darfLoeschen &&
            <button className="btn btn-danger" onClick={loeschenClick}>{texte.loeschen}</button>
            }
          </div>
        </div>
        }
      </>
      }
      <div className="zeile abgrenzen">
        <div className="zeile">
          {texte.arbeitsraum.nachrichten}&nbsp;
          <button className={`btn btn-mini ${hatUngelesene ? 'btn-warning' : ''}`} onClick={() => setzeChatMode(!chatMode)}>
            <i className="icon-envelope"/>
            {meineArbeitsraumEintraege.length > 0 &&
            <span className="anzahl">({meineArbeitsraumEintraege.length})</span>
            }
          </button>
        </div>
        {chatMode &&
        <ArbeitsraumListe arbeitsraumListe={meineArbeitsraumEintraege} aufgabeZuteilung={aufgabeZuteilung} teamTeilnehmer={teamTeilnehmer}/>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ausbildung: state.ausbildung,
    arbeitsraumListe: state.arbeitsraumListe,
    dokumentPfad: state.api.update,
    texte: state.i18n.texte
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    arbeitsraumGelesen: (arbeitsraumIdListe, zusatzdaten) => {
      dispatch(arbeitsraumGelesen(arbeitsraumIdListe, zusatzdaten));
    },
    aufgabeUpdate: (aufgabeZuteilung, ausbildner, zusatzdaten) => {
      dispatch(aufgabeZuteilungUpdate(aufgabeZuteilung, ausbildner, zusatzdaten));
    },
    aufgabeLoeschen: (aufgabeZuteilung, ausbildner) => {
      dispatch(aufgabeZuteilungDelete(aufgabeZuteilung, ausbildner));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AufgabeZuteilung);
