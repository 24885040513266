
const VORLAGE_DATEN_EDIT = 'VORLAGE_DATEN_EDIT';

export function vorlageDatenEdit(vorlage) {
  return (dispatch) => {
    dispatch({type: VORLAGE_DATEN_EDIT, vorlage: vorlage});
  };
}

function vorlagenDatenReducer(state = {}, action) {
  switch (action.type) {
    case VORLAGE_DATEN_EDIT:
      return {
        ...state,
        editVorlage: action.vorlage
      };
    default:
      return state;
  }
}

export default vorlagenDatenReducer;
