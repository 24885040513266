import {combineReducers} from 'redux';
import {dummyReducer} from "../../../lib/datenhaltung";
import rollbarReducer from "../../../reducers/rollbar";
import vorlagenReducer from "./modules/vorlagen";
import vorlagenDatenReducer from "./modules/vorlagenDaten";

export default combineReducers({
  vorlagen: vorlagenReducer,
  buchungstypen: dummyReducer,
  daten: vorlagenDatenReducer,
  api: dummyReducer,
  i18n: dummyReducer,
  rollbar: rollbarReducer,
});
