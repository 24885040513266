// @flow

import type {AppState, EinsatzState, SpielState, TeamState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";
import TextfeldZeile from "../TextfeldZeile";
import NoteDropdown from "../NoteDropdown";

import {heimTeamNoteSetzen, heimTeamKommentarSetzen, gastTeamNoteSetzen, gastTeamKommentarSetzen} from "../../state/modules/teams";

const Teamnoten = (props: {
  einsatz: EinsatzState,
  spiel: SpielState,
  teams: TeamState,
  i18n: any,
  heimTeamNoteSetzen: ((string) => void),
  heimTeamKommentarSetzen: ((string) => void),
  gastTeamNoteSetzen: ((string) => void),
  gastTeamKommentarSetzen: ((string) => void)
}) => {
  if (props.teams.heimTeam && props.teams.gastTeam) {
    return (
      <table className="table table-striped">
        <tbody>
        <tr className="control-group">
          <th>
            <label className="control-label" htmlFor="heimTeamNote" style={{width: '170px'}}>
              {props.i18n.felder.heimTeamNote}
            </label>
          </th>
          <td>
            <div className="controls">
              <NoteDropdown feldName="heimTeamNote" feldWert={props.teams.heimTeam.teamNote}
                            requestDaten={{spielId: props.spiel.id, teamId: props.spiel.heimTeamId}} onValueChange={(neuerWert) => {
                props.heimTeamNoteSetzen(neuerWert);
              }}/>
            </div>
          </td>
        </tr>
        <TextfeldZeile feldTitel={props.i18n.felder.heimTeamKommentar} feldName="heimTeamKommentar" feldWert={props.teams.heimTeam.teamKommentar}
                       requestDaten={{spielId: props.spiel.id, teamId: props.spiel.heimTeamId}} onChange={(event) => {
          props.heimTeamKommentarSetzen(event.target.value);
        }}/>
        <tr className="control-group">
          <th>
            <label className="control-label" htmlFor="gastTeamNote" style={{width: '170px'}}>
              {props.i18n.felder.gastTeamNote}
            </label>
          </th>
          <td>
            <div className="controls">
              <NoteDropdown feldName="gastTeamNote" feldWert={props.teams.gastTeam.teamNote}
                            requestDaten={{spielId: props.spiel.id, teamId: props.spiel.gastTeamId}} onValueChange={(neuerWert) => {
                props.gastTeamNoteSetzen(neuerWert);
              }}/>
            </div>
          </td>
        </tr>
        <TextfeldZeile feldTitel={props.i18n.felder.gastTeamKommentar} feldName="gastTeamKommentar" feldWert={props.teams.gastTeam.teamKommentar}
                       requestDaten={{spielId: props.spiel.id, teamId: props.spiel.gastTeamId}} onChange={(event) => {
          props.gastTeamKommentarSetzen(event.target.value);
        }}/>
        </tbody>
      </table>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    spiel: state.spiel,
    teams: state.teams,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    heimTeamNoteSetzen: (neuerWert: string) => {
      dispatch(heimTeamNoteSetzen(neuerWert));
    },
    heimTeamKommentarSetzen: (neuerWert: string) => {
      dispatch(heimTeamKommentarSetzen(neuerWert));
    },
    gastTeamNoteSetzen: (neuerWert: string) => {
      dispatch(gastTeamNoteSetzen(neuerWert));
    },
    gastTeamKommentarSetzen: (neuerWert: string) => {
      dispatch(gastTeamKommentarSetzen(neuerWert));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Teamnoten);
