import {executeRequest} from "../lib/api";
import {ersetzeStringMitDatum, sortierePersonName} from "../lib/formatierungen";
import {sortiereAufgabenZuteilungen} from "./teilnehmerAufgabenReducer";

const TEAM_TEILNEHMER_AUFGABE_EDIT = 'TEAM_TEILNEHMER_AUFGABE_EDIT';
const TEAM_TEILNEHMER_AUFGABE_DELETE = 'TEAM_TEILNEHMER_AUFGABE_DELETE';
const TEAM_TEILNEHMER_VIDEOTAG_EDIT = 'TEAM_TEILNEHMER_VIDEOTAG_EDIT';
const TEAM_TEILNEHMER_VIDEOTAG_DELETE = 'TEAM_TEILNEHMER_VIDEOTAG_DELETE';
const TEAM_TEILNEHMER_UPDATE = 'TEAM_TEILNEHMER_UPDATE';
const TEAM_TEILNEHMER_SPEICHERN = 'TEAM_TEILNEHMER_SPEICHERN';

export function sortiereTeamZuteilungen(a, b) {
  if (a.ausbildner.id === b.ausbildner.id) {
    return a.von - b.von;
  }
  return sortierePersonName(a.ausbildner, b.ausbildner);
}
export function bibliothekReferenzSetzen(objektMitBibliothekReferenz, bibliothek) {
  objektMitBibliothekReferenz.bibliothek = bibliothek.find(b => b.id === objektMitBibliothekReferenz.bibliothekId);
  return objektMitBibliothekReferenz;
}

export function aufgabeZuteilungTeamErstellen(aufgabe, teilnehmerListe) {
  return (dispatch) => {
    teilnehmerListe.forEach(teilnehmer => dispatch(aufgabeZuteilungErstellen(aufgabe, teilnehmer)));
  };
}
export function aufgabeZuteilungErstellen(aufgabe, teilnehmer) {
  return (dispatch, getState) => {
    const state = getState();
    const daten = {
      kontext: 'aufgabe_zuteilen',
      aufgabeId: aufgabe.id,
      teamId: teilnehmer.teamId,
      ausbildnerId: teilnehmer.ausbildner.id,
    };
    return executeRequest(state.api.update, state.api.token, 'PUT', daten, (result) => {
      const az = bibliothekReferenzSetzen(result.data.aufgabeZuteilung, state.bibliothek);
      ersetzeStringMitDatum(az, ['von', 'bis']);
      dispatch({type: TEAM_TEILNEHMER_AUFGABE_EDIT, teilnehmer: teilnehmer, aufgabeZuteilung: az});
    });
  };
}

export function aufgabeZuteilungUpdate(aufgabeZuteilung, teilnehmer, zusatzdaten) {
  return (dispatch, getState) => {
    const state = getState();
    // zusatzdaten keys: von, bis, beurteilung, beurteilungAbgeschlossen
    const daten = {
      ...zusatzdaten,
      kontext: 'aufgabe_update',
      aufgabeZuteilungId: aufgabeZuteilung.id,
      teamId: teilnehmer.teamId,
    };
    return executeRequest(state.api.update, state.api.token, 'PUT', daten, (result) => {
      const az = bibliothekReferenzSetzen(result.data.aufgabeZuteilung, state.bibliothek);
      ersetzeStringMitDatum(az, ['von', 'bis']);
      dispatch({type: TEAM_TEILNEHMER_AUFGABE_EDIT, teilnehmer: teilnehmer, aufgabeZuteilung: az});
    });
  };
}

export function aufgabeZuteilungDelete(aufgabeZuteilung, teilnehmer) {
  return (dispatch, getState) => {
    const api = getState().api;
    const daten = {
      kontext: 'aufgabe_delete',
      aufgabeZuteilungId: aufgabeZuteilung.id,
      teamId: teilnehmer.teamId,
    };
    return executeRequest(api.update, api.token, 'PUT', daten, (result) => {
      dispatch({type: TEAM_TEILNEHMER_AUFGABE_DELETE, teilnehmerId: teilnehmer.id, aufgabeZuteilung: aufgabeZuteilung});
    });
  };
}

export function videoTagEdit(aufgabeZuteilung, teilnehmer, videoTag) {
  return (dispatch) => {
    dispatch({type: TEAM_TEILNEHMER_VIDEOTAG_EDIT, teilnehmerId: teilnehmer.id, aufgabeZuteilungId: aufgabeZuteilung.id, videoTag: {...videoTag}});
  };
}

export function videoTagUpdate(aufgabeZuteilung, teilnehmer, zusatzdaten) {
  return (dispatch, getState) => {
    const state = getState();
    // zusatzdaten keys: sekunden, kommentar, videoTagId
    const daten = {
      ...zusatzdaten,
      kontext: 'video_tag_update',
      aufgabeZuteilungId: aufgabeZuteilung.id,
      teamId: teilnehmer.teamId,
    };
    return executeRequest(state.api.update, state.api.token, 'PUT', daten, (result) => {
      dispatch({type: TEAM_TEILNEHMER_VIDEOTAG_EDIT, teilnehmerId: teilnehmer.id, aufgabeZuteilungId: aufgabeZuteilung.id, videoTag: result.data.videoTag});
    });
  };
}

export function videoTagDelete(aufgabeZuteilung, teilnehmer, videoTag) {
  return (dispatch, getState) => {
    if (videoTag.id <= 0) {
      dispatch({type: TEAM_TEILNEHMER_VIDEOTAG_DELETE, teilnehmerId: teilnehmer.id, aufgabeZuteilungId: aufgabeZuteilung.id, videoTagId: videoTag.id});
    } else {
      const state = getState();
      const daten = {
        kontext: 'video_tag_delete',
        aufgabeZuteilungId: aufgabeZuteilung.id,
        teamId: teilnehmer.teamId,
        videoTagId: videoTag.id
      };
      return executeRequest(state.api.update, state.api.token, 'PUT', daten, (result) => {
        dispatch({type: TEAM_TEILNEHMER_VIDEOTAG_DELETE, teilnehmerId: teilnehmer.id, aufgabeZuteilungId: aufgabeZuteilung.id, videoTagId: videoTag.id});
      });
    }
  };
}

export function teamTeilnehmerUpdate(teamTeilnehmer) {
  return (dispatch) => {
    if (Array.isArray(teamTeilnehmer)) {
      teamTeilnehmer.forEach(tt => dispatch({type: TEAM_TEILNEHMER_UPDATE, teamTeilnehmer: tt}));
    } else {
      dispatch({type: TEAM_TEILNEHMER_UPDATE, teamTeilnehmer: teamTeilnehmer});
    }
  };
}

export function teamTeilnehmerSpeichern(teilnehmer, zusatzdaten) {
  return (dispatch, getState) => {
    const state = getState();
    const daten = { 
      ...zusatzdaten,
      kontext: 'team_teilnehmer_update',
      teamId: teilnehmer.teamId,
      teamZuteilungId: teilnehmer.id,
    };
    return executeRequest(state.api.update, state.api.token, 'PUT', daten, (result) => {
      const teamTeilnehmer = result.data.teamTeilnehmer ? 
        ersetzeStringMitDatum(result.data.teamTeilnehmer, ['von', 'bis', 'abgeschlossenAm']) : 
        {...teilnehmer};
      teamTeilnehmer.aufgaben = teilnehmer.aufgaben;
      dispatch({type: TEAM_TEILNEHMER_UPDATE, teamTeilnehmer: teamTeilnehmer });
    });
  };
}

function teamTeilnehmerReducer(state = [], action) {
  let teamTeilnehmer = null;
  let liste = [];
  let aufgabeZuteilung = null;

  switch (action.type) {
    case TEAM_TEILNEHMER_AUFGABE_EDIT:
      teamTeilnehmer = state.find(tt => tt.id === action.teilnehmer.id);
      liste = [
        ...state.filter(tt => tt.id !== action.teilnehmer.id),
        {
          ...teamTeilnehmer,
          aufgaben: [
            ...teamTeilnehmer.aufgaben.filter((az) => az.id !== action.aufgabeZuteilung.id),
            action.aufgabeZuteilung
          ].sort(sortiereAufgabenZuteilungen)
        }
      ];
      liste.sort(sortiereTeamZuteilungen);
      return liste;
    case TEAM_TEILNEHMER_AUFGABE_DELETE:
      teamTeilnehmer = state.find(tt => tt.id === action.teilnehmerId);
      liste = [
        ...state.filter(tt => tt.id !== action.teilnehmerId),
        {
          ...teamTeilnehmer,
          aufgaben: teamTeilnehmer.aufgaben.filter(a => a.id !== action.aufgabeZuteilung.id)
        }
      ];
      liste.sort(sortiereTeamZuteilungen);
      return liste;
    case TEAM_TEILNEHMER_VIDEOTAG_EDIT:
      teamTeilnehmer = state.find(tt => tt.id === action.teilnehmerId);
      aufgabeZuteilung = teamTeilnehmer.aufgaben.find((az) => az.id === action.aufgabeZuteilungId);
      const aufgabenEdit = [
        ...teamTeilnehmer.aufgaben.filter((az) => az.id !== action.aufgabeZuteilungId),
        {
          ...aufgabeZuteilung,
          videoTags: [
            ...aufgabeZuteilung.videoTags.filter(vt => vt.id !== action.videoTag.id),
            action.videoTag
          ]
        }
      ];
      aufgabenEdit.sort(sortiereAufgabenZuteilungen);
      liste = [
        ...state.filter(tt => tt.id !== action.teilnehmerId),
        {
          ...teamTeilnehmer,
          aufgaben: aufgabenEdit
        }
      ];
      liste.sort(sortiereTeamZuteilungen);
      return liste;
    case TEAM_TEILNEHMER_VIDEOTAG_DELETE:
      teamTeilnehmer = state.find(tt => tt.id === action.teilnehmerId);
      aufgabeZuteilung = teamTeilnehmer.aufgaben.find((az) => az.id === action.aufgabeZuteilungId);
      liste = [
        ...state.filter(tt => tt.id !== action.teilnehmerId),
        {
          ...teamTeilnehmer,
          aufgaben: [
            ...teamTeilnehmer.aufgaben.filter((az) => az.id !== action.aufgabeZuteilungId),
            {
              ...aufgabeZuteilung,
              videoTags: aufgabeZuteilung.videoTags.filter(vt => vt.id !== action.videoTagId)
            }
          ].sort(sortiereAufgabenZuteilungen)
        }
      ];
      liste.sort(sortiereTeamZuteilungen);
      return liste;
    case TEAM_TEILNEHMER_UPDATE:
      teamTeilnehmer = action.teamTeilnehmer;
      liste = [
        ...state.filter(tt => tt.id !== teamTeilnehmer.id),
        {...teamTeilnehmer}
      ];
      liste.sort(sortiereTeamZuteilungen);
      return liste;
    case TEAM_TEILNEHMER_SPEICHERN:
      teamTeilnehmer = state.find(tt => tt.id === action.teamTeilnehmer.id);
      liste = [
        ...state.filter(tt => tt.id !== action.ausbildner.id),
        {
          ...action.teamTeilnehmer,
          aufgaben: teamTeilnehmer.aufgaben,
          ergebnis: action.ergebnisTrainer,
        }
      ];
      liste.sort(sortiereTeamZuteilungen);
      return liste;
    default:
      return state;
  }

}

export default teamTeilnehmerReducer;
