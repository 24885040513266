import React from "react";
import {connect} from "react-redux";

const SyncLabel = ({bexioStatus, texte, spinnerAnzeigen = true}) => {

  // initial ist synchronisiert null
  if (bexioStatus.synchronisiert === null) {
    return <span className="ajax-spinner-klein"/>;
  }

  let text = '';
  let farbe = 'default';
  const istTransfer = bexioStatus.transferiert !== undefined; // benutzt andere texte? (betrifft aktuell nur rechnungen)

  if (bexioStatus.aktiv) {
    text = istTransfer ? texte.transferierenLaeuft : texte.synchronisierenLaeuft;
    farbe = 'warning';
  } else {
    if (bexioStatus.synchronisiert) {
      farbe = 'success';
      if (istTransfer) {
        if (bexioStatus.transferiert < bexioStatus.total) {
          text = texte.transferiertTeilweise;
          farbe = 'warning';
        } else {
          text = texte.transferiertJa;
        }
      } else {
        text = texte.synchronisiertJa;
      }
    } else {
      text = istTransfer ? texte.transferiertNein : texte.synchronisiertNein;
      farbe = 'danger';
    }
  }

  return (
    <>
      <span className={`label label-${farbe}`}>{text}</span>
      {spinnerAnzeigen && bexioStatus.aktiv &&
      <span className="ajax-spinner-klein"/>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bexio: state.bexio,
    texte: state.i18n.texte.bexio,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SyncLabel);

