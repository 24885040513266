import {erstelleZustandFuerSpielerkarte, periodenEndeMinutenErstellen} from "../lib/spielablauf";

function preloader(options) {
  return {
    ajax: { spinnerCount: 0 },
    spiel: {...options.spiel, anpfiff_ab: new Date(options.spiel.anpfiff_ab)},
    heartbeat: options.heartbeat,
    heim: { 
      team: options.heimTeam, 
      spielerkarte: options.heimSpielerkarte,
      spieler: options.heimSpielerkarte.startformation.concat(options.heimSpielerkarte.ersatzformation),
      zustand: erstelleZustandFuerSpielerkarte(options.heimSpielerkarte)
    },
    gast: {
      team: options.gastTeam, 
      spielerkarte: options.gastSpielerkarte,
      spieler: options.gastSpielerkarte.startformation.concat(options.gastSpielerkarte.ersatzformation),
      zustand: erstelleZustandFuerSpielerkarte(options.gastSpielerkarte)
    },
    liga: {
      ...options.liga,
      periodenEndeMinuten: periodenEndeMinutenErstellen(options.liga)
    },
    spielfeld: options.spielfeld,
    spielanzeige: options.spielanzeige,
    spielverlauf: [],
    editMarkierer: { spielverlauf: [], feldspieler: [], staffbank: null, spielzeit: null, tickerAnzeigen: true },
    api: options.api,
    i18n: options.i18n,
    rollbar: options.rollbar,
  };
};

export default preloader;
