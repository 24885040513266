import SpielerBadge from "../SpielerBadge";
import React from "react";
import { connect } from "react-redux";
import type { AppState, SpielerEintrag } from "../../state/stateTypes";
import SpielerReadonlyTips from "./spiel/SpielerReadonlyTips";

const AndereTipsRow = ({ spieler, i18n }: Props) => {
  const hatAndereTips = spieler.andereTips != null && spieler.andereTips.length > 0;

  if (hatAndereTips) {
    return (
      <>
        {spieler.andereTips.map((andererTipp, index) => (
          <tr style={{ height: '30px' }}>
            <React.Fragment key={index}>
              <td className="text-left" style={{ verticalAlign: 'middle', margin: '0', padding: '4px 5px' }}>
                {andererTipp.scout.rolle}
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <SpielerBadge spieler={andererTipp.scout}/>
              </td>
              <td colSpan={2} className="text-right"/>
              <SpielerReadonlyTips tips={andererTipp}/>
            </React.Fragment>
          </tr>
        ))
        }
      </>
    )
  } else {
    return (
      <tr style={{ height: '30px' }}>
        <>
          <td colSpan="4" className="text-right"/>
          <td colSpan="7" className="text-right">
            <p style={{ verticalAlign: 'middle', margin: '0', padding: '4px 5px' }}>
              {i18n.tips.no_tips}
            </p>
          </td>
          <td colSpan="2" className="text-right"/>
        </>
        <td colSpan="4" className="text-right"/>
      </tr>
    );
  }
};

type Props = {
  spieler: SpielerEintrag,
  i18n: any,
}

const mapStateToProps = (state: AppState) => {
  return { i18n: state.i18n, };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AndereTipsRow);
