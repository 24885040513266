import React, {useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../../components/ModalerDialog";
import {spielerUpdate} from "../../state/modules/sponsorenlaufSpieler";

const SpielerBearbeiten = ({spielerliste, texte, spielerUpdate, onModalClose}) => {
  const [pflichtbeitrag, setPflichtbeitrag] = useState('');
  const texteSpieler = texte.spieler
  
  const speichern = () => {
    if (pflichtbeitrag >= 0) {
      spielerUpdate(spielerliste, 'pflichtbeitrag', pflichtbeitrag);
      onModalClose();
    }
  };

  return (
    <ModalerDialog>
      <div className="modal">
        <div className="modal-header">
          <button type="button" className="close" onClick={onModalClose}>&times;</button>
          <h3>{texteSpieler.bearbeiten}</h3>
        </div>
        <div className="modal-body form-horizontal">
          <div className="control-group">
            <label className="control-label" htmlFor="inputPflichtbeitrag">{texteSpieler.pflichtbeitrag}</label>
            <div className="controls">
              <input type="number" id="inputPflichtbeitrag" value={pflichtbeitrag} onChange={ev => setPflichtbeitrag(ev.currentTarget.value)} />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn" onClick={onModalClose}>{texte.abbrechen}</button>
          &nbsp;
          <button type="button" className="btn btn-primary" onClick={speichern}>{texte.speichern}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    spielerUpdate: (spielerId, key, value) => { dispatch(spielerUpdate(spielerId, key, value)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpielerBearbeiten);
