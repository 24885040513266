import {sortiereLobby} from "../lib/helpers";

function preloader(options) {
  return {
    person: options.person,
    rooms: options.rooms,
    settings: {
      ...options.settings,
      lobby: options.settings.lobby.sort(sortiereLobby),
      lobbyOffen: false,
      supportAnwesend: options.settings.lobby.length > 0,
    },
    api: options.api,
    i18n: options.i18n,
    rollbar: options.rollbar
  }
}

export default preloader;
