// @flow

import type {AppState, EinsatzState, NavigationState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from 'react-redux';

import Spiel from '../Spiel'
import Turnier from '../Turnier'
import Training from '../Training'
import Spielerliste from '../Spielerliste';
import Spielprotokoll from '../Spielprotokoll';
import Noten from '../Noten';
import EinsatzInfos from "../EinsatzInfos";
import Abschluss from '../Abschluss';
import Aufstellung from "../Aufstellung";
import Kontakt from "../Kontakt";

const ContentRouter = (props: { einsatz: EinsatzState, navigation: NavigationState }) => {
  switch (props.navigation.aktiverTab) {
    case 'spiel':
      return <Spiel/>;
    case 'turnier':
      return <Turnier/>;
    case 'training':
      return <Training/>;
    case 'aufstellung':
      return <Aufstellung/>;
    case 'spielerliste':
      return <Spielerliste/>;
    case 'spielprotokoll':
      return <Spielprotokoll/>;
    case 'noten':
      return <Noten/>;
    case 'einsatzInfos':
      return <EinsatzInfos/>;
    case 'kontakt':
      return <Kontakt/>;
    case 'abschluss':
      return <Abschluss/>;
    default:
      return <div>Oups</div>
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    navigation: state.navigation,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentRouter);
