import React from "react";
import {connect} from "react-redux";
import Daten from "./Daten";
import BexioSynchronisation from "./BexioSynchronisation";
import Rechnungen from "./Rechnungen";

const Ansicht = ({rechnungslauf, bexio, rechnungen, rechnungenVorschau}) => {
  return (
    <>
      <Daten/>
      {bexio.aktiv === true && rechnungslauf.id !== null &&
      <BexioSynchronisation/>
      }
      {rechnungenVorschau.length > 0 &&
      <Rechnungen rechnungen={rechnungenVorschau} istVorschau={true}/>
      }
      {rechnungen.length > 0 &&
      <Rechnungen rechnungen={rechnungen} istVorschau={false}/>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    rechnungslauf: state.rechnungslauf,
    bexio: state.bexio,
    rechnungen: state.rechnungen,
    rechnungenVorschau: state.rechnungenVorschau,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Ansicht);
