import React from "react";
import {connect} from "react-redux";
import AbbrechnungKlassenlehrer from "./AbbrechnungKlassenlehrer";

const Abbrechnung = ({klassenlehrer}) => {
  return (
    <>
      {klassenlehrer.map(lehrer => <AbbrechnungKlassenlehrer key={lehrer.id} klassenlehrer={lehrer} />)}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    klassenlehrer: state.klassenlehrer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Abbrechnung);
