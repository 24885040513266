const SPONSORENLAUF_SPONSOREN_EDIT = 'SPONSORENLAUF_SPONSOREN_EDIT';

export function sponsorenSortieren(a, b) {
  let result = a.name.localeCompare(b.name);
  if (result === 0) {
    result = b.id - a.id;
  }
  return result;
}

export function sponsorenEdit(sponsoren) {
  return (dispatch) => {
    dispatch({ type: SPONSORENLAUF_SPONSOREN_EDIT, sponsoren: sponsoren });
  }
}

function sponsorenlaufSponsorenReducer(state = [], action) {
  switch (action.type) {
    case SPONSORENLAUF_SPONSOREN_EDIT:
      const sponsorenIds = action.sponsoren.map(s => s.id);
      const liste = state
        .filter(s => sponsorenIds.includes(s.id) === false)
        .concat(action.sponsoren);
      return liste.sort(sponsorenSortieren);
    default:
      return state;
  }
}

export default sponsorenlaufSponsorenReducer;
