import React from "react";
import useGlobal from "../../store";

const TeamInfos = () => {
  const [globalState] = useGlobal();

  return (
    <div className="teams flex-row">
      <div className="flex-1 team">
        <div className="img">
          <img src={globalState.spielInfos.heimTeam.logo} alt={globalState.spielInfos.heimTeam.name} />
        </div>
        <div>{globalState.spielInfos.heimTeam.name}</div>
      </div>
      <div className="vs">{globalState.i18n.vs}</div>
      <div className="flex-1 team">
        <div className="img">
          <img src={globalState.spielInfos.gastTeam.logo} alt={globalState.spielInfos.gastTeam.name} />
        </div>
        <div>{globalState.spielInfos.gastTeam.name}</div>
      </div>
    </div>
  );
};

export default TeamInfos;
