import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {vorlageErstellen, vorlageTextDelete, vorlageUpdate} from "../../state/modules/vorlagen";
import {vorlageDatenEdit} from "../../state/modules/vorlagenDaten";
import TextEdit from "./TextEdit";
import TextItem from "./TextItem";
import ConfirmButton from "../../../../components/ConfirmButton";

const Form = ({vorlage, buchungstypen, i18n, vorlageErstellen, vorlageUpdate, vorlageDatenEdit, vorlageTextDelete}) => {
  const [inaktiv, setInaktiv] = useState(vorlage.inaktiv || false);
  const [bezeichnung, setBezeichnung] = useState(vorlage.bezeichnung || '');
  const [buchungstyp, setBuchungstyp] = useState(vorlage.buchungstyp || {});
  const [vorlageTexte, setVorlageTexte] = useState(vorlage.texte ? [...vorlage.texte] : []);
  const [editText, setEditText] = useState(null);
  const texte = i18n.texte;
  const istVorlageGespeichert = vorlage.id > 0;
  const istSpeicherbar = !istVorlageGespeichert || vorlage.bezeichnung != bezeichnung || vorlage.buchungstyp.id != buchungstyp.id || vorlage.inaktiv != inaktiv;

  useEffect(() => {
    // texte nach speichern in db neu laden -> id's sind nun vorhanden
    setVorlageTexte(vorlage.texte);
  }, [vorlage]);

  const speichern = (vt) => {
    const texteArray = vt || vorlageTexte;
    if (istVorlageGespeichert) {
      vorlageUpdate(vorlage.id, inaktiv, bezeichnung, buchungstyp.id, texteArray);
    } else {
      vorlageErstellen(inaktiv, bezeichnung, buchungstyp.id, texteArray);
    }
  };

  const speichernClick = (ev) => {
    ev.currentTarget.blur();
    speichern(vorlageTexte);
  };

  const abbrechenClick = () => {
    vorlageDatenEdit(null);
  };

  const neuerTextClick = (spracheId) => {
    const reihenfolge = Math.max(...vorlage.texte.map(t => t.reihenfolge)) + 1;
    const neuerText = {spracheId, reihenfolge, header: '', footer: '', freiwilligZusatz: '', freiwilligHeader: '', freiwilligFooter: ''};
    setVorlageTexte([...vorlageTexte, neuerText]);
    setEditText(neuerText);
  };

  const deleteText = (text) => {
    if (text.id > 0) {
      vorlageTextDelete(vorlage.id, text);
    } else {
      // ist nur in state vorhanden -> da entfernen
      setVorlageTexte(vorlageTexte.filter(t => t !== text));
    }
  };

  const textChange = (text) => {
    const list = vorlageTexte.filter(t => t.spracheId !== text.spracheId);
    list.push(text);
    list.sort((a, b) => a.reihenfolge - b.reihenfolge);
    setVorlageTexte(list);
    if (istVorlageGespeichert) {
      speichern(list);
    }
  };
  
  const sortierungChange = (dragIndex, hoverIndex) => {
    const dragText = vorlageTexte[dragIndex];
    const arr = [...vorlageTexte];
    arr.splice(dragIndex, 1);
    arr.splice(hoverIndex, 0, dragText);
    arr.forEach((t, i) => t.reihenfolge = i+1); // reihenfolge neu setzen
    setVorlageTexte(arr);
  };

  const renderFehlendeTextButtons = () => {
    const vorhandenSprachen = vorlageTexte.map(t => t.spracheId);
    const fehlendeSprachen = i18n.sprachen.filter(s => !vorhandenSprachen.includes(s.id));

    if (fehlendeSprachen.length === 0) {
      return <></>;
    }

    return (
      <div className="button-liste">
        {texte.neuerTextInSprache}:&nbsp;
        {fehlendeSprachen.map(s => <button key={s.id} type="button" className="btn" onClick={() => neuerTextClick(s.id)}>{s.bezeichnung}</button>)}
      </div>
    );
  };

  return (
    <>
      <h2 className="page-header">
        {texte.vorlageBearbeiten}
      </h2>

      <table className="attributes table">
        <tbody>
        <tr>
          <th><label>{texte.inaktiv}</label></th>
          <td><input type="checkbox" checked={inaktiv} onChange={ev => setInaktiv(ev.currentTarget.checked)}/></td>
        </tr>
        <tr>
          <th><label>{texte.bezeichnung}</label></th>
          <td>
            <input type="text" value={bezeichnung} onChange={ev => setBezeichnung(ev.currentTarget.value)}/>
          </td>
        </tr>
        <tr>
          <th><label>{texte.buchungstyp}</label></th>
          <td>
            <select value={buchungstyp.id} onChange={ev => setBuchungstyp(buchungstypen.find(b => b.id == ev.currentTarget.value) || {})}>
              <option value=""></option>
              {buchungstypen.map(bt => <option key={bt.id} value={bt.id}>{bt.bezeichnung}</option>)}
            </select>
          </td>
        </tr>
        {vorlage.errorMessages &&
        <tr>
          <td colSpan={2}>
            {vorlage.errorMessages.map((fehler, index) => <div key={index} className="alert alert-danger">{fehler}</div>)}
          </td>
        </tr>
        }
        </tbody>
      </table>
      <div className="form-actions button-liste">
        <button type="button" disabled={!istSpeicherbar} className="btn btn-primary" onClick={speichernClick}>{texte.speichern}</button>
        {istVorlageGespeichert ||
        <button type="button" className="btn" onClick={abbrechenClick}>{texte.abbrechen}</button>
        }
      </div>

      {renderFehlendeTextButtons()}

      <table className="table table-condensed">
        <thead>
        <tr>
          <th></th>
          <th>{texte.sprache}</th>
          <th>{texte.header}</th>
          <th>{texte.footer}</th>
          <th>{texte.freiwilligZusatz}</th>
          <th>{texte.freiwilligHeader}</th>
          <th>{texte.freiwilligFooter}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {vorlageTexte.map((t, index) => <TextItem key={t.spracheId} vorlageText={t} index={index} onEditText={setEditText} onDeleteText={deleteText} sortierungChange={sortierungChange} sortierungSave={speichern}/>)}
        </tbody>
      </table>

      {editText !== null &&
      <TextEdit text={editText} onChange={t => textChange(t)} onClose={() => setEditText(null)}/>
      }

      <div className="form-actions button-liste">
        {istSpeicherbar ? (
          <ConfirmButton type="button" className="btn" confirmText={texte.ungespeicherteDaten} onConfirmed={abbrechenClick} okText={texte.ok} cancelText={texte.abbrechen} buttonText={texte.zurueck}/>
        ) : (
          <button type="button" className="btn" onClick={abbrechenClick}>{texte.zurueck}</button>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    buchungstypen: state.buchungstypen,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    vorlageErstellen: (inaktiv, bezeichnung, buchungstypId, texte) => {
      dispatch(vorlageErstellen(inaktiv, bezeichnung, buchungstypId, texte));
    },
    vorlageUpdate: (vorlageId, inaktiv, bezeichnung, buchungstypId, texte) => {
      dispatch(vorlageUpdate(vorlageId, inaktiv, bezeichnung, buchungstypId, texte));
    },
    vorlageTextDelete: (vorlageId, text) => {
      dispatch(vorlageTextDelete(vorlageId, text));
    },
    vorlageDatenEdit: (vorlage) => {
      dispatch(vorlageDatenEdit(vorlage));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);

