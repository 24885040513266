import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../components/ModalerDialog";
import {ABFRAGE_BERICHT_ABSCHLUSS, berechneTotal, berichtAbschliessen} from "../state/berichtReducer";

const checkWerte = [
  {feldname: 'sSpielgewichtung',          check: 'presence', fehlertext: 'spielgewichtung_fehlt'},
  {feldname: 'gesamtbeurteilung',         check: 'presence', fehlertext: 'gesamtbeurteilung_fehlt'},
  {feldname: 'kommission',                check: 'presence', fehlertext: 'kommission_fehlt'},
  {feldname: 'sEinsatzmoeglichkeit',      check: 'presence', fehlertext: 'einsatzmoeglichkeit_fehlt'},
  {feldname: 'kernkompetenzen_kommentar', check: 'coachingPoint', coachingPoint: 'kernkompetenzen', fehlertext: 'kernkompetenzen_kommentar_fehlt'},
  {feldname: 'persoenlichkeit_kommentar', check: 'coachingPoint', coachingPoint: 'persoenlichkeit', fehlertext: 'persoenlichkeit_kommentar_fehlt'},
  {feldname: 'persoenlichkeit_kommentar', check: 'coachingPoint', coachingPoint: 'persoenlichkeit_sra', fehlertext: 'persoenlichkeit_sra_kommentar_fehlt'},
  {feldname: 'regel_kommentar',           check: 'coachingPoint', coachingPoint: 'regel', fehlertext: 'regel_kommentar_fehlt'},
  {feldname: 'fitness_kommentar',         check: 'coachingPoint', coachingPoint: 'fitness', fehlertext: 'fitness_kommentar_fehlt'},
  {feldname: 'fitness_kommentar',         check: 'coachingPoint', coachingPoint: 'fitness_sra', fehlertext: 'fitness_sra_kommentar_fehlt'},
  {feldname: 'punkte_positiv',            check: 'feature', feature: 'punkte_positiv', fehlertext: 'punkte_positiv_fehlt'},
  {feldname: 'punkte_verbesserung',       check: 'feature', feature: 'punkte_verbesserung', fehlertext: 'punkte_verbesserung_fehlt'},
  {feldname: 'sVerhaltenDebriefing',      check: 'feature', feature: 'verhalten_debriefing', fehlertext: 'verhalten_debriefung_fehlt'},
  {feldname: 'sAkzeptanzSRTeam',          check: 'feature', feature: 'akzeptanz_sr_team', fehlertext: 'akzeptanz_sr_team_fehlt'},
  {feldname: 'hat_spesen',                check: 'spesen', fehlertext: 'spesen_fehlt'},
];

const Abschluss = ({bericht, hatSpesen, features, coaching_points, berichtAbschliessen, texte, laufendeAbfragen}) => {
  const [showModal, setShowModal] = useState(false);

  const resultatBerichtPruefung = useMemo(() => {
    const fehler = [];

    checkWerte.forEach(check => {
      let hatWertNichtGesetzt = !bericht[check.feldname];
      let hatFehler = false;
      
      switch (check.check) {
        case 'presence':
          hatFehler = hatWertNichtGesetzt;
          break;
        case 'feature':
          hatFehler = features[check.feature] && hatWertNichtGesetzt;
          break;
        case 'coachingPoint':
          hatFehler = coaching_points[check.coachingPoint] && hatWertNichtGesetzt;
          break;
        case 'spesen':
          hatFehler = hatSpesen && berechneTotal(bericht) == 0;
          break;
      }

      if (hatFehler) {
        fehler.push(texte[check.fehlertext]);
      }
    });

    return fehler;

  }, [bericht]);

  const abschliessenClick = () => {
    setShowModal(false);
    berichtAbschliessen(bericht);
  };

  // nur prüffehler anzeigen wenn vorhanden
  if (resultatBerichtPruefung.length > 0) {
    return (
      <div className="alert alert-error"><h4>{texte.fehler_title}</h4>
        <ul>
          {resultatBerichtPruefung.map((fehler, index) => <li key={index}>{fehler}</li>)}
        </ul>
      </div>
    );
  }

  return (
    <>
      <button type="button" className="btn btn-primary" disabled={bericht.bBerichtDefinitiv || laufendeAbfragen[ABFRAGE_BERICHT_ABSCHLUSS]} onClick={() => setShowModal(true)}>
        {texte.abschliessen}
        {laufendeAbfragen[ABFRAGE_BERICHT_ABSCHLUSS] === true &&
        <>
          &nbsp;
          <span className="ajax-spinner-klein inverted"/>
        </>
        }
      </button>
      {showModal &&
        <ModalerDialog>
          <div className="modal">
            <div className="modal-header">
              <button type="button" className="close" onClick={() => setShowModal(false)}>&times;</button>
              <h3>{texte.abschliessen}</h3>
            </div>
            <div className="modal-body">{texte.wirklich_abschliessen}</div>
            <div className="modal-footer">
              <button type="button" className="btn" onClick={() => setShowModal(false)}>{texte.abbrechen}</button>
              <button type="button" className="btn btn-primary" onClick={abschliessenClick}>{texte.abschliessen}</button>
            </div>
          </div>
        </ModalerDialog>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bericht: state.bericht,
    hatSpesen: state.hatSpesen,
    features: state.listen.features,
    coaching_points: state.listen.coaching_points,
    texte: state.i18n.texte.abschluss,
    laufendeAbfragen: state.laufendeAbfragen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    berichtAbschliessen: (bericht) => {
      dispatch(berichtAbschliessen(bericht));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Abschluss);

