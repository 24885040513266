import React from "react";
import {Provider} from "react-redux"
import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";
import preloaderFuerFinaltag from "./state/preloaderFuerFinaltag";
import reducerFuerFinaltag from "./state/reducerFuerFinaltag";
import ErrorBoundary from "../../components/ErrorBoundary";
import AnsichtFinaltag from "./components/AnsichtFinaltag";
import FavoritenWatch from "./components/FavoritenWatch";
import SpielMeldungenModal from "./components/SpielMeldungenModal";
import DisclaimerHinweis from "./components/DisclaimerHinweis";
import DisclaimerModal from "./components/DisclaimerModal";

const Finaltag = (props) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducerFuerFinaltag, preloaderFuerFinaltag(props), composeEnhancers(applyMiddleware(thunk)));

  return (
    <Provider store={store}>
      <ErrorBoundary fehlermeldung={props.i18n.allgemeinerFehler}>
        <AnsichtFinaltag/>
        <FavoritenWatch/>
        <SpielMeldungenModal/>
        <DisclaimerHinweis/>
        <DisclaimerModal/>
      </ErrorBoundary>
    </Provider>
  );
};

export default Finaltag
