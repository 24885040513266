import {ersetzeStringMitDezimalzahl} from "../../../lib/datenhaltung";

function preloader(options) {
  return {
    bericht: ersetzeStringMitDezimalzahl(options.bericht, ['smnEntschädigung', 'smnReisespesen', 'smnZuschlag']),
    hatSpesen: options.hatSpesen,
    listen: options.listen,
    api: options.api,
    i18n: options.i18n,
    fehler: {},
    laufendeAbfragen: {},
    rollbar: options.rollbar,
  };
}

export default preloader;
