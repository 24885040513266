import React, {useRef, useState} from "react";
import {connect} from "react-redux";
import {videoTagEdit} from "../../state/teamTeilnehmerReducer";
import ModalerDialog from "../../../../components/ModalerDialog";
import {ViewKontext} from "../../lib/constants";
import VideoTag from "./VideoTag";

const VideoTagging = ({aufgabeZuteilung, teamTeilnehmer, teamTeilnehmerListe, ausbildung, dokumentPfad, texte, videoTagEdit}) => {
  const videoRef = useRef(undefined);
  const [showVideo, setShowVideo] = useState(false);
  const [videoWidth, setVideoWidth] = useState('auto');
  const [videoHeight, setVideoHeight] = useState('auto');
  const bearbeitbar = ausbildung.bearbeitbar && ausbildung.kontext === ViewKontext.Klassenlehrer;
  // aufgabe via teilnehmerliste frisch holen. ohne diesen umweg wurde state-update hier nicht bemerkt..?
  const aufgabe = bearbeitbar ? teamTeilnehmerListe.find(tt => tt.id === teamTeilnehmer.id).aufgaben.find(a => a.id === aufgabeZuteilung.id) : aufgabeZuteilung;
  const sortierteTags = aufgabe.videoTags.sort((a, b) => a.sekunden - b.sekunden);

  const neuerEintrag = () => {
    videoRef.current.pause();
    const videoTag = {
      id: -1 * aufgabe.videoTags.length,
      sekunden: Math.floor(videoRef.current.currentTime),
      kommentar: '',
      editMode: true
    };
    videoTagEdit(aufgabe, teamTeilnehmer, videoTag);
  };

  const seek = (sekunden) => {
    videoRef.current.currentTime = sekunden;
    videoRef.current.play();
  };

  const videoReady = () => {
    if (videoRef.current.videoWidth >= videoRef.current.videoHeight) {
      setVideoWidth('100%');
    } else {
      setVideoHeight('100%');
    }
  };
  
  return (
    <>
      <div>
        <button className="btn" onClick={() => setShowVideo(!showVideo)}>{texte.videoTagging.anzeigen}</button>
      </div>
      {showVideo &&
      <ModalerDialog>
        <div className="block" style={{width: '80%', maxHeight: '90%', overflow: 'auto'}}>
          <div className="header">
            <b>{texte.videoTagging.titel}</b>
            <button className="btn btn-small pull-right" onClick={() => setShowVideo(false)}>
              <i className="icon-remove"/>
            </button>
          </div>
          <div className="content">
            <div className="video-tagging">
              <div className="video-container">
                <video ref={videoRef} onCanPlay={videoReady} width={videoWidth} height={videoHeight} controls="controls">
                  <source src={`${dokumentPfad}?aufgabeZuteilungId=${aufgabe.id}`} type="video/mp4"/>
                  {texte.browserUnterstuetztNichtHtml5Video}
                </video>
              </div>
              <div className="video-tags">
                <div className="block">
                  {sortierteTags.map((vt) => <VideoTag key={vt.id} aufgabeZuteilung={aufgabe} teamTeilnehmer={teamTeilnehmer} videoTag={vt} seek={seek} /> )}
                </div>
                <div className="footer">
                  {bearbeitbar &&
                  <button className="btn btn-primary" onClick={neuerEintrag}>{texte.videoTagging.neuerKommentar}</button>
                  }
                  <button className="btn pull-right" onClick={() => setShowVideo(false)}>{texte.videoTagging.schliessen}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalerDialog>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    teamTeilnehmerListe: state.teamTeilnehmer,
    ausbildung: state.ausbildung,
    dokumentPfad: state.api.update,
    texte: state.i18n.texte
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    videoTagEdit: (aufgabeZuteilung, ausbildner, videoTag) => { dispatch(videoTagEdit(aufgabeZuteilung, ausbildner, videoTag)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoTagging);
