import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../components/ModalerDialog";
import {executeRequest} from "../lib/fetchHelper";

const CsvExportModal = ({leistungenIds = [], onHide, rechnungslaufId, texte, csvExportUrl, apiToken}) => {
  const [csvDaten, setCsvDaten] = useState([]);
  
  useEffect(() => {
    executeRequest(csvExportUrl, apiToken, 'GET', {rechnungslaufId: rechnungslaufId, leistungenIds: leistungenIds}).
    then(result => {
      if (result.ok) {
        setCsvDaten(result.data.csv);
      }
    });
  }, []);
  
  const schliessen = () => {
    onHide();
  };

  return <ModalerDialog>
    <div className="modal modal-wide">
      <div className="modal-header">
        <button type="button" className="close" onClick={schliessen}>&times;</button>
        <h3>{texte.titel}</h3>
      </div>
      <div className="modal-body" style={{overflow: 'visible'}}>
          <pre style={{width: '100%', height: '150px', overflow: 'auto', boxSizing: 'border-box', whiteSpace: 'nowrap'}}>
            {csvDaten.map((zeile, index) => <div key={index}>{zeile.join(', ')}</div>)}
          </pre>
      </div>
      <div className="modal-footer">
        <form action={csvExportUrl} method='post'>
          <input type="hidden" name="rechnungslaufId" value={rechnungslaufId} />
          {leistungenIds.map(id => <input key={id} type="hidden" name="leistungenIds[]" value={id} />)}
          <input type="hidden" name="authenticity_token" value={apiToken} />
          <button type="submit" className="btn btn-success">{texte.exportieren}</button>
          <button type="button" className="btn" onClick={schliessen}>{texte.schliessen}</button>
        </form>
      </div>
    </div>
  </ModalerDialog>;
};

const mapStateToProps = (state) => {
  return {
    rechnungslaufId: state.rechnungslauf.id,
    texte: state.i18n.texte.csvExport,
    csvExportUrl: state.api.csvExport,
    apiToken: state.api.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CsvExportModal);
