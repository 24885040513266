// @flow

import type {AppState, EinsatzState, SpielState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import TextfeldZeile from "../TextfeldZeile";

import {gesamtKommentarSetzen, einsatzBemerkungenSetzen} from "../../state/modules/einsatz";
import {spielKommentarSetzen, generellesSpielniveauSetzen, intensitaetSpielrhythmusSetzen} from "../../state/modules/spiel";
import NoteDropdown from "../NoteDropdown";

const Gesamtnoten = (props: {
  einsatz: EinsatzState,
  spiel: SpielState,
  i18n: any,
  spielKommentarSetzen: ((string) => void),
  generellesSpielniveauSetzen: ((number) => void),
  intensitaetSpielrhythmusSetzen: ((number) => void),
  gesamtKommentarSetzen: ((string) => void),
  einsatzBemerkungenSetzen: ((string) => void),
}) => {

  return (
    <table className="table table-striped">
      <tbody>
      <tr className="control-group">
        <th>
          <label className="control-label" htmlFor={props.spiel.generellesSpielniveau} style={{width: '170px'}}>{props.i18n.felder.generellesSpielniveau}</label>
        </th>
        <td>
          <div className="controls">
            <NoteDropdown forceReadonly={props.einsatz.readonly} feldName="generellesSpielniveau" feldWert={props.spiel.generellesSpielniveau} onValueChange={neuerWert => props.generellesSpielniveauSetzen(neuerWert)}
            />
          </div>
        </td>
      </tr>
      <tr className="control-group">
        <th>
          <label className="control-label" htmlFor={props.spiel.intensitaetSpielrhythmus} style={{width: '170px'}}>{props.i18n.felder.intensitaetSpielrhythmus}</label>
        </th>
        <td>
          <div className="controls">
            <NoteDropdown forceReadonly={props.einsatz.readonly} feldName="intensitaetSpielrhythmus" feldWert={props.spiel.intensitaetSpielrhythmus} onValueChange={neuerWert => props.intensitaetSpielrhythmusSetzen(neuerWert)}
            />
          </div>
        </td>
      </tr>
      <TextfeldZeile feldTitel={props.i18n.felder.gesamtKommentar}
                     feldName="gesamtKommentar"
                     feldWert={props.einsatz.gesamtKommentar}
                     onChange={(event) => {
                       props.gesamtKommentarSetzen(event.target.value);
                     }}/>
      <TextfeldZeile feldTitel={props.i18n.felder.einsatzBemerkungen}
                     feldName="einsatzBemerkungen"
                     feldWert={props.einsatz.einsatzBemerkungen}
                     onChange={(event) => {
                       props.einsatzBemerkungenSetzen(event.target.value);
                     }}/>
      <TextfeldZeile feldTitel={props.i18n.felder.spielKommentar}
                     feldName="spielKommentar"
                     feldWert={props.spiel.spielKommentar}
                     requestDaten={{spielId: props.spiel.id}}
                     onChange={(event) => {
                       props.spielKommentarSetzen(event.target.value);
                     }}/>
      </tbody>
    </table>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    spiel: state.spiel,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    spielKommentarSetzen: (neuerWert: string) => {
      dispatch(spielKommentarSetzen(neuerWert));
    },
    generellesSpielniveauSetzen: (neuerWert: number) => {
      dispatch(generellesSpielniveauSetzen(neuerWert));
    },
    intensitaetSpielrhythmusSetzen: (neuerWert: number) => {
      dispatch(intensitaetSpielrhythmusSetzen(neuerWert));
    },
    gesamtKommentarSetzen: (neuerWert: string) => {
      dispatch(gesamtKommentarSetzen(neuerWert));
    },
    einsatzBemerkungenSetzen: (neuerWert: string) => {
      dispatch(einsatzBemerkungenSetzen(neuerWert));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Gesamtnoten);
