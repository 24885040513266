import React, {useState} from 'react';
import {connect} from "react-redux";
import AufgabeBearbeiten from "./AufgabeBearbeiten";
import PersonIcon from "../../../../components/PersonIcon";
import ArbeitsraumEintrag from "../ArbeitsraumEintrag";
import {formatiereDatumZeit} from "../../lib/formatierungen";

const AufgabenListe = ({klasse, aufgaben, arbeitsraumListe, texte}) => {
  const [showAlleKlassenEintraege, setShowAlleKlassenEintraege] = useState(false);
  const [showTeilnehmerliste, setShowTeilnehmerliste] = useState(false);
  const jetzt = new Date();
  const meineAufgaben = aufgaben.filter(a => a.kursplanTeamsId === klasse.id && (a.von <= jetzt || klasse.von <= jetzt));
  let meineKlasseArbeitsraumEintraege = arbeitsraumListe.filter(a => a.betrifftKlasse && a.kursplanTeamsId === klasse.id);
  const anzahlKlasseEintraege = meineKlasseArbeitsraumEintraege.length;

  if (!showAlleKlassenEintraege && meineKlasseArbeitsraumEintraege.length > 0) {
    meineKlasseArbeitsraumEintraege = [meineKlasseArbeitsraumEintraege[0]];
  }

  return (
    <div className="block">
      <div className="header flex">
        <div className="spalte">
          <b>{klasse.bezeichnung}</b>
          &nbsp;<span className="label label-warning">{klasse.programmBlock.name}</span>
          <br/>
          {`${texte.experte}: ${klasse.experte.name}`}
          <PersonIcon person={klasse.experte}/>
        </div>
        <div className="spalte">
          <button className="btn btn-mini pull-right" onClick={() => setShowTeilnehmerliste(!showTeilnehmerliste)}>{texte.teilnehmerListe}</button>
          {`${texte.datumVon}: ${formatiereDatumZeit(klasse.von)}`}
          <br/>
          {`${texte.datumBis}: ${formatiereDatumZeit(klasse.bis)}`}
        </div>
      </div>
      {showTeilnehmerliste &&
      <div className="content">
        {klasse.teilnehmerListe.map((teilnehmerPerson) => <div key={teilnehmerPerson.id} className="eintrag-box">{teilnehmerPerson.name}<PersonIcon person={teilnehmerPerson}/></div>)}
      </div>
      }
      {(meineKlasseArbeitsraumEintraege.length > 0 || meineAufgaben.length > 0) &&
      <div className="content expand-width">
        {meineKlasseArbeitsraumEintraege.length > 0 &&
        <div className="block arbeitsraum-klasse">
          <div className="header">
            {texte.arbeitsraum.arbeitsraumKlasse}
            <button className="btn btn-mini pull-right" onClick={() => setShowAlleKlassenEintraege(!showAlleKlassenEintraege)}>
              <i className="icon-envelope"/>
              {anzahlKlasseEintraege > 0 &&
              <span className="anzahl">({anzahlKlasseEintraege})</span>
              }
            </button>
          </div>
          <div className="content">
            {meineKlasseArbeitsraumEintraege.map(a => <ArbeitsraumEintrag key={a.id} eintrag={a}/>)}
          </div>
        </div>
        }
        {meineAufgaben.length > 0 &&
        <div className="block">
          <div className="header">{texte.aufgabenListe}</div>
          <div className="content expand-width">
            {meineAufgaben.map(a => <AufgabeBearbeiten key={a.id} aufgabeZuteilung={a}/>)}
            {meineAufgaben.length === 0 &&
            <div className="alert alert-info">{texte.keineAufgabenZugeteilt}</div>
            }
          </div>
        </div>
        }
      </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    aufgaben: state.aufgaben,
    arbeitsraumListe: state.arbeitsraumListe,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AufgabenListe);
