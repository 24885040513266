import Rollbar from 'rollbar'

const ROLLBAR_INIT = 'ROLLBAR_INIT';

export function rollbarInitialisieren() {
  return (dispatch, getState) => {
    const rollbar = new Rollbar(getState().rollbar.config);
    dispatch({ type: ROLLBAR_INIT, instance: rollbar});
  };
}

function rollbarReducer(state = {}, action) {
  switch (action.type) {
    case ROLLBAR_INIT:
      return {
        ...state,
        instance: action.instance,
      };
    default:
      return state;
  }
}

export default rollbarReducer;
