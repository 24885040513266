import React from "react";
import TeamLabel from "./TeamLabel";

const Rangliste = ({rangliste, useFavorit = true}) => {

  const renderRanglisteZeile = (zeile) => {
    const team = {id: zeile.teamId, bezeichnung: zeile.teamname};
    return (
      <tr key={zeile.rang}>
        <td>{zeile.rang}.</td>
        <td>
          {useFavorit &&
          <TeamLabel team={team} leerAnzeigen={true} iconWhite={true}/>
          }
          {useFavorit || team.bezeichnung}
        </td>
        <td className="text-center">{zeile.anzahlSpiele}</td>
        <td className="text-center">{zeile.siege}</td>
        <td className="text-center">{zeile.unentschieden}</td>
        <td className="text-center">{zeile.niederlagen}</td>
        <td className="text-center">
          <div className="torverhaeltnis">
            <div className="text-right">{zeile.erzielteTore}</div>
            <div className="text-center">:</div>
            <div className="text-left">{zeile.erhalteneTore}</div>
          </div>
        </td>
        <td className="text-center"><b>{zeile.anzahlPunkte}</b></td>
      </tr>
    );
  };

  return (
    <div className="rangliste">
      <table className="table">
        <colgroup>
          <col width="20"/>
          <col style={{minWidth: 140}}/>
          <col/>
          <col/>
          <col/>
          <col/>
          <col width="60"/>
          <col/>
        </colgroup>
        <tbody>
        {rangliste.map(zeile => renderRanglisteZeile(zeile))}
        </tbody>
      </table>
    </div>
  );
};

export default Rangliste;
