import {executeRequest} from "../../lib/api";
import {ereignisseAbfragen} from "./spielverlauf";
import {laufendeSpielzeitInitialisieren, spielanzeigeToreSetzen} from "./spielanzeige";
import {spielUpdate} from "./spiel";
import {ereignisEditClear} from "./editMarkierer";
import {PERIODE} from "../../lib/spielablauf";

const HEARTBEAT_ABFRAGEN = 'HEARTBEAT_ABFRAGEN';
const HEARTBEAT_SETZEN = 'HEARTBEAT_SETZEN';
const HEARTBEAT_ABFRAGE_EREIGNISSE = 'HEARTBEAT_ABFRAGE_EREIGNISSE';
const HEARTBEAT_OFFLINE = 'HEARTBEAT_OFFLINE';

let timerIntervalId = null;

export function heartbeatTimerStarten() {
  return (dispatch) => {
    clearInterval(timerIntervalId); // zur sicherheit

    timerIntervalId = setInterval(() => {
      dispatch(heartbeatAbfragen());
    }, 2000);
  };
}

export function heartbeatTimerStoppen() {
  return () => {
    clearInterval(timerIntervalId); // zur sicherheit
  };
}

export function heartbeatOffline(error) {
  return {type: HEARTBEAT_OFFLINE, error: error};
}

export function heartbeatAbfragen(laufendeSpielzeitSetzen = false) {
  return (dispatch, getState) => {
    const api = getState().api;
    return executeRequest(api.heartbeat, api.token, 'GET', {}, (result) => {
      let state = getState();
      let jsonDaten = result.data;
      if (jsonDaten.tos.umsystem_modifikation && new Date(state.heartbeat.abfrageEreignisse) < new Date(jsonDaten.tos.umsystem_modifikation)) {
        dispatch(ereignisseAbfragen());
      }
      if (jsonDaten.spiel) {
        const zwischenresultat = jsonDaten.spiel.zwischenresultat;
        dispatch(spielanzeigeToreSetzen(zwischenresultat.heim, zwischenresultat.gast));
      }
      dispatch({type: HEARTBEAT_ABFRAGEN, heartbeat: jsonDaten});

      if (laufendeSpielzeitSetzen) {
        dispatch(laufendeSpielzeitInitialisieren());
      }
      
      if (jsonDaten.spiel.periode === PERIODE.spiel_beendet || jsonDaten.spiel.periode === PERIODE.spiel_abgebrochen) {
        dispatch(heartbeatTimerStoppen());
        dispatch(spielUpdate({ ist_abgeschlossen: true }));
        dispatch(ereignisEditClear());
      }
    }, (error) => {
      dispatch(heartbeatOffline(error));
    });
  };
}

// setzt laufende spielzeit -> sync sicherstellen zwischen MHA / TOS / etc 
export function heartbeatLaufendeSpielzeitSetzen(laufendeSpielzeit, zeit_laeuft) {
  return (dispatch, getState) => {
    const api = getState().api;
    const spielzeit = getState().heartbeat.spielzeit;
    const jetzt = new Date();
    const minute = Math.floor(laufendeSpielzeit / 60);
    const sekunde = laufendeSpielzeit % 60;
    
    if (zeit_laeuft === undefined) {
      zeit_laeuft = spielzeit.zeit_laeuft;
    }
    
    const daten = {
      gemessen_um: jetzt.toISOString(),
      minute: minute,
      sekunde: sekunde,
      zeit_laeuft: zeit_laeuft
    };
    return executeRequest(api.heartbeat, api.token, 'GET', daten, (result) => {
      dispatch({type: HEARTBEAT_SETZEN, heartbeat: result.data});
    });
  };
}

export const heartbeatAbfrageEreignisse = () => {
  return (dispatch) => {
    dispatch({type: HEARTBEAT_ABFRAGE_EREIGNISSE});
  };
};

function heartbeatReducer(state = {}, action) {
  switch (action.type) {
    case HEARTBEAT_ABFRAGEN:
      return {
        ...state,
        online: true,
        spiel: action.heartbeat.spiel,
        spielzeit: action.heartbeat.spielzeit,
        tos: action.heartbeat.tos,
        highlight: action.heartbeat.highlight,
      };
    case HEARTBEAT_SETZEN:
      return {
        ...state,
        online: true,
        spiel: action.heartbeat.spiel,
        spielzeit: action.heartbeat.spielzeit,
        tos: action.heartbeat.tos,
        highlight: action.heartbeat.highlight,
      };
    case HEARTBEAT_ABFRAGE_EREIGNISSE:
      return {
        ...state,
        online: true,
        abfrageEreignisse: new Date(),
      };
    case HEARTBEAT_OFFLINE:
      return {
        ...state,
        online: false,
      }
    default:
      return state;
  }
}

export default heartbeatReducer;
