
function machFetchInit(method) {
  return {
    method: method,
    mode: 'same-origin',
    credentials: 'same-origin',
    headers: {'content-type': 'application/json'}
  }
}

function startFetch(fetchInit, url) {
  return fetch(url, fetchInit)
    .then(response => {
      return response.json().then(jsonData => ({data: jsonData, ok: response.ok, status: response.status}));
    });
}

export async function executeGet(url, daten) {
  let apiUrl = url;
  if (daten) {
    const querystring = Object.keys(daten)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(daten[key])}`)
      .join('&');
    apiUrl = `${url}?${querystring}`;
  }

  return startFetch(machFetchInit('GET'), apiUrl);
}

export async function executeJson(url, token, method, daten) {
  daten['authenticity_token'] = token;

  const fetchInit = machFetchInit(method);
  fetchInit.body = JSON.stringify(daten);

  return startFetch(fetchInit, url);
}
