import React from "react";

const Pager = ({totalItemCount = 0, pageIndex = 1, pageSize = 10, onPageChange, onPageSizeChange = undefined, pageSizeArray = [10, 20, 50, 100]}) => {
  if (totalItemCount === 0) {
    return <></>; // nichts rendern wenn kein paging nötig ist
  }

  const showPageSize = onPageSizeChange !== undefined;
  if (totalItemCount <= pageSize) {
    if (showPageSize && totalItemCount > pageSizeArray[0]) {
      // pageSize nur rendern wenn kein paging nötig ist (bei minimaler pageSize -> wir gehen davon aus dass der erste eintrag im pageSizeArray den kleinsten wert hat)
      return (
        <div className="pagination pagination-right" style={{margin: '4px 0'}}>
          {renderPageSize()}
        </div>
      );
    } else {
      return <></>;
    }
  }

  const pageNumbers = [];
  const pagesTotal = Math.ceil(totalItemCount / pageSize);
  if (pagesTotal <= 10) {
    // nur eine gruppe mit nummern anzeigen
    for (let i = 1; i <= pagesTotal; i++) {
      pageNumbers.push(i);
    }
  } else {
    // mehrere gruppen anzeigen (mit eintrag "..." getrennt)
    const anzahlInGruppe = 7;

    // erster bereich: mindestens 2 rendern, sonst "anzahlInGruppe".
    if (pageIndex < anzahlInGruppe) { // wenn pageIndex == anzahlInGruppe, dann bereits nur die ersten 2 rendern
      for (let i = 1; i <= anzahlInGruppe; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push(null, pagesTotal - 1, pagesTotal);
    } else {
      pageNumbers.push(1, 2);

      if (pageIndex > pagesTotal - anzahlInGruppe + 1) {
        // wir sind im letzten teil, also einfach "..." und die letzten "anzahlInGruppe" rendern
        pageNumbers.push(null);
        for (let i = pagesTotal - anzahlInGruppe + 1; i <= pagesTotal; i++) {
          pageNumbers.push(i);
        }
      } else {
        // wir sind im mittelteil, evt mit 2 "..." trennungen (keine trennung einfügen wenn index fortlaufend ist)
        const differenz = Math.floor((anzahlInGruppe - 1) / 2);
        const start = pageIndex - differenz;
        const ende = pageIndex + differenz;
        if (start > 3) {
          pageNumbers.push(null);
        }
        for (let i = start; i <= ende; i++) {
          pageNumbers.push(i);
        }
        if (ende <= pagesTotal - differenz) {
          pageNumbers.push(null);
        }
        pageNumbers.push(pagesTotal - 1, pagesTotal);
      }
    }
  }

  function pageChange(index) {
    onPageChange && onPageChange(index);
  }

  function pageSizeChange(ev) {
    onPageSizeChange(ev.currentTarget.value);
    ev.currentTarget.blur();
  }

  function renderPageSize() {
    if (showPageSize) {
      return (
        <select className="page-size" value={pageSize} onChange={ev => pageSizeChange(ev)}>
          {pageSizeArray.map(size => {
            if (isNaN(size)) {
              return <option key={size.anzahl} value={size.anzahl}>{size.text}</option>;
            } else {
              return <option key={size} value={size}>{size}</option>;
            }
          })}
        </select>
      );
    }
    return <></>;
  }
  return (
    <div className="pagination pagination-right" style={{margin: '4px 0'}}>
      {renderPageSize()}
      <ul>
        <li className="previous_page">
          {pageIndex === 1 &&
          <span>←</span>
          }
          {pageIndex > 1 &&
          <a style={{cursor: 'pointer'}} onClick={() => pageChange(pageIndex - 1)}>←</a>
          }
        </li>
        {pageNumbers.map((number, index) => {
          if (number === null) {
            return <li key={index}><span>&hellip;</span></li>; // rendert gruppentrennung mit "..."
          } else {
            return (
              <li key={index} className={pageIndex === number ? 'active' : ''}>
                {pageIndex === number &&
                <span>{number}</span>
                }
                {pageIndex === number ||
                <a style={{cursor: 'pointer'}} onClick={() => pageChange(number)}>{number}</a>
                }
              </li>
            );
          }
        })}
        <li className="next_page">
          {pageIndex < pagesTotal &&
          <a style={{cursor: 'pointer'}} onClick={() => pageChange(pageIndex + 1)}>→</a>
          }
          {pageIndex === pagesTotal &&
          <span>→</span>
          }
        </li>
      </ul>
    </div>
  );
};

export default Pager;
