import {listeTimerStarten, listeTimerStoppen, roomList} from "./rooms";
import {executeRequest} from "../lib/api";
import {sortiereLobby} from "../lib/helpers";

const SETTINGS_ROOM = 'SETTINGS_ROOM';
const SETTINGS_AUDIO = 'SETTINGS_AUDIO';
const SETTINGS_VIDEO = 'SETTINGS_VIDEO';
const SETTINGS_ROOMS_TIMESTAMP = 'SETTINGS_ROOMS_TIMESTAMP';
const SETTINGS_LOBBY = 'SETTINGS_LOBBY';
const SETTINGS_FULLSCREEN = 'SETTINGS_FULLSCREEN';
const SETTINGS_LOBBY_OFFEN = 'SETTINGS_LOBBY_OFFEN';

let lobbyTimerIntervalId = null;

export function lobbyTimerStarten() {
  return (dispatch, getState) => {
    clearInterval(lobbyTimerIntervalId); // zur sicherheit
    const timeout = getState().settings.lobbyCheckIntervall;
    lobbyTimerIntervalId = setInterval(() => {
      if (getState().settings.lobbyOffen) {
        dispatch(lobbyEnter());
      } else {
        dispatch(lobbyListe());
      }
    }, timeout * 1000);
  };
}
export function lobbyTimerStoppen() {
  return () => {
    clearInterval(lobbyTimerIntervalId);
  };
}

export function refreshRoomsList() {
  return (dispatch, getState) => {
    // erzwingt refresh der roomsliste sowie aller participants. 
    // muss aktuell immer aufgerufen werden wenn sich der zustand ändert.
    // sobald twilio-callback eingebaut ist, sollte dass dann überflüssig werden.
    dispatch(setRoomsTimestamp(null));
    dispatch(roomList());
  }
}
export function setCurrentRoom(room) {
  return (dispatch, getState) => {
    if (room) {
      dispatch(listeTimerStoppen());
      dispatch(lobbyTimerStoppen());
    } else {
      dispatch(listeTimerStarten());
      dispatch(lobbyTimerStarten());
    }
    dispatch({type: SETTINGS_ROOM, room: room});
  };
}
export function setAudio(isMuted) {
  return (dispatch) => {
    dispatch({type: SETTINGS_AUDIO, isMuted: isMuted});
  };
}
export function setVideo(isMuted) {
  return (dispatch) => {
    dispatch({type: SETTINGS_VIDEO, isMuted: isMuted});
  };
}
export function setFullscreen(isFullscreen) {
  return (dispatch) => {
    dispatch({type: SETTINGS_FULLSCREEN, isFullscreen: isFullscreen});
  };
}
export function setRoomsTimestamp(timestamp) {
  return (dispatch) => {
    dispatch({type: SETTINGS_ROOMS_TIMESTAMP, roomsTimestamp: typeof (timestamp) === 'string' ? new Date(timestamp) : null});
  };
}
export function lobbyListe() {
  return (dispatch, getState) => {
    const api = getState().api;
    executeRequest(api.lobby, api.token, 'POST', {lobby: 'liste'}, (result) => {
      if (result.ok) {
        dispatch(setLobby(result.data.personen));
      } else {
        console.log(result);
      }
    });
  };
}
export function lobbyEnter() {
  return (dispatch, getState) => {
    const api = getState().api;
    executeRequest(api.lobby, api.token, 'POST', {lobby: 'enter'}, (result) => {
      if (result.ok) {
        dispatch({type: SETTINGS_LOBBY, personen: result.data.personen});
        dispatch({type: SETTINGS_LOBBY_OFFEN, lobbyOffen: true});
        dispatch(listeTimerStarten());
      } else {
        console.log(result);
      }
    });
  };
}
export function lobbyLeave() {
  return (dispatch, getState) => {
    dispatch(listeTimerStoppen());
    const api = getState().api;
    executeRequest(api.lobby, api.token, 'POST', {lobby: 'leave'}, (result) => {
      if (result.ok) {
        dispatch({type: SETTINGS_LOBBY, personen: result.data.personen});
        dispatch({type: SETTINGS_LOBBY_OFFEN, lobbyOffen: false});
      } else {
        console.log(result);
      }
    });
  };
}
export function setLobby(personen) {
  return (dispatch, getState) => {
    dispatch({type: SETTINGS_LOBBY, personen: personen});
    const currentPerson = getState().person;
    if (personen.find(p => p.id === currentPerson.id)) {
      dispatch({type: SETTINGS_LOBBY_OFFEN, lobbyOffen: true});
    }
  };
}

function settingsReducer(state = {}, action) {
  switch (action.type) {
    case SETTINGS_ROOM:
      return {...state, currentRoom: action.room};
    case SETTINGS_AUDIO:
      return {...state, audioMuted: action.isMuted};
    case SETTINGS_VIDEO:
      return {...state, videoMuted: action.isMuted};
    case SETTINGS_FULLSCREEN:
      return {...state, fullscreen: action.isFullscreen};
    case SETTINGS_ROOMS_TIMESTAMP:
      return {...state, roomsTimestamp: action.roomsTimestamp};
    case SETTINGS_LOBBY_OFFEN:
      return {...state, lobbyOffen: action.lobbyOffen};
    case SETTINGS_LOBBY:
      const neueLobby = action.personen.sort(sortiereLobby);
      return {
        ...state,
        lobby: neueLobby,
        supportAnwesend: neueLobby.length > 0,
      };
    default:
      return state;
  }
}

export default settingsReducer;
