import React, {useMemo} from "react";
import {connect} from "react-redux";
import {formatiereDatum} from "../../../lib/formatierungen";
import {selektiertBewertung} from "../state/modules/selektiert";

const BewertungAuswahl = ({spieler, bewertungen, texte, selektiertBewertung}) => {
  const spielerBewertungen = useMemo(() => bewertungen.filter(b => b.spieler_id === spieler.id), [bewertungen]);

  if (spielerBewertungen.length === 0) {
    return <></>;
  }
  
  return (
    <table className="table attributes widthAuto">
      <tbody>
      <tr>
        <td colSpan={3}><span className="text-bold">{texte.auswahlBewertung}</span></td>
      </tr>
      {spielerBewertungen.map(sb => {
        return <tr key={sb.id}>
          <td>{formatiereDatum(sb.datum_zeit)}</td>
          <td>
            {sb.abgeschlossen &&
            <span className="label label-success">{texte.abgeschlossen}</span>
            }
          </td>
          <td>
            <button type="button" className="btn" onClick={() => selektiertBewertung(sb)}><i className="icon-edit"/></button>
          </td>
        </tr>;
      })}
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => {
  return {
    bewertungen: state.bewertungen,
    texte: state.i18n.texte,
    api: state.api,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selektiertBewertung: (bewertung) => {
      dispatch(selektiertBewertung(bewertung));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BewertungAuswahl);
