import React, {useState} from "react";
import {connect} from "react-redux";
import ArbeitsraumListe from "../ArbeitsraumListe";

const ArbeitsraumKlasse = ({teams, arbeitsraumListe, texte}) => {
  const [showAlleEintraege, setShowAlleEintraege] = useState([]);

  const toggleZeigeAlleEintraege = (teamId) => {
    if(showAlleEintraege.includes(teamId)) {
      setShowAlleEintraege(showAlleEintraege.filter(t => t !== teamId));
    } else {
      setShowAlleEintraege([...showAlleEintraege, teamId]);
    }
  };

  return (
    <>
      {teams.map(team => {
        let meineArbeitsraumEintraege = arbeitsraumListe.filter(a => a.betrifftKlasse && a.kursplanTeamsId === team.id);
        const anzahlEintraege = meineArbeitsraumEintraege.length;

        if (!showAlleEintraege.includes(team.id) && meineArbeitsraumEintraege.length > 0) {
          meineArbeitsraumEintraege = [meineArbeitsraumEintraege[0]];
        }

        return <div key={team.id} className="block arbeitsraum-klasse">
          <div className="header">
            <h6>
              {`${texte.arbeitsraumKlasse}: ${team.bezeichnung}`}
              &nbsp;<span className="label label-warning">{team.programmBlock.name}</span>
              <button className="btn btn-mini pull-right" disabled={meineArbeitsraumEintraege.length === 0} onClick={() => toggleZeigeAlleEintraege(team.id)}>
                <i className="icon-envelope"/>
                {anzahlEintraege > 0 &&
                <span className="anzahl">({anzahlEintraege})</span>
                }
              </button>
            </h6>
          </div>
          <div className="content">
            <ArbeitsraumListe arbeitsraumListe={meineArbeitsraumEintraege} team={team} fuerKlasse={true}/>
          </div>
        </div>;
      })}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    teams: state.teams,
    arbeitsraumListe: state.arbeitsraumListe,
    texte: state.i18n.texte.arbeitsraum
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ArbeitsraumKlasse);
