import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../components/ModalerDialog";
import {resultatMelden} from "../state/modules/spiele";

const ResultatMeldenModal = ({spiel, onHide, spielstatusListe, teams, texte, resultatMelden}) => {
  const [toreHeim, setToreHeim] = useState(spiel.keinResultat ? '' : spiel.toreHeim);
  const [toreGast, setToreGast] = useState(spiel.keinResultat ? '' : spiel.toreGast);
  const [spielstatusId, setSpielstatusId] = useState(spiel.keinResultat ? '2' : spiel.spielstatusId);
  const [kommentar, setKommentar] = useState('');
  const selectedSpielstatus = spielstatusListe.find(s => s.id === parseInt(spielstatusId, 10));
  const inputToreHeim = useRef(null);
  const heimTeam = teams.find(t => t.id === spiel.heimTeamId);
  const gastTeam = teams.find(t => t.id === spiel.gastTeamId);

  useEffect(() => {
    inputToreHeim.current.focus();
  }, []);
  
  const speichern = () => {
    const status = spielstatusListe.find(st => st.id == spielstatusId);
    if (status) {
      if (toreHeim === '') {
        // fehler anzeigen
        return;
      }
      if (toreGast === '') {
        // fehler anzeigen
        return;
      }
      if (status.kommentarNoetig && kommentar === '') {
        // fehler anzeigen
        return;
      }
      resultatMelden(spiel, toreHeim, toreGast, spielstatusId, kommentar);
      onHide();
    }
  };

  const schliessen = () => {
    onHide();
  };

  return (
    <ModalerDialog>
      <div className="modal">
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{spiel.keinResultat ? texte.resultatMelden : texte.resultatAendern}</h3>
        </div>
        <div className="modal-body">
          <div className="row-fluid">
            <div className="span4"><b>{heimTeam.bezeichnung}</b></div>
            <div className="span8"><input ref={inputToreHeim} type="number" value={toreHeim} onChange={e => setToreHeim(e.currentTarget.value)}/></div>
          </div>
          <div className="row-fluid">
            <div className="span4"><b>{gastTeam.bezeichnung}</b></div>
            <div className="span8"><input type="number" value={toreGast} onChange={e => setToreGast(e.currentTarget.value)}/></div>
          </div>
          <div className="row-fluid">
            <div className="span4"><b>{texte.spielstatus}</b></div>
            <div className="span8">
              <select value={spielstatusId} onChange={e => setSpielstatusId(e.currentTarget.value)}>
                <option/>
                {spielstatusListe.map(status => <option key={status.id} value={status.id}>{status.bezeichnung}</option>)}
              </select>
            </div>
            {selectedSpielstatus && selectedSpielstatus.kommentarNoetig &&
            <div className="row-fluid">
              <div className="span4"><b>{texte.kommentar}</b></div>
              <div className="span8"><textarea style={{boxSizing: "border-box", width: "100%"}} rows={5} value={kommentar} onChange={e => setKommentar(e.currentTarget.value)}/></div>
            </div>
            }
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-primary" onClick={speichern}>{texte.speichern}</button>
          <button className="btn" onClick={schliessen}>{texte.abbrechen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    spielstatusListe: state.spielstatusListe,
    teams: state.teams,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resultatMelden: (spiel, toreHeim, toreGast, spielstatusId, kommentar) => { dispatch(resultatMelden(spiel, toreHeim, toreGast, spielstatusId, kommentar)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultatMeldenModal);
