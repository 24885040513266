import React, {useContext} from 'react'
import {Kontext} from "../index";
import Tooltip from "../../../components/Tooltip";

const Zeitlinie = (props) => {
  const context = useContext(Kontext);

  function titelFuerEintrag(eintrag) {
    let titel;
    let zusatz = '';
    if (eintrag.typ === 'spiel') {
      titel = <>
        {`[${eintrag.spieltyp}] ${eintrag.spielteams[0]}`}
        <br/>
        {` - ${eintrag.spielteams[1]}`}
      </>
      if (eintrag.bearbeitbarText !== null) {
        zusatz = <>
          <br/>
          {eintrag.bearbeitbarText.toUpperCase()}
        </>;
      }
    } else {
      titel = context.texte.typ[eintrag.typ];
    }
    return <>{titel}<br/>{eintrag.zeitpunkt}{zusatz}</>;
  }

  return (
    <div className="zeitlinie">
      <Tooltip content={context.texte.rueckwaerts} aktiv={props.page > 0}>
        <span className={props.page == 0 ? "keine-anzeige" : ""} onClick={() => props.onPageChange(props.page - 1)}>
          <i className="navigation icon icon-backward"/>
        </span>
      </Tooltip>
      {
        props.eintraege.map((eintrag) => {
          const cssClasses = ['label'];
          cssClasses.push(eintrag.typ == 'training' ? 'label-info' : 'label-success');
          if (!eintrag.bearbeitbar) {
            cssClasses.push('gesperrt');
          }
          return (
            <Tooltip key={eintrag.typ + eintrag.id} content={titelFuerEintrag(eintrag)}>
              <span className={cssClasses.join(' ')}>
                {eintrag.zeitpunktTagKuerzel}
                <br/>
                {eintrag.zeitpunktTagMonat}
                <br/>
                {context.texte.typ_titel[eintrag.typ]}  
              </span>
            </Tooltip>
          );
        })
      }
      <Tooltip content={context.texte.vorwaerts} aktiv={props.limit <= props.eintraege.length}>
        <span className={props.limit > props.eintraege.length ? "keine-anzeige" : ""} onClick={() => props.onPageChange(props.page + 1)}>
          <i className="navigation icon icon-forward"/>
        </span>
      </Tooltip>
    </div>
  );
};
export default Zeitlinie
