import React, {useState} from "react";
import {connect} from "react-redux";
import {executeRequest} from "../lib/fetchHelper";
import {formatiereDatum} from "../../../lib/formatierungen";

const RechnungslaufAuswahl = ({value = null, onChange, multiselect = false, istEntfernbar = false, api, texte}) => {
  const [liste, setListe] = useState([]);
  const [index, setIndex] = useState(0);
  const [weitereResultate, setWeitereResultate] = useState(false);
  const [abfrageAktiv, setAbfrageAktiv] = useState(false);
  const [sucheTimeout, setSucheTimeout] = useState(null);

  const timeoutMiliseconds = 400; // TODO: passendes timeout für eingabe von hand finden

  const [rechnungslauf, setRechnungslauf] = useState(value ? [value] : []);
  const [bezeichnung, setBezeichnung] = useState('');
  const [showResultate, setShowResultate] = useState(false);

  const zeigeResultate = (anzeigen) => {
    setShowResultate(anzeigen && liste.length > 0);
  };

  const sucheStarten = (suche, idx = 0) => {
    clearTimeout(sucheTimeout);
    const timeoutId = setTimeout(() => abfragen(suche, idx), timeoutMiliseconds);
    setSucheTimeout(timeoutId);
  };

  const abfragen = (suche, idx) => {
    const daten = {
      bezeichnung: suche,
      index: idx
    };
    setAbfrageAktiv(true);
    setIndex(idx);
    executeRequest(api.rechnungslauf, api.token, 'GET', daten)
      .then(result => {
        if (result.ok) {
          const data = result.data;
          if (idx > 0) {
            setListe(liste.concat(data.rechnungslaeufe));
          } else {
            setListe(data.rechnungslaeufe);
          }
          setWeitereResultate(data.weitereResultate);
          setShowResultate(data.rechnungslaeufe.length > 0);
        }
        setAbfrageAktiv(false);
      });
  };

  const selectRechnungslauf = (rl) => {
    zeigeResultate(false);
    if (multiselect) {
      // keine mehrfachwahl eines rechnungslaufs möglich
      if (rechnungslauf.find(r => r.id === rl.id) !== rl) {
        const selected = [...rechnungslauf, rl];
        setRechnungslauf(selected);
        onChange(selected);
      }
    } else {
      setRechnungslauf([rl]);
      onChange(rl);
    }
  };

  const auswahlEntfernen = (event, rl) => {
    event.preventDefault();
    event.stopPropagation();
    if (multiselect) {
      const selected = rechnungslauf.filter(r => r.id !== rl.id);
      setRechnungslauf(selected);
      onChange(selected);
    } else {
      setRechnungslauf([]);
      onChange(null);
    }
  };

  const rendeRechnungslauf = (r) => {
    return <>{`${r.bezeichnung} (${formatiereDatum(r.erstelltAm)})`}</>;
  };

  const onScrollResultate = (event) => {
    if (weitereResultate && abfrageAktiv === false) {
      const target = event.currentTarget;
      if (target.scrollTop >= (target.scrollHeight - target.clientHeight)) {
        abfragen(bezeichnung, index + 1);
      }
    }
  };

  const onChangeBezeichnung = (text) => {
    setIndex(0);
    setBezeichnung(text);
    zeigeResultate(false);
    sucheStarten(text);
  };

  const onFocusBezeichnung = (ev) => {
    if (liste.length === 0) {
      sucheStarten(bezeichnung);
    }
  };

  const onClickBezeichnung = () => {
    zeigeResultate(!showResultate);
  };

  const onBlur = () => {
    zeigeResultate(false);
  };

  return (
    <div className="suchbereich">
      <div className="zeile text-nowrap">
        <input type="text" placeholder={texte.textsuche} onChange={ev => onChangeBezeichnung(ev.currentTarget.value)} onFocus={onFocusBezeichnung} onBlur={onBlur} onClick={onClickBezeichnung}/>
        {abfrageAktiv &&
        <span className="ajax-spinner-klein"/>
        }
      </div>
      {rechnungslauf.length > 0 &&
      <div className="zeile liste">
        {rechnungslauf.map(rl => {
          return (
            <div key={rl.id}>
              <span className="bordered-box" onClick={() => zeigeResultate(!showResultate)} onBlur={onBlur} tabIndex={-1}>
                {rendeRechnungslauf(rl)}
                {istEntfernbar &&
                <button type="button" className="close" onClick={ev => auswahlEntfernen(ev, rl)}>&times;</button>
                }
              </span>
            </div>
          );
        })}
      </div>
      }
      {showResultate > 0 &&
      <div className="suchresultat-container">
        <div className="suchresultat-liste" onScroll={onScrollResultate} onMouseDown={ev => ev.preventDefault()}>
          {liste.map(l => <div key={l.id} className={`item ${rechnungslauf.find(rl => l.id === rl.id) ? 'selected' : ''}`} onClick={() => selectRechnungslauf(l)}>{rendeRechnungslauf(l)}</div>)}
        </div>
      </div>
      }
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    api: state.api,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RechnungslaufAuswahl);
