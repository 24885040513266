export function berechneAlleWerte(daten, spieler, wachstumsreserve) {
  daten['beinlaenge'] = berechneBeinlaenge(daten);
  daten['chronologisches_alter'] = berechneChronologischesAlter(daten, spieler);
  const maturityOffset = berechneMaturityOffset(daten, spieler);
  daten['biologisches_alter'] = berechneBiologischesAlter(daten, maturityOffset, spieler);
  daten['biologischer_entwicklungsstand'] = berechneBiologischerEntwicklungsstand(daten);
  daten['erwachsenen_groesse_cm'] = berechneErwachsenengroesse(daten, maturityOffset, spieler, wachstumsreserve);
  daten['erwachsenen_groesse_prozent'] = berechneErwachsenengroesseProzent(daten);
  return daten;
}

function berechneBeinlaenge(daten) {
  let beinlaenge = 0;
  if (daten['koerpergroesse_sitzend'] > 0 && daten['koerpergroesse_stehend'] > 0) {
    beinlaenge = (daten['koerpergroesse_stehend'] - daten['koerpergroesse_sitzend']).toFixed(1);
  }
  return beinlaenge > 0 ? beinlaenge : null;
}

function berechneChronologischesAlter(daten, spieler) {
  if (daten['datum_zeit_messung']) {
    const diff = daten['datum_zeit_messung'] - new Date(spieler.person.geburtsdatum);
    return (diff / (1000 * 60 * 60 * 24 * 365.25)).toFixed(2);
  }
  return null;
}

function berechneBiologischesAlter(daten, maturityOffset, spieler) {
  if (maturityOffset === null) {
    return null;
  }
  let alter = 0;
  if (spieler.geschlecht === 'm') {
    alter = 13.8 + maturityOffset;
  } else {
    alter = 11.8 + maturityOffset;
  }
  return alter.toFixed(1);
}

function berechneErwachsenengroesse(daten, maturityOffset, spieler, wachstumsreserve) {
  if (maturityOffset !== null && daten['chronologisches_alter'] > 0 && daten['koerpergroesse_stehend'] > 0) {
    const alterWachstumsspurt = daten['chronologisches_alter'] - maturityOffset;

    let lookupSpalte = 3;
    if (spieler.geschlecht === 'm') {
      if (alterWachstumsspurt < 13) {
        lookupSpalte = 1;
      } else if (alterWachstumsspurt < 15) {
        lookupSpalte = 2;
      }
    } else {
      if (alterWachstumsspurt < 11) {
        lookupSpalte = 1;
      } else if (alterWachstumsspurt < 13) {
        lookupSpalte = 2;
      }
    }

    const lookupTabelle = wachstumsreserve[spieler.geschlecht];
    let eintrag = lookupTabelle[0]; // default ist erster eintrag, da kein eintrag gefunden wird wenn maturityOffset < -4 ist
    for (let i = 0; i < lookupTabelle.length; i++) {
      if (lookupTabelle[i][0] <= maturityOffset) {
        eintrag = lookupTabelle[i];
      } else {
        break;
      }
    }
    return (daten['koerpergroesse_stehend'] + eintrag[lookupSpalte]).toFixed(1);
  }
  return null;
}

function berechneErwachsenengroesseProzent(daten) {
  if (daten['koerpergroesse_stehend'] > 0 && daten['erwachsenen_groesse_cm'] > 0) {
    return (daten['koerpergroesse_stehend'] / daten['erwachsenen_groesse_cm'] * 100).toFixed(1);
  }
  return null;
}

function berechneBiologischerEntwicklungsstand(daten) {
  let entwicklungsstand = null;
  if (daten['biologisches_alter'] > 0 && daten['chronologisches_alter'] > 0) {
    const delta = daten['biologisches_alter'] - daten['chronologisches_alter'];
    if (delta > 0.6251) {
      entwicklungsstand = 1;
    } else if (delta > 0.3751) {
      entwicklungsstand = 2;
    } else if (delta < -0.625) {
      entwicklungsstand = 5;
    } else if (delta < -0.375) {
      entwicklungsstand = 4;
    } else {
      entwicklungsstand = 3;
    }
  }
  return entwicklungsstand;
}

function berechneMaturityOffset(daten, spieler) {
  if (daten['beinlaenge'] > 0 && daten['chronologisches_alter'] > 0 && daten['koerpergroesse_sitzend'] > 0 && daten['koerpergroesse_stehend'] > 0 && daten['koerpergewicht'] > 0) {
    if (spieler.geschlecht === 'm') {
      return (-9.236 +
        (0.0002708 * daten['beinlaenge'] * daten['koerpergroesse_sitzend']) +
        (-0.001663 * daten['chronologisches_alter'] * daten['beinlaenge']) +
        (0.007216 * daten['chronologisches_alter'] * daten['koerpergroesse_sitzend']) +
        (0.02292 * daten['koerpergewicht'] / daten['koerpergroesse_stehend'] * 100)
      );
    } else {
      return (-9.376 +
        (0.0001882 * daten['beinlaenge'] * daten['koerpergroesse_sitzend']) +
        (0.0022 * daten['chronologisches_alter'] * daten['beinlaenge']) +
        (0.005841 * daten['chronologisches_alter'] * daten['koerpergroesse_sitzend']) -
        (0.002658 * daten['chronologisches_alter'] * daten['koerpergewicht']) +
        (0.07693 * (daten['koerpergewicht'] / daten['koerpergroesse_stehend']) * 100)
      );
    }
  }

  return null;
}