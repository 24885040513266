import React, {useState, useRef, useEffect} from 'react'
import '../shared/shared-components'

export const POSITION = {
  left: 'left',
  right: 'right',
  top: 'top',
  bottom: 'bottom'
};

const Tooltip = ({children, content, aktiv = true, position = POSITION.top, tagName = 'span', className = "", ...props}) => {
  const [istSichtbar, setIstSichtbar] = useState(false);
  const [styleWerte, setStyleWerte] = useState(null);
  const triggerElement = useRef(null);
  const tooltipElement = useRef(null);
  const classNames = ["tooltip-trigger", className].join(" ");
  const DynamicTag = `${tagName}`;
  const [animateClass, setAnimateClass] = useState('in');
  
  useEffect(() => {
    if (aktiv && istSichtbar && styleWerte === null) {
      const style = {
        left: 'auto',
        right: 'auto',
        top: 'auto',
        bottom: 'auto',
      };
      const ausloeser = triggerElement.current;
      switch (position) {
        case POSITION.right:
          style.top = -Math.floor((tooltipElement.current.clientHeight - ausloeser.clientHeight) / 2);
          style.left = ausloeser.clientWidth;
          break;
        case POSITION.left:
          style.top = -Math.floor((tooltipElement.current.clientHeight - ausloeser.clientHeight) / 2);
          style.left = -tooltipElement.current.clientWidth;
          break;
        case POSITION.top:
          style.bottom = ausloeser.clientHeight;
          style.left = -Math.floor((tooltipElement.current.clientWidth - ausloeser.clientWidth) / 2);
          break;
        case POSITION.bottom:
          style.top = ausloeser.clientHeight;
          style.left = -Math.floor((tooltipElement.current.clientWidth - ausloeser.clientWidth) / 2);
          break;
      }
      setStyleWerte(style);
    }
  }, [istSichtbar, aktiv]);
  
  const handleMouseOver = () => {
    setAnimateClass('in');
    setIstSichtbar(true);
  };
  const handleMouseLeave = () => {
    setAnimateClass('');
    window.setTimeout(() => setIstSichtbar(false), 100);
  };

  return (
    <DynamicTag ref={triggerElement} className={classNames} {...props} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      {children}
      {aktiv && istSichtbar &&
      <div ref={tooltipElement} className={`tooltip fade ${animateClass} ${position}`} style={styleWerte || {left: 0, top: 0}} onMouseEnter={handleMouseLeave}>
        <div className="tooltip-arrow"></div>
        <div className="tooltip-inner">{content}</div>
      </div>
      }
    </DynamicTag>
  );
};

export default Tooltip;
