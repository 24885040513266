// @flow

import type {AppState, EinsatzState, Team, TeamState, TrainingState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

const TeamHeaderTraining = (props: {
  einsatz: EinsatzState,
  teams: TeamState,
  training: TrainingState,
  i18n: any,
}) => {
  if (!props.training) {
    return <></>;
  }

  let team: Team | null = null;
  props.teams.liste.forEach((teamEintrag: Team) => {
    if (teamEintrag.id === props.training.teamId) {
      team = teamEintrag;
    }
  });

  if (!team) {
    return <></>;
  }

  return (
    <div id="spiel-informationen">
      <div className="well" style={{height: '50px'}}>
        <div className={"team-" + team.id} style={{width: '100%', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div className="emblem">
            <div className="avatar pull-left">
              <img src={team.logo} style={{width: '34px'}} alt={team.id}/>
            </div>
            <div className="pull-left">
              <div className="title">{team.name}</div>
              <div className="subtitle">{team.ligaUndSpielklasse}</div>
            </div>
          </div>
          <div className="clearfix"/>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    teams: state.teams,
    training: state.training,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamHeaderTraining);
