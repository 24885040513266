import React, {useState, useEffect, useRef} from 'react';
import useEscape from "../../../effects/useEscape";
import {setFullscreen} from "../state/settings";
import {connect} from "react-redux";
import Tooltip, {POSITION} from "../../../components/Tooltip";

const ParticipantRemote = ({participant, fullscreen, texte, setFullscreen}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const [istAktiv, setIstAktiv] = useState(true);
  let fullscreenTimerId = 0;

  useEscape(() => setFullscreen(false));

  useEffect(() => {
    // component unmount: event listener entfernen
    return () => {
      window.removeEventListener('mousemove', resetAktivitaet);
    };
  }, []);

  const resetAktivitaet = () => {
    setIstAktiv(true);
    clearTimeout(fullscreenTimerId);
    fullscreenTimerId = setTimeout(() => {
      setIstAktiv(false);
    }, 500);
  };

  useEffect(() => {
    if (fullscreen) {
      window.addEventListener('mousemove', resetAktivitaet);
      resetAktivitaet();
    } else {
      window.removeEventListener('mousemove', resetAktivitaet);
    }
  }, [fullscreen]);
  
  const trackpubsToTracks = (trackMap) => {
    return Array.from(trackMap.values())
      .map(publication => publication.track)
      .filter(track => track !== null);
  };

  useEffect(() => {
    const trackSubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => [...videoTracks, track]);
      } else {
        setAudioTracks(audioTracks => [...audioTracks, track]);
      }
    };
    const trackUnsubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } else {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      }
    };

    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[videoTracks.length - 1];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
        setFullscreen(false);
      };
    } else {
      if (fullscreen) {
        setFullscreen(false);
      }
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <div className={`remote-participant ${fullscreen ? 'fullscreen' : ''}`}>
      {videoTracks.length > 0 &&
      <div className="video-container">
        <video ref={videoRef} autoPlay={true}/>
      </div>
      }
      {audioTracks.length > 0 &&
      <audio ref={audioRef} autoPlay={true}/>
      }
      {fullscreen &&
      <div className={`steuerung-container ${istAktiv ? 'aktiv' : ''}`}>
        <div className="steuerung">
          <Tooltip content={texte.fullscreenAus} position={POSITION.bottom}>
            <button className="material-button btn btn-info" onClick={() => setFullscreen(false)}>
              <i className="material-icons-outlined">fullscreen_exit</i>
            </button>
          </Tooltip>
        </div>
      </div>
      }
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    fullscreen: state.settings.fullscreen,
    texte: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFullscreen: (istFullscreen) => { dispatch(setFullscreen(istFullscreen)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantRemote);
