import React from "react";
import useGlobal from "../../../../store";
import {spielstandUndZeitBei, getVideo} from "../../../../services/helpers";

const ShowFormButton = () => {
  const [globalState, globalActions] = useGlobal();

  const getSpielZustand = (currentTime) => {
    const selectedVideo = getVideo(globalState, globalState.selectedVideo);
    return spielstandUndZeitBei(currentTime, selectedVideo, globalState.spielInfos.periodenInfos, globalState.tore);
  };

  const handleClick = () => {
    const video = document.querySelector("div.video-wrapper video") || {};
    if (video.pause) {
      video.pause();
    }
    const spielZustand = getSpielZustand(video.currentTime);
    globalActions.setFormItem({
      typId: globalState.stammdaten.kommentarTypen[0] && globalState.stammdaten.kommentarTypen[0].id,
      titel: "",
      beschreibung: "",
      video: globalState.selectedVideo,
      start: parseInt(video.currentTime, 10),
      minute: Math.max(spielZustand.minute, 1),
      zusatzminute: spielZustand.zusatzminute,
      teamId: null,
      spielerEinsatzId: null,
      trainerId: null,
      schiedsrichterId: null,
    });
    globalActions.setFormVisible(true);
  };

  return (
    <div className="flex-row">
      <div className="flex-1">
        <button className="primary" onClick={handleClick}>
          {globalState.i18n.kommentar_hinzufuegen}
        </button>
      </div>
      <div hidden={globalState.appState.runningRequests <= 0}>
        {globalState.appState.runningRequests > 0 && (
          <div className="pull-right">
            <img src={globalState.stammdaten.icons.spinner} alt={globalState.i18n.lade} style={{margin: "8px 0 8px 8px"}}/>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowFormButton;
