// @flow

import type {AppState, ProtokollEintrag} from "../../../state/stateTypes";
import type {Dispatch} from "../../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import {
  protokollWertSetzenToreHeim,
  protokollWertSetzenToreGast,
} from "../../../state/modules/protokoll";

const SpielstandAnzeige = (props: {
  i18n: any,
  toreHeim: number | null,
  toreGast: number | null,
  eintrag: ProtokollEintrag,
  protokollWertSetzenToreHeim: (string, number, boolean) => void,
  protokollWertSetzenToreGast: (string, number, boolean) => void,
}) => {
  if (props.eintrag.torBearbeitbar && !props.eintrag.nurLesen) {
    return (
      <div>
        <input type="text"
               style={{width: '40px', textAlign: 'center'}}
               value={props.toreHeim === null ? '' : props.toreHeim}
               onChange={(event) => {
                 props.protokollWertSetzenToreHeim(props.eintrag.key, event.target.value, true)
               }}/>
        <span style={{margin: '2px'}}>
          :
        </span>
        <input type="text"
               style={{width: '40px', textAlign: 'center'}}
               value={props.toreGast === null ? '' : props.toreGast}
               onChange={(event) => {
                 props.protokollWertSetzenToreGast(props.eintrag.key, event.target.value, true)
               }}/>
      </div>
    );
  } else {
    return (
      <div>
        <input type="text"
               disabled="disabled"
               style={{width: '40px', textAlign: 'center'}}
               value={props.toreHeim === null ? '' : props.toreHeim}
               readOnly={true}/>
        <span style={{margin: '2px'}}>
          :
        </span>
        <input type="text"
               disabled="disabled"
               style={{width: '40px', textAlign: 'center'}}
               value={props.toreGast === null ? '' : props.toreGast}
               readOnly={true}/>
      </div>
    );
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    protokollWertSetzenToreHeim: (eintragKey: string, wert: number) => {
      dispatch(protokollWertSetzenToreHeim(eintragKey, wert, true));
    },
    protokollWertSetzenToreGast: (eintragKey: string, wert: number) => {
      dispatch(protokollWertSetzenToreGast(eintragKey, wert, true));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpielstandAnzeige);
