import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {statusFavoritenTeamsPoll} from "../state/modules/status";

const FavoritenWatch = ({status, favoritenTeamsPoll}) => {
  const [watchInterval, setWatchInterval] = useState(null);
  const pollingIntervall = 1 * 60 * 1000; // jede minute abfragen (spiellog ist nur auf 1 minute genau)

  useEffect(() => {
    clearInterval(watchInterval);
    if (status.favoritenAktiv && status.favoritenTeams.length > 0) {
      favoritenTeamsPoll();
      let intervalId = setInterval(() => {
        favoritenTeamsPoll();
      }, pollingIntervall);
      setWatchInterval(intervalId);
    }
  }, [status.favoritenTeams]);

  return <></>;
};

const mapStateToProps = (state) => {
  return {
    status: state.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    favoritenTeamsPoll: () => { dispatch(statusFavoritenTeamsPoll()); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FavoritenWatch);
