import React from 'react';
import {connect} from "react-redux";
import AufgabeZuteilung from "./AufgabeZuteilung";
import {useDrop} from "react-dnd";
import {ItemTypes} from "../../lib/constants";
import {aufgabeZuteilungErstellen} from "../../state/teamTeilnehmerReducer";

const TeilnehmerAufgabenZuteilen = ({teilnehmer, aufgabenModus, texte, aufgabeZuteilungErstellen}) => {
  const teilnehmerAufgaben = teilnehmer.aufgaben;

  const [{isOver, canDrop}, drop] = useDrop({
    accept: ItemTypes.Aufgabe,
    drop: (item) => {
      aufgabeZuteilungErstellen(item, teilnehmer);
    },
    canDrop: (item) => {
      return teilnehmerAufgaben.find(a => a.bibliothekId === item.id) === undefined;
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  return (
    <div ref={drop} className={`content expand-width ${canDrop ? 'drop-ziel' : ''} ${canDrop && isOver ? 'drop-hover' : ''}`}>
      {teilnehmerAufgaben.map(az => <AufgabeZuteilung key={az.id} aufgabeZuteilung={az} teamTeilnehmer={teilnehmer} aufgabenModus={aufgabenModus}/>)}
      {teilnehmerAufgaben.length === 0 &&
      <div className="alert alert-info">{texte.keineAufgabenZugeteilt}</div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    aufgabeZuteilungErstellen: (aufgabe, ausbildner) => { dispatch(aufgabeZuteilungErstellen(aufgabe, ausbildner)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeilnehmerAufgabenZuteilen);
