import React, {useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../components/ModalerDialog";
import PersonBadge from "../../../components/PersonBadge";
import {rechnungCreate, rechnungUpdate} from "../state/modules/rechnungen";
import PersonAuswahl from "./PersonAuswahl";
import LeistungAuswahl from "./LeistungAuswahl";
import {berechtigungFuer} from "../lib/berechtigungen";
import {BEXIO_STATUS} from "../lib/constants";

const RechnungEdit = ({rechnung = null, onHide, rechnungslaufTyp, bexio, texte, rechnungCreate, rechnungUpdate}) => {
  const [person, setPerson] = useState(rechnung && rechnung.person);
  const [leistung, setLeistung] = useState(rechnung && rechnung.leistung);
  const [betragFreiwillig, setBetragFreiwillig] = useState(rechnung && rechnung.betragFreiwillig || false);
  const [betrag, setBetrag] = useState(rechnung !== null ? rechnung.betrag : '');
  const [bemerkungen, setBemerkungen] = useState(rechnung && rechnung.bemerkungen);
  
  const art = rechnung === null ? 'create' : 'update';
  const editPerson = berechtigungFuer(rechnungslaufTyp, 'person');
  const editLeistung = berechtigungFuer(rechnungslaufTyp, 'leistung');
  const editBetragFreiwillig = berechtigungFuer(rechnungslaufTyp, 'betragFreiwillig');
  const editBetrag = berechtigungFuer(rechnungslaufTyp, 'betrag');

  const speichern = () => {
    if (rechnung) {
      // update mit berechtigungschecks
      const personId = editPerson && editPerson[art] ? person.id : null;
      const leistungId = editLeistung && editLeistung[art] ? leistung.id : null;
      const betragswert = editBetrag && editBetrag[art] ? betrag : null;
      const bemerkungenChecked = darfBemerkungenBearbeiten(rechnung) ? bemerkungen : null;
      rechnungUpdate(rechnung, personId, leistungId, betragFreiwillig, betragswert, bemerkungenChecked);
      schliessen();
    } else {
      // create, muss person und leistung ausgewählt haben
      if (person && leistung) {
        rechnungCreate(person.id, leistung.id, betragFreiwillig, betrag, bemerkungen);
        schliessen();
      }
    }
  };

  const schliessen = () => {
    onHide();
  };

  const selectLeistung = (l) => {
    if (betrag === null || rechnung === null) {
      setBetrag(l.betrag);
    }
    setLeistung(l);
  };

  const darfBemerkungenBearbeiten = (rechnung) => {
    if (bexio.aktiv) {
      if (rechnung) {
        // bexio aktiv -> bemerkungen nur anzeigen wenn rechnung freigegeben aber noch nicht vollständig bezahlt ist
        return [
          BEXIO_STATUS.pending,
          BEXIO_STATUS.partial,
          BEXIO_STATUS.canceled,
          BEXIO_STATUS.unpaid
        ].includes(rechnung.bexioStatus);
      }
      return false;
    } else {
      // bexio inaktiv -> bemerkungen immer anzeigen
      return true;
    }
  };

  return (
    <ModalerDialog>
      <div className="modal modal-wide">
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{rechnung ? texte.rechnungBearbeiten : texte.rechnungErstellen}</h3>
        </div>
        <div className="modal-body" style={{overflow: 'visible'}}>
          <table className="table attributes ">
            <tbody>
            <tr>
              <th>{texte.person}</th>
              <td>
                {editPerson && editPerson[art] &&
                <PersonAuswahl value={person} onPersonChange={(p) => setPerson(p)}/>
                }
                {(editPerson && editPerson[art]) ||
                <PersonBadge person={person} personVerlinken={false} showVcf={false} fotoTooltipAktiv={false}/>
                }
              </td>
            </tr>
            {editLeistung && editLeistung[art] &&
            <tr>
              <th>{texte.leistung}</th>
              <td>
                <LeistungAuswahl value={leistung} onLeistungChange={(l) => selectLeistung(l)}/>
              </td>
            </tr>
            }
            {editBetragFreiwillig && editBetragFreiwillig[art] &&
            <tr>
              <th>{texte.betragFreiwillig}</th>
              <td><input type="checkbox" checked={betragFreiwillig} onChange={ev => setBetragFreiwillig(ev.currentTarget.checked)}/></td>
            </tr>
            }
            {editBetrag && editBetrag[art] &&
            <tr>
              <th>{texte.betrag}</th>
              <td><input className="betrag" type="number" step="0.05" value={betrag} onChange={ev => setBetrag(ev.currentTarget.value)}/></td>
            </tr>
            }
            {darfBemerkungenBearbeiten(rechnung) &&
            <tr>
              <th>{texte.bemerkungen}</th>
              <td><textarea rows={5} style={{width: '100%'}} value={bemerkungen || ''} onChange={ev => setBemerkungen(ev.currentTarget.value)}/></td>
            </tr>
            }
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" onClick={() => speichern()}>{texte.speichern}</button>
          <button type="button" className="btn" onClick={schliessen}>{texte.abbrechen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    rechnungslaufTyp: state.rechnungslauf.typ,
    bexio: state.bexio,
    texte: state.i18n.texte.rechnungen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rechnungCreate: (personId, leistungId, betragFreiwillig, betrag, bemerkungen) => { dispatch(rechnungCreate(personId, leistungId, betragFreiwillig, betrag, bemerkungen)); },
    rechnungUpdate: (rechnung, personId, leistungId, betragFreiwillig, betrag, bemerkungen) => { dispatch(rechnungUpdate(rechnung, personId, leistungId, betragFreiwillig, betrag, bemerkungen)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RechnungEdit);
