import React, {useState} from "react";
import {connect} from "react-redux";
import {teamDelete, teamSpeichern} from "../../state/teamsReducer";
import ModalerDialog from "../../../../components/ModalerDialog";
import Datepicker from "../Datepicker";

const KalenderPopup = ({team, onClose, klassenlehrer, programmBlockListe, texte, teamSpeichern, teamDelete}) => {
  const [experteId, setExperteId] = useState(team.experte.id);
  const [expertenListe, setExpertenListe] = useState([]);
  const [bezeichnung, setBezeichnung] = useState(team.bezeichnung);
  const [von, setVon] = useState(team.von);
  const [bis, setBis] = useState(team.bis);
  const [ganzeKlasse, setGanzeKlasse] = useState(false);
  const [programmBlockId, setProgrammBlockId] = useState(team.programmBlock.id);

  const experteButtonClick = (experteId) => {
    if(expertenListe.includes(experteId)) {
      setExpertenListe(expertenListe.filter(id => id !== experteId));
    } else {
      setExpertenListe([...expertenListe, experteId]);
    }
  };

  const erstelleTeam = () => {
    if (!bezeichnung || !programmBlockId) {
      return;
    }

    let experten;
    if (team.id === undefined) {
      experten = [...expertenListe];
    } else {
      experten = [experteId];
    }
    if (experten.length === 0) {
      return;
    }
    experten.forEach(id => {
      const experte = klassenlehrer.find(kl => kl.id === id);
      if (experte) {
        const zusatzdaten = {
          teamId: team.id,
          bezeichnung: bezeichnung,
          von: von,
          bis: bis,
          programmBlockId: programmBlockId,
          ganzeKlasse: ganzeKlasse
        };
        teamSpeichern(experte, zusatzdaten);
      }
    });
    onClose();
  };

  const loescheTeam = () => {
    teamDelete(team);
    onClose();
  };

  return (
    <ModalerDialog>
      <div className="block">
        <div className="header">
          <b>{texte.kursleiter.klasseBearbeiten}</b>
        </div>
        <div className="content edit extrabreit">
          <div className="zeile">
            <label>{texte.datumVon}</label>
            <Datepicker datum={von} changeHandler={setVon} mitZeit={true}/>
          </div>
          <div className="zeile">
            <label>{texte.datumBis}</label>
            <Datepicker datum={bis} changeHandler={setBis} mitZeit={true}/>
          </div>
          <div className="zeile">
            <label>{texte.teamProgrammBlock}</label>
            <select className="select" value={programmBlockId} onChange={(ev) => setProgrammBlockId(ev.currentTarget.value)}>
              <option value=""/>
              {programmBlockListe.map(block => <option key={block.id} value={block.id}>{block.name}</option>)}
            </select>
          </div>
          <div className="zeile">
            <label>{texte.teamBezeichnung}</label>
            <input type="text" value={bezeichnung} onChange={(ev) => setBezeichnung(ev.currentTarget.value)}/>
          </div>
          {team.id === undefined && // neues team: experte(n) wählen mit buttons
          <div className="zeile">
            <label style={{verticalAlign: "top"}}>{texte.experte}</label>
            <span className="btn-liste" style={{ maxWidth: 400, display: "inline-block"}}>
              {klassenlehrer.map(lehrer => {
                const aktiv = expertenListe.includes(lehrer.id);
                return <button key={lehrer.id} className={`btn btn-mini ${aktiv ? 'btn-success' : ''}`} onClick={() => experteButtonClick(lehrer.id)}>{`${lehrer.name} (${lehrer.funktion})`}</button>;
              })}
            </span>
          </div>
          }
          {team.id !== undefined && // bestehendes team: experte kann mit dropdown geändert werden
          <div className="zeile">
            <label>{texte.experte}</label>
            <select className="select" value={experteId} onChange={(ev) => setExperteId(ev.currentTarget.value)}>
              <option value=""/>
              {klassenlehrer.map(lehrer => <option key={lehrer.id} value={lehrer.id}>{`${lehrer.name} (${lehrer.funktion})`}</option>)}
            </select>
          </div>
          }
          {team.id === undefined &&
          <div className="zeile">
            <label>{texte.teamGanzeKlasse}</label>
            <input type="checkbox" checked={ganzeKlasse} onChange={(ev) => setGanzeKlasse(ev.currentTarget.checked)}/>
          </div>
          }
          <div className="zeile">
            <button className="btn btn-default" onClick={() => onClose()}>{texte.abbrechen}</button>
            <div className="pull-right">
              {team.id &&
              <button className="btn btn-danger" onClick={loescheTeam}>{texte.loeschen}</button>
              }
              &nbsp;&nbsp;&nbsp;
              <button className="btn btn-primary" onClick={erstelleTeam}>{texte.speichern}</button>
            </div>
          </div>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    klassenlehrer: state.klassenlehrer,
    programmBlockListe: state.programmBlockListe,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    teamSpeichern: (klassenlehrer, zusatzdaten) => { dispatch(teamSpeichern(klassenlehrer, zusatzdaten)) },
    teamDelete: (team) => { dispatch(teamDelete(team)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KalenderPopup);
