import React, {useState} from 'react';
import {connect} from "react-redux";
import IconToggle from "./IconToggle";
import {ViewKontext} from "../lib/constants";
import {teamTeilnehmerSpeichern} from "../state/teamTeilnehmerReducer";
import {teamZuteilungAbschluss} from "../state/lehrerTeamTeilnehmerReducer";
import {formatiereDatumZeit} from "../lib/formatierungen";
import TeamTeilnehmerAufgabe from "./TeamTeilnehmerAufgabe";

const TeamTeilnehmerAbschluss = ({teamTeilnehmer, ausbildung, teams, texte, klassenlehrerSpeichern, kursleiterSpeichern}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [teilnehmerKommentar, setTeilnehmerKommentar] = useState(teamTeilnehmer.kommentar);
  const istKursleiter = ausbildung.kontext === ViewKontext.Kursleiter;
  const beurteilungAbgeschlossen = teamTeilnehmer.abgeschlossen;
  const team = teams.find(t => t.id === teamTeilnehmer.teamId);

  const speichern = (abschliessen) => {
    const zusatzdaten = {kommentar: teilnehmerKommentar, abschliessen: abschliessen};

    // shared component: unterscheiden vom aktuellen benutzer
    switch (ausbildung.kontext) {
      case ViewKontext.Klassenlehrer:
        klassenlehrerSpeichern(teamTeilnehmer, zusatzdaten);
        break;
      case ViewKontext.Kursleiter:
        kursleiterSpeichern(teamTeilnehmer, zusatzdaten);
        break;
    }
  };

  const abschlussAufheben = () => {
    if (istKursleiter) {
      kursleiterSpeichern(teamTeilnehmer, { abschliessen: false });
    }
  };

  return (
    <div className="block">
      <div className="header flex">
        <span className="spalte">
          <b>{team.bezeichnung}</b>
          &nbsp;<span className="label label-warning">{team.programmBlock.name}</span>
          {beurteilungAbgeschlossen &&
          <>
            &nbsp;<span className={`label label-important`}>{texte.teilnehmerAbschluss.abgeschlossen}</span>
          </>
          }
        </span>
        <span className="spalte">
          <span className="pull-right">
            <button className="btn btn-mini" onClick={() => setShowDetails(!showDetails)}>
              <IconToggle istOffen={showDetails}/>
            </button>
          </span>
          {`${texte.datumVon}: ${formatiereDatumZeit(team.von)}`}
          <br/>
          {`${texte.datumBis}: ${formatiereDatumZeit(team.bis)}`}
        </span>
      </div>
      {showDetails &&
      <div className="content edit">
        {teamTeilnehmer.aufgaben.map(aufgabe => <TeamTeilnehmerAufgabe key={aufgabe.id} aufgabe={aufgabe}/>)}
        <div className="zeile">
          {ausbildung.bearbeitbar &&
          <>
            <label htmlFor={`${teamTeilnehmer.id}-kommentar`}>{texte.teilnehmerAbschluss.kommentar}</label>
            <textarea disabled={beurteilungAbgeschlossen} id={`${teamTeilnehmer.id}-kommentar`} rows="5" className="inline" value={teilnehmerKommentar} onChange={(ev) => setTeilnehmerKommentar(ev.currentTarget.value)}/>
          </>
          }
          {ausbildung.bearbeitbar ||
          <>
            {texte.teilnehmerAbschluss.kommentar}
            <div className="kommentar">{teilnehmerKommentar}</div>
          </>
          }
        </div>
        {ausbildung.bearbeitbar && !beurteilungAbgeschlossen &&
        <div className="zeile">
          <button className="btn btn-primary" onClick={() => speichern(false)}>{texte.speichern}</button>
          <button className="btn btn-success pull-right" onClick={() => speichern(true)}>{texte.abschliessen}</button>
        </div>
        }
        {ausbildung.bearbeitbar && beurteilungAbgeschlossen && istKursleiter && 
        <div className="zeile">
          <button className="btn btn-danger" onClick={abschlussAufheben}>{texte.abschliessenAufheben}</button>
        </div>
        }
      </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ausbildung: state.ausbildung,
    teams: state.teams,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    klassenlehrerSpeichern: (teamTeilnehmer, zusatzdaten) => { dispatch(teamTeilnehmerSpeichern(teamTeilnehmer, zusatzdaten)) },
    kursleiterSpeichern: (teamTeilnehmer, zusatzdaten) => { dispatch(teamZuteilungAbschluss(teamTeilnehmer, zusatzdaten)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamTeilnehmerAbschluss);
