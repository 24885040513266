// @flow

import type {
  AppState,
  EinsatzState,
  TurnierState,
  SpielerState,
  SpielerEintrag,
  TurnierSpielerEintrag,
} from "../../../state/stateTypes";
import type {Dispatch} from "../../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import Textfeld from "../../Textfeld";
import SpielerBadge from "../../SpielerBadge";

const SpielerkarteSpielerReadonly = (props: {
  einsatz: EinsatzState,
  turnier: TurnierState,
  spielerliste: SpielerState,
  pfade: any,
  i18n: any,
  spieler: SpielerEintrag,
  spielerEinsatz: TurnierSpielerEintrag,
}) => {
  const spielerRow = <tr>
    <td style={{whiteSpace: 'nowrap', verticalAlign: 'middle'}}>
      <span className="label label-info">{props.spieler.passnummer}</span>
      {
        props.spielerEinsatz.spielfuehrer ? <span className="label label-important spielfuehrer">C</span> : ''
      }
    </td>
    <td style={{verticalAlign: 'middle'}}>
      <SpielerBadge spieler={props.spieler}/>
    </td>
    <td>
      <div className="spielposition" style={{width: '110px', verticalAlign: 'middle'}}>
        {props.spielerEinsatz.spielposition}
      </div>
    </td>
    <td style={{textAlign: 'right', verticalAlign: 'middle'}}>
      {props.spielerEinsatz.rueckennummer}
    </td>
    <td style={{width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle'}}>
      {props.spieler.tips.t || '-'}
    </td>
    <td style={{width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle'}}>
      {props.spieler.tips.i || '-'}
    </td>
    <td style={{width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle'}}>
      {props.spieler.tips.p || '-'}
    </td>
    <td style={{width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle'}}>
      {props.spieler.tips.s || '-'}
    </td>
    <td style={{width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle'}}>
      <span className="durchschnitt">
        {props.spieler.tips.noteDurchschnitt ? props.spieler.tips.noteDurchschnitt.toFixed(1) : ''}
      </span>
    </td>
    <td style={{width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle'}}>
      {props.spieler.tips.entwicklung || '-'}
    </td>
    <td style={{width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle'}}>
      {props.spieler.tips.note || '-'}
    </td>
    <td colSpan={2} style={{textAlign: 'center', verticalAlign: 'middle'}}>
      &nbsp;
    </td>
  </tr>;

  const kommentarAnzeigen = (props.spieler.tips.kommentar || '').length > 0;
  if (kommentarAnzeigen) {
    return (
      <tbody>
      {spielerRow}
      <tr>
        <td colSpan="4">&nbsp;</td>
        <td colSpan="9">
          <Textfeld
            feldName="tipsKommentar"
            feldWert={props.spieler.tips.kommentar || ''}
            requestDaten={{}}
            breite='388px'
            forceReadonly={true}
          />
        </td>
      </tr>
      </tbody>
    );
  } else {
    return (
      <tbody>
      {spielerRow}
      </tbody>
    );
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    turnier: state.turnier,
    spielerliste: state.spielerliste,
    pfade: state.pfade,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpielerkarteSpielerReadonly);
