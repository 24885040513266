import {executeRequest} from "../../lib/fetchHelper";
import {rechnungenSetzen, rechnungHinzufuegen} from "./rechnungen";
import {rechnungslaufSetzen} from "./rechnungslauf";
import {endeRequest, requestTyp, startRequest} from "./laufendeAbfragen";

const BEXIO_STATUS_SETZEN = 'BEXIO_STATUS_SETZEN';
const BEXIO_STATUS_PERSONEN_SETZEN = 'BEXIO_STATUS_PERSONEN_SETZEN';
const BEXIO_STATUS_KONTEN_SETZEN = 'BEXIO_STATUS_KONTEN_SETZEN';
const BEXIO_STATUS_RECHNUNGEN_SETZEN = 'BEXIO_STATUS_RECHNUNGEN_SETZEN';
const BEXIO_STATUS_RECHNUNGSSTATUS_SETZEN = 'BEXIO_STATUS_RECHNUNGSSTATUS_SETZEN';
const BEXIO_STATUS_RESOURCEN_FEHLER = 'BEXIO_STATUS_RESOURCEN_FEHLER';

export function bexioStatusAbfragen(personen, konten, rechnungen, rechnungenStatus) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;

    personen = personen && !state.laufendeRequests[requestTyp.bexioCheckPersonen];
    konten = konten && !state.laufendeRequests[requestTyp.bexioCheckKonten];
    rechnungen = rechnungen && !state.laufendeRequests[requestTyp.bexioCheckRechnungen];
    rechnungenStatus = rechnungenStatus && !state.laufendeRequests[requestTyp.bexioCheckRechnungenStatus];

    if (!(personen || konten || rechnungen || rechnungenStatus)) {
      return; // abbrechen, da nach abgleich mit den laufenden requests keiner zum abfragen übrig ist
    }

    const daten = {
      rechnungslaufId: state.rechnungslauf.id,
      checkPersonen: personen,
      checkKonten: konten,
      checkRechnungen: rechnungen,
      checkRechnungenStatus: rechnungenStatus
    };
    // status aktualisieren, da nun abgefragt wird
    const bexioStatus = state.bexio.status;
    const status = {
      ...bexioStatus,
      personen: {...bexioStatus.personen, aktiv: bexioStatus.personen.aktiv || personen},
      konten: {...bexioStatus.konten, aktiv: bexioStatus.konten.aktiv || konten},
      rechnungen: {...bexioStatus.rechnungen, aktiv: bexioStatus.rechnungen.aktiv || rechnungen},
      rechnungenStatus: {...bexioStatus.rechnungenStatus, aktiv: bexioStatus.rechnungenStatus.aktiv || rechnungenStatus},
    };
    dispatch({type: BEXIO_STATUS_SETZEN, status: status});

    // request-typen als laufend markieren -> damit nicht erneut gestartet wird wenn der vorherige request nicht beendet ist (-> bexio component benutzt setIntervall, fragt also einfach ab)
    if (personen) { dispatch(startRequest(requestTyp.bexioCheckPersonen)); }
    if (konten) { dispatch(startRequest(requestTyp.bexioCheckKonten)); }
    if (rechnungen) { dispatch(startRequest(requestTyp.bexioCheckRechnungen)); }
    if (rechnungenStatus) { dispatch(startRequest(requestTyp.bexioCheckRechnungenStatus)); }

    return executeRequest(api.bexio, api.token, 'GET', daten)
      .then(result => {
        dispatch(bexioAlleStatusSetzen(result.data));

        // wenn delayed-job beendet, sendet er auch die aktualisierten daten:
        if (result.data.rechnungslaufAktualisieren) {
          dispatch(rechnungslaufSetzen(result.data.rechnungslaufAktualisieren));
        }
        if (result.data.rechnungenAktualisieren) {
          dispatch(rechnungenSetzen(result.data.rechnungenAktualisieren));
        }

        // request-typen als beendet markieren
        if (personen) { dispatch(endeRequest(requestTyp.bexioCheckPersonen)); }
        if (konten) { dispatch(endeRequest(requestTyp.bexioCheckKonten)); }
        if (rechnungen) { dispatch(endeRequest(requestTyp.bexioCheckRechnungen)); }
        if (rechnungenStatus) { dispatch(endeRequest(requestTyp.bexioCheckRechnungenStatus)); }
      }).catch(error => {
        // ajax-aktiv anzeige beenden
        dispatch(endeRequest(requestTyp.bexioCheckPersonen));
        dispatch(endeRequest(requestTyp.bexioCheckKonten));
        dispatch(endeRequest(requestTyp.bexioCheckRechnungen));
        dispatch(endeRequest(requestTyp.bexioCheckRechnungenStatus));
      });
  };
}

export function bexioRechnungenTotalSetzen() {
  return (dispatch, getState) => {
    const state = getState();
    const statusRechnungen = state.bexio.status.rechnungen;
    const rechnungen = {
      ...statusRechnungen,
      total: state.rechnungen.length,
      transferiert: Math.min(state.rechnungen.length, statusRechnungen.transferiert)
    };
    dispatch({type: BEXIO_STATUS_RECHNUNGEN_SETZEN, rechnungen: rechnungen});
  }
}

export function bexioRechnungenStatusAbfragen() {
  return (dispatch, getState) => {
    const state = getState();

    // sync-status sofort setzen, damit buttons disabled werden
    dispatch({type: BEXIO_STATUS_RECHNUNGSSTATUS_SETZEN, rechnungenStatus: {...state.bexio.status.rechnungenStatus, aktiv: true}});

    const api = state.api;
    const daten = {
      rechnungslaufId: state.rechnungslauf.id,
      typ: 'rechnungsstatus'
    };
    return executeRequest(api.bexio, api.token, 'POST', daten)
      .then(result => {
        dispatch(bexioAlleStatusSetzen(result.data));
      });
  };
}

export function bexioTransferStarten() {
  return (dispatch, getState) => {
    const state = getState();

    // sync-status sofort setzen, damit buttons disabled werden
    dispatch({type: BEXIO_STATUS_RECHNUNGEN_SETZEN, rechnungen: {...state.bexio.status.rechnungen, aktiv: true}});

    const api = state.api;
    const daten = {
      rechnungslaufId: state.rechnungslauf.id,
      typ: 'rechnungslauf'
    };
    return executeRequest(api.bexio, api.token, 'POST', daten)
      .then(result => {
        dispatch(bexioAlleStatusSetzen(result.data));
      });
  };
}

export function bexioPersonenSynchronisieren() {
  return (dispatch, getState) => {
    const state = getState();

    // sync-status sofort setzen, damit buttons disabled werden
    dispatch({type: BEXIO_STATUS_PERSONEN_SETZEN, personen: {...state.bexio.status.personen, aktiv: true}});

    const api = state.api;
    const daten = {
      rechnungslaufId: state.rechnungslauf.id,
      typ: 'personen'
    };
    return executeRequest(api.bexio, api.token, 'POST', daten)
      .then(result => {
        dispatch(bexioAlleStatusSetzen(result.data));
      });
  };
}

export function bexioRechnungTransferStarten(rechnung) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const daten = {
      rechnungslaufId: state.rechnungslauf.id,
      rechnungId: rechnung.id,
      typ: 'transfer'
    };
    return executeRequest(api.bexio, api.token, 'PUT', daten)
      .then(result => {
        if (result.ok) {
          if (result.data.rechnung) {
            dispatch(rechnungHinzufuegen(result.data.rechnung));
          }
          dispatch(bexioAlleStatusSetzen(result.data));
        }
      });
  };
}

export function bexioTransferEntfernen() {
  return (dispatch, getState) => {
    const state = getState();

    // sync-status sofort setzen, damit buttons disabled werden
    dispatch({type: BEXIO_STATUS_RECHNUNGEN_SETZEN, rechnungen: {...state.bexio.status.rechnungen, aktiv: true}});

    const api = state.api;
    const daten = {
      rechnungslaufId: state.rechnungslauf.id,
    };
    executeRequest(api.bexio, api.token, 'DELETE', daten)
      .then(result => {
        dispatch(bexioAlleStatusSetzen(result.data));
      });
  };
}

export function bexioAlleStatusSetzen(data) {
  return (dispatch) => {
    if (data.personen) {
      dispatch({type: BEXIO_STATUS_PERSONEN_SETZEN, personen: data.personen});
    }
    if (data.konten) {
      dispatch({type: BEXIO_STATUS_KONTEN_SETZEN, konten: data.konten});
    }
    if (data.rechnungen) {
      dispatch({type: BEXIO_STATUS_RECHNUNGEN_SETZEN, rechnungen: data.rechnungen});
    }
    if (data.rechnungenStatus) {
      dispatch({type: BEXIO_STATUS_RECHNUNGSSTATUS_SETZEN, rechnungenStatus: data.rechnungenStatus});
    }
    if (data.resourcenFehler) {
      dispatch({type: BEXIO_STATUS_RESOURCEN_FEHLER, resourcenFehler: data.resourcenFehler});
    }
  };
}

function bexioReducer(state = {}, action) {
  switch (action.type) {
    case BEXIO_STATUS_SETZEN:
      return {
        ...state,
        status: action.status
      };
    case BEXIO_STATUS_PERSONEN_SETZEN:
      return {
        ...state,
        status: {
          ...state.status,
          personen: action.personen
        }
      };
    case BEXIO_STATUS_KONTEN_SETZEN:
      return {
        ...state,
        status: {
          ...state.status,
          konten: action.konten
        }
      };
    case BEXIO_STATUS_RECHNUNGEN_SETZEN:
      return {
        ...state,
        status: {
          ...state.status,
          rechnungen: action.rechnungen
        }
      };
    case BEXIO_STATUS_RECHNUNGSSTATUS_SETZEN:
      return {
        ...state,
        status: {
          ...state.status,
          rechnungenStatus: action.rechnungenStatus
        }
      };
    case BEXIO_STATUS_RESOURCEN_FEHLER:
      return {
        ...state,
        resourcenFehler: action.resourcenFehler
      };
    default:
      return state;
  }
}

export default bexioReducer;
