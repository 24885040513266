// @flow

import type {AppState, EinsatzState, TeamState, TurnierState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";
import TextfeldZeile from "../TextfeldZeile";
import NoteDropdown from "../NoteDropdown";

import {turnierTeamNoteSetzen, turnierTeamKommentarSetzen, turnierTeamBemerkungenSetzen} from "../../state/modules/turnier";

const Turniersnoten = (props: {
  einsatz: EinsatzState,
  turnier: TurnierState,
  teams: TeamState,
  i18n: any,
  turnierTeamNoteSetzen: ((string) => void),
  turnierTeamKommentarSetzen: ((string) => void),
  turnierTeamBemerkungenSetzen: ((string) => void)
}) => {
  return (
    <table className="table table-striped">
      <tbody>
      <tr className="control-group">
        <th>
          <label className="control-label" htmlFor="turnierTeamNote" style={{width: '170px'}}>
            {props.i18n.felder.turnierTeamNote}
          </label>
        </th>
        <td>
          <div className="controls">
            <NoteDropdown feldName="turnierTeamNote" feldWert={props.turnier.turnierTeamNote}
                          requestDaten={{turnierId: props.turnier.id}} onValueChange={(neuerWert) => {
              props.turnierTeamNoteSetzen(neuerWert);
            }}/>
          </div>
        </td>
      </tr>
      <TextfeldZeile feldTitel={props.i18n.felder.turnierTeamKommentar} feldName="turnierTeamKommentar" feldWert={props.turnier.turnierTeamKommentar}
                     requestDaten={{turnierId: props.turnier.id}} onChange={(event) => {
        props.turnierTeamKommentarSetzen(event.target.value);
      }}/>
      <TextfeldZeile feldTitel={props.i18n.felder.turnierTeamBemerkungen} feldName="turnierTeamBemerkungen" feldWert={props.turnier.turnierTeamBemerkungen}
                     requestDaten={{turnierId: props.turnier.id}} onChange={(event) => {
        props.turnierTeamBemerkungenSetzen(event.target.value);
      }}/>
      </tbody>
    </table>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    turnier: state.turnier,
    teams: state.teams,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    turnierTeamNoteSetzen: (neuerWert: string) => {
      dispatch(turnierTeamNoteSetzen(neuerWert));
    },
    turnierTeamKommentarSetzen: (neuerWert: string) => {
      dispatch(turnierTeamKommentarSetzen(neuerWert));
    },
    turnierTeamBemerkungenSetzen: (neuerWert: string) => {
      dispatch(turnierTeamBemerkungenSetzen(neuerWert));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Turniersnoten);
