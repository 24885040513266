// @flow

import type {AppState, NavigationState, TeamState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import {aktivesAufstellungTeamSetzen} from "../../state/modules/navigation";

const TeamSelektion = (props: {
  navigation: NavigationState,
  teams: TeamState,
  i18n: any,
  onTeamTabKlick: (string) => void,
}) => {
  if (props.teams.heimTeam && props.teams.gastTeam) {
    if (props.navigation.aktivesAufstellungTeam === 'heim') {
      return (
        <ul className="nav nav-tabs">
          <li className="active" style={{cursor: "pointer"}}>
            <a>
              <img src={props.teams.heimTeam.logo} style={{width: '20px'}} alt={props.teams.heimTeam.id}/>&nbsp;&nbsp;
              {props.i18n.subtitel.heimTeam}
            </a>
          </li>
          <li style={{cursor: "pointer"}}>
            <a onClick={() => props.onTeamTabKlick('gast')}>
              <img src={props.teams.gastTeam.logo} style={{width: '20px'}} alt={props.teams.gastTeam.id}/>&nbsp;&nbsp;
              {props.i18n.subtitel.gastTeam}
            </a>
          </li>
        </ul>
      );
    } else {
      return (
        <ul className="nav nav-tabs">
          <li style={{cursor: "pointer"}}>
            <a onClick={() => props.onTeamTabKlick('heim')}>
              <img src={props.teams.heimTeam.logo} style={{width: '20px'}} alt={props.teams.heimTeam.id}/>&nbsp;&nbsp;
              {props.i18n.subtitel.heimTeam}
            </a>
          </li>
          <li className="active" style={{cursor: "pointer"}}>
            <a>
              <img src={props.teams.gastTeam.logo} style={{width: '20px'}} alt={props.teams.gastTeam.id}/>&nbsp;&nbsp;
              {props.i18n.subtitel.gastTeam}
            </a>
          </li>
        </ul>
      );
    }
  } else {
    return <></>;
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    navigation: state.navigation,
    teams: state.teams,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onTeamTabKlick: (aktivesAufstellungTeam: 'heim' | 'gast') => {
      dispatch(aktivesAufstellungTeamSetzen(aktivesAufstellungTeam));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamSelektion);
