// @flow

import fetch from 'cross-fetch';
import type {AjaxState, ServerDaten, TrainingState} from "../stateTypes";
import type {Action, Dispatch, GetState} from "../actionTypes";
import {apiFehlerAnzeigen, apiServerFehlerBehandeln} from "./navigation";
import {einsatzReadonlySetzen} from "./einsatz";

var timeouts = {};

export function mitTimeout(timeoutKey: string, callback: (void) => void) {
  if (timeouts[timeoutKey] != null) {
    clearTimeout(timeouts[timeoutKey]);
    timeouts[timeoutKey] = null;
  }
  timeouts[timeoutKey] = setTimeout(() => {
    callback();
  }, 300);
}

function getMeta(metaName: string): string {
  const metas = document.getElementsByTagName('meta');
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaName) {
      return metas[i].getAttribute('content') || '';
    }
  }
  return '';
}

export function apiRequestAbschicken(apiUrl: string,
                                     apiMethode: string,
                                     feldName: string | null,
                                     neuerWert: string | number | {} | null,
                                     requestDaten: {} | null,
                                     callback: null | (Dispatch, any) => void) {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch(spinnerAnzeigen());
    const csrfToken = getMeta("csrf-token");
    const apiToken = getState().pfade.api.token;
    if (requestDaten === null) {
      requestDaten = {};
    }
    if (feldName !== null) {
      requestDaten[feldName] = neuerWert;
    }
    const body = apiMethode === 'GET' ? null : JSON.stringify(requestDaten);
    fetch(apiUrl, {
      method: apiMethode,
      headers: {
        'x-requested-with': 'XMLHttpRequest',
        'x-csrf-token': csrfToken,
        'x-auth-token': apiToken,
        'content-type': 'application/json',
      },
      credentials: 'same-origin',
      body: body,
    })
      .then(resultat => {
        dispatch(spinnerVerstecken());
        if ((resultat.status < 200 || resultat.status >= 300) && resultat.status !== 422) {
          dispatch(apiServerFehlerBehandeln(resultat.status));
          if (apiUrl !== getState().pfade.api.datenReload) {
            dispatch(alleDatenNeuLaden());
          }
        } else {
          resultat.json().then(resultatJson => {
            if (resultatJson.fehler) {
              dispatch(apiFehlerAnzeigen(resultatJson.fehler));
              if (apiUrl !== getState().pfade.api.datenReload) {
                dispatch(alleDatenNeuLaden());
              }
            } else {
              if (callback) {
                callback(dispatch, resultatJson);
              }
            }
          })
        }
      })
      .catch((fehler: mixed) => {
        dispatch(spinnerVerstecken());
        dispatch(apiServerFehlerBehandeln(fehler));
      });
  }
}

export function alleDatenNeuLaden() {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch(einsatzReadonlySetzen());
    apiRequestAbschicken(
      getState().pfade.api.datenReload,
      'GET',
      null,
      null,
      null,
      (dispatch: Dispatch, serverDaten: ServerDaten) => {
        dispatch(einsatzDatenZuruecksetzen(serverDaten));
        if (serverDaten.spiel) {
          dispatch(spielDatenZuruecksetzen(serverDaten));
          dispatch(protokollDatenZuruecksetzen(serverDaten));
        }
        if (serverDaten.training) {
          dispatch(trainingDatenZuruecksetzen(serverDaten));
        }
        if (serverDaten.turnier) {
          dispatch(turnierDatenZuruecksetzen(serverDaten));
        }
        dispatch(teamDatenZuruecksetzen(serverDaten));
        dispatch(spielerDatenZuruecksetzen(serverDaten));
      }
    )(dispatch, getState);
  };
}

export function einsatzDatenZuruecksetzen(serverDaten: ServerDaten) {
  return {type: "AJAX_EINSATZ_DATEN_ZURUECKSETZEN", serverDaten};
}

export function spielDatenZuruecksetzen(serverDaten: ServerDaten) {
  return {type: "AJAX_SPIEL_DATEN_ZURUECKSETZEN", serverDaten};
}

export function trainingDatenZuruecksetzen(serverDaten: ServerDaten) {
  return {type: "AJAX_TRAINING_DATEN_ZURUECKSETZEN", serverDaten};
}

export function turnierDatenZuruecksetzen(serverDaten: ServerDaten) {
  return {type: "AJAX_TURNIER_DATEN_ZURUECKSETZEN", serverDaten};
}

export function teamDatenZuruecksetzen(serverDaten: ServerDaten) {
  return {type: "AJAX_TEAM_DATEN_ZURUECKSETZEN", serverDaten};
}

export function protokollDatenZuruecksetzen(serverDaten: ServerDaten) {
  return {type: "AJAX_PROTOKOLL_DATEN_ZURUECKSETZEN", serverDaten};
}

export function spielerDatenZuruecksetzen(serverDaten: ServerDaten) {
  return {type: "AJAX_SPIELER_DATEN_ZURUECKSETZEN", serverDaten};
}

export function spinnerAnzeigen() {
  return {type: "AJAX_SPINNER_ANZEIGEN"};
}

export function spinnerVerstecken() {
  return {type: "AJAX_SPINNER_VERSTECKEN"};
}

function ajaxReducer(state: AjaxState, action: Action): AjaxState {
  switch (action.type) {
    case "AJAX_SPINNER_ANZEIGEN":
      return {
        ...state,
        spinnerCount: state.spinnerCount + 1,
      };
    case "AJAX_SPINNER_VERSTECKEN":
      return {
        ...state,
        spinnerCount: state.spinnerCount - 1 < 0 ? 0 : state.spinnerCount - 1,
      };
    default:
      return state || {spinnerCount: 0};
  }
}

export default ajaxReducer
