// @flow

import type {AppState, ProtokollEintrag, SpielerEintrag, SpielerkarteEintrag, SpielState} from "../stateTypes";
import type {Action, ActionThunk, Dispatch, GetState} from "../actionTypes";
import {spielDatenLaden} from "../datenLaden";

export function spielKommentarSetzen(wert: string): Action {
  return {type: "SPIEL_KOMMENTAR_SETZEN", wert};
}
export function generellesSpielniveauSetzen(wert: number): Action {
  return {type: "SPIEL_GENERELLES_SPIELNIVEAU_SETZEN", wert};
}
export function intensitaetSpielrhythmusSetzen(wert: number): Action {
  return {type: "SPIEL_INTENSITAET_SPIELRHYTHMUS_SETZEN", wert};
}

export function spielstandAusProtokollHolen(): ActionThunk {
  return (dispatch: Dispatch, getState: GetState) => {
    const protokoll = getState().protokoll;
    if (protokoll !== null) {
      dispatch({
        type: "SPIEL_SPIELSTAND_AUS_PROTOKOLL_HOLEN",
        spielstandHeim: protokoll.spielstandHeim,
        spielstandGast: protokoll.spielstandGast
      });
    }
  }
}

export function spielerkarteEintragFuer(state: AppState, spieler: SpielerEintrag): SpielerkarteEintrag | null {
  let spielerkarteEintrag: SpielerkarteEintrag | null = null;
  if (state.spiel) {
    [
      state.spiel.spielerkarten.heimTeam.startformation,
      state.spiel.spielerkarten.heimTeam.ersatzformation,
      state.spiel.spielerkarten.gastTeam.startformation,
      state.spiel.spielerkarten.gastTeam.ersatzformation,
    ].forEach((spielerkarteEintragListe: SpielerkarteEintrag[]) => {
      spielerkarteEintragListe.forEach((moeglicherEintrag: SpielerkarteEintrag) => {
        if (moeglicherEintrag.spielerId === spieler.id && moeglicherEintrag.nichtregistriert === spieler.nichtregistriert) {
          spielerkarteEintrag = moeglicherEintrag;
        }
      });
    });
  }
  return spielerkarteEintrag;
}

const eintragWertAktualisieren = (liste: ProtokollEintrag[], eintragKey: string, wertName: string, neuerWert: string | number | boolean | null, istZahl: boolean, defaultWert: string | number | null) => {
  liste = liste.map<ProtokollEintrag>((eintrag) => {
    if (eintrag.key === eintragKey) {
      let neuerEintrag = {...eintrag};
      neuerEintrag[wertName] = istZahl ? (parseInt(neuerWert, 10) || defaultWert) : neuerWert;
      return neuerEintrag;
    } else {
      return eintrag;
    }
  });
  return liste;
};

function spielReducer(state: SpielState, action: Action): SpielState | null {
  switch (action.type) {
    case "SPIEL_KOMMENTAR_SETZEN":
      return {
        ...state,
        spielKommentar: action.wert,
      };
    case "SPIEL_GENERELLES_SPIELNIVEAU_SETZEN":
      return {
        ...state,
        generellesSpielniveau: action.wert,
      };
    case "SPIEL_INTENSITAET_SPIELRHYTHMUS_SETZEN":
      return {
        ...state,
        intensitaetSpielrhythmus: action.wert,
      };
    case "SPIEL_SPIELSTAND_AUS_PROTOKOLL_HOLEN":
      if (action.spielstandHeim || action.spielstandGast) {
        return {
          ...state,
          spielstandHeim: action.spielstandHeim || 0,
          spielstandGast: action.spielstandGast || 0,
        };
      } else {
        return {
          ...state,
          spielstandHeim: state.serverSpielstandHeim,
          spielstandGast: state.serverSpielstandGast,
        };
      }
    case "AJAX_SPIEL_DATEN_ZURUECKSETZEN":
      return spielDatenLaden(action.serverDaten);
    case "PROTOKOLL_VIDEO_ANZEIGEN":
      return {
        ...state,
        spielverlauf: eintragWertAktualisieren(state.spielverlauf, action.eintragKey, "videoAnzeigen", true, false, null),
      };
    case "PROTOKOLL_VIDEO_VERSTECKEN":
      return {
        ...state,
        spielverlauf: eintragWertAktualisieren(state.spielverlauf, action.eintragKey, "videoAnzeigen", false, false, null),
      };
    default:
      return state || null;
  }
}

export default spielReducer
