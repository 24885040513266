// @flow

import type {AppState, TeamState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import Gesamtnoten from "./Gesamtnoten";
import Aktionen from "./Aktionen";

const Spiel = (props: { teams: TeamState, i18n: any }) => {
  return (
    <div className="tab-pane">
      <Gesamtnoten/>
      <Aktionen/>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    teams: state.teams,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Spiel);
