import {statusSpielgruppenClear} from "./status";

const SPIELGRUPPE_RANGLISTE = 'SPIELGRUPPE_RANGLISTE';
const SPIELGRUPPE_SETZEN = 'SPIELGRUPPE_SETZEN';

export function spielgruppenSortieren(a, b) {
  if (a.reihenfolge === b.reihenfolge) {
    let textA = a.spielklasse;
    if (a.bezeichnung) {
      textA = `${a.bezeichnung} - ${a.spielklasse}`;
    }
    let textB = b.spielklasse;
    if (b.bezeichnung) {
      textB = `${b.bezeichnung} - ${b.spielklasse}`;
    }
    return textA.localeCompare(textB);
  }
  return a.reihenfolge - b.reihenfolge;
}

export function spielgruppeRanglisteSetzen(spielgruppe, rangliste) {
  return (dispatch) => {
    dispatch({type: SPIELGRUPPE_RANGLISTE, spielgruppeId: spielgruppe.id, rangliste: rangliste });
  }
}

export function spielgruppeSetzen(spielgruppen) {
  return (dispatch) => {
    dispatch(statusSpielgruppenClear());
    dispatch({type: SPIELGRUPPE_SETZEN, spielgruppen: spielgruppen });
  }
}

function spielgruppenReducer(state = [], action) {
  switch (action.type) {
    case SPIELGRUPPE_RANGLISTE:
      const spielgruppe = state.find(sg => sg.id === action.spielgruppeId);
      if (spielgruppe) {
        spielgruppe.rangliste = action.rangliste;
        return state
          .filter(sg => sg.id !== spielgruppe.id)
          .concat([spielgruppe])
          .sort(spielgruppenSortieren);
      } else {
        return state;
      }
    case SPIELGRUPPE_SETZEN:
      return action.spielgruppen.sort(spielgruppenSortieren);
    default:
      return state;
  }
}

export default spielgruppenReducer;
