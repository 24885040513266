import {apiRequestAktiv} from "./api";
import {ersetzeStringMitDatum} from "../../../../lib/datenhaltung";
import {executeGet, executeJson} from "../../../../lib/fetchWrapper";
import {selektiertBewertung} from "./selektiert";

const BEWERTUNGEN_INDEX = 'BEWERTUNGEN_INDEX';
const BEWERTUNGEN_UPDATE = 'BEWERTUNGEN_UPDATE';
const BEWERTUNGEN_DELETE = 'BEWERTUNGEN_DELETE';

export function bewertungenAlleLaden(von = undefined, bis = undefined) {
  return (dispatch, getState) => {
    dispatch(apiRequestAktiv(true));
    const state = getState();
    const daten = { spieler: state.spielerliste.map(s => s.id) };
    if (von) { daten.von = von.toString('yyyy-MM-dd'); }
    if (bis) { daten.bis = bis.toString('yyyy-MM-dd'); }
    return executeGet(state.api.bewertungen, daten)
      .then(result => {
        dispatch({type: BEWERTUNGEN_INDEX, liste: result.data.liste});
      }).finally(() => {
        dispatch(apiRequestAktiv(false));
      });
  };
}

export function bewertungenErstellen(spieler) {
  return (dispatch, getState) => {
    const api = getState().api;

    const daten = { spieler_id: spieler.id };
    if (spieler.auswahlspieler_id > 0) {
      daten.auswahlspieler_id = spieler.auswahlspieler_id;
    }

    return executeJson(`${api.bewertungen}`, api.token, 'POST', daten)
      .then(result => {
        dispatch({type: BEWERTUNGEN_UPDATE, bewertung: result.data.bewertung});
        dispatch(selektiertBewertung(result.data.bewertung));
      });
  };
}

export function bewertungenSpeichern(daten) {
  return (dispatch, getState) => {
    const api = getState().api;
    return executeJson(`${api.bewertungen}/${daten.bewertung.id}`, api.token, 'PUT', daten)
      .then(result => {
        if (result.ok) {
          dispatch({type: BEWERTUNGEN_UPDATE, bewertung: result.data.bewertung});
          dispatch(selektiertBewertung(result.data.bewertung));
        } else if (result.status === 404) {
          dispatch({type: BEWERTUNGEN_DELETE, bewertung: bewertung});
          dispatch(selektiertBewertung(null));
        }
      });
  };
}

export function bewertungenSpielerLoeschen(bewertung) {
  return (dispatch, getState) => {
    const api = getState().api;
    return executeJson(`${api.bewertungen}/${bewertung.id}`, api.token, 'DELETE')
      .then(result => {
        if (result.ok || result.status === 404) {
          dispatch({type: BEWERTUNGEN_DELETE, bewertung: bewertung});
          dispatch(selektiertBewertung(null));
        }
      });
  };
}

function sortiereBewertungen(a, b) {
  return b.datum_zeit - a.datum_zeit;
}

function bewertungen(state = [], action) {
  switch (action.type) {
    case BEWERTUNGEN_INDEX:
      return action.liste.map(model => ersetzeStringMitDatum(model, ['datum_zeit', 'datum_abgeschlossen'])); // daten komplett überschreiben
    case BEWERTUNGEN_UPDATE:
      return [
        ...(state.filter(b => b.id !== action.bewertung.id)),
        ersetzeStringMitDatum(action.bewertung, ['datum_zeit', 'datum_abgeschlossen'])
      ].sort(sortiereBewertungen);
    case BEWERTUNGEN_DELETE:
      return state.filter(b => b.id !== action.bewertung.id);
    default:
      return state;
  }
}

export default bewertungen;
