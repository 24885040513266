// @flow

import type {AppState, ProtokollEintrag, SpielerEintrag, SpielerkarteEintrag, SpielerState, SpielState} from "../../../state/stateTypes";
import type {Dispatch} from "../../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

let formation = (einsaetze: SpielerkarteEintrag[], spielerliste: SpielerEintrag[]) => {
  return einsaetze.map((einsatz: SpielerkarteEintrag) => {
    let einsatzSpieler: SpielerEintrag | void = spielerliste.find((spieler: SpielerEintrag) => {
      return spieler.id === einsatz.spielerId && spieler.nichtregistriert === einsatz.nichtregistriert;
    });
    if (einsatzSpieler && !einsatz.nichtregistriert) {
      return <option key={einsatz.id} value={einsatzSpieler.id}>{einsatz.rueckennummer} | {einsatzSpieler.name}</option>;
    } else {
      return <></>
    }
  });
};

const TeamSpieler = (props: {
  spiel: SpielState,
  spielerliste: SpielerState,
  i18n: any,
  aktiv: boolean,
  eintrag: ProtokollEintrag,
  auswechselSpieler: boolean,
  spielerChange: (any) => void,
}) => {
  const selectedId = (props.auswechselSpieler ? props.eintrag.auswechselSpielerId : props.eintrag.spielerId) || '';

  if (props.eintrag.heimTeam && !props.eintrag.gastTeam) {
    return (
      <div style={{margin: '0 10px 0 0'}}>
        <select style={{width: '200px'}} value={selectedId} onChange={props.spielerChange} disabled={props.aktiv ? null : "disabled"}>
          <option value="">&nbsp;</option>
          <optgroup label={props.i18n.subtitel.startformation}/>
          {formation(props.spiel.spielerkarten.heimTeam.startformation, props.spielerliste.liste)}
          <optgroup label={props.i18n.subtitel.auswechselspieler}/>
          {formation(props.spiel.spielerkarten.heimTeam.ersatzformation, props.spielerliste.liste)}
        </select>
      </div>
    );
  } else if (props.eintrag.gastTeam && !props.eintrag.heimTeam) {
    return (
      <div style={{margin: '0 10px 0 0'}}>
        <select style={{width: '200px'}} value={selectedId} onChange={props.spielerChange} disabled={props.aktiv ? null : "disabled"}>
          <option value="">&nbsp;</option>
          <optgroup label={props.i18n.subtitel.startformation}/>
          {formation(props.spiel.spielerkarten.gastTeam.startformation, props.spielerliste.liste)}
          <optgroup label={props.i18n.subtitel.auswechselspieler}/>
          {formation(props.spiel.spielerkarten.gastTeam.ersatzformation, props.spielerliste.liste)}
        </select>
      </div>
    );
  } else {
    return (
      <div style={{margin: '0 10px 0 0'}}>
        <select style={{width: '200px'}} value={selectedId} onChange={props.spielerChange} disabled={props.aktiv ? null : "disabled"}>
          <option value="">&nbsp;</option>
          <optgroup label={props.i18n.subtitel.heimTeam}/>
          {formation(props.spiel.spielerkarten.heimTeam.startformation, props.spielerliste.liste)}
          {formation(props.spiel.spielerkarten.heimTeam.ersatzformation, props.spielerliste.liste)}
          <optgroup label={props.i18n.subtitel.gastTeam}/>
          {formation(props.spiel.spielerkarten.gastTeam.startformation, props.spielerliste.liste)}
          {formation(props.spiel.spielerkarten.gastTeam.ersatzformation, props.spielerliste.liste)}
        </select>
      </div>
    );
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    spiel: state.spiel,
    spielerliste: state.spielerliste,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamSpieler);
