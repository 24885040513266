import React, {useState} from "react";
import {formatiereDatum} from "../lib/formatierungen";
import Tooltip, {POSITION} from "./Tooltip";
import Bearbeiten from "./Person/Bearbeiten";
import {ladeLocale} from "../lib/datenhaltung";

const PersonBadgeTexte = {
  de: {
    kontaktSpeichern: 'Kontakt speichern'
  },
  fr: {
    kontaktSpeichern: 'Enregistrer le contact'
  },
  it: {
    kontaktSpeichern: 'Registrare il contatto'
  }
};

const PersonBadge = ({person, personVerlinken = true, showVcf = true, fotoTooltipAktiv = true, className = '', ...props}) => {
  const classNames = ["person-badge", className].join(" ");
  const [showEdit, setShowEdit] = useState(false);
  const [locale, _setLocale] = useState(ladeLocale());
  const texte = PersonBadgeTexte[locale];

  const renderPersonName = () => {
    if (personVerlinken && person.editUrl) {
      return <a href={person.editUrl} className="abschneiden" onClick={(event) => {
        event.preventDefault();
        setShowEdit(true);
      }}>{person.name}</a>;
    }

    return <span className="abschneiden">{person.name}</span>;
  };

  const renderContent = () => {
    return (
      <>
        <div className="avatar">
          <img src={person.fotoUrl}/>
        </div>
        <div className="text">
          <div className="title">
            {showVcf && person.vcfUrl &&
            <Tooltip content={texte.kontaktSpeichern} tagName="a" href={person.vcfUrl} className="vcf">
              <i className="icon-download"/>
            </Tooltip>
            }
            {renderPersonName()}
          </div>
          {person.geburtsdatum &&
          <div className="subtitle">
            <i className="icon-calendar"/> {formatiereDatum(person.geburtsdatum)}
          </div>
          }
        </div>
      </>
    );
  };

  let personBadgeComponent = <></>;
  if (fotoTooltipAktiv && person.hatFoto) {
    personBadgeComponent = <Tooltip content={<img src={person.fotoUrl}/>} position={POSITION.left}>
      <div className={classNames} {...props}>{renderContent()}</div>
    </Tooltip>;
  } else {
    personBadgeComponent = <div className={classNames} {...props}>{renderContent()}</div>;
  }

  return (
    <>
      {personBadgeComponent}
      {showEdit &&
      <Bearbeiten person={person} onClose={() => setShowEdit(false)}/>
      }
    </>
  );
};

export default PersonBadge;
