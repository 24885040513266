// @flow

import type {AppState, SpielerState, SpielerkarteEintrag} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

const Spielposition = (props: {
  children: any,
  spielerliste: SpielerState,
  pfade: any,
  i18n: any,
  einsatz: SpielerkarteEintrag,
}) => {
  const spieler = props.spielerliste.liste.find((s) => s.id === props.einsatz.spielerId && s.nichtregistriert === props.einsatz.nichtregistriert);
  let style = {};

  if (props.einsatz.spielposition.zeile && props.einsatz.spielposition.spalte) {
    let grid = {
      zeile: 70 / 24 * (25 - props.einsatz.spielposition.zeile),
      spalte: 50 / 19 * (props.einsatz.spielposition.spalte + 4),
    };
    style = {
      top: `${grid.zeile}%`,
      left: `${grid.spalte}%`,
    };
  }
  return (
    <div className={"spielposition besetzt"} style={style}>
      <div className={"bezeichnung"}>{props.einsatz.spielposition.name}</div>
      {props.children}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    spielerliste: state.spielerliste,
    pfade: state.pfade,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Spielposition);
