import React, {createRef, useEffect, useState} from "react";
import ModalerDialog from "../ModalerDialog";
import ConfirmButton from "../ConfirmButton";
import {executeFormData, executeGet, executeJson} from "../../lib/fetchHelper";

const Texte = {
  de: {
    titel: 'Bild auswählen',
    tabs: {
      upload: 'Hochladen',
      url: 'Internet-Bild',
      galerie: 'Galerie',
    },
    erklaerung: {
      upload: 'Wählen Sie ein Bild von Ihrer Festplatte, um es hochzuladen und auf clubcorner.ch zu benutzen.',
      url: 'Fügen Sie die URL eines online ohne Login verfügbaren Bildes in das Textfeld ein, um ein beliebiges Bild in den Text einzufügen.',
      galerie: 'Bereits von Ihnen hochgeladene Bilder stehen hier zur Verfügung. Wählen Sie ein Bild per Klick, um es einzufügen.',
      width_height: 'Optional: Sie können die Breite und/oder die Höhe der Grafik bestimmen.',
      width: 'Breite:',
      height: 'Höhe:',
    },
    abbrechen: 'Abbrechen',
    einfuegen: 'Bild Einfügen',
    loeschen: 'Bild Löschen',
    loeschen_hinweis: 'Es wird nicht geprüft, ob das Bild irgendwo verwendet wird. Wollen Sie wirklich löschen?',
  },
  fr: {
    titel: "Choisir image",
    tabs: {
      upload: "Télécharger",
      url: "Image du Internet",
      galerie: "Galerie",
    },
    erklaerung: {
      upload: "Choisissez une image de votre disque dur pour la télécharger et l'utiliser sur clubcorner.ch.",
      url: "Pour insérer une image dans le texte, collez l'URL d'une image en ligne sans vous connecter à la zone de texte.",
      galerie: "Les photos que vous avez déjà téléchargées sont disponibles ici. Sélectionnez une image en cliquant pour l'insérer.",
      width_height: "Facultatif: vous pouvez spécifier la largeur et / ou la hauteur du graphique.",
      width: "Largeur:",
      height: "Hauteur:",
    },
    abbrechen: "Abandonner",
    einfuegen: "Insérer une image",
    loeschen: "Supprimer l'image",
    loeschen_hinweis: "Il ne vérifie pas si l'image est utilisée quelque part. Vous voulez vraiment supprimer?",
  },
  it: {
    titel: "Seleziona immagine",
    tabs: {
      upload: "Caricare",
      url: "Immagine di Internet",
      galerie: "Galleria",
    }, 
    erklaerung: {
      upload: "Scegli un'immagine dal disco rigido per caricarla e usarla a clubcorner.ch.",
      url: "Per inserire un'immagine nel testo, incollare l'URL di un'immagine online senza accedere alla casella di testo.",
      galerie: "Le foto che hai già caricato sono disponibili qui. Seleziona un'immagine facendo clic per inserirla.",
      width_height: "Opzionale: è possibile specificare la larghezza e / o l'altezza dell'immagine.",
      width: "Larghezza:",
      height: "Altezza:",
    },
    abbrechen: "Abortire",
    einfuegen: "Inserisci l'immagine",
    loeschen: "Cancellare l'immagine",
    loeschen_hinweis: "Non controlla se l'immagine viene utilizzata da qualche parte. Volete davvero cancellare?",
  }
}

const TABS = {
  upload: 'upload',
  url: 'url',
  galerie: 'galerie'
}

const GrafikEinfuegen = ({locale, imageUploadsUrl, token, onHide, onChange}) => {
  const [activeTab, setActiveTab] = useState(imageUploadsUrl ? TABS.upload : TABS.url);
  const [ajaxLaueft, setAjaxLaeuft] = useState({ upload: false, galerie: false });
  const fileInput = createRef();
  const [uploadImage, setUploadImage] = useState(null);
  const [linkUrl, setLinkUrl] = useState('');
  const [galerie, setGalerie] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageWidth, setImageWidth] = useState('');
  const [imageHeight, setImageHeight] = useState('');
  const texte = Texte[locale];
  
  useEffect(() => {
    if (ajaxLaueft.upload === false && fileInput.current) {
      fileInput.current.value = ''; // fileInput zurücksetzen nach erfolgtem upload geht nur hier weil sonst der kontext fehlt.
    }
  }, [ajaxLaueft]);

  const ladeGalerie = () => {
    setGalerie(null);
    setAjaxLaeuft({...ajaxLaueft, galerie: true});
    executeGet(imageUploadsUrl).then(result => {
      if (result.ok) {
        setGalerie(result.data);
      }
    }).finally(() => setAjaxLaeuft({...ajaxLaueft, galerie: false}));
  };

  const navClick = (ev, tab) => {
    ev.preventDefault();
    ev.stopPropagation();
    setActiveTab(tab);

    if (tab === TABS.galerie && galerie === null) {
      // galerie initial laden
      ladeGalerie();
    }
  };
  
  const loescheUpload = () => {
    if (selectedImage) {
      setAjaxLaeuft({...ajaxLaueft, galerie: true});
      executeJson(`${imageUploadsUrl}/${selectedImage.file}`, token, 'delete', {}).then(result => {
        setSelectedImage(null);
        ladeGalerie();
      }).finally(() => setAjaxLaeuft({...ajaxLaueft, galerie: false}))
    }
  };
  
  const macheUpload = () => {
    if (fileInput.current && fileInput.current.files.length >= 1) {
      setAjaxLaeuft({...ajaxLaueft, upload: true});
      let data = {
        'upload[image]': fileInput.current.files[0]
      };
      executeFormData(imageUploadsUrl, token, 'POST', data).then(result => {
        if (result.ok) {
          setUploadImage(result.data);
          setGalerie(null); // damit dieser upload in galerie auch angezeigt wird
        }
      }).finally(() => {
        setAjaxLaeuft({...ajaxLaueft, upload: false});
      });
    }
  };
  
  const onBildEinfuegen = () => {
    switch (activeTab) {
      case TABS.upload:
        if (uploadImage) {
          onChange(uploadImage.fileUri, imageWidth, imageHeight);
        }
        break;
      case TABS.url:
        onChange(linkUrl, imageWidth, imageHeight);
        break;
      case TABS.galerie:
        if (selectedImage) {
          onChange(galerie.basepath + selectedImage.file, imageWidth, imageHeight);
        }
        break;
    }
  };

  const renderGalerie = () => {
    if (galerie) {
      return (
        <div>
          {galerie.imagesData.map(data => {
            return <React.Fragment key={data.label}>
              <div>{data.label}</div>
              {data.images.map(img => {
                let isSelected = selectedImage === img;
                return <img key={img.file} className={isSelected ? 'selected' : ''} src={galerie.basepath + img.file} onClick={() => setSelectedImage(isSelected ? null : img)}/>;
              })}
            </React.Fragment>;
          })}
        </div>
      );
    }
    return <></>;
  };

  return (
    <ModalerDialog>
      <div className="modal markdown-image-upload">
        <div className="modal-header">
          <button type="button" className="close" onClick={() => onHide()}>&times;</button>
          <h3>{texte.titel}</h3>
        </div>
        <div className="modal-body container-fluid">
          <ul className="nav nav-tabs">
            {imageUploadsUrl &&
              <li className={activeTab === TABS.upload ? 'active' : ''}>
                <a href="#" onClick={(ev) => navClick(ev, TABS.upload)}>{texte.tabs.upload}</a>
              </li>
            }
            <li className={activeTab === TABS.url ? 'active' : ''}>
              <a href="#" onClick={(ev) => navClick(ev, TABS.url)}>{texte.tabs.url}</a>
            </li>
            {imageUploadsUrl &&
              <li className={activeTab === TABS.galerie ? 'active' : ''}>
                <a href="#" onClick={(ev) => navClick(ev, TABS.galerie)}>{texte.tabs.galerie}</a>
              </li>
            }
          </ul>
          <div className="tab-content">
            {activeTab == TABS.upload &&
              <div className="upload-tab">
                {texte.erklaerung.upload}
                <hr/>
                {ajaxLaueft.upload && <div className="ajax-spinner-bar"><span /></div>}
                <div id="image-file-field">
                  <input ref={fileInput} accept=".png, .jpg, .jpeg, .gif" type="file" style={{width: '100%'}} onChange={macheUpload}/>
                </div>
                {uploadImage && <div className="image-display"><img src={uploadImage.fileUri}/></div>}
              </div>
            }
            {activeTab == TABS.url &&
              <div className="url-tab">
                {texte.erklaerung.url}
                <hr/>
                <input type="url" value={linkUrl} onChange={ev => setLinkUrl(ev.currentTarget.value)}/>
              </div>
            }
            {activeTab == TABS.galerie &&
              <div className="gallery-tab">
                <div className="flex-column">
                  <div className="flex-grow-1">
                    {texte.erklaerung.galerie}
                    <hr/>
                  </div>
                  {ajaxLaueft.galerie && <div className="ajax-spinner-bar"><span /></div>}
                  {renderGalerie()}
                </div>
              </div>
            }
          </div>
          <hr/>
          <div>
            <label>{texte.erklaerung.width_height}</label>
            <span style={{paddingRight: 10}}>
              {texte.erklaerung.width}&nbsp;<input type="number" value={imageWidth} onChange={ev => setImageWidth(ev.currentTarget.value)} min="1" style={{width: 80}}/>
            </span>
            <span>
              {texte.erklaerung.height}&nbsp;<input type="number" value={imageHeight} onChange={ev => setImageHeight(ev.currentTarget.value)} min="1" style={{width: 80}}/>
            </span>
          </div>
        </div>
        <div className="modal-footer">
          {selectedImage && <ConfirmButton className="btn btn-danger pull-left" confirmText={texte.loeschen_hinweis} okText={texte.loeschen} cancelText={texte.abbrechen} onConfirmed={loescheUpload}>{texte.loeschen}</ConfirmButton>}
          <button type="button" className="btn" onClick={() => onHide()}>{texte.abbrechen}</button>
          <button type="button" className="btn btn-primary" onClick={onBildEinfuegen}>{texte.einfuegen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

export default GrafikEinfuegen;
