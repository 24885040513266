// @flow
import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {executeJson} from "../lib/fetchHelper";
import {biologischeEntwicklungText, mirwaldAlleSpielerLaden, mirwaldSpielerIndex} from "../state/modules/mirwald";
import Tooltip from "../../../components/Tooltip";
import PersonBadge from "../../../components/PersonBadge";
import MirwaldModal from "./MirwaldModal";
import StatusLabel from "./StatusLabel";
import {formatiereDatum, formatiereDatumZeit} from "../../../lib/formatierungen";
import AlleBearbeitenModal from "./AlleBearbeitenModal";

const Mirwald = ({phase, spieler, mirwald, biologischeEntwicklung, bearbeitbar, api, texte, mirwaldAlleSpielerLaden, mirwaldSpielerIndex}) => {
  const [showSpieler, setShowSpieler] = useState(null);
  const [bearbeitungsModus, setBearbeitungsModus] = useState(false);

  useEffect(() => {
    // lade mirwald von server
    mirwaldAlleSpielerLaden(phase);
  }, [phase]);

  const abschlussMoeglich = useMemo(() => !spieler.some(s => !mirwald.find(m => m.spielerId === s.id && m.phase === phase)), [mirwald]);
  const abschlussAlle = useMemo(() => {
    return spieler.every(s => {
      const m = mirwald.find(m => m.spielerId === s.id && m.phase === phase);
      return m && m.abgeschlossen;
    });
  }, [mirwald]);

  const abschliessen = () => {
    executeJson(api.mirwald, api.token, 'POST', {phase: phase})
      .then(result => {
        if (result.ok) {
          mirwaldSpielerIndex(result.data.liste);
        }
      });
  };

  const renderSpieler = (spieler) => {
    const spielerMirwald = mirwald.find(m => m.spielerId === spieler.id && m.phase === phase);

    return (
      <tr key={spieler.id}>
        <td><Tooltip content={texte.passnummer} className="label label-info">{spieler.passnummer}</Tooltip></td>
        <td><PersonBadge person={spieler.person} personVerlinken={false}/></td>
        <td><StatusLabel model={spielerMirwald}/></td>
        <td className="text-nowrap">{spielerMirwald && <><i className="icon-calendar"/>&nbsp;{formatiereDatum(spielerMirwald.datum_zeit_messung)}</>}</td>
        <td>{spielerMirwald && spielerMirwald.biologisches_alter}</td>
        <td>{spielerMirwald && biologischeEntwicklungText(biologischeEntwicklung, spielerMirwald.biologischer_entwicklungsstand)}</td>
        <td>{spielerMirwald && `${spielerMirwald.erwachsenen_groesse_prozent}%`}</td>
        <td className="text-nowrap">{spielerMirwald && `${spielerMirwald.erwachsenen_groesse_cm} cm`}</td>
        {bearbeitbar &&
        <td>
          {spieler.editable &&
            <button className="btn" onClick={() => setShowSpieler(spieler)}>
              <i className="icon-edit"></i>
            </button>
          }
        </td>
        }
      </tr>
    );
  };

  if (api.aktiv) {
    return <div className="text-center"><i className="fa fa-refresh fa-spin fa-3x"/></div>;
  }

  return (
    <>
      {abschlussMoeglich && !abschlussAlle &&
        <div className="form-actions">
          <button type="button" className="btn btn-primary" onClick={abschliessen}>{texte.tests_abschliessen}</button>
        </div>
      }
      <table className="table table-striped table-condensed table-mirwald">
        <colgroup>
          <col width={54}/>
          <col width={160}/>
          <col width={20}/>
          <col/>
          <col width={20}/>
          <col width={20}/>
          <col width={20}/>
          <col width={20}/>
          {bearbeitbar &&
          <col width={20}/>
          }
        </colgroup>
        <thead>
        <tr>
          <th></th>
          <th>{texte.spieler}</th>
          <th>{texte.status}</th>
          <th><Tooltip content={texte.mirwald.datum_zeit_messung}>{texte.mirwald.datum_zeit_messung_kurz}</Tooltip></th>
          <th><Tooltip content={texte.mirwald.biologisches_alter}>{texte.mirwald.biologisches_alter_kurz}</Tooltip></th>
          <th><Tooltip content={texte.mirwald.biologischer_entwicklungsstand}>{texte.mirwald.biologischer_entwicklungsstand_kurz}</Tooltip></th>
          <th><Tooltip content={texte.mirwald.erwachsenen_groesse_prozent}>{texte.mirwald.erwachsenen_groesse_prozent_kurz}</Tooltip></th>
          <th><Tooltip content={texte.mirwald.erwachsenen_groesse_cm}>{texte.mirwald.erwachsenen_groesse_cm_kurz}</Tooltip></th>
          {bearbeitbar &&
          <th>
            <Tooltip content={texte.mirwald.alle_bearbeiten} className="btn btn-primary" onClick={() => setBearbeitungsModus(!bearbeitungsModus)}>
              <i className="icon-edit"></i>
            </Tooltip>
          </th>
          }
        </tr>
        </thead>
        <tbody>
        {spieler.map(s => renderSpieler(s))}
        </tbody>
      </table>
      {showSpieler &&
        <MirwaldModal phase={phase} spieler={showSpieler} onHide={() => setShowSpieler(null)}/>
      }
      {bearbeitungsModus == true &&
        <AlleBearbeitenModal phase={phase} spielerListe={spieler} onHide={() => setBearbeitungsModus(false)}/>
      }
      {bearbeitbar && abschlussMoeglich && !abschlussAlle &&
        <div className="form-actions">
          <button type="button" className="btn btn-primary" onClick={abschliessen}>{texte.tests_abschliessen}</button>
        </div>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    spieler: state.spieler,
    mirwald: state.mirwald,
    biologischeEntwicklung: state.listen.biologischeEntwicklung,
    bearbeitbar: state.listen.bearbeitbar,
    api: state.api,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mirwaldAlleSpielerLaden: (phase) => {
      dispatch(mirwaldAlleSpielerLaden(phase));
    },
    mirwaldSpielerIndex: (liste) => {
      dispatch(mirwaldSpielerIndex(liste));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mirwald);
