import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import {heartbeatLaufendeSpielzeitSetzen} from "../state/modules/heartbeat";
import {laufendeSpielzeitStarten, laufendeSpielzeitStoppen, laufendeSpielzeitSetzen} from "../state/modules/spielanzeige";
import {naechsteAktionen, berechneSpielUndZusatzminute, AKTION} from "../lib/spielablauf";
import {spielzeitSetMenu} from "../state/modules/editMarkierer";

const Spielzeit = ({zeitLaueft, laufendeSpielzeit, spiel, liga, showMenu, texte, heartbeatLaufendeSpielzeitSetzen, spielzeitSetzen, timerStarten, timerStoppen, setShowMenu}) => {
  const [submenu, setSubmenu] = useState([]);
  const zeitInput = useRef(null);

  useEffect(() => {
    if (zeitLaueft === true) {
      timerStarten();
    } else {
      timerStoppen();
    }
    return () => timerStoppen();
  }, [zeitLaueft]);

  // spielzeit ist in sekunden, extrahieren aller einzelnen digits der anzeige:
  const sekunden = laufendeSpielzeit % 60;
  const minuten = (laufendeSpielzeit - sekunden) / 60;
  const digit1 = sekunden % 10;
  const digit2 = Math.floor(sekunden / 10);
  const digit3 = minuten % 10;
  const digit4 = Math.floor((minuten % 100) / 10);
  const digit5 = Math.floor(minuten / 100);
  const halbzeit = 'first'; // TODO: dynamisch anzeigen

  const onSpielzeitKeydown = (event) => {
    if (event.keyCode === 13) {
      parseSpielzeit(event.target.value);
    }
  };

  const onSpielzeitOkClick = () => {
    parseSpielzeit(zeitInput.current.value);
  };

  const onSpielzeitAktionAusfuehren = (data) => {
    if (data.aktion === undefined) {
      // nur submenu anzeigen, noch keine aktion ausführen
      setSubmenu(data.submenu);
    } else {
      if (data.aktion === AKTION.noop) {
        // noop -> keine aktion ausführen
      } else {
        // gewählte aktion ausführen
        if (data.minute === null) {
          // minute / zusatzminute für ereignis berechnen
          const laufendeMinute = (Math.floor(minuten) + 1);
          const [spielminute, zusatzminute] = berechneSpielUndZusatzminute(spiel.periode, liga, laufendeMinute);
          spielzeitSetzen(data.aktion, data.typ, spielminute, zusatzminute);
        } else {
          // spielminute vorhanden: anpfiff einer periode, spielminute ist also bekannt
          spielzeitSetzen(data.aktion, data.typ, data.minute, 0);
        }
      }
      setSubmenu([]); // kein submenu, falls vorhanden
    }
    setShowMenu(false); // menu immer ausblenden
  };
  
  const toggleShowMenu = (event) => {
    event.stopPropagation();

    if (showMenu || submenu.length > 0) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
    setSubmenu([]);
  };

  const parseSpielzeit = (value) => {
    if (showMenu) {
      const split = value.split(':');
      if (split.length !== 2) {
        return;
      }
      let min = parseInt(split[0], 10);
      let sek = parseInt(split[1], 10);
      heartbeatLaufendeSpielzeitSetzen(min * 60 + sek);
      setShowMenu(false);
    }
  };

  const digitClassName = d => `n${d}`;

  return (
    <>
      <div className="uhr" onClick={toggleShowMenu}>
        <div className={"halbzeit " + halbzeit}>&nbsp;</div>
        <div className="spielzeit">
          {digit5 > 0 &&
          <span className={digitClassName(digit5)}></span>
          }
          <span className={digitClassName(digit4)}></span>
          <span className={digitClassName(digit3)}></span>
          <span className="sep"></span>
          <span className={digitClassName(digit2)}></span>
          <span className={digitClassName(digit1)}></span>
        </div>
      </div>
      {showMenu &&
      <div className="spielzeit-menu" onClick={(e) => e.stopPropagation()}>
        <ul className="dropdown-menu" id="menu" style={{display: "block"}}>
          {zeitLaueft &&
          <li>
            <input ref={zeitInput} type="text" defaultValue={`${digit5 > 0 ? digit5 : ''}${digit4}${digit3}:${digit2}${digit1}`} onKeyDown={onSpielzeitKeydown}/>
            <button className="btn btn-mini" onClick={onSpielzeitOkClick}>
              <i className="icon icon-ok"/>
            </button>
          </li>
          }
          {
            naechsteAktionen(spiel.periode, liga, minuten, texte).map((data, index) =>
              <li key={`menu-${data.aktion || index}`} className={ index > 0 ? "seperator" : ""}>
                <a onClick={() => onSpielzeitAktionAusfuehren(data)}>{data.text}</a>
              </li>
            )
          }
        </ul>
      </div>
      }
      {submenu.length > 0 &&
      <div className="spielzeit-menu">
        <ul className="dropdown-menu" id="menu" style={{display: "block"}}>
          {
            submenu.map((data, index) => {
              if (data.aktion) {
                return <li key={`submenu-${data.aktion}`} className={index > 0 ? "seperator" : ""}>
                  <a onClick={() => onSpielzeitAktionAusfuehren(data)}>{data.text}</a>
                </li>;
              } else {
                return <li key={index}>
                  <p>{data.text}</p>
                </li>;
              }
            })
          }
        </ul>
      </div>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    zeitLaueft: state.heartbeat.spielzeit.zeit_laeuft,
    laufendeSpielzeit: state.spielanzeige.laufendeSpielzeit,
    spiel: state.heartbeat.spiel,
    liga: state.liga,
    showMenu: state.editMarkierer.spielzeit,
    texte: state.i18n.header,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    heartbeatLaufendeSpielzeitSetzen: (sekunden, zeit_laeuft) => { dispatch(heartbeatLaufendeSpielzeitSetzen(sekunden, zeit_laeuft)); },
    spielzeitSetzen: (typ, method, spielminute, zusatzminute) => { dispatch(laufendeSpielzeitSetzen(typ, method, spielminute, zusatzminute)); },
    timerStarten: () => { dispatch(laufendeSpielzeitStarten()); },
    timerStoppen: () => { dispatch(laufendeSpielzeitStoppen()); },
    setShowMenu: (showMenu) => { dispatch(spielzeitSetMenu(showMenu)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Spielzeit);
