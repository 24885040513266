// @flow

import type {AppState, SpielerEintrag, ValidierungState} from "../stateTypes";
import type {Action, GetState, Dispatch} from "../actionTypes";
import {protokollHatTore} from "./protokoll";
import {spielerkarteEintragFuer} from "./spiel";

function feldValidieren(feld: any, fehlermeldung: string, einsatzFehler: string[]): boolean {
  if (feld === undefined || feld === null || feld === "") {
    einsatzFehler.push(fehlermeldung);
    return false;
  } else {
    return true;
  }
}

export function validierungDurchfuehren(state: AppState): ValidierungState {
  // einsatz
  let einsatzGestartet = false;
  let einsatzFehler = [];
  einsatzGestartet = feldValidieren(state.einsatz.gesamtKommentar, state.i18n.validierung.fehler.keinGesamtKommentar, einsatzFehler) || einsatzGestartet;
  einsatzGestartet = feldValidieren(state.einsatz.einsatzBemerkungen, state.i18n.validierung.fehler.keineEinsatzBemerkungen, einsatzFehler) || einsatzGestartet;

  const spiel = state.spiel;
  if (state.einsatz.typ === 'spiel' && spiel) {
    einsatzGestartet = feldValidieren(spiel.generellesSpielniveau, state.i18n.validierung.fehler.keineNoteGenerellesSpielniveau, einsatzFehler) || einsatzGestartet;
    einsatzGestartet = feldValidieren(spiel.intensitaetSpielrhythmus, state.i18n.validierung.fehler.keineNoteIntensitaetSpielrhythmus, einsatzFehler) || einsatzGestartet;
    einsatzGestartet = feldValidieren(spiel.spielKommentar, state.i18n.validierung.fehler.keinSpielKommentar, einsatzFehler) || einsatzGestartet;
  } else if (state.einsatz.typ === 'training') {
    const training = state.training;
    if (training) {
      einsatzGestartet = feldValidieren(training.trainingTeamNote, state.i18n.validierung.fehler.keineTrainingTeamNote, einsatzFehler) || einsatzGestartet;
      einsatzGestartet = feldValidieren(training.trainingTeamKommentar, state.i18n.validierung.fehler.keinTrainingTeamKommentar, einsatzFehler) || einsatzGestartet;
      einsatzGestartet = feldValidieren(training.trainingTeamBemerkungen, state.i18n.validierung.fehler.keineTrainingTeamBemerkungen, einsatzFehler) || einsatzGestartet;
    }
  } else if (state.einsatz.typ === 'turnier') {
    const turnier = state.turnier;
    if (turnier) {
      einsatzGestartet = feldValidieren(turnier.turnierTeamNote, state.i18n.validierung.fehler.keineTurnierTeamNote, einsatzFehler) || einsatzGestartet;
      einsatzGestartet = feldValidieren(turnier.turnierTeamKommentar, state.i18n.validierung.fehler.keinTurnierTeamKommentar, einsatzFehler) || einsatzGestartet;
      einsatzGestartet = feldValidieren(turnier.turnierTeamBemerkungen, state.i18n.validierung.fehler.keineTurnierTeamBemerkungen, einsatzFehler) || einsatzGestartet;
    }
  }
  // tips
  let tipsFehler = [];
  let tipsGestartet = false;
  if (state.einsatz.istTorhueterEinsatz) {
    // nur Torhüter als "Spieler" berücksichtigen
    let spielerMitFehlendenTips = state.spielerliste.liste.filter((spieler) => {
      const spielerEinsatz = spielerkarteEintragFuer(state, spieler);
      return (spielerEinsatz && spielerEinsatz.torhueter) && (spieler.tips.t === null || spieler.tips.i === null || spieler.tips.p === null || spieler.tips.s === null);
    });
    if (spielerMitFehlendenTips.length > 0) {
      tipsFehler.push(state.i18n.validierung.fehler.spielerMitFehlendenTips)
    }
    let spielerOhneAlleTips = state.spielerliste.liste.filter((spieler) => {
      const spielerEinsatz = spielerkarteEintragFuer(state, spieler);
      return (spielerEinsatz && spielerEinsatz.torhueter) && (spieler.tips.t === null && spieler.tips.i === null && spieler.tips.p === null && spieler.tips.s === null);
    });
    if (spielerOhneAlleTips.length > 0) {
      tipsFehler.push(state.i18n.validierung.fehler.spielerOhneAlleTips)
    }
    if (state.spielerliste.liste.length === 0) {
      tipsFehler.push(state.i18n.validierung.fehler.keineSpielerEinsaetze)
    }
    tipsGestartet = tipsGestartet || spielerOhneAlleTips.length < state.spielerliste.liste.length;
  } else if (state.einsatz.hatTorhueterEinsatz) {
    // alles ausser Torhüter als "Spieler" berücksichtigen
    let spielerMitFehlendenTips = state.spielerliste.liste.filter((spieler) => {
      const spielerEinsatz = spielerkarteEintragFuer(state, spieler);
      return (!spielerEinsatz || !spielerEinsatz.torhueter) && (spieler.tips.t === null || spieler.tips.i === null || spieler.tips.p === null || spieler.tips.s === null);
    });
    if (spielerMitFehlendenTips.length > 0) {
      tipsFehler.push(state.i18n.validierung.fehler.spielerMitFehlendenTips)
    }
    let spielerOhneAlleTips = state.spielerliste.liste.filter((spieler) => {
      const spielerEinsatz = spielerkarteEintragFuer(state, spieler);
      return (!spielerEinsatz || !spielerEinsatz.torhueter) && (spieler.tips.t === null && spieler.tips.i === null && spieler.tips.p === null && spieler.tips.s === null);
    });
    if (spielerOhneAlleTips.length > 0) {
      tipsFehler.push(state.i18n.validierung.fehler.spielerOhneAlleTips)
    }
    if (state.spielerliste.liste.length === 0) {
      tipsFehler.push(state.i18n.validierung.fehler.keineSpielerEinsaetze)
    }
    tipsGestartet = tipsGestartet || spielerOhneAlleTips.length < state.spielerliste.liste.length;
  } else {
    // alle Spieler als "Spieler" berücksichtigen
    let spielerMitFehlendenTips = state.spielerliste.liste.filter((spieler) => {
      return spieler.tips.t === null || spieler.tips.i === null || spieler.tips.p === null || spieler.tips.s === null;
    });
    if (spielerMitFehlendenTips.length > 0) {
      tipsFehler.push(state.i18n.validierung.fehler.spielerMitFehlendenTips)
    }
    let spielerOhneAlleTips = state.spielerliste.liste.filter((spieler) => {
      return spieler.tips.t === null && spieler.tips.i === null && spieler.tips.p === null && spieler.tips.s === null;
    });
    if (spielerOhneAlleTips.length > 0) {
      tipsFehler.push(state.i18n.validierung.fehler.spielerOhneAlleTips)
    }
    if (state.spielerliste.liste.length === 0) {
      tipsFehler.push(state.i18n.validierung.fehler.keineSpielerEinsaetze)
    }
    tipsGestartet = tipsGestartet || spielerOhneAlleTips.length < state.spielerliste.liste.length;
  }
  // zusammmenfassung immer vorhandener validierungen
  let validierung: ValidierungState = {
    einsatz: {
      gestartet: einsatzGestartet || einsatzFehler.length === 0,
      vollstaendig: einsatzFehler.length === 0,
      fehler: einsatzFehler,
      abschlussFehler: state.validierung.einsatz.abschlussFehler,
    },
    tips: {
      gestartet: tipsGestartet || tipsFehler.length === 0,
      vollstaendig: tipsFehler.length === 0,
      fehler: tipsFehler,
    },
    allesVollstaendig: einsatzFehler.length === 0 && tipsFehler.length === 0,
  };
  
  // prognoseFragen
  if (state.einsatz.typ === "spiel") {
    const fragenFehler = [];
    const spielerMitPrognose = state.spielerliste.liste.filter(spieler => spieler.prognoseFragen && spieler.prognoseFragen.prognose !== null && spieler.prognoseFragen.prognose > 0);
    if (spielerMitPrognose.length < state.spielerliste.liste.length) {
      fragenFehler.push(state.i18n.validierung.fehler.spielerOhnePrognose);
    }
    validierung['prognoseFragen'] = {
      gestartet: spielerMitPrognose.length > 0,
      vollstaendig: fragenFehler.length === 0,
      fehler: fragenFehler
    };
  }
  
  // protokoll (nicht immer vorhanden)
  const protokoll = state.protokoll;
  if (protokoll && state.einsatz.typ === "spiel") {
    let protokollFehler = [];
    if (protokoll.liste.length <= 0) {
      protokollFehler.push(state.i18n.validierung.fehler.keinProtokoll)
    }
    if (spiel) {
      let protokollSpielstandHeim = 0;
      let protokollSpielstandGast = 0;
      protokoll.liste.forEach((eintrag) => {
        switch (eintrag.aktion) {
          case "tor":
            protokollSpielstandHeim += eintrag.heimTeam ? 1 : 0;
            protokollSpielstandGast += eintrag.gastTeam ? 1 : 0;
            break;
          case "elfmeter_tor":
            protokollSpielstandHeim += eintrag.heimTeam ? 1 : 0;
            protokollSpielstandGast += eintrag.gastTeam ? 1 : 0;
            break;
          case "eigentor":
            protokollSpielstandHeim += eintrag.gastTeam ? 1 : 0;
            protokollSpielstandGast += eintrag.heimTeam ? 1 : 0;
            break;
        }
      });
      const toreTesten = (spiel.spielstandDefinitiv || spiel.serverSpielstandHeim || spiel.serverSpielstandGast) && protokollHatTore(protokoll.liste);
      if (toreTesten && protokollSpielstandHeim !== spiel.serverSpielstandHeim) {
        protokollFehler.push(state.i18n.validierung.fehler.heimTeamTorePassenNicht + " (" +
          spiel.serverSpielstandHeim + ":" + spiel.serverSpielstandGast + ")");
      }
      if (toreTesten && protokollSpielstandGast !== spiel.serverSpielstandGast) {
        protokollFehler.push(state.i18n.validierung.fehler.gastTeamTorePassenNicht + " (" +
          spiel.serverSpielstandHeim + ":" + spiel.serverSpielstandGast + ")");
      }
    }
    validierung.protokoll = {
      gestartet: protokoll.liste.length > 0 || protokollFehler.length === 0,
      vollstaendig: protokollFehler.length === 0,
      fehler: protokollFehler,
    };
    validierung.allesVollstaendig = validierung.allesVollstaendig && protokollFehler.length === 0;
  }
  // torhüter-noten (nicht immer vorhanden)
  if (state.einsatz.istTorhueterEinsatz && state.einsatz.typ === "spiel") {
    let torhueterFehler = [];
    let gestartet = false;
    let torhueterMitFehlendenNoten = state.spielerliste.liste.filter((spieler) => {
      const spielerEinsatz = spielerkarteEintragFuer(state, spieler);
      return (spielerEinsatz && spielerEinsatz.torhueter) && (spieler.torhueterNoten.filter((torhueterNote) => {
        if (torhueterNote !== null) {
          gestartet = true;
        }
        return torhueterNote.note === null;
      }).length > 0);
    });
    if (torhueterMitFehlendenNoten.length > 0) {
      torhueterFehler.push(state.i18n.validierung.fehler.torhueterMitFehlendenNoten)
    }
    let torhueterOhneAlleNoten = state.spielerliste.liste.filter((spieler) => {
      const spielerEinsatz = spielerkarteEintragFuer(state, spieler);
      return (spielerEinsatz && spielerEinsatz.torhueter) && (spieler.torhueterNoten.filter((torhueterNote) => {
        return torhueterNote.note === null;
      }).length === spieler.torhueterNoten.length);
    });
    if (torhueterOhneAlleNoten.length > 0) {
      torhueterFehler.push(state.i18n.validierung.fehler.torhueterOhneAlleNoten)
    }
    let torhueterOhnePflichtnoten = state.spielerliste.liste.filter((spieler) => {
      const spielerEinsatz = spielerkarteEintragFuer(state, spieler);
      return (spielerEinsatz && spielerEinsatz.torhueter) && (spieler.torhueterNoten.filter((torhueterNote) => {
        return torhueterNote.pflichtnote && torhueterNote.note === null;
      }).length > 0);
    });
    if (torhueterOhnePflichtnoten.length > 0) {
      torhueterFehler.push(state.i18n.validierung.fehler.torhueterOhnePflichtnoten)
    }
    if (state.spielerliste.liste.filter((spieler) => {
      const spielerEinsatz = spielerkarteEintragFuer(state, spieler);
      return spielerEinsatz && spielerEinsatz.torhueter;
    }).length === 0) {
      torhueterFehler.push(state.i18n.validierung.fehler.keineTorhueterEinsaetze)
    }
    validierung.torhueter = {
      gestartet: gestartet || torhueterFehler.length === 0,
      vollstaendig: torhueterFehler.length === 0,
      fehler: torhueterFehler,
    }
  }
  // noten (nicht immer vorhanden)
  if (state.einsatz.noten !== null && state.einsatz.noten.length > 0 && state.einsatz.typ === "spiel" && state.teams) {
    const heimTeam = state.teams.heimTeam;
    const gastTeam = state.teams.gastTeam;
    if (heimTeam && gastTeam) {
      let notenFehler = [];
      state.einsatz.noten.forEach((note) => {
        if (note.erlaubt && !note.note) {
          notenFehler.push(
            state.i18n.validierung.fehler.noteFehlt + ": " +
            (note.heimTeam ? heimTeam.name + " / " : "") +
            (note.gastTeam ? gastTeam.name + " / " : "") +
            state.i18n.notenKategorien[note.kategorieId.toString()] + " / " +
            state.i18n.notenArten[note.artId.toString()]
          );
        }
      });
      validierung.noten = {
        gestartet: state.einsatz.noten.filter((note) => {
          return note.erlaubt && note.note;
        }).length > 0 || notenFehler.length === 0,
        vollstaendig: notenFehler.length === 0,
        fehler: notenFehler,
      };
      validierung.allesVollstaendig = validierung.allesVollstaendig && notenFehler.length === 0;
    }
  }
  return validierung;
}

export function validieren() {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({type: "VALIDIERUNG_VALIDIEREN", state: getState()});
  };
}

function validierungReducer(state: ValidierungState, action: Action): ValidierungState | null {
  switch (action.type) {
    case "VALIDIERUNG_VALIDIEREN":
      return validierungDurchfuehren(action.state);
    case "VALIDIERUNG_ABSCHLUSS_FEHLER":
      return {
        ...state,
        einsatz: {
          ...state.einsatz,
          abschlussFehler: action.fehler,
        }
      };
    default:
      return state || null;
  }
}

export default validierungReducer
