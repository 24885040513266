import {executeRequest} from "../lib/api";
import {ersetzeStringMitDatum, sortierePersonName} from "../lib/formatierungen";

const AUSBILDNER_ERGEBNIS_UPDATE = 'AUSBILDNER_ERGEBNIS_UPDATE';
const AUSBILDNER_UPDATE = 'AUSBILDNER_UPDATE';

export function ausbildnerUpdate(ausbildner) {
  return (dispatch) => {
    dispatch({type: AUSBILDNER_ERGEBNIS_UPDATE, ausbildner: ausbildner});
  };
}

export function ausbildnerAbschlussSpeichern(ausbildner, zusatzdaten) {
  return (dispatch, getState) => {
    const state = getState();
    const daten = {
      ...zusatzdaten,
      kontext: 'ausbildner_abschluss',
      ausbildungId: ausbildner.ausbildungId,
    };
    return executeRequest(state.api.update, state.api.token, 'PUT', daten, (result) => {
      const a = result.data.ausbildner;
      a.ergebnis = ersetzeStringMitDatum(result.data.ergebnis, ['beurteilungAbgeschlossenAm']);
      dispatch({type: AUSBILDNER_ERGEBNIS_UPDATE, ausbildner: a});
    });
  };
}

export function ausbildungSpeichern(ausbildner, statusId, zusatzdaten = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const daten = {
      ...zusatzdaten,
      kontext: 'ausbildung_speichern',
      ausbildungId: ausbildner.ausbildungId,
      ausbildungStatusId: statusId,
    };
    return executeRequest(state.api.update, state.api.token, 'PUT', daten, (result) => {
      dispatch({type: AUSBILDNER_UPDATE, ausbildner: result.data.ausbildner});
    });
  };
}

function ausbildnerReducer(state = [], action) {
  let ausbildner;
  switch (action.type) {
    case AUSBILDNER_ERGEBNIS_UPDATE:
      ausbildner = action.ausbildner;
      return [
        ...state.filter(a => a.id !== ausbildner.id),
        {...ausbildner}
      ].sort((a, b) => sortierePersonName(a, b));
    case AUSBILDNER_UPDATE:
      ausbildner = action.ausbildner;
      return [
        ...state.filter(a => a.id !== ausbildner.id),
        ausbildner
      ].sort((a, b) => sortierePersonName(a, b));
    default:
      return state;
  }

}

export default ausbildnerReducer;
