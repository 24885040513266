// @flow

import type {AppState, EinsatzState, ValidierungState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

const Validierung = (props: {
  validierung: ValidierungState,
  i18n: any,
  pfade: any,
}) => {
  return (
    <table className={'table table-striped table-bordered'}>
      <thead>
      <tr>
        <th>{props.i18n.validierung.kategorie}</th>
        <th style={{textAlign: "center", width: "75px"}}>{props.i18n.validierung.gestartet}</th>
        <th style={{textAlign: "center", width: "75px"}}>{props.i18n.validierung.vollstaendig}</th>
        <th>{props.i18n.validierung.meldungen}</th>
      </tr>
      </thead>
      <tbody>
      {
        ["einsatz", "tips", "prognoseFragen", "protokoll", "torhueter", "noten"].map((kategorie) => {
          if (props.validierung[kategorie] !== undefined) {
            return <tr key={kategorie}>
              <td>{props.i18n.validierung[kategorie]}</td>
              <td style={{textAlign: "center", width: "75px"}}>
                <img alt={props.i18n.validierung.gestartet} src={props.validierung[kategorie].gestartet ? props.pfade.icons.ok : props.pfade.icons.nok}/>
              </td>
              <td style={{textAlign: "center", width: "75px"}}>
                <img alt={props.i18n.validierung.vollstaendig} src={props.validierung[kategorie].vollstaendig ? props.pfade.icons.ok : props.pfade.icons.nok}/>
              </td>
              <td>
                {props.validierung[kategorie].fehler.length === 0 ? "" : <ul style={{fontSize: '10px', margin: '0 0 0 15px'}}>
                  {
                    props.validierung[kategorie].fehler.map((fehlermeldung) => {
                      return <li key={fehlermeldung} style={{lineHeight: '15px'}}>{fehlermeldung}</li>;
                    })
                  }
                </ul>}
              </td>
            </tr>
          }
        })
      }
      {
        (props.validierung.einsatz.abschlussFehler.length > 0 && true) && <tr>
          <td>{props.i18n.validierung.serverMeldungen}</td>
          <td style={{textAlign: "center", width: "75px"}}>
            <img alt={props.i18n.validierung.gestartet} src={props.pfade.icons.ok}/>
          </td>
          <td style={{textAlign: "center", width: "75px"}}>
            <img alt={props.i18n.validierung.vollstaendig} src={props.pfade.icons.nok}/>
          </td>
          <td>
            <ul style={{fontSize: '10px', margin: '0 0 0 15px'}}>
              {
                props.validierung.einsatz.abschlussFehler.map((fehlermeldung) => {
                  return <li key={fehlermeldung} style={{lineHeight: '15px'}}>{fehlermeldung}</li>;
                })
              }
            </ul>
          </td>
        </tr>
      }
      </tbody>
    </table>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    validierung: state.validierung,
    i18n: state.i18n,
    pfade: state.pfade,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Validierung);
