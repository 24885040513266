import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../components/ModalerDialog";
import {executeGet, executeJson} from "../lib/fetchHelper";
import {sportmotorikSpielerEntfernen, sportmotorikSpielerUpdate} from "../state/modules/sportmotorik";
import PersonBadge from "../../../components/PersonBadge";
import {ersetzeStringMitDatum} from "../../../lib/datenhaltung";
import Popover, {POSITION} from "../../../components/Popover";

const SportmotorikModal = ({phase, spieler, onHide, sportmotorikKonfig, texte, api, sportmotorikSpielerUpdate, sportmotorikSpielerEntfernen}) => {
  const [sportmotorik, setSportmotorik] = useState(null);
  const [xhrAktiv, setXhrAktiv] = useState(false);
  const texteSportmotorik = texte.sportmotorik;
  const readonly = sportmotorik && sportmotorik.abgeschlossen;
  const notenTabellen = sportmotorikKonfig.referenzwerte.filter(wert => wert.alter === spieler.alter).sort((a, b) => a.note - b.note);

  const schliessen = () => {
    onHide();
  };

  useEffect(() => {
    // lade sportmotorik von server
    setXhrAktiv(true);
    executeGet(`${api.sportmotorik}/${spieler.id}`, {phase: phase})
      .then(result => {
        const motorik = ersetzeStringMitDatum(result.data.sportmotorik, ['datum_zeit', 'datum_abgeschlossen']);
        setSportmotorik(motorik);
        if (motorik.id > 0) {
          sportmotorikSpielerUpdate(motorik);
        }
        setXhrAktiv(false);
      })
      .catch(() => xhrAktiv(false));
  }, []);

  const speichern = (aktion = null) => {
    const daten = {
      id: spieler.id,
      phase: phase,
      aktion: aktion,
      sportmotorik: {...sportmotorik}
    };
    delete daten.sportmotorik.errors;
    setXhrAktiv(true);
    executeJson(`${api.sportmotorik}/${spieler.id}`, api.token, 'PUT', daten)
      .then(result => {
        const motorik = ersetzeStringMitDatum(result.data.sportmotorik, ['datum_zeit', 'datum_abgeschlossen']);
        setSportmotorik(motorik);
        sportmotorikSpielerUpdate(motorik);
        setXhrAktiv(false);
      })
      .catch(() => xhrAktiv(false));
  };

  const loeschen = () => {
    setXhrAktiv(true);
    executeJson(`${api.sportmotorik}/${spieler.id}`, api.token, 'DELETE', {phase: phase})
      .then(result => {
        if (result.ok) {
          setSportmotorik(null);
          sportmotorikSpielerEntfernen(sportmotorik);
          setXhrAktiv(false);
          schliessen();
        }
      })
      .catch(() => xhrAktiv(false));
  };

  const referenzwertBestimmen = (test, nummerischerWert, referenzwerte) => {
    let wert = null;
    if (nummerischerWert >= 0 && referenzwerte.length > 0) {
      if (referenzwerte[0].min < referenzwerte[0].max) {
        wert = referenzwerte.find(w => w.min <= nummerischerWert && w.max >= nummerischerWert);
      } else {
        wert = referenzwerte.find(w => w.max <= nummerischerWert && w.min >= nummerischerWert);
      }
    }
    return wert;
  };
  
  const setzeWert = (key, value, test = null) => {
    const daten = {...sportmotorik};
    if (value === '') {
      // reset soll möglich sein
      daten[key] = null;
      if (test !== null) {
        daten[`${test}_te`] = null;
      }
    } else {
      const nummerischerWert = Number(value);
      daten[key] = nummerischerWert;
      if (test !== null) {
        const referenzwerte = notenTabellen.filter(note => note.name === test);
        let note = null;
        if (nummerischerWert >= 0 && referenzwerte.length > 0) {
          note = 1; // eingabe und refernzwerte vorhanden -> es gibt immer eine note (mindestens 1)
        }
        const wert = referenzwertBestimmen(test, nummerischerWert, referenzwerte);
        if (wert) {
          note = wert.note;
        }
        daten[`${test}_te`] = note;
      }
    }
    setSportmotorik(daten);
  };

  const setzeText = (key, value) => {
    const daten = {...sportmotorik};
    daten[key] = value;
    setSportmotorik(daten);
  };
  
  const renderReferenzwerte = (referenzwerte) => {
    if (referenzwerte.length > 0) {
      const tabelle = <table className="table table-bordered table-condensed table-striped"><tbody>{referenzwerte.map(w => <tr key={w.note}><th width="10px">{w.note}</th><td width="10px">{w.min}</td><td width="10px">{w.max}</td></tr>)}</tbody></table>;
      return <>&nbsp;<Popover className="icon icon-info-sign referenzwerte" position={POSITION.right} content={tabelle}/></>;
    }
    return <></>;
  };

  const tabelleKategorie = (kategorie, idx) => {
    let tabIndex = (idx + 1) * 10;
    return (
      <table key={kategorie.name} className="table table-bordered table-condensed">
        <tbody>
        <tr>
          <th width="25%">{texteSportmotorik[kategorie.name]}</th>
          {kategorie.tests.map((test, index) => {
            if (test) {
              if (sportmotorikKonfig.pflichwerte.some(p => p === test)) {
                return <th key={index} width="25%">{texteSportmotorik[test]}</th>;
              }
              return <td key={index} width="25%"><i>{texteSportmotorik[test]}</i></td>
            }
            return <td key={index} width="25%"></td>;
          })}
        </tr>
        <tr>
          <th width="25%">{texteSportmotorik.resultat}:</th>
          {kategorie.tests.map((test, index) => {
            if (test === null) {
              return <td key={index} width="25%"></td>;
            }
            const feldname = `${test}_res`;
            const readonly = sportmotorik.abgeschlossen || !sportmotorikKonfig.pflichwerte.some(p => p === test);
            const referenzwerte = notenTabellen.filter(note => note.name === test);
            const valid = readonly || referenzwerte.length === 0 || sportmotorik[feldname] === null || referenzwertBestimmen(test, sportmotorik[feldname], referenzwerte) != null; 

            return <td key={index} width="25%" className={valid ? '' : 'error'}>
                <input disabled={readonly} tabIndex={tabIndex + (index * 3)} className={valid ? '' : 'error'} type="number" step={0.01} min={0} value={sportmotorik[feldname] === null ? '' : sportmotorik[feldname]} onChange={(ev) => setzeWert(feldname, ev.currentTarget.value, test)}  style={{width: 100}}/>
                {renderReferenzwerte(referenzwerte)}
                {valid || <>&nbsp;{texteSportmotorik.wert_ausserhalb_referenz}</>}
            </td>;
          })}
        </tr>
        <tr>
          <th width="25%">{texteSportmotorik.note}:</th>
          {kategorie.tests.map((test, index) => {
            if (test === null) {
              return <td key={index} width="25%"></td>;
            }

            const feldname = `${test}_te`;
            const readonly = sportmotorik.abgeschlossen || !sportmotorikKonfig.pflichwerte.some(p => p === test);
            return <td key={index} width="25%">
              <select disabled={readonly} tabIndex={tabIndex + (index * 3) + 1} value={sportmotorik[feldname] === null ? '' : sportmotorik[feldname]} onChange={(ev) => setzeWert(feldname, ev.currentTarget.value)} className="select-note">
                <option value="">-</option>
                {[5, 4, 3, 2, 1].map(note => <option key={note} value={note}>{note}</option>)}
              </select>
            </td>;
          })}
        </tr>
        </tbody>
      </table>
    );
  };

  return (
    <ModalerDialog>
      <div className="modal breit-modal" style={{top: '5%', maxHeight: '95%'}}>
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{texteSportmotorik.titel}</h3>
        </div>
        <div className="modal-body">
          <table>
            <tbody>
            <tr>
              <td style={{verticalAlign: 'top', paddingRight: 10}}><span className="label label-info">{spieler.passnummer}</span></td>
              <td><PersonBadge person={spieler.person} personVerlinken={false} fotoTooltipAktiv={false}/></td>
            </tr>
            </tbody>
          </table>
          {sportmotorik &&
          <>
            {sportmotorikKonfig.kategorien.map((kategorie, index) => tabelleKategorie(kategorie, index))}
            <table className="table table-bordered table-condensed">
              <colgroup>
                <col width="25%"/>
                <col width="75%"/>
              </colgroup>
              <tbody>
              <tr>
                <th><label className="text-bold" htmlFor="bemerkungen">{texteSportmotorik.bemerkungen}</label></th>
                <td><input id="bemerkungen" disabled={sportmotorik.abgeschlossen} tabIndex={100} type="text" className="input-100prozent" value={sportmotorik.bemerkungen || ''} onChange={ev => setzeText('bemerkungen', ev.currentTarget.value)} /></td>
              </tr>
              <tr>
                <th><label className="text-bold" htmlFor="verletzt">{texteSportmotorik.verletzt}</label></th>
                <td><input type="checkbox" disabled={sportmotorik.abgeschlossen} tabIndex={101} checked={sportmotorik.verletzt} onChange={ev => setzeWert('verletzt', ev.currentTarget.checked)} /></td>
              </tr>
              </tbody>
            </table>
          </>
          }
        </div>
        <div className="modal-footer">
          {sportmotorik && sportmotorik.id > 0 && !readonly &&
          <button type="button" tabIndex={200} className="btn btn-success" onClick={() => speichern('abschliessen')}>{texte.abschliessen}</button>
          }
          {sportmotorik && readonly === false &&
          <button type="submit" tabIndex={201} className="btn btn-primary" onClick={(ev) => { ev.currentTarget.blur(); speichern(); }}>
            {texte.speichern}
            {xhrAktiv &&
            <>&nbsp;<i className="fa fa-refresh fa-spin"></i></>
            }
          </button>
          }
          {sportmotorik && sportmotorik.id > 0 && readonly &&
          <button type="button" tabIndex={203} className="btn btn-warning" onClick={() => speichern('bearbeiten')}>{texte.bearbeiten}</button>
          }
          <button type="button" tabIndex={204} className="btn" onClick={schliessen}>{texte.schliessen}</button>
          {sportmotorik && sportmotorik.id > 0 && !readonly &&
          <div className="pull-left">
            <button type="button" tabIndex={205} className="btn btn-danger" onClick={loeschen}>{texte.loeschen}</button>
          </div>
          }
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    sportmotorikKonfig: state.listen.sportmotorik,
    texte: state.i18n.texte,
    api: state.api,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sportmotorikSpielerUpdate: (sportmotorik) => {
      dispatch(sportmotorikSpielerUpdate(sportmotorik));
    },
    sportmotorikSpielerEntfernen: (sportmotorik) => {
      dispatch(sportmotorikSpielerEntfernen(sportmotorik));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SportmotorikModal);
