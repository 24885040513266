// @flow

import type {AppState, SpielerEintrag, TrainingSpielerEintrag} from "../../../state/stateTypes";
import type {Dispatch} from "../../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";
import SpielerBadge from "../../SpielerBadge";

const SpielerkarteSpielerOhneDaten = (props: {
  pfade: any,
  i18n: any,
  spieler: SpielerEintrag,
  spielerEinsatz: TrainingSpielerEintrag,
}) => {
  const spielerRow = <tr>
    <td style={{whiteSpace: 'nowrap', verticalAlign: 'middle'}}>
      <span className="label label-info">{props.spieler.passnummer}</span>
    </td>
    <td style={{verticalAlign: 'middle'}}>
      <SpielerBadge spieler={props.spieler}/>
    </td>
    <td>
      <div className="spielposition" style={{width: '110px', verticalAlign: 'middle'}}>
        {props.spielerEinsatz.spielposition}
      </div>
    </td>
    <td style={{textAlign: 'right', verticalAlign: 'middle'}}>
      {props.spielerEinsatz.rueckennummer}
    </td>
    <td colSpan={9} style={{textAlign: 'center', verticalAlign: 'middle'}}>
      &mdash;
    </td>
  </tr>;

  return (
    <tbody>
    {spielerRow}
    </tbody>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    pfade: state.pfade,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpielerkarteSpielerOhneDaten);
