import React, {useEffect, useState} from "react";
import ModalerDialog from "../ModalerDialog";

const Texte = {
  de: {
    titel: 'Link erstellen',
    text: 'Text',
    url: 'Url',
    ok: 'Link einfügen',
    abbrechen: 'Abbrechen'
  },
  fr: {
    titel: 'Créer un lien',
    text: 'Texte',
    url: 'Url',
    ok: 'Insérer un lien',
    abbrechen: 'Abandonner'
  },
  it: {
    titel: 'Crea collegamento',
    text: 'Testo',
    url: 'Url',
    ok: 'Inserisci link',
    abbrechen: 'Abortire'
  }
}

const regex = /(\[.+])?(\(.+\))?/

function parseInputText(context) {
  let selection = context.text.slice(context.start, context.end);
  let result = regex.exec(selection);
  let text = (result[1] || '').replaceAll(/[\[\]]/g, '');
  let url = (result[2] || '').replaceAll(/[\(\)]/g, '');

  if (result[0] == '') {
    text = selection;
  }

  return {text: text, url: url};
}

const LinkEinfuegen = ({context, locale, onHide, onChange}) => {
  const [linkText, setLinkText] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const texte = Texte[locale];

  useEffect(() => {
    const parsed = parseInputText(context);
    setLinkText(parsed.text);
    setLinkUrl(parsed.url);
  }, []);

  return (
    <ModalerDialog>
      <div className="modal">
        <div className="modal-header">
          <button type="button" className="close" onClick={() => onHide()}>&times;</button>
          <h3>{texte.titel}</h3>
        </div>
        <div className="modal-body container-fluid">
          <div className="row-fluid" style={{marginBottom: 10}}>
            <span className="span3"><label htmlFor="text" style={{fontWeight: 'bold'}}>{texte.text}</label></span>
            <span className="span9"><input type="text" id="text" value={linkText} onChange={ev => setLinkText(ev.currentTarget.value)} style={{width: '90%'}}/></span>
          </div>
          <div className="row-fluid">
            <span className="span3"><label htmlFor="url" style={{fontWeight: 'bold'}}>{texte.url}</label></span>
            <span className="span9"><input type="url" id="url" value={linkUrl} onChange={ev => setLinkUrl(ev.currentTarget.value)} style={{width: '90%'}}/></span>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn" onClick={() => onHide()}>{texte.abbrechen}</button>
          <button type="button" className="btn btn-primary" onClick={() => onChange(linkText, linkUrl)}>{texte.ok}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

export default LinkEinfuegen;
