import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {filtereAufgaben, formatiereDatumZeit} from "../../lib/formatierungen";
import TeamTeilnehmer from "./TeamTeilnehmer";
import {AufgabenModus} from "./AufgabeZuteilung";
import {AusbildungStatus} from "../../lib/constants";

const TeamAufgabenBewerten = ({team, aufgabenFilter, ausbildner, teamTeilnehmer, abschlussStatusListe, texte}) => {
  const [showTeilnehmer, setShowTeilnehmer] = useState(null);
  const sichtbareStatus = [...abschlussStatusListe.map(s => s.id), AusbildungStatus.Angemeldet, AusbildungStatus.Anwesend];
  const ausbildnerFiltered = ausbildner.filter(a => sichtbareStatus.includes(a.ausbildungStatusId)).map(a => a.id);
  const teilnehmer = teamTeilnehmer.filter(tt => tt.teamId === team.id && ausbildnerFiltered.includes(tt.ausbildner.id));
  let hatEinTeilnehmerAufgaben = false;

  useEffect(() => {
    // teilnehmer deselektieren bei filter-change
    setShowTeilnehmer(null);
  }, [aufgabenFilter]);
  
  const toggleTeamTeilnehmer = (tt) => {
    if (showTeilnehmer === tt) {
      setShowTeilnehmer(null);
    } else {
      setShowTeilnehmer(tt);
    }
  };
  
  return (
    <div className="block">
      <div className="header flex">
        <div className="spalte">
          <b>{team.bezeichnung}</b>
          &nbsp;<span className="label label-warning">{team.programmBlock.name}</span>
        </div>
        <div className="spalte">
          {`${texte.datumVon}: ${formatiereDatumZeit(team.von)}`}
          <br/>
          {`${texte.datumBis}: ${formatiereDatumZeit(team.bis)}`}
        </div>
      </div>
      <div className="content">
        {teilnehmer.length > 0 &&
        <div className="zeile btn-liste">
          {teilnehmer.map(tt => {
            const anzahlAufgabenGefiltert = filtereAufgaben(tt.aufgaben, aufgabenFilter).length;
            hatEinTeilnehmerAufgaben = hatEinTeilnehmerAufgaben || anzahlAufgabenGefiltert > 0;
            if (anzahlAufgabenGefiltert > 0) {
              return <button key={tt.id} className={`btn ${showTeilnehmer && tt === showTeilnehmer ? 'btn-primary' : ''}`} onClick={() => toggleTeamTeilnehmer(tt)}>{tt.ausbildner.name} ({anzahlAufgabenGefiltert})</button>;
            } else {
              return;
            }
          })}
        </div>
        }
        {teilnehmer.length === 0 &&
        <div className="alert alert-info">{texte.keineTeamZuordnungen}</div>
        }
        {teilnehmer.length > 0 && !hatEinTeilnehmerAufgaben &&
        <div className="alert alert-info">{texte.keineTeamZuordnungenMitAufgaben}</div>
        }
      </div>
      {showTeilnehmer &&
      <div className="content">
        <TeamTeilnehmer teilnehmer={showTeilnehmer} aufgabenModus={AufgabenModus.abschliessen} aufgabenFilter={aufgabenFilter}/>
      </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ausbildner: state.ausbildner,
    teamTeilnehmer: state.teamTeilnehmer,
    abschlussStatusListe: state.abschlussStatusListe,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamAufgabenBewerten);
