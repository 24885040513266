import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../../components/ModalerDialog";
import MarkdownEditor from "../../../../components/MarkdownEditor";
import {executeRequest} from "../../lib/fetchHelper";

const EmailSendenModal = ({rechnungen, onHide, api, locale, texte}) => {
  const [betreff, setBetreff] = useState('');
  const [nachricht, setNachricht] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [mailFehler, setMailFehler] = useState(null);

  const personen = useMemo(() => {
    // im sponsorenlauf hat der spieler-sponsor keine echte person id -> "uniqId" erstellen um namenskolliosionen zu vermeiden
    let liste = rechnungen.map(r => {
      return {
        ...r.person,
        uniqId: r.person.spielerSponsorId === undefined ? `person-${r.person.id}` : `sponsor-${r.person.spielerSponsorId}`
      };
    });
    return Object.values(liste.reduce((acc, obj) => ({ ...acc, [obj.uniqId]: obj }), {}));
  }, [rechnungen]);

  const [empfaenger, setEmpfaenger] = useState(personen.map(p => p.uniqId));
  const [sendenLaeuft, setSendenLaeuft] = useState(false);

  const emailSenden = () => {
    setMailFehler(null);
    if (empfaenger.length === 0 || betreff == '' || nachricht == '') {
      return;
    }
    const daten = {
      typ: 'email',
      betreff: betreff,
      nachricht: nachricht,
      anhaenge: attachments
    };
    daten.personenIds = personen.filter(p => empfaenger.includes(p.uniqId) && p.spielerSponsorId === undefined).map(p => p.id);
    daten.spielerSponsorenIds = personen.filter(p => empfaenger.includes(p.uniqId) && p.spielerSponsorId > 0).map(p => p.spielerSponsorId);
    
    setSendenLaeuft(true);
    return executeRequest(api.rechnungenSuche, api.token, 'POST', daten)
      .then(result => {
        if (result.ok) {
          schliessen();
        } else {
          setMailFehler(result.data.errors.map((fehler, index) => ({id: index, error: fehler})));
          setSendenLaeuft(false);
        }
      }).catch(ex => {
        setMailFehler([{id: 1, error: ex.toString()}])
        setSendenLaeuft(false);
      });
  };

  const schliessen = () => {
    onHide();
  };

  const toggleEmpfaenger = (person) => {
    if (empfaenger.includes(person.uniqId)) {
      setEmpfaenger(empfaenger.filter(p => p !== person.uniqId));
    } else {
      setEmpfaenger([...empfaenger, person.uniqId]);
    }
  };
  
  const erstelleAttachment = (ev) => {
    setAttachments([...attachments, ev.currentTarget.files[0]])
    ev.currentTarget.value = '';
  };
  
  const entferneAttachment = (id) => {
    setAttachments(attachments.filter(a => a.lastModified !== id))
  };
  
  const removeFehler = (id) => {
    setMailFehler(mailFehler.filter(f => f.id != id));
  };

  return (
    <ModalerDialog>
      <div className="modal breit-modal">
        <div className="modal-header">
          <button type="button" className="close" disabled={sendenLaeuft} onClick={schliessen}>&times;</button>
          <h3>{texte.titel}</h3>
        </div>
        <div className="modal-body">
          {mailFehler &&
          <div>
            {mailFehler.map(fehler => {
              return <div key={fehler.id} className="alert alert-error">
                <a className="close" onClick={() => removeFehler(fehler.id)}>×</a>
                <span>{fehler.error}</span>
              </div>;
            })}
          </div>
          }
          <table className="table attributes">
            <tbody>
            <tr>
              <td className="label-liste">
                <div>
                  <b>{texte.empfaenger}</b>
                </div>
                {personen.map(p => <span key={p.uniqId} disabled={!empfaenger.includes(p.uniqId)} className="label label-info" onClick={() => toggleEmpfaenger(p)}>{p.name}</span>)}
              </td>
            </tr>
            <tr>
              <td><input placeholder={texte.betreff} style={{width: 'calc(100% - 14px)'}} type="text" value={betreff} onChange={ev => setBetreff(ev.currentTarget.value)}/></td>
            </tr>
            <tr>
              <td><MarkdownEditor placeholder={texte.nachricht} value={nachricht} onChange={text => setNachricht(text)} markdownUrl={api.markdown} token={api.token} locale={locale}/></td>
            </tr>
            <tr>
              <td>
                <input type="file" className="btn" value="" onChange={erstelleAttachment} />
                <div className="button-liste" style={{marginTop: 2, marginLeft: -2}}>
                  {attachments.length > 0 && 
                    <>{attachments.map(a => <button key={a.lastModified} type="button" className="btn btn-small" onClick={() => entferneAttachment(a.lastModified)}>{a.name} | <i className="icon-trash"/></button>)}</>
                  }
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" disabled={sendenLaeuft} onClick={() => emailSenden()}>
            {texte.senden}
            {sendenLaeuft && <>&nbsp;<span className="ajax-spinner-klein inverted"/></>}
          </button>
          <button type="button" className="btn" disabled={sendenLaeuft} onClick={schliessen}>{texte.abbrechen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    api: state.api,
    locale: state.i18n.locale,
    texte: state.i18n.texte.emailSenden,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailSendenModal);
