import React from "react";

const FormRadioListe = ({name, value, eintraege, readonly = false, onRadioChange}) => {
  const wert = value || 0 // Default Value

  if (readonly) {
    const eintrag = eintraege.find(eintrag => eintrag.id == wert) || {};
    return <span>{eintrag.bezeichnung || ''}</span>;
  }

  return (
    <span className="control-group optional">
      {eintraege.map(eintrag => {
        const inputId = `${name}_${eintrag.id}`;
        return (
          <div key={eintrag.id} className="radio">
            <input type="radio" className="radio_buttons optional" id={inputId} name={name} value={eintrag.id} checked={wert === eintrag.id} onChange={() => onRadioChange(name, eintrag.id)}/>
            <label htmlFor={inputId}>{eintrag.bezeichnung}</label>
          </div>
        );
      })}
    </span>
  );
};

export default FormRadioListe;
