// @flow

import type {
  AppState,
  EinsatzState,
  SpielerState,
  SpielerEintrag,
  SpielerTips,
  TorhueterNote,
  TurnierState,
  TurnierSpielerEintrag,
} from "../../../state/stateTypes";
import type {Dispatch} from "../../../state/actionTypes";
import {apiRequestAbschicken} from "../../../state/modules/ajax";

import React from "react";
import {connect} from "react-redux";
import NoteDropdown from "../../NoteDropdown";

import {
  spielerKommentarfeldEinAusblenden,
  spielerTipsTSetzen,
  spielerTipsISetzen,
  spielerTipsPSetzen,
  spielerTipsSSetzen,
  spielerTipsEntwicklungSetzen,
  spielerTipsNoteSetzen,
  spielerTipsKommentarSetzen,
  spielerTorhueterNoteSetzen,
  spielerTorhueterNoteKommentarSetzen,
} from "../../../state/modules/spieler";
import Textfeld from "../../Textfeld";
import LoeschButton from "../LoeschButton";
import SpielerBadge from "../../SpielerBadge";

const TurnierTorhueterNoten = (props: {
  einsatz: EinsatzState,
  turnier: TurnierState,
  spielerliste: SpielerState,
  pfade: any,
  i18n: any,
  spielerKommentarfeldEinAusblenden: (number) => void,
  spielerTipsTSetzen: (number, string, SpielerTips) => void,
  spielerTipsISetzen: (number, string, SpielerTips) => void,
  spielerTipsPSetzen: (number, string, SpielerTips) => void,
  spielerTipsSSetzen: (number, string, SpielerTips) => void,
  spielerTipsNoteSetzen: (number, string, SpielerTips) => void,
  spielerTipsEntwicklungSetzen: (number, string, SpielerTips) => void,
  spielerTipsKommentarSetzen: (number, string, SpielerTips) => void,
  spielerTorhueterNoteSetzen: (number, number, number | null) => void,
  spielerTorhueterNoteKommentarSetzen: (number, number, string) => void,
  feldAnApiSchicken: (string, string, string | number | null, {}) => void,
  spieler: SpielerEintrag,
  spielerEinsatz: TurnierSpielerEintrag,
}) => {
  const aktiv = !props.einsatz.readonly;

  const requestDaten = {
    turnierId: props.turnier.id,
    spielerId: props.spieler.id,
    spielerTips: props.spieler.tips,
    torhueterNoten: props.spieler.torhueterNoten,
  };
  const successKlass = props.spieler.tips.t && props.spieler.tips.i && props.spieler.tips.p && props.spieler.tips.s ? "success" : "";

  const spielerRow = <tr className={successKlass}>
    <td style={{whiteSpace: 'nowrap', verticalAlign: 'middle'}}>
      <span className="label label-info">{props.spieler.passnummer}</span>
      {
        props.spielerEinsatz.spielfuehrer ? <span className="label label-important spielfuehrer">C</span> : ''
      }
    </td>
    <td style={{width: '130px', minWidth: '130px', maxWidth: '100px', verticalAlign: 'middle'}}>
      <SpielerBadge spieler={props.spieler}/>
    </td>
    <td>
      <div className="spielposition" style={{width: '110px', verticalAlign: 'middle'}}>
        {props.spielerEinsatz.spielposition || ''}
      </div>
    </td>
    <td style={{textAlign: 'right', verticalAlign: 'middle'}}>
      {props.spielerEinsatz.rueckennummer}
    </td>
    <td style={{width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle'}}>
      <NoteDropdown feldName="tipsT" feldLabel={props.i18n.tips.tLang} feldWert={props.spieler.tips.t || ''} einfaerben={true} requestDaten={requestDaten}
                    onValueChange={(neuerWert) => {
                      props.spielerTipsTSetzen(props.spieler.id, neuerWert, props.spieler.tips)
                    }}/>
    </td>
    <td style={{width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle'}}>
      <NoteDropdown feldName="tipsI" feldLabel={props.i18n.tips.iLang} feldWert={props.spieler.tips.i || ''} einfaerben={true} requestDaten={requestDaten}
                    onValueChange={(neuerWert) => {
                      props.spielerTipsISetzen(props.spieler.id, neuerWert, props.spieler.tips)
                    }}/>
    </td>
    <td style={{width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle'}}>
      <NoteDropdown feldName="tipsP" feldLabel={props.i18n.tips.pLang} feldWert={props.spieler.tips.p || ''} einfaerben={true} requestDaten={requestDaten}
                    onValueChange={(neuerWert) => {
                      props.spielerTipsPSetzen(props.spieler.id, neuerWert, props.spieler.tips)
                    }}/>
    </td>
    <td style={{width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle'}}>
      <NoteDropdown feldName="tipsS" feldLabel={props.i18n.tips.sLang} feldWert={props.spieler.tips.s || ''} einfaerben={true} requestDaten={requestDaten}
                    onValueChange={(neuerWert) => {
                      props.spielerTipsSSetzen(props.spieler.id, neuerWert, props.spieler.tips)
                    }}/>
    </td>
    <td style={{width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle'}}>
      <span className="durchschnitt">
        {props.spieler.tips.noteDurchschnitt ? props.spieler.tips.noteDurchschnitt.toFixed(1) : ''}
      </span>
    </td>
    <td style={{width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle'}}>
      <NoteDropdown feldName="tipsEntwicklung" feldLabel={props.i18n.tips.entwicklungLang} feldWert={props.spieler.tips.entwicklung || ''}
                    requestDaten={requestDaten} onValueChange={(neuerWert) => {
        props.spielerTipsEntwicklungSetzen(props.spieler.id, neuerWert, props.spieler.tips)
      }}/>
    </td>
    <td style={{width: '55px', padding: '0', textAlign: 'center', verticalAlign: 'middle'}}>
      <NoteDropdown feldName="tipsNote" feldLabel={props.i18n.tips.noteLang} feldWert={props.spieler.tips.note || ''} einfaerben={true} requestDaten={requestDaten}
                    onValueChange={(neuerWert) => {
                      props.spielerTipsNoteSetzen(props.spieler.id, neuerWert, props.spieler.tips)
                    }}/>
    </td>
    <td style={{width: '40px', textAlign: 'right', verticalAlign: 'middle'}}>
      &nbsp;
    </td>
    <td style={{width: '40px', textAlign: 'right', verticalAlign: 'middle'}}>
      <LoeschButton spieler={props.spieler}
                    spielerEinsatz={props.spielerEinsatz}
                    aktiv={aktiv}
                    requestDaten={requestDaten}/>
    </td>
  </tr>;

  return (
    <tbody>
    {spielerRow}
    <tr>
      <td colSpan="4">&nbsp;</td>
      <td colSpan="9">
        <Textfeld
          feldName="tipsKommentar"
          feldWert={props.spieler.tips.kommentar || ''}
          requestDaten={requestDaten}
          breite='403px'
          hoehe='80px'
          onChange={(event) => {
            props.spielerTipsKommentarSetzen(props.spieler.id, event.target.value, props.spieler.tips)
          }}
        />
      </td>
    </tr>
    {
      props.spieler.torhueterNoten.map((note: TorhueterNote) => {
        return (<tr key={'torhueterNote' + note.noteArtId + props.spieler.id}>
          <td>&nbsp;</td>
          <td colSpan="2">
            <strong>
              {note.name}{note.pflichtnote && "*"}
            </strong>
          </td>
          <td>
            <NoteDropdown feldName={'torhueterNote' + note.noteArtId} feldWert={note.note || ''} requestDaten={requestDaten} onValueChange={(neuerWert) => {
              props.spielerTorhueterNoteSetzen(props.spieler.id, note.noteArtId, neuerWert);
            }} forceReadonly={note.gesamtnote}/>
          </td>
          <td colSpan="9">
            <Textfeld
              feldName={'torhueterNoteKommentar' + note.noteArtId}
              feldWert={note.kommentar || ''}
              requestDaten={requestDaten}
              breite='403px'
              hoehe='60px'
              onChange={(event) => {
                props.spielerTorhueterNoteKommentarSetzen(props.spieler.id, note.noteArtId, event.target.value)
              }}
            />
          </td>
        </tr>);
      })
    }
    </tbody>);
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    turnier: state.turnier,
    spielerliste: state.spielerliste,
    pfade: state.pfade,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    spielerKommentarfeldEinAusblenden: (spielerId: number) => {
      dispatch(spielerKommentarfeldEinAusblenden(spielerId));
    },
    spielerTipsTSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsTSetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsISetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsISetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsPSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsPSetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsSSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsSSetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsEntwicklungSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsEntwicklungSetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsNoteSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsNoteSetzen(spielerId, neuerWert === '' ? null : parseFloat(neuerWert), altesTips));
    },
    spielerTipsKommentarSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsKommentarSetzen(spielerId, neuerWert, altesTips));
    },
    spielerTorhueterNoteSetzen: (spielerId: number, noteArtId: number, neuerWert: number | null) => {
      dispatch(spielerTorhueterNoteSetzen(spielerId, noteArtId, neuerWert));
    },
    spielerTorhueterNoteKommentarSetzen: (spielerId: number, noteArtId: number, neuerWert: string) => {
      dispatch(spielerTorhueterNoteKommentarSetzen(spielerId, noteArtId, neuerWert));
    },
    feldAnApiSchicken: (apiUrl: string, feldName: string, neuerWert: string | number | null, requestDaten: {}) => {
      dispatch(apiRequestAbschicken(apiUrl, 'PATCH', feldName, neuerWert, requestDaten, () => {
      }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TurnierTorhueterNoten);
