import React, {createRef, useState} from "react";
import {connect} from "react-redux";
import {arbeitsraumSpeichern} from "../state/arbeitsraumReducer";
import {ViewKontext} from "../lib/constants";

const ArbeitsraumEintragEdit = ({aufgabeZuteilung, team, teamTeilnehmer, ausbildung, texte, speichernClicked, arbeitsraumSpeichern}) => {
  const [titel, setTitel] = useState('');
  const [text, setText] = useState('');
  const [fisSenden, setFisSenden] = useState(ausbildung.kontext === ViewKontext.Klassenlehrer && !teamTeilnehmer);
  const fileInput = createRef();
  const [fileName, setFileName] = useState(undefined);
 
  const onFileChange = () => {
    if (fileInput.current.files.length > 0) {
      setFileName(fileInput.current.files[0].name);
    } else {
      setFileName(undefined);
    }
  };
  
  const onSpeichernClick = () => {
    const zusatzdaten = {
      titel: titel,
      text: text,
      fisSenden: fisSenden
    };
    if (team) {
      zusatzdaten['teamId'] = team.id;
    }
    if (teamTeilnehmer) {
      zusatzdaten['teamZuteilungId'] = teamTeilnehmer.id;
    }
    if (fileInput.current.files.length > 0) {
      zusatzdaten['file'] = fileInput.current.files[0];
    }
    arbeitsraumSpeichern(aufgabeZuteilung, zusatzdaten);
    setTitel('');
    setText('');
    fileInput.current.value = '';
    setFileName(undefined);
    speichernClicked();
  };
  
  if (ausbildung.bearbeitbar) {
    return (
      <div className="eintrag-box">
        <div className="zeile">
          <label>{texte.titel}</label>
          <input type="text" onChange={(ev) => setTitel(ev.currentTarget.value)} value={titel}/>
        </div>
        <div className="zeile">
          <label>{texte.text}</label>
          <textarea onChange={(ev) => setText(ev.currentTarget.value)} value={text} rows="5"/>
        </div>
        <div className="zeile">
          <label>{texte.file}</label>
          <input type="file" ref={fileInput} accept="*/*" className="hide" onChange={onFileChange}/>
          <span className="btn btn-upload" onClick={() => fileInput.current.click()}>{texte.fileButton}</span>
          {fileName &&
          <>
            &nbsp;<span>{fileName}</span>
          </>
          }
        </div>
        <div className="zeile">
          <label>
            <input id="cbxFisSenden" type="checkbox" onChange={(ev) => setFisSenden(ev.currentTarget.checked)} checked={fisSenden} style={ { marginTop: -4, marginRight: 4 } }/>
            {texte.fisSenden}
          </label>
        </div>
        <div className="zeile">
          <button className="btn btn-primary" onClick={onSpeichernClick}>{texte.neuerEintrag}</button>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state) => {
  return {
    ausbildung: state.ausbildung,
    texte: state.i18n.texte.arbeitsraum
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    arbeitsraumSpeichern: (aufgabeZuteilung, zusatzdaten) => { dispatch(arbeitsraumSpeichern(aufgabeZuteilung, zusatzdaten)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArbeitsraumEintragEdit);
