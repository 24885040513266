// @flow

import type {
  AppState,
  SpielerEintrag,
  SpielerkarteEintrag,
} from "../../../state/stateTypes";
import type { Dispatch } from "../../../state/actionTypes";

import React from "react";
import { connect } from "react-redux";
import SpielerBadge from "../../SpielerBadge";

const WettkampfleistungSpielerReadonly = (props: {
  pfade: any,
  i18n: any,
  spieler: SpielerEintrag,
  spielerEinsatz: SpielerkarteEintrag,
}) => {
  return <tbody>
  <tr>
    <td style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }}>
      <span className="label label-info">{props.spieler.passnummer}</span>
      {
        props.spielerEinsatz.spielfuehrer ? <span className="label label-important spielfuehrer">C</span> : ''
      }
    </td>
    <td style={{ verticalAlign: 'middle' }}>
      <SpielerBadge spieler={props.spieler}/>
    </td>
    <td>
      <div className="spielposition" style={{ width: '110px', verticalAlign: 'middle' }}>
        {props.spielerEinsatz.spielposition.name}
      </div>
    </td>
    <td style={{ width: '50px', textAlign: 'right', verticalAlign: 'middle' }}>
      {props.spielerEinsatz.rueckennummer}
    </td>
    <td colSpan={7} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
      &mdash;
    </td>
  </tr>
  </tbody>;
};

const mapStateToProps = (state: AppState) => {
  return {
    pfade: state.pfade,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WettkampfleistungSpielerReadonly);
