import React, {useEffect} from 'react';
import Room from "./Room";
import {connect} from "react-redux";
import {liveSupportAnzeige} from "../lib/helpers";
import {openRoom} from "../state/rooms";
import {setCurrentRoom} from "../state/settings";

const AnsichtAnfrage = ({rooms, settings, texte, openRoom, setCurrentRoom}) => {

  useEffect(() => {
    liveSupportAnzeige(settings.supportAnwesend);
  }, [settings.supportAnwesend]);

  useEffect(() => {
    if (rooms.length > 0) {
      setCurrentRoom(rooms[0]);
    } else {
      setCurrentRoom(null);
    }
  }, [rooms]);

  if (settings.currentRoom === null) {
    const info = <div>
      <h4>{texte.info1}</h4>
      <p>{texte.info2}</p>
      <p>{texte.info3}</p>
      <p>{texte.info4}</p>
    </div>;
    if (settings.supportAnwesend) {
      return <>{info}<button className="btn btn-primary" onClick={() => openRoom()}>{texte.anfrageStarten}</button></>;
    } else {
      return <>{info}<div className="alert alert-warning">{texte.liveSupportNichtVerfuegbar}</div></>;
    }
  } else {
    return <Room room={settings.currentRoom}/>;
  }
};

const mapStateToProps = (state) => {
  return {
    rooms: state.rooms,
    settings: state.settings,
    texte: state.i18n.ansichtAnfrage,
    api: state.api,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openRoom: () => { dispatch(openRoom()); },
    setCurrentRoom: (room) => { dispatch(setCurrentRoom(room)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnsichtAnfrage);
