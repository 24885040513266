import React from 'react'
import {connect} from "react-redux";
import SpielerMenu from "./SpielerMenu";
import EreignisIcon from "./EreignisIcon";
import {feldspielerMenuShow, feldspielerMenuSichtbar} from "../state/modules/editMarkierer";

const berechneSpielposition = (pos) => {
  // spielposition min/max werte: 
  // zeile: 3 / 25
  // spalte: 2 / 26

  // zeile: 50% höhe bei zeile=14 (wert vom torwart)
  // spalte: 84% von links / rechts bei spalte=22 (wert von sturmspitze)
  return {
    zeile: 50 / 14 * (pos.zeile),
    spalte: 84 / 22 * (pos.spalte - 5)
  };
};

const Feldspieler = ({zustandSpieler, teamSpielerkarte, anzeige, penaltySchiessen, positionBenutzen, positionen, placeholder, feldspielerMenu, feldspielerMenuShow}) => {
  const spieler = teamSpielerkarte.spieler.find(s => s.id === zustandSpieler.id && s.unregistrierter_spieler === zustandSpieler.unregistriert);
  const menuSichtbar = feldspielerMenuSichtbar(feldspielerMenu, spieler);

  const showMenuClick = (event) => {
    event.stopPropagation();
    feldspielerMenuShow(spieler);
  };

  let cssKlasse = `feld-spieler ${anzeige}`;
  let style = {};
  if (penaltySchiessen === true) {
    cssKlasse += " penalty-schiessen";
  } else {
    if (positionBenutzen) {
      const position = positionen.find(p => p.id === zustandSpieler.position_id);
      if (position) {
        let grid = berechneSpielposition(position);
        style = {};
        if (anzeige === 'links') {
          style.left = `${grid.spalte}%`;
          style.top = `calc(${grid.zeile}% - 38px)`
          if (grid.zeile > 50) {
            cssKlasse += " untere-feldhaelfte";
          }
        } else {
          style.right = `${grid.spalte}%`;
          style.bottom = `calc(${grid.zeile}% - 38px)`
          if (grid.zeile <= 50) {
            cssKlasse += " untere-feldhaelfte";
          }
        }
      } else {
        cssKlasse += " keine-position";
      }
    }
  }
  if (menuSichtbar) {
    cssKlasse += " menu-offen";
  }
  const nrFarbe = "#000";

  return (
    <span className={cssKlasse} style={style} onClick={showMenuClick}>
      <div className="foto-container">
        <img src={spieler.bild_url || placeholder}/>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 30" className="nr">
        <polygon fill={nrFarbe} points="24 1 10 1 1 6 4.5 12.5 7 12 7 29 27 29 27 12 29.5 12.5 33 6 24 1"></polygon>
        <text x="17" y="19.5" fill="#FFF">{spieler.rueckennummer}</text>
      </svg>
      <span className="name" title={spieler.name}>{spieler.name}</span>
      {zustandSpieler.ereignisse.length > 0 &&
      <span className="ereignisse">
        {
          zustandSpieler.ereignisse
            .map((ereignisTyp, index) => <EreignisIcon key={`icon-${index}-${ereignisTyp}-${spieler.id}`} ereignisTyp={ereignisTyp}/>)
            .reverse()
        }
      </span>
      }
      {spieler.spielfuehrer &&
      <span className="captain">C</span>
      }
      <SpielerMenu spieler={spieler} teamSpielerkarte={teamSpielerkarte}/>
    </span>
  );
};

const mapStateToProps = (state) => {
  return {
    positionen: state.spielfeld.positionen,
    placeholder: state.spielfeld.avatar_placeholder,
    feldspielerMenu: state.editMarkierer.feldspieler,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    feldspielerMenuShow: (spieler) => {
      dispatch(feldspielerMenuShow(spieler));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Feldspieler);
