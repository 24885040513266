import React from "react";
import ControlGroup from "./ControlGroup";
import {formatiereDatum} from "../../lib/formatierungen";

const Stammdaten = ({person, texte, sprachen, korrespondenzsprachen, onWertChange}) => {
  // INFO: natürliche / juristische person muss unterschieden werden

  return (
    <section>
      <h3 className="page-section" style={{margin: 0}}>{texte.titel}</h3>
      <div className="row-fluid">
        <div className="span6">
          {person.natuerlich &&
          <>
            <ControlGroup labelText={texte.anrede} htmlFor="anrede">
              <input type="text" id="anrede" value={person.anrede} disabled={true}/>
            </ControlGroup>
            <ControlGroup labelText={texte.vorname} htmlFor="vorname">
              <input type="text" id="vorname" value={person.vorname} disabled={true}/>
            </ControlGroup>
            <ControlGroup labelText={texte.nachname} htmlFor="nachname" errors={person.errors.geburtsort}>
              <input type="text" id="nachname" value={person.nachname} disabled={true} />
            </ControlGroup>
            <ControlGroup labelText={texte.geburtsdatum} htmlFor="geburtsdatum">
              <div className="input-prepend">
                <span className="add-on"><i className="icon-calendar"></i></span>
                <input type="text" id="geburtsdatum" value={formatiereDatum(person.geburtsdatum)} disabled={true} style={{width: 100}} />
              </div>
            </ControlGroup>
            <ControlGroup labelText={texte.geburtsort} htmlFor="geburtsort">
              <input type="text" id="geburtsort" value={person.geburtsort || ''} onChange={event => onWertChange('geburtsort', event.currentTarget.value)}/>
            </ControlGroup>
          </>
          }
          {person.natuerlich ||
          <>
            <ControlGroup labelText={texte.firmenname} htmlFor="name">
              <input type="text" id="name" value={person.nachname} disabled={true}/>
            </ControlGroup>
            <ControlGroup labelText={texte.zusatz} htmlFor="vorname">
              <input type="text" id="vorname" value={person.vorname || ''} disabled={true}/>
            </ControlGroup>
            <ControlGroup labelText={texte.gruendung} htmlFor="geburtsdatum">
              <div className="input-prepend">
                <span className="add-on"><i className="icon-calendar"></i></span>
                <input type="text" id="geburtsdatum" value={formatiereDatum(person.geburtsdatum)} disabled={true} style={{width: 100}} />
              </div>
            </ControlGroup>
          </>
          }
        </div>

        <div className="span6">
          <ControlGroup labelText={person.natuerlich ? texte.nationalitaet : texte.firmensitz} htmlFor="nationalitaet">
            <input type="text" id="nationalitaet" value={person.nationalitaet} disabled={true}/>
          </ControlGroup>
          <ControlGroup labelText={texte.sprache} htmlFor="sprache" errors={person.errors.sprache}>
            <select id="sprache" value={person.sprache_id} onChange={event => onWertChange('sprache_id', event.currentTarget.value)}>
              {sprachen && sprachen.map(s => <option key={s.id} value={s.id}>{s.bezeichnung}</option>)}
            </select>
          </ControlGroup>
          <ControlGroup labelText={texte.korrespondenzsprache} htmlFor="korrespondenzsprache" errors={person.errors.korrespondenzsprache}>
            <select id="korrespondenzsprache" value={person.korrespondenzsprache_id} onChange={event => onWertChange('korrespondenzsprache_id', event.currentTarget.value)}>
              {korrespondenzsprachen && korrespondenzsprachen.map(s => <option key={s.id} value={s.id}>{s.bezeichnung}</option>)}
            </select>
          </ControlGroup>
          {person.natuerlich &&
          <>
            <ControlGroup labelText={texte.beruf} htmlFor="beruf" errors={person.errors.beruf}>
              <input type="text" id="beruf" value={person.beruf || ''} onChange={event => onWertChange('beruf', event.currentTarget.value)}/>
            </ControlGroup>
            <ControlGroup labelText={texte.ahv_nr} htmlFor="ahv_nr" errors={person.errors.ahv_nr}>
              <input type="text" id="ahv_nr" value={person.ahv_nr || ''} onChange={event => onWertChange('ahv_nr', event.currentTarget.value)}/>
            </ControlGroup>
          </>
          }
        </div>
      </div>
    </section>
  );
};
export default Stammdaten;
