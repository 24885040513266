
function preloader(options) {
  return {
    spielerliste: options.spielerliste,
    bewertungen: [],
    selektiert: { bewertung: null },
    listen: options.listen,
    api: options.api,
    i18n: options.i18n,
    rollbar: options.rollbar,
  };
}

export default preloader;
