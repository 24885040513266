import {combineReducers} from 'redux';
import {dummyReducer} from "../../../lib/datenhaltung";
import rollbarReducer from "../../../reducers/rollbar";
import rechnungslaufReducer from "./modules/rechnungslauf";
import rechnungenReducer from "./modules/rechnungen";
import rechnungenVorschauReducer from "./modules/rechnungenVorschau";
import bexioReducer from "./modules/bexio";
import laufendeRequestsReducer from "./modules/laufendeAbfragen";
import teamsReducer from "./modules/teams";

export default combineReducers({
  rechnungslauf: rechnungslaufReducer,
  konfiguration: dummyReducer,
  rechnungen: rechnungenReducer,
  rechnungenVorschau: rechnungenVorschauReducer,
  teams: teamsReducer,
  typen: dummyReducer,
  darf: dummyReducer,
  bexio: bexioReducer,
  laufendeRequests: laufendeRequestsReducer,
  api: dummyReducer,
  i18n: dummyReducer,
  rollbar: rollbarReducer,
});
