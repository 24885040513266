// @flow

import type {AppState, ProtokollEintrag} from "../../../state/stateTypes";
import type {Dispatch} from "../../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import EintragLoeschen from "./EintragLoeschen";
import MinuteUndTeams from "./MinuteUndTeams";
import TeamSpieler from "./TeamSpieler";

import {
  protokollWertSetzenMinute,
  protokollWertSetzenZusatzminute,
  protokollSortieren,
  protokollWertSetzenTeam,
  protokollWertSetzenSpielerId,
  protokollWertSetzenBemerkung,
  protokollEintragLoeschen,
} from "../../../state/modules/protokoll";
import VideoAnzeige from "../VideoAnzeige";

const AllgemeinerEintrag = (props: {
  protokollWertSetzenMinute: (string, string) => void,
  protokollWertSetzenZusatzminute: (string, string) => void,
  protokollSortieren: (string) => void,
  protokollWertSetzenTeam: (string, boolean, boolean) => void,
  protokollWertSetzenSpielerId: (string, string) => void,
  protokollWertSetzenBemerkung: (string, string) => void,
  protokollEintragLoeschen: (string) => void,
  eintrag: ProtokollEintrag,
  i18nTitel: string,
  iconPfad: string,
  spielerRendern: boolean,
  textfeldRendern: boolean,
  readonly: boolean,
}) => {
  const aktiv = !props.readonly && props.eintrag.id !== null;
  let textfeld = () => {
    return <div style={{padding: '2px 0px 6px 44px'}}>
      <input type="text" style={{width: '693px'}} value={props.eintrag.bemerkung || ''} disabled={aktiv ? null : "disabled"} onChange={(event) => {
        props.protokollWertSetzenBemerkung(props.eintrag.key, event.target.value);
      }}/>
    </div>;
  };
  let spielerDropdown = () => {
    return <TeamSpieler aktiv={aktiv}
                        eintrag={props.eintrag}
                        auswechselSpieler={false}
                        spielerChange={(event) => {
                          props.protokollWertSetzenSpielerId(props.eintrag.key, event.target.value);
                        }}/>;
  };

  return (
    <tr>
      <td className={props.eintrag.highlight ? "protokoll-highlight" : ""}>
        <div style={{display: 'flex', alignItems: 'center', height: '45px'}}>
          <div style={{width: '45px'}} rel="tooltip" data-original-title={props.i18nTitel}>
            <img src={props.iconPfad} alt={props.i18nTitel}/>
          </div>
          <MinuteUndTeams aktiv={aktiv}
                          eintrag={props.eintrag}
                          minuteChange={(event) => {
                            props.protokollWertSetzenMinute(props.eintrag.key, event.target.value)
                          }}
                          zusatzminuteChange={(event) => {
                            props.protokollWertSetzenZusatzminute(props.eintrag.key, event.target.value)
                          }}
                          protokollSortieren={(event) => {
                            props.protokollSortieren(props.eintrag.key)
                          }}
                          heimTeamKlick={(event) => {
                            if (!props.eintrag.heimTeam) {
                              props.protokollWertSetzenTeam(props.eintrag.key, true, false);
                            }
                          }}
                          gastTeamKlick={(event) => {
                            if (!props.eintrag.gastTeam) {
                              props.protokollWertSetzenTeam(props.eintrag.key, false, true);
                            }
                          }}/>
          {props.spielerRendern ? spielerDropdown() : <></>}
        </div>
        {
          props.textfeldRendern ? textfeld() : <></>
        }
      </td>
      <td className={props.eintrag.highlight ? "protokoll-highlight" : ""}>
        {
          !props.readonly &&
          <EintragLoeschen aktiv={aktiv} loeschenKlick={(event) => {
            props.protokollEintragLoeschen(props.eintrag.key)
          }}/>
        }
        <VideoAnzeige eintrag={props.eintrag}/>
      </td>
    </tr>
  );
};

AllgemeinerEintrag.defaultProps = {
  textfeldRendern: true,
  spielerRendern: true,
};

const mapStateToProps = (state: AppState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    protokollWertSetzenMinute: (eintragKey: string, wert: string) => {
      let parsedWert = wert === "" ? null : parseInt(wert);
      dispatch(protokollWertSetzenMinute(eintragKey, parsedWert, false));
    },
    protokollWertSetzenZusatzminute: (eintragKey: string, wert: string) => {
      let parsedWert = wert === "" ? 0 : parseInt(wert);
      dispatch(protokollWertSetzenZusatzminute(eintragKey, parsedWert, false));
    },
    protokollSortieren: (eintragKey: string) => {
      dispatch(protokollSortieren(eintragKey, true));
    },
    protokollWertSetzenTeam: (eintragKey: string, heimTeam: boolean, gastTeam: boolean) => {
      dispatch(protokollWertSetzenTeam(eintragKey, heimTeam, gastTeam, false));
    },
    protokollWertSetzenSpielerId: (eintragKey: string, wert: string) => {
      let parsedWert = wert === "" ? null : parseInt(wert);
      dispatch(protokollWertSetzenSpielerId(eintragKey, parsedWert, false));
    },
    protokollWertSetzenBemerkung: (eintragKey: string, wert: string) => {
      dispatch(protokollWertSetzenBemerkung(eintragKey, wert));
    },
    protokollEintragLoeschen: (eintragKey: string) => {
      dispatch(protokollEintragLoeschen(eintragKey, false));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllgemeinerEintrag);
