// @flow

import type {AppState, EinsatzState, NavigationState, StammdatenState, SpielState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import TeamSelektion from "./TeamSelektion";
import NoteDropdown from "../NoteDropdown";
import {
  einsatzNoteSetzen,
  einsatzNoteKommentarAnzeigen,
  einsatzNoteKommentarVerstecken,
  einsatzNoteKommentarAendern,
} from "../../state/modules/einsatz";
import Textfeld from "../Textfeld";

const Noten = (props: {
  navigation: NavigationState,
  einsatz: EinsatzState,
  spiel: SpielState,
  i18n: any,
  stammdaten: StammdatenState,
  einsatzNoteSetzen: (number | null, boolean, number, number, string) => void,
  einsatzNoteKommentarAnzeigen: (number, string) => void,
  einsatzNoteKommentarVerstecken: (number, string) => void,
  einsatzNoteKommentarAendern: (number, string, string) => void,
}) => {
  let relevanteNoten = props.einsatz.noten.filter((note) => {
    return (props.navigation.aktivesNotenTeam === 'heim' && note.heimTeam)
      || (props.navigation.aktivesNotenTeam === 'gast' && note.gastTeam);
  });
  const hintergrundFarben = ['#eeeeee', '#ffffff'];
  let hintergrundFarbIndex = 0;
  const teamId = props.navigation.aktivesNotenTeam === 'heim' ? props.spiel.heimTeamId : props.spiel.gastTeamId;
  const noten = Object.keys(props.i18n.notenKategorien).map((kategorieId) => {
    const kommentarRequestDaten = {notenTeam: props.navigation.aktivesNotenTeam, kategorieId: kategorieId};
    const kategorieText = props.i18n.notenKategorien[kategorieId];
    const notenSpalten = [];
    const kategorieKey = props.navigation.aktivesNotenTeam + kategorieId;
    let hatUmbruch = false;
    let anzahlNotenSpalten = 0;
    let kommentar = '';
    let kommentarAnzeigen = false;
    relevanteNoten.forEach((note) => {
      if (note.kategorieId.toString() === kategorieId) {
        hatUmbruch = hatUmbruch || note.umbruch;
        anzahlNotenSpalten += 1;
        const artKey = props.navigation.aktivesNotenTeam + note.kategorieId + note.artId;
        if (note.erlaubt) {
          kommentarAnzeigen = kommentarAnzeigen || note.kommentarAnzeigen;
          if (!!note.kommentar) {
            kommentar = note.kommentar;
          }
          const requestDaten = {notenTeam: props.navigation.aktivesNotenTeam, kategorieId: note.kategorieId, artId: note.artId};
          let hatTeilnoten = false;
          let alleTeilnotenVorhanden = true;
          let teilnotenHabenTeilnoten = false;
          props.stammdaten.notenBerechnung.forEach((link: { quelle: number, ziel: number }) => {
            if (link.ziel === note.kategorieId) {
              hatTeilnoten = true;
              props.einsatz.noten.forEach((andereNote) => {
                if (andereNote.erlaubt && andereNote.kategorieId === link.quelle && andereNote.teamId === note.teamId) {
                  alleTeilnotenVorhanden = alleTeilnotenVorhanden && andereNote.note;
                  props.stammdaten.notenBerechnung.forEach((link: { quelle: number, ziel: number }) => {
                    if (link.ziel === andereNote.kategorieId) {
                      teilnotenHabenTeilnoten = true;
                    }
                  });
                }
              });
            }
          });
          const forceReadonly = hatTeilnoten && (alleTeilnotenVorhanden || teilnotenHabenTeilnoten);
          notenSpalten.push(<td key={artKey} style={{textAlign: 'center'}}>
            <NoteDropdown feldName="einsatzNote" feldWert={note.note || ''} forceReadonly={forceReadonly} requestDaten={requestDaten}
                          onValueChange={(neuerWert) => {
                            props.einsatzNoteSetzen(note.teamId, note.gesamtnote, note.kategorieId, note.artId, neuerWert);
                          }}/>
          </td>);
        } else {
          notenSpalten.push(<td key={artKey} style={{textAlign: 'center'}}>
            &mdash;
          </td>);
        }
      }
    });
    let style = {};
    style['backgroundColor'] = hintergrundFarben[hintergrundFarbIndex];
    let styleErsteZeile = {...style};
    if (hatUmbruch) {
      style['borderBottom'] = '2px solid black';
      hintergrundFarbIndex = (hintergrundFarbIndex + 1) % 2;
    }

    if (kommentarAnzeigen) {
      return (<>
        <tr key={kategorieKey + '_1'} style={styleErsteZeile}>
          <td>{kategorieText}</td>
          {notenSpalten}
          <td>
            <button name="button" type="button" className="btn btn-mini kommentar" onClick={() => props.einsatzNoteKommentarVerstecken(teamId, kategorieId)}>
              <i className="icon-comment"/>
            </button>
          </td>
        </tr>
        <tr key={kategorieKey + '_2'} style={style}>
          <td>&nbsp;</td>
          <td colSpan={1 + anzahlNotenSpalten}>
            <Textfeld
              feldName="einsatzNotenKommentar"
              feldWert={kommentar || ''}
              requestDaten={kommentarRequestDaten}
              breite='340px'
              onChange={(event) => {
                props.einsatzNoteKommentarAendern(teamId, kategorieId, event.target.value)
              }}
            />
          </td>
        </tr>
      </>);
    } else {
      return (<>
        <tr key={kategorieKey} style={style}>
          <td>{kategorieText}</td>
          {notenSpalten}
          <td>
            <button name="button" type="button" className="btn btn-mini kommentar" onClick={() => props.einsatzNoteKommentarAnzeigen(teamId, kategorieId)}>
              <i className="icon-comment"/>
            </button>
          </td>
        </tr>
      </>);
    }
  });

  return (
    <div className="tab-pane">
      <TeamSelektion/>
      <table className="table">
        <thead>
        <tr>
          <th>{props.i18n.subtitel.noteKategorie}</th>
          {
            Object.keys(props.i18n.notenArten).map((artId) => {
              return <th key={"artHeader" + artId}>{props.i18n.notenArten[artId]}</th>;
            })
          }
        </tr>
        </thead>
        <tbody>{noten}</tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    navigation: state.navigation,
    einsatz: state.einsatz,
    spiel: state.spiel,
    i18n: state.i18n,
    stammdaten: state.stammdaten,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    einsatzNoteKommentarAnzeigen: (teamId: number, kategorieId: string) => {
      dispatch(einsatzNoteKommentarAnzeigen(teamId, parseInt(kategorieId)));
    },
    einsatzNoteKommentarVerstecken: (teamId: number, kategorieId: string) => {
      dispatch(einsatzNoteKommentarVerstecken(teamId, parseInt(kategorieId)));
    },
    einsatzNoteKommentarAendern: (teamId: number, kategorieId: string, kommentar: string) => {
      dispatch(einsatzNoteKommentarAendern(teamId, parseInt(kategorieId), kommentar));
    },
    einsatzNoteSetzen: (teamId: number | null, gesamtnote: boolean, kategorieId: number, artId: number, wert: string) => {
      dispatch(einsatzNoteSetzen(teamId, gesamtnote, kategorieId, artId, wert, true, true));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Noten);
