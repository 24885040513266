import React, {useEffect, useMemo, useState} from 'react'
import {connect} from "react-redux";
import Tooltip from "../../../components/Tooltip";
import {berechneSpielUndZusatzminute, EREIGNIS_SUBTYP, EREIGNIS_TYP} from "../lib/spielablauf";
import {generischesEreignisErstellen} from "../state/modules/spielverlauf";

const TickerEreignisGenerisch = ({heimOderGast, spielverlauf, laufendeSpielzeit, heartbeat, liga, ereignisTypen, texte, generischesEreignisErstellen, onClose}) => {
  const [minuteValue, setMinuteValue] = useState(0);
  const [zusatzminuteValue, setZusatzminuteValue] = useState(0);
  const [sekundenValue, setSekundenValue] = useState(0);
  const [ereignisTypValue, setEreignisTypValue] = useState(EREIGNIS_TYP.default);
  const [ereignisSubtypValue, setEreignisSubtypValue] = useState(EREIGNIS_SUBTYP.default);
  const [torZumValue, setTorZumValue] = useState(null);

  useEffect(() => {
    // spielminute nur initial setzen!
    const laufendeMinute = Math.floor(laufendeSpielzeit / 60) + 1;
    let [minute, zusatzminute] = berechneSpielUndZusatzminute(heartbeat.spiel.periode, liga, laufendeMinute);
    setMinuteValue(minute);
    setZusatzminuteValue(zusatzminute);
    setSekundenValue(laufendeSpielzeit % 60);
  }, []);

  const toreFuerAssist = useMemo(() => {
    setTorZumValue(null);
    if (ereignisTypValue == EREIGNIS_TYP.assist) {
      return spielverlauf
        .filter(e => e.typ_id === EREIGNIS_TYP.tor && e.team_id == heimOderGast.team.id)
        .map(e => e.zwischenresultat);
    } else {
      return null;
    }
  }, [ereignisTypValue]);

  // event handlers

  const onMinuteChange = (event) => {
    let minute = parseInt(event.target.value, 10);
    if (!isNaN(minute)) {
      // TODO: min / max werte prüfen?
      minute = Math.max(minute, 1);
      minute = Math.min(minute, 120);
      setMinuteValue(minute);
      if (!istZusatzminuteMoeglich(minute)) {
        setZusatzminuteValue(0);
      }
    }
  };

  const onZusatzminuteChange = (event) => {
    const zusatzminute = parseInt(event.target.value, 10);
    if (!isNaN(zusatzminute)) {
      setZusatzminuteValue(zusatzminute);
    }
  };

  const onSekundenChange = (event) => {
    let sekunden = parseInt(event.target.value, 10);
    if (!isNaN(sekunden)) {
      sekunden = Math.max(sekunden, 0);
      sekunden = Math.min(sekunden, 59);
      setSekundenValue(sekunden);
    }
  };

  const onEreignisTypChange = (event) => {
    setEreignisTypValue(parseInt(event.target.value, 10));
    // subtyp-value auf ersten eintrag setzen
    let ereignisTyp = ereignisTypen.find(et => et.id == event.target.value);
    if (ereignisTyp) {
      setEreignisSubtypValue(ereignisTyp.subactions[0].id);
    }
  };

  const onEreignisSubtypChange = (event) => {
    setEreignisSubtypValue(event.target.value);
  };

  const onTorZumChange = (event) => {
    setTorZumValue(event.target.value);
  };

  const onSpeichernClick = () => {
    if (ereignisTypValue == 0) {
      return; // muss zum speichern ausgewählt sein!
    }

    let daten = {
      typ_id: ereignisTypValue,
      subtyp_id: ereignisSubtypValue,
      minute: minuteValue,
      zusatzminute: zusatzminuteValue,
      sekunden: sekundenValue,
    };
    
    if (ereignisTypValue == EREIGNIS_TYP.assist) {
      if (torZumValue === null) {
        return; // zwischenresultat muss bei assist ausgewählt sein
      }
      daten.zwischenresultat = torZumValue;
    }

    generischesEreignisErstellen(heimOderGast, daten);
    onClose();
  };

  // helpers
  const istZusatzminuteMoeglich = (minute) => {
    return liga.periodenEndeMinuten.indexOf(minute) >= 0;
  };

  let ereignisTyp = ereignisTypen.find(et => et.id == ereignisTypValue);

  return (
    <div className="eintrag edit">
      <div className="flex-row">
        <select value={ereignisTypValue} onChange={onEreignisTypChange}>
          <option value={0}></option>
          {ereignisTypen.map(et => <option key={et.id} value={et.id}>{et.title}</option>)}
        </select>
      </div>
      {ereignisTypValue != EREIGNIS_TYP.default && ereignisTyp && ereignisTyp.subactions.length > 1 &&
        <div className="flex-row">
          <select value={ereignisSubtypValue} onChange={onEreignisSubtypChange}>
            {ereignisTyp.subactions.map(est => <option key={est.id} value={est.id}>{est.title}</option>)}
          </select>
        </div>
      }
      {toreFuerAssist && 
        <div className="flex-row">
          <select value={torZumValue} onChange={onTorZumChange}>
            <option></option>
            {toreFuerAssist.map(tore => <option key={tore} value={tore}>{tore}</option>)}
          </select>
        </div>
      }
      <div className="flex-row">
        <Tooltip className="input-prepend contentbox" content={texte.spielminute}>
          <span className="add-on">
            <i className="icon-time"/>
          </span>
          <input type="text" value={minuteValue} onChange={onMinuteChange}/>
        </Tooltip>
        <Tooltip className="input-prepend contentbox" content={texte.zusatzminute}>
          <span className="add-on">
            <i className="icon-time"/>
            <i className="icon-plus"/>
          </span>
          <input type="text" value={zusatzminuteValue} onChange={onZusatzminuteChange} disabled={istZusatzminuteMoeglich(minuteValue) === false}/>
        </Tooltip>
        <Tooltip className="input-prepend contentbox" content={texte.sekunden}>
          <span className="add-on">
            <i className="icon-time"/>
          </span>
          <input type="text" value={sekundenValue} onChange={onSekundenChange}/>
        </Tooltip>
      </div>
      <div className="flex-row">
        <button className="btn btn-primary" onClick={onSpeichernClick}>{texte.speichern}</button>
        <button className="btn" onClick={onClose}>{texte.abbrechen}</button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    laufendeSpielzeit: state.spielanzeige.laufendeSpielzeit,
    spielverlauf: state.spielverlauf,
    heartbeat: state.heartbeat,
    liga: state.liga,
    ereignisTypen: state.spielfeld.ereignisse.ingame,
    texte: state.i18n.ticker,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    generischesEreignisErstellen: (heimOderGast, ereignisDaten) => { dispatch(generischesEreignisErstellen(heimOderGast, ereignisDaten)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TickerEreignisGenerisch);
