import React, {useRef} from "react";
import {connect} from "react-redux";
import {formatiereDatumZeit, groupBy} from "../../lib/formatierungen";
import {teamZuteilungDelete, teamZuteilungSpeichern} from "../../state/lehrerTeamTeilnehmerReducer";
import {createZeitslotKey, Zeitslot} from "../../lib/models";
import {AusbildungStatus} from "../../lib/constants";

const TeilnehmerKlasseGrid = ({ausbildner, teams, teamTeilnehmer, abschlussStatusListe, texte, teamZuteilungSpeichern, teamZuteilungLoeschen}) => {
  const map = groupBy(teams, tt => createZeitslotKey(tt));
  const zeitslots = [...map.values()].map(t => new Zeitslot(t));
  const scrollContainer = useRef(null);
  const sichtbareStatus = [...abschlussStatusListe.map(s => s.id), AusbildungStatus.Angemeldet, AusbildungStatus.Anwesend];
  const ausbildnerFiltered = ausbildner.filter(a => sichtbareStatus.includes(a.ausbildungStatusId));

  const bearbeitenClick = (teilnehmer, klasse, teamTeilnehmer) => {
    if (teamTeilnehmer) {
      teamZuteilungLoeschen(teamTeilnehmer);
    } else {
      teamZuteilungSpeichern(klasse, teilnehmer, {});
    }
  };

  const alleSelektieren = (team, selektiert) => {
    ausbildnerFiltered.forEach(a => {
      const zuordnung = teamTeilnehmer.filter(tt => tt.ausbildner.id === a.id).find(tt => tt.teamId === team.id);
      if (selektiert) {
        if (!zuordnung) {
          teamZuteilungSpeichern(team, a, {});
        }
      } else {
        if (zuordnung) {
          teamZuteilungLoeschen(zuordnung);
        }
      }
    });
  };

  const scrollClick = (betrag) => {
    scrollContainer.current.scrollLeft += betrag;
  };
  
  const machZweizeilen = (text) => {
    let position = 0;
    const a = [];
    const b = [];
    const split = text.split(' ');
    const mitte = Math.ceil(text.length / 2) + split.length - 1;
    split.forEach((s, index) => {
      position += s.length + 1;
      if (index > 0 && position > mitte) {
        b.push(s);
      } else {
        a.push(s);
      }
    });
    return [a, b]
  };
  
  return (
    <div className="grid">
      <div className="teilnehmer-container">
        <div className="platzhalter">
          <button className="btn" onClick={() => scrollClick(-350)}><i className="icon icon-arrow-left"/></button>
          <button className="btn" onClick={() => scrollClick(350)}><i className="icon icon-arrow-right"/></button>
        </div>
        {ausbildnerFiltered.map((a) => {
          return <div key={a.id} className="teilnehmer">{a.name}</div>;
        })}
      </div>
      <div className="teams" ref={scrollContainer}>
        {zeitslots.map(slot => {
          return <div key={slot.key} className="zeitslot">
            <div className="datum-zeit">
              {formatiereDatumZeit(slot.von)}
              <br/>
              {formatiereDatumZeit(slot.bis)}
            </div>
            <div className="team-container">
              {slot.teams.map(team => {
                const [zeileEins, zeileZwei] = machZweizeilen(team.bezeichnung)
                return <div key={team.id} className="team">
                  <div className="klasse">
                    <b>
                      {zeileEins.join(' ')}
                      <br/>
                      {zeileZwei.join(' ')}
                    </b>
                    <br/>
                    <span className="label label-warning">{team.programmBlock.name}</span>
                    <br/>
                    {team.experte.name}
                    <div className="selektieren">
                      <button className="btn btn-mini" onClick={() => alleSelektieren(team, true)}>{texte.alleSelektieren}</button>
                      <br/>
                      <button className="btn btn-mini" onClick={() => alleSelektieren(team, false)}>{texte.alleDeselektieren}</button>
                    </div>
                  </div>
                  {ausbildnerFiltered.map((a) => {
                    const zuordnung = teamTeilnehmer.filter(tt => tt.ausbildner.id === a.id).find(tt => tt.teamId === team.id);
                    return <div key={a.id} className={`zuordnung ${zuordnung ? 'aktiv' : 'inaktiv'}`} onClick={() => bearbeitenClick(a, team, zuordnung)}>
                      {zuordnung &&
                      <i className="icon-ok"/>
                      }
                    </div>;
                  })}
                </div>;
              })}
            </div>
          </div>;
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ausbildner: state.ausbildner,
    teams: state.teams,
    teamTeilnehmer: state.teamTeilnehmer,
    klassenlehrer: state.klassenlehrer,
    abschlussStatusListe: state.abschlussStatusListe,
    texte: state.i18n.texte.kursleiter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    teamZuteilungSpeichern: (klassenlehrer, ausbildner, zusatzdaten) => { dispatch(teamZuteilungSpeichern(klassenlehrer, ausbildner, zusatzdaten)) },
    teamZuteilungLoeschen: (teamZuteilung) => { dispatch(teamZuteilungDelete(teamZuteilung)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeilnehmerKlasseGrid);
