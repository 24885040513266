import React, { useState } from "react";
import GameHeader from "./GameHeader";
import TeamInfos from "./TeamInfos";
import GameSubheader from "./GameSubheader";
import Ticker from "./Ticker";
import Form from "./Ticker/Form";
import Tabs from "./Tabs";

const GameDetails = () => {
  const [tab, setTab] = useState("ereignisse");

  return (
    <div className="game-details flex-column">
      <GameHeader />
      <div className="scrollable flex-column flex-1">
        <GameSubheader />
        <TeamInfos />
        <Tabs tab={tab} setTab={setTab} />
        <Ticker tab={tab} />
      </div>
      <Form tab={tab} />
    </div>
  );
};

export default GameDetails;
