// @flow

import type {NavigationState, MoeglicherTab, TrainingState} from "../stateTypes";
import type {Action, Dispatch, GetState} from "../actionTypes";

import {validieren} from "./validierung";

export function apiFehlerAnzeigen(fehler: string[]) {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({type: "NAVIGATION_API_FEHLER_ANZEIGEN", fehler});
  };
}

export function apiServerFehlerBehandeln(fehler: mixed) {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({type: "NAVIGATION_API_SERVER_FEHLER_BEHANDELN", fehler, i18n: getState().i18n});
  };
}

export function fehlerAnzeigeEntfernen(fehler: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({type: "NAVIGATION_FEHLER_ANZEIGE_ENTFERNEN", fehler});
  };
}

export function aktivenTabSetzen(aktiverTab: MoeglicherTab) {
  return (dispatch: Dispatch, getState: GetState) => {
    if (aktiverTab === "abschluss") {
      validieren()(dispatch, getState); // ziemlich meta...
    }
    if (aktiverTab === "spielprotokoll") {
      dispatch({type: "PROTOKOLL_HIGHLIGHT_ENTFERNEN"})
    }
    dispatch({type: "NAVIGATION_AKTIVEN_TAB_SETZEN", aktiverTab});
  };
}

export function aktivesAufstellungTeamSetzen(aktivesTeam: 'heim' | 'gast') {
  return {type: "NAVIGATION_AKTIVES_AUFSTELLUNG_TEAM_SETZEN", aktivesTeam};
}

export function aktivesSpielerTeamSetzen(aktivesTeam: 'heim' | 'gast') {
  return {type: "NAVIGATION_AKTIVES_SPIELER_TEAM_SETZEN", aktivesTeam};
}

export function aktivesNotenTeamSetzen(aktivesTeam: 'heim' | 'heimWettkampfleistungen' | 'gast' | 'gastWettkampfleistungen') {
  return {type: "NAVIGATION_AKTIVES_NOTEN_TEAM_SETZEN", aktivesTeam};
}

export function aktivenProtokollTypSetzen(protokollTyp: 'protokoll' | 'spielverlauf') {
  return {type: "NAVIGATION_AKTIVEN_PROTOKOLL_TYP_SETZEN", protokollTyp};
}

function navigationReducer(state: NavigationState, action: Action): NavigationState {
  switch (action.type) {
    case "NAVIGATION_API_FEHLER_ANZEIGEN":
      return {
        ...state,
        aktuelleFehler: action.fehler,
      };
    case "NAVIGATION_API_SERVER_FEHLER_BEHANDELN":
      let fehlerString = action.i18n.fehler.unbekannter_fehler;
      if (typeof action.fehler === 'string') {
        fehlerString = action.fehler;
      } else if (typeof action.fehler === 'number') {
        fehlerString = action.i18n.fehler.fehler_code + action.fehler.toString();
      } else if (action.fehler != null && typeof action.fehler === 'object' && action.fehler.hasOwnProperty('toString')) {
        fehlerString = action.fehler.toString();
      }
      return {
        ...state,
        aktuelleFehler: [fehlerString],
      };
    case "NAVIGATION_FEHLER_ANZEIGE_ENTFERNEN":
      return {
        ...state,
        aktuelleFehler: state.aktuelleFehler.filter((f) => (f !== action.fehler)),
      };
    case "NAVIGATION_AKTIVEN_TAB_SETZEN":
      return {
        ...state,
        aktiverTab: action.aktiverTab,
      };
    case "NAVIGATION_AKTIVES_AUFSTELLUNG_TEAM_SETZEN":
      return {
        ...state,
        aktivesAufstellungTeam: action.aktivesTeam,
      };
    case "NAVIGATION_AKTIVES_SPIELER_TEAM_SETZEN":
      return {
        ...state,
        aktivesSpielerTeam: action.aktivesTeam,
      };
    case "NAVIGATION_AKTIVES_NOTEN_TEAM_SETZEN":
      return {
        ...state,
        aktivesNotenTeam: action.aktivesTeam,
      };
    default:
      return state || {aktiverTab: 'spiel', aktivesTeam: 'heim', tabs: []};
  }
}

export default navigationReducer
