import fetch from "cross-fetch";

export function executeRequest(url, token, method, daten, callback, fehlerCallback) {

  const fetchInit = {
    method: method,
    headers: {
      'x-requested-with': 'XMLHttpRequest',
      'x-auth-token': token,
      'content-type': 'application/json',
    },
    credentials: 'same-origin'
  };

  let apiUrl = url;
  if (method === 'GET') {
    if (daten) {
      const params = new URLSearchParams();
      for (let propName in daten) {
        if (daten.hasOwnProperty(propName)) {
          params.append(propName, daten[propName]);
        }
      }
      apiUrl = url + "?" + params.toString()
    }
  } else {
    fetchInit.body = JSON.stringify(daten);
    const csrfToken = document.querySelector("meta[name=csrf-token]");
    if (csrfToken) {
      fetchInit.headers['x-csrf-token'] = csrfToken.content;
    }
  }

  fetch(apiUrl, fetchInit)
    .then(response => {
      return response.json().then(jsonData => ({data: jsonData, ok: response.ok, status: response.status}));
    })
    .then(jsonResultat => callback(jsonResultat))
    .catch(fehler => {
      console.error(fehler);
      if (fehlerCallback) {
        fehlerCallback(fehler);
      }
    });
}
