// @flow

import { eintragQuicksort, protokollHatTore } from "./modules/protokoll";
import { validierungDurchfuehren } from "./modules/validierung";

import type {ServerDaten, AppState, ProtokollEintrag, TeamState, EinsatzState, SpielState, ProtokollState, SpielerState, EinsatzNote} from "./stateTypes";
import { EINSATZ_ROLLE } from "./modules/einsatz";

export const einsatzDatenLaden = (serverDaten: ServerDaten): EinsatzState => {
  return {
    ...serverDaten.einsatz,
    noten: serverDaten.einsatz.noten.map<EinsatzNote>((eintrag) => {
      return {
        ...eintrag,
        kommentarAnzeigen: !!(eintrag.kommentar),
      };
    }),
    abschlussGestartet: false,
    abschlussButtonGedrueckt: false,
    loeschenGestartet: false,
    loeschenButtonGedrueckt: false,
    readonly: serverDaten.einsatz.abgeschlossen,
  };
};

export const spielDatenLaden = (serverDaten: ServerDaten): SpielState => {
  let neuIndex = 0;
  return {
    ...serverDaten.spiel,
    serverSpielstandHeim: serverDaten.spiel.spielstandHeim,
    serverSpielstandGast: serverDaten.spiel.spielstandGast,
    spielverlauf: eintragQuicksort(serverDaten.spiel.spielverlauf.map<ProtokollEintrag>((eintrag) => {
      return {
        ...eintrag,
        zusatzminute: eintrag.zusatzminute || 0,
        key: "id_" + (eintrag.id || neuIndex++),
        highlight: false,
        videoAnzeigen: false,
        nurLesen: true,
        torBearbeitbar: false,
        neuPosition: null,
      };
    }), null),
  };
};

export const teamDatenLaden = (serverDaten: ServerDaten): TeamState => {
  const teams = {
    liste: serverDaten.teams,
    heimTeam: undefined,
    gastTeam: undefined
  };
  if (serverDaten.spiel) {
    teams.heimTeam = serverDaten.teams.find((team) => team.id === serverDaten.spiel.heimTeamId);
    teams.gastTeam = serverDaten.teams.find((team) => team.id === serverDaten.spiel.gastTeamId);
  }
  return teams;
};

export const protokollDatenLaden = (serverDaten: ServerDaten): ProtokollState => {
  const toreBearbeitbar = serverDaten.spiel.spielstandHeim !== 0 || serverDaten.spiel.spielstandGast !== 0; // wenn der Server 0:0 hat, berechnen wir die Tore selbst
  let neuIndex = 0;
  const protokollEintraege = serverDaten.protokoll.map<ProtokollEintrag>((eintrag) => {
    return {
      ...eintrag,
      spielerUnregistriert: false,
      auswechselSpielerId: null,
      auswechselSpielerUnregistriert: null,
      zusatzminute: eintrag.zusatzminute || 0,
      strafgrundId: null,
      bemerkungId: null,
      unfallartId: null,
      key: "id_" + (eintrag.id || neuIndex++),
      highlight: false,
      hatVideo: false,
      videoPfad: null,
      videoAnzeigen: false,
      nurLesen: false,
      torBearbeitbar: toreBearbeitbar,
      neuPosition: null,
    };
  });
  const spielverlaufEintraege = serverDaten.spiel.spielverlauf.map<ProtokollEintrag>((eintrag) => {
    return {
      ...eintrag,
      zusatzminute: eintrag.zusatzminute || 0,
      key: "ereignis_" + (eintrag.id || neuIndex++),
      highlight: false,
      videoAnzeigen: false,
      nurLesen: true,
      torBearbeitbar: false,
      neuPosition: null,
    };
  });
  return {
    liste: eintragQuicksort(protokollEintraege.concat(spielverlaufEintraege), null),
    spielstandHeim: serverDaten.spiel ? serverDaten.spiel.spielstandHeim : null,
    spielstandGast: serverDaten.spiel ? serverDaten.spiel.spielstandGast : null,
    neuIndex: neuIndex,
  };
};

export const spielerDatenLaden = (serverDaten: ServerDaten): SpielerState => {
  return {
    liste: serverDaten.spieler.map((spieler) => {
      return {
        id: spieler.id,
        nichtregistriert: spieler.nichtregistriert,
        name: spieler.name,
        kurzname: spieler.kurzname,
        passnummer: spieler.passnummer,
        nation: spieler.nation,
        geburtstag: spieler.geburtstag,
        bildUrl: spieler.bildUrl,
        tips: {
          ...spieler.tips,
          kommentarAufgeklappt: spieler.tips.kommentar !== null && spieler.tips.kommentar !== '',
          weitereTipsAufgeklappt: false,
          nurLesen: !!(spieler.tips.scoutingEinsatzId && (spieler.tips.scoutingEinsatzId !== serverDaten.einsatz.id)),
        },
        andereTips: spieler.andereTips,
        torhueterNoten: spieler.torhueterNoten,
        personEdit: spieler.personEdit,
        personKontakt: spieler.personKontakt,
        loeschNachfrageAnzeigen: false,
        externeVereine: spieler.externeVereine,
        prognoseFragen: spieler.prognoseFragen,
      };
    }),
  };
};

export const datenInitialLaden = (serverDaten: ServerDaten): AppState => {

  let tabs = ['abschluss'];
  let spiel = null;
  let protokoll = null;
  let turnier = null;
  let training = null;

  switch (serverDaten.einsatz.typ) {
    case 'spiel':
      if (serverDaten.einsatz.einsatzRolleId == EINSATZ_ROLLE.scout_video) {
        tabs = ['spiel', 'aufstellung', 'spielerliste', 'spielprotokoll', 'abschluss'];
      } else if (serverDaten.einsatz.noten.length > 0) {
        tabs = ['spiel', 'aufstellung', 'spielerliste', 'spielprotokoll', 'noten', 'einsatzInfos', 'kontakt', 'abschluss'];
      } else {
        tabs = ['spiel', 'aufstellung', 'spielerliste', 'spielprotokoll', 'einsatzInfos', 'kontakt', 'abschluss'];
      }
      spiel = spielDatenLaden(serverDaten);
      protokoll = protokollDatenLaden(serverDaten);
      break;
    case 'turnier':
      if (serverDaten.einsatz.einsatzRolleId == EINSATZ_ROLLE.scout_video) {
        tabs = ['turnier', 'spielerliste', 'abschluss'];
      } else {
        tabs = ['turnier', 'spielerliste', 'einsatzInfos', 'kontakt', 'abschluss'];
      }
      turnier = serverDaten.turnier;
      break;
    case 'training':
      if (serverDaten.einsatz.einsatzRolleId == EINSATZ_ROLLE.scout_video) {
        tabs = ['training', 'spielerliste', 'abschluss'];
      } else {
        tabs = ['training', 'spielerliste', 'einsatzInfos', 'kontakt', 'abschluss'];
      }
      training = serverDaten.training;
      break;
  }

  let state: AppState = {
    navigation: {
      tabs: tabs,
      aktiverTab: serverDaten.einsatz.typ,
      aktivesAufstellungTeam: 'heim',
      aktivesSpielerTeam: 'heim',
      aktivesNotenTeam: 'heim',
      aktuelleFehler: [],
    },
    einsatz: einsatzDatenLaden(serverDaten),
    teams: teamDatenLaden(serverDaten),
    spiel: spiel,
    protokoll: protokoll,
    turnier: turnier,
    training: training,
    spielerliste: spielerDatenLaden(serverDaten),
    schiedsrichterliste: {
      liste: serverDaten.schiedsrichter,
    },
    verantwortlichenliste: {
      liste: serverDaten.verantwortliche,
    },
    ajax: {
      spinnerCount: 0,
    },
    i18n: serverDaten.i18n,
    pfade: serverDaten.pfade,
    stammdaten: serverDaten.stammdaten,
    validierung: {
      einsatz: {
        gestartet: false,
        vollstaendig: false,
        fehler: [],
        abschlussFehler: [],
      },
      allesVollstaendig: false,
    },
  };
  state.validierung = validierungDurchfuehren(state);
  if (state.spiel && state.protokoll && protokollHatTore(state.protokoll.liste)) {
    let spielstandHeim = 0;
    let spielstandGast = 0;
    state.protokoll.liste.forEach((eintrag) => {
      switch (eintrag.aktion) {
        case "tor":
          spielstandHeim += eintrag.heimTeam ? 1 : 0;
          spielstandGast += eintrag.gastTeam ? 1 : 0;
          break;
        case "elfmeter_tor":
          spielstandHeim += eintrag.heimTeam ? 1 : 0;
          spielstandGast += eintrag.gastTeam ? 1 : 0;
          break;
        case "eigentor":
          spielstandHeim += eintrag.gastTeam ? 1 : 0;
          spielstandGast += eintrag.heimTeam ? 1 : 0;
          break;
      }
    });
    if (state.protokoll !== null) {
      state.protokoll.spielstandHeim = spielstandHeim;
      state.protokoll.spielstandGast = spielstandGast;
    }
    if (state.spiel !== null) {
      state.spiel.spielstandHeim = spielstandHeim;
      state.spiel.spielstandGast = spielstandGast;
    }
  }
  return state;
};
