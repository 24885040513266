import React from "react";
import { SpielerTips } from "../../../state/stateTypes";

const SpielerReadonlyTips = ({ tips }: Props) => (
  <>
    <td style={{ width: '55px', padding: '4px 5px', textAlign: 'center', verticalAlign: 'middle' }}>
      {tips != null && tips.t != null ? tips.t : '-'}
    </td>
    <td style={{ width: '55px', padding: '4px 5px', textAlign: 'center', verticalAlign: 'middle' }}>
      {tips != null && tips.i != null ? tips.i : '-'}
    </td>
    <td style={{ width: '55px', padding: '4px 5px', textAlign: 'center', verticalAlign: 'middle' }}>
      {tips != null && tips.p != null ? tips.p : '-'}
    </td>
    <td style={{ width: '55px', padding: '4px 5px', textAlign: 'center', verticalAlign: 'middle' }}>
      {tips != null && tips.s != null ? tips.s : '-'}
    </td>
    <td style={{ width: '55px', padding: '4px 5px', textAlign: 'center', verticalAlign: 'middle' }}>
        <span className="durchschnitt">
          {tips != null && tips.noteDurchschnitt ? tips.noteDurchschnitt.toFixed(1) : ''}
        </span>
    </td>
    <td style={{ width: '55px', padding: '4px 5px', textAlign: 'center', verticalAlign: 'middle' }}>
      {tips != null && tips.entwicklung != null ? tips.entwicklung : '-'}
    </td>
    <td style={{ width: '55px', padding: '4px 5px', textAlign: 'center', verticalAlign: 'middle' }}>
      {tips != null && tips.note != null ? tips.note : '-'}
    </td>
  </>
);

type Props = {
  tips: SpielerTips,
};

export default SpielerReadonlyTips;