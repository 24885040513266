// @flow

export function prognoseWertBerechnen(prognose: number): {farbe: string, buchstabe: string} {
  let farbe = '';
  let buchstabe = '';
  if (prognose > 0 && prognose <= 2) {
    farbe = 'rot';
    buchstabe = 'C';
  } else if (prognose > 2 && prognose <= 4) {
    farbe = 'gelb';
    buchstabe = 'B';
  } else if (prognose > 4) {
    farbe = 'gruen';
    buchstabe = 'A';
  }
  return {farbe, buchstabe};
}
