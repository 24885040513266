import React from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../components/ModalerDialog";
import PersonBadge from "../../../components/PersonBadge";
import {bewertungenSpeichern, bewertungenErstellen, bewertungenSpielerLoeschen} from "../state/modules/bewertungen";
import BewertungAuswahl from "./BewertungAuswahl";
import BewertungBearbeiten from "./BewertungBearbeiten";
import {selektiertBewertung} from "../state/modules/selektiert";
import TeamBadge from "../../../components/TeamBadge";
import PersonIcon, {POSITION} from "../../../components/PersonIcon";

const BewertungModal = ({spieler, onHide, selektierteBewertung, texte, neueBewertung, speichereBewertung, loescheBewertung, deselektiereBewertung}) => {
  const readonly = selektierteBewertung && selektierteBewertung.abgeschlossen;

  const modalSchliessen = () => {
    if (selektierteBewertung) {
      deselektiereBewertung();
    }
    onHide();
  };

  const schliessen = () => {
    if (selektierteBewertung) {
      deselektiereBewertung();
    } else {
      onHide();
    }
  };

  const speichern = (aktion = null, ev = null) => {
    const daten = {
      aktion: aktion,
      bewertung: {
        ...selektierteBewertung,
        datum_zeit: selektierteBewertung.datum_zeit.toString('yyyy-MM-dd')
      }
    };
    speichereBewertung(daten);
    if (ev) {
      ev.currentTarget.blur();
    }
  };

  const loeschen = () => {
    loescheBewertung(selektierteBewertung);
  };

  const renderTeamTrainer = (trainer) => {
    return (
      <>
        {trainer.map(trainer => {
          return <span key={trainer.id} style={{display: 'inline-block'}}>
            <div>{trainer.name} <PersonIcon person={trainer} position={POSITION.bottom}/></div>
            <em style={{fontSize: 'smaller'}}>{trainer.funktion}</em>
          </span>;
        })}
      </>
    );
  };

  return (
    <ModalerDialog>
      <div className="modal breit-modal" style={{top: '5%', maxHeight: '95%'}}>
        <div className="modal-header">
          <button type="button" className="close" onClick={modalSchliessen}>&times;</button>
          <h3>{texte.bewertungTitel}</h3>
        </div>
        <div className="modal-body">
          <table style={{width: '100%'}}>
            <colgroup>
              <col style={{width: 40}}/>
              <col style={{width: 200}}/>
              <col style={{width: 200}}/>
              <col style={{width: 'auto'}}/>
            </colgroup>
            <tbody>
            <tr>
              <td rowSpan={spieler.teams.length} style={{verticalAlign: 'top'}}><span className="label label-info">{spieler.passnummer}</span>&nbsp;</td>
              <td rowSpan={spieler.teams.length} style={{verticalAlign: 'top'}}><PersonBadge person={spieler.person} personVerlinken={false} fotoTooltipAktiv={false}/></td>
              <td style={{verticalAlign: 'top'}}>
                {spieler.teams.length > 0 &&
                <TeamBadge key={spieler.teams[0].id} team={spieler.teams[0]}/>
                }
              </td>
              <td style={{verticalAlign: 'top'}}>
                {spieler.teams.length > 0 && renderTeamTrainer(spieler.teams[0].trainer)}
              </td>
            </tr>
            {spieler.teams.slice(1).map(team => {
              return (
                <tr key={team.id}>
                  <td style={{verticalAlign: 'top'}}>
                    <TeamBadge key={team.id} team={team}/>
                  </td>
                  <td style={{verticalAlign: 'top'}}>
                    {renderTeamTrainer(team.trainer)}
                  </td>
                </tr>
              )
            })}
            </tbody>
          </table>
          <hr/>
          {selektierteBewertung === null &&
          <BewertungAuswahl spieler={spieler} onBewertungSelektiert={b => setBewertung(b)}/>
          }
          {selektierteBewertung !== null &&
          <BewertungBearbeiten/>
          }
        </div>
        <div className="modal-footer">
          <div className="pull-left">
            {selektierteBewertung === null &&
            <button type="button" className="btn btn-primary" onClick={() => neueBewertung(spieler)}>{texte.neueBewertung}</button>
            }
            {selektierteBewertung && !readonly &&
            <button type="button" className="btn btn-danger" onClick={loeschen}>{texte.loeschen}</button>
            }
          </div>
          {selektierteBewertung && !readonly &&
          <button type="button" className="btn btn-success" onClick={ev => speichern('abschliessen', ev)}>{texte.abschliessen}</button>
          }
          {selektierteBewertung && !readonly &&
          <button type="button" className="btn btn-primary" onClick={ev => speichern(null, ev)}>{texte.speichern}</button>
          }
          {selektierteBewertung && readonly &&
          <button type="button" className="btn btn-warning" onClick={ev => speichern('bearbeiten', ev)}>{texte.bearbeiten}</button>
          }
          <button type="button" className="btn" onClick={schliessen}>{texte.schliessen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    selektierteBewertung: state.selektiert.bewertung,
    prognoseFragen: state.listen.prognoseFragen,
    texte: state.i18n.texte,
    api: state.api,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    neueBewertung: (spieler) => {
      dispatch(bewertungenErstellen(spieler));
    },
    speichereBewertung: (daten) => {
      dispatch(bewertungenSpeichern(daten));
    },
    loescheBewertung: (bewertung) => {
      dispatch(bewertungenSpielerLoeschen(bewertung));
    },
    deselektiereBewertung: () => {
      dispatch(selektiertBewertung(null));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BewertungModal);
