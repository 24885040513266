import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {rechnungslaufCreate, rechnungslaufUpdate, rechnungslaufVorschau} from "../state/modules/rechnungslauf";
import LeistungAuswahl from "./LeistungAuswahl";
import RechnungslaufAuswahl from "./RechnungslaufAuswahl";
import RechnungslaufEdit from "./RechnungslaufEdit";
import {RECHNUNGSLAUF_TYPEN} from "../lib/constants";
import DatumPicker from "../../../components/DatumPicker";

const Daten = ({rechnungslauf, konfiguration, bexio, bankkonten, rechnungenVorhanden, vorschauVorhanden, laufendeRequests, i18n, rechnungslaufVorschau, rechnungslaufCreate, rechnungslaufUpdate}) => {
  const [leistung, setLeistung] = useState(konfiguration.leistung);
  const [bankkontoId, setBankkontoId] = useState(rechnungslauf.bankkontoId || undefined);
  const [gruppieren, setGruppieren] = useState(rechnungslauf.gruppieren || konfiguration.gruppieren);
  const [bezeichnung, setBezeichnung] = useState(rechnungslauf.bezeichnung || konfiguration.bezeichnung);
  const [differenzRechnungslauf, setDifferenzRechnungslauf] = useState([]);
  const [rechnungstitel, setRechnungstitel] = useState(rechnungslauf.rechnungstitel || konfiguration.bezeichnung);
  const [rechnungstexte, setRechnungstexte] = useState(rechnungslauf.texte || bexio.rechnungstexte);
  const [ansprechpartnerId, setAnsprechpartnerId] = useState(rechnungslauf.ansprechpartnerId);
  const [rechnungsdatum, setRechnungsdatum] = useState(rechnungslauf.rechnungsdatum);
  const [zahlbarBis, setZahlbarBis] = useState(rechnungslauf.zahlbarBis);
  const [showRechnungstexte, setShowRechnungstexte] = useState(false);
  const [rechnungslaufErstellenLaeuft, setRechnungslaufErstellenLaeuft] = useState(false);
  const bexioAktiv = bexio.aktiv;
  const texte = i18n.texte;
  const datenTexte = texte.daten;
  
  useEffect(() => {
    if (rechnungslaufErstellenLaeuft && vorschauVorhanden === false) {
      setRechnungslaufErstellenLaeuft(false);
    }
  }, [vorschauVorhanden])

  const erstellen = () => {
    if (!rechnungslaufErstellenLaeuft) {
      setRechnungslaufErstellenLaeuft(true);
      rechnungslaufCreate(bezeichnung, rechnungstitel, gruppieren, leistung, bankkontoId, differenzRechnungslauf.map(rl => rl.id), rechnungstexte, ansprechpartnerId, rechnungsdatum, zahlbarBis);
    }
  };
 
  const aktualisierenClick = (ev) => {
    ev.currentTarget.blur();
    aktualisieren(rechnungstexte);
  };

  const aktualisieren = (texte) => {
    rechnungslaufUpdate(bezeichnung, rechnungstitel, gruppieren, bankkontoId, texte, ansprechpartnerId, rechnungsdatum, zahlbarBis);
  };

  const onLeistungChange = (l) => {
    setLeistung(l);
    rechnungslaufVorschau(l, differenzRechnungslauf.map(rl => rl.id));
  };

  const onDifferenzRechnungslaufChange = (diffRechnungslauf) => {
    setDifferenzRechnungslauf(diffRechnungslauf);
    rechnungslaufVorschau(leistung, diffRechnungslauf.map(rl => rl.id));
  };

  const onRechnungstexteChange = (texte) => {
    setRechnungstexte(texte);
    if (rechnungslauf.id > 0) {
      aktualisieren(texte);
    }
  };

  function bexioAnsprechpartnerName() {
    const benutzer = bexio.benutzer.find(b => b.id === bexio.ansprechpartnerId);
    if (benutzer) {
      return `(${benutzer.name})`;
    }
    return '';
  }

  return (
    <>
      <h2 className="page-section">{datenTexte.titel}</h2>
      <table className="attributes table">
        <tbody>
        {rechnungslauf.errorMessages &&
        <tr>
          <td colSpan={2}>
            {rechnungslauf.errorMessages.map((fehler, index) => <div key={index} className="alert alert-danger">{fehler}</div>)}
          </td>
        </tr>
        }
        <tr>
          <th>{datenTexte.konfiguration}</th>
          <td>{konfiguration.bezeichnung}</td>
        </tr>
        <tr>
          <th><label htmlFor="rechnungslauf_bezeichnung">{datenTexte.bezeichnung}</label></th>
          <td className="formular-breit">
            <input type="text" value={bezeichnung} onChange={ev => setBezeichnung(ev.currentTarget.value)} id="rechnungslauf_bezeichnung"/>
          </td>
        </tr>
        <tr>
          <th><label htmlFor="rechnungslauf_rechnungs_titel">{datenTexte.titelRechnungen}</label></th>
          <td className="formular-breit">
            <input type="text" value={rechnungstitel} onChange={ev => setRechnungstitel(ev.currentTarget.value)} id="rechnungslauf_rechnungs_titel"/>
          </td>
        </tr>
        {!rechnungenVorhanden && konfiguration.id === null &&
        <>
          <tr>
            <th><label>{datenTexte.leistung}</label></th>
            <td>
              <LeistungAuswahl value={leistung} onLeistungChange={onLeistungChange}/>
            </td>
          </tr>
        </>
        }
        {!rechnungenVorhanden && konfiguration.id !== null &&
        <>
          <tr>
            <td colSpan={2}>
              <label><b>{datenTexte.differenzErstellen}</b></label>
            </td>
          </tr>
          <tr>
            <th><label>{datenTexte.rechnungslaufAuswahl}</label></th>
            <td>
              <RechnungslaufAuswahl multiselect={true} istEntfernbar={true} onChange={onDifferenzRechnungslaufChange}/>
            </td>
          </tr>
        </>
        }
        {bexioAktiv &&
        <>
          <tr>
            <td colSpan={2}><b>{datenTexte.bexioSubtitel}</b></td>
          </tr>
          <tr>
            <th><label htmlFor="rechnungslauf_konto">{datenTexte.bankkonto}</label></th>
            <td className="formular-breit">
              <select value={bankkontoId} onChange={ev => setBankkontoId(ev.currentTarget.value)} id="rechnungslauf_konto">
                <option value=""></option>
                {bankkonten.map(bk => <option key={bk.typ} value={bk.typ}>{bk.bezeichnung}</option>)}
              </select>
            </td>
          </tr>
          {[RECHNUNGSLAUF_TYPEN.sponsorenlauf_sponsor, RECHNUNGSLAUF_TYPEN.sponsorenlauf_spieler].includes(rechnungslauf.typ) == false &&
          <tr>
            <th><label htmlFor="rechnungslauf_pro_person_gruppieren">{datenTexte.gruppieren}</label></th>
            <td>
              <input type="checkbox" checked={gruppieren} onChange={ev => setGruppieren(ev.currentTarget.checked)} id="rechnungslauf_pro_person_gruppieren" style={{verticalAlign: 'top'}}/>
              <div className="alert alert-info">{datenTexte.gruppierenHinweis}</div>
            </td>
          </tr>
          }
          <tr>
            <th>{datenTexte.rechnungstexteBearbeiten}</th>
            <td>
              <button type="button" className="btn" onClick={() => setShowRechnungstexte(!showRechnungstexte)}>{texte.anzeigen}</button>
            </td>
          </tr>
          <tr>
            <th><label htmlFor="ansprechpartner">{datenTexte.ansprechpartner}</label></th>
            <td>
              <select value={ansprechpartnerId || ''} onChange={ev => setAnsprechpartnerId(ev.currentTarget.value)} id="ansprechpartner">
                <option/>
                {bexio.benutzer.map(b => <option key={b.id} value={b.id}>{b.name}</option>)}
              </select>
              <div className="alert alert-info" style={{marginTop: '4px'}}>{datenTexte.ansprechpartnerHinweis} {bexioAnsprechpartnerName()}</div>
            </td>
          </tr>
          <tr>
            <th><label htmlFor="rechnungsdatum">{datenTexte.rechnungsdatum}</label></th>
            <td>
              <DatumPicker value={rechnungsdatum || ''} onChange={d => setRechnungsdatum(d)} locale={i18n.locale} id="rechnungsdatum" />
              <div className="alert alert-info" style={{marginTop: '4px'}}>{datenTexte.rechnungsdatumHinweis}</div>
            </td>
          </tr>
          <tr>
            <th><label htmlFor="zahlbarBis">{datenTexte.zahlbarBis}</label></th>
            <td>
              <DatumPicker value={zahlbarBis || ''} onChange={d => setZahlbarBis(d)} locale={i18n.locale} id="zahlbarBis" />
            </td>
          </tr>
        </>
        }
        </tbody>
      </table>
      <div className="button-liste">
        {rechnungslauf.id === null && (vorschauVorhanden || rechnungslauf.typ !== RECHNUNGSLAUF_TYPEN.rechnungslaufKonfiguration) &&
        <button type="button" className="btn btn-primary" onClick={erstellen} disabled={(bexioAktiv && !bankkontoId) || rechnungslaufErstellenLaeuft}>
          {datenTexte.rechnungslaufUndRechnungenErstellen}
          {rechnungslaufErstellenLaeuft && <div className="ajax-spinner-bar"><span/></div>}
        </button>
        }
        {rechnungslauf.id === null ||
          <>
            <button type="button" className="btn btn-primary" disabled={laufendeRequests.rechnungslauf === true} onClick={aktualisierenClick}>
              {datenTexte.rechnungslaufAktualisieren}
              {laufendeRequests.rechnungslauf === true && <>&nbsp;<div className="ajax-spinner-klein inverted"/></>}
            </button>
          </>
        }
      </div>
      {showRechnungstexte &&
      <RechnungslaufEdit rechnungstexte={rechnungstexte} onChange={onRechnungstexteChange} onHide={() => setShowRechnungstexte(false)}/>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    rechnungslauf: state.rechnungslauf,
    konfiguration: state.konfiguration,
    bexio: state.bexio,
    bankkonten: state.typen.bankkonten,
    rechnungenVorhanden: state.rechnungen.length > 0,
    vorschauVorhanden: state.rechnungenVorschau.length > 0,
    laufendeRequests: state.laufendeRequests,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rechnungslaufVorschau: (leistung, differenzRechnungslauf) => { dispatch(rechnungslaufVorschau(leistung, differenzRechnungslauf)) },
    rechnungslaufCreate: (bezeichnung, rechnungstitel, gruppieren, leistung, bankkontoId, differenzRechnungslauf, rechnungstexte, ansprechpartnerId, rechnungsdatum, zahlbarBis) => { dispatch(rechnungslaufCreate(bezeichnung, rechnungstitel, gruppieren, leistung, bankkontoId, differenzRechnungslauf, rechnungstexte, ansprechpartnerId, rechnungsdatum, zahlbarBis)) },
    rechnungslaufUpdate: (bezeichnung, rechnungstitel, gruppieren, bankkontoId, rechnungstexte, ansprechpartnerId, rechnungsdatum, zahlbarBis) => { dispatch(rechnungslaufUpdate(bezeichnung, rechnungstitel, gruppieren, bankkontoId, rechnungstexte, ansprechpartnerId, rechnungsdatum, zahlbarBis)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Daten);
