import React, {useState} from "react";
import {connect} from "react-redux";
import {setzeBezeichnung} from "../../state/modules/sponsorenlaufBezeichnungen";
import ModalerDialog from "../../../../components/ModalerDialog";
import {executeJson} from "../../../../lib/fetchHelper";

const BezeichnungModal = ({bezeichnung, onClose, sprachen, texte, api, setzeBezeichnung}) => {
  const [editBezeichnung, setEditBezeichnung] = useState({...bezeichnung});

  const setTextWert = (spracheId, bezeichnung) => {
    const neu = {
      ...editBezeichnung,
      texte: [...editBezeichnung.texte.filter(t => t.spracheId != spracheId), {spracheId, bezeichnung}]
    };
    setEditBezeichnung(neu);
  };

  const schliessen = () => {
    onClose();
  };

  const speichern = () => {
    const data = {
      bezeichnung: editBezeichnung
    };
    executeJson(api.bezeichnungen, api.token, 'POST', data).
    then(result => {
      if (result.ok) {
        setzeBezeichnung(result.data.bezeichnung);
        onClose(result.data.bezeichnung.id);
      } else {
        setEditBezeichnung(result.data.bezeichnung);
      }
    });
  };

  return (
    <ModalerDialog>
      <div className="modal modal-wide">
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{editBezeichnung ? texte.bezeichnung : texte.neueBezeichnungErstellen}</h3>
        </div>
        <div className="modal-body">
          {editBezeichnung.errorMessages && editBezeichnung.errorMessages.length > 0 &&
            editBezeichnung.errorMessages.map((error, index) => <div key={index} className="alert alert-error">{error}</div>)
          }
          <table className="table attributes formular-breit">
            <tbody>
            <tr>
              <th>{texte.inaktiv}</th>
              <td><input type="checkbox" checked={editBezeichnung.inaktiv} onChange={ev => setEditBezeichnung({...editBezeichnung, inaktiv: ev.currentTarget.checked})}/></td>
            </tr>
            {sprachen.map(sprache => {
              let text = editBezeichnung.texte.find(t => t.spracheId === sprache.id) || {};
              return <tr key={sprache.id}>
                <th>{sprache.bezeichnung}</th>
                <td><input type="text" value={text.bezeichnung || ''} maxLength={250} onChange={ev => setTextWert(sprache.id, ev.currentTarget.value)}/></td>
              </tr>;
            })}
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" onClick={speichern}>{texte.speichern}</button>
          &nbsp;
          <button type="button" className="btn" onClick={schliessen}>{texte.abbrechen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    sprachen: state.listen.sprachen,
    texte: state.i18n.texte,
    api: state.api
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setzeBezeichnung: (bezeichnung) => { dispatch(setzeBezeichnung(bezeichnung)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BezeichnungModal);
