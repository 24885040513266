import React, {useEffect} from 'react';
import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

import reducer from "./state/reducerSponsorenlauf";
import preloader from "./state/preloaderSponsorenlauf";
import ErrorBoundary from "../../components/ErrorBoundary";
import {rollbarInitialisieren} from "../../reducers/rollbar";
import Ansichten from "./components/Sponsorenlauf/Ansichten";

const Sponsorenlauf = (props) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducer, preloader(props), composeEnhancers(applyMiddleware(thunk)));

  useEffect(() => {
    store.dispatch(rollbarInitialisieren());
   }, []);

  return (
    <Provider store={store}>
      <ErrorBoundary fehlermeldung={props.i18n.allgemeinerFehler}>
        <Ansichten />
      </ErrorBoundary>
    </Provider>
  );
};

export default Sponsorenlauf;
