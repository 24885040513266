import React, {useState, useRef, useEffect} from 'react';
import {createLocalVideoTrack, createLocalAudioTrack} from 'twilio-video';
import {connect} from "react-redux";
import {closeRoom} from "../state/rooms";
import {setCurrentRoom, setFullscreen} from "../state/settings";
import Tooltip from "../../../components/Tooltip";

const ParticipantLocal = ({room, participant, remoteParticipant, remoteTrackCount, person, settings, texte, closeRoom, setCurrentRoom, setFullscreen}) => {
  const [videoTrack, setVideoTrack] = useState(null);
  const [audioTrack, setAudioTrack] = useState(null);
  const [screenTrack, setScreenTrack] = useState(null);

  const videoRef = useRef(null);
  const audioRef = useRef(null);

  useEffect(() => {
    if (person.istSupport) {
      handleAudio();
    }
    
    return () => {
      // aufräumen
      if (videoTrack) {
        setVideoTrack(null);
        videoTrack.detach();
        videoTrack.stop();
      }
      if (audioTrack) {
        setAudioTrack(null);
        audioTrack.detach();
        audioTrack.stop();
      }
    };
  }, []);

  const handleVideo = () => {
    if (videoTrack) {
      // video ausschalten
      setVideoTrack(null);
      videoTrack.detach();
      videoTrack.stop();
      participant.unpublishTrack(videoTrack);
    } else {
      // video einschalten
      createLocalVideoTrack()
        .then(function(videoTrack) {
          setVideoTrack(videoTrack);
          videoTrack.attach(videoRef.current);
          participant.publishTrack(videoTrack);
        })
        .catch(err => console.error("Error:" + err));
    }
  };

  const handleAudio = () => {
    if (audioTrack) {
      // audio ausschalten
      setAudioTrack(null);
      audioTrack.detach();
      audioTrack.stop();
      participant.unpublishTrack(audioTrack);
    } else {
      // audio einschalten
      createLocalAudioTrack()
        .then(function(audioTrack) {
          setAudioTrack(audioTrack);
          audioTrack.attach(audioRef.current);
          participant.publishTrack(audioTrack);
        }).catch(err => console.error("Error:" + err));
    }
  };

  const handleScreensharing = () => {
    if (screenTrack) {
      // screen share ausschalten
      screenTrack.stop();
      participant.unpublishTrack(screenTrack);
      setScreenTrack(null);
    } else {
      // screen share einschalten
      navigator.mediaDevices.getDisplayMedia({video: {cursor: "always"}, audio: false})
        .then(stream => {
          const track = stream.getVideoTracks()[0];
          participant.publishTrack(track);
          setScreenTrack(track);
        })
        .catch(err => console.error("Error:" + err));
    }
  };

  const renderInfo = () => {
    if (person.istSupport === false) {
      return (
        <div className="alert alert-success text-center">
          <h4>{texte.hinweis}</h4>
        </div>
      );
    }
    return <></>;
  };

  const renderTeilnehmer = () => {
    if (person.istSupport && settings.currentRoom) {
      const teilnehmer = settings.currentRoom.person;
      return (
        <table>
          <tbody>
            <tr style={{height: 10}}>
              <td rowSpan={2}>
                {teilnehmer.fotoUrl &&
                <img src={teilnehmer.fotoUrl}/>
                }
              </td>
              <td>{teilnehmer.name} ({teilnehmer.id})</td>
              <td>{teilnehmer.vereine.map((v, index) => <span key={index} className="zeile">{v}</span>)}</td>
              <td>{teilnehmer.telefon.map((tel, index) => <a key={index} className="zeile" target="_blank" href={`tel:${tel}`}>{tel}</a>)}</td>
              <td>{teilnehmer.email.map((email, index) => <a key={index} className="zeile" target="_blank" href={`mailto:${email}`}>{email}</a>)}</td>
            </tr>
          <tr>
            <td colSpan={4}>
              {renderEmailLink()}
            </td>
          </tr>
          </tbody>
      </table>
      );
    }
    return <></>;
  };

  const infoText = () => {
    if (remoteParticipant) {
      return `${texte.verbundenMit} ${remoteParticipant.identity}`;
    } else {
      if (person.istSupport) {
        return texte.keineVerbindung;
      } else {
        return texte.wartenAufSupport;
      }
    }
  };
  
  const renderEmailLink = () => {
    if (person.istSupport) {
      const teilnehmer = settings.currentRoom.person;
      const jetzt = new Date();
      const subject = `${texte.mailAnSupportTitel} / ${jetzt.toLocaleDateString('de-CH')} ${jetzt.toLocaleTimeString('de-CH', {hour: '2-digit', minute: '2-digit'})} ${teilnehmer.name} (${teilnehmer.id})`;
      const body = '';
      const href = `mailto:${settings.supportEmail}?cc=${teilnehmer.email[0]}&cc=${settings.supportEmailCc}&subject=${subject}&body=${body}`;
      return <a className='btn btn-info' target="_blank" href={href}>{texte.mailAnSupport}</a>;
    }
    return <></>;
  };

  return (
    <div className="local-participant">
      {renderInfo()}
      {renderTeilnehmer()}
      <div className="controls">
        <span>
          <h3>{infoText()}</h3>
        </span>
        <span>
          <Tooltip content={videoTrack ? texte.videoAus : texte.videoEin}>
            <button onClick={handleVideo} className={`material-button btn btn-${videoTrack ? 'success' : 'warning'}`}>
              <i className="material-icons-outlined">{videoTrack ? 'videocam' : 'videocam_off'}</i>
            </button>&nbsp;
          </Tooltip>
          <Tooltip content={audioTrack ? texte.audioAus : texte.audioEin}>
            <button onClick={handleAudio} className={`material-button btn btn-${audioTrack ? 'success' : 'warning'}`}>
              <i className="material-icons-outlined">{audioTrack ? 'mic' : 'mic_off'}</i>
            </button>&nbsp;
          </Tooltip>
          <Tooltip content={screenTrack ? texte.screenshareAus : texte.screenshareEin}>
            <button onClick={handleScreensharing} className={`material-button btn btn-${screenTrack ? 'success' : 'warning'}`}>
              <i className="material-icons-outlined">{screenTrack ? 'screen_share' : 'stop_screen_share'}</i>
            </button>&nbsp;
          </Tooltip>
          {remoteParticipant && remoteTrackCount > 0 &&
          <Tooltip content={texte.fullscreenEin}>
            <button onClick={() => setFullscreen(true)} className={`material-button btn btn-info`}>
              <i className="material-icons-outlined">fullscreen</i>
            </button>
          </Tooltip>
          }
        </span>
        <span>
          {person.istSupport === true &&
          <>
            <button onClick={() => setCurrentRoom(null)} className="btn btn-primary">{texte.raumVerlassen}</button>&nbsp;
            <button onClick={() => closeRoom(room)} className="btn btn-danger">{texte.raumSchliessen}</button>
          </>
          }
          {person.istSupport === false &&
          <button onClick={() => closeRoom(room)} className="btn btn-danger">{texte.supportSchliessen}</button>
          }
        </span>
      </div>
      {videoTrack &&
      <video ref={videoRef} autoPlay={true}/>
      }
      {audioTrack && false &&
      <audio ref={audioRef} autoPlay={true}/>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    person: state.person,
    settings: state.settings,
    texte: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeRoom: (room) => { dispatch(closeRoom(room)); },
    setCurrentRoom: (room) => { dispatch(setCurrentRoom(room)); },
    setFullscreen: (istFullscreen) => { dispatch(setFullscreen(istFullscreen)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantLocal);
