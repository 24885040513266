import React from "react";
import {connect} from "react-redux";
import HTML5Backend from 'react-dnd-html5-backend'
import {DndProvider} from 'react-dnd'
import Liste from "./Liste";
import Form from "./Form";

const Ansicht = ({daten}) => {

  if (daten.editVorlage === null) {
    return <Liste/>;
  } else {
    return (
      <DndProvider backend={HTML5Backend}>
        <Form vorlage={daten.editVorlage}/>
      </DndProvider>
    );
  }

};

const mapStateToProps = (state) => {
  return {
    daten: state.daten,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Ansicht);

