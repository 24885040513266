import React, {useState} from "react";
import {useDrag} from "react-dnd";
import {ItemTypes} from "../../lib/constants";
import IconToggle from "../IconToggle";
import BibliothekDetail from "../BibliothekDetail";

const BibliothekAufgabe = ({aufgabe}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [{}, drag] = useDrag({
    item: {type: ItemTypes.Aufgabe, id: aufgabe.id}
  });

  return (
    <div className="aufgabe eintrag-box" ref={drag}>
      <div className="titel">
        {aufgabe.titel}
        <button className="btn btn-mini" onClick={() => setShowDetails(!showDetails)}>
          <IconToggle istBearbeitbar={false} istOffen={showDetails} />
        </button>
      </div>
      {showDetails &&
      <BibliothekDetail aufgabe={aufgabe}/>
      }
    </div>
  );
};

export default BibliothekAufgabe;
