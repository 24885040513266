import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bexioStatusAbfragen, bexioPersonenSynchronisieren, bexioTransferStarten, bexioRechnungenStatusAbfragen, bexioTransferEntfernen} from "../state/modules/bexio";
import ModalerDialog from "../../../components/ModalerDialog";
import SyncLabel from "./SyncLabel";
import {BEXIO_LINKS, BEXIO_STATUS} from "../lib/constants";
import ConfirmButton from "../../../components/ConfirmButton";
import Tooltip from "../../../components/Tooltip";

  const BexioSynchronisation = ({bexio, rechnungslauf, rechnungen, texte, bexioStatusAbfragen, bexioPersonenSynchronisieren, bexioTransferStarten, bexioRechnungenStatusAbfragen, bexioTransferEntfernen}) => {
  const [showKontakte, setShowKontakte] = useState(false);
  const [syncPersonenInterval, setSyncPersonenInterval] = useState(null);
  const [syncRechnungenInterval, setSyncRechnungenInterval] = useState(null);
  const [syncRechnungenStatusInterval, setSyncRechnungenStatusInterval] = useState(null);
  const bexioTexte = texte.bexio;
  const bexioPersonen = bexio.status.personen;
  const bexioKonten = bexio.status.konten;
  const bexioRechnungen = bexio.status.rechnungen;
  const bexioRechnungenStatus = bexio.status.rechnungenStatus;

  useEffect(() => {
    // initiale abfrage, falls nötig

    if (rechnungslauf.id !== null) {
      if (!rechnungslauf.transferiert) {
        // rechnungen sind nicht transferiert
        if (!bexioPersonen.aktiv) {
          bexioStatusAbfragen(true, false, false, false);
        }
        if (!bexioKonten.aktiv) {
          bexioStatusAbfragen(false, true, false, false);
        }
      }
    } else {
      // rechnungen sind noch nicht transferiert
      if (!bexioRechnungen.aktiv) {
        bexioStatusAbfragen(false, false, true, false);
      }
    }
  }, []);

  useEffect(() => {
    clearInterval(syncPersonenInterval);
    if (bexioPersonen.aktiv) {
      let intervalId = setInterval(() => {
        bexioStatusAbfragen(true, false, false, false);
      }, 5000);
      setSyncPersonenInterval(intervalId);
    }
  }, [bexioPersonen.aktiv]);

  useEffect(() => {
    clearInterval(syncRechnungenInterval);
    if (bexioRechnungen.aktiv) {
      let intervalId = setInterval(() => {
        bexioStatusAbfragen(false, false, true, false);
      }, 5000);
      setSyncRechnungenInterval(intervalId);
    }
  }, [bexioRechnungen.aktiv]);

  useEffect(() => {
    clearInterval(syncRechnungenStatusInterval);
    if (bexioRechnungenStatus.aktiv) {
      let intervalId = setInterval(() => {
        bexioStatusAbfragen(false, false, false, true);
      }, 1000);
      setSyncRechnungenStatusInterval(intervalId);
    }
  }, [bexioRechnungenStatus.aktiv]);

  const personenSyncStarten = () => {
    setShowKontakte(false);
    bexioPersonenSynchronisieren();
  };
  
  const kontenSyncAbfragen = () => {
    bexioStatusAbfragen(false, true, false, false);
  };

  const zuruecksetzen = () => {
    bexioTransferEntfernen();
  };

  return (
    <>
      <h2 className="page-section">{bexioTexte.titel}</h2>
      <table className="table table-bordered table-striped">
        <tbody>
        <tr>
          <th style={{width: 160}}>{bexioTexte.personen}</th>
          <td style={{width: 190}}><SyncLabel bexioStatus={bexioPersonen}/></td>
          <td className="text-right">
            <a className="btn" target="clubcorner-bexio-fenster" href={BEXIO_LINKS.kontake}>
              <i className="icon-circle-arrow-up"/>&nbsp;
              {`${bexioTexte.kontakte} (bexio)`}
            </a>
          </td>
          <td className="text-right">
            <button className="btn" disabled={bexioPersonen.aktiv} onClick={() => setShowKontakte(true)}>{bexioTexte.erneutSynchronisieren}</button>
          </td>
        </tr>
        <tr>
          <th>{bexioTexte.konten}</th>
          <td><SyncLabel bexioStatus={bexioKonten}/></td>
          <td className="text-right">
            <a className="btn" target="clubcorner-bexio-fenster" href={BEXIO_LINKS.konten}>
              <i className="icon-circle-arrow-up"/>&nbsp;
              {`${bexioTexte.konten} (bexio)`}
            </a>
          </td>
          <td className="text-right">
            <button className="btn" disabled={bexioKonten.aktiv} onClick={kontenSyncAbfragen}>{bexioTexte.erneutAbfragen}</button>
          </td>
        </tr>
        <tr>
          <th>{bexioTexte.rechnungen}</th>
          <td>
            <SyncLabel bexioStatus={bexioRechnungen} spinnerAnzeigen={false}/>
            {bexioRechnungen.aktiv &&
              <>
                {` (${bexioRechnungen.transferiert}/${bexioRechnungen.total})`}
                <span className="ajax-spinner-klein"/>
                {bexioRechnungen.errorMessages && bexioRechnungen.errorMessages.length > 0 &&
                  <Tooltip tagName="span" content={bexioRechnungen.errorMessages.join(<br/>)} className="badge badge-important">
                    <i className="icon-warning-sign icon-white"/>
                  </Tooltip>
                }
              </>
            }
            {bexioRechnungenStatus.aktiv &&
              <>
                <br/>
                <span className={`label label-warning`}>{texte.bexio.rechnungsstatusAbfrageLaeuft}</span>
                <span className="ajax-spinner-klein"/>
              </>
            }
          </td>
          <td className="text-right">
            <a className="btn" target="clubcorner-bexio-fenster" href={BEXIO_LINKS.rechnungen}>
              <i className="icon-circle-arrow-up"/>&nbsp;
              {`${bexioTexte.rechnungen} (bexio)`}
            </a>
          </td>
          <td className="text-right">
            {(!bexioRechnungen.synchronisiert || bexioRechnungen.total > bexioRechnungen.transferiert) && !bexioRechnungen.aktiv && bexioPersonen.synchronisiert && bexioKonten.synchronisiert &&
              <button className="btn btn-primary" disabled={bexioRechnungenStatus.aktiv} onClick={() => bexioTransferStarten()}>{bexioTexte.transferieren}</button>
            }
            {!bexioRechnungen.aktiv && bexioRechnungen.transferiert > 0 && 
              <div className="button-liste">
                <button className="btn btn-primary" disabled={bexioRechnungenStatus.aktiv} onClick={() => bexioRechnungenStatusAbfragen()}>{bexioTexte.statusAbfragen}</button>
                {rechnungen.some(r => r.transferiert && [BEXIO_STATUS.nichtAufBexio, BEXIO_STATUS.draft].includes(r.bexioStatus)) &&
                  <ConfirmButton className="btn btn-danger pull-right" disabled={bexioRechnungenStatus.aktiv} onConfirmed={zuruecksetzen} buttonText={bexioTexte.rechnungslaufZuruecksetzen} confirmText={bexioTexte.wirklichZuruecksetzen} okText={texte.ok} cancelText={texte.abbrechen}/>
                }
              </div>
            }
          </td>
        </tr>
        </tbody>
      </table>
      {showKontakte &&
        <ModalerDialog>
          <div className="modal">
            <div className="modal-body">
              {bexioTexte.kontakteHinweis1}
              <br/>
              <br/>
              {bexioTexte.kontakteHinweis2}
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" onClick={() => personenSyncStarten()}>{texte.ok}</button>
              <button className="btn" onClick={() => setShowKontakte(false)}>{texte.abbrechen}</button>
            </div>
          </div>
        </ModalerDialog>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bexio: state.bexio,
    rechnungslauf: state.rechnungslauf,
    rechnungen: state.rechnungen,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bexioStatusAbfragen: (personen, konten, rechnungen, rechnungenStatus) => { dispatch(bexioStatusAbfragen(personen, konten, rechnungen, rechnungenStatus)) },
    bexioPersonenSynchronisieren: () => { dispatch(bexioPersonenSynchronisieren()) },
    bexioTransferStarten: () => { dispatch(bexioTransferStarten()) },
    bexioRechnungenStatusAbfragen: () => { dispatch(bexioRechnungenStatusAbfragen()) },
    bexioTransferEntfernen: () => { dispatch(bexioTransferEntfernen()) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BexioSynchronisation);
