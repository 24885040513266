import React, {useState} from "react";
import { getVideo, spielstandUndZeitBei } from "../../services/helpers";
import useGlobal from "../../store";
import Video from "../../../../components/Video";

const VideoPlayer = () => {
  const [globalState, globalActions] = useGlobal();
  const [gameTimer, setGameTimer] = useState("00:00");
  const [gameScore, setGameScore] = useState("0:0");
  const [angezeigtePeriode, setAngezeigtePeriode] = useState("");

  const handleTimeUpdate = currentTime => {
    const selectedVideo = getVideo(globalState, globalState.selectedVideo);

    if (selectedVideo) {
      let spielZustand = spielstandUndZeitBei(currentTime, selectedVideo, globalState.spielInfos.periodenInfos, globalState.tore);
      setGameScore(spielZustand.spielstand || "0:0");
      setAngezeigtePeriode(spielZustand.periodeName);
      if (spielZustand.zeitAnzeigen) {
        if (spielZustand.zusatzsekunden) {
          setGameTimer(
            `${spielZustand.minute}' +${gameTimerFormat(spielZustand.zusatzsekunden)}`
          );
        } else {
          setGameTimer(gameTimerFormat(spielZustand.sekunden));
        }
      } else {
        setGameTimer('');
      }
    }
  };

  const gameTimerFormat = position => {
    const minutes = Math.floor(position / 60);
    const seconds = position - minutes * 60;

    return [minutes, seconds]
      .map(timePart => String(timePart).padStart(2, '0'))
      .join(":");
  };

  const getOverlay = () => {
    const video = getVideo(globalState, globalState.selectedVideo);

    if (!video) return;

    return (
      <div className="video-overlay" hidden={globalState.overlaySequence}>
        <div className="result flex-row">
          <div className="flex-1"></div>
          <div className="flex-1 team">{globalState.spielInfos.heimTeam.name}</div>
          <div className="score">{gameScore}</div>
          <div className="flex-1 team text-right">
            {globalState.spielInfos.gastTeam.name}
          </div>
          <div className="flex-1 text-right">
            <div className="time">{gameTimer}</div>
          </div>
        </div>
        <div className="text-center">
          <div className="video-name">{angezeigtePeriode || video.spiel_abschnitt}</div>
        </div>
      </div>
    );
  };

  const getVideoPlayer = () => {
    const video = getVideo(globalState, globalState.selectedVideo);
    if (video) {
      return (
        <Video src={video.video_name} autoPlay={true} onEnded={nextVideo} onTimeUpdate={handleTimeUpdate}/>
      );
    } else {
      return <div>...</div>;
    }
  };

  const nextVideo = () => {
    const index = globalState.videos.findIndex(
      v => v.id === globalState.selectedVideo
    );
    const nextVideoInList = globalState.videos[index + 1];
    if (nextVideoInList) {
      globalActions.selectVideo(nextVideoInList.id);
    }
  };

  return (
    <div className="flex-row">
      <div className="video-wrapper">
        {getOverlay()}
        {getVideoPlayer()}
      </div>
    </div>
  );
};

export default VideoPlayer;
