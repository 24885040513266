// @flow

import type { EinsatzNote, EinsatzState, TrainingState } from "../stateTypes";
import type { Action, Dispatch, GetState } from "../actionTypes";

import { apiRequestAbschicken } from "./ajax";
import { einsatzDatenLaden } from "../datenLaden";

export const EINSATZ_ROLLE = {
  scout: 10,
  scout_a: 21,
  scout_b: 22,
  scout_c: 23,
  scout_footuro: 30,
  scout_torhueter: 50,
  scout_video: 60,
  scout_supervisor: 70
};

export function einsatzReadonlySetzen() {
  return { type: "EINSATZ_READONLY_SETZEN" };
}

export function gesamtKommentarSetzen(wert: string) {
  return { type: "EINSATZ_GESAMT_KOMMENTAR_SETZEN", wert };
}

export function einsatzBemerkungenSetzen(wert: string) {
  return { type: "EINSATZ_BEMERKUNGEN_SETZEN", wert };
}

export function einsatzNoteSetzen(teamId: number | null,
                                  gesamtnote: boolean | null,
                                  kategorieId: number,
                                  artId: number | null,
                                  wert: string,
                                  rippleNachUnten: boolean,
                                  rippleNachOben: boolean) {
  return (dispatch: Dispatch, getState: GetState) => {
    const parsedWert = wert ? parseFloat(wert) : null;
    dispatch({ type: "EINSATZ_NOTE_SETZEN", teamId, gesamtnote, kategorieId, artId, wert: parsedWert });
    const notenBerechnung = getState().stammdaten.notenBerechnung;
    const gemeinsameRequestDaten = {
      notenTeam: teamId,
      kategorieId: kategorieId,
      artId: null,
    };
    if (rippleNachUnten) {
      notenBerechnung.forEach((link: { quelle: number, ziel: number }) => {
        if (link.ziel === kategorieId) {
          const requestDaten = {
            ...gemeinsameRequestDaten,
            kategorieId: link.quelle,
          };
          dispatch(apiRequestAbschicken(getState().pfade.api.einsatz, 'PATCH', 'einsatzNote', wert, requestDaten, () => {
            // noop im Callback
          }));
          einsatzNoteSetzen(teamId, null, link.quelle, null, wert, true, false)(dispatch, getState);
        }
      });
    }
    if (rippleNachOben) {
      let ziele = [];
      notenBerechnung.forEach((link: { quelle: number, ziel: number }) => {
        if (link.quelle === kategorieId) {
          ziele.push(link.ziel);
        }
      });
      ziele.forEach((ziel: number) => {
        let hatDurchschnitt: boolean = true;
        let noten: number[] = [];
        notenBerechnung.forEach((link: { quelle: number, ziel: number }) => {
          if (link.ziel === ziel) {
            getState().einsatz.noten.forEach((note) => {
              if (note.teamId === teamId && note.kategorieId === link.quelle && note.erlaubt) {
                if (note.kategorieId === kategorieId && (artId === null || note.artId === artId)) {
                  noten.push(parseInt(wert));
                } else if (note.note) {
                  noten.push(note.note);
                } else {
                  hatDurchschnitt = false;
                }
              }
            });
          }
        });
        if (hatDurchschnitt) {
          let durchschnitt: number = (noten.reduce((a, b) => a + b, 0)) / noten.length;
          const requestDaten = {
            ...gemeinsameRequestDaten,
            kategorieId: ziel,
          };
          dispatch(apiRequestAbschicken(getState().pfade.api.einsatz, 'PATCH', 'einsatzNote', durchschnitt, requestDaten, () => {
            // noop im Callback
          }));
          einsatzNoteSetzen(teamId, null, ziel, null, durchschnitt.toString(), false, true)(dispatch, getState);
        }
      });
    }

  };
}

export function einsatzNoteKommentarAnzeigen(teamId: number, kategorieId: number) {
  return { type: "EINSATZ_NOTE_KOMMENTAR_ANZEIGEN", teamId, kategorieId };
}

export function einsatzNoteKommentarVerstecken(teamId: number, kategorieId: number) {
  return { type: "EINSATZ_NOTE_KOMMENTAR_VERSTECKEN", teamId, kategorieId };
}

export function einsatzNoteKommentarAendern(teamId: number, kategorieId: number, kommentar: string) {
  return { type: "EINSATZ_NOTE_KOMMENTAR_AENDERN", teamId, kategorieId, kommentar };
}

export function einsatzAbschlussButtonEin() {
  return { type: "EINSATZ_ABSCHLUSS_BUTTON_EIN" };
}


export function einsatzAbschlussButtonAus() {
  return { type: "EINSATZ_ABSCHLUSS_BUTTON_AUS" };
}

export function einsatzAbschlussStarten() {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({ type: "EINSATZ_ABSCHLUSS_STARTEN" });
    const apiUrl = getState().pfade.api.abschluss;
    dispatch(apiRequestAbschicken(apiUrl, "POST", null, null, null, (dispatch, resultat) => {
      if (resultat.errors) {
        dispatch({ type: "VALIDIERUNG_ABSCHLUSS_FEHLER", fehler: resultat.errors });
        dispatch({ type: "EINSATZ_ABSCHLUSS_FEHLER" });
      } else {
        dispatch({ type: "EINSATZ_ABSCHLUSS_ERFOLG", headerText: getState().i18n.subtitel.abgeschlossenTitel });
      }
    }));
  };
}

export function einsatzLoeschenButtonEin() {
  return { type: "EINSATZ_LOESCHEN_BUTTON_EIN" };
}


export function einsatzLoeschenButtonAus() {
  return { type: "EINSATZ_LOESCHEN_BUTTON_AUS" };
}

export function einsatzLoeschenStarten() {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({ type: "EINSATZ_LOESCHEN_STARTEN" });
    const apiUrl = getState().pfade.api.abschluss;
    dispatch(apiRequestAbschicken(apiUrl, "DELETE", null, null, null, (dispatch, resultat) => {
      if (resultat.errors) {
        dispatch({ type: "EINSATZ_LOESCHEN_FEHLER", fehler: resultat.errors });
      } else {
        dispatch({ type: "EINSATZ_LOESCHEN_ERFOLG", pfad: resultat.pfad });
        window.location.href = resultat.pfad;
      }
    }));
  };
}

export function einsatzAbschlussErfolg() {
  return { type: "EINSATZ_ABSCHLUSS_ERFOLG" };
}

export function einsatzAbschlussFehler() {
  return { type: "EINSATZ_ABSCHLUSS_FEHLER" };
}

const notenAktualisieren = (noten: EinsatzNote[],
                            teamId: number | null,
                            gesamtnote: boolean | null,
                            kategorieId: number,
                            artId: number | null,
                            wert: number | null) => {
  return noten.map<EinsatzNote>((note) => {
    if (
      note.teamId === teamId &&
      (gesamtnote === null || note.gesamtnote === gesamtnote) &&
      note.kategorieId === kategorieId &&
      (artId === null || note.artId === artId) &&
      note.erlaubt
    ) {
      return {
        ...note,
        note: wert,
      };
    } else {
      return note;
    }
  });
};

function einsatzReducer(state: EinsatzState, action: Action): EinsatzState {
  switch (action.type) {
    case "EINSATZ_READONLY_SETZEN":
      return {
        ...state,
        readonly: true,
      };
    case "EINSATZ_GESAMT_KOMMENTAR_SETZEN":
      return {
        ...state,
        gesamtKommentar: action.wert,
      };
    case "EINSATZ_BEMERKUNGEN_SETZEN":
      return {
        ...state,
        einsatzBemerkungen: action.wert,
      };
    case "EINSATZ_NOTE_SETZEN":
      return {
        ...state,
        noten: notenAktualisieren(state.noten,
          action.teamId,
          action.gesamtnote,
          action.kategorieId,
          action.artId,
          action.wert)
      };
    case "EINSATZ_NOTE_KOMMENTAR_ANZEIGEN":
      return {
        ...state,
        noten: state.noten.map((note) => {
          if (note.kategorieId === action.kategorieId && note.teamId === action.teamId) {
            return {
              ...note,
              kommentarAnzeigen: true,
            }
          } else {
            return note;
          }
        }),
      };
    case "EINSATZ_NOTE_KOMMENTAR_VERSTECKEN":
      return {
        ...state,
        noten: state.noten.map((note) => {
          if (note.kategorieId === action.kategorieId && note.teamId === action.teamId) {
            return {
              ...note,
              kommentarAnzeigen: false,
            }
          } else {
            return note;
          }
        }),
      };
    case "EINSATZ_NOTE_KOMMENTAR_AENDERN":
      return {
        ...state,
        noten: state.noten.map((note) => {
          if (note.kategorieId === action.kategorieId && note.teamId === action.teamId) {
            return {
              ...note,
              kommentar: action.kommentar,
              kommentarAnzeigen: true,
            }
          } else {
            return note;
          }
        }),
      };
    case "EINSATZ_ABSCHLUSS_BUTTON_EIN":
      return {
        ...state,
        abschlussButtonGedrueckt: true,
      };
    case "EINSATZ_ABSCHLUSS_BUTTON_AUS":
      return {
        ...state,
        abschlussButtonGedrueckt: false,
      };
    case "EINSATZ_ABSCHLUSS_STARTEN":
      return {
        ...state,
        abschlussGestartet: true,
        abschlussButtonGedrueckt: false,
        readonly: true,
      };
    case "EINSATZ_LOESCHEN_BUTTON_EIN":
      return {
        ...state,
        loeschenButtonGedrueckt: true,
      };
    case "EINSATZ_LOESCHEN_BUTTON_AUS":
      return {
        ...state,
        loeschenButtonGedrueckt: false,
      };
    case "EINSATZ_LOESCHEN_STARTEN":
      return {
        ...state,
        loeschenGestartet: true,
        loeschenButtonGedrueckt: false,
      }
    case "EINSATZ_ABSCHLUSS_ERFOLG":
      document.getElementsByTagName('h1')[0].innerHTML = action.headerText; // ausserhalb React-Komponente, deshalb nicht via State
      return {
        ...state,
        abgeschlossen: true,
        abschlussGestartet: false,
        abschlussButtonGedrueckt: false,
        readonly: true,
      };
    case "EINSATZ_ABSCHLUSS_FEHLER":
      return {
        ...state,
        abgeschlossen: false,
        abschlussGestartet: false,
        abschlussButtonGedrueckt: false,
        readonly: false,
      };
    case "EINSATZ_LOESCHEN_ERFOLG":
      return {
        ...state,
        loeschenGestartet: true, // Dies bleibt auf true da nach erfolg ein weiterleiten gemacht wird und der Benutzer nicht das Gefühl haben soll das dies nicht erfolgreich war.
        loeschenButtonGedrueckt: false,
      }
    case "EINSATZ_LOESCHEN_FEHLER":
      return {
        ...state,
        loeschenGestartet: false,
        loeschenButtonGedrueckt: false,
      }
    case "AJAX_EINSATZ_DATEN_ZURUECKSETZEN":
      return einsatzDatenLaden(action.serverDaten);
    default:
      return state || {};
  }
}

export default einsatzReducer
