
const API_REQUEST_AKTIV = 'API_REQUEST_AKTIV';

export function apiRequestAktiv(aktiv) {
  return (dispatch) => {
    dispatch({type: API_REQUEST_AKTIV, aktiv: aktiv});
  };
}

function apiReducer(state = {}, action) {
  switch (action.type) {
    case API_REQUEST_AKTIV:
      return {
        ...state,
        aktiv: action.aktiv
      };
    default:
      return state;
  }
}

export default apiReducer;
