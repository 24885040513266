import React from "react";
import {connect} from "react-redux";

const ErgebnisTrainer = ({ergebnisTrainer, dokumentPfad, texte}) => {
  if (ergebnisTrainer) {
    return (
      <div className="block">
        <div className="header">
          <b>{texte.teilnehmerAbschluss.abschlussBeurteilung}</b>
        </div>
        <div className="content edit">
          <div className="zeile">
            <label>{texte.teilnehmerAbschluss.noteTechnik}</label>
            <span>{ergebnisTrainer.noteTechnik}</span>
          </div>
          <div className="zeile">
            <label>{texte.teilnehmerAbschluss.noteMethodik}</label>
            <span>{ergebnisTrainer.noteMethodik}</span>
          </div>
          <div className="zeile">
            <label>{texte.teilnehmerAbschluss.noteKondition}</label>
            <span>{ergebnisTrainer.noteKondition}</span>
          </div>
          <div className="zeile">
            <label>{texte.teilnehmerAbschluss.noteTheorie}</label>
            <span>{ergebnisTrainer.noteTheorie}</span>
          </div>
          <div className="zeile">
            <label>{texte.teilnehmerAbschluss.noteWeiterempfehlung}</label>
            <span>{ergebnisTrainer.noteWeiterempfehlung}</span>
          </div>
          <div className="zeile">
            <label>{texte.teilnehmerAbschluss.notePotenzial}</label>
            <span>{ergebnisTrainer.notePotenzial}</span>
          </div>
          <div className="zeile">
            <label>{texte.teilnehmerAbschluss.note}</label>
            <span>{ergebnisTrainer.note}</span>
          </div>
          <div className="zeile">
            <label>{texte.teilnehmerAbschluss.notenblatt}</label>
            <span>{ergebnisTrainer.notenblatt}</span>
          </div>
          <div className="zeile">
            <label>{texte.teilnehmerAbschluss.abschlussBeurteilung}</label>
            <span className="kommentar">{ergebnisTrainer.abschlussBeurteilung}</span>
          </div>
          {ergebnisTrainer.hatDokument &&
          <div className="zeile">
            <label>{texte.teilnehmerAbschluss.abschlussDokument}</label>
            <span className="datei-upload">
              <a href={`${dokumentPfad}?ergebnisTrainerId=${ergebnisTrainer.id}`} target="_blank" className={`ikon ${ergebnisTrainer.dokumentIcon}`}/>
            </span>
          </div>
          }
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state) => {
  return {
    ergebnisTrainer: state.ergebnisTrainer,
    dokumentPfad: state.api.update,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ErgebnisTrainer);
