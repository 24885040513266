// @flow

import type {AppState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";
import Aktionen from "../Training/Aktionen";
import Gesamtnoten from "./Gesamtnoten";
import Turniernoten from "./Turniernoten";

const Turnier = (props: {
  i18n: any,
}) => {
  return (
    <div className="tab-pane">
      <h3>{props.i18n.subtitel.gesamtnoten}</h3>
      <Gesamtnoten/>
      <h3>{props.i18n.subtitel.turniernoten}</h3>
      <Turniernoten/>
      <Aktionen/>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Turnier);
