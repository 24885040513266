// @flow

import type {AppState} from "../../../../state/stateTypes";
import type {Dispatch} from "../../../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

const ZusatzDropdown = (props) => {
  return (
    <div style={{margin: '0 10px 0 0'}}>
      <select style={{width: '200px'}} value={props.gewaehlterWert || ''} onChange={props.wertChange} disabled={"disabled"}>
        <option value="">&nbsp;</option>
        {
          props.werteListe.map((werteEintrag) => {
            return <option key={werteEintrag.id} value={werteEintrag.id}>{werteEintrag.name}</option>;
          })
        }
      </select>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZusatzDropdown);
