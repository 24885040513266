import React from 'react'
import {connect} from "react-redux";
import {EREIGNIS_TYP} from "../lib/spielablauf";
import EreignisIcon from "./EreignisIcon";

const PenaltytorAnzeige = ({team, ereignisse, texte}) => {
  const penalties = ereignisse.filter((e) => e.typ_id === EREIGNIS_TYP.penaltyschiessen && e.team_id === team.id);
  const leereintraege = [];
  for (let i = penalties.length; i < 5; i++) {
    leereintraege.push(<span key={i} />)
  }
  
  return <div className="penaltytor-anzeige">
    <div className="titel">{texte.titel}</div>
    <div className="torliste">
      {penalties.map((ereignis, index) => {
        return <span key={index}>
          <EreignisIcon ereignis={ereignis}/>
        </span>;
      })}
      {leereintraege}
    </div>
  </div>;
};

const mapStateToProps = (state) => {
  return {
    ereignisse: state.spielverlauf,
    texte: state.i18n.penaltytor_anzeige,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PenaltytorAnzeige);
