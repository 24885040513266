// @flow

import type {Action} from "./actionTypes";

import {combineReducers} from 'redux';

import navigationReducer from "./modules/navigation";
import einsatzReducer from "./modules/einsatz";
import spielReducer from "./modules/spiel";
import teamsReducer from "./modules/teams";
import protokollReducer from "./modules/protokoll";
import turnierReducer from "./modules/turnier";
import trainingReducer from "./modules/training";
import spielerReducer from "./modules/spieler";
import validierungReducer from "./modules/validierung";
import ajaxReducer from "./modules/ajax";

function dummyReducer(state: {}, action: Action) {
  return state || null;
}

export default combineReducers({
  navigation: navigationReducer,
  einsatz: einsatzReducer,
  spiel: spielReducer,
  teams: teamsReducer,
  protokoll: protokollReducer,
  turnier: turnierReducer,
  training: trainingReducer,
  spielerliste: spielerReducer,
  schiedsrichterliste: dummyReducer,
  verantwortlichenliste: dummyReducer,
  validierung: validierungReducer,
  ajax: ajaxReducer,
  i18n: dummyReducer,
  pfade: dummyReducer,
  stammdaten: dummyReducer,
});
