import React from "react";

const FormRadioListeHorizontal = ({name, value, readonly = false, onRadioChange}) => {
  const selectedWert = value || 0; // Es wird im Coaching_bericht ein default value von 0 gesetzt.
  const wertOptionen = [1, 0, -1];

  const erstelleInhalt = (option) => {
    if (!readonly) {
      return <input type="radio" name={name} value={option} checked={selectedWert === option} onChange={() => onRadioChange(name, option)}/>;
    }

    if (selectedWert === option) {
      return <i className="icon-ok"/>;
    }
    return "-";
  };

  return (
    <>
      {wertOptionen.map(option => {
        return (
          <td key={option} style={{width: 20}}>
            {erstelleInhalt(option)}
          </td>
        );
      })}
    </>
  );
};

export default FormRadioListeHorizontal;
