function preloaderRechnungskontrollen(options) {
  return {
    rechnungen: options.rechnungen,
    status: { sucheLaeuft: false, sucheGestartet: false, fehler: [] },
    teams: options.teams,
    listen: options.listen,
    bexio: options.bexio,
    api: options.api,
    i18n: options.i18n,
    rollbar: options.rollbar,
  };
}

export default preloaderRechnungskontrollen;
