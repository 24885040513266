import {executeRequest} from "../lib/api";

const BEZOGENE_LEISTUNGEN_UPDATE = 'BEZOGENE_LEISTUNGEN_UPDATE';

export function bezogeneLeistungUpdate(bezogeneLeistungen) {
  return (dispatch) => {
    dispatch({type: BEZOGENE_LEISTUNGEN_UPDATE, bezogeneLeistungen: bezogeneLeistungen});
  };
}

export function bezogeneLeistungDelete(klassenlehrer, bezogeneLeistungId) {
  return (dispatch, getState) => {
    const state = getState();
    const daten = {
      kontext: 'leistung_loeschen',
      ausbildungId: klassenlehrer.ausbildungId,
      bezogeneLeistungId: bezogeneLeistungId
    };
    return executeRequest(state.api.update, state.api.token, 'PUT', daten, (result) => {
      dispatch({type: BEZOGENE_LEISTUNGEN_UPDATE, bezogeneLeistungen: result.data.bezogeneLeistungen});
    });
  };
}

function bezogeneLeistungenReducer(state = [], action) {
  switch (action.type) {
    case BEZOGENE_LEISTUNGEN_UPDATE:
      const bezogeneLeistungen = action.bezogeneLeistungen;
      return [
        ...state.filter(bl => bl.ausbildungId !== bezogeneLeistungen.ausbildungId),
        bezogeneLeistungen
      ];
    default:
      return state;
  }

}

export default bezogeneLeistungenReducer;
