import React, {useEffect, useState} from "react";

function ikonCssFuerEndung(endung) {
  let css = '';
  switch (endung) {
    case 'pdf':
      css = 'ikon-pdf'
      break;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      css = 'ikon-bild';
      break;
    case 'mov':
    case 'mp4':
      css = 'ikon-video';
      break;
    case 'doc':
    case 'docx':
      css = 'ikon-word';
      break;
    case 'xls':
    case 'xslx':
    case 'csv':
      css = 'ikon-csv';
      break;
    case 'ppt':
    case 'pptx':
      css = 'ikon-ppt';
      break;
    default:
      css = 'ikon-print';
  }
  return `ikon ${css}`;
}

const IkonFuerDatei = ({dateiname}) => {
  const [dateiendung, setDateiendung] = useState(null);

  useEffect(() => {
    setDateiendung(dateiname.split('.').reverse()[0]);
  }, [dateiendung])

  if (dateiname) {
    return <i className={ikonCssFuerEndung(dateiendung)}/>
  }
  return <></>;
};

export default IkonFuerDatei;
