import React from "react";
import useGlobal from "../../store";

const VideoPreviews = () => {
  const [globalState, globalActions] = useGlobal();

  const changeVideo = id => {
    globalActions.selectVideo(id);
  };

  const startPreview = id => {
    const previewVideo = document.getElementById(`preview-${id}`);
    if (previewVideo) {
      previewVideo.play().catch(() => {
        // noop
      });
    }
  };
  const stopPreview = id => {
    const previewVideo = document.getElementById(`preview-${id}`);
    if (previewVideo) {
      previewVideo.currentTime = 0;
      previewVideo.pause();
    }
  };

  const isSelectedVideo = id => {
    return globalState.selectedVideo === id;
  };

  const getPreview = video => {
    let selectedClassName = isSelectedVideo(video.id) ? "selected" : "";
    return (
      <div
        className={`preview ${selectedClassName}`}
        id={`preview-video-${video.id}`}
        key={video.id}
        onClick={() => changeVideo(video.id)}
        onMouseOver={e => startPreview(video.id)}
        onMouseLeave={e => stopPreview(video.id)}
      >
        <video muted loop id={`preview-${video.id}`}>
          <source src={video.video_name} type="video/mp4"/>
        </video>
        <div className="preview-text">
          <div className="flex-1">&nbsp;</div>
          <b>{video.spiel_abschnitt}</b>
          <div className="smaller">{video.untertitel}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="preview-wrapper flex-row">
      {globalState.videos.map(video => getPreview(video))}
    </div>
  );
};

export default VideoPreviews;
