import {executeRequest} from "../lib/api";
import {ersetzeStringMitDatum, sortierePersonName} from "../lib/formatierungen";
import {bibliothekReferenzSetzen} from "./teamTeilnehmerReducer";
import {sortiereAufgabenZuteilungen} from "./teilnehmerAufgabenReducer";
import {fehlermeldungCreate} from "./fehlermeldungReducer";

const TEAM_TEILNEHMER_AUFGABE_UPDATE = 'TEAM_TEILNEHMER_AUFGABE_UPDATE';
const TEAM_TEILNEHMER_UPDATE = 'TEAM_TEILNEHMER_UPDATE';
const TEAM_TEILNEHMER_DELETE = 'TEAM_TEILNEHMER_DELETE';

export function teamZuteilungAufgabeZuruecksetzen(aufgabe) {
  return (dispatch, getState) => {
    const state = getState();
    const daten = {
      kontext: 'team_teilnehmer_aufgabe_reset',
      teamZuteilungId: aufgabe.kursplanTeamsId,
      aufgabeZuteilungId: aufgabe.id,
      beurteilungAbgeschlossen: false,
    };

    return executeRequest(state.api.update, state.api.token, 'PUT', daten, (result) => {
      const aufgabe = ersetzeStringMitDatum(result.data.aufgabe, ['von', 'bis']);
      bibliothekReferenzSetzen(aufgabe, state.bibliothek);
      dispatch({type: TEAM_TEILNEHMER_AUFGABE_UPDATE, aufgabe: aufgabe});
    });
  };
}

export function teamZuteilungUpdate(teamZuteilung) {
  return (dispatch) => {
    if (Array.isArray(teamZuteilung)) {
      teamZuteilung.forEach(tt => dispatch({type: TEAM_TEILNEHMER_UPDATE, teamZuteilung: tt}));
    } else {
      dispatch({type: TEAM_TEILNEHMER_UPDATE, teamZuteilung: teamZuteilung});
    }
  };
}

export function teamZuteilungSpeichern(team, ausbildner, zusatzdaten) {
  return (dispatch, getState) => {
    const state = getState();
    const daten = {
      kontext: 'team_teilnehmer_update',
      teamId: team.id,
      ausbildnerId: ausbildner.id
    };
    if (zusatzdaten.teamZuteilungId) daten['teamZuteilungId'] = zusatzdaten.teamZuteilungId;
    if (zusatzdaten.statusId) daten['statusId'] = zusatzdaten.statusId;

    return executeRequest(state.api.update, state.api.token, 'PUT', daten, (result) => {
      const teamZuteilung = ersetzeStringMitDatum(result.data.teamZuteilung, ['abgeschlossenAm']);
      dispatch({type: TEAM_TEILNEHMER_UPDATE, teamZuteilung: teamZuteilung});
    });
  };
}

export function teamZuteilungAbschluss(teamZuteilung, zusatzdaten) {
  return (dispatch, getState) => {
    const api = getState().api;
    const daten = {
      ...zusatzdaten,
      kontext: 'team_teilnehmer_abschluss',
      teamId: teamZuteilung.teamId,
      teamZuteilungId: teamZuteilung.id,
    };
    return executeRequest(api.update, api.token, 'PUT', daten, (result) => {
      const tz = ersetzeStringMitDatum(result.data.teamZuteilung, ['abgeschlossenAm']);
      tz.aufgaben = teamZuteilung.aufgaben;
      dispatch({type: TEAM_TEILNEHMER_UPDATE, teamZuteilung: tz});
    });
  };
}

export function teamZuteilungDelete(teamZuteilung) {
  return (dispatch, getState) => {
    const api = getState().api;
    const daten = {
      kontext: 'team_teilnehmer_delete',
      teamId: teamZuteilung.teamId,
      teamZuteilungId: teamZuteilung.id,
    };
    return executeRequest(api.update, api.token, 'PUT', daten, (result) => {
      if (result.ok) {
        dispatch({type: TEAM_TEILNEHMER_DELETE, teamZuteilung: teamZuteilung});
      } else {
        dispatch(fehlermeldungCreate(result.data.errors));
      }
    });
  };
}

export function teamZuteilungenEntfernen(team) {
  return (dispatch, getState) => {
    getState()
      .teamTeilnehmer
      .filter(tt => tt.teamId === team.id)
      .forEach(tt => { dispatch({type: TEAM_TEILNEHMER_DELETE, teamZuteilung: tt}); });
  };
}

function lehrerTeamTeilnehmerReducer(state = [], action) {
  let teamZuteilung = null;

  switch (action.type) {
    case TEAM_TEILNEHMER_AUFGABE_UPDATE:
      const aufgabe = action.aufgabe;
      teamZuteilung = state.find(tt => tt.teamId === aufgabe.kursplanTeamsId);
      teamZuteilung.aufgaben = [
        ...teamZuteilung.aufgaben.filter(a => a.id !== aufgabe.id),
        aufgabe
      ];
      teamZuteilung.aufgaben.sort(sortiereAufgabenZuteilungen);
      return [
        ...state.filter(tt => tt.id !== teamZuteilung.id),
        {...teamZuteilung}
      ].sort((a, b) => sortierePersonName(a.ausbildner, b.ausbildner));
    case TEAM_TEILNEHMER_UPDATE:
      teamZuteilung = action.teamZuteilung;
      return [
        ...state.filter(tt => tt.id !== teamZuteilung.id),
        {...teamZuteilung}
      ].sort((a, b) => sortierePersonName(a.ausbildner, b.ausbildner));
    case TEAM_TEILNEHMER_DELETE:
      teamZuteilung = action.teamZuteilung;
      return state.filter(tt => tt.id !== teamZuteilung.id);
    default:
      return state;
  }

}

export default lehrerTeamTeilnehmerReducer;
