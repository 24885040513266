import React, {useState, useMemo} from "react";
import {connect} from "react-redux";
import PersonBadge from "../../../../components/PersonBadge";
import {formatiereBetrag} from "../../../../lib/formatierungen";
import {SPONSORENLAUF_EINSATZ} from "../../lib/constants";
import Pager from "../../../../components/Pager";
import Tooltip from "../../../../components/Tooltip";
import Filter from "./Filter";
import {downloadCsv, filtereSponsorenlaufSponsoren} from "../../lib/datenHelper";
import IkonFuerDatei from "../../../../components/IkonFuerDatei";
import SponsorBearbeitenModal from "./SponsorBearbeitenModal";

function sortiereSponsoren(liste, spielerliste, sortierung) {
  liste.sort((a, b) => {
    let v = 0;
    for (let i = 0; i < sortierung.length; i++) {
      let sort = sortierung[i];
      // 0 bedeutet nicht aktiv!
      if (sort.dir !== 0) {
        if (sort.feldname == 'spieler') {
          let spielerA = spielerliste.find(s => s.spielerId == a.spielerId)?.person?.name || '';
          let spielerB = spielerliste.find(s => s.spielerId == b.spielerId)?.person?.name || '';
          v = spielerA.localeCompare(spielerB);
        } else {
          v = a[sort.feldname].localeCompare(b[sort.feldname]);
        }
        if (v !== 0) {
          return v * sort.dir;
        }
      }
    }
    return v;
  });
  return liste;
}

const Sponsoren = ({spielerliste, teams, sponsoren, spielerkategorien, pageSizeArray, einsaetze, i18n}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [filterValues, setFilterValues] = useState(null);
  const [pageSize, setPageSize] = useState(999999);
  const [pageIndex, setPageIndex] = useState(1);
  const [sortierung, setSortierung] = useState([{feldname: 'spieler', dir: 1}, {feldname: 'name', dir: 1}]);
  const [sponsorBearbeiten, setSponsorBearbeiten] = useState(null);
  const texte = i18n.texte;
  const texteSponsoren = i18n.texte.sponsoren;

  const alleTeamIds = useMemo(() => {
    return sponsoren.map(s => s.teamId).filter((id, index, self) => self.indexOf(id) === index);
  }, [sponsoren]);

  const filterReset = () => {
    setFilterValues(null);
    pageIndexChange();
  };

  const pageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    pageIndexChange();
  };

  const pageIndexChange = (index = 1) => {
    setPageIndex(index);
  };

  const gefilterteSponsorenliste = useMemo(() => {
    return sortiereSponsoren(
      filtereSponsorenlaufSponsoren(sponsoren, filterValues, spielerkategorien, spielerliste),
      spielerliste,
      sortierung
    );
  }, [sponsoren, filterValues, sortierung])

  const paginated = useMemo(() => {
    let startIndex = (pageIndex - 1) * pageSize;
    let endIndex = Math.min(pageIndex * pageSize, sponsoren.length);
    return gefilterteSponsorenliste.slice(startIndex, endIndex);
  }, [pageIndex, pageSize, gefilterteSponsorenliste]);

  const onSortieren = (feldname) => {
    let sort = sortierung.find(s => s.feldname === feldname);
    if (sort) {
      if (sort.dir === 0) {
        sort.dir = 1;
      } else if (sort.dir === 1) {
        sort.dir = -1;
      } else {
        sort.dir = 0;
      }
      // position für das feld ist wegen click num am schluss:
      setSortierung([...sortierung.filter(s => s.feldname !== sort.feldname), sort]);
    } else {
      setSortierung([...sortierung, {feldname: feldname, dir: 1}]);
    }
    setPageIndex(1); // sortierung ändern setzt immer paging zurück
  };

  const generateCsv = (event) => {
    event.preventDefault();

    let data = [];
      data.push([
        texte.csv.nachname, texte.csv.vorname, texte.sponsoren.mail, texte.sponsoren.adresse, texte.csv.plz, texte.csv.ort, texte.csv.land,
        texte.sponsoren.spieler, texte.sponsoren.team,
        texte.anzahlRunden, texte.sponsoren.einsatz,
        texte.betragPauschal, texte.betragRunde, texte.betragTotal
      ]);

    gefilterteSponsorenliste.forEach(sponsor => {
      let spieler = spielerliste.find(s => s.spielerId == sponsor.spielerId);
      let team = teams.find(t => t.id == sponsor.teamId);
      let einsatz = einsaetze.find(e => e.id == sponsor.einsatzId);

        data.push([
          sponsor.nachname, sponsor.vorname, sponsor.email, sponsor.adresse.strasse, sponsor.adresse.plz, sponsor.adresse.ort, sponsor.adresse.land,
          `${spieler.person.nachname} ${spieler.person.vorname}`, team.bezeichnung,
          sponsor.anzahlRunden, einsatz.bezeichnung,
          formatiereBetrag(sponsor.betragPauschal, i18n.locale), formatiereBetrag(sponsor.betragRunde, i18n.locale), formatiereBetrag(sponsor.betragTotal, i18n.locale)
        ]);
    });

    downloadCsv(data, texte.csv.filenameSponsor);
  };

  const renderSortierung = (feldname, text) => {
    const css = ['sort-me'];
    let index = null;
    let sort = sortierung.find(s => s.feldname === feldname);
    if (sort) {
      css.push('active');
      if (sort.dir === 1) {
        css.push('desc');
      } else if (sort.dir === -1) {
        css.push('asc');
      }
      if (sort.dir !== 0) {
        // reihenfolge der suche anzeigen. zahl ist index + 1, aber nur die sortierungen beachten welche auch aktiv sind!
        index = <sup> {sortierung.filter(s => s.dir !== 0).indexOf(sort) + 1}</sup>;
      }
    }
    return <div className={css.join(' ')} onClick={() => onSortieren(feldname)}>{text}{index}</div>;
  };

  return (
    <section>
      <div className="pull-right">
        {filterValues === null || <>
          <Tooltip tagName="button" className="btn btn-small" content={texte.filter.loeschen} onClick={filterReset}>
            <i className="icon-remove"/>
          </Tooltip>
          &nbsp;
        </>}
        <button onClick={() => setShowFilter(!showFilter)} className="btn btn-small"><i className="icon-filter"/>{texte.filter.button}</button>
      </div>
      <h2 className="page-section">{texteSponsoren.titel} ({gefilterteSponsorenliste.length})</h2>
      <div>
        <a href="" onClick={ev => generateCsv(ev)}>
          <IkonFuerDatei dateiname={'.csv'}/>
        </a>
      </div>
      {showFilter &&
        <Filter filterValues={filterValues} teamIds={alleTeamIds} fuerSponsor={true} onFilterChange={filter => setFilterValues(filter)}/>
      }
      <Pager pageIndex={pageIndex} pageSize={pageSize} totalItemCount={gefilterteSponsorenliste.length} pageSizeArray={pageSizeArray} onPageChange={pageIndexChange} onPageSizeChange={pageSizeChange}/>
      <table className="attributes table table-condensed badge-compact table-sort">
        <thead>
        <tr>
          <th>{renderSortierung('name', texteSponsoren.name)}</th>
          <th>{texteSponsoren.adresse}</th>
          <th>{renderSortierung('spieler', texteSponsoren.spieler)}</th>
          {/*<th>{texteSponsoren.team}</th>*/}
          <th>{texteSponsoren.einsatz}</th>
          <th>{texteSponsoren.betrag}</th>
          <th>{texte.anzahlRunden}</th>
          <th>{texte.betragTotal}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {paginated.map(sponsor => {
          let spieler = spielerliste.find(s => s.spielerId == sponsor.spielerId);
          // let team = teams.find(t => t.id == sponsor.teamId);
          let einsatz = einsaetze.find(e => e.id == sponsor.einsatzId);
          let betrag = sponsor.einsatzId == SPONSORENLAUF_EINSATZ.runde ? sponsor.betragRunde : sponsor.betragPauschal;
          return (<tr key={sponsor.id}>
              <td>{sponsor.name}</td>
              <td>
                {sponsor.adresse.strasse}
                <br/>
                {sponsor.adresse.plz} {sponsor.adresse.ort}
                <br/>
                {sponsor.adresse.land}
              </td>
              <td>
                {spieler && spieler.person &&
                  <PersonBadge person={spieler.person} className="compact" fotoTooltipAktiv={false} personVerlinken={false} showVcf={false}/>
                }
              </td>
              {/*<td><TeamBadge team={team} className="compact"/></td>*/}
              <td>{einsatz.bezeichnung}</td>
              <td>{formatiereBetrag(betrag, i18n.locale)}</td>
              <td>{sponsor.anzahlRunden}</td>
              <td>{formatiereBetrag(sponsor.betragTotal, i18n.locale)}</td>
              <td>
                <Tooltip tagName="button" className="btn btn-small" content={texteSponsoren.bearbeiten} onClick={() => setSponsorBearbeiten(sponsor.id)}>
                  <i className="icon-edit"/>
                </Tooltip>
              </td>
            </tr>);
        })}
        </tbody>
      </table>
      {sponsorBearbeiten && 
        <SponsorBearbeitenModal sponsor={paginated.find(sponsor => sponsor.id === sponsorBearbeiten)} onHide={() => setSponsorBearbeiten(null)}/>
      }
      <Pager pageIndex={pageIndex} pageSize={pageSize} totalItemCount={gefilterteSponsorenliste.length} pageSizeArray={pageSizeArray} onPageChange={pageIndexChange} onPageSizeChange={pageSizeChange}/>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    spielerliste: state.spieler,
    teams: state.listen.teams,
    sponsoren: state.sponsoren,
    spielerkategorien: state.listen.spielerkategorien,
    pageSizeArray: state.listen.pager,
    einsaetze: state.listen.einsaetze,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sponsoren);
