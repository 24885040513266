import React, {useEffect} from 'react'
import {connect} from "react-redux";
import Room from "./Room";
import {setCurrentRoom} from "../state/settings";
import {closeRoom} from "../state/rooms";

const AnsichtSupport = ({person, rooms, settings, texte, closeRoom, setCurrentRoom}) => {

  useEffect(() => {
    if (!settings.currentRoom) {
      rooms.forEach(room => {
        if (room.teilnehmer.find(t => t.istSupport === true && t.name === person.name)) {
          setCurrentRoom(room);
          return;
        }
      });
    }
  }, [rooms]);

  if (settings.currentRoom) {
    return <Room room={settings.currentRoom}/>;
  } else {
    const titel = rooms.length === 0 ? texte.keineAnfragen : texte.aktuelleAnfragen ;

    return <div>
      <h2>{titel}</h2>
      {rooms.length > 0 &&
      <table className="table table-striped">
        <thead>
          <tr>
            <th>{texte.agent}</th>
            <th>{texte.erstellt}</th>
            <th>{texte.benutzer}</th>
            <th>{texte.vereine}</th>
            <th>{texte.telefon} / {texte.email}</th>
            <th/>
          </tr>
        </thead>
        <tbody>
        {rooms.map(room => {
          const p = room.person;
          const created = new Date(room.created);
          const teilnehmer = room.teilnehmer.filter(t => t.istSupport === true && t.name !== person.name);
          return (
            <tr key={room.sid}>
              <td>
                {teilnehmer.length > 0 &&
                teilnehmer.map(t => <div key={t.sid}>{t.name}</div>)
                }
                {teilnehmer.length === 0 &&
                <button onClick={() => setCurrentRoom(room)} className="btn btn-success">
                  <div className="pulsating puls1">
                    <div className="dot"/>
                    &nbsp;&nbsp;{texte.verbinden}
                  </div>
                </button>
                }
              </td>
              <td>
                <span className="zeile">{created.toLocaleDateString()} {created.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}</span>
              </td>
              <td>
                {p.name} ({p.id})
                {room.teilnehmer.filter(t => t.istSupport === false).length === 0 &&
                <span className="label label-warning">{texte.nichtVerbunden}</span>
                }
              </td>
              <td>{p.vereine.map((v, index) => <span key={index} className="zeile">{v}</span>)}</td>
              <td>
                {p.telefon.map((tel, index) => <a key={index} className="zeile" href={`tel:${tel}`}>{tel}</a>)}
                {p.email.map((email, index) => <a key={index} className="zeile" href={`mailto:${email}`}>{email}</a>)}
              </td>
              <td>
                {teilnehmer.length === 0 &&
                <button onClick={() => closeRoom(room)} className="btn btn-danger">{texte.schliessen}</button>
                }
              </td>
            </tr>
          );
        })}
        </tbody>
      </table>
      }
    </div>;
  }
};

const mapStateToProps = (state) => {
  return {
    person: state.person,
    rooms: state.rooms,
    settings: state.settings,
    texte: state.i18n.ansichtSupport,
    api: state.api,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeRoom: (room) => { dispatch(closeRoom(room)); },
    setCurrentRoom: (room) => { dispatch(setCurrentRoom(room)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnsichtSupport);
