import React from "react";
import {connect} from "react-redux";
import DatumPicker from "../../../components/DatumPicker";
import NoteInput from "../../../components/NoteInput";
import {prognoseWertBerechnen} from "../../../lib/prognoseFragen";
import {selektiertBewertung} from "../state/modules/selektiert";

const BewertungBearbeiten = ({bewertung, prognoseFragen, i18n, updateBewertung}) => {
  const readonly = bewertung.abgeschlossen;
  const texte = i18n.texte;

  const setzeNote = (key, value) => {
    const daten = {...bewertung};
    daten[key] = value;
    const durchschnitt = daten.note_durchschnitt = (daten.technik + daten.intelligenz + daten.persoenlichkeit + daten.schnelligkeit) / 4;
    if (durchschnitt > 0) {
      daten.note_durchschnitt = durchschnitt;
    }
    updateBewertung(daten);
  };

  const setzeWert = (key, value) => {
    const daten = {...bewertung};
    daten[key] = value;
    updateBewertung(daten);
  };

  const setzeFrage = (key, frageId, frageSelektiert) => {
    const daten = {...bewertung};
    daten.prognose_fragen[key] = {id: frageId, selektiert: frageSelektiert};
    daten.prognose_fragen.prognose = [1, 2, 3, 4, 5, 6].map(frageNr => daten.prognose_fragen[`frage${frageNr}`].selektiert).filter(b => b === true).length;
    updateBewertung(daten);
  };

  const renderTips = () => {
    const texteTips = texte.tips;

    return (
      <table className="table table-condensed">
        <colgroup>
          <col width="20%"/>
          <col width="40px"/>
          <col width="*"/>
        </colgroup>
        <thead>
        <tr>
          <th colSpan={3}>{texte.tipsTitel}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{texte.datum}</td>
          <td><DatumPicker disabled={readonly} value={bewertung.datum_zeit} onChange={datum => setzeWert('datum_zeit', datum)} locale={i18n.locale} /></td>
          <td></td>
        </tr>
        <tr>
          <td>{texteTips.technik}</td>
          <td><NoteInput disabled={readonly} value={bewertung.technik} onChange={wert => setzeNote('technik', wert)}/></td>
          <td><textarea className="input-100prozent" rows={3} disabled={readonly} value={bewertung.technik_kommentar || ''} onChange={ev => setzeWert('technik_kommentar', ev.currentTarget.value)}/></td>
        </tr>
        <tr>
          <td>{texteTips.intelligenz}</td>
          <td><NoteInput disabled={readonly} value={bewertung.intelligenz} onChange={wert => setzeNote('intelligenz', wert)}/></td>
          <td><textarea className="input-100prozent" rows={3} disabled={readonly} value={bewertung.intelligenz_kommentar || ''} onChange={ev => setzeWert('intelligenz_kommentar', ev.currentTarget.value)}/></td>
        </tr>
        <tr>
          <td>{texteTips.persoenlichkeit}</td>
          <td><NoteInput disabled={readonly} value={bewertung.persoenlichkeit} onChange={wert => setzeNote('persoenlichkeit', wert)}/></td>
          <td><textarea className="input-100prozent" rows={3} disabled={readonly} value={bewertung.persoenlichkeit_kommentar || ''} onChange={ev => setzeWert('persoenlichkeit_kommentar', ev.currentTarget.value)}/></td>
        </tr>
        <tr>
          <td>{texteTips.schnelligkeit}</td>
          <td><NoteInput disabled={readonly} value={bewertung.schnelligkeit} onChange={wert => setzeNote('schnelligkeit', wert)}/></td>
          <td><textarea className="input-100prozent" rows={3} disabled={readonly} value={bewertung.schnelligkeit_kommentar || ''} onChange={ev => setzeWert('schnelligkeit_kommentar', ev.currentTarget.value)}/></td>
        </tr>
        <tr>
          <td>{texteTips.durchschnittsnote}</td>
          <td>{bewertung.note_durchschnitt > 0 && <NoteInput disabled={true} value={bewertung.note_durchschnitt}/>}</td>
          <td></td>
        </tr>
        <tr>
          <td>{texte.spielernote}</td>
          <td><NoteInput disabled={readonly} value={bewertung.spieler_note} onChange={wert => setzeNote('spieler_note', wert)}/></td>
          <td></td>
        </tr>
        <tr>
          <td>{texte.entwicklung}</td>
          <td><NoteInput disabled={readonly} value={bewertung.entwicklung} onChange={wert => setzeNote('entwicklung', wert)}/></td>
          <td><textarea className="input-100prozent" rows={3} disabled={readonly} value={bewertung.entwicklung_kommentar || ''} onChange={ev => setzeWert('entwicklung_kommentar', ev.currentTarget.value)}/></td>
        </tr>
        <tr>
          <td>{texte.kommentar}</td>
          <td colSpan={2}><textarea className="input-100prozent" rows={3} disabled={readonly} value={bewertung.spieler_kommentar || ''} onChange={ev => setzeWert('spieler_kommentar', ev.currentTarget.value)}/></td>
        </tr>
        </tbody>
      </table>
    );
  };

  const renderPrognoseFragen = () => {
    const prognose = prognoseWertBerechnen(bewertung.prognose_fragen.prognose);
    return (
      <table className="table table-condensed">
        <colgroup>
          <col width="*"/>
          <col width="40px"/>
        </colgroup>
        <thead>
        <tr>
          <th>{texte.prognoseFragenTitel}</th>
          <th className={`text-center prognose ${prognose.farbe}`}>{prognose.buchstabe}</th>
        </tr>
        </thead>
        <tbody>
        {prognoseFragen.map((frage, index) => {
          const frageKey = `frage${index + 1}`;
          const bewertungFrage = bewertung.prognose_fragen[frageKey];
          return (
            <tr key={frage.id}>
              <td><label htmlFor={`cb${frageKey}`} className="text-bold">{frage.text}</label></td>
              <td className="text-center"><input id={`cb${frageKey}`} type="checkbox" checked={bewertungFrage.selektiert} onChange={ev => setzeFrage(frageKey, frage.id, ev.currentTarget.checked)}/></td>
            </tr>
          );
        })}
        <tr>
          <td colSpan={2}>
            <label>{texte.bemerkungen}</label>
            <textarea className="input-100prozent" rows={3} disabled={readonly} value={bewertung.bemerkungen || ''} onChange={ev => setzeWert('bemerkungen', ev.currentTarget.value)}/>
          </td>
        </tr>
        </tbody>
      </table>
    );
  };

  return (
    <>
      {renderTips()}
      {renderPrognoseFragen()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bewertung: state.selektiert.bewertung,
    prognoseFragen: state.listen.prognoseFragen,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateBewertung: (bewertung) => { dispatch(selektiertBewertung(bewertung)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BewertungBearbeiten);
