// @flow

import type {EinsatzState, TeamState, Team, TrainingState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";
import TeamBadge from "../../../../components/TeamBadge";

const TrainingEinsatzInfos = ({einsatz, training, teams, texte}: { einsatz: EinsatzState, training: TrainingState, teams: TeamState, texte: any}) => {
  let team = teams.liste.find(t => t.id === training.teamId);

  return (
    <div className="tab-pane">
      <table className="table table-striped">
        <tbody>
        <tr>
          <th>{texte.titel.zeit}</th>
          <td>
            <i className="icon-calendar"/> {einsatz.datum}
            <br/>
            <i className="icon-time"/> {einsatz.zeit}
          </td>
        </tr>
        <tr>
          <th>{texte.titel.ort}</th>
          <td>
            {training.wettspielfeld || '-'}
            {training.halle && <><br/>{training.halle}</>}
            {training.bereich && <><br/>{training.bereich}</>}
          </td>
        </tr>
        <tr>
          <th>{texte.titel.typ}</th>
          <td>
              <span className="label">
                {einsatz.einsatzTyp}
              </span>
          </td>
        </tr>
        <tr>
          <th>{texte.titel.rolle}</th>
          <td>
              <span className="label">
                {einsatz.einsatzRolle}
              </span>
          </td>
        </tr>
        <tr>
          <th>{texte.titel.team}</th>
          <td>
            {team && <TeamBadge className="komplett" team={{name: team.name, logoUrl: team.logo, ligaSpielklasse: team.ligaUndSpielklasse}} />}
          </td>
        </tr>
        <tr>
          <th>{texte.titel.abschluss}</th>
          <td>
            {
              einsatz.abgeschlossen ? (
                einsatz.abschlussDatum ?
                  texte.abschluss.abgeschlossenAm + " " + einsatz.abschlussDatum :
                  texte.abschluss.abgeschlossen
                ) : texte.abschluss.keinAbschluss
            }
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    einsatz: state.einsatz,
    training: state.training,
    teams: state.teams,
    i18n: state.i18n,
    texte: state.i18n.infos,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingEinsatzInfos);
