// @flow

import type {EinsatzState, SpielState, TeamState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";
import TeamBadge from "../../../../components/TeamBadge";

const SpielEinsatzInfos = ({einsatz, spiel, teams, texte}: { einsatz: EinsatzState, spiel: SpielState, teams: TeamState, texte: any}) => {
  return (
    <div className="tab-pane">
      <table className="table table-striped">
        <tbody>
        <tr>
          <th>{texte.titel.zeit}</th>
          <td>
            <i className="icon-calendar"/> {einsatz.datum}
            <br/>
            <i className="icon-time"/> {einsatz.zeit}
          </td>
        </tr>
        <tr>
          <th>{texte.titel.spiel}</th>
          <td>
            <span className={"label label-info"}>{spiel.spielnummer}</span>
          </td>
        </tr>
        <tr>
          <th>{texte.titel.spieltyp}</th>
          <td>
              <span className="label">{spiel.spieltyp}</span>
          </td>
        </tr>
        <tr>
          <th>{texte.titel.ort}</th>
          <td>
            {spiel.wettspielfeld || '-'}
          </td>
        </tr>
        <tr>
          <th>{texte.titel.typ}</th>
          <td>
            <span className="label">{einsatz.einsatzTyp}</span>
          </td>
        </tr>
        <tr>
          <th>{texte.titel.rolle}</th>
          <td>
            <span className="label">{einsatz.einsatzRolle}</span>
          </td>
        </tr>
        <tr>
          <th>{texte.titel.heimTeam}</th>
          <td>
            {teams.heimTeam && <TeamBadge className="komplett" team={{name: teams.heimTeam.name, logoUrl: teams.heimTeam.logo, ligaSpielklasse: teams.heimTeam.ligaUndSpielklasse}}/>}
          </td>
        </tr>
        <tr>
          <th>{texte.titel.gastTeam}</th>
          <td>
            {teams.gastTeam && <TeamBadge className="komplett" team={{name: teams.gastTeam.name, logoUrl: teams.gastTeam.logo, ligaSpielklasse: teams.gastTeam.ligaUndSpielklasse}}/>}
          </td>
        </tr>
        <tr>
          <th>{texte.titel.abschluss}</th>
          <td>
            {
              einsatz.abgeschlossen ? (
                einsatz.abschlussDatum ?
                  texte.abschluss.abgeschlossenAm + " " + einsatz.abschlussDatum :
                  texte.abschluss.abgeschlossen
                ) : texte.abschluss.keinAbschluss
            }
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    einsatz: state.einsatz,
    spiel: state.spiel,
    teams: state.teams,
    texte: state.i18n.infos,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpielEinsatzInfos);
