import React, {useState} from "react";
import {connect} from "react-redux";
import PersonIcon from "../../../../components/PersonIcon";
import LehrerTeam from "./LehrerTeam";
import IconToggle from "../IconToggle";

const LehrerTeams = ({klassenlehrer, teams, texte}) => {
  const [showTeams, setShowTeams] = useState(true);
  const lehrerTeams = teams.filter(team => team.experte.id === klassenlehrer.id);

  if (lehrerTeams.length === 0) {
    return <></>;
  }
  
  return (
    <div className="block">
      <div className="header">
        <button className="btn btn-mini pull-right" onClick={() => setShowTeams(!showTeams)}>
          <IconToggle istOffen={showTeams}/>
        </button>
        {`${texte.experte}: ${klassenlehrer.name}`}
        <PersonIcon person={klassenlehrer}/>
      </div>
      {showTeams &&
      <div className="content">
        {lehrerTeams.map((team) => <LehrerTeam key={team.id} team={team} showKlassenlehrer={false}/>)}
      </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    teams: state.teams,
    texte: state.i18n.texte
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LehrerTeams);
