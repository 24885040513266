import React, { useEffect } from 'react';

function useEscape(onEscape) {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        onEscape();
      }
    };
    document.addEventListener('keydown', handleEsc);
    return () => document.removeEventListener('keydown', handleEsc);
  }, []);
}

export default useEscape;
