// @flow

import type {AppState, EinsatzState, NavigationState, SpielerState, SpielState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";
import TeamSelektion from "./TeamSelektion";
import Spielerbank from "./Spielerbank";
import Feldspieler from "./Feldspieler";
import Spielposition from "./Spielposition";

const Aufstellung = (props: {
  navigation: NavigationState,
  einsatz: EinsatzState,
  spiel: SpielState,
  spielerliste: SpielerState,
  i18n: any,
  heimTeam: boolean,
}) => {
  let istHeimTeam = props.navigation.aktivesAufstellungTeam === 'heim';
  let spielerkarte = istHeimTeam ? props.spiel.spielerkarten.heimTeam : props.spiel.spielerkarten.gastTeam;

  const einsatzMitPosition = [];
  const einsatzOhnePosition = [];
  spielerkarte.startformation.forEach((einsatz) => {
    if (einsatz.spielposition.zeile === null || einsatz.spielposition.spalte === null) {
      einsatzOhnePosition.push(einsatz);
    } else {
      einsatzMitPosition.push(einsatz);
    }
  });
  
  return (
    <div className="tab-pane">
      <TeamSelektion/>
      <div className={"spielfeld-context"}>
        <div className={"spielfeld"}>
          {einsatzOhnePosition.length > 0 &&
          <div className="unzugeordnete-spieler">
            {einsatzOhnePosition.map(einsatz => <Feldspieler key={einsatz.id} einsatz={einsatz}/>)}
          </div>
          }
          {einsatzMitPosition.map((einsatz) => <Spielposition key={einsatz.id} einsatz={einsatz}><Feldspieler einsatz={einsatz}/></Spielposition>)}
        </div>
        <Spielerbank spielerkarte={spielerkarte}/>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    navigation: state.navigation,
    einsatz: state.einsatz,
    spielerliste: state.spielerliste,
    spiel: state.spiel,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Aufstellung);
