import React, {useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../components/ModalerDialog";
import PersonBadge from "../../../components/PersonBadge";
import {rechnungUpdate} from "../state/modules/rechnungen";
import {BEXIO_STATUS_BEMERKUNGEN} from "../lib/constants";
import {formatiereBetrag} from "../../../lib/formatierungen";

const RechnungBemerkungenEdit = ({rechnung = null, onHide, bexio, locale, texte, rechnungUpdate}) => {
  const [bemerkungen, setBemerkungen] = useState(rechnung !== null ? rechnung.bemerkungen : null);
  
  const speichern = () => {
    if (darfBemerkungenBearbeiten(rechnung)) {
      rechnungUpdate(rechnung, bemerkungen);
    }
    schliessen();
  };

  const schliessen = () => {
    onHide();
  };

  const darfBemerkungenBearbeiten = (rechnung) => {
    if (bexio.aktiv) {
      if (rechnung) {
        // bexio aktiv -> bemerkungen nur anzeigen wenn rechnung freigegeben aber noch nicht vollständig bezahlt ist
        return BEXIO_STATUS_BEMERKUNGEN.includes(rechnung.bexioStatus);
      }
      return false;
    }
    // bexio inaktiv -> bemerkungen immer anzeigen
    return true;
  };

  return (
    <ModalerDialog>
      <div className="modal modal-wide">
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{texte.rechnungBearbeiten}</h3>
        </div>
        <div className="modal-body" style={{overflow: 'visible'}}>
          <table className="table attributes ">
            <tbody>
            <tr>
              <th>{texte.person}</th>
              <td>
                <PersonBadge person={rechnung.person} personVerlinken={false} showVcf={false} fotoTooltipAktiv={false}/>
              </td>
            </tr>
            <tr>
              <th>{texte.leistung}</th>
              <td>{rechnung.leistung.bezeichnung}</td>
            </tr>
            <tr>
              <th>{texte.betragFreiwillig}</th>
              <td>
                {rechnung.betragFreiwillig === true &&
                <i className="icon-ok"/>
                }
              </td>
            </tr>
            <tr>
              <th>{texte.betrag}</th>
              <td>{formatiereBetrag(rechnung.betrag, locale)}</td>
            </tr>
            {darfBemerkungenBearbeiten(rechnung) &&
            <tr>
              <th>{texte.bemerkungen}</th>
              <td><textarea rows={5} style={{width: '100%'}} value={bemerkungen || ''} onChange={ev => setBemerkungen(ev.currentTarget.value)}/></td>
            </tr>
            }
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" onClick={() => speichern()}>{texte.speichern}</button>
          <button type="button" className="btn" onClick={schliessen}>{texte.abbrechen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    bexio: state.bexio,
    locale: state.i18n.locale,
    texte: state.i18n.texte.rechnungen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rechnungUpdate: (rechnung, bemerkungen) => { dispatch(rechnungUpdate(rechnung, null, null, null, null, bemerkungen)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RechnungBemerkungenEdit);
