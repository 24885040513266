import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import PersonBadge from "../../../../components/PersonBadge";
import TeamBadge from "../../../../components/TeamBadge";
import {formatiereBetrag} from "../../../../lib/formatierungen";
import {spielerUpdate} from "../../state/modules/sponsorenlaufSpieler";
import {filtereSponsorenlauf} from "../../lib/datenHelper";
import Filter from "./Filter";
import Pager from "../../../../components/Pager";
import Tooltip from "../../../../components/Tooltip";

const SpielerResultate = ({spielerliste, teams, sponsoren, spielerkategorien, pageSizeArray, i18n, spielerUpdate}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [filterValues, setFilterValues] = useState(null);

  const texte = i18n.texte;
  const texteSpieler = texte.spieler;

  const [pageSize, setPageSize] = useState(999999);
  const [pageIndex, setPageIndex] = useState(1);

  const speichern = (spieler, anzahlRunden) => {
    if (spieler.anzahlRunden != anzahlRunden) {
      spielerUpdate(spieler.id, 'anzahlRunden', anzahlRunden)
    }
  };

  const alleTeamIds = useMemo(() => {
    return spielerliste.map(s => s.teamId).filter((id, index, self) => self.indexOf(id) === index);
  }, [spielerliste]);

  const filterReset = () => {
    setFilterValues(null);
    pageIndexChange();
  };

  const pageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    pageIndexChange();
  };

  const pageIndexChange = (index = 1) => {
    setPageIndex(index);
  };
  
  const gefilterteSpielerliste = useMemo(() => {
    return filtereSponsorenlauf(spielerliste, filterValues, spielerkategorien, sponsoren);
  }, [spielerliste, filterValues])

  const paginated = useMemo(() => {
    let startIndex = (pageIndex - 1) * pageSize;
    let endIndex = Math.min(pageIndex * pageSize, spielerliste.length);
    return gefilterteSpielerliste.slice(startIndex, endIndex);
  }, [pageIndex, pageSize, gefilterteSpielerliste]);
  
  return (
    <section>
      <div className="pull-right">
        {filterValues === null ||
          <>
            <Tooltip tagName="button" className="btn btn-small" content={texte.filter.loeschen} onClick={filterReset}>
              <i className="icon-remove"/>
            </Tooltip>
            &nbsp;
          </>
        }
        <button onClick={() => setShowFilter(!showFilter)} className="btn btn-small"><i className="icon-filter"/>{texte.filter.button}</button>
      </div>
      <h2 className="page-section">{texteSpieler.titel}</h2>
      {showFilter && 
      <Filter filterValues={filterValues} teamIds={alleTeamIds} onFilterChange={filter => setFilterValues(filter)}/>
      }
      <Pager pageIndex={pageIndex} pageSize={pageSize} totalItemCount={gefilterteSpielerliste.length} pageSizeArray={pageSizeArray} onPageChange={pageIndexChange} onPageSizeChange={pageSizeChange}/>
      <table className="table attributes table-condensed table-striped badge-compact">
        <thead>
        <tr>
          <th>{texteSpieler.spieler}</th>
          <th>{texteSpieler.team}</th>
          <th>{texteSpieler.pflichtbeitrag}</th>
          <th>{texte.anzahlRunden}</th>
          <th>{texte.betragPauschal}</th>
          <th>{texte.betragRunde}</th>
          <th>{texte.betragRechnung}</th>
        </tr>
        </thead>
        <tbody>
        {paginated.map((spieler, index) => {
          let team = teams.find(t => t.id === spieler.teamId);
          return <tr key={spieler.id}>
            <td><PersonBadge person={spieler.person} /></td>
            <td>{team && <TeamBadge team={team}/>}</td>
            <td>{formatiereBetrag(spieler.pflichtbeitrag, i18n.locale)}</td>
            <td><input type="number" style={{width: 40}} min={0} tabIndex={10 + index} defaultValue={spieler.anzahlRunden} onBlur={ev => speichern(spieler, ev.currentTarget.value)}/></td>
            <td className="text-right">{formatiereBetrag(spieler.betragPauschal, i18n.locale)}</td>
            <td className="text-right">{formatiereBetrag(spieler.betragRunde, i18n.locale)}</td>
            <td className={spieler.betragDifferenz > 0 ? 'text-right fehlbetrag' : 'text-right'}>{formatiereBetrag(spieler.betragDifferenz, i18n.locale)}</td>
          </tr>;
        })}
        </tbody>
      </table>
      <Pager pageIndex={pageIndex} pageSize={pageSize} totalItemCount={gefilterteSpielerliste.length} pageSizeArray={pageSizeArray} onPageChange={pageIndexChange} onPageSizeChange={pageSizeChange}/>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    spielerliste: state.spieler,
    teams: state.listen.teams,
    sponsoren: state.sponsoren,
    spielerkategorien: state.listen.spielerkategorien,
    pageSizeArray: state.listen.pager,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    spielerUpdate: (spieler, key, value) => { dispatch(spielerUpdate(spieler, key, value)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpielerResultate);
