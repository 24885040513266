// @flow

import type {Dispatch} from "../../state/actionTypes";
import {apiRequestAbschicken} from "../../state/modules/ajax";
import type {
  AppState,
  SpielerEintrag,
  SpielerkarteEintrag,
  TrainingSpielerEintrag,
  TurnierSpielerEintrag,
  SpielerTips,
  PrognoseFragen,
} from "../../state/stateTypes";

import React from "react";
import {connect} from "react-redux";

import {
  spielerLoeschNachfrageAnzeigen,
  spielerLoeschNachfrageVerstecken,
  spielerTipsLoeschen,
  spielerPrognoseFragenSetzen,
} from "../../state/modules/spieler";
import Tooltip from "../../../../components/Tooltip";

const LoeschButton = (props: {
  pfade: any,
  i18n: any,
  spielerLoeschNachfrageAnzeigen: (number) => void,
  spielerLoeschNachfrageVerstecken: (number) => void,
  spielerTipsLoeschen: (number, SpielerTips) => void,
  spielerPrognoseFragenSetzen: (number, PrognoseFragen) => void,
  feldAnApiSchicken: (string, string, string | number | {} | null, {}) => void,
  spieler: SpielerEintrag,
  spielerEinsatz: SpielerkarteEintrag | TurnierSpielerEintrag | TrainingSpielerEintrag,
  aktiv: boolean,
  requestDaten: any,
}) => {
  const tipsLoeschenMitServer = (spielerId: number, altesTips: SpielerTips) => {
    const tipsRequestDaten = {
      ...props.requestDaten,
      spielerId: props.spieler.id,
      spielerTips: {
        ...altesTips,
        t: null,
        i: null,
        p: null,
        s: null,
        entwicklung: null,
        note: null,
        kommentar: null,
        noteDurchschnitt: null,
        scout: null,
      },
      torhueterNoten: props.spieler.torhueterNoten.map((note) => {
        return {
          ...note,
          note: null,
          kommentar: null,
        };
      }),
    };
    props.feldAnApiSchicken(props.pfade.api.einsatz, 'tipsAlle', null, tipsRequestDaten);
    props.spielerTipsLoeschen(spielerId, altesTips);
    if (props.requestDaten.spielId) {
      // auch prognosefragen zurücksetzen
      const pf = {
        prognose: 0,
        frage1: {id: null, selektiert: false},
        frage2: {id: null, selektiert: false},
        frage3: {id: null, selektiert: false},
        frage4: {id: null, selektiert: false},
        frage5: {id: null, selektiert: false},
        frage6: {id: null, selektiert: false},
        prognoseAufgeklappt: false
      };
      props.feldAnApiSchicken(props.pfade.api.einsatz, 'prognoseFragen', pf, props.requestDaten);
      props.spielerPrognoseFragenSetzen(spielerId, pf);
    }
  };

  if (props.spieler.loeschNachfrageAnzeigen) {
    return (<>
      <div className="bootbox modal fade in" aria-hidden="false">
        <div className="modal-body" style={{textAlign: 'left'}}>{props.i18n.anderes.wollen_sie_loeschen}</div>
        <div className="modal-footer" style={{textAlign: 'right'}}>
          <a className="btn btn-primary"
             href="#"
             onClick={() => {
               if (props.aktiv) {
                 tipsLoeschenMitServer(props.spieler.id, props.spieler.tips);
               }
             }}>{props.i18n.buttons.ok}</a>
          <a data-handler="0" className="btn null" href="#" onClick={() => {
            if (props.aktiv) {
              props.spielerLoeschNachfrageVerstecken(props.spieler.id);
            }
          }}>{props.i18n.buttons.abbrechen}</a>
        </div>
      </div>
      <div className="modal-backdrop fade in" onClick={() => {
        if (props.aktiv) {
          props.spielerLoeschNachfrageVerstecken(props.spieler.id);
        }
      }}/>
      <span>
        <button disabled="disabled"
                name="button"
                type="button"
                className="btn btn-mini delete_wettkampfleistung"><i className="icon-trash"/></button>
      </span>
    </>);
  } else {
    return (<>
      <span>
        <Tooltip content={props.i18n.icons.loeschen}>
          <button type="button" disabled={!props.aktiv}
                  className="btn btn-mini delete_wettkampfleistung"
                  onClick={() => {
                    if (props.aktiv) {
                      props.spielerLoeschNachfrageAnzeigen(props.spieler.id);
                    }
                  }}>
            <i className="icon-trash"/>
          </button>
        </Tooltip>
      </span>
    </>);
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    pfade: state.pfade,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    spielerLoeschNachfrageAnzeigen: (spielerId: number) => {
      dispatch(spielerLoeschNachfrageAnzeigen(spielerId));
    },
    spielerLoeschNachfrageVerstecken: (spielerId: number) => {
      dispatch(spielerLoeschNachfrageVerstecken(spielerId));
    },
    spielerTipsLoeschen: (spielerId: number, altesTips: SpielerTips) => {
      dispatch(spielerTipsLoeschen(spielerId, altesTips));
    },
    spielerPrognoseFragenSetzen: (spielerId: number, prognoseFragen: PrognoseFragen) => {
      dispatch(spielerPrognoseFragenSetzen(spielerId, prognoseFragen));
    },
    feldAnApiSchicken: (apiUrl: string, feldName: string, neuerWert: string | number | {} | null, requestDaten: {}) => {
      dispatch(apiRequestAbschicken(apiUrl, 'PATCH', feldName, neuerWert, requestDaten, () => {
      }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoeschButton);
