import React from "react";

const IconToggle = ({istOffen, istBearbeitbar}) => {
  let css = "";
  if(istBearbeitbar) {
    css = "icon-edit";
  } else {
    if(istOffen) {
      css = "icon-chevron-up";
    } else {
      css = "icon-chevron-down";
    }
  }
  return <i className={css}/>;
};

export default IconToggle;
