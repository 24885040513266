// @flow

import type {AppState, EinsatzState, ProtokollState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import NeuerEintrag from "./NeuerEintrag";
import {protokollHatSpielverlaufTore} from "../../state/modules/protokoll";

const MoeglicheEintraege = (props: {
  einsatz: EinsatzState,
  protokoll: ProtokollState,
  i18n: any,
  pfade: any,
  position: "oben" | "unten",
}) => {
  if (!props.einsatz.abgeschlossen) {
    const toreAnzeigen = !protokollHatSpielverlaufTore(props.protokoll.liste);
    const style = {
      marginTop: '20px',
      marginBottom: '20px',
      border: '1px solid gray',
      padding: '10px',
      borderColor: '#ccc',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    };
    return (
      <div id="moegliche-eintraege" style={style}>
        <NeuerEintrag titel={props.i18n.eintraege.voll.spielfilm}
                      kurzerTitel={props.i18n.eintraege.kurz.spielfilm}
                      icon={props.pfade.icons.spielfilm}
                      art={"spielfilm"} aktion={"-"} istTor={false}
                      position={props.position}/>
        <NeuerEintrag titel={props.i18n.eintraege.voll.spielsystem}
                      kurzerTitel={props.i18n.eintraege.kurz.spielsystem}
                      icon={props.pfade.icons.spielsystem}
                      art={"spielsystem"} aktion={"-"} istTor={false}
                      position={props.position}/>
        {toreAnzeigen && <NeuerEintrag titel={props.i18n.eintraege.voll.tor}
                                       kurzerTitel={props.i18n.eintraege.kurz.tor}
                                       icon={props.pfade.icons.tor}
                                       art={"spielereignis"} aktion={"tor"} istTor={true}
                                       position={props.position}/>}
        <NeuerEintrag titel={props.i18n.eintraege.voll.assist}
                      kurzerTitel={props.i18n.eintraege.kurz.assist}
                      icon={props.pfade.icons.assist}
                      art={"spielereignis"} aktion={"assist"} istTor={false}
                      position={props.position}/>
        {toreAnzeigen && <NeuerEintrag titel={props.i18n.eintraege.voll.eigentor}
                                       kurzerTitel={props.i18n.eintraege.kurz.eigentor}
                                       icon={props.pfade.icons.eigentor}
                                       art={"spielereignis"} aktion={"eigentor"} istTor={true}
                                       position={props.position}/>}
        <NeuerEintrag titel={props.i18n.eintraege.voll.entstehung}
                      kurzerTitel={props.i18n.eintraege.kurz.entstehung}
                      icon={props.pfade.icons.entstehung}
                      art={"spielereignis"} aktion={"entstehung"} istTor={false}
                      position={props.position}/>
        <NeuerEintrag titel={props.i18n.eintraege.voll.offensiv}
                      kurzerTitel={props.i18n.eintraege.kurz.offensiv}
                      icon={props.pfade.icons.offensiv}
                      art={"spielereignis"} aktion={"offensiv"} istTor={false}
                      position={props.position}/>
        <NeuerEintrag titel={props.i18n.eintraege.voll.defensiv}
                      kurzerTitel={props.i18n.eintraege.kurz.defensiv}
                      icon={props.pfade.icons.defensiv}
                      art={"spielereignis"} aktion={"defensiv"} istTor={false}
                      position={props.position}/>
        <NeuerEintrag titel={props.i18n.eintraege.voll.beginn}
                      kurzerTitel={props.i18n.eintraege.kurz.beginn}
                      icon={props.pfade.icons.beginn}
                      art={"spielereignis"} aktion={"beginn"} istTor={false}
                      position={props.position}/>
        <NeuerEintrag titel={props.i18n.eintraege.voll.wechsel}
                      kurzerTitel={props.i18n.eintraege.kurz.wechsel}
                      icon={props.pfade.icons.wechsel}
                      art={"spielereignis"} aktion={"wechsel"} istTor={false}
                      position={props.position}/>
        <NeuerEintrag titel={props.i18n.eintraege.voll.standardsituation}
                      kurzerTitel={props.i18n.eintraege.kurz.standardsituation}
                      icon={props.pfade.icons.standardsituation}
                      art={"standardsituation"} aktion={"-"} istTor={false}
                      position={props.position}/>
      </div>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    protokoll: state.protokoll,
    i18n: state.i18n,
    pfade: state.pfade,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoeglicheEintraege);
