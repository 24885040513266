import React from "react";
import {connect} from "react-redux";

const SpielstatusLabel = ({spielstatusId, spielstatusListe}) => {
  const spielstatus = spielstatusListe.find(s => s.id === spielstatusId);

  if (spielstatus) {
    const cssNames = ["label", "label-umbruch"];
    switch(spielstatus.id) {
      case 2:
        cssNames.push("label-success");
        break;
      case 5:
        cssNames.push("label-important");
        break;
      case 3:
      case 4:
      case 6:
      case 9:
        cssNames.push("label-warning");
        break;
    }
    return <span className={cssNames.join(" ")}>{spielstatus.bezeichnung}</span>;
  } else {
    return <></>;
  }
};

const mapStateToProps = (state) => {
  return {
    spielstatusListe: state.spielstatusListe,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SpielstatusLabel);
