import React from "react";
import {connect} from "react-redux";
import {PERSON_GESCHLECHT} from "../../../../lib/constants";

export const DefaultFilter = {person: null, sponsor: null, sponsorVorhanden: null, spielerkategorie: null, betragVon: null, betragBis: null, geschlecht: null, team: null, ohneTeam: null, spielerGesperrt: null, abmeldenMarkiert: null};

const Filter = ({listen, filterValues, teamIds, fuerSponsor = false, texte, onFilterChange}) => {

  const filterChange = (feldname, wert) => {
    const filter = {...filterValues};
    if (wert === '') {
      filter[feldname] = null;
    } else {
      filter[feldname] = wert;
    }
    onFilterChange(filter);
  };
  
  if (!filterValues) {
    filterValues = DefaultFilter;
  }

  return (
    <>
      <br/>
      <table className="table attributes">
        <tbody>
        <tr>
          <th>{texte.spieler}</th>
          <td>
            <input type="text" value={filterValues.person || ''} onChange={ev => filterChange('person', ev.currentTarget.value)}/>
          </td>
          {fuerSponsor ? 
          <>
            <th>{texte.sponsor}</th>
            <td>
              <input type="text" value={filterValues.sponsor || ''} onChange={ev => filterChange('sponsor', ev.currentTarget.value)}/>
            </td>
          </> : 
          <>
            <th>{texte.sponsorVorhanden}</th>
            <td>
              <span className="btn-group">
                <button type="button" className={`btn btn-small${filterValues.sponsorVorhanden === true ? ' btn-success' : ''}`} onClick={() => filterChange('sponsorVorhanden', filterValues.sponsorVorhanden === true ? null : true)}>{texte.ja}</button>
                <button type="button" className={`btn btn-small${filterValues.sponsorVorhanden === false ? ' btn-success' : ''}`} onClick={() => filterChange('sponsorVorhanden', filterValues.sponsorVorhanden === false ? null : false)}>{texte.nein}</button>
              </span>
            </td>
          </>
          }
        </tr>
        <tr>
          <th>{texte.spielerkategorie}</th>
          <td>
            <select value={filterValues.spielerkategorie || ''} onChange={ev => filterChange('spielerkategorie', parseInt(ev.currentTarget.value, 10))}>
              <option value=""></option>
              {listen.spielerkategorien.map(kat => <option key={kat.id} value={kat.id}>{kat.bezeichnung}</option>)}
            </select>
          </td>
          <th>{fuerSponsor ? texte.betragTotal : texte.pflichtbeitrag} {texte.von} / {texte.bis}</th>
          <td className="text-nowrap">
            <input className="betrag" type="number" min={0} step={1} value={filterValues.betragVon || ''} onChange={ev => filterChange('betragVon', ev.currentTarget.value)}/>
            &nbsp;
            <input className="betrag" type="number" min={0} step={1} value={filterValues.betragBis || ''} onChange={ev => filterChange('betragBis', ev.currentTarget.value)}/>
          </td>
        </tr>
        <tr>
          <th>{texte.team}</th>
          <td>
            <select value={filterValues.team || ''} onChange={ev => filterChange('team', ev.currentTarget.value)}>
              <option value=""></option>
              {listen.teams.filter(team => teamIds.includes(team.id)).map(t => <option key={t.id} value={t.id}>{t.bezeichnung}</option>)}
            </select>
          </td>
          <th>{texte.ohneTeam}</th>
          <td>
            <span className="btn-group">
              <button type="button" className={`btn btn-small${filterValues.ohneTeam === true ? ' btn-success' : ''}`} onClick={() => filterChange('ohneTeam', filterValues.ohneTeam === true ? null : true)}>{texte.ja}</button>
              <button type="button" className={`btn btn-small${filterValues.ohneTeam === false ? ' btn-success' : ''}`} onClick={() => filterChange('ohneTeam', filterValues.ohneTeam === false ? null : false)}>{texte.nein}</button>
            </span>
          </td>
        </tr>
        <tr>
          <th>{texte.geschlecht}</th>
          <td>
            <span className="btn-group">
              <button type="button" className={`btn btn-small${filterValues.geschlecht === PERSON_GESCHLECHT.mann ? ' btn-success' : ''}`} onClick={() => filterChange('geschlecht', filterValues.geschlecht === PERSON_GESCHLECHT.mann ? null : PERSON_GESCHLECHT.mann)}>{texte.mann}</button>
              <button type="button" className={`btn btn-small${filterValues.geschlecht === PERSON_GESCHLECHT.frau ? ' btn-success' : ''}`} onClick={() => filterChange('geschlecht', filterValues.geschlecht === PERSON_GESCHLECHT.frau ? null : PERSON_GESCHLECHT.frau)}>{texte.frau}</button>
            </span>
          </td>
          <th>{texte.vereinsinternGesperrt}</th>
          <td>
            <span className="btn-group">
              <button type="button" className={`btn btn-small${filterValues.spielerGesperrt === true ? ' btn-success' : ''}`} onClick={() => filterChange('spielerGesperrt', filterValues.spielerGesperrt === true ? null : true)}>{texte.ja}</button>
              <button type="button" className={`btn btn-small${filterValues.spielerGesperrt === false ? ' btn-success' : ''}`} onClick={() => filterChange('spielerGesperrt', filterValues.spielerGesperrt === false ? null : false)}>{texte.nein}</button>
            </span>
          </td>
        </tr>
        <tr>
          <th>{texte.zumAbmeldenMarkiert}</th>
          <td>
            <span className="btn-group">
              <button type="button" className={`btn btn-small${filterValues.abmeldenMarkiert === true ? ' btn-success' : ''}`} onClick={() => filterChange('abmeldenMarkiert', filterValues.abmeldenMarkiert === true ? null : true)}>{texte.ja}</button>
              <button type="button" className={`btn btn-small${filterValues.abmeldenMarkiert === false ? ' btn-success' : ''}`} onClick={() => filterChange('abmeldenMarkiert', filterValues.abmeldenMarkiert === false ? null : false)}>{texte.nein}</button>
            </span>
          </td>
          <th></th>
          <td></td>
        </tr>
        </tbody>
      </table>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    listen: state.listen,
    texte: state.i18n.texte.filter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);

