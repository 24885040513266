import {executeRequest} from "../../lib/fetchHelper";
import {rechnungenVorschauEntfernen, rechnungenVorschauSetzen} from "./rechnungenVorschau";
import {rechnungenSetzen} from "./rechnungen";
import {bexioStatusAbfragen} from "./bexio";
import {ersetzeStringMitDatum, setzePageTitel} from "../../../../lib/datenhaltung";
import {RECHNUNGSLAUF_TYPEN} from "../../lib/constants";
import {endeRequest, requestTyp, startRequest} from "./laufendeAbfragen";
import {teamsLaden} from "./teams";

const RECHNUNGSLAUF_UPDATE = 'RECHNUNGSLAUF_UPDATE';

let abortControllerVorschau = null;

export function rechnungslaufVorschau(leistung, differenzRechnungslauf) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const daten = {
      konfigurationId: state.konfiguration.id,
      typ: state.rechnungslauf.typ,
    };
    if (leistung) {
      daten['leistungId'] = leistung.id;
    }
    if ([RECHNUNGSLAUF_TYPEN.rechnungenVerbaende, RECHNUNGSLAUF_TYPEN.sponsoren, RECHNUNGSLAUF_TYPEN.sponsorenlauf_spieler, RECHNUNGSLAUF_TYPEN.sponsorenlauf_sponsor].includes(state.rechnungslauf.typ)) {
      daten['idListe'] = state.konfiguration.idListe;
    }
    if (differenzRechnungslauf.length > 0) {
      daten['differenzRechnungslauf'] = differenzRechnungslauf;
    }
    if (abortControllerVorschau) {
      abortControllerVorschau.abort();
    }
    abortControllerVorschau = new AbortController();

    dispatch(startRequest(requestTyp.rechnungslauf));
    executeRequest(`${api.rechnungslauf}/0`, api.token, 'GET', daten, {signal: abortControllerVorschau.signal})
      .then(result => {
        if (result.ok) {
          dispatch(teamsLaden(result.data.rechnungenVorschau));
          dispatch({type: RECHNUNGSLAUF_UPDATE, rechnungslauf: result.data.rechnungslauf});
          dispatch(rechnungenVorschauSetzen(result.data.rechnungenVorschau));
        }
      })
      .catch(ex => {
        // noop, aber drin wegen AbortController.abort()
      })
      .finally(() => {
      abortControllerVorschau = null;
      dispatch(endeRequest(requestTyp.rechnungslauf));
    });
  };
}

export function rechnungslaufCreate(bezeichnung, rechnungstitel, gruppieren, leistung, bankkontoId, differenzRechnungslauf, rechnungstexte, ansprechpartnerId, rechnungsdatum, zahlbarBis) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const bexioAktiv = state.bexio.aktiv == true;
    const daten = {
      konfigurationId: state.konfiguration.id,
      typ: state.rechnungslauf.typ,
      bezeichnung: bezeichnung,
      rechnungstitel: rechnungstitel,
      gruppieren: gruppieren,
      rechnungsdatum: rechnungsdatum ? rechnungsdatum.toLocaleDateString('de-CH') : '',
      zahlbarBis: zahlbarBis ? zahlbarBis.toLocaleDateString('de-CH') : ''
    };
    
    if (bexioAktiv) {
      daten['bankkontoId'] = bankkontoId;
      daten['rechnungstexte'] = rechnungstexte;
    }
    if (leistung) {
      daten['leistungId'] = leistung.id;
    }
    if ([RECHNUNGSLAUF_TYPEN.rechnungenVerbaende, RECHNUNGSLAUF_TYPEN.sponsoren, RECHNUNGSLAUF_TYPEN.sponsorenlauf_spieler, RECHNUNGSLAUF_TYPEN.sponsorenlauf_sponsor].includes(state.rechnungslauf.typ)) {
      daten['idListe'] = state.konfiguration.idListe;
    }
    if (differenzRechnungslauf.length > 0) {
      daten['differenzRechnungslauf'] = differenzRechnungslauf;
    }
    if (ansprechpartnerId !== null) {
      daten['ansprechpartnerId'] = ansprechpartnerId;
    }
    dispatch(startRequest(requestTyp.rechnungslauf));
    executeRequest(api.rechnungslauf, api.token, 'POST', daten)
      .then(result => {
        dispatch({type: RECHNUNGSLAUF_UPDATE, rechnungslauf: result.data.rechnungslauf});
        if (result.data.rechnungslauf.generiert) {
          dispatch(rechnungenVorschauEntfernen());
          dispatch(rechnungenSetzen(result.data.rechnungen));

          if (bexioAktiv) {
            dispatch(bexioStatusAbfragen(true, false, false, false));
            dispatch(bexioStatusAbfragen(false, true, false, false));
          }

          const titel = state.i18n.texte.titelEdit;
          window.history.pushState({}, `${titel} - clubcorner.ch`, `${window.location.origin}${api.root}/${result.data.rechnungslauf.id}/edit`);
          setzePageTitel(titel);
        }
      }).finally(() => {
      dispatch(endeRequest(requestTyp.rechnungslauf));
    });
  };
}

export function rechnungslaufUpdate(bezeichnung, rechnungstitel, gruppieren, bankkontoId, rechnungstexte, ansprechpartnerId, rechnungsdatum, zahlbarBis) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const daten = {
      bezeichnung: bezeichnung,
      rechnungstitel: rechnungstitel,
      gruppieren: gruppieren,
      bankkontoId: bankkontoId,
      rechnungstexte: rechnungstexte,
      rechnungsdatum: rechnungsdatum ? rechnungsdatum.toLocaleDateString('de-CH') : '',
      zahlbarBis: zahlbarBis ? zahlbarBis.toLocaleDateString('de-CH') : ''
    };
    if (ansprechpartnerId !== null) {
      daten['ansprechpartnerId'] = ansprechpartnerId;
    }
    dispatch(startRequest(requestTyp.rechnungslauf));
    executeRequest(`${api.rechnungslauf}/${state.rechnungslauf.id}`, api.token, 'PUT', daten)
      .then(result => {
        if (result.ok) {
          dispatch({type: RECHNUNGSLAUF_UPDATE, rechnungslauf: result.data.rechnungslauf});
          dispatch(rechnungenSetzen(result.data.rechnungen));
        }
      }).finally(() => {
        dispatch(endeRequest(requestTyp.rechnungslauf));
    });
  };
}

export function rechnungslaufSetzen(rechnungslauf) {
  return (dispatch) => {
    dispatch({type: RECHNUNGSLAUF_UPDATE, rechnungslauf: rechnungslauf});
  };
}

function rechnungslaufReducer(state = {}, action) {
  switch (action.type) {
    case RECHNUNGSLAUF_UPDATE:
      return ersetzeStringMitDatum(action.rechnungslauf, ['rechnungsdatum', 'zahlbarBis']);
    default:
      return state;
  }
}

export default rechnungslaufReducer;
