// @flow

import type {AppState, NavigationState} from "../state/stateTypes";
import type {Dispatch} from "../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import {fehlerAnzeigeEntfernen} from "../state/modules/navigation";

const Fehlermeldungen = (props: {
  navigation: NavigationState,
  fehlerAnzeigeEntfernen: (string) => void,
}) => {
  const fehlermeldungen = props.navigation.aktuelleFehler.map((fehler) => {
    return <div className="alert fade in alert-error" key={fehler}>
      <a className="close" onClick={() => props.fehlerAnzeigeEntfernen(fehler)}>×</a>
      <span>{fehler}</span>
    </div>
  });

  return (
    <div>
      {fehlermeldungen.map((f) => f)}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    navigation: state.navigation,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fehlerAnzeigeEntfernen: (fehler: string) => {
      dispatch(fehlerAnzeigeEntfernen(fehler));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Fehlermeldungen);
