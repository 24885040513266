import React, {useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../components/ModalerDialog";
import {rechnungUpdate} from "../state/modules/rechnungen";
import LeistungAuswahl from "./LeistungAuswahl";
import PersonAuswahl from "./PersonAuswahl";
import {berechtigungFuer} from "../lib/berechtigungen";

const RechnungenEdit = ({rechnungen, onHide, rechnungslaufTyp, texte, rechnungUpdate}) => {
  const [person, setPerson] = useState(undefined);
  const [leistung, setLeistung] = useState(undefined);
  const [betragFreiwillig, setBetragFreiwillig] = useState(false);
  const [betrag, setBetrag] = useState('');

  const art = 'update';
  const editPerson = berechtigungFuer(rechnungslaufTyp, 'person');
  const editLeistung = berechtigungFuer(rechnungslaufTyp, 'leistung');
  const editBetragFreiwillig = berechtigungFuer(rechnungslaufTyp, 'betragFreiwillig');
  const editBetrag = berechtigungFuer(rechnungslaufTyp, 'betrag');

  const speichern = () => {
    const personId = person && editPerson && editPerson[art] ? person.id : null;
    const leistungId = leistung && editLeistung && editLeistung[art] ? leistung.id : null;
    const betragswert = editBetrag && editBetrag[art] ? betrag : null;
    rechnungen.forEach(rechnung => {
      rechnungUpdate(rechnung, personId, leistungId, betragFreiwillig, betragswert);
    });
    schliessen();
  };

  const schliessen = () => {
    onHide();
  };

  const selectLeistung = (l) => {
    if (betrag === '') {
      setBetrag(l.betrag);
    }
    setLeistung(l);
  };

  return (
    <ModalerDialog>
      <div className="modal modal-wide">
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{texte.rechnungenBearbeiten}</h3>
        </div>
        <div className="modal-body" style={{overflow: 'visible'}}>
          <table className="table attributes ">
            <tbody>
            {editPerson && editPerson[art] &&
            <tr>
              <th>{texte.person}</th>
              <td>
                {rechnungen.filter(r => r.bexioReferenzId > 0).length === 0
                  ? <PersonAuswahl value={person} onPersonChange={(p) => setPerson(p)} minChars={3}/>
                  : <div className="alert alert-info">{texte.rechnungenPersonEditNichtErlaubt}</div>
                }
              </td>
            </tr>
            }
            {editLeistung && editLeistung[art] &&
            <tr>
              <th>{texte.leistung}</th>
              <td>
                <LeistungAuswahl value={leistung} onLeistungChange={(l) => selectLeistung(l)} istEntfernbar={true}/>
              </td>
            </tr>
            }
            {editBetragFreiwillig && editBetragFreiwillig[art] &&
            <tr>
              <th>{texte.betragFreiwillig}</th>
              <td><input type="checkbox" checked={betragFreiwillig} onChange={ev => setBetragFreiwillig(ev.currentTarget.checked)}/></td>
            </tr>
            }
            {editBetrag && editBetrag[art] &&
            <tr>
              <th>{texte.betrag}</th>
              <td><input className="betrag" type="number" step="0.05" value={betrag} onChange={ev => setBetrag(ev.currentTarget.value)}/></td>
            </tr>
            }
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" onClick={() => speichern()}>{texte.speichern}</button>
          <button type="button" className="btn" onClick={schliessen}>{texte.abbrechen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    rechnungslaufTyp: state.rechnungslauf.typ,
    texte: state.i18n.texte.rechnungen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rechnungUpdate: (rechnung, personId, leistungId, betragFreiwillig, betrag) => { dispatch(rechnungUpdate(rechnung, personId, leistungId, betragFreiwillig, betrag, null)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RechnungenEdit);
