import React, {useEffect} from 'react';
import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

import reducer from './state/reducer';

import preloader from "./state/preloader";
import {ereignisseAbfragen} from "./state/modules/spielverlauf";
import {heartbeatAbfragen, heartbeatTimerStarten} from "./state/modules/heartbeat";
import {rollbarInitialisieren} from "../../reducers/rollbar";
import ErrorBoundary from "../../components/ErrorBoundary";
import SpielAnsicht from "./components/SpielAnsicht";
import Ticker from './components/Ticker'
import AnsichtBlocker from "./components/AnsichtBlocker";

const Tos = (props) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducer, preloader(props), composeEnhancers(applyMiddleware(thunk)));

  useEffect(() => {
    store.dispatch(rollbarInitialisieren());
    store.dispatch(heartbeatAbfragen(true));
    store.dispatch(heartbeatTimerStarten());
    store.dispatch(ereignisseAbfragen());
  }, []);

  return (
    <Provider store={store}>
      <ErrorBoundary fehlermeldung={'Es ist ein Fehler passiert... :/ Bitte laden Sie die Seite neu.'}>
        <SpielAnsicht/>
        <Ticker/>
        <AnsichtBlocker/>
      </ErrorBoundary>
    </Provider>
  );
};

export default Tos;
