import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../../components/ModalerDialog";
import {BEXIO_STATUS_BEMERKUNGEN} from "../../lib/constants";
import {rechnungenBemerkungenUpdate} from "../../state/modules/rechnungen";

const RechnungenBemerkungenModal = ({rechnungen, onHide, bexio, texte, rechnungenBemerkungenUpdate}) => {
  const [bemerkungen, setBemerkungen] = useState(rechnungen.length === 1 ? rechnungen[0].bemerkungen : undefined);
  const gefilterteRechnungen = useMemo(() => {
    if (bexio.aktiv) {
      // bexio aktiv -> bemerkungen nur für rechnungen anzeigen wenn rechnung freigegeben aber noch nicht vollständig bezahlt ist
      return rechnungen.filter(r => BEXIO_STATUS_BEMERKUNGEN.includes(r.bexioStatus));
    }
    return rechnungen;
  }, [rechnungen]);

  const speichern = () => {
    rechnungenBemerkungenUpdate(gefilterteRechnungen, bemerkungen);
    schliessen();
  };

  const schliessen = () => {
    onHide();
  };

  return (
    <ModalerDialog>
      <div className="modal modal-wide">
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{texte.rechnungBearbeiten}</h3>
        </div>
        <div className="modal-body" style={{overflow: 'visible'}}>
          <table className="table attributes ">
            <tbody>
            <tr>
              <th>{texte.person}</th>
              <td>
                <div className="label-liste">
                  {gefilterteRechnungen.map(r => <span key={r.id} className="label label-info">{r.person.name}</span>)}
                </div>
              </td>
            </tr>
            <tr>
              <th>{texte.bemerkungen}</th>
              <td><textarea rows={5} style={{width: '100%'}} value={bemerkungen} onChange={ev => setBemerkungen(ev.currentTarget.value)}/></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" onClick={() => speichern()}>{texte.speichern}</button>
          <button type="button" className="btn" onClick={schliessen}>{texte.abbrechen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    bexio: state.bexio,
    texte: state.i18n.texte.rechnungen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rechnungenBemerkungenUpdate: (rechnungen, bemerkungen) => { dispatch(rechnungenBemerkungenUpdate(rechnungen, bemerkungen)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RechnungenBemerkungenModal);
