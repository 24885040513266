import React from 'react'
import {EREIGNIS_SUBTYP, EREIGNIS_TYP} from "../lib/spielablauf";

export const ICON_TYP = {
  punkt: 'punkt',
  tor: 'tor',
  eigentor: 'eigentor',
  fehlschuss: 'fehlschuss',
  gelbeKarte: 'gelbeKarte',
  roteKarte: 'roteKarte',
  einAuswechsel: 'einAuswechsel',
  einwechsel: 'einwechsel',
  auswechsel: 'auswechsel',
  hatFilm: 'hatFilm',
};

const EreignisIcon = ({ereignis, ereignisTyp, className = null, ...props}) => {
  const css = ['ikon'];
  if (className) {
    css.push(className)
  }
  let typId = ereignisTyp;
  if (ereignis) {
    switch (ereignis.typ_id) {
      case EREIGNIS_TYP.tor:
        if (ereignis.subtyp_id === EREIGNIS_SUBTYP.eigentor) {
          typId = ICON_TYP.eigentor;
        } else {
          typId = ICON_TYP.tor;
        }
        break;
      case EREIGNIS_TYP.penaltyschiessen:
        if (ereignis.subtyp_id === EREIGNIS_SUBTYP.penaltyschiessen_kein_tor) {
          typId = ICON_TYP.fehlschuss;
        } else {
          typId = ICON_TYP.tor;
        }
        break;
      case EREIGNIS_TYP.gelbeKarte:
        typId = ICON_TYP.gelbeKarte;
        break;
      case EREIGNIS_TYP.roteKarte:
        typId = ICON_TYP.roteKarte;
        break;
      case EREIGNIS_TYP.auswechslung:
        typId = ICON_TYP.einAuswechsel;
        break;
    }
  }
  switch (typId) {
    case ICON_TYP.punkt:
      css.push('punkt');
      break;
    case ICON_TYP.tor:
      css.push('ikon-tor');
      break;
    case ICON_TYP.eigentor:
      css.push('ikon-eigentor');
      break;
    case ICON_TYP.fehlschuss:
      css.push('ikon-fehlschuss');
      break;
    case ICON_TYP.einAuswechsel:
      css.push('ikon-ein-auswechslung');
      break;
    case ICON_TYP.einwechsel:
      css.push('ikon-einwechslung');
      break;
    case ICON_TYP.auswechsel:
      css.push('ikon-auswechslung');
      break;
    case ICON_TYP.gelbeKarte:
      css.push('ikon-gelbe-karte');
      break;
    case ICON_TYP.roteKarte:
      css.push('ikon-rote-karte');
      break;
    case ICON_TYP.hatFilm:
      css.push('ikon-hat-film');
      break;
  }
  return (css === null ? <></> : <i className={css.join(' ')} {...props}/>);
};

export default EreignisIcon;
