import React from "react";
import useGlobal from "../../store";

const GameHeader = () => {
  const [globalState] = useGlobal();

  return (
    <div className="header">
      <h1>
        <b>{globalState.spielInfos.heimTeam.name}</b> &mdash; <b>{globalState.spielInfos.gastTeam.name}</b>
      </h1>
      <div className="subtitle">
        {globalState.spielInfos.date} {globalState.spielInfos.time}
      </div>
    </div>
  );
};

export default GameHeader;
