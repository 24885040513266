// @flow

import type {AjaxState, AppState, NavigationState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import NavigationLink from "./NavigationLink";

const Navigation = (props: {
  navigation: NavigationState,
  ajax: AjaxState,
  i18n: any,
  pfade: any,
}) => {
  const spinner = props.ajax.spinnerCount > 0 ? <div className="pull-right"><img src={props.pfade.icons.spinner} alt={props.i18n.anderes.lade}/></div> : '';

  return (
    <div>
      {spinner}
      <ul className="nav nav-tabs">
        {
          props.navigation.tabs.map((tab) => {
            return <NavigationLink titel={props.i18n.tabs[tab]} tabName={tab} key={tab}/>
          })
        }
      </ul>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    navigation: state.navigation,
    ajax: state.ajax,
    i18n: state.i18n,
    pfade: state.pfade,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation);
