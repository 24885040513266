import React from "react";
import {connect} from "react-redux";
import BibliothekDetail from "./BibliothekDetail";
import VideoPreview from "./VideoPreview";

const FreigebeneneAufgabe = ({aufgabe, dokumentPfad, texte}) => {
  return (
    <div className="aufgabe eintrag-box expand-width">
      <div className="header flex">
        <b>{aufgabe.bibliothek.titel}</b>
      </div>
      <BibliothekDetail aufgabe={aufgabe.bibliothek}/>
      <div className="zeile">
        {texte.kommentar}
        <div className="kommentar">{aufgabe.kommentar}</div>
      </div>
      {aufgabe.hatDokument &&
      <div className="zeile">
        <span className="datei-upload">
          {texte.file}:&nbsp;<a href={`${dokumentPfad}/${aufgabe.id}`} target="_blank" className={`ikon ${aufgabe.dokumentIcon}`}/>
        </span>
        {aufgabe.hatDokument && aufgabe.dokumentIcon === 'ikon-video' &&
        <VideoPreview videoPfad={`${dokumentPfad}/${aufgabe.id}`}/>
        }
      </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dokumentPfad: state.api.freigegebeneAufgaben,
    texte: state.i18n.texte
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FreigebeneneAufgabe);
