import React, {useState} from 'react'
import Spielzeit from './Spielzeit'
import {connect} from "react-redux";
import {spielAnzahlZuschauerSetzen} from "../state/modules/spielanzeige";
import {PERIODE} from "../lib/spielablauf";

const Header = ({spielanzeige, heartbeat, heimTeam, gastTeam, texte, api, anzahlZuschauerSetzen}) => {
  const [zuschauerEdit, setZuschauerEdit] = useState(false);

  const onZuschauerKeyDown = (event) => {
    switch (event.keyCode) {
      case 13: // enter
        speichereZuschauer(event.target.value);
        break;
      case 27: // esc
        setZuschauerEdit(false);
        break;
    }
  };

  const onZuschauerBlur = (event) => {
    speichereZuschauer(event.target.value);
  };

  const speichereZuschauer = (zuschauerString) => {
    const zuschauer = Number.parseInt(zuschauerString, 10);
    if (!isNaN(zuschauer) && zuschauer >= 0) {
      if (zuschauer != spielanzeige.zuschauer) {
        anzahlZuschauerSetzen(zuschauer);
      }
      setZuschauerEdit(false);
    }
  };

  return (
    <div className="header flex-column">
      <div className="flex-row">
        <span className="flex1"/>
        <span className="zuschauer">
          {`${spielanzeige.datum} - `}
          <b>
            {`${texte.zuschauer}: `}
            {zuschauerEdit === false &&
            <span onClick={() => setZuschauerEdit(true)}>{spielanzeige.zuschauer}</span>
            }
            {zuschauerEdit &&
            <input type="number" onKeyDown={onZuschauerKeyDown} onBlur={onZuschauerBlur} autoFocus={true} defaultValue={spielanzeige.zuschauer}/>
            }
          </b>
        </span>
        <span className="flex1"/>
      </div>
      <div className="spiel-anzeige flex-row">
        <span className="team flex1 flex-row">
          <span className="verein-logo">
            <img src={heimTeam.verein.logo}/>
          </span>
          <span className="punkte-anzeige">
            <span>{Math.floor(spielanzeige.heimTore / 10)}</span>
            <span>{spielanzeige.heimTore % 10}</span>
          </span>
          <span className="team-name">
            <a href={api.heimTeamEdit}>
              {heimTeam.name}
            </a>
          </span>
        </span>
        <span className="spielinfos">
          {heartbeat.spielzeit && heartbeat.spiel.periode != PERIODE.spiel_beendet && heartbeat.spiel.periode != PERIODE.spiel_abgebrochen &&
          <Spielzeit/>
          }
        </span>
        <span className="team rechts flex1 flex-row">
          <span className="team-name">
            <a href={api.gastTeamEdit}>
              {gastTeam.name}
            </a>
          </span>
          <span className="punkte-anzeige">
            <span>{Math.floor(spielanzeige.gastTore / 10)}</span>
            <span>{spielanzeige.gastTore % 10}</span>
          </span>
          <span className="verein-logo">
            <img src={gastTeam.verein.logo}/>
          </span>
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    spielanzeige: state.spielanzeige,
    heartbeat: state.heartbeat,
    heimTeam: state.heim.team,
    gastTeam: state.gast.team,
    texte: state.i18n.header,
    api: state.api,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    anzahlZuschauerSetzen: (zuschauer) => {
      dispatch(spielAnzahlZuschauerSetzen(zuschauer));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
