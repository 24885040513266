import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../components/ModalerDialog";
import {rechnungSpielerSperren} from "../state/modules/rechnungen";
import PersonBadge from "../../../components/PersonBadge";

const SpielerSperrenModal = ({rechnungen, onHide, texte, rechnungSpielerSperren}) => {
  const [bemerkungen, setBemerkungen] = useState('');
  const rechnungenMitSperre = useMemo(() => {
    return rechnungen.filter(r => r.spielerInfo && r.spielerInfo.gesperrt === true);
  }, [rechnungen]);
  const rechnungenOhneSperre = useMemo(() => {
    return rechnungen.filter(r => r.spielerInfo && r.spielerInfo.gesperrt === false);
  }, [rechnungen]);
  const personIdMitSperre = [];
  const personIdOhneSperre = [];

  const sperren = () => {
    if (bemerkungen == '') {
      return;
    }
    rechnungSpielerSperren(rechnungenOhneSperre, true, bemerkungen);
    if (rechnungenMitSperre.length === 0) {
      schliessen();
    }
  };

  const aufheben = () => {
    rechnungSpielerSperren(rechnungenMitSperre, false, bemerkungen);
    if (rechnungenOhneSperre.length === 0) {
      schliessen();
    }
  };

  const schliessen = () => {
    onHide();
  };

  return (
    <ModalerDialog>
      <div className="modal modal-wide">
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{texte.titel}</h3>
        </div>
        <div className="modal-body" style={{overflow: 'visible'}}>
          <table className="table attributes">
            <tbody>
            {rechnungenOhneSperre.length > 0 &&
              <>
                <tr>
                  <th>{texte.nichtGesperrteSpieler}</th>
                  <td>{rechnungenOhneSperre.map(r => {
                    if(!personIdOhneSperre.includes(r.person.id)) {
                      personIdOhneSperre.push(r.person.id);
                      return <span style={{display: 'inline-block'}}><PersonBadge key={r.id} person={r.person} personVerlinken={false} fotoTooltipAktiv={false} showVcf={false}/></span>;
                    }
                    return null;
                  })}</td>
                </tr>
                <tr>
                  <th>{texte.bemerkungen}</th>
                  <td><textarea rows={5} style={{width: '100%'}} value={bemerkungen} onChange={ev => setBemerkungen(ev.currentTarget.value)}/></td>
                </tr>
                <tr>
                  <th></th>
                  <td><button type="button" className="btn btn-danger" onClick={sperren}>{texte.spielerSperren}</button></td>
                </tr>
              </>
            }
            {rechnungenMitSperre.length > 0 &&
              <>
                <tr>
                  <th>{texte.gesperrteSpieler}</th>
                  <td>{rechnungenMitSperre.map(r => {
                    if(!personIdMitSperre.includes(r.person.id)) {
                      personIdMitSperre.push(r.person.id);
                      return <span key={r.id} style={{display: 'inline-block'}}><PersonBadge key={r.id} person={r.person} personVerlinken={false} fotoTooltipAktiv={false} showVcf={false}/></span>;
                    }
                    return null;
                  })}</td>
                </tr>
                <tr>
                  <th></th>
                  <td><button type="button" className="btn btn-success" onClick={aufheben}>{texte.sperrungAufloesen}</button></td>
                </tr>
              </>
            }
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn" onClick={schliessen}>{texte.abbrechen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    texte: state.i18n.texte.spielerSperren,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rechnungSpielerSperren: (rechnung, sperren, bemerkungen) => {
      dispatch(rechnungSpielerSperren(rechnung, sperren, bemerkungen));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpielerSperrenModal);
