const MARKIERER_EREIGNIS_START = 'MARKIERER_EREIGNIS_START';
const MARKIERER_EREIGNIS_STOP = 'MARKIERER_EREIGNIS_STOP';
const MARKIERER_EREIGNIS_CLEAR = 'MARKIERER_EREIGNIS_CLEAR';
const MARKIERER_FELDSPIELER_MENU_SHOW = 'MARKIERER_FELDSPIELER_MENU_SHOW';
const MARKIERER_FELDSPIELER_MENU_HIDE = 'MARKIERER_FELDSPIELER_MENU_HIDE';
const MARKIERER_STAFFBANK_MENU_SET = 'MARKIERER_STAFFBANK_MENU_SET';
const MARKIERER_SPIELZEIT_MENU_SET = 'MARKIERER_SPIELZEIT_MENU_SET';
const MARKIERER_CLOSE_ALL = 'MARKIERER_CLOSE_ALL';
const MARKIERER_TOGGLE_TICKER = 'MARKIERER_TOGGLE_TICKER';

function getSpielerId(spieler) {
  return `${spieler.unregistrierter_spieler ? 'u' : 'r'}-${spieler.id}`;
}

export function feldspielerMenuSichtbar(feldspieler, spieler) {
  return feldspieler.includes(getSpielerId(spieler))
}

export function ereignisEditStart(ereignisId) {
  return (dispatch) => {
    dispatch({type: MARKIERER_EREIGNIS_START, ereignisId: ereignisId});
  };
}
export function ereignisEditStop(ereignisId) {
  return (dispatch) => {
    dispatch({type: MARKIERER_EREIGNIS_STOP, ereignisId: ereignisId});
  };
}
export function ereignisEditClear() {
  return (dispatch) => {
    dispatch({type: MARKIERER_EREIGNIS_CLEAR});
  };
}
export function feldspielerMenuShow(spieler) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: MARKIERER_CLOSE_ALL});

    if (!state.spiel.ist_abgeschlossen) {
      const spielerId = getSpielerId(spieler);
      if (state.editMarkierer.feldspieler.includes(spielerId)) {
        dispatch({type: MARKIERER_FELDSPIELER_MENU_HIDE });
      } else {
        dispatch({type: MARKIERER_CLOSE_ALL});
        dispatch({type: MARKIERER_FELDSPIELER_MENU_SHOW, spielerId: spielerId});
      }
    }
  };
}
export function feldspielerMenuHide() {
  return (dispatch, getState) => {
    if (getState().editMarkierer.feldspieler.length > 0) {
      dispatch({type: MARKIERER_FELDSPIELER_MENU_HIDE });
    }
  };
}
export function staffbankSetMenu(data = null) {
  return (dispatch) => {
    dispatch({type: MARKIERER_CLOSE_ALL});
    dispatch({type: MARKIERER_STAFFBANK_MENU_SET, menu: data});
  };
}
export function spielzeitSetMenu(showMenu) {
  return (dispatch) => {
    dispatch({type: MARKIERER_CLOSE_ALL});
    dispatch({type: MARKIERER_SPIELZEIT_MENU_SET, showMenu: showMenu});
  };
}
export function schliesseAlleMenus() {
  return (dispatch) => {
    dispatch({type: MARKIERER_CLOSE_ALL});
  }
}
export function toggleTickerAnzeige(anzeigen) {
  return (dispatch) => {
    dispatch({type: MARKIERER_TOGGLE_TICKER, anzeigen: anzeigen});
  }
}

function editMarkiererReducer(state = {}, action) {
  switch (action.type) {
    case MARKIERER_EREIGNIS_START:
      return {
        ...state,
        spielverlauf: [
          ...state.spielverlauf,
          action.ereignisId
        ]
      };
    case MARKIERER_EREIGNIS_STOP:
      return {
        ...state,
        spielverlauf: state.spielverlauf.filter(id => id != action.ereignisId)
      };
    case MARKIERER_EREIGNIS_CLEAR:
      return {
        ...state,
        spielverlauf: []
      };
    case MARKIERER_FELDSPIELER_MENU_SHOW:
      return {
        ...state,
        feldspieler: [action.spielerId]
      };
    case MARKIERER_FELDSPIELER_MENU_HIDE:
      return {
        ...state,
        feldspieler: []
      };
    case MARKIERER_STAFFBANK_MENU_SET:
      return {
        ...state,
        staffbank: action.menu
      };
    case MARKIERER_SPIELZEIT_MENU_SET:
      return {
        ...state,
        spielzeit: action.showMenu
      };
    case MARKIERER_CLOSE_ALL:
      return {
        ...state,
        feldspieler: [],
        spielzeit: false,
        staffbank: null,
      };
    case MARKIERER_TOGGLE_TICKER:
      return {
        ...state,
        tickerAnzeigen: action.anzeigen
      };
    default:
      return state;
  }
}

export default editMarkiererReducer;
