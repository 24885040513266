import React, {useEffect, useMemo, useRef, useState} from "react";
import {
  BigPlayButton,
  ControlBar,
  ForwardControl,
  PlaybackRateMenuButton,
  Player,
  ReplayControl,
  Shortcut
} from "video-react";

const VideoPlayer = ({src, ...props}) => {
  const [zoom, setZoom] = useState(1);
  const [data, setData] = useState(null);
  const [istMobile, setIstMobile] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [startPunkt, setStartPunkt] = useState({x: 0, y: 0});
  const [koordinate, setKoordinate] = useState({x: 0, y: 0});
  const [fixPunkt, setFixPunkt] = useState({x: 0, y: 0});
  const [grenzwert, setGrenzwert] = useState({max: 0, min: 0});
  const videoContainer = useRef(null);
  const player = useRef(null);

  const resizeObserver = new ResizeObserver(() => {
    containerInfoHandler();
  });

  useEffect(() => {
    player.current.subscribeToStateChange(state => setIsFullScreen(state.isFullscreen));
    return () => {
      resizeObserver.disconnect();
    }
  }, []);

  useEffect(() => {
    if (zoom === 1 || isFullScreen) {
      setVideoScaleTranslate(1, 0, 0);
    }
  }, [zoom, isFullScreen]);

  const faktor = useMemo(() => {
    return (zoom - 1) / zoom;
  }, [zoom]);

  const containerInfoHandler = () => {
    if (videoContainer.current) {
      let container = videoContainer.current.offsetParent;
      setData({
        width: container.clientWidth,
        height: container.clientHeight,
        offsetLeft: container.offsetLeft + container.offsetParent.offsetLeft,
        offsetTop: container.offsetTop + container.offsetParent.offsetTop,
      });
    }
    return null;
  };

  const zoomChangeHandler = (ev) => {
    let val = parseInt(ev.currentTarget.value, 10);
    setZoom(val);
    setFixPunkt({x: 0, y: 0});
    setVideoScaleTranslate(val, 0, 0);
  };

  function berechneKoordinaten(e) {
    const x = (((e.pageX - data.offsetLeft + 1) - data.width / 2) / data.width * 100) * faktor;
    const y = (((e.pageY - data.offsetTop + 1) - data.height / 2) / data.height * 100) * faktor;
    return {x, y};
  }

  const setVideoScaleTranslate = (scale, x, y) => {
    player.current.video.video.style = `transform: scale(${scale}) translate(${x}%, ${y}%)`;
  };

  const mousemove = (e) => {
    if (!istMobile) {
      resizeObserver.observe(document.querySelector('.collapse'))
      if (isFullScreen) {
        return;
      }
      if (data) {
        let {x, y} = berechneKoordinaten(e);
        setVideoScaleTranslate(zoom, x * -1, y * -1);
      }
    }
  };

// Mobile (Touch)
  const touchstart = (e) => {
    if (isFullScreen) {
      return;
    }
    if (data) {
      if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)) {

        let cursor = berechneKoordinaten(e.changedTouches[0]);
        setStartPunkt({
          x: cursor.x,
          y: cursor.y,
        });
        setGrenzwert({
          max: (faktor / 2) * 100,
          min: (faktor / 2) * -100,
        });
        setIstMobile(true);
        document.body.style.overflow = 'hidden';
      }
    }
  };

  const touchmove = (e) => {
    if (isFullScreen || !istMobile) {
      return;
    }

    let cursor = berechneKoordinaten(e.changedTouches[0]);
    let diffX = checkGrenzwert((startPunkt.x - cursor.x) * -1);
    let diffY = checkGrenzwert((startPunkt.y - cursor.y) * -1);
    let x = checkGrenzwert(fixPunkt.x + diffX);
    let y = checkGrenzwert(fixPunkt.y + diffY);
    setKoordinate({
      x: x,
      y: y,
    });
    setVideoScaleTranslate(zoom, x, y);
  };

  const touchend = () => {
    if (isFullScreen) {
      return;
    }
    setFixPunkt({
      x: koordinate.x,
      y: koordinate.y,
    });
    document.body.style.removeProperty('overflow');
  };

  const checkGrenzwert = (wert) => {
    if (wert >= grenzwert.max) {
      return grenzwert.max;
    }
    if (wert <= grenzwert.min) {
      return grenzwert.min
    }
    return wert;
  };

  return (
    <>
      <div ref={videoContainer} onMouseMove={mousemove} onTouchStart={touchstart} onTouchMove={touchmove} onTouchEnd={touchend} style={{overflow: 'hidden'}}>
        <Player ref={player} {...props} playsInline>
          <Shortcut clickable={!istMobile || isFullScreen}/>
          <source src={src} type="video/mp4"/>
          <ControlBar>
            <PlaybackRateMenuButton rates={[2, 1.5, 1, 0.5, 0.3, 0.1]} order={2.1}/>
            <ReplayControl seconds={10} order={2.2}/>
            <ForwardControl seconds={10} order={2.3}/>
          </ControlBar>
          <BigPlayButton position="center"/>
        </Player>
      </div>
      <div style={{paddingTop: '1em'}}>
        <label>Videozoom: <b>{zoom}x</b></label>
        <input className="form-range slider" type="range" max="7" min="1" step="1" value={zoom} onChange={zoomChangeHandler} style={{boxShadow: 'none'}}/>
      </div>
    </>
  );
};

export default VideoPlayer;
