// @flow

import type {AppState, ProtokollEintrag} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";
import {protokollVideoAnzeigen, protokollVideoVerstecken} from "../../state/modules/protokoll";

const VideoAnzeige = (props: {
  i18n: any,
  eintrag: ProtokollEintrag,
  protokollVideoAnzeigen: (string) => void,
  protokollVideoVerstecken: (string) => void,
}) => {
  if (props.eintrag.hatVideo) {
    return <>
      <span onClick={() => props.protokollVideoAnzeigen(props.eintrag.key)} style={{cursor: "pointer"}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
          <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"/>
        </svg>
      </span>
      <div
        className={props.eintrag.videoAnzeigen ? "blur flex-column" : "hidden"}
        onClick={() => props.protokollVideoVerstecken(props.eintrag.key)}
        onBlur={() => props.protokollVideoVerstecken(props.eintrag.key)}
      >
        <div className="overlay-wrapper">
          <div className="overlay-sequence" onClick={e => e.stopPropagation()}>
            <button className="close-overlay icon-button" onClick={() => props.protokollVideoVerstecken(props.eintrag.key)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 18 18" style={{paddingTop: "8px"}}>
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/>
              </svg>
            </button>
            <video className="overlay-video" controls onEnded={() => props.protokollVideoVerstecken(props.eintrag.key)}>
              <source src={props.eintrag.videoPfad || ""} type="video/mp4"/>
              {props.i18n.anderes.browser_unterstuetzt_nicht_html5_video}
            </video>
          </div>
        </div>
      </div>
    </>;
  } else {
    return <></>;
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    protokollVideoAnzeigen: (eintragKey: string) => {
      dispatch(protokollVideoAnzeigen(eintragKey));
    },
    protokollVideoVerstecken: (eintragKey: string) => {
      dispatch(protokollVideoVerstecken(eintragKey));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoAnzeige);
