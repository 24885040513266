import React, {useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../../components/ModalerDialog";
import {executeJson} from "../../../../lib/fetchHelper";

const SpielerFisNachrichtModal = ({spielerliste, sponsorenlauf, spieler, texte, api, onModalClose}) => {
  const [betreff, setBetreff] = useState('');
  const [nachricht, setNachricht] = useState('');
  const [fehler, setFehler] = useState([]);
  const texteFisNachricht = texte.fisNachricht;

  const sendeNachricht = () => {
    const daten = {
      sponsorenlaufId: sponsorenlauf.id,
      typ: 'fis',
      spielerIds: spieler.filter(s => spielerliste.includes(s.id)).map(s => s.spielerId),
      fis: {
        betreff: betreff,
        nachricht: nachricht
      }
    };
    
    executeJson(api.nachrichten, api.token, 'POST', daten)
      .then(result => {
        if (result.ok) {
          onModalClose();
        } else {
          setFehler(result.data.errors);
        }
      });
  };
  
  return (
    <ModalerDialog>
      <div className="modal modal-wide">
        <div className="modal-header">
          <button type="button" className="close" onClick={onModalClose}>&times;</button>
          <h3>{texteFisNachricht.titel}</h3>
        </div>
        <div className="modal-body">
          <div className="form-horizontal">
            <div className={`control-group ${fehler['betreff'] ? 'error' : ''}`} style={{marginBottom: 10}}>
              <label className="control-label">{texteFisNachricht.betreff}</label>
              <div className="controls">
                <input type="text" onChange={(ev) => setBetreff(ev.currentTarget.value)} value={betreff} style={{width: '97%'}}/>
                {fehler['betreff']}
              </div>
            </div>
            <br/>
            <div className={`control-group ${fehler['nachricht'] ? 'error' : ''}`} style={{marginBottom: 10}}>
              <label className="control-label">{texteFisNachricht.nachricht}</label>
              <div className="controls">
                <textarea onChange={(ev) => setNachricht(ev.currentTarget.value)} value={nachricht} rows={5} style={{width: '97%'}}/>
                {fehler['nachricht']}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn" onClick={onModalClose}>{texte.abbrechen}</button>
          &nbsp;
          <button type="button" className="btn btn-primary" onClick={sendeNachricht}>{texteFisNachricht.senden}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    sponsorenlauf: state.sponsorenlauf,
    spieler: state.spieler,
    texte: state.i18n.texte,
    api: state.api,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SpielerFisNachrichtModal);
