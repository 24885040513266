// @flow

import type {AppState, EinsatzState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

import TextfeldZeile from "../TextfeldZeile";

import {gesamtKommentarSetzen, einsatzBemerkungenSetzen} from "../../state/modules/einsatz";

const Gesamtnoten = (props: {
    einsatz: EinsatzState,
    i18n: any,
    gesamtKommentarSetzen: ((string) => void),
    einsatzBemerkungenSetzen: ((string) => void),
  }) => {
    return (
      <table className="table table-striped">
        <tbody>
        <TextfeldZeile feldTitel={props.i18n.felder.gesamtKommentar}
                       feldName="gesamtKommentar"
                       feldWert={props.einsatz.gesamtKommentar}
                       onChange={(event) => {
                         props.gesamtKommentarSetzen(event.target.value);
                       }}/>
        <TextfeldZeile feldTitel={props.i18n.felder.einsatzBemerkungen}
                       feldName="einsatzBemerkungen"
                       feldWert={props.einsatz.einsatzBemerkungen}
                       onChange={(event) => {
                         props.einsatzBemerkungenSetzen(event.target.value);
                       }}/>
        </tbody>
      </table>
    );
  }
;

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    gesamtKommentarSetzen: (neuerWert: string) => {
      dispatch(gesamtKommentarSetzen(neuerWert));
    },
    einsatzBemerkungenSetzen: (neuerWert: string) => {
      dispatch(einsatzBemerkungenSetzen(neuerWert));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Gesamtnoten);
