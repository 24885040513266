import React, {useState, useEffect} from 'react'
import {connect} from "react-redux";
import {spielerEreignisErstellen} from "../state/modules/spielverlauf";
import {EREIGNIS_SUBTYP, EREIGNIS_TYP} from "../lib/spielablauf";
import {feldspielerMenuHide, feldspielerMenuSichtbar} from "../state/modules/editMarkierer";

const SpielerMenu = ({spieler, teamSpielerkarte, ereignisse, spiel, tore_nicht_erfassen, spielverlauf, heimTeamId, feldspielerMenu, texte, ereignisErstellen, feldspielerMenuHide}) => {
  const [selectedEreignis, setSelectedEreignis] = useState(null);
  const [letztesZwischenresultat, setLetztesZwischenresultat] = useState(spiel.zwischenresultat);
  const menuSichtbar = feldspielerMenuSichtbar(feldspielerMenu, spieler);
  
  useEffect(() => {
    if(menuSichtbar === true) {
      setLetztesZwischenresultat(spiel.zwischenresultat);
    }
    if(menuSichtbar === false) {
      setSelectedEreignis(null);
    }
  }, [menuSichtbar]);

  const onEreignisClick = (event, ereignis) => {
    event.stopPropagation();

    if (selectedEreignis === ereignis.id) {
      setSelectedEreignis(null);
    } else {
      setSelectedEreignis(ereignis.id);
    }
  };

  const onSubaktionClick = (ereignisTyp, ereignisSubtyp, zusatzdaten = {}) => {
    ereignisErstellen(spieler, ereignisTyp, ereignisSubtyp, teamSpielerkarte.team, zusatzdaten);
    feldspielerMenuHide();
  };

  const onAuswechlsungClick = (ereignisTyp, ereignisSubtyp, auswechselSpielerId, auswechselUnregistrierterSpieler) => {
    onSubaktionClick(ereignisTyp, ereignisSubtyp, {auswechsel_spieler_id: auswechselSpielerId, auswechsel_unregistrierter_spieler: auswechselUnregistrierterSpieler});
  };

  const onAssistClick = (ereignisTyp, ereignisSubtyp, torZum) => {
    onSubaktionClick(ereignisTyp, ereignisSubtyp, {zwischenresultat: torZum});
  };
  
  const onPenaltyClick = (ereignisTyp, ereignisSubtyp) => {
    const zusatzdaten = ereignisSubtyp.id === EREIGNIS_SUBTYP.penaltyschiessen_tor ? {zwischenresultat: erstelleNaechstesZwischenresultat()} : {};
    ereignisErstellen(spieler, ereignisTyp, ereignisSubtyp, teamSpielerkarte.team, zusatzdaten);
    feldspielerMenuHide();
  };
  
  const ereignisTypTitel = (ereignisTyp) => {
    if (ereignisTyp.id === EREIGNIS_TYP.tor) {
      return `${ereignisTyp.title} (${texte.tor_zum} ${erstelleNaechstesZwischenresultat()})`;
    } else {
      return ereignisTyp.title;
    }
  };
  
  const ereignisSubtypTitel = (ereignisSubtyp) => {
    if (ereignisSubtyp.id === EREIGNIS_SUBTYP.eigentor) {
      return `${ereignisSubtyp.title} (${texte.tor_zum} ${erstelleNaechstesZwischenresultat(ereignisSubtyp)})`;
    } else {
      return ereignisSubtyp.title;
    }
  };
  
  const erstelleNaechstesZwischenresultat = (ereignisSubtyp = null) => {
    let resultat = [letztesZwischenresultat.heim, letztesZwischenresultat.gast];
    if (ereignisSubtyp && ereignisSubtyp.id === EREIGNIS_SUBTYP.eigentor) {
      teamSpielerkarte.team.id === heimTeamId ? resultat[1]++ : resultat[0]++;
    } else {
      teamSpielerkarte.team.id === heimTeamId ? resultat[0]++ : resultat[1]++;
    }
    return resultat.join(':');
  };
  
  if (menuSichtbar === true) {
    return (
      <ul className="menu ereignisMenu">
        {
          ereignisse.map((ereignisTyp) => {
            // HACK: solange MHA nicht live-api benutzt, darf in tos bei mha-aktiv kein tor erstellt werden
            if (tore_nicht_erfassen && ereignisTyp.id === EREIGNIS_TYP.tor) {
              return <li key={ereignisTyp.id} className="not-allowed">{ereignisTypTitel(ereignisTyp)}</li>;
            }

            let subactions = null;
            let auswechselspieler = null;
            let assistTore = null;
            let penaltyschiessen = null;
            const zeigeSubmenu = selectedEreignis === ereignisTyp.id;
            if (zeigeSubmenu) {
              switch (ereignisTyp.id) {
                case EREIGNIS_TYP.auswechslung:
                  auswechselspieler = teamSpielerkarte.spieler.filter(s => teamSpielerkarte.zustand.ersatzspieler.find(es => es.id === s.id && es.unregistriert === s.unregistrierter_spieler) != undefined);
                  break;
                case EREIGNIS_TYP.assist:
                  assistTore = spielverlauf.filter(e => e.typ_id === EREIGNIS_TYP.tor && e.team_id == teamSpielerkarte.team.id).map(e => e.zwischenresultat);
                  break;
                case EREIGNIS_TYP.penaltyschiessen:
                  penaltyschiessen = ereignisTyp.subactions;
                  break;
                default:
                  subactions = ereignisTyp.subactions;
                  break;
              }
            }
  
            return (
              <li key={ereignisTyp.id} onClick={(e) => onEreignisClick(e, ereignisTyp)} className={zeigeSubmenu ? 'active' : ''}>
                {ereignisTypTitel(ereignisTyp)}
                {zeigeSubmenu &&
                <ul className="menu ereignisSubmenu">
                  {auswechselspieler && 
                  auswechselspieler.map((auswechsel) => <li key={auswechsel.id} onClick={() => onAuswechlsungClick(ereignisTyp, ereignisTyp.subactions[0], auswechsel.id, auswechsel.unregistrierter_spieler)}>{auswechsel.rueckennummer} - {auswechsel.name}</li>)
                  }
                  {assistTore && 
                  assistTore.map((torZum) => <li key={torZum} onClick={() => onAssistClick(ereignisTyp, ereignisTyp.subactions[0], torZum)}>{torZum}</li>)
                  }
                  {penaltyschiessen &&
                  penaltyschiessen.map((ereignisSubtyp) => <li key={ereignisSubtyp.id} onClick={() => onPenaltyClick(ereignisTyp, ereignisSubtyp)}>{ereignisSubtyp.title}</li>)
                  }
                  {subactions && 
                  subactions.map((ereignisSubtyp) => {
                    let zusatzdaten = selectedEreignis === EREIGNIS_TYP.tor ? { zwischenresultat: erstelleNaechstesZwischenresultat(ereignisSubtyp) } : {};
                    return <li key={ereignisSubtyp.id} onClick={() => onSubaktionClick(ereignisTyp, ereignisSubtyp, zusatzdaten)}>{ereignisSubtypTitel(ereignisSubtyp)}</li>;
                  })
                  }
                </ul>
                }
              </li>
            );
          })
        }
      </ul>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state) => {
  return {
    ereignisse: state.heartbeat.spiel.periode === 'penaltyschiessen' ? state.spielfeld.ereignisse.penalty : state.spielfeld.ereignisse.ingame,
    spiel: state.heartbeat.spiel,
    tore_nicht_erfassen: state.heartbeat.highlight.uebernommen,
    spielverlauf: state.spielverlauf,
    heimTeamId: state.heim.team.id,
    feldspielerMenu: state.editMarkierer.feldspieler,
    texte: state.i18n.spieler_menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ereignisErstellen: (spieler, ereignisTyp, ereignisSubtyp, team, zusatzdaten) => { dispatch(spielerEreignisErstellen(spieler, ereignisTyp, ereignisSubtyp, team, zusatzdaten))},
    feldspielerMenuHide: () => { dispatch(feldspielerMenuHide()); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpielerMenu);
