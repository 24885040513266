import React from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/src/stylesheets/datepicker.scss'
import {registerLocale} from "react-datepicker";
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';

registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('it', it);

// kein datum grösser zulassen -> das ist der maximale wert von smalldatetime im sql-server (und grösser macht nicht viel sinn, da über 50 jahre in zukunft)
const maxDateDefault = new Date('2079-06-06');
const minDateDefault = new Date('1900-01-01');

const DatumPicker = ({value, locale, onChange, showTimeSelect = false, minDate = minDateDefault, maxDate = maxDateDefault, className = '', ...props}) => {
  const classNames = ["date", className];
  let dateFormat = "dd.MM.yyyy";
  if (showTimeSelect) {
    classNames.push('time');
    dateFormat += " HH:mm";
  }

  return <DatePicker
    selected={value}
    onChange={onChange}
    showTimeSelect={showTimeSelect}
    locale={locale}
    dateFormat={dateFormat}
    timeFormat={'HH:mm'}
    minDate={minDate}
    maxDate={maxDate}
    shouldCloseOnSelect={true}
    className={classNames.join(" ")}
    {...props}
  />
};

export default DatumPicker;
