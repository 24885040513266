import fetch from "cross-fetch";

// ************************************************************************************
// Vereinhetilichte Version aller fetch-Helpers.
// 
// Request: 
// Es gibt drei arten von requests: 
// - Pur GET
// - json POST / PUT / DELETE
// - Multipart-Form POST / PUT / DELETE
// 
// Response: erwartet immer eine JSON Antwort.
// 
// TODO: alle bisherigen versionen ausbauen und mit dieser version hier ersetzen.
// ************************************************************************************

export async function executeGet(url, daten = null) {
  const fetchInit = machFetchInit('GET');
  fetchInit.headers = {'content-type': 'application/json'};

  let apiUrl = url;
  if (daten) {
    const params = new URLSearchParams();
    appendObjectToRequest(params, daten);
    apiUrl = url + "?" + params.toString();
  }

  return startFetch(fetchInit, apiUrl);
}

export async function executeJson(url, token, method, daten = {}) {
  daten['authenticity_token'] = token;

  const fetchInit = machFetchInit(method);
  fetchInit.headers = {'content-type': 'application/json'};
  fetchInit.body = JSON.stringify(daten);

  return startFetch(fetchInit, url);
}

export async function executeFormData(url, token, method, daten) {
  const fetchInit = machFetchInit(method);

  const form = new FormData();
  appendObjectToRequest(form, daten);
  form.append('authenticity_token', token);
  fetchInit.body = form;

  return startFetch(fetchInit, url);
}

function startFetch(fetchInit, url) {
  return fetch(url, fetchInit)
    .then(response => {
      return response.json().then(jsonData => ({data: jsonData, ok: response.ok, status: response.status}));
    });
}

function machFetchInit(method) {
  return {
    method: method,
    mode: 'same-origin',
    credentials: 'same-origin',
  }
}

function appendObjectToRequest(formData, daten, prefix = '') {
  for (let propName in daten) {
    if (daten.hasOwnProperty(propName)) {
      const val = daten[propName];
      let name = `${prefix}${propName}`;
      if (val instanceof Array) {
        fillArray(formData, val, name);
      } else if (val instanceof Date) { // fällt sonst unter "Object"
        formData.append(name, JSON.stringify(val));
      } else if (val instanceof File) { // fällt sonst unter "Object"
        formData.append(name, val);
      } else if (val instanceof Object) {
        appendObjectToRequest(formData, val, `${prefix}[${propName}]`);
      } else {
        formData.append(name, val);
      }
    }
  }
}

function fillArray(form, array, prefix = '') {
  array.forEach((element, _index) => {
    if (element instanceof Array || element instanceof Object) {
      appendObjectToRequest(form, element, `${prefix}[]`);
    } else {
      form.append(`${prefix}[]`, element);
    }
  });
}
