import {rechnungSortieren} from "./modules/rechnungen";
import {ersetzeStringMitDatum} from "../../../lib/datenhaltung";

function preloaderRechnungslauf(options) {
  return {
    rechnungslauf: ersetzeStringMitDatum(options.rechnungslauf, ['rechnungsdatum', 'zahlbarBis']),
    konfiguration: options.konfiguration,
    rechnungen: options.rechnungen.sort(rechnungSortieren),
    rechnungenVorschau: options.rechnungenVorschau.sort(rechnungSortieren),
    teams: options.teams,
    typen: options.typen,
    darf: options.darf,
    bexio: options.bexio,
    laufendeRequests: {},
    api: options.api,
    i18n: options.i18n,
    rollbar: options.rollbar,
  };
}

export default preloaderRechnungslauf;
