import React, {createRef, useState} from "react";
import {connect} from "react-redux";

const TeilnehmerAbschlussForm = ({ausbildner, ergebnis, ergebnisUpdate, ausbildung, dokumentPfad, texte}) => {
  const fileInput = createRef();
  const [fileName, setFileName] = useState(undefined);
  const bearbeitbar = ausbildung.bearbeitbar && !ergebnis.beurteilungAbgeschlossen;

  const onFileChange = () => {
    if (fileInput.current.files.length > 0) {
      setFileName(fileInput.current.files[0].name);
      ergebnis['fileUpload'] = fileInput.current.files[0];
    } else {
      setFileName(undefined);
      ergebnis['fileUpload'] = null;
    }
    ergebnisUpdate(ergebnis);
  };

  const inputChange = (feldName, wert) => {
    ergebnis[feldName] = wert;
    ergebnisUpdate(ergebnis);
  };

  return (
    <>
      {['noteTechnik', 'noteMethodik', 'noteKondition', 'noteTheorie', 'noteWeiterempfehlung', 'notePotenzial'].map(noteName => {
        return <div key={noteName} className="zeile">
          <label htmlFor={`${ausbildner.id}-${noteName}`}>{texte.teilnehmerAbschluss[noteName]}</label>
          <input disabled={!bearbeitbar} id={`${ausbildner.id}-${noteName}`} type="number" step="0.1" className="note" value={ergebnis[noteName]} onChange={(ev) => inputChange(noteName, ev.currentTarget.value)}/>
        </div>
      })}
      <div className="zeile">
        <label htmlFor={`${ausbildner.id}-note`}>{texte.teilnehmerAbschluss.note}</label>
        <input disabled={!bearbeitbar} id={`${ausbildner.id}-note`} type="text" className="" value={ergebnis.note} maxLength="10" onChange={(ev) => inputChange('note', ev.currentTarget.value)}/>
      </div>
      <div className="zeile">
        <label htmlFor={`${ausbildner.id}-notenblatt`}>{texte.teilnehmerAbschluss.notenblatt}</label>
        <input disabled={!bearbeitbar} id={`${ausbildner.id}-notenblatt`} type="text" className="" value={ergebnis.notenblatt} maxLength="50" onChange={(ev) => inputChange('notenblatt', ev.currentTarget.value)}/>
      </div>
      <div className="zeile">
        <label htmlFor={`${ausbildner.id}-abschlussBeurteilung`}>{texte.teilnehmerAbschluss.abschlussBeurteilung}</label>
        <textarea disabled={!bearbeitbar} id={`${ausbildner.id}-abschlussBeurteilung`} rows="5" className="inline" value={ergebnis.abschlussBeurteilung} onChange={(ev) => inputChange('abschlussBeurteilung', ev.currentTarget.value)}/>
      </div>
      <div className="zeile">
        <label htmlFor={`${ausbildner.id}-upload`}>{texte.teilnehmerAbschluss.abschlussDokument}</label>
        {bearbeitbar &&
        <>
          <input id={`${ausbildner.id}-upload`} type="file" ref={fileInput} accept="*/*" className="hide" onChange={onFileChange}/>
          <span className="btn btn-upload" onClick={() => fileInput.current.click()}>{texte.fileButton}</span>
          {fileName &&
          <>
            &nbsp;<span>{fileName}</span>
          </>
          }
        </>
        }
        {ergebnis.hatDokument &&
        <span className="datei-upload">
          <a href={`${dokumentPfad}?ergebnisTrainerId=${ergebnis.id}`} target="_blank" className={`ikon ${ergebnis.dokumentIcon}`}/>
        </span>
        }
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ausbildung: state.ausbildung,
    dokumentPfad: state.api.update,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TeilnehmerAbschlussForm);
