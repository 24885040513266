import React, {useState} from "react";
import {connect} from "react-redux";
import {teamZuteilungDelete, teamZuteilungSpeichern, teamZuteilungUpdate} from "../../state/lehrerTeamTeilnehmerReducer";
import IconToggle from "../IconToggle";
import PersonIcon from "../../../../components/PersonIcon";

const TeamTeilnehmer = ({team, teamTeilnehmer, teamStatusListe, texte, teamZuteilungSpeichern, teamZuteilungDelete, teamZuteilungUpdate}) => {
  const [editMode, setEditMode] = useState(false);
  const darfLoeschen = true; // TODO: muss das anders werden?

  const onSpeichernClick = () => {
    const zusatzdaten = {
      teamZuteilungId: teamTeilnehmer.id,
      statusId: teamTeilnehmer.statusId,
    };

    teamZuteilungSpeichern(team, teamTeilnehmer.ausbildner, zusatzdaten);
    setEditMode(false);
  };

  const onLoeschenClick = () => {
    teamZuteilungDelete(teamTeilnehmer);
    setEditMode(false);
  };
  
  const onInputChange = (feldName, wert) => {
    teamTeilnehmer[feldName] = wert;
    teamZuteilungUpdate(teamTeilnehmer);
  };

  return (
    <div className={`eintrag-box ${editMode ? 'expand-width' : ''}`}>
      <div className="header">
        {teamTeilnehmer.ausbildner.name}
        <PersonIcon person={teamTeilnehmer.ausbildner}/>
        <button className="btn btn-mini" onClick={() => setEditMode(!editMode)}>
          <IconToggle istBearbeitbar={darfLoeschen} />
        </button>
      </div>
      {editMode &&
      <div className="edit">
        <div className="zeile abgrenzen">
          {texte.teamStatus}&nbsp;
          <select className='select' value={teamTeilnehmer.status.id} onChange={(ev) => onInputChange('statusId', ev.currentTarget.value)}>
            {teamStatusListe.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
          </select>
        </div>
        <div className="zeile">
          <button className="btn btn-primary" onClick={onSpeichernClick}>{texte.speichern}</button>
          {darfLoeschen &&
          <button className="btn btn-danger pull-right" onClick={onLoeschenClick}>{texte.teamZuordnungEntfernen}</button>
          }
        </div>
      </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    teamStatusListe: state.teamStatusListe,
    texte: state.i18n.texte
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    teamZuteilungSpeichern: (klassenlehrer, ausbildner, zusatzdaten) => { dispatch(teamZuteilungSpeichern(klassenlehrer, ausbildner, zusatzdaten)) },
    teamZuteilungDelete: (teamZuteilung) => { dispatch(teamZuteilungDelete(teamZuteilung)) },
    teamZuteilungUpdate: (teamZuteilung) => { dispatch(teamZuteilungUpdate(teamZuteilung)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamTeilnehmer);
