import React from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../components/ModalerDialog";
import {spielmeldungenClear, zuSpielgruppeWechseln} from "../state/modules/status";
import {formatiereZeit} from "../../../lib/formatierungen";
import SpielstatusLabel from "./SpielstatusLabel";

const SpielMeldungenModal = ({status, texte, spielMeldungenClear, zuSpielgruppeWechseln}) => {

  const schliessen = () => {
    spielMeldungenClear();
  };

  const navigiereZuSpielgruppe = (spielgruppeId) => {
    spielMeldungenClear();
    zuSpielgruppeWechseln(spielgruppeId);
  };

  const renderMeldung = (meldung, index) => {
    const cssClass = `row-fluid spiel-box ${index % 2 === 0 ? 'striped' : ''}`;
    return (
      <div key={meldung.id}>
        <div className={`${cssClass} visible-desktop`}>
          <div className="span2" style={{whiteSpace: 'nowrap'}}>
            <i className="icon-time" style={{verticalAlign: 'baseline'}}/> {formatiereZeit(meldung.spielzeit)} <SpielstatusLabel spielstatusId={meldung.spielstatusId}/>
          </div>
          <div className="span4 text-right">{meldung.heim}</div>
          <div className="span1 text-center">{`${meldung.toreHeim}:${meldung.toreGast}`}</div>
          <div className="span4">{meldung.gast}</div>
          <div className="span1">
            <button className="btn" onClick={() => navigiereZuSpielgruppe(meldung.spielgruppeId)}>
              <i className="icon-eye-open" />
            </button>
          </div>
        </div>
        <div className={`${cssClass} hidden-desktop`}>
          <div className="span6 clearfix">
            <i className="icon-time" style={{verticalAlign: 'baseline'}}/>
            &nbsp;
            {formatiereZeit(meldung.spielzeit)}
            &nbsp;
            <SpielstatusLabel spielstatusId={meldung.spielstatusId}/> 
            &nbsp;
            <button className="btn" onClick={() => navigiereZuSpielgruppe(meldung.spielgruppeId)}>
              <i className="icon-eye-open" />
            </button>
          </div>
          <div className="span6">
            <div className="paarung">
              <div className="team">{meldung.heim}</div>
              <div className="resultat">{meldung.toreHeim}</div>
            </div>
            <div className="paarung">
              <div className="team">{meldung.gast}</div>
              <div className="resultat">{meldung.toreGast}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (status.spielMeldungen.length > 0) {
    return (
      <ModalerDialog>
        <div className="modal volleBreite">
          <div className="modal-header">
            <button type="button" className="close" onClick={schliessen}>&times;</button>
            <h3>{texte.neueResultate}</h3>
          </div>
          <div className="modal-body">
            <div className="spiel-container">
              {status.spielMeldungen.map((meldung, index) => renderMeldung(meldung, index))}
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={schliessen}>{texte.ok}</button>
          </div>
        </div>
      </ModalerDialog>
    );
  }
  return <></>;
};

const mapStateToProps = (state) => {
  return {
    status: state.status,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    spielMeldungenClear: () => {
      dispatch(spielmeldungenClear());
    },
    zuSpielgruppeWechseln: (spielgruppeId) => {
      dispatch(zuSpielgruppeWechseln(spielgruppeId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpielMeldungenModal);
