import React, {useEffect, useMemo, useState} from 'react'
import {connect} from "react-redux";
import PersonBadge from "../../../components/PersonBadge";
import {bewertungenAlleLaden} from "../state/modules/bewertungen";
import BewertungModal from "./BewertungModal";
import DatumPicker from "../../../components/DatumPicker";

const Spielerliste = ({spielerliste, bewertungen, saisons, i18n, apiAktiv, bewertungenAlleLaden}) => {
  const [editSpieler, setEditSpieler] = useState(null);
  const [von, setVon] = useState(undefined);
  const [bis, setBis] = useState(undefined);
  const [spielerFilter, setSpielerFilter] = useState('');

  const texte = i18n.texte;

  useEffect(() => {
    bewertungenAlleLaden();
  }, []);
  
  const spielerGefiltert = useMemo(() => {
    if (spielerFilter.length > 0) {
      let suche = spielerFilter.toLocaleLowerCase();
      return spielerliste.filter(s => s.person.name.toLocaleLowerCase().indexOf(suche) >= 0);
    }
    return spielerliste;
  }, [spielerFilter]);
  
  const saisonChange = (saisonId) => {
    if (saisonId > 0) {
      const saison = saisons.find(s => s.id == saisonId);
      setVon(new Date(saison.von));
      setBis(new Date(saison.bis));
    } else {
      setVon(undefined);
      setBis(undefined);
    }
  };
  
  const bewertungenLadenClick = () => {
    bewertungenAlleLaden(von, bis)
  };

  return (
    <>
      <table className="table attributes">
        <tbody>
        <tr>
          <td><label className="text-bold">{texte.zeitraum}</label></td>
          <td className="text-nowrap">
            <DatumPicker value={von} onChange={dt => setVon(dt)} locale={i18n.locale}/>&nbsp;<DatumPicker value={bis} onChange={dt => setBis(dt)} locale={i18n.locale}/>
          </td>
          <td><label className="text-bold">{saisons.length > 0 && texte.saisonwahl}</label></td>
          <td>
            {saisons.length > 0 &&
            <select onChange={ev => saisonChange(ev.currentTarget.value)} style={{width: 178}}>
              <option value="0"></option>
              {saisons.map(s => <option key={s.id} value={s.id}>{s.text}</option>)}
            </select>
            }
          </td>
        </tr>
        <tr>
          <td><label>{texte.spielerFilter}</label></td>
          <td><input type="text" value={spielerFilter} onChange={ev => setSpielerFilter(ev.currentTarget.value)} style={{width: 178}}/></td>
          <td>
            {apiAktiv &&
            <div className="text-center"><i className="fa fa-refresh fa-spin fa-2x"/></div>
            }
          </td>
          <td>
            <button type="button" className="btn" onClick={bewertungenLadenClick}>{texte.bewertungenLaden}</button>
          </td>
        </tr>
        </tbody>
      </table>
      <table className="table table-condensed">
        <colgroup>
          <col style={{width: 50}}/>
          <col/>
          <col/>
          <col/>
        </colgroup>
        <thead>
        <tr>
          <th colSpan={2}>{texte.spieler}</th>
          <th>{texte.anzahlBewertungen}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {spielerGefiltert.map(spieler => {
          const spielerBewertungen = bewertungen.filter(b => b.spieler_id === spieler.id);
          return (
            <tr key={spieler.id}>
              <td><span className="label label-info">{spieler.passnummer}</span></td>
              <td><PersonBadge person={spieler.person}/></td>
              <td>{spielerBewertungen.length}</td>
              <td>
                <button type="button" className="btn" onClick={() => setEditSpieler(spieler)}><i className="icon-edit"/></button>
              </td>
            </tr>
          );
        })}
        </tbody>
      </table>
      {editSpieler &&
      <BewertungModal spieler={editSpieler} onHide={() => setEditSpieler(null)}/>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    spielerliste: state.spielerliste,
    bewertungen: state.bewertungen,
    saisons: state.listen.saisons,
    i18n: state.i18n,
    apiAktiv: state.api.aktiv,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bewertungenAlleLaden: (von, bis) => { dispatch(bewertungenAlleLaden(von, bis)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Spielerliste);
