// @flow
import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {sportmotorikAlleSpielerLaden, sportmotorikSpielerIndex} from "../state/modules/sportmotorik";
import {executeJson} from "../lib/fetchHelper";
import Tooltip from "../../../components/Tooltip";
import PersonBadge from "../../../components/PersonBadge";
import SportmotorikModal from "./SportmotorikModal";
import StatusLabel from "./StatusLabel";

const Sportmotorik = ({phase, spieler, sportmotorik, bearbeitbar, texte, api, sportmotorikAlleSpielerLaden, sportmotorikSpielerIndex}) => {
  const [showSpieler, setShowSpieler] = useState(null);

  useEffect(() => {
    // lade sportmotorik von server
    sportmotorikAlleSpielerLaden(phase);
  }, [phase]);

  const abschlussMoeglich = useMemo(() => !spieler.some(s => !sportmotorik.find(m => m.spielerId === s.id && m.phase === phase)), [sportmotorik]);
  const abschlussAlle = useMemo(() => {
    return spieler.every(s => {
      const m = sportmotorik.find(m => m.spielerId === s.id && m.phase === phase);
      return m && m.abgeschlossen;
    });
  }, [sportmotorik]);

  const abschliessen = () => {
    executeJson(api.sportmotorik, api.token, 'POST', {phase: phase})
      .then(result => {
        if (result.ok) {
          sportmotorikSpielerIndex(result.data.liste);
        }
      });
  };

  const renderSpieler = (spieler) => {
    const spielerMotorik = sportmotorik.find(m => m.spielerId === spieler.id && m.phase === phase);

    return (
      <tr key={spieler.id}>
        <td><Tooltip content={texte.passnummer} className="label label-info">{spieler.passnummer}</Tooltip></td>
        <td><PersonBadge person={spieler.person} personVerlinken={false}/></td>
        <td><StatusLabel model={spielerMotorik}/></td>
        <td>{spielerMotorik && spielerMotorik.verletzt && <span className="label label-important">{texte.sportmotorik.verletzt}</span>}</td>
        <td>{spielerMotorik && spielerMotorik.note_durchschnitt > 0 ? spielerMotorik.note_durchschnitt.toFixed(1) : <>&mdash;</>}</td>
        <td>
          {bearbeitbar &&
          <button className="btn" onClick={() => setShowSpieler(spieler)}>
            <i className="icon-edit"></i>
          </button>
          }
        </td>
      </tr>
    );
  };

  if (api.aktiv) {
    return <div className="text-center"><i className="fa fa-refresh fa-spin fa-3x"/></div>;
  }

  return (
    <>
      {abschlussMoeglich && !abschlussAlle &&
      <div className="form-actions">
        <button type="button" className="btn btn-primary" onClick={abschliessen}>{texte.tests_abschliessen}</button>
      </div>
      }
      <table className="table table-striped table-condensed">
        <colgroup>
          <col width={54}/>
          <col width={200}/>
          <col width={80}/>
          <col/>
          <col width={80}/>
          <col width={20}/>
        </colgroup>
        <thead>
        <tr>
          <th></th>
          <th>{texte.spieler}</th>
          <th>{texte.status}</th>
          <th></th>
          <th>⌀</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {spieler.map(s => renderSpieler(s))}
        </tbody>
      </table>
      {showSpieler &&
      <SportmotorikModal phase={phase} spieler={showSpieler} onHide={() => setShowSpieler(null)}/>
      }
      {bearbeitbar && abschlussMoeglich && !abschlussAlle &&
      <div className="form-actions">
        <button type="button" className="btn btn-primary" onClick={abschliessen}>{texte.tests_abschliessen}</button>
      </div>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    spieler: state.spieler,
    sportmotorik: state.sportmotorik,
    bearbeitbar: state.listen.bearbeitbar,
    texte: state.i18n.texte,
    api: state.api,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sportmotorikAlleSpielerLaden: (phase) => {
      dispatch(sportmotorikAlleSpielerLaden(phase));
    },
    sportmotorikSpielerIndex: (liste) => {
      dispatch(sportmotorikSpielerIndex(liste));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sportmotorik);
