import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import Video from 'twilio-video';
import ParticipantRemote from "./ParticipantRemote";
import ParticipantLocal from "./ParticipantLocal";
import {refreshRoomsList, setCurrentRoom} from "../state/settings";
import useWindowUnload from "../../../effects/useWindowUnload";

const Room = ({room, texte, setCurrentRoom, refreshRoomsList}) => {
  const [participants, setParticipants] = useState([]);
  const [videoRoom, setVideoRoom] = useState(null);
  const [remoteVideoTrackCount, setRemoteVideoTrackCount] = useState(0);

  useWindowUnload(texte.windowUnload);
  
  useEffect(() => {
    const participantConnected = participant => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);
      participant.on('trackPublished', track => {
        if (track.kind === 'video') {
          setRemoteVideoTrackCount(prevCount => setRemoteVideoTrackCount(prevCount + 1));
        }
      });
      participant.on('trackUnpublished', track => {
        if (track.kind === 'video') {
          setRemoteVideoTrackCount(prevCount => setRemoteVideoTrackCount(prevCount - 1));
        }
      });
    };
    const participantDisconnected = participant => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      );
    };
    Video.connect(room.token, {
      name: room.name,
      audio: false,
      video: false
    }).then(vr => {
      setVideoRoom(vr);
      vr.on('participantConnected', participantConnected);
      vr.on('participantDisconnected', participantDisconnected);
      vr.on('disconnected', () => setCurrentRoom(null));
      vr.participants.forEach(participantConnected);
      
      refreshRoomsList();
    });

    return () => {
      setVideoRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(trackPublication => trackPublication.track.stop());
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
      refreshRoomsList();
    };
  }, []);

  if (videoRoom === null) {
    return <></>;
  } else {
    return (
      <div className="room">
        {videoRoom &&
        <ParticipantLocal room={videoRoom} participant={videoRoom.localParticipant} remoteParticipant={participants[0]} remoteTrackCount={remoteVideoTrackCount} />
        }
        {participants.map(participant => <ParticipantRemote key={participant.sid} participant={participant} />)}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    texte: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentRoom: (room) => { dispatch(setCurrentRoom(room)); },
    refreshRoomsList: () => { dispatch(refreshRoomsList()); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Room);
