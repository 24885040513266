import React, {useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../../components/ModalerDialog";
import PersonBadge from "../../../../components/PersonBadge";
import {formatiereBetrag} from "../../../../lib/formatierungen";
import {rechnungenBemerkungVereinSpeichern} from "../../state/modules/rechnungskontrollen";

const BemerkungenSpielerModal = ({rechnung, onHide, locale, texte, rechnungenBemerkungVereinSpeichern}) => {
  const [bemerkungen, setBemerkungen] = useState(rechnung.spielerInfo ? (rechnung.spielerInfo.bemerkungen || '') : '');
  const texteBemerkungen = texte.bemerkungenBearbeiten;

  const speichern = () => {
    rechnungenBemerkungVereinSpeichern(rechnung, bemerkungen);
    schliessen();
  };

  const schliessen = () => {
    onHide();
  };

  return (
    <ModalerDialog>
      <div className="modal modal-wide">
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{texte.rechnungen.bemerkungenSpieler}</h3>
        </div>
        <div className="modal-body" style={{overflow: 'visible'}}>
          <table className="table attributes ">
            <tbody>
            <tr>
              <th>{texteBemerkungen.person}</th>
              <td>
                <PersonBadge person={rechnung.person} personVerlinken={false} showVcf={false} fotoTooltipAktiv={false}/>
              </td>
            </tr>
            <tr>
              <th>{texteBemerkungen.leistung}</th>
              <td>{rechnung.leistung.bezeichnung}</td>
            </tr>
            <tr>
              <th>{texteBemerkungen.betragFreiwillig}</th>
              <td>
                {rechnung.betragFreiwillig === true &&
                <i className="icon-ok"/>
                }
              </td>
            </tr>
            <tr>
              <th>{texteBemerkungen.betrag}</th>
              <td>{formatiereBetrag(rechnung.betrag, locale)}</td>
            </tr>
            <tr>
              <th>{texte.rechnungen.bemerkungenSpieler}</th>
              <td><textarea rows={5} style={{width: '100%'}} value={bemerkungen} onChange={ev => setBemerkungen(ev.currentTarget.value)}/></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" onClick={speichern}>{texteBemerkungen.speichern}</button>
          <button type="button" className="btn" onClick={schliessen}>{texteBemerkungen.abbrechen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    locale: state.i18n.locale,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rechnungenBemerkungVereinSpeichern: (rechnung, bemerkungen) => {
      dispatch(rechnungenBemerkungVereinSpeichern(rechnung, bemerkungen));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BemerkungenSpielerModal);
