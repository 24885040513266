import React, {useState} from 'react';
import {connect} from "react-redux";
import BibliothekAufgabe from "./BibliothekAufgabe";

const Bibliothek = ({bibliothek, texte}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState([]);

  let bibliothekFiltered = bibliothek.filter(b => b.aktiv && b.publiziert);

  const moeglicheFilter = [];
  bibliothekFiltered.forEach(b => {
    if (!moeglicheFilter.includes(b.block)) {
      moeglicheFilter.push(b.block);
    }
    if (!moeglicheFilter.includes(b.thema)) {
      moeglicheFilter.push(b.thema);
    }
  });

  if (filter.length > 0) {
    bibliothekFiltered = bibliothekFiltered.filter(b => filter.includes(b.block) || filter.includes(b.thema));
  }

  const toggleFilter = (name) => {
    if (filter.includes(name)) {
      setFilter(filter.filter(f => f !== name));
    } else {
      setFilter([...filter, name]);
    }
  };

  return (
    <div className="block bibliothek">
      <div className="header">
        <h6>
          {texte.bibliothek}
          <button className="btn btn-mini pull-right" onClick={() => setShowFilter(!showFilter)}>
            <i className="icon-filter"/>
          </button>
        </h6>
        {showFilter &&
        <div className="btn-list">
          {moeglicheFilter.map(mf => <button key={mf} className={`btn btn-small ${filter.includes(mf) ? 'btn-success' : ''}`} onClick={() => toggleFilter(mf)}>{mf}</button>)}
        </div>
        }
      </div>
      <div className="content">
        {bibliothekFiltered.map((aufgabe) => <BibliothekAufgabe key={aufgabe.id} aufgabe={aufgabe}/>)}
        {bibliothekFiltered.length === 0 &&
        <div className="alert alert-info">{texte.bibliothekKeineEintraege}</div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    bibliothek: state.bibliothek,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Bibliothek);
