import React from "react";
import {connect} from "react-redux";
import {formatiereDatumZeit} from "../../../lib/formatierungen";

const StatusLabel = ({ model, texte }) => {
  let statusText = '';
  let statusCss = '';

  if (model) {
    if (model.abgeschlossen) {
      statusText = texte.abgeschlossen;
      statusCss = 'label-success'
    } else {
      statusText = texte.in_bearbeitung;
      statusCss = 'label-info'
    }
  } else {
    statusText = texte.ausstehend;
    statusCss = 'label-warning'
  }

  return (
    <>
      <span className={`label ${statusCss}`}>{statusText}</span>
      {model && model.abgeschlossen &&
      <><br/><span className="label">{formatiereDatumZeit(model.datum_abgeschlossen)}</span></>
      }
    </>
  );
};


const mapStateToProps = (state) => {
  return {
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusLabel);
