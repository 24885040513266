import React, {useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../components/ModalerDialog";
import PersonBadge from "../../../components/PersonBadge";
import DatumPicker from "../../../components/DatumPicker";
import {formatiereDatum} from "../../../lib/formatierungen";
import Tooltip from "../../../components/Tooltip";
import {executeJson} from "../lib/fetchHelper";
import {mirwaldSpielerUpdate} from "../state/modules/mirwald";
import StatusLabel from "./StatusLabel";
import {berechneAlleWerte,} from "../lib/mirwaldBerechnung";

const AlleBearbeitenModal = ({phase, spielerListe, mirwald, onHide, wachstumsreserve, locale, texte, api, mirwaldSpielerUpdate}) => {
  const spieler_zu_mirwald = spielerListe.map(s => {
    const daten = mirwald.find(m => m.spielerId === s.id);
    if (typeof daten == 'undefined') {
      return ({spielerId: s.id, phase: phase});
    } else {
      return daten;
    }
  });
  const [mirwaldDaten, setMirwaldDaten] = useState(spieler_zu_mirwald);
  const [defaultDatum, setDefaultDatum] = useState(null);
  const texteMirwald = texte.mirwald;

  const schliessen = () => {
    onHide();
  };

  const alleVorhanden = (mirwald) => {
    return mirwald.datum_zeit_messung && mirwald.koerpergewicht && mirwald.koerpergroesse_stehend && mirwald.koerpergroesse_sitzend;
  }

  const speichern = (spieler, mirwald) => {
    if (!alleVorhanden(mirwald)) {
      return;
    }
    berechneAlleWerte(mirwald, spieler, wachstumsreserve);
    const daten = {
      id: spieler.id,
      phase: phase,
      aktion: 'bearbeiten',
      mirwald: {...mirwald}
    };
    delete daten.mirwald.errors;
    executeJson(`${api.mirwald}/${spieler.id}`, api.token, 'PUT', daten)
      .then(result => {
        mirwaldSpielerUpdate(result.data.mirwald);
      })
  };

  const defaultDatumChange = (value) => {
    const neueMirwaldDaten = mirwaldDaten.map(mir => {
      if (!mir.datum_zeit_messung) {
        mir.datum_zeit_messung = value;
      }
      return mir;
    })
    setDefaultDatum(value);
    setMirwaldDaten(neueMirwaldDaten);
  }

  const setzeWert = (key, value, spieler) => {
    const neueMirwaldDaten = [...mirwaldDaten];
    const spielerMirwald = neueMirwaldDaten.find(m => m.spielerId === spieler.id && m.phase === phase);
    spielerMirwald[key] = Number(value);
    speichern(spieler, spielerMirwald);
    setMirwaldDaten(neueMirwaldDaten);
  };  

  const setzeText = (key, value, spieler) => {
    const neueMirwaldDaten = [...mirwaldDaten];
    const spielerMirwald = neueMirwaldDaten.find(m => m.spielerId === spieler.id && m.phase === phase);
    spielerMirwald[key] = value;
    speichern(spieler, spielerMirwald);
    setMirwaldDaten(neueMirwaldDaten);
  };

  const renderSpieler = (spieler) => {
    const spielerMirwald = mirwaldDaten.find(m => m.spielerId === spieler.id && m.phase === phase);

    if (spielerMirwald.abgeschlossen) {
      return (
        <tr key={spieler.id}>
          <td><Tooltip content={texte.passnummer} className="label label-info">{spieler.passnummer}</Tooltip></td>
          <td><PersonBadge person={spieler.person} fotoTooltipAktiv={false} showVcf={false} personVerlinken={false}/></td>
          <td><StatusLabel model={spielerMirwald}/></td>
          <td><span className="label">{formatiereDatum(spielerMirwald.datum_zeit_messung)}</span></td>
          <td className={'text-right'}>{spielerMirwald['koerpergewicht']} kg</td>
          <td className={'text-right'}>{spielerMirwald['koerpergroesse_stehend']} cm</td>
          <td className={'text-right'}>{spielerMirwald['koerpergroesse_sitzend']} cm</td>
        </tr>
      );
    }
    if (spieler.editable == true) {
      return (
        <tr key={spieler.id}>
          <td><Tooltip content={texte.passnummer} className="label label-info">{spieler.passnummer}</Tooltip></td>
          <td><PersonBadge person={spieler.person} fotoTooltipAktiv={false} showVcf={false} personVerlinken={false}/></td>
          <td><StatusLabel model={spielerMirwald}/></td>
          <td><DatumPicker locale={locale} value={spielerMirwald.datum_zeit_messung} onChange={datum => setzeText('datum_zeit_messung', datum, spieler)}/></td>
          <td className="text-right">
            <input type="number" max={500} min={1} step={0.1}
                   value={spielerMirwald.koerpergewicht || ''}
                   onChange={(ev) => setzeWert('koerpergewicht', ev.currentTarget.value, spieler)}
                   className={'text-right'}/>
            {' kg'}
          </td>
          <td className={'text-right'}>
            <input type="number" max={250} min={1} step={0.1}
                   value={spielerMirwald.koerpergroesse_stehend || ''}
                   onChange={(ev) => setzeWert('koerpergroesse_stehend', ev.currentTarget.value, spieler)}
                   className={'text-right'}/>
            {' cm'}
          </td>
          <td className={'text-right'}>
            <input type="number" max={250} min={1} step={0.1}
                   value={spielerMirwald.koerpergroesse_sitzend || ''}
                   onChange={(ev) => setzeWert('koerpergroesse_sitzend', ev.currentTarget.value, spieler)}
                   className={'text-right'}/>
            {' cm'}
          </td>
        </tr>
      );
    }
    return (
      <tr key={spieler.id}>
        <td><Tooltip content={texte.passnummer} className="label label-info">{spieler.passnummer}</Tooltip></td>
        <td><PersonBadge person={spieler.person} fotoTooltipAktiv={false} showVcf={false} personVerlinken={false}/></td>
        <td colSpan={5}></td>
      </tr>
    );
  };

  return (
    <ModalerDialog>
      <div className="modal breit-modal">
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{texteMirwald.titel}</h3>
        </div>
        <div className="modal-body">
          <table className="table table-condensed attributes">
            <colgroup>
              <col width="8%"/>
              <col width="20%"/>
              <col width="9%"/>
              <col width="18%"/>
              <col width="15%"/>
              <col width="15%"/>
              <col width="15%"/>
            </colgroup>
            <thead>
            <tr>
              <th></th>
              <th>{texte.spieler}</th>
              <th>{texte.status}</th>
              <th>{texteMirwald.datum_zeit_messung_kurz}</th>
              <th className="text-right">{texteMirwald.koerpergewicht}</th>
              <th className="text-right">{texteMirwald.koerpergroesse_stehend}</th>
              <th className="text-right">{texteMirwald.koerpergroesse_sitzend}</th>
            </tr>
            </thead>
            <tbody>
            {spielerListe.map(s => renderSpieler(s))}
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <>
            <div className="pull-left" style={{display: 'flex', alignItems: 'center'}}>
              <label className="control-label" htmlFor={'datum_zeit_messung'} style={{marginRight: '5px'}}>{texteMirwald.initial_datum_zeit_messung}</label>
              <DatumPicker locale={locale} value={defaultDatum} onChange={datum => defaultDatumChange(datum)}/>
            </div>
            <button type="button" className="btn btn-primary" onClick={schliessen}>
              {texte.speichern}
            </button>
            <button type="button" className="btn" onClick={schliessen}>{texte.schliessen}</button>
          </>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    mirwald: state.mirwald,
    wachstumsreserve: state.listen.wachstumsreserve,
    locale: state.i18n.locale,
    texte: state.i18n.texte,
    api: state.api,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mirwaldSpielerUpdate: (mirwald) => {
      dispatch(mirwaldSpielerUpdate(mirwald));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlleBearbeitenModal);
