import React, {useState} from "react";
import {connect} from "react-redux";
import ModalerDialog from "../../../components/ModalerDialog";
import VorlageAuswahl from "./VorlageAuswahl";
import MarkdownEditor from "../../../components/MarkdownEditor";

const RechnungslaufEdit = ({rechnungstexte, bexio, onChange, onHide, i18n, api}) => {
  const [textModel, setTextModel] = useState({...rechnungstexte});
  const [editSprache, setEditSprache] = useState(i18n.sprachen[0].locale);
  const [showVorlageAuswahl, setShowVorlageAuswahl] = useState(false);
  const texte = i18n.texte;
  const editTexte = texte.rechnungslaufEdit;

  const textChange = (locale, name, value) => {
    const model =  {...textModel};
    model[locale][name] = value;
    setTextModel(model);
  };
  
  const getText = (sprache, name) => {
    if (!textModel[sprache]) {
      textModel[sprache] = {};
    }
    return textModel[sprache][name] || '';
  };

  const onVorlageChange = (vorlage) => {
    const model = {};
    vorlage.texte.forEach(t => {
      const sprache = i18n.sprachen.find(s => s.id === t.spracheId);
      model[sprache.locale] = {
        header: t.header,
        footer: t.footer,
        freiwilligZusatz: t.freiwilligZusatz,
        freiwilligHeader: t.freiwilligHeader,
        freiwilligFooter: t.freiwilligFooter,
      };
    });
    setTextModel(model);
    setShowVorlageAuswahl(false);
  };

  const schliessen = () => {
    onHide();
  };
  
  const speichern = () => {
    onChange(textModel);
    schliessen();
  };

  return (
    <ModalerDialog>
      <div className="" style={{width: '60%', }}>
        <div className="modal-header">
          <button type="button" className="close" onClick={schliessen}>&times;</button>
          <h3>{texte.daten.rechnungstexteBearbeiten}</h3>
        </div>
        <div className="modal-body">
          <div className="pull-right button-liste">
            {showVorlageAuswahl ||
            <>
              <button type="button" className="btn" onClick={() => setShowVorlageAuswahl(true)}>{editTexte.vonVorlageUebernehmen}</button>
              <button type="button" className="btn" onClick={() => setTextModel(bexio.rechnungstexte)}>{editTexte.bexioDefaultUebernehmen}</button>
            </>
            }
            {showVorlageAuswahl &&
            <VorlageAuswahl istEntfernbar={true} onChange={onVorlageChange} />
            }
          </div>
          <ul className="nav nav-tabs">
            {i18n.sprachen.map(s => <li key={s.id} className={editSprache === s.locale ? 'active' : ''}><a href="#" onClick={() => { setEditSprache(s.locale); return false; }}>{s.bezeichnung}</a></li>)}
          </ul>
          <table className="table attributes formular-breit">
            <tbody>
            <tr>
              <th><label>{editTexte.header}</label></th>
              <td><MarkdownEditor showBexioAktionen={true} value={getText(editSprache, 'header')} onChange={md => textChange(editSprache, 'header', md)} markdownUrl={api.markdown} token={api.token} locale={i18n.locale}/></td>
            </tr>
            <tr>
              <th><label>{editTexte.footer}</label></th>
              <td><MarkdownEditor showBexioAktionen={true} value={getText(editSprache, 'footer')} onChange={md => textChange(editSprache, 'footer', md)} markdownUrl={api.markdown} token={api.token} locale={i18n.locale}/></td>
            </tr>
            <tr>
              <th><label>{editTexte.freiwilligZusatz}</label></th>
              <td><input type="text" value={getText(editSprache, 'freiwilligZusatz')} onChange={ev => textChange(editSprache,'freiwilligZusatz', ev.currentTarget.value)}/></td>
            </tr>
            <tr>
              <th><label>{editTexte.freiwilligHeader}</label></th>
              <td><MarkdownEditor showBexioAktionen={true} value={getText(editSprache, 'freiwilligHeader')} onChange={md => textChange(editSprache, 'freiwilligHeader', md)} markdownUrl={api.markdown} token={api.token} locale={i18n.locale}/></td>
            </tr>
            <tr>
              <th><label>{editTexte.freiwilligFooter}</label></th>
              <td><MarkdownEditor showBexioAktionen={true} value={getText(editSprache, 'freiwilligFooter')} onChange={md => textChange(editSprache, 'freiwilligFooter', md)} markdownUrl={api.markdown} token={api.token} locale={i18n.locale}/></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" onClick={() => speichern()}>{editTexte.speichern}</button>
          <button type="button" className="btn" onClick={schliessen}>{texte.abbrechen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    rechnungslauf: state.rechnungslauf,
    bexio: state.bexio,
    i18n: state.i18n,
    api: state.api,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RechnungslaufEdit);
