import React, {useState} from 'react'
import {connect} from "react-redux";
import {seitenwechsel} from "../state/modules/spielfeld";
import StaffbankMenu from "./StaffbankMenu";
import EreignisIcon from "./EreignisIcon";
import {staffbankSetMenu} from "../state/modules/editMarkierer";

const Footer = ({spielIstAbgeschlossen, anzeige, heimSpielerkarte, gastSpielerkarte, ereignisseErsatzspieler, ereignisseStaff, showMenu, texte, seitenwechsel, staffbankSetMenu}) => {
  const [selectedPersonMenu, setSelectedPersonMenu] = useState(null);
  const [selectedPersonSubmenu, setSelectedPersonSubmenu] = useState(null);
  
  const onHideMenu = () => {
    staffbankSetMenu(null);
    setSelectedPersonMenu(null);
  };

  const onMenuClick = (event, position, typ) => {
    event.stopPropagation();

    if(showMenu && showMenu.position === position && showMenu.typ === typ) {
      staffbankSetMenu(null);
    } else {
      staffbankSetMenu({position: position, typ: typ});
    }
    setSelectedPersonMenu(null);
  };

  const onSelectedPersonClick = (event, spieler) => {
    event.stopPropagation();

    if (spielIstAbgeschlossen) {
      return;
    }
    if (selectedPersonMenu === spieler.id){
      setSelectedPersonMenu(null);
    } else {
      setSelectedPersonMenu(spieler.id);
    }
    setSelectedPersonSubmenu(null);
  };

  const onSeitenwechselClick = () => {
    staffbankSetMenu(null);
    seitenwechsel();
  };
  
  const buttonCssClasses = {
    links: {
      ersatz: 'ersatz',
      staff: 'staff',
      abwesend: 'abwesend',
    },
    rechts: {
      ersatz: 'ersatz',
      staff: 'staff',
      abwesend: 'abwesend',
    },
  };
  
  let menuComponent = null;

  if(showMenu) {
    const teamSpielerkarte = (anzeige === 'normal') ? 
      (showMenu.position === 'links' ? heimSpielerkarte : gastSpielerkarte) : 
      (showMenu.position === 'rechts' ? heimSpielerkarte : gastSpielerkarte);

    const menuClass = `menu staffMenu ${showMenu.position}`;
    switch (showMenu.typ) {
      case 'ersatz':
        if (teamSpielerkarte.zustand.ersatzspieler.length === 0) {
          break;
        }
        menuComponent = <ul className={menuClass}>
          {
            teamSpielerkarte.zustand.ersatzspieler.map((zustandSpieler, index) => {
              const spieler = teamSpielerkarte.spieler.find(s => s.id === zustandSpieler.id && s.unregistrierter_spieler === zustandSpieler.unregistriert);
              return <li key={index} className="flex-row">
                <span className="bild">
                  <img src={spieler.bild_url} />
                </span>
                <span className="flex1 name" onClick={(e) => onSelectedPersonClick(e, spieler)}>
                  {spieler.rueckennummer} {spieler.name}
                  {zustandSpieler.ereignisse.length > 0 &&
                  zustandSpieler.ereignisse.map((ereignisTyp, index) => <EreignisIcon key={index} ereignisTyp={ereignisTyp} />)
                  }
                </span>
                {selectedPersonMenu === spieler.id && ereignisseErsatzspieler.length > 0 &&
                  <StaffbankMenu spieler={spieler} team={teamSpielerkarte.team} ereignisse={ereignisseErsatzspieler} hideMenu={onHideMenu} />
                }
              </li>;
            })
          }
        </ul>;
        buttonCssClasses[showMenu.position].ersatz += ' open';
        break;
      case 'staff':
        if (teamSpielerkarte.zustand.staff.length === 0) {
          break;
        }
        menuComponent = <ul className={menuClass}>
          { 
            teamSpielerkarte.zustand.staff.map((zustandStaff, index) => {
              const staff = teamSpielerkarte.spielerkarte.staffbank.find(s => s.id === zustandStaff.id);
              return <li key={index} className="flex-row">
                <span className="bild">
                  <img src={staff.bild_url} />
                </span>
                <span className="flex1 name" onClick={(e)=> onSelectedPersonClick(e, staff)}>
                  {staff.name}
                  {zustandStaff.ereignisse.length > 0 &&
                  zustandStaff.ereignisse.map(ereignisTyp => <EreignisIcon key={`icon-${ereignisTyp}-${staff.id}`} ereignisTyp={ereignisTyp} />)
                  }
                </span>
                {selectedPersonMenu === staff.id && ereignisseStaff.length > 0 &&
                <StaffbankMenu staff={staff} team={teamSpielerkarte.team} ereignisse={ereignisseStaff} hideMenu={onHideMenu} />
                }
              </li>;
            })
          }
        </ul>;
        buttonCssClasses[showMenu.position].staff += ' open';
        break;
      case 'abwesend':
        if (teamSpielerkarte.spielerkarte.abwesende.length === 0) {
          break;
        }
        menuComponent = <ul className={menuClass}>
          {
            teamSpielerkarte.spielerkarte.abwesende.map((abwesend, index) => {
              return <li key={index} className="flex-row">
                <span className="bild">
                  <img src={abwesend.bild_url} />
                </span>
                <span className="flex1 name">{abwesend.name}</span>
              </li>;
            })
          }
        </ul>;
        buttonCssClasses[showMenu.position].abwesend += ' open';
        break;
    }
  }

  const spielerkarteLinks = (anzeige === 'normal') ? heimSpielerkarte.spielerkarte : gastSpielerkarte.spielerkarte;
  const spielerkarteRechts = (anzeige === 'normal') ? gastSpielerkarte.spielerkarte : heimSpielerkarte.spielerkarte;

  return(
    <div className="footer">
      <div className="buttons links">
        {spielerkarteLinks.abwesende.length > 0 &&
        <span className="menuContainer">
          <button className={buttonCssClasses.links.abwesend} onClick={(e) => onMenuClick(e,'links', 'abwesend')}>{texte.abwesend}</button>
          {menuComponent && showMenu.position === 'links' && showMenu.typ === 'abwesend' &&
          menuComponent
          }
        </span>
        }
        {spielerkarteLinks.staffbank.length > 0 &&
        <span className="menuContainer">
          <button className={buttonCssClasses.links.staff} onClick={(e) => onMenuClick(e, 'links', 'staff')}>{texte.staff}</button>
          {menuComponent && showMenu.position === 'links' && showMenu.typ === 'staff' &&
          menuComponent
          }
        </span>
        }
        {spielerkarteLinks.ersatzformation.length > 0 &&
        <span className="menuContainer">
          <button className={buttonCssClasses.links.ersatz} onClick={(e) => onMenuClick(e, 'links', 'ersatz')}>{texte.ersatz}</button>
          {menuComponent && showMenu.position === 'links' && showMenu.typ === 'ersatz' &&
          menuComponent
          }
        </span>
        }
      </div>
      <div>
        <i className="fa fa-undo seitenwechsel" onClick={onSeitenwechselClick}/>
      </div>
      <div className="buttons rechts">
        {spielerkarteRechts.ersatzformation.length > 0 &&
        <span className="menuContainer">
          <button className={buttonCssClasses.rechts.ersatz} onClick={(e) => onMenuClick(e, 'rechts', 'ersatz')}>{texte.ersatz}</button>
            {menuComponent && showMenu.position === 'rechts' && showMenu.typ === 'ersatz' &&
            menuComponent
            }
        </span>
        }
        {spielerkarteRechts.staffbank.length > 0 &&
        <span className="menuContainer">
          <button className={buttonCssClasses.rechts.staff} onClick={(e) => onMenuClick(e, 'rechts', 'staff')}>{texte.staff}</button>
          {menuComponent && showMenu.position === 'rechts' && showMenu.typ === 'staff' &&
          menuComponent
          }
        </span>
        }
        {spielerkarteRechts.abwesende.length > 0 &&
        <span className="menuContainer">
          <button className={buttonCssClasses.rechts.abwesend} onClick={(e) => onMenuClick(e, 'rechts', 'abwesend')}>{texte.abwesend}</button>
          {menuComponent && showMenu.position === 'rechts' && showMenu.typ === 'abwesend' &&
          menuComponent
          }
        </span>
        }
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    spielIstAbgeschlossen: state.spiel.ist_abgeschlossen,
    anzeige: state.spielfeld.anzeige,
    heimSpielerkarte: state.heim,
    gastSpielerkarte: state.gast,
    ereignisseErsatzspieler: state.spielfeld.ereignisse.substitutes,
    ereignisseStaff: state.spielfeld.ereignisse.staff,
    showMenu: state.editMarkierer.staffbank,
    texte: state.i18n.footer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    seitenwechsel: () => { dispatch(seitenwechsel()); },
    staffbankSetMenu: (daten) => { dispatch(staffbankSetMenu(daten)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
