import {ersetzeStringMitDatum, sortierePersonName, sortiereTeam} from "../lib/formatierungen";
import {bibliothekReferenzSetzen} from "./teamTeilnehmerReducer";
import {sortiereAufgabenZuteilungen} from "./teilnehmerAufgabenReducer";

function preloaderFuerKursleiter(options) {
  return {
    ausbildung: ersetzeStringMitDatum(options.ausbildung, ['von', 'bis']),
    ausbildner: options.ausbildner.map(a => {
      a.ergebnis = ersetzeStringMitDatum(a.ergebnis, ['beurteilungAbgeschlossenAm'])
      return a;
    }).sort(sortierePersonName),
    klassenlehrer: options.klassenlehrer.sort(sortierePersonName),
    teams: options.teams.map(team => {
      return ersetzeStringMitDatum(team, ['von', 'bis']);
    }).sort(sortiereTeam),
    teamTeilnehmer: options.teamTeilnehmer.map(tt => {
      ersetzeStringMitDatum(tt, ['abgeschlossenAm']);
      tt.aufgaben.forEach(aufgabe => {
        bibliothekReferenzSetzen(aufgabe, options.bibliothek);
        ersetzeStringMitDatum(aufgabe, ['von', 'bis']);
      });
      tt.aufgaben.sort(sortiereAufgabenZuteilungen);
      return tt;
    }).sort((a, b) => sortierePersonName(a.ausbildner, b.ausbildner)),
    teamStatusListe: options.teamStatusListe,
    programmBlockListe: options.programmBlockListe,
    bibliothek: options.bibliothek,
    abschlussStatusListe: options.abschlussStatusListe,
    abmeldungStatusListe: options.abmeldungStatusListe,
    abmeldegruende: options.abmeldegruende,
    leistungen: options.leistungen,
    bezogeneLeistungen: options.bezogeneLeistungen,
    api: options.api,
    i18n: options.i18n,
    rollbar: options.rollbar,
    fehlermeldungen: [],
  };
}

export default preloaderFuerKursleiter;
