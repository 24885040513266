import React, {useState, useEffect, useRef} from 'react'
import '../shared/shared-components'

export const POSITION = {
  left: 'left',
  right: 'right',
  top: 'top',
  bottom: 'bottom'
};

const Position = ({children, triggerElement = null, triggerRect = null, position = POSITION.top, className = "", ...props}) => {
  const [styleWerte, setStyleWerte] = useState(null);
  const positionElement = useRef(null);

  useEffect(() => {
    if (styleWerte === null) {
      const vorhandeneStyles = props.styles || {};
      const style = {
        ...vorhandeneStyles,
        display: 'block',
        position: 'fixed',
        left: 'auto',
        right: 'auto',
        top: 'auto',
        bottom: 'auto',
      };
      if (triggerElement) {
        triggerRect = triggerElement.getBoundingClientRect();
      }
      if (triggerRect === null) {
        // fallback
        triggerRect = { top: 0, left: 0, width: 0, height: 0 };
      }
      switch (position) {
        case POSITION.left:
          style.top = triggerRect.top + ((-positionElement.current.clientHeight + triggerRect.height) / 2);
          style.left = triggerRect.left - positionElement.current.clientWidth - 2;
          break;
        case POSITION.right:
          style.top = triggerRect.top + ((-positionElement.current.clientHeight + triggerRect.height) / 2);
          style.left = triggerRect.left + triggerRect.width;
          break;
        case POSITION.top:
          style.top = triggerRect.top - positionElement.current.clientHeight;
          style.left = triggerRect.left + ((-positionElement.current.clientWidth + triggerRect.width) / 2);
          break;
        case POSITION.bottom:
          style.top = triggerRect.bottom;
          style.left = triggerRect.left + ((-positionElement.current.clientWidth + triggerRect.width) / 2);
          break;
      }
      setStyleWerte(style);
    }
  }, [triggerElement, triggerRect]);

  return (
    <div ref={positionElement} className={`${className} ${position}`} style={styleWerte || {display: 'block', position: 'absolute', left: -100000, top: -100000}} {...props}>
      {children}
    </div>
  );
};

export default Position;
