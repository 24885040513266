import React, {useState} from "react";

const NoteInput = ({value, readOnly = false, disabled = false, einfaerben = true, notenAuswahl = [5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1], onChange}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const aktiv = false;

  const handleChange = (neuerWert) => {
    setShowDropdown(false);
    onChange(neuerWert);
  };

  let cssClasses = 'leistung';
  if (aktiv) {
    cssClasses += ' simple-select';
  }
  if (einfaerben && value !== null) {
    if (value > 0 && value <= 2) {
      cssClasses += ' rot';
    } else if (value > 2 && value < 4) {
      cssClasses += ' gelb';
    } else if (value >= 4) {
      cssClasses += ' gruen';
    }
  }

  const numberValue = Number(value);
  const valueBereinigt = numberValue === 0 ? '-' : numberValue.toFixed(1);

  return (
    <span className="note-input-container" onFocus={() => setShowDropdown(!readOnly)} onBlur={() => setShowDropdown(false)}>
      <input readOnly={readOnly} disabled={disabled} type="text" value={valueBereinigt} className={cssClasses} onChange={() => {}}/>
      {showDropdown &&
      <ul className={"simple-select-list"} style={{width: '22px'}}>
        <li onMouseDown={() => handleChange(null)} onMouseUp={() => handleChange(null)}>-</li>
        {notenAuswahl.map(note => <li key={note} onMouseDown={() => handleChange(note)} onMouseUp={() => handleChange(note)}>{note.toFixed(1)}</li>)}
      </ul>
      }
    </span>
  );
};


export default NoteInput;
