import {rechnungSortieren} from "./rechnungen";

const RECHNUNGEN_VORSCHAU_SETZEN = 'RECHNUNGEN_VORSCHAU_SETZEN';
const RECHNUNGEN_VORSCHAU_DELETE = 'RECHNUNGEN_VORSCHAU_DELETE';

export function rechnungenVorschauSetzen(rechnungen) {
  return (dispatch) => {
    dispatch({ type: RECHNUNGEN_VORSCHAU_SETZEN, rechnungen: rechnungen});
  };
}
export function rechnungenVorschauEntfernen() {
  return (dispatch) => {
    dispatch({ type: RECHNUNGEN_VORSCHAU_DELETE});
  };
}

function rechnungenVorschauReducer(state = [], action) {
  switch (action.type) {
    case RECHNUNGEN_VORSCHAU_SETZEN:
      return action.rechnungen.sort(rechnungSortieren);
    case RECHNUNGEN_VORSCHAU_DELETE:
      return [];
    default:
      return state;
  }
}

export default rechnungenVorschauReducer;
