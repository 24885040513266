import React from 'react'

const Prewrap = ({children, tagName = 'span', style = {}, ...props}) => {
  const DynamicTag = `${tagName}`;
  const styles = {...style, whiteSpace: 'pre-wrap'};

  return (
    <DynamicTag style={styles} {...props}>
      {children}
    </DynamicTag>
  );
};

export default Prewrap;
