// @flow

import type {AppState, EinsatzState, SpielerEintrag, Wettkampfleistung} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";

const TipsZusammenfassung = (props: {
  einsatz: EinsatzState,
  i18n: any,
  relevanteSpieler: SpielerEintrag[],
  relevanteWettkampfleistungen: Wettkampfleistung[],
}) => {
  let anzahlTips = 0;
  let sum = {t: 0, i: 0, p: 0, s: 0};
  let anzahlLabel = props.i18n.tips.tipse.many;
  if (props.relevanteSpieler !== null) {
    props.relevanteSpieler.forEach((spieler) => {
      if (spieler.tips.t && spieler.tips.i && spieler.tips.p && spieler.tips.s) {
        anzahlTips += 1;
        sum.t += spieler.tips.t;
        sum.i += spieler.tips.i;
        sum.p += spieler.tips.p;
        sum.s += spieler.tips.s;
      }
      anzahlLabel = anzahlTips === 1 ? props.i18n.tips.tipse.one : props.i18n.tips.tipse.many;
    });
  }
  if (props.relevanteWettkampfleistungen !== null) {
    anzahlLabel = props.i18n.tips.wettkampfleistungen.many;
    props.relevanteWettkampfleistungen.forEach((wettkampfleistung) => {
      if (wettkampfleistung.t && wettkampfleistung.i && wettkampfleistung.p && wettkampfleistung.s) {
        anzahlTips += 1;
        sum.t += wettkampfleistung.t;
        sum.i += wettkampfleistung.i;
        sum.p += wettkampfleistung.p;
        sum.s += wettkampfleistung.s;
      }
      anzahlLabel = anzahlTips === 1 ? props.i18n.tips.wettkampfleistungen.one : props.i18n.tips.wettkampfleistungen.many;
    });
  }
  return (
    <div className="scouting-tips">
      <div className="zusammenfassung">
        <div className="row-fluid">
          <div className="span12">
            <div className="well">
              <h6>
                {props.i18n.tips.durchschnitt} ({anzahlTips} {anzahlLabel})
              </h6>
              <span>{anzahlTips <= 0 ? "-" : ((sum.t + sum.i + sum.p + sum.s) / (anzahlTips * 4)).toFixed(1)}</span>
            </div>
          </div>
        </div>
        <div className="row-fluid">
          <div className="span12">
            <table className="table table-condensed table-striped">
              <tbody>
              <tr>
                <th>{props.i18n.tips.tLang}</th>
                <td>{anzahlTips <= 0 ? "-" : (sum.t / anzahlTips).toFixed(1)}</td>
              </tr>
              <tr>
                <th>{props.i18n.tips.iLang}</th>
                <td>{anzahlTips <= 0 ? "-" : (sum.i / anzahlTips).toFixed(1)}</td>
              </tr>
              <tr>
                <th>{props.i18n.tips.pLang}</th>
                <td>{anzahlTips <= 0 ? "-" : (sum.p / anzahlTips).toFixed(1)}</td>
              </tr>
              <tr>
                <th>{props.i18n.tips.sLang}</th>
                <td>{anzahlTips <= 0 ? "-" : (sum.s / anzahlTips).toFixed(1)}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TipsZusammenfassung);
