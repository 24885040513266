// @flow

import type {TeamState, Team} from "../stateTypes";
import type {Action} from "../actionTypes";
import {teamDatenLaden} from "../datenLaden";

export function heimTeamKommentarSetzen(wert: string): Action {
  return {type: "TEAMS_HEIM_TEAM_KOMMENTAR_SETZEN", wert};
}

export function heimTeamNoteSetzen(wert: string): Action {
  const parsedWert = parseInt(wert, 10);
  return {type: "TEAMS_HEIM_TEAM_NOTE_SETZEN", wert: isNaN(parsedWert) ? null : parsedWert};
}

export function gastTeamKommentarSetzen(wert: string): Action {
  return {type: "TEAMS_GAST_TEAM_KOMMENTAR_SETZEN", wert};
}

export function gastTeamNoteSetzen(wert: string): Action {
  const parsedWert = parseInt(wert, 10);
  return {type: "TEAMS_GAST_TEAM_NOTE_SETZEN", wert: isNaN(parsedWert) ? null : parsedWert};
}

function teamsReducer(state: TeamState, action: Action): TeamState | null {
  let neuerState;

  switch (action.type) {
    case "TEAMS_HEIM_TEAM_KOMMENTAR_SETZEN":
      neuerState = {
        ...state,
        liste: state.liste.map<Team>((team) => {
          if (state.heimTeam && team.id === state.heimTeam.id) {
            return {...team, teamKommentar: action.wert}
          } else {
            return team;
          }
        })
      };
      if (state.heimTeam) {
        neuerState.heimTeam = {...state.heimTeam, teamKommentar: action.wert};
      }
      return neuerState;
    case "TEAMS_HEIM_TEAM_NOTE_SETZEN":
      neuerState = {
        ...state,
        liste: state.liste.map<Team>((team) => {
          if (state.heimTeam && team.id === state.heimTeam.id) {
            return {...team, teamNote: action.wert}
          } else {
            return team;
          }
        })
      };
      if (state.heimTeam) {
        neuerState.heimTeam = {...state.heimTeam, teamNote: action.wert};
      }
      return neuerState;
    case "TEAMS_GAST_TEAM_KOMMENTAR_SETZEN":
      neuerState = {
        ...state,
        liste: state.liste.map<Team>((team) => {
          if (state.gastTeam && team.id === state.gastTeam.id) {
            return {...team, teamKommentar: action.wert}
          } else {
            return team;
          }
        }),
      };
      if (state.gastTeam) {
        neuerState.gastTeam = {...state.gastTeam, teamKommentar: action.wert};
      }
      return neuerState;
    case "TEAMS_GAST_TEAM_NOTE_SETZEN":
      neuerState = {
        ...state,
        liste: state.liste.map<Team>((team) => {
          if (state.gastTeam && team.id === state.gastTeam.id) {
            return {...team, teamNote: action.wert}
          } else {
            return team;
          }
        })
      };
      if (state.gastTeam) {
        neuerState.gastTeam = {...state.gastTeam, teamNote: action.wert};
      }
      return neuerState;
    case "AJAX_TEAM_DATEN_ZURUECKSETZEN":
      return teamDatenLaden(action.serverDaten);
    default:
      return state || null;
  }
}

export default teamsReducer
