import {executeRequest} from "../lib/api";
import {setCurrentRoom, setLobby, setRoomsTimestamp} from "./settings";

const ROOMS_LIST = 'ROOMS_LIST';
const ROOMS_CREATE = 'ROOMS_CREATE';
const ROOMS_REMOVE = 'ROOMS_REMOVE';

let roomsTimerIntervalId = null;

export function listeTimerStarten() {
  return (dispatch) => {
    clearInterval(roomsTimerIntervalId); // zur sicherheit

    roomsTimerIntervalId = setInterval(() => {
      dispatch(roomList());
    }, 5 * 1000);
  };
}

export function listeTimerStoppen() {
  return () => {
    clearInterval(roomsTimerIntervalId); // zur sicherheit
  };
}

export function roomList() {
  return (dispatch, getState) => {
    const state = getState();
    const data = {};
    if (state.settings.roomsTimestamp) {
      data['zuletztGeprueft'] = state.settings.roomsTimestamp.toISOString();
    }
    executeRequest(state.api.raumListe, state.api.token, 'GET', data, (result) => {
      if (result.ok) {
        dispatch({type: ROOMS_LIST, liste: result.data.rooms});
        dispatch(setRoomsTimestamp(result.data.timestamp));
        if (result.data.lobby) {
          dispatch(setLobby(result.data.lobby));
        }
      } else {
        console.log(result);
      }
    });
  };
}

export function openRoom() {
  return (dispatch, getState) => {
    const api = getState().api;
    executeRequest(api.raumErstellen, api.token, 'GET', {}, (result) => {
      if (result.ok) {
        dispatch({type: ROOMS_CREATE, room: result.data});
      } else {
        console.log(result);
      }
    });
  };
}

export function closeRoom(room) {
  return (dispatch, getState) => {
    const api = getState().api;
    executeRequest(`${api.raumListe}/${room.sid}`, api.token, 'DELETE', {}, (result) => {
      if (result.ok) {
        dispatch({type: ROOMS_REMOVE, room: room});
        dispatch(setCurrentRoom(null));
      } else {
        console.log(result);
      }
    });
  };
}

function roomsReducer(state = [], action) {
  switch (action.type) {
    case ROOMS_LIST:
      return action.liste;
    case ROOMS_CREATE:
      const room = action.room;
      return [
        ...state.filter(r => r.sid !== room.sid),
        room,
      ];
    case ROOMS_REMOVE:
      return state.filter(r => r.sid !== action.room.sid);
    default:
      return state;
  }
}

export default roomsReducer;
