// @flow

import type {AppState, EinsatzState} from "../state/stateTypes";
import type {Dispatch} from "../state/actionTypes";

import React, {useState} from "react";
import {connect} from "react-redux";

import {mitTimeout, apiRequestAbschicken} from "../state/modules/ajax";

const NoteDropdown = (props: {
  einsatz: EinsatzState,
  i18n: any,
  pfade: any,
  feldAnApiSchicken: (string, string, string | number | null, {}) => void,
  feldName: string,
  feldLabel: string | void,
  feldWert: number | null,
  width: number | void,
  requestDaten: {},
  onValueChange: (number | null) => void,
  forceReadonly: boolean | void,
  einfaerben: boolean | false,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const handleChange = (neuerWert) => {
    mitTimeout(props.feldName, () => {
      props.feldAnApiSchicken(
        props.pfade.api.einsatz,
        props.feldName,
        neuerWert,
        props.requestDaten || {}
      );
    });
    props.onValueChange(neuerWert);
    setShowDropdown(false);
  };
  const aktuellerWert = props.feldWert && props.feldWert !== -1 ? props.feldWert.toFixed(1) : '-';

  const aktiv = !props.forceReadonly && !props.einsatz.readonly;

  let cssClasses = 'leistung';
  if (aktiv) {
    cssClasses += ' simple-select';
  }
  if (props.einfaerben && props.feldWert !== null) {
    if (props.feldWert > 0 && props.feldWert <= 2) {
      cssClasses += ' rot';
    } else if (props.feldWert > 2 && props.feldWert < 4) {
      cssClasses += ' gelb';
    } else if (props.feldWert >= 4) {
      cssClasses += ' gruen';
    }
  }

  if (aktiv && showDropdown) {
    return <span className="note-container" onBlur={() => {
      setShowDropdown(false);
    }}>
      <input
        type={"text"}
        name={props.feldName}
        value={aktuellerWert}
        className={cssClasses}
        style={{width: props.width || '22px', position: 'relative'}}
        onChange={() => {
          // React reklamiert bei so-was-wie-readonly ohne onChange
        }}
        rel={"tooltip"}
        title={props.feldLabel}
      />
      <ul className={"simple-select-list"} style={{width: props.width || '22px'}}>
        <li onMouseDown={() => handleChange(null)} onMouseUp={() => handleChange(null)}>-</li>
        {
          [5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1].map((note) => {
            return <li key={note} onMouseDown={() => handleChange(note)} onMouseUp={() => handleChange(note)}>{note.toFixed(1)}</li>
          })
        }
      </ul>
    </span>
  } else {
    return <span onFocus={() => {
      if (aktiv) {
        setShowDropdown(true)
      }
    }}>
      <input
        disabled={aktiv ? null : "disabled"}
        autoComplete={"off"}
        aria-autocomplete={"none"}
        type={"text"}
        name={props.feldName}
        value={aktuellerWert}
        className={cssClasses}
        style={{width: props.width || '22px', position: 'relative'}}
        onChange={() => {
          // React reklamiert bei so-was-wie-readonly ohne onChange
        }}
        onMouseDown={() => {
          setShowDropdown(true);
        }}
      />
    </span>
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    i18n: state.i18n,
    pfade: state.pfade,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    feldAnApiSchicken: (apiUrl: string, feldName: string, neuerWert: string | number | null, requestDaten: {}) => {
      dispatch(apiRequestAbschicken(apiUrl, 'PATCH', feldName, neuerWert, requestDaten, () => {
      }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoteDropdown);
