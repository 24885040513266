// @flow

import type {EinsatzState} from "../../state/stateTypes";
import type {Dispatch} from "../../state/actionTypes";

import React from "react";
import {connect} from "react-redux";
import TeamHeaderSpiel from "./TeamHeaderSpiel";
import TeamHeaderTurnier from "./TeamHeaderTurnier";
import TeamHeaderTraining from "./TeamHeaderTraining";

const TeamHeader = (props: {
  einsatz: EinsatzState,
}) => {
  if (props.einsatz.typ === "spiel") {
    return <TeamHeaderSpiel/>;
  } else if (props.einsatz.typ === "turnier") {
    return <TeamHeaderTurnier/>
  } else if (props.einsatz.typ === "training") {
    return <TeamHeaderTraining/>
  }
};

const mapStateToProps = (state) => {
  return {
    einsatz: state.einsatz,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamHeader);
