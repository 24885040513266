import React, {useState} from "react";
import {connect} from "react-redux";
import PersonBadge from "../../../../components/PersonBadge";
import TeamBadge from "../../../../components/TeamBadge";
import ModalerDialog from "../../../../components/ModalerDialog";
import {spielerCreate} from "../../state/modules/sponsorenlaufSpieler";

const SpielerHinzufuegenModal = ({typ, teamId, spielerkategorieId, personen, teams, texte, spielerCreate, onModalClose}) => {
  const [personenliste, setPersonenliste] = useState(personen);
  const [spielerSelektiert, setSpielerSelektiert] = useState([]);
  const texteSpieler = texte.spieler;

  const spielerSelektieren = (spielerId, checked) => {
    const neueSpielerIds = spielerSelektiert.filter(id => id !== spielerId);
    if (checked) {
      neueSpielerIds.push(spielerId)
    }
    setSpielerSelektiert(neueSpielerIds);
  };

  const spielerHinzufuegen = (alleSpieler, ev) => {
    ev.currentTarget.blur();
    const validePersonen = personenliste.filter(p => (p.teamId || p.fakeTeamId) > 0);
    if (validePersonen.length == 0) {
      return;
    }
    if (alleSpieler) {
      let neuePersonen = validePersonen.map(p => { return { id: p.spielerId, teamId: p.teamId || p.fakeTeamId } });
      if (typ == 'spielerkategorieId') {
        spielerCreate(spielerkategorieId, null, neuePersonen);
      } else if (typ == 'teamId') {
        spielerCreate(null, teamId, neuePersonen);
      }
      onModalClose();
    } else {
      const neuePersonen = validePersonen.filter(p => spielerSelektiert.includes(p.spielerId)).map(p => { return { id: p.spielerId, teamId: p.teamId || p.fakeTeamId } })
      if (typ == 'spielerkategorieId') {
        spielerCreate(spielerkategorieId, null, neuePersonen);
      } else if (typ == 'teamId') {
        spielerCreate(null, teamId, neuePersonen);
      }
      setPersonenliste(personenliste.filter(p => neuePersonen.find(o => o.id === p.spielerId) === undefined));
    }
  };

  return (
    <ModalerDialog>
      <div className="modal">
        <div className="modal-header">
          <button type="button" className="close" onClick={onModalClose}>&times;</button>
          <h3>{texteSpieler.hinzufuegenSpieler}</h3>
        </div>
        <div className="modal-body">
          {personenliste.length > 0 &&
          <table className="table table-striped table-condensed">
            <thead>
            <tr>
              <th>{texteSpieler.spieler}</th>
              <th>{texteSpieler.team}</th>
              <th><input type="checkbox" onClick={ev => setSpielerSelektiert(ev.currentTarget.checked ? personenliste.map(p => p.spielerId) : [])}/></th>
            </tr>
            </thead>
            <tbody>
            {personenliste.map(person => {
              let team = teams.find(t => t.id === person.teamId);
              return <tr key={person.id}>
                <td>
                  <PersonBadge person={person} fotoTooltipAktiv={false} personVerlinken={false} showVcf={false}/>
                  { person.externeVereine &&
                    <div className="text-muted">
                      {person.externeVereine.map(verein => <div key={verein}>{verein}</div>)}
                    </div>
                  }
                </td>
                <td>
                  {team && <TeamBadge team={team}/>}
                  {!team && 
                    <select value={person.fakeTeamId || undefined} onChange={ev => person.fakeTeamId = ev.currentTarget.value}>
                      <option></option>
                      {teams.map(t => <option key={t.id} value={t.id}>{t.bezeichnung}</option>)}
                    </select>
                  }
                </td>
                <td><input type="checkbox" checked={spielerSelektiert.includes(person.spielerId)} onChange={ev => spielerSelektieren(person.spielerId, ev.currentTarget.checked)}/></td>
              </tr>;
            })}
            </tbody>
          </table>
          }
          {personenliste.length === 0 && <div>{texteSpieler.keinePersonen}</div>}
        </div>
        <div className="modal-footer">
          <button type="button" className="btn" onClick={onModalClose}>{texte.abbrechen}</button>
          &nbsp;
          <button type="button" className="btn btn-primary" disabled={spielerSelektiert.length === 0} onClick={ev => spielerHinzufuegen(false, ev)}>{texteSpieler.selektierteHinzufuegen}</button>
          &nbsp;
          <button type="button" className="btn btn-primary" disabled={personenliste.length === 0} onClick={ev => spielerHinzufuegen(true, ev)}>{texteSpieler.alleHinzufuegen}</button>
        </div>
      </div>
    </ModalerDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    teams: state.listen.teams,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    spielerCreate: (spielerkategorieId, teamId, spielerIds) => { dispatch(spielerCreate(spielerkategorieId, teamId, spielerIds)) },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpielerHinzufuegenModal);
