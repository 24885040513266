import React from "react";
import {connect} from "react-redux";
import Spielgruppe from "./Spielgruppe";

const Spielgruppen = ({spielgruppen, spiele, texte}) => {
  if (spielgruppen.length === 0) {
    return <h2>{texte.keineDaten}</h2>;
  } else {
    return spielgruppen.map(sg => {
      const spieleInGruppe = spiele.filter(s => s.spielgruppeId === sg.id);
      return <Spielgruppe key={sg.id} spielgruppe={sg} spiele={spieleInGruppe}/>;
    });
  }
};

const mapStateToProps = (state) => {
  return {
    spielgruppen: state.spielgruppen,
    spiele: state.spiele,
    texte: state.i18n.texte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Spielgruppen);
