// @flow

import type { AppState, EinsatzState, SpielerEintrag, SpielerTips, TurnierSpielerEintrag, TurnierState, } from "../../../state/stateTypes";
import type { Dispatch } from "../../../state/actionTypes";

import React from "react";
import { connect } from "react-redux";

import { spielerTipsKommentarSetzen } from "../../../state/modules/spieler";
import Textfeld from "../../Textfeld";
import SpielerRow from "../SpielerRow";
import AndereTipsRow from "../AndereTipsRow";

const TurnierSpieler = ({ einsatz, turnier, spielerTipsKommentarSetzen, spieler, spielerEinsatz, }: Props) => {
  const aktiv = !einsatz.readonly;
  const requestDaten = { turnierId: turnier.id, spielerId: spieler.id, spielerTips: spieler.tips, };
  const kommentarAnzeigen = aktiv ? spieler.tips.kommentarAufgeklappt : (spieler.tips.kommentar || '').length > 0;
  const weitereTipsAnzeigen = einsatz.andereTipsAnzeigen ? spieler.tips.weitereTipsAufgeklappt : false;

  return (
    <tbody>
    <SpielerRow spieler={spieler} spielerEinsatz={spielerEinsatz} requestDaten={requestDaten}
                prognoseWert={null} typ={'turnier'} andereTipsAnzeigen={einsatz.andereTipsAnzeigen}/>
    {kommentarAnzeigen &&
      <tr>
        <td colSpan="4">&nbsp;</td>
        <td colSpan="9">
          <Textfeld
            feldName="tipsKommentar"
            feldWert={spieler.tips.kommentar || ''}
            requestDaten={requestDaten}
            breite='388px'
            onChange={(event) => {
              spielerTipsKommentarSetzen(spieler.id, event.target.value, spieler.tips)
            }}
          />
        </td>
      </tr>
    }
    {(weitereTipsAnzeigen) &&
      <AndereTipsRow spieler={spieler}/>
    }
    </tbody>
  );
};

type Props = {
  einsatz: EinsatzState,
  turnier: TurnierState,
  spielerTipsKommentarSetzen: (number, string, SpielerTips) => void,
  spieler: SpielerEintrag,
  spielerEinsatz: TurnierSpielerEintrag,
}

const mapStateToProps = (state: AppState) => {
  return {
    einsatz: state.einsatz,
    turnier: state.turnier,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    spielerTipsKommentarSetzen: (spielerId: number, neuerWert: string, altesTips: SpielerTips) => {
      dispatch(spielerTipsKommentarSetzen(spielerId, neuerWert, altesTips));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TurnierSpieler);
