import React, {useState} from "react";
import {connect} from "react-redux";
import {videoTagDelete, videoTagUpdate, videoTagEdit} from "../../state/teamTeilnehmerReducer";
import {ViewKontext} from "../../lib/constants";

const VideoTagging = ({aufgabeZuteilung, teamTeilnehmer, seek = () => {}, videoTag, ausbildung, texte, videoTagEdit, videoTagUpdate, videoTagLoeschen}) => {
  const [editSekunden, setEditSekunden] = useState(videoTag.sekunden);
  const [editKommentar, setEditKommentar] = useState(videoTag.kommentar);
  const bearbeitbar = ausbildung.bearbeitbar && ausbildung.kontext === ViewKontext.Klassenlehrer;

  const setEditMode = (mode) => {
    if (!mode && videoTag.id <= 0) {
      // abbrechen klick, bei neu erstellten eintrag soll diesen löschen (war nie auf server gespeichert)
      videoTagLoeschen(aufgabeZuteilung, teamTeilnehmer, videoTag);
    } else {
      setEditSekunden(videoTag.sekunden);
      setEditKommentar(videoTag.kommentar);
      videoTag.editMode = mode;
      videoTagEdit(aufgabeZuteilung, teamTeilnehmer, videoTag);
    }
  };

  const tagSpeichern = () => {
    let zusatzdaten = {
      sekunden: editSekunden,
      kommentar: editKommentar
    };
    if (videoTag.id > 0) {
      zusatzdaten.videoTagId = videoTag.id;
    }
    videoTagUpdate(aufgabeZuteilung, teamTeilnehmer, zusatzdaten);
    if (videoTag.id <= 0) {
      // eintrag mit negativer id entfernen, da nach dem Speichern eine echte id gesetzt ist und so nicht mehr mit der bisherigen übereinstimmt
      videoTagLoeschen(aufgabeZuteilung, teamTeilnehmer, videoTag);
    } else {
      setEditMode(false)
    }
  };

  const tagLoeschen = () => {
    videoTagLoeschen(aufgabeZuteilung, teamTeilnehmer, videoTag);
  };

  const formatiereZeit = (sekunden) => {
    const d = new Date(0);
    d.addSeconds(sekunden);
    return d.toString('mm:ss');
  };

  if (videoTag.editMode) {
    return (
      <div className="video-tag tag-edit">
        <div className="zeile">
          <label>{texte.videoTagging.sekunden}</label>
          <input className="zeitstempel" type="number" step={1} min={1} value={editSekunden} onChange={(e) => setEditSekunden(e.currentTarget.value)}/>
        </div>
        <div className="zeile">
          <label>{texte.videoTagging.kommentar}</label>
          <textarea rows={4} value={editKommentar} onChange={(e) => setEditKommentar(e.currentTarget.value)}/>
        </div>
        <div className="zeile">
          <button className="btn" onClick={() => setEditMode(false)}>{texte.abbrechen}</button>
          <button className="btn btn-primary pull-right" onClick={tagSpeichern}>{texte.speichern}</button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="video-tag">
        <div className="zeile">
          <span className="label label-info zeitstempel" onClick={() => seek(videoTag.sekunden)}>{formatiereZeit(videoTag.sekunden)}</span>
          <span className="kommentar">{videoTag.kommentar}</span>
        </div>
        {bearbeitbar &&
        <div className="zeile">
          <button className="btn btn-small" onClick={tagLoeschen}><i className="icon-trash"/></button>
          <button className="btn btn-small pull-right" onClick={() => setEditMode(true)}><i className="icon-edit"/></button>
        </div>
        }
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    ausbildung: state.ausbildung,
    dokumentPfad: state.api.update,
    texte: state.i18n.texte
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    videoTagEdit: (aufgabeZuteilung, ausbildner, videoTag) => { dispatch(videoTagEdit(aufgabeZuteilung, ausbildner, videoTag)); },
    videoTagUpdate: (aufgabeZuteilung, ausbildner, zusatzdaten) => { dispatch(videoTagUpdate(aufgabeZuteilung, ausbildner, zusatzdaten)); },
    videoTagLoeschen: (aufgabeZuteilung, ausbildner, videoTag) => { dispatch(videoTagDelete(aufgabeZuteilung, ausbildner, videoTag)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoTagging);
