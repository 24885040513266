import {executeJson} from "../../../../lib/fetchHelper";

const SPONSORENLAUF_PFLICHTBEITRAEGE_EDIT = 'SPONSORENLAUF_PFLICHTBEITRAEGE_EDIT';

export function pflichtbeitraegeVergangenUebernehmen() {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const data = {
      sponsorenlaufId: state.sponsorenlauf.id,
      vonLetztemSponsorenlaufUebernehmen: true
    };
    executeJson(`${api.pflichtbeitraege}`, api.token, 'POST', data).
    then(result => {
      if (result.ok) {
        dispatch({type: SPONSORENLAUF_PFLICHTBEITRAEGE_EDIT, pflichtbeitraege: result.data.pflichtbeitraege});
        if (result.data.infoMessage) {
          window.neueFlashMessage(result.data.infoMessage, 'info').anzeigen();
        }
      }
    });
  };
}

export function pflichtbeitraegeSpeichern(pflichtbeitraege) {
  return (dispatch, getState) => {
    const state = getState();
    const api = state.api;
    const data = {
      sponsorenlaufId: state.sponsorenlauf.id,
      pflichtbeitraege: pflichtbeitraege
    };
    executeJson(`${api.pflichtbeitraege}`, api.token, 'POST', data).
    then(result => {
      if (result.ok) {
        dispatch({type: SPONSORENLAUF_PFLICHTBEITRAEGE_EDIT, pflichtbeitraege: result.data.pflichtbeitraege});
      }
    });
  };
}


function sponsorenlaufPflichtbeitraegeReducer(state = [], action) {
  switch (action.type) {
    case SPONSORENLAUF_PFLICHTBEITRAEGE_EDIT:
      return action.pflichtbeitraege;
    default:
      return state;
  }
}

export default sponsorenlaufPflichtbeitraegeReducer;
